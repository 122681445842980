import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  DocumentIcon,
} from '@heroicons/react/24/outline';

const SocialLinkContainer = styled.div`
    margin: 21px 0 0;
    padding: 14px 16px 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 4px;
    cursor: pointer;

    &:hover svg{
      opacity: 0.7;
    }
`;

const Text = styled.p`
    margin: 0;
    width: 55%;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
`;

const Icon = styled.div`    
  width: ${(props) => (props.type === 'instruction' ? '24px' : '40px')};
  height: ${(props) => (props.type === 'instruction' ? '24px' : '40px')};
  margin-right: ${(props) => (props.type === 'instruction' ? '8px' : 0)};;
  svg{
    transition: opacity .15s ease;
  }
`;

export default function SocialLink(props) {
  const { text, link, type } = props;

  const svg = useMemo(() => {
    if (type === 'telegram') {
      return (
        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2606_63076)">
            <path d="M19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39Z" fill="url(#paint0_linear_2606_63076)" />
            <path d="M15.9242 28.4375C15.2924 28.4375 15.3998 28.199 15.1819 27.5974L13.3242 21.4836L27.6242 13" fill="#C8DAEA" />
            <path d="M15.9258 28.4382C16.4133 28.4382 16.6286 28.2152 16.9008 27.9507L19.5008 25.4225L16.2576 23.4668" fill="#A9C9DD" />
            <path d="M16.2564 23.4679L24.1149 29.2738C25.0118 29.7686 25.6588 29.5124 25.8823 28.4413L29.0811 13.3673C29.4085 12.0543 28.5806 11.4586 27.7226 11.8481L8.93922 19.0909C7.6571 19.6052 7.66474 20.3205 8.70555 20.6392L13.5258 22.1438L24.6851 15.1035C25.212 14.784 25.6956 14.9556 25.2987 15.3079" fill="url(#paint1_linear_2606_63076)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_2606_63076" x1="26.013" y1="6.513" x2="16.263" y2="29.25" gradientUnits="userSpaceOnUse">
              <stop stopColor="#37AEE2" />
              <stop offset="1" stopColor="#1E96C8" />
            </linearGradient>
            <linearGradient id="paint1_linear_2606_63076" x1="21.9446" y1="19.5028" x2="24.9878" y2="26.4365" gradientUnits="userSpaceOnUse">
              <stop stopColor="#EFF7FC" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_2606_63076">
              <rect width="39" height="39" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    if (type === 'instruction') {
      return (
        <DocumentIcon />
      );
    }
  }, [type]);

  return (
    <SocialLinkContainer onClick={() => { window.open(link, '_blank').focus(); }}>
      <Text>{text}</Text>
      <Icon type={type}>
        {svg}
      </Icon>
    </SocialLinkContainer>
  );
}
