import React from 'react';
import styled from 'styled-components';

const RadioButtonBlock = styled.div`
  margin-bottom: 0;
  border: 1px solid #cfcfcf;
  
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
  }
  input:checked+label span {
    text-shadow: 0 0 .6px #374151;
    background-color: #e9e9e9;
    border: 1px solid #cfcfcf;
  }
  
  label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 20px;
    cursor: pointer;
    margin-bottom: 0;
    
    span {
      text-shadow: 0 0 .6px #374151;
      background-color: transparent;
      margin: -1px;
      padding: 9px 16px;
      border: 1px solid transparent;
      transition: border .15s ease, background-color .15s ease, text-shadow .15s ease;
      box-sizing: border-box;
        &:hover {
            z-index: 1;
            border: 1px solid #a3a3a3;
        }
    }
  }
 `;

const RadioButtonWrapper = styled.div`
  &:not(:last-child) {
      margin-right: 10px;
  }

    @media (max-width: 1023px) {
        display: none;
    }
    
    background-color: #fff;
    flex-shrink: 0;

    ${RadioButtonBlock} {
        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            span {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
        &:not(:last-child) {
            margin-right: 0;
            border-right: none;
        }
        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            span {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
        }
    }
 `;

const RadioButtonList = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
 `;

export default function RadioButtonListTable(props) {
  const {
    children, disabled, width, list,
  } = props;

  return (
    <RadioButtonWrapper disabled={disabled} width={width}>
      <RadioButtonList>
        {list.map((element, index) => (
          <RadioButtonBlock>
            <input type="radio" checked={element.checked} id={`${element.title}-${index}`} onChange={(e) => element.method(e, index)} />
            <label htmlFor={`${element.title}-${index}`}>
              <span>{element.title}</span>
            </label>
          </RadioButtonBlock>
        ))}
      </RadioButtonList>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </RadioButtonWrapper>
  );
}
