import React from 'react';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function AzureBlock(props) {
  const {
    disableFields,
    showCommission,
    companies,
    handleChooseOffer,
    blurClass,
    commissions,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );
  const premiums = [2000, 5000, 10000];
  const currentCommission = (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1)
    ? commissions.find((commission) => commission.company === companies.alpha.name && commission.integration === 'azure') ? commissions.find((commission) => commission.company === companies.alpha.name && commission.integration === 'azure').price : 0
    : commissions.find((commission) => commission.company === companies.alpha.name) ? commissions.find((commission) => commission.company === companies.alpha.name).price : 0;
  const azureBlock = premiums.map((premium) => (
    <div key={premium} className="company_item mt-2">
      <div className="company_item__image_block">
        <div className="company_item__image">
          <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies.alpha.logo}`} alt="" width="142" height="62" />
        </div>
        <div className="company_item__match_info">
          <span>Соответствие запросу </span>
          <span className="main-color">100%</span>
        </div>
      </div>
      <div className="company_item__price_block hidden-xs">
        <div className="company_item__price">{premium.toLocaleString()}р.</div>
        <button type="button" onClick={() => handleChooseOffer(premium, currentCommission)} disabled={disableFields} className={`btn custom-btn mb-2 ${blurClass.length > 0 && ('disabled')}`}>Оформить</button>
      </div>
      <div className="company_item__content">
        <h2><span>Коронавирус.НЕТ</span></h2>
        <div className="company_item__content--body">
          <h3 className="font-weight-bold">Покрытие:</h3>
          <ul>
            <li>Страховая сумма: 1 000 000 рублей</li>
            <li>Возраст: от 7 до 70 лет</li>
            <li>Срок действия полиса: 1 год</li>
            <li>Время покрытия: 24 часа</li>
            <li>Территория страхования: весь мир</li>
            <li>Могут быть застрахованны только граждане РФ</li>
          </ul>
          <h3 className="font-weight-bold">Возмещение:</h3>
          <ul>
            <li>Инфекционное заболевание - {(premium * 10).toLocaleString()}р. Выплата производится 1 раз в течение действия полиса</li>
            <li>Смерть в результате инфекционного заболевания - 100% от страховой суммы, за вычетом ранее произведенной выплаты</li>
          </ul>
          {showCommission && (
            <SimpleNotification>Ваше вознаграждение: {((currentCommission * premium) / 100).toFixed(2)} рублей = {currentCommission}%.</SimpleNotification>
          )}
        </div>
      </div>
      <div className="company_item__price_block visible-xs">
        <div className="company_item__price">{premium.toLocaleString()}р.</div>
        <div className="company_item__buy">
          <button type="button" onClick={() => handleChooseOffer(premium, currentCommission)} disabled={disableFields} className={`btn custom-btn mb-2 ${blurClass.length > 0 && ('disabled')}`}>Оформить</button>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      {azureBlock}
    </>
  );
}
