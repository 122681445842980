import React, {
  forwardRef, useState, useContext, useMemo, useEffect,
} from 'react';
import Select from 'react-select';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { customFetch, customSelectStyle } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import DynamicIcon from '../../Utils/UiComponents/DynamicIcon';
import LoadingSpinner from '../../Layout/LoadingSpinner';

function Insurances({
  tags,
  notes,
  companies,
  banksOption,
  setCompanies,
}) {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  const productsKey = {
    mortgage: null,
    mortgageLife: null,
    mortgageComplex: null,
    osago: null,
  };

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [currentBank, setCurrentBank] = useState(null);
  // const [currentStock, setCurrentStock] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentProduct, setCurrentProduct] = useState({ label: 'Имущество', value: 'mortgage' });
  const [currentTag, setCurrentTag] = useState(productsKey);
  const [currentRisk, setCurrentRisk] = useState(productsKey);
  const [currentLimit, setCurrentLimit] = useState(productsKey);
  const [currentAllocation, setCurrentAllocation] = useState(null);
  const [load, setLoad] = useState(false);

  const [optionTags, setOptionTags] = useState([]);
  const [optionCompanies, setOptionCompanies] = useState([]);
  const [optionLimits, setOptionsLimits] = useState([]);
  const [optionRisks, setOptionRisks] = useState([]);
  // const [optionStocks, setOptionStocks] = useState([]);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const allocation = [
    { label: 'Лучший тариф', value: 'best' },
    { label: 'Надежная страховая', value: 'safe' },
    { label: 'Рекомендуем', value: 'recommend' },
    { label: 'Такая цена только у нас', value: 'price' },
  ];

  const products = [
    { label: 'Имущество', value: 'mortgage' },
    { label: 'Жизнь', value: 'mortgageLife' },
    { label: 'Жизнь и имущество', value: 'mortgageComplex' },
    { label: 'ОСАГО', value: 'osago' },
  ];

  const getIds = (arr) => Object.keys(arr).reduce((result, key) => ({
    ...result,
    [key]: Array.isArray(arr[key]) && arr[key].length && arr[key].map((item) => item.value),
  }), productsKey);

  const getOptions = (options, tag) => (options ? Object.keys(options).reduce((result, key) => {
    const elements = [];
    Array.isArray(tag) && tag.forEach((item) => Array.isArray(options[key]) && options[key].length && options[key].find((el) => el === item.id) && elements.push({ label: item.title || item.description, value: item.id }));
    return {
      ...result,
      [key]: elements,
    };
  }, productsKey) : productsKey);

  const save = () => {
    setLoad(true);
    const idsTag = getIds(currentTag);
    const idsRisk = getIds(currentRisk);
    const idsLimit = getIds(currentLimit);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/setInsuranceAdditions`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company: currentCompany.value,
        risks: idsRisk,
        limits: idsLimit,
        banks: currentBank && currentBank.map((item) => item.value),
        tags: idsTag,
        flag: currentAllocation && currentAllocation.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCompanies((company) => ({
            ...company,
            [currentCompany.value]: {
              ...company[currentCompany.value],
              risks: response.risks,
              restrictions: response.restrictions,
              banks_accredited: response.banks_accredited,
              tags: response.tags,
              flag: response.flag,
            },
          }));
          showModalInfo('Сохранено');
        } else {
          showModalInfo('Не удалось сохранить изменения');
        }
        setLoad(false);
      })
      .catch(() => {
        showModalInfo('Не удалось сохранить изменения');
        setLoad(false);
      });
  };

  const getTag = (id) => tags.find((tag) => tag.id === id);

  useEffect(() => {
    notes.forEach((note) => {
      note.type === 'risk'
        ? setOptionRisks((risk) => [...risk, { label: note.description, value: note.id }])
        : setOptionsLimits((limit) => [...limit, { label: note.description, value: note.id }]);
    });
  }, [notes]);

  useEffect(() => {
    Object.keys(companies).forEach((company) => {
      setOptionCompanies((itemCompany) => [...itemCompany, { label: companies[company].name, value: company }]);
    });
  }, [companies]);

  useEffect(() => {
    tags.forEach((tag) => {
      setOptionTags((itemTag) => [...itemTag, { label: tag.title, value: tag.id }]);
    });
  }, [tags]);

  useEffect(() => {
    if (currentCompany) {
      const tagsCompany = getOptions(companies[currentCompany.value].tags, tags);
      const risksCompany = getOptions(companies[currentCompany.value].risks, notes);
      const limitationCompany = getOptions(companies[currentCompany.value].restrictions, notes);
      const allocationCompany = allocation.find((item) => item.value === companies[currentCompany.value].flag);

      setCurrentBank(companies[currentCompany.value].banks_accredited && companies[currentCompany.value].banks_accredited.map((item) => banksOption.find((itemBank) => item === itemBank.value)));
      setCurrentTag(tagsCompany || productsKey);
      setCurrentAllocation(allocationCompany && allocationCompany);
      setCurrentRisk(risksCompany || productsKey);
      setCurrentLimit(limitationCompany || productsKey);
    }
  }, [currentCompany]);

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор страховой</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentCompany}
              onChange={(e) => setCurrentCompany(e)}
              options={optionCompanies}
              placeholder="Страховая"
            />
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор продукта</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentProduct}
              onChange={(e) => setCurrentProduct(e)}
              options={products}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор банков аккредитации</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentBank}
              isMulti
              onChange={(e) => setCurrentBank(e)}
              options={banksOption}
              placeholder="Банк"
            />
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выделение</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentAllocation}
              onChange={(e) => setCurrentAllocation(e)}
              options={allocation}
              placeholder="Лучший тариф"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор рисков</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentRisk[currentProduct.value]}
              isMulti
              onChange={(e) => setCurrentRisk((risk) => ({ ...risk, [currentProduct.value]: e }))}
              options={optionRisks}
              placeholder="Риск"
            />
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор ограничений</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              isMulti
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentLimit[currentProduct.value]}
              onChange={(e) => setCurrentLimit((limit) => ({ ...limit, [currentProduct.value]: e }))}
              options={optionLimits}
              placeholder="Ограничение"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Выбор тегов</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              isMulti
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentTag[currentProduct.value]}
              onChange={(e) => setCurrentTag((tag) => ({ ...tag, [currentProduct.value]: e }))}
              options={optionTags}
              placeholder="Тег"
            />
          </div>
        </div>
        {currentTag[currentProduct.value] && currentTag[currentProduct.value].map((item) => {
          const tag = getTag(item.value);
          return (
            <div className="dynamic-tag">
              <DynamicIcon className="dynamic-tag__icon" type={tag.outline} nameIcon={tag.icon_name} />
              <span className="dynamic-tag__title">{tag.title.charAt(0).toUpperCase() + tag.title.slice(1)}</span>
              {tag.tooltip ? <QuestionMarkCircleIcon className="dynamic-tag__tooltip" /> : null}
            </div>
          );
        })}
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <button disabled={load} type="button" className="btn btn-success mr-3 mb-3 mt-3" onClick={save}>Сохранить</button>
            {load ? <LoadingSpinner type="spin" height={38} width={38} className="loading-circle ml-3" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Insurances);
