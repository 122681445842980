import React, {
  useState, useEffect, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faStar } from '@fortawesome/fontawesome-free-solid';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faClone, faEnvelope } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import { format } from 'date-fns';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { customFetch } from '../../Utils/Helpers';
import PhoneVerifying from '../../Utils/PhoneVerifying';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import useUserInfoStore from '../../Stores/UserInfoStore';

const FileSaver = require('file-saver');

export default function MortgageCheckInfo(props) {
  const {
    formData,
    chosenOffer,
    companies,
    backToForm,
    setChosenOffer,
    draftInfo,
    setDisableField,
    disableField,
    policy,
    loadPolicy,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const [phoneVerified, setPhoneVerified] = useState(false);
  const [showModal, setShowModal] = useState({
    absolutDeclarationForm: false,
  });
  const [loadingFinalResult, setLoadingFinalResults] = useState(false);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const [visibleTooltipCopyToClipboard, setVisibleTooltipCopyToClipboard] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [emailForLink, setEmailForLink] = useState('');
  const [openModalSendLinkToEmail, setOpenModalSendLinkToEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [commissionChangedFlag, setCommissionChangedFlag] = useState(false);
  const [commission, setCommission] = useState(0);
  const [paymentInfo, setPaymentInfo] = useState('');

  const submitPayment = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/payment?draftHash=${draftInfo.hash}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then(() => {
        loadPolicy(policy.id);
        setDisableField(true);
      });
  };

  const verifyPhone = () => {
    if (chosenOffer.companyCode === 'absolut'
      || (chosenOffer.companyCode === 'rshbstrah' && formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'domrf' && formData.bank.value.value !== 'rosselhozbank')) {
      setPhoneVerified(false);
      setShowModal((prev) => ({ ...prev, absolutDeclarationForm: true }));
    }
  };

  const getPaymentLink = () => {
    setLoadingPaymentLink(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/getPaymentLink?draftHash=${draftInfo.hash}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if ((chosenOffer.companyCode === 'alpha-retail' || chosenOffer.companyCode === 'alpha-bank') && response.status && response.status === 'success') {
            setPaymentInfo('Продолжите процесс оформления полиса на телефоне, номер которого был указан при заполнении данных');
            submitPayment();
          } else {
            setPaymentLink(response.paymentLink);
          }
        } else {
          showModalInfo(response.error, 'error');
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при получении ссылки на оплату');
      })
      .finally(() => {
        setLoadingPaymentLink(false);
      });
  };

  const recalculate = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/calculation`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash: draftInfo.hash,
        chosenInsurance: chosenOffer.companyCode,
        calcType: 'final',
      }),
    }).then((response) => response.json())
      .then((response) => {
        if (!response.errorMessage && !response.error && !response.errors) {
          const { price, agentCommission } = response;
          if (chosenOffer.commission && agentCommission !== chosenOffer.commission) {
            setCommissionChangedFlag(true);
          }
          setChosenOffer((prev) => ({
            ...prev,
            price,
          }));
          setCommission(agentCommission);
          if (chosenOffer.companyCode === 'absolut' || (chosenOffer.companyCode === 'rshbstrah' && formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'domrf' && formData.bank.value.value !== 'rosselhozbank')) {
            verifyPhone();
          } else {
            getPaymentLink();
          }
        } else {
          setChosenOffer((prev) => ({
            ...prev,
            price: 0.00,
          }));
          const error = response.errorMessage || response.error || response.errors.errors[0].message;
          showModalInfo(error, 'error');
          setErrorMessage(error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при выполнении финального расчета');
      })
      .finally(() => {
        setLoadingFinalResults(false);
      });
  };

  const sendLinkToEmail = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/sendLinkToEmail`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailForLink,
        link: paymentLink,
        policyId: draftInfo.id,
        cost: chosenOffer.price,
        company: companies[chosenOffer.companyCode].name,
        product: 'mortgage',
      }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Ссылка отправлена на почту');
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setOpenModalSendLinkToEmail((prev) => !prev);
      });
    submitPayment();
  };

  const copyToClipBoard = () => {
    setVisibleTooltipCopyToClipboard(true);
    submitPayment();
  };

  const getKid = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/get-kid`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash: draftInfo.hash,
        insurance: chosenOffer.companyCode,
        service: true,
      }),
    })
      .then((response) => response.blob())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else if (response) {
          let fileName = '';
          switch (draftInfo.type) {
            case 'mortgage':
              fileName = `kid_mortgage_${chosenOffer.companyCode}`;
              break;
            case 'mortgageLife':
              fileName = `kid_mortgage_life_${chosenOffer.companyCode}`;
              break;
            case 'mortgageComplex':
              fileName = `kid_mortgage_complex_${chosenOffer.companyCode}`;
              break;
            default:
              break;
          }
          const fileExt = draftInfo.type === 'mortgageComplex' && chosenOffer.companyCode === 'sogaz' ? 'zip' : 'pdf';
          FileSaver.saveAs(response, `${fileName}.${fileExt}`);
        }
      });
  };

  useEffect(() => {
    if (!policy.is_demo) {
      setLoadingFinalResults(true);
      recalculate(chosenOffer.integration);
    }
  }, []);

  useEffect(() => {
    if (visibleTooltipCopyToClipboard) {
      setTimeout(() => {
        setVisibleTooltipCopyToClipboard(false);
      }, 3000);
    }
  }, [visibleTooltipCopyToClipboard]);

  useEffect(() => {
    if (formData.contactEmail.value) {
      setEmailForLink(formData.contactEmail.value);
    }
  }, [formData.contactEmail.value]);

  useEffect(() => {
    if (phoneVerified) {
      getPaymentLink();
    }
  }, [phoneVerified]);

  return (
    <>
      <PhoneVerifying
        setPhoneVerified={setPhoneVerified}
        showModal={showModal}
        setShowModal={setShowModal}
        draftHash={draftInfo.hash}
        phoneVerified={phoneVerified}
        phone={formData.contactPhone.value}
      />
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
        closeIconSize={16}
        open={openModalSendLinkToEmail}
        onClose={() => setOpenModalSendLinkToEmail((prev) => !prev)}
        center
      >
        <form onSubmit={sendLinkToEmail}>
          <label htmlFor="inputEmail">Укажите адрес электронной почты</label>
          <input value={emailForLink} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={(e) => setEmailForLink(e.target.value)} />
          <SimpleNotification>На указанный адрес отправлено письмо со ссылкой на оплату.</SimpleNotification>
          <button className="btn btn-success mt-2" type="submit">Отправить</button>
        </form>
      </Modal>
      <div name="check_info" className="search_results__main page--calck">
        <SimpleNotification offsetBottom="1rem">Отлично! Вы выбрали продукт от компании {companies[chosenOffer.companyCode].name}.</SimpleNotification>
        <SimpleNotification offsetBottom="1rem">Проверьте правильность введенных данных.</SimpleNotification>
        {userInfo.hide_commission === 0 && (
          <SimpleNotification offsetBottom="1rem">Ваше вознаграждение: {loadingFinalResult ? (<LoadingSpinner type="spin" className="loading-circle d-inline-block" height={20} width={20} />) : <><b>{((commission * chosenOffer.price) / 100).toFixed(2)} рублей</b> = {commission}%</>}.</SimpleNotification>
        )}
        {formData.bank.value.value === 'vtb' ? (
          <SimpleNotification offsetBottom="1rem"><strong>Важно! При не соответствии данных клиента и/или данных кредитного договора, требованиям банка ВТБ, есть риск отказа банка от договора страхования. Внимательно проверьте данные перед оформлением! Обязательно проверяйте требования банка по конкретной сделке, уточняйте требования к полису у менеджера кредитной организации.</strong></SimpleNotification>
        ) : null}
        {chosenOffer.companyCode === 'sogaz' || chosenOffer.companyCode === 'liberty' ? (
          <SimpleNotification offsetBottom="1rem"><strong>Вы можете <a className="dotted_link" onClick={getKid}>ознакомиться</a> с ключевым информационным документом компании {chosenOffer.companyName} перед оформлением полиса</strong></SimpleNotification>
        ) : null}
        <div>
          <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
        </div>
        <div className="mb-2 d-flex">
          <span className="mr-auto align-self-center">Введенные данные</span>
          {!disableField
            ? <button type="button" onClick={(e) => backToForm(e, 'top')} className="btn custom-btn">Редактировать</button>
            : null}
        </div>
        <table className="data_table">
          <thead>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Страхователь</span></td>
              <td className="data_table__row_content">
                <p>
                  {`${formData.lastName.value} ${formData.firstName.value} ${formData.middleName.value ? formData.middleName.value : ''}`}, {format(formData.birthday.value, 'dd.MM.yyyy')} ({formData.gender.value.label})
                </p>
                <p>
                  <b>Паспорт:</b> {formData.passport.value.substring(0, 4)} {formData.passport.value.substring(4)}, {format(formData.passportDate.value, 'dd.MM.yyyy')}, {formData.passportDivision.value} ({formData.passportUnitNumber.value})
                </p>
                <p><b>Адрес:</b> {formData.insuredAddress.value.value}</p>
                <p><b>Адрес регистрации:</b> {formData.insuredAddressRegistration.value.value}</p>
              </td>
            </tr>
            {draftInfo.type === 'mortgageLife' || draftInfo.type === 'mortgageComplex' ? (
              <tr>
                <td className="data_table__row_title"><span className="color-light">Страхование жизни и здоровья</span></td>
                <td className="data_table__row_content">
                  <p>
                    <b>Рост:</b> {formData.height.value} см.
                    <b>Вес:</b> {formData.weight.value} кг.
                    <b>Артериальное давление:</b> {formData.bloodPressureTop.value}/{formData.bloodPressureLow.value}
                  </p>
                  {(formData.profession.value.value || formData.sport.value.value) ? (
                    <p>
                      <b>Профессия:</b> {formData.profession.value.label}, <b>Спорт:</b> {formData.sport.value.label}
                    </p>
                  ) : null}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="data_table__row_title"><span className="color-light">Данные по ипотеке</span>
              </td>
              <td className="data_table__row_content">
                <p><b>Кредит:</b> {formData.bank.value.label}, {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(formData.loanBalance.value)}{chosenOffer.companyCode === 'zetta' || chosenOffer.companyCode === 'soglasie' ? <span>, {formData.annualRate.value}%</span> : null}</p>
                {draftInfo.type === 'mortgageLife' ? (
                  <p>
                    <b>Объект:</b> {formData.buildingType.value.label}, {formData.buildingSquare.value}м<sup><small>2</small></sup>, {formData.objectCost.value} руб.
                  </p>
                ) : null}
                {draftInfo.type === 'mortgage' || draftInfo.type === 'mortgageComplex' ? (
                  <>
                    <p>
                      <b>Объект:</b> {formData.buildingType.value.label}, {formData.buildingSquare.value}м<sup><small>2</small></sup>, {formData.yearOfConstruction.value} г., этаж {formData.floor.value}/{formData.buildingFloorCount.value}, {formData.objectCost.value} руб.
                      {formData.fireDangerFlag.value ? (<><br /> Есть деревянные перекрытия</>) : null}
                      {formData.hasGasFlag.value ? (<><br /> Есть газосодержащие коммуникации</>) : null}
                    </p>
                    <p><b>Право собственности:</b> {formData.ownership.value ? 'Есть' : 'Нет'}</p>
                    <p><b>Сдаётся в аренду:</b> {formData.usingForRent.value ? 'Да' : 'Нет'}</p>
                    <p><b>Адрес:</b> {formData.correctAddressFlag.value ? formData.correctAddress.value : formData.address.value.value}</p>
                  </>
                ) : null}
                <p><b>Договор:</b> {formData.creditAgreementFlag.value ? `№${formData.creditAgreementNumber.value} от ${format(formData.creditAgreementDate.value, 'dd.MM.yyyy')}${(formData.creditAgreementAddress.value.value && formData.creditAgreementAddress.value.data.city) ? `, ${formData.creditAgreementAddress.value.data.city}` : ''}` : 'Нет'}</p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Данные о полисе</span></td>
              <td className="data_table__row_content">
                <p>
                  <b>Продукт:</b>
                  {draftInfo.type === 'mortgage' ? ' Страхование имущества' : null}
                  {draftInfo.type === 'mortgageLife' ? ' Страхование жизни' : null}
                  {draftInfo.type === 'mortgageComplex' ? ' Комплексное ипотечное страхование' : null}
                </p>
                <p><b>Cтраховая компания:</b> {companies[chosenOffer.companyCode].name}</p>
                <p><b>Дата начала:</b> {format(formData.startDate.value, 'dd.MM.yyyy')}, <b>Дата окончания:</b> {format(formData.endDate.value, 'dd.MM.yyyy')}</p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Контактная информация</span></td>
              <td className="data_table__row_content">
                <p><b>Телефон:</b> {formData.contactPhone.value}, <b>Email:</b> {formData.contactEmail.value}</p>
                {(chosenOffer.companyCode === 'rshbstrah' && formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'domrf' && formData.bank.value.value !== 'rosselhozbank')
                  ? <SimpleNotification>Окончательный пакет документов страхования будет выслан на указанный почтовый адрес после оплаты.</SimpleNotification>
                  : null}
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div className="sticky-top search_results__sidebar page--search js-search_results__sidebar">
        <div className="search_results__info mt-0">
          <div className="product_block">
            <div className="product_block__title">
              <div className="product_block__title-pay">{chosenOffer.price} руб.</div>
              <span className="product_block__title-name">Страхование ипотеки</span>
            </div>
            <div className="product_block__body">
              <div className="product_block__image">
                <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo}`} width="142" height="62" alt="" />
                <div className="company_item__star">
                  {[...Array(companies[chosenOffer.companyCode].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </div>
              <div className="product_block__info">
                <div className="product_block__info_title">Стоимость полиса</div>
                <dl className="description_list">
                  <dt className="color-light">
                    {draftInfo.type === 'mortgage' ? 'Страхование имущества' : null}
                    {draftInfo.type === 'mortgageLife' ? 'Страхование жизни' : null}
                    {draftInfo.type === 'mortgageComplex' ? 'Комплексное страхование' : null}
                  </dt>
                  <dd><b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</b></dd>
                </dl>
                <div className="product_block__info_footer">
                  <dl className="description_list">
                    <dt className="color-light">Итого:</dt>
                    <dd><b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</b></dd>
                  </dl>
                </div>
              </div>
              <div className="product_block__footer">
                {loadingFinalResult
                  ? (<><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Выполняется финальный расчет</>)
                  : null}
                {loadingPaymentLink
                  ? (<><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Получение ссылки на оплату</>)
                  : null}
                {!loadingPaymentLink && !loadingFinalResult
                  ? (
                    <div>
                      {errorMessage ? (
                        <div className="error_block">{errorMessage}</div>
                      ) : (
                        <div>
                          {(chosenOffer.companyCode === 'alpha-retail' || chosenOffer.companyCode === 'alpha-bank') && paymentInfo ? (
                            <div className="info_block">{paymentInfo}</div>
                          ) : (
                            <div>
                              {policy.is_demo ? (
                                <a type="button" rel="noopener noreferrer" onClick={(e) => e.preventDefault()} className="btn custom-btn mr-1">Оплатить</a>
                              ) : (
                                <div>
                                  {paymentLink ? (
                                    <>
                                      <a type="button" rel="noopener noreferrer" target="_blank" href={paymentLink} onClick={submitPayment} className="btn custom-btn mr-1">Оплатить</a>
                                      <Tooltip
                                        animation="zoom"
                                        placement="top"
                                        visible={visibleTooltipCopyToClipboard}
                                        overlay={<span>Ссылка скопирована в буфер обмена</span>}
                                        trigger=""
                                        overlayClassName="custom-tooltip"
                                      >
                                        <CopyToClipboard text={paymentLink}>
                                          <button type="button" title="Скопировать ссылку на оплату" onClick={copyToClipBoard} className="btn btn-outline btn-square sq mr-1">
                                            <FontAwesomeIcon icon={faClone} className="fa-fw" />
                                          </button>
                                        </CopyToClipboard>
                                      </Tooltip>
                                      <button type="button" title="Отправить ссылку на оплату" onClick={() => setOpenModalSendLinkToEmail(true)} className="btn btn-outline btn-square">
                                        <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                                      </button>
                                    </>
                                  ) : null}
                                  {commissionChangedFlag ? (<div className="error_block">Комиссионное вознаграждение изменилось.</div>) : null}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
