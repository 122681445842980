import React, {
  useContext, useEffect, useMemo,
  useState,
} from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import { format, parseISO } from 'date-fns';
import { faLongArrowAltLeft } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
  handleChange,
} from '../../Utils/Helpers';
import getBanks from '../../Utils/ClassifiersLoaders';
import CommissionInput from '../../Admin/CommissionInput';

export default function PartnerProgramExclusionForm(props) {
  const {
    agent,
    setShowForm,
    customContracts,
    tableSelectedExclusion,
    exclusions,
  } = props;

  const agentTypeOptions = [
    { value: null, label: 'Для всех' },
    { value: 'natural', label: 'Физ. лицо' },
    { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
    { value: 'individual', label: 'Индивидуальный предприниматель' },
    { value: 'legal', label: 'Юр. лицо' },
  ];

  const regions = [
    {
      value: 'moscow',
      label: 'Москва',
    },
    {
      value: 'spb',
      label: 'Санкт-Петербург',
    },
    {
      value: 'moscowObl',
      label: 'Московская обл.',
    },
    {
      value: 'spbObl',
      label: 'Ленинградская обл.',
    },
    {
      value: 'other',
      label: 'Россия',
    },
    {
      value: 'all',
      label: 'Заполнить для всех',
    },
  ];
  const typeDeclarationOptions = [
    {
      label: 'Мидл-офис',
      value: 'middle-office',
    },
    {
      label: 'Цифровой брокер',
      value: 'digital-broker',
    },
    {
      label: 'Куратор СК',
      value: 'curator',
    },
  ];
  const { showModalInfo } = useContext(ModalMessagesContext);
  const customStyles = useMemo(() => customSelectStyle(), []);
  const [selectedExclusion, setSelectedExclusion] = useState(null);
  const [risksOptions, setRisksOptions] = useState([]);
  const [customContractsOptions, setCustomContractsOptions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [formData, setFormData] = useState({
    contractId: null,
    contractLabel: null,
    product: {
      value: {
        label: 'Ипотека',
        value: 'mortgage',
      },
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    risk: {
      value: {
        value: false,
        label: false,
      },
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    agentType: {
      value: {
        value: null,
        label: 'Для всех',
      },
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    channel: {
      value: '',
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    typeDeclaration: {
      value: null,
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    endDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    insurance: {
      value: false,
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    login: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: true,
    },
    commissionExclusions: {},
  });

  const makeLabelForKeyOptions = (customContractsOption) => {
    const channels = {
      both: 'Оба',
      b2b: 'B2B',
      b2c: 'B2C',
    };
    return `${customContractsOption.id}. ${customContractsOption.insurance.name}; канал - ${channels[customContractsOption.channel]}; ${customContractsOption.product.name}; Риск - ${customContractsOption.risk ? customContractsOption.risk.name : 'Общий'}; ${customContractsOption.agent.last_name} ${customContractsOption.agent.first_name} ${customContractsOption.agent.middle_name} - ${customContractsOption?.agent_id}; логин - ${customContractsOption.login}`;
  };

  const handleSetSelectedContract = (contract) => {
    const contractLabel = customContractsOptions.find(({ value }) => value.id === contract.id);
    const data = {
      ...formData,
      contractId: contract.id,
      contractLabel,
      product: {
        ...formData.product,
        value: {
          value: contract.product.code,
          label: contract.product.name,
        },
      },
      channel: {
        ...formData.channel,
        value: contract.channel,
      },
      insurance: {
        ...formData.insurance,
        value: {
          value: contract.insurance.code,
          label: contract.insurance.name,
        },
      },
      risk: {
        ...formData.risk,
        value: {
          value: contract.risk ? contract.risk.code : null,
          label: contract.risk ? contract.risk.name : 'Общий',
        },
      },
    };
    const risks = contract.product.object_insurance.map((value) => ({
      value: value.code,
      label: value.name,
    }));
    risks.push({
      value: null,
      label: 'Общий',
    });
    setRisksOptions(risks);
    setFormData(data);
  };

  function handleSetSelectedExclusionByTypeDeclaration(typeDeclaration) {
    const data = {
      ...formData,
      typeDeclaration: {
        ...formData.typeDeclaration,
        value: typeDeclarationOptions.find(({ value }) => value === typeDeclaration),
      },
    };
    setFormData(data);
  }

  const setSelectedDataFromTableClick = (contract, exclusion) => {
    const contractLabel = customContractsOptions.find(({ value }) => value.id === contract.id);
    const commission = {
      franch: exclusion?.commission?.franch,
      uk: exclusion?.commission?.uk,
    };
    const data = {
      ...formData,
      contractId: contract.id,
      contractLabel,
      product: {
        ...formData.product,
        value: {
          value: contract.product.code,
          label: contract.product.name,
        },
      },
      channel: {
        ...formData.channel,
        value: contract.channel,
      },
      insurance: {
        ...formData.insurance,
        value: {
          value: contract.insurance.code,
          label: contract.insurance.name,
        },
      },
      risk: {
        ...formData.risk,
        value: {
          value: exclusion.risk ? exclusion.risk.code : null,
          label: exclusion.risk ? exclusion.risk.name : 'Общий',
        },
      },
      agentType: {
        ...formData.agentType,
        value: exclusion.agent_type ? agentTypeOptions.find(({ value }) => value === exclusion.agent_type.code) : { value: null, label: 'Для всех' },
      },
      typeDeclaration: {
        ...formData.typeDeclaration,
        value: typeDeclarationOptions.find(({ value }) => value === exclusion.type_declaration),
      },
      startDate: {
        ...formData.startDate,
        value: exclusion?.start_date ? parseISO(exclusion.start_date) : null,
      },
      endDate: {
        ...formData.endDate,
        value: exclusion?.end_date ? parseISO(exclusion.end_date) : null,
      },
      commissionExclusions: commission,
    };
    setFormData(data);
    const risks = contract.product.object_insurance.map((value) => ({
      value: value.code,
      label: value.name,
    }));
    risks.push({
      value: null,
      label: 'Общий',
    });
    setSelectedExclusion(exclusion);
    setRisksOptions(risks);
    if (exclusion.region) {
      setSelectedRegion(regions.find(({ value }) => value === exclusion?.region?.code));
    }
  };

  const prepCommissionObjectData = (field) => {
    const data = {};
    if (field === 'commissionExclusions') {
      if (selectedBank.value === 'all' || selectedRegion.value === 'all') {
        (formData.product.value.value === 'mortgage' ? banks : regions).map((element) => element.value)
          .filter((element) => element !== 'all')
          .forEach((insurance) => {
            data[insurance] = {
              franch: parseFloat(formData[field].franch),
              uk: parseFloat(formData[field].uk),
            };
          });
      } else {
        Object.keys(formData[field])
          .forEach(() => {
            data[(selectedBank.value ? selectedBank.value : selectedRegion.value)] = {
              franch: parseFloat(formData[field].franch),
              uk: parseFloat(formData[field].uk),
            };
          });
      }
    }
    return data;
  };
  const saveExclusion = (e) => {
    e.preventDefault();
    const commissionExclusions = prepCommissionObjectData('commissionExclusions');
    if (Object.keys(commissionExclusions).length === 0) {
      showModalInfo('Заполните комиссию');
      return;
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/partnerCommission`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        custom_contract_id: formData.contractId,
        commissionExclusions,
        type_declaration: formData.channel.value === 'b2b' ? formData.typeDeclaration.value.value : null,
        for_agent: agent.id,
        product: formData.product.value.value,
        start_date: `${format(formData.startDate.value, 'yyyy-MM-dd')} 00:00:00`,
        end_date: formData.endDate.value ? `${format(formData.endDate.value, 'yyyy-MM-dd')} 00:00:00` : null,
        risk: formData.risk.value.value,
        agentType: formData.agentType.value.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setShowForm(false);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const updateExclusion = (e) => {
    e.preventDefault();
    const commissionExclusions = prepCommissionObjectData('commissionExclusions');
    if (Object.keys(commissionExclusions).length === 0) {
      showModalInfo('Заполните комиссию');
      return;
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/partnerCommission/${selectedExclusion.id}`, {
      method: 'put',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        end_date: `${format(formData.endDate.value, 'yyyy-MM-dd')} 00:00:00`,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          setShowForm(false);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const handleChangeQuotaFranch = (insurance, bank, e) => {
    const { value } = e.target;
    if (value > 100) {
      showModalInfo('Не может быть больше 100%');
      return;
    }
    setFormData((prev) => ({
      ...prev,
      commissionExclusions: {
        ...prev.commissionExclusions,
        franch: value,
      },
    }));
  };

  const handleChangeQuotaUk = (insurance, bank, e) => {
    const { value } = e.target;
    if (value > 100) {
      showModalInfo('Не может быть больше 100%');
      return;
    }
    setFormData((prev) => ({
      ...prev,
      commissionExclusions: {
        ...prev.commissionExclusions,
        uk: value,
      },
    }));
  };

  useEffect(() => {
    if (formData.insurance.value.value && formData.product.value.value === 'mortgage') {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      getBanks(token, formData.insurance.value.value)
        .then((response) => {
          const banksOptions = response.map((value) => ({
            value: value.bank.code,
            label: value.bank.name,
          }));
          banksOptions.push({
            value: 'all',
            label: 'Заполнить для всех',
          });
          setBanks(banksOptions);
        });
    }
  }, [formData.insurance.value]);

  useEffect(() => {
    const optList = customContracts.map((customContractsOption) => ({
      value: customContractsOption,
      label: makeLabelForKeyOptions(customContractsOption),
    }));
    setCustomContractsOptions(optList);
  }, []);

  useEffect(() => {
    if (tableSelectedExclusion.contract && customContractsOptions.length) {
      const currentContract = customContracts.find(({ id }) => id === tableSelectedExclusion.contract);
      const currentExclusion = exclusions.find(({ id }) => id === tableSelectedExclusion.exclusions);
      setSelectedDataFromTableClick(currentContract, currentExclusion);
    }
  }, [customContractsOptions]);

  useEffect(() => {
    if (tableSelectedExclusion.contract && banks.length) {
      const currentExclusion = exclusions.find(({ id }) => id === tableSelectedExclusion.exclusions);
      setSelectedBank(banks.find(({ value }) => value === currentExclusion?.bank?.code));
    }
  }, [banks]);

  return (
    <form onSubmit={saveExclusion}>
      <div>
        <a href="#" onClick={(e) => { e.preventDefault(); setShowForm(false); }} className="page_back_link mb-1"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К списку исключений</a>
      </div>
      <div className="form-group row">
        <div className="col-lg-9 col-sm-12">
          <label htmlFor="customContracts">Ключ интеграции</label>
          <Select
            classNamePrefix="react-select"
            inputId="customContracts"
            name="customContracts"
            placeholder="Ключ"
            isDisabled={tableSelectedExclusion.contract}
            noOptionsMessage={() => 'Не найдено'}
            styles={customStyles}
            value={formData.contractLabel}
            className={classnames('form-control-custom')}
            onChange={(e) => handleSetSelectedContract(e.value)}
            options={customContractsOptions}
          />
        </div>
      </div>
      {formData.channel.value === 'b2b' ? (
        <div className="form-group row">
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="typeDeclaration">Тип оформления</label>
            <Select
              required
              classNamePrefix="react-select"
              inputId="typeDeclaration"
              name="typeDecloration"
              placeholder="Тип оформления"
              noOptionsMessage={() => 'Не найдено'}
              styles={customStyles}
              className={classnames('form-control-custom')}
              value={formData.typeDeclaration.value}
              onChange={(e) => handleSetSelectedExclusionByTypeDeclaration(e.value)}
              options={typeDeclarationOptions}
              isDisabled={tableSelectedExclusion.exclusions}
            />
          </div>
        </div>
      ) : null}
      {(formData.channel.value !== 'b2b' || formData.typeDeclaration.value) && formData.contractId ? (
        <>
          <div className="form-group row">
            <div className="col-lg-3 col-sm-12">
              <label htmlFor="risk">Риск</label>
              <Select
                required
                classNamePrefix="react-select"
                inputId="risk"
                name="risk"
                placeholder="Риск"
                isDisabled={tableSelectedExclusion.exclusions}
                noOptionsMessage={() => 'Не найдено'}
                styles={customStyles}
                className={classnames('form-control-custom')}
                value={formData.risk.value}
                onChange={(e) => handleChange(e, 'risk', formData, setFormData)}
                options={risksOptions}
              />
            </div>
            <div className="col-lg-3 col-sm-12">
              <label htmlFor="agentType">Тип пользователя</label>
              <Select
                required
                classNamePrefix="react-select"
                inputId="agentType"
                name="agentType"
                placeholder="agentType"
                isDisabled={tableSelectedExclusion.exclusions}
                noOptionsMessage={() => 'Не найдено'}
                styles={customStyles}
                className={classnames('form-control-custom')}
                value={formData.agentType.value}
                onChange={(e) => handleChange(e, 'agentType', formData, setFormData)}
                options={agentTypeOptions}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3 col-sm-12">
              <label htmlFor="startDate">Дата начала действия</label>
              <DatePicker
                disabled={tableSelectedExclusion.exclusions}
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                required
                className={classnames('form-control', { error: formData.startDate.errorMessage })}
                selected={formData.startDate.value ? new Date(formData.startDate.value) : null}
                onChange={(e) => {
                  if (e > formData.endDate.value) {
                    handleChange(null, 'endDate', formData, setFormData);
                  }
                  handleChange(e, 'startDate', formData, setFormData);
                }}
                name="startDate"
                id="startDate"
                locale={ru}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              {formData.startDate.errorMessage && (
                <div className="validation-error">
                  {formData.startDate.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 col-sm-12">
              <label htmlFor="endDate">Дата окончания действия</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                className={classnames('form-control', { error: formData.startDate.errorMessage })}
                selected={formData.endDate.value ? new Date(formData.endDate.value) : null}
                onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
                name="endDate"
                id="endDate"
                minDate={formData.startDate.value ? new Date(formData.startDate.value) : null}
                locale={ru}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                disabled={formData.endDate.value}
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              {formData.endDate.errorMessage && (
                <div className="validation-error">
                  {formData.endDate.errorMessage}
                </div>
              )}
            </div>
          </div>
          {(formData.product.value.value === 'mortgage' && banks.length > 0) ? (
            <>
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="bank">Банк</label>
                  <Select
                    required
                    classNamePrefix="react-select"
                    inputId="bank"
                    name="bank"
                    isDisabled={tableSelectedExclusion.exclusions}
                    placeholder="Банк"
                    noOptionsMessage={() => 'Не найдено'}
                    styles={customStyles}
                    value={selectedBank}
                    className={classnames('form-control-custom')}
                    onChange={(e) => setSelectedBank(e)}
                    options={banks}
                  />
                </div>
              </div>
              {
                banks.map((bank) => (
                  <React.Fragment key={bank.value}>
                    <div className={selectedBank.value === bank.value ? 'form-group row  d-flex flex-row' : 'd-none'}>
                      <div className="col-lg-3 col-sm-12">
                        <label htmlFor="">Квота пользователя</label>
                        <CommissionInput
                          disabled={tableSelectedExclusion.exclusions}
                          handleInputCommission={handleChangeQuotaFranch}
                          value={formData.commissionExclusions ? formData.commissionExclusions.franch : ''}
                          region={bank.value}
                          company={formData.insurance.value}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <label htmlFor="">Квота партнера</label>
                        <CommissionInput
                          disabled={tableSelectedExclusion.exclusions}
                          handleInputCommission={handleChangeQuotaUk}
                          value={formData.commissionExclusions ? formData.commissionExclusions.uk : ''}
                          region={bank.value}
                          company={formData.insurance.value}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))
              }
            </>
          ) : null}
          {(formData.product.value.value !== 'osago' && formData.product.value.value !== 'mortgage') ? (
            <>
              <div className="form-group row">
                <label className="col-lg-3 col-sm-12">Россия</label>
              </div>
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="">Квота пользователя</label>
                  <CommissionInput
                    handleInputCommission={handleChangeQuotaFranch}
                    value={formData.commissionExclusions.other ? formData.commissionExclusions.other.franch : ''}
                    region="other"
                    company={formData.insurance.value}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="">Квота партнера</label>
                  <CommissionInput
                    handleInputCommission={handleChangeQuotaUk}
                    value={formData.commissionExclusions.other ? formData.commissionExclusions.other.uk : ''}
                    region="other"
                    company={formData.insurance.value}
                  />
                </div>
              </div>
            </>
          ) : null}
          {formData.product.value.value === 'osago' ? (
            <>
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="region">Регион</label>
                  <Select
                    required
                    classNamePrefix="react-select"
                    inputId="region"
                    name="region"
                    isDisabled={tableSelectedExclusion.exclusions}
                    value={selectedRegion}
                    placeholder="Регион"
                    noOptionsMessage={() => 'Не найдено'}
                    styles={customStyles}
                    className={classnames('form-control-custom')}
                    onChange={(e) => setSelectedRegion(e)}
                    options={regions}
                  />
                </div>
              </div>
              {
                regions.map((region) => (
                  <React.Fragment key={region.value}>
                    <div className={selectedRegion.value === region.value ? 'form-group row  d-flex flex-row' : 'd-none'}>
                      <div className="col-lg-3 col-sm-12">
                        <label htmlFor="">Квота пользователя</label>
                        <CommissionInput
                          disabled={tableSelectedExclusion.exclusions}
                          handleInputCommission={handleChangeQuotaFranch}
                          value={formData.commissionExclusions ? formData.commissionExclusions.franch : ''}
                          region={region.value}
                          company={formData.insurance.value}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <label htmlFor="">Квота партнера</label>
                        <CommissionInput
                          disabled={tableSelectedExclusion.exclusions}
                          handleInputCommission={handleChangeQuotaUk}
                          value={formData.commissionExclusions ? formData.commissionExclusions.uk : ''}
                          region={region.value}
                          company={formData.insurance.value}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))
              }
            </>
          ) : null}
        </>
      ) : null}
      {!selectedExclusion
        ? (
          <>
            <button type="submit" className="btn btn-success mr-2">Добавить</button>
            <button type="button" className="btn btn-secondary" onClick={() => setShowForm(false)}>Отмена</button>
          </>
        ) : <button type="button" className="btn btn-success" onClick={(e) => updateExclusion(e)}>Сохранить</button>}
    </form>
  );
}
