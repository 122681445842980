import React from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../Stores/UserInfoStore';
import CustomIcon from './CustomIcon';

const Link = styled.a`
    max-width: max-content;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #737373;
    margin-top: 8px;
    margin-bottom: 16px;
    transition: color 0.3s;
    cursor: pointer;

    @include vp--1023 {
        margin-top: 14px;
        margin-left: 6px;
        margin-bottom: 16px;
    }

    @include vp--767 {
        margin-top: 24px;
        margin-left: 0;
        margin-bottom: 24px;
    }

    svg {
        color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#72c367')};
        margin-left: -3px;
        min-width: 16px;
        height: 16px;
    }

    span {
        margin-left: 4px;
    }

    &:hover {
        color: #3d3d3b;
    }
`;

function LinkUnderLine(props) {
  const { children, onClick } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <Link theme={theme} onClick={onClick}>
      <CustomIcon pureSvg icon="chevronLeft" />
      <span>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </span>
    </Link>
  );
}

export default LinkUnderLine;
