import React, {
  forwardRef, useState, useImperativeHandle, useEffect, useRef, useMemo, useContext,
} from 'react';
import MultiRef from 'react-multi-ref';
import Modal from 'react-responsive-modal';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import { faRedoAlt } from '@fortawesome/fontawesome-free-solid';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  toLocalDate,
  customFetch,
  setTableSorting,
  getDefaultSorting,
  setTableSettings7,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Table from '../Layout/Table';

function InterfaceTourAgentFlagsTable(props, ref) {
  const {
    agentsFlagsList, type, setAgentsFlagsList,
  } = props;

  const localStorageKey = useMemo(() => (type === 'tour' ? 'interfaceTourAgentsFlagsListSettings' : 'interfaceTourOneAgentFlagsListSettings'), []);

  const [loading, setLoading] = useState(false);
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [defaultSorting, setDefaultSorting] = useState([{
    field: 'created_at',
    order: 'true',
  }]);
  const [flushId, setFlushId] = useState(null);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const simulateClick = new MultiRef();

  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
  }));

  const flushFlag = (id) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/${id}/flush`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newAgentsFlagsList = agentsFlagsList.map((tour) => {
            if (id === tour.id) {
              return {
                ...tour,
                id: null,
              };
            }
            return tour;
          });
          setAgentsFlagsList(newAgentsFlagsList);
        } else {
          showModalInfo(response.error, 'error');
        }
      });
  };

  useEffect(() => {
    if (flushId) {
      flushFlag(flushId);
      setFlushId(null);
    }
  }, [flushId]);

  const updateTableColumns = () => {
    const newColumns = [{
      Header: 'Дата прохождения тура',
      accessor: 'updated_at',
      disableFilters: true,
      minWidth: 150,
      width: reactLocalStorage.getObject(localStorageKey).updated_at,
      Cell: ({ row }) => <div>{row.original.id ? format(toLocalDate(parseISO(row.original.updated_at)), 'yyyy-MM-dd, HH:mm:ss') : '-' }</div>,
      show: reactLocalStorage.getObject(localStorageKey).show_updated_at !== false,
      showCustomized: true,
    }, {
      id: 'finished_flag',
      Header: 'Флаг прохождения демо-тура',
      Filter: 'selectColumnFilter',
      filter: 'strictEqualSelectFilterFn',
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.id) {
          return (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        if (!row.original.id) {
          return (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        return 'Без статуса';
      },
      accessor: (d) => d.id ? 'Пройден' : 'Не пройден',
      show: reactLocalStorage.getObject(localStorageKey).show_finished_flag !== false,
      showCustomized: true,
    }, {
      Header: 'Сбросить флаг',
      width: 50,
      id: 'flush',
      disableFilters: true,
      show: true,
      Cell: ({ row }) => (
        <div title="Сбросить флаг" className="flush-button"><FontAwesomeIcon onClick={() => { if (row.original.id) { setFlushId(row.original.id); } }} icon={faRedoAlt} className="fa-fw" /></div>
      ),
    }];
    if (type === 'tour') {
      newColumns.unshift({
        Header: 'ФИО пользователя',
        accessor: 'agentFio',
        minWidth: 150,
        width: reactLocalStorage.getObject(localStorageKey).agentFio,
        show: reactLocalStorage.getObject(localStorageKey).show_agentFio !== false,
        showCustomized: true,
      });
    } else {
      newColumns.unshift({
        Header: 'Наименование демо-тура',
        accessor: 'name',
        minWidth: 150,
        width: reactLocalStorage.getObject(localStorageKey).name,
        show: reactLocalStorage.getObject(localStorageKey).show_name !== false,
        showCustomized: true,
      });
    }
    setColumns(newColumns);
  };

  const handleTableCustomizedSetAll = () => {
    simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  const handleTableCustomizedSetNone = () => {
    simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  const handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columnsArray = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject(localStorageKey);
    columnsArray.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject(localStorageKey, settings);
    setFlagModalTableCustomized(false);
  };

  const customizedColumns = columns.map((column) => {
    const id = column.id ? column.id : column.accessor;
    if (column.showCustomized) {
      return (
        <li key={id} className="form-check">
          <input ref={simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
          <label className="form-check-label" htmlFor={id}>{column.Header}</label>
        </li>
      );
    }
    return null;
  });

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    window.history.replaceState(null, null, queryParams);
  };

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef.current) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    updateTableColumns();
    const defaultSorting = [getDefaultSorting('interfaceToursListSettings')];
    setDefaultSorting(defaultSorting);
  }, []);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={handleSubmitTableCustomized} id="tableCustomized">
          <ul className="two-column-list">
            {customizedColumns}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <Table
        data={agentsFlagsList}
        columns={columns}
        loading={loading}
        noDataText="Демо-туры не найдены"
        defaultFiltered={filtered}
        defaultSorted={defaultSorting}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, localStorageKey);
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, localStorageKey);
        }}
        onFilteredChange={(filtered) => onFilteredChangeCustom(filtered)}
      />
    </>
  );
}

export default forwardRef(InterfaceTourAgentFlagsTable);
