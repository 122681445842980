import styled from 'styled-components';
import React, { useState } from 'react';
import { hideAll } from 'tippy.js';
import { XCircleIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { shallow } from 'zustand/shallow';
import { SlideDown } from 'react-slidedown';
import CustomIcon from '../CustomIcon';
import TableResultAnswers from './TableResultAnswers';
import Button from '../Button';
import {
  ModalContainerMultiply,
  ModalContainerMultiplyBlock, ModalContainerMultiplyBlockList,
  ModalContainerMultiplyBlockPopup, ModalContainerMultiplyBlockTitle,
} from './PageTopAnswers';
import TableTbody from './TableTbody';
import TableThead from './TableThead';
import TableTheadTr from './TableTheadTr';
import TableTheadTh from './TableTheadTh';
import TableTbodyTr from './TableTbodyTr';
import TableTbodyTd from './TableTbodyTd';
import TableAnswersAmount, {
  TableAnswersAmountContainerDiscount,
  TableAnswersAmountContainerRef,
  TableAnswersAmountContainerValue,
} from './TableAnswersAmount';
import useUserInfoStore from '../../Stores/UserInfoStore';

export const TableContainer = styled.table`
    width: 100%;
    border-collapse: collapse;
    @media (max-width: 1023px) {
        display: block;
    }
`;

export const TableContainerWrapper = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
  box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    @media (max-width: 767px) {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: transparent;
    }
`;

export const ContainerImgAnswerslTable = styled.div`
    background-image: url(${(props) => (props.img || '')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 12px 0 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`;

export default function TableAnswers(props) {
  const {
    disabled, width, integrationAnswers,
  } = props;
  const [openTab, setOpenTap] = useState({});
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const changeTab = (index) => {
    const newTab = { ...openTab };
    newTab[index] = !newTab[index];
    setOpenTap(newTab);
  };

  return (
    <TableContainerWrapper>
      <TableContainer disabled={disabled} width={width}>
        <TableThead>
          <TableTheadTr>
            <TableTheadTh block="name"><div><span>Страховая компания</span></div></TableTheadTh>
            <TableTheadTh block="value"><div><span>Стоимость</span></div></TableTheadTh>
            <TableTheadTh block="sum"><div><span>Вознаграждение</span></div></TableTheadTh>
          </TableTheadTr>
        </TableThead>
        <TableTbody>
          {integrationAnswers.map(((item, index) => (
            <TableTbodyTr active={openTab[index]}>
              <TableTbodyTd block="name">
                <div>
                  <ContainerImgAnswerslTable img={`${process.env.REACT_APP_BACKEND_DOMAIN}/${item.mini_logo}`} />
                  <span>{item.companyName}</span>
                </div>
              </TableTbodyTd>
              {item.price ? (
                <TableTbodyTd block="val">
                  <TableAnswersAmount>
                    <TableAnswersAmountContainerValue><span>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.price)}</span></TableAnswersAmountContainerValue>
                    {item.coefficients ? (
                      <Tippy
                        style
                        placement="bottom-start"
                        interactive
                        trigger="click"
                        className="page-popup page-popup--agents-list page-popup-user-notifications page-popup_auto-width"
                        arrow={false}
                        offset={[0, 12]}
                        content={(
                          <ModalContainerMultiply>
                            <Button type="button" onClick={hideAll}><XCircleIcon height={24} color="#a3a3a3" /></Button>
                            <ModalContainerMultiplyBlockPopup>
                              <ModalContainerMultiplyBlock>
                                <ModalContainerMultiplyBlockTitle>Коэффициенты</ModalContainerMultiplyBlockTitle>
                                <ModalContainerMultiplyBlockList>
                                  {item.coefficients.tb ? (<li><span className="term">ТБ: </span><span className="def">{item.coefficients.tb}</span></li>) : null}
                                  {item.coefficients.kbm ? (<li><span className="term">КБМ: </span><span className="def">{item.coefficients.kbm}</span></li>) : null}
                                  {item.coefficients.kvs ? (<li><span className="term">КВС: </span><span className="def">{item.coefficients.kvs}</span></li>) : null}
                                  {item.coefficients.ks ? (<li><span className="term">КС: </span><span className="def">{item.coefficients.ks}</span></li>) : null}
                                  {item.coefficients.kp ? (<li><span className="term">КП: </span><span className="def">{item.coefficients.kp}</span></li>) : null}
                                  {item.coefficients.km ? (<li><span className="term">КМ: </span><span className="def">{item.coefficients.km}</span></li>) : null}
                                  {item.coefficients.kn ? (<li><span className="term">КН: </span><span className="def">{item.coefficients.kn}</span></li>) : null}
                                  {item.coefficients.ko ? (<li><span className="term">КО: </span><span className="def">{item.coefficients.ko}</span></li>) : null}
                                  {item.coefficients.kt ? (<li><span className="term">КТ: </span><span className="def">{item.coefficients.kt}</span></li>) : null}
                                  {item.coefficients.kpr ? (<li><span className="term">КПР: </span><span className="def">{item.coefficients.kpr}</span></li>) : null}
                                </ModalContainerMultiplyBlockList>
                              </ModalContainerMultiplyBlock>
                            </ModalContainerMultiplyBlockPopup>
                          </ModalContainerMultiply>
                      )}
                      >
                        <div>
                          <Button type="button"><CustomIcon icon="iconMath" width="14px" height="14px" pureSvg /></Button>
                        </div>
                      </Tippy>
                    ) : null}
                    <TableAnswersAmountContainerRef>Стоимость</TableAnswersAmountContainerRef>
                  </TableAnswersAmount>
                </TableTbodyTd>
              ) : null}
              {item.price ? (
                <TableTbodyTd block="sum">
                  <TableAnswersAmount block="commission">
                    <TableAnswersAmountContainerValue><span>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((item.commission * item.price) / 100)}</span></TableAnswersAmountContainerValue>
                    <TableAnswersAmountContainerDiscount>
                      <span className="text">КВ</span>
                      <span className="bracket">(</span>
                      <span className="value">{item.commission}</span>
                      <span className="unit">%</span>
                      <span className="bracket">)</span>
                    </TableAnswersAmountContainerDiscount>
                  </TableAnswersAmount>
                </TableTbodyTd>
              ) : null}
              {!item.price ? (<TableTbodyTd block="desc"><span>{Object.values(item.integrations).find((integration) => integration.errorMessage) ? Object.values(item.integrations).find((integration) => integration.errorMessage).errorMessage : 'Отказ'}</span></TableTbodyTd>) : null}
              {userInfo.full_time_employee ? (
                <TableTbodyTd block="admin-btn" active={openTab[index]}>
                  <Button type="button" onClick={() => changeTab(index)} className={`${openTab[index] ? 'is-active' : ''}`}>
                    <span className="title">Подробности</span>
                    <span className={`icon-desk ${openTab[index] ? 'rotate-desk' : ''}`}><CustomIcon icon="chevronDown" width="14px" height="14px" pureSvg /></span>
                    <span className="icon-mob"><CustomIcon icon="icon-info-gray" width="14px" height="14px" pureSvg /></span>
                  </Button>
                </TableTbodyTd>
              ) : null}
              {openTab[index] ? (
                <SlideDown closed={!openTab[index]} className="w-100">
                  <TableTbodyTd block="results">
                    <TableResultAnswers resultsData={item} headerText={item.companyName} onClick={changeTab} indexElement={index} />
                  </TableTbodyTd>
                </SlideDown>
              ) : null}
            </TableTbodyTr>
          )))}
        </TableTbody>
      </TableContainer>
    </TableContainerWrapper>
  );
}
