import React from 'react';
import DashboardTopWrapper from '../Layout/Dashboard/DashboardTopWrapper';
import DashboardMainInfo from '../Layout/Dashboard/DashboardMainInfo';
import DashboardProgressPercent from '../Layout/Dashboard/DashboardProgressPercent';
import DashboardWrapperFooterText from '../Layout/Dashboard/DashboardWrapperFooterText';
import DashboardCardBig from '../Layout/Dashboard/DashboardCardBig';
import PeopleLink from '../../img/people-link.svg';
import DashboardCardBigList from '../Layout/Dashboard/DashboardCardBigList';
import DashboardCardListItem from '../Layout/Dashboard/DashboardCardListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/fontawesome-free-solid';

export default function DashboardCardAgentsBoard(props) {
  const {
    titleBoard,
    listDashboardData,
    mainInfoData,
    mainInfoTitle,
    footerText,
  } = props;

  return (
    <DashboardCardBig width={mainInfoData.width}>
      <DashboardTopWrapper width="38%">
        <h2>{titleBoard}</h2>
        <DashboardMainInfo>
          <p>{mainInfoTitle}</p>
          <DashboardProgressPercent
            rotate={mainInfoData.rotate}
            iconBackground={mainInfoData.iconBackground}
            iconColor={mainInfoData.iconColor}
          >
            {mainInfoData.icon}
            <p>{mainInfoData.title} {mainInfoData.mark ?? '%'}</p>
          </DashboardProgressPercent>
        </DashboardMainInfo>
        <DashboardWrapperFooterText>{footerText}</DashboardWrapperFooterText>
        <div className="big-icon"><img src={PeopleLink} alt="" className="icon" /></div>
      </DashboardTopWrapper>
      <DashboardCardBigList>
        {listDashboardData.map((item) => (
          <DashboardCardListItem>
            <div className="block">
              <p className="block-text">{item.title}</p>
              <div className="increase">
                <p className="text">{item.value}</p>
                {!item.disabled ? (
                  <DashboardProgressPercent
                    rotate={item.rotate}
                    iconBackground={item.iconBackground}
                    iconColor={item.iconColor}
                  >
                    {item.icon}
                    <p>{item.valueIcon === Infinity ? (<FontAwesomeIcon icon={faInfinity} />) : item.valueIcon}{item.mark ?? '%'}</p>
                  </DashboardProgressPercent>
                ) : null}
              </div>
            </div>
          </DashboardCardListItem>
        ))}
      </DashboardCardBigList>
    </DashboardCardBig>
  );
}
