import React from 'react';
import {
  handleChange,
} from '../../Utils/Helpers';

export default function CargoOptionsForm(props) {
  const { formData, setFormData, handleValidate } = props;

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h3 className="mortgage-options-header">
              Дополнительные сведения
            </h3>
            <ul className="mortgage-options">
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    checked={formData.usedcargo.value}
                    name="usedcargo"
                    onClick={handleValidate}
                    type="checkbox"
                    onChange={(e) => handleChange(e, 'usedcargo', formData, setFormData)}
                    id="usedcargo"
                  />
                  <span className="slider round" />
                </label>
                <span className="switch-text">Б\У груз</span>
              </li>
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    checked={formData.loadunload.value}
                    name="loadunload"
                    onClick={handleValidate}
                    type="checkbox"
                    onChange={(e) => handleChange(e, 'loadunload', formData, setFormData)}
                    id="loadunload"
                  />
                  <span className="slider round"/>
                </label>
                <span className="switch-text">Разгрузка\Погрузка</span>
              </li>
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    checked={formData.refrisks.value}
                    name="refrisks"
                    onClick={handleValidate}
                    type="checkbox"
                    onChange={(e) => handleChange(e, 'refrisks', formData, setFormData)}
                    id="refrisks"
                  />
                  <span className="slider round"/>
                </label>
                <span className="switch-text">Реф.риски</span>
              </li>
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    checked={formData.overload.value}
                    name="overload"
                    onClick={handleValidate}
                    type="checkbox"
                    onChange={(e) => handleChange(e, 'overload', formData, setFormData)}
                    id="refrisks"
                  />
                  <span className="slider round"/>
                </label>
                <span className="switch-text">Несколько пунктов перегрузки</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
