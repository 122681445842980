import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const Text = styled.div`
`;

const ButtonWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 15px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(143,143,143,.08);    border-radius: 8px;
    ${(props) => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}` : '')};
    ${(props) => (props.offsetTop ? `margin-top: ${props.offsetTop}` : '')};

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: ${(props) => {
      if (props.type === 'ok') {
        return '#0bd95d';
      }
      if (props.type === 'error') {
        return 'red';
      }
      return '#f90';
    }};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export default function FormNotificationColorLine(props) {
  const {
    children,
    type,
    buttonText,
    buttonOnClick,
    offsetBottom,
    offsetTop,
  } = props;

  return (
    <Wrapper type={type} offsetBottom={offsetBottom} offsetTop={offsetTop}>
      <Text>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </Text>
      {buttonText ? (
        <ButtonWrapper>
          <Button onClick={buttonOnClick} type="border">{buttonText}</Button>
        </ButtonWrapper>
      ) : null}
    </Wrapper>
  );
}
