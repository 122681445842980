import React from 'react';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

const WidgetAnalyticsIntegrationVkAds = ({
  showMenu,
  vkAdsId,
  changeWidgetAnalyticsIntegrationId,
  updateWidgetWithInfo,
  widget,
}) => (
  <div className="position-relative">
    <div className="form-group row">
      <div className="col-12">
        <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку систем аналитики</LinkBreadcrumbs>
      </div>
    </div>
    <div className="card">
      <div className="form-group row">
        <div className="col-lg-12">
          <FormNotificationColorLine>
            <p>Для корректной работы необходимо указать идентификатор отслеживания и добавить следующие цели в настройках вашего аккаунта Vk Ads:</p>
            <ul>
              {widget.policy_type.code === 'osago' ? (
                <>
                  <li><strong>Телефон прошел валидацию:</strong> phoneValidate_widget</li>
                  <li><strong>Старт расчета:</strong> createQuote_widget</li>
                  <li><strong>Автомобиль заполнен:</strong> carFilled_widget</li>
                  <li><strong>Страхователь заполнен:</strong> insuranceFilled_widget</li>
                  <li><strong>Собственник заполнен:</strong> ownerFilled_widget</li>
                  <li><strong>Водитель заполнен:</strong> driverFilled_widget</li>
                  <li><strong>Получены результаты:</strong> getResult_widget</li>
                  <li><strong>Выбор страховой:</strong> chooseInsurance_widget</li>
                  <li><strong>Попытка покупки:</strong> buyPolicy_widget</li>
                  <li><strong>Полис успешно оплачен:</strong> acceptPayment_widget</li>
                </>
              ) : null}
              {widget.policy_type.code === 'mortgage' ? (
                <>
                  <li><strong>Телефон прошел валидацию:</strong> phoneValidate_widgetMortgage</li>
                  <li><strong>Старт расчета:</strong> createQuote_widgetMortgage</li>
                  <li><strong>Получены результаты:</strong> getResult_widgetMortgage</li>
                  <li><strong>Выбор страховой:</strong> chooseInsurance_widgetMortgage</li>
                  <li><strong>Попытка покупки:</strong> buyPolicy_widgetMortgage</li>
                  <li><strong>Полис успешно оплачен:</strong> acceptPayment_widgetMortgage</li>
                </>
              ) : null}
              {widget.policy_type.code === 'greenCard' ? (
                <>
                  <li><strong>Получены результаты:</strong> getResult_widget_greenCard</li>
                  <li><strong>Выбор страховой:</strong> chooseInsurance_widget_greenCard</li>
                  <li><strong>Отправка заявки:</strong> buyPolicy_widget_greenCard</li>
                  <li><strong>Полис успешно оплачен:</strong> acceptPayment_widget_greenCard</li>
                </>
              ) : null}
              {widget.policy_type.code === 'dkp' ? (
                <>
                  <li><strong>Переход к оформлению ДКП:</strong> checkDocumentData_widgetDKP</li>
                  <li><strong>Оформление ДКП:</strong> getDocument_widgetDKP</li>
                </>
              ) : null}
              {widget.policy_type.code === 'kbm' ? (
                <>
                  <li><strong>Получен результат проверки КБМ:</strong> getKbm_widgetKbm</li>
                  <li><strong>Отправлен запрос на восстановление КБМ:</strong> refreshKbm_widgetKbm</li>
                  <li><strong>Переход к оформлению ОСАГО:</strong> getOsago_widgetKbm</li>
                </>
              ) : null}
              {widget.policy_type.code === 'property' ? (
                <>
                  <li><strong>Получен результат проверки КБМ:</strong> getKbm_widgetProperty</li>
                  <li><strong>Отправлен запрос на восстановление КБМ:</strong> refreshKbm_widgetProperty</li>
                </>
              ) : null}
              {widget.policy_type.code === 'dmsStudent' ? (
                <>
                  <li><strong>Получены результаты:</strong> getResult_widgetDmsStudent</li>
                  <li><strong>Выбор страховой:</strong> chooseInsurance_widgetDmsStudent</li>
                  <li><strong>Отправка заявки:</strong> buyPolicy_widgetDmsStudent</li>
                  <li><strong>Полис успешно оплачен:</strong> acceptPayment_widgetDmsStudent</li>
                </>
              ) : null}
            </ul>
          </FormNotificationColorLine>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <label htmlFor="widgetGoogleId">Идентификатор отслеживания в Vk Ads</label>
          <input
            value={vkAdsId}
            onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'vkAdsId')}
            onBlur={updateWidgetWithInfo}
            name="widgetVkAdsId"
            type="text"
            className="form-control"
            id="widgetVkAdsId"
          />
        </div>
      </div>
    </div>
  </div>
);

export default WidgetAnalyticsIntegrationVkAds;
