import React, {
  useContext, useEffect, useState,
} from 'react';
import classnames from 'classnames';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../Utils/Helpers';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import useUserInfoStore from '../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

export default function BonusesEvents(props) {
  const { history, toggle } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [events, setEvents] = useState({
    mortgage: false,
    self_employed: false,
    active_agents: false,
    ifl: false,
  });

  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const checkBonusesEvents = () => {
    setLoadingFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/bonuses/events`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setEvents(response);
        } else {
          showModalInfo('Ошибка при загрузке акций');
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setLoadingFlag(false);
      });
  };

  useEffect(() => {
    if (userInfo.id) {
      checkBonusesEvents();
    }
  }, [userInfo.id]);

  return (
    <LoadingBanner loadingFlag={loadingFlag}>
      <h2 className="page-wrapper__sub-title">Акции</h2>
      <ul className="events-grid events-grid--stock">
        {events.ifl !== false ? (
          <li className="events-grid__event">
            <div className={classnames('event', { 'event--active': events.ifl === true })}>
              <div className="event__achieve">
                <div className="event__achieve-title">+ 300 ₽</div>
              </div>
              <h3 className="event__achieve-head">300 ₽ за полис страхования имущества</h3>
              <p className="event__achieve-text">Оформите первый полис страхования имущества и получите 300 ₽ на ваш счет.</p>
              {events.ifl === true ? (
                <div className="event__active-label">
                  <div className="label">
                    <div className="label__title-wrapper">
                      <span className="label__title">Выполнено</span>
                      <svg width="40" height="40" aria-hidden="true">
                        <svg id="icon-check" viewBox="0 0 20 20" width="100%" height="100%">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="currentcolor" />
                        </svg>
                      </svg>
                    </div>
                  </div>
                </div>
              ) : false}
            </div>
          </li>
        ) : null}
        {events.self_employed !== false ? (
          <li className="events-grid__event">
            <div className={classnames('event', { 'event--active': events.self_employed === true })}>
              <div className="event__achieve event__achieve--big">
                <div className="event__achieve-title">+ 500 ₽</div>
              </div>
              <h3 className="event__achieve-head">500 ₽ всем самозанятым</h3>
              <p className="event__achieve-text">Перейдите на статус самозанятого, оформите три полиса ОСАГО и получите 500 ₽ на ваш счет.</p>
              <div className="event__stock-btn">
                <button disabled={events.self_employed === true} className="btn btn--green-border" type="button">
                  <span className="btn__title" onClick={() => history.push('profile')}>Сменить статус</span>
                </button>
              </div>
              {events.self_employed === true ? (
                <div className="event__active-label">
                  <div className="label">
                    <div className="label__title-wrapper">
                      <span className="label__title">Выполнено</span>
                      <svg width="40" height="40" aria-hidden="true">
                        <svg id="icon-check" viewBox="0 0 20 20" width="100%" height="100%">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="currentcolor" />
                        </svg>
                      </svg>
                    </div>
                  </div>
                </div>
              ) : false}
            </div>
          </li>
        ) : null}
        {events.active_agents !== false ? (
          <li className="events-grid__event">
            <div className={classnames('event', { 'event--active': events.active_agents === true })}>
              <div className="event__achieve event__achieve--big">
                <div className="event__achieve-title">+ 1000 ₽</div>
              </div>
              <div className="event__load-bar">
                <div className="progress-scale">
                  <div className="progress-scale__description">
                    <span className="progress-scale__text">Активно</span>
                    <span className="progress-scale__value"> {events.active_agents} из 10</span>
                  </div>
                  <div className="progress-scale__bar">
                    <div className="progress-scale__bg-wrapper">
                      <div className="progress-scale__bg" style={{ maxWidth: `${events.active_agents * 100 / 10}%` }} />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="event__achieve-head">1000 ₽ за приглашение пользователя</h3>
              <p className="event__achieve-text">Пригласи 10 активных пользователей получи 1000 ₽ на баланс. Пользователь считается активным, если продает от 2-ух полисов в месяц.</p>
              <div className="event__stock-btn">
                <button disabled={events.self_employed === true} className="btn btn--green-border" type="button">
                  <span className="btn__title" onClick={() => toggle('2')}>Реферальная программа</span>
                </button>
              </div>
              {events.active_agents === true ? (
                <div className="event__active-label">
                  <div className="label">
                    <div className="label__title-wrapper">
                      <span className="label__title">Выполнено</span>
                      <svg width="40" height="40" aria-hidden="true">
                        <svg id="icon-check" viewBox="0 0 20 20" width="100%" height="100%">
                          <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="currentcolor" />
                        </svg>
                      </svg>
                    </div>
                  </div>
                </div>
              ) : false}
            </div>
          </li>
        ) : null}
      </ul>
    </LoadingBanner>
  );
}
