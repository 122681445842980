import React, {
  useState,
  forwardRef,
} from 'react';
import AgentEnforcementProceedingsTable from './AgentEnforcementProceedingsTable';
import AgentEnforcementProceedingsForm from './AgentEnforcementProceedingsForm';
import AgentEnforcementProceedingsHistoryTable from './AgentEnforcementProceedingsHistoryTable';

function AgentEnforcementProceedings(props) {
  const {
    agent, tab, history,
  } = props;
  const [showForm, setShowForm] = useState(false);
  const [selectedEnforcementProceeding, setSelectedEnforcementProceeding] = useState(false);

  return (
    showForm ? (
      <>
        <AgentEnforcementProceedingsForm selectedEnforcementProceeding={selectedEnforcementProceeding} setSelectedEnforcementProceeding={setSelectedEnforcementProceeding} agent={agent} setShowForm={setShowForm} />
        <AgentEnforcementProceedingsHistoryTable history={history} selectedEnforcementProceeding={selectedEnforcementProceeding} tab={tab} />
      </>
    ) : (
      <>
        <button type="button" className="btn btn-success mb-3" onClick={() => setShowForm(true)}>Добавить</button>
        <AgentEnforcementProceedingsTable setSelectedEnforcementProceeding={setSelectedEnforcementProceeding} setShowForm={setShowForm} history={history} agent={agent} tab={tab} />
      </>
    )
  );
}

export default forwardRef(AgentEnforcementProceedings);
