import React from 'react';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

export default function MortgageOptionsForm(props) {
  const { formData, toggleFlag, draftInfo } = props;

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h3 className="mortgage-options-header">
              Дополнительные сведения
              <Tippy
                className="tippy-custom"
                arrow={false}
                offset={[0, 5]}
                content={(
                  <div>Эти сведения позволяют подобрать страховую компанию, которая сможет оформить полис. Например, некоторые компании не берут на страхование квартиры в газифицированных домах,
                    некоторые не могут оформить полис, если ещё не получен номер кредитного договора
                  </div>
                )}
                hideOnClick={false}
              >
                <div className="mortgage-options-header__icon">
                  <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw mr-2 d-none d-lg-block" />
                </div>
              </Tippy>
            </h3>
            <ul className="mortgage-options">
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    onChange={toggleFlag}
                    checked={!formData.creditAgreementFlag.value}
                    name="creditAgreementFlag"
                    type="checkbox"
                    id="creditAgreementFlag"
                  />
                  <span className="slider round" />
                </label>
                <span className="switch-text">Нет номера кредитного договора</span>
              </li>
              {formData.bank.value.value !== 'sberbank' ? (
                <li className="mortgage-options__item">
                  <label className="switch">
                    <input
                      onChange={toggleFlag}
                      checked={!formData.ownership.value}
                      name="ownership"
                      type="checkbox"
                      id="ownership"
                    />
                    <span className="slider round" />
                  </label>
                  <span className="switch-text">Нет права собственности</span>
                </li>
              ) : null}
              {draftInfo.type === 'mortgage' || draftInfo.type === 'mortgageComplex' ? (
                <>
                  <li className="mortgage-options__item">
                    <label className="switch">
                      <input
                        onChange={toggleFlag}
                        checked={formData.hasGasFlag.value}
                        name="hasGasFlag"
                        type="checkbox"
                        id="hasGasFlag"
                      />
                      <span className="slider round" />
                    </label>
                    <span className="switch-text">Есть газосодержащие коммуникации</span>
                  </li>
                  <li className="mortgage-options__item">
                    <label className="switch">
                      <input
                        onChange={toggleFlag}
                        checked={formData.fireDangerFlag.value}
                        name="fireDangerFlag"
                        type="checkbox"
                        id="fireDangerFlag"
                      />
                      <span className="slider round" />
                    </label>
                    <span className="switch-text">Есть деревянные перекрытия</span>
                  </li>
                  <li className="mortgage-options__item">
                    <label className="switch">
                      <input
                        onChange={toggleFlag}
                        checked={formData.usingForRent.value}
                        name="usingForRent"
                        type="checkbox"
                        id="usingForRent"
                      />
                      <span className="slider round" />
                    </label>
                    <span className="switch-text">Сдаётся в аренду</span>
                  </li>
                </>
              ) : null}
            </ul>
            {!formData.creditAgreementFlag.value ? (
              <SimpleNotification offsetTop="1rem">После заключения кредитного договора впишите его номер в страховой полис</SimpleNotification>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
