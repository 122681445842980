import React, {
  useEffect, useMemo, useImperativeHandle, forwardRef, useRef, useState,
} from 'react';
import {
  useTable, useSortBy, usePagination, useFlexLayout, useResizeColumns, useFilters, useGlobalFilter, useAsyncDebounce, useRowSelect,
} from 'react-table';
import classnames from 'classnames';
import matchSorter from 'match-sorter';
import { parseISO, format } from 'date-fns';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import LoadingSpinner from './LoadingSpinner';

function Table(props, ref) {
  const {
    data,
    columns,
    onTableCellClick,
    onContextMenuClick,
    loading,
    noDataText,
    defaultSorted,
    defaultFiltered,
    onFilteredChange,
    onResizedChange,
    onSortedChange,
    showFooter,
  } = props;

  const tableRef = useRef(null);
  const topScrollRef = useRef(null);
  const currentScroll = useRef(0);

  const [recalculateTopScroll, setRecalculateTopScroll] = useState(false);

  const defaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );

  const selectColumnFilter = ({
    column: {
      filterValue, setFilter, preFilteredRows, id, filterOptions,
    },
  }) => {
    const options = useMemo(() => {
      const optionsList = new Set();
      preFilteredRows.forEach((row) => {
        if (row.values[id] !== '-' && row.values[id]) {
          optionsList.add(row.values[id]);
        }
      });
      return [...optionsList.values()];
    }, [id, preFilteredRows]);
    return (
      <select
        value={filterValue || 'all'}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        {filterOptions
          ? filterOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))
          : (
            <>
              <option value="">Все</option>
              {options.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </>
          )}
      </select>
    );
  };

  const sliderColumnFilter = ({
    column: {
      filterValue, setFilter, preFilteredRows, id,
    },
  }) => {
    const [min, max] = useMemo(() => {
      let newMin = preFilteredRows && preFilteredRows.length && typeof preFilteredRows[0].values[id] === 'number' ? preFilteredRows[0].values[id] : 0;
      let newMax = preFilteredRows && preFilteredRows.length && typeof preFilteredRows[0].values[id] === 'number' ? preFilteredRows[0].values[id] : 0;
      if (preFilteredRows) {
        preFilteredRows.forEach((row) => {
          if (typeof row.values[id] === 'number') {
            newMin = Math.min(row.values[id], newMin);
            newMax = Math.max(row.values[id], newMax);
          }
        });
      }
      return [newMin, newMax];
    }, [id, preFilteredRows, columns]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
      </>
    );
  };

  const fuzzyTextFilterFn = (rows, id, filterValue) => matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const dateTextFilterFn = (rows, id, filterValue) => rows.filter((row) => {
    const rowValue = row.original[id] ? format(parseISO(row.original[id]), 'dd.MM.yyyy') : null;
    return rowValue ? rowValue.includes(filterValue) : null;
  });

  dateTextFilterFn.autoRemove = (val) => !val;

  const filterGreaterThan = (rows, id, filterValue) => rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue || typeof rowValue !== 'number';
  });

  filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

  const strictEqualSelectFilterFn = (rows, id, filterValue) => rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === filterValue;
  });

  strictEqualSelectFilterFn.autoRemove = (val) => !val;

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 700,
      Filter: defaultColumnFilter,
    }),
    [],
  );

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      value: (rows, id, filterValue) => rows.filter((row) => filterValue === row.values[id]),
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  };

  const hiddenColumns = columns.filter((column) => !column.show).map((column) => (column.id ? column.id : column.accessor));
  const memoColumns = useMemo(() => columns.map((column) => {
    const prepColumn = { ...column };
    if (prepColumn.Filter) {
      if (prepColumn.Filter === 'sliderColumnFilter') {
        prepColumn.Filter = sliderColumnFilter;
      }
      if (prepColumn.Filter === 'selectColumnFilter') {
        prepColumn.Filter = selectColumnFilter;
      }
    }
    if (prepColumn.filter) {
      if (prepColumn.filter === 'filterGreaterThan') {
        prepColumn.filter = filterGreaterThan;
      }
      if (prepColumn.filter === 'strictEqualSelectFilterFn') {
        prepColumn.filter = strictEqualSelectFilterFn;
      }
      if (prepColumn.filter === 'dateTextFilterFn') {
        prepColumn.filter = dateTextFilterFn;
      }
    }
    if (typeof (prepColumn.width) === 'undefined') {
      prepColumn.width = null;
    }
    return prepColumn;
  }), [columns]);
  const memoData = useMemo(() => data, [data]);

  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: {
        pageIndex: 0,
        hiddenColumns,
        pageSize: 50,
        sortBy: defaultSorted,
        filters: defaultFiltered,
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
  );

  useImperativeHandle(ref, () => ({
    getTableData: () => ({
      pageIndex,
      pageSize,
      pageData: page,
      filteredData: rows,
    }),
  }));

  useEffect(() => {
    onResizedChange(state.columnResizing.columnWidths);
  }, [state.columnResizing.columnWidths]);

  useEffect(() => {
    if (state.filters.length > 0) {
      onFilteredChange(state.filters);
    }
  }, [state.filters]);

  useEffect(() => {
    onSortedChange(state.sortBy);
  }, [state.sortBy]);

  const copyScroll = (e, copyTargetRef) => {
    const { target } = e;
    if (e.target.scrollLeft !== currentScroll.current) {
      currentScroll.current = target.scrollLeft;
      copyTargetRef.current.scrollLeft = target.scrollLeft;
    }
  };

  const body = useMemo(() => (
    <div className="rt-tbody">
      {/* Applying the table body props */}
      <div {...getTableBodyProps()} className="rt-tr-group">
        { // Looping over the table rows
          page.map((row, ind) => {
            // Preparing row for rendering
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                {/* Applying the row props and adding classname for alternate rows */}
                <ContextMenuTrigger id={`context_id_${row.id}`}>
                  <div
                    {...row.getRowProps()}
                    className={classnames(
                      'rt-tr',
                      ind % 2 === 0 ? '-odd' : '-even',
                      { 'red-light': row.original.highlight },
                    )}
                  >
                    { // Looping over the row cells
                      row.cells.map((cell) => (
                        // Applying the cell props and rendering the cell content
                        <div
                          {...cell.getCellProps()}
                          className={classnames('rt-td', cell.column.className)}
                          onMouseDown={(e) => { e.preventDefault(); if (onTableCellClick) { onTableCellClick(e, row, cell); } }}
                        >
                          {cell.render('Cell')}
                        </div>
                      ))
                    }
                  </div>
                </ContextMenuTrigger>
                {onContextMenuClick ? (
                  <ContextMenu id={`context_id_${row.id}`}>
                    <MenuItem onClick={(e) => { e.preventDefault(); if (onContextMenuClick) { onContextMenuClick(e, row); } }}>
                      Открыть в новой вкладке
                    </MenuItem>
                  </ContextMenu>
                ) : null}
              </React.Fragment>
            );
          })
        }
      </div>
    </div>
  ), [page, memoColumns, memoData, state.columnResizing.isResizingColumn, hiddenColumns]);

  useEffect(() => {
    setTimeout(() => {
      setRecalculateTopScroll((prev) => !prev);
    }, 500);
  }, [page, memoColumns, memoData, state.columnResizing.isResizingColumn]);

  useEffect(() => {
    let topScrollWidth = 0;
    if (tableRef.current.getElementsByClassName('rt-thead')[0].getElementsByClassName('rt-tr').length && tableRef.current.getElementsByClassName('rt-thead')[0].getElementsByClassName('rt-tr')[0].getElementsByClassName('rt-th').length) {
      [...tableRef.current.getElementsByClassName('rt-thead')[0].getElementsByClassName('rt-tr')[0].getElementsByClassName('rt-th')].forEach((cell) => {
        topScrollWidth += cell.clientWidth;
      });
    }
    topScrollRef.current.getElementsByTagName('div')[0].style.width = `${topScrollWidth}px`;
  }, [recalculateTopScroll]);

  return (
    <>
      <div className="ReactTable">
        <div style={{ overflowX: 'auto', overflowY: 'hidden' }} onScroll={(e) => copyScroll(e, tableRef)} ref={topScrollRef}>
          <div style={{ paddingTop: '1px', height: 0 }}>&nbsp;</div>
        </div>
        <div {...getTableProps} ref={tableRef} className="rt-table" onScroll={(e) => copyScroll(e, topScrollRef)}>
          <div className="rt-thead -header">
            { // Looping over the header rows
              headerGroups.map((headerGroup, key) => (
                // Applying the header row props
                <React.Fragment key={key}>
                  <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                    {// Looping over the headers in each row
                      headerGroup.headers.map((column) =>
                        /* Checking if column can be resized and applying resizable props along with
                        header cell props and finally rendering the header cell
                        */
                        (column.resizable === false ? (
                          <div {...column.getHeaderProps(column.getSortByToggleProps())} className={classnames('rt-th', { 'bth--sort-desc': column.isSorted && column.isSortedDesc, 'bth--sort-asc': column.isSorted && !column.isSortedDesc })}>
                            {column.render('Header')}
                          </div>
                        ) : (
                          <div
                            {...column.getHeaderProps()}
                            className={classnames('rt-th rt-resizable-header', { 'bth--sort-desc': column.isSorted && column.isSortedDesc, 'bth--sort-asc': column.isSorted && !column.isSortedDesc })}
                          >
                            <div
                              {...column.getSortByToggleProps()}
                              className="rt-resizable-header-content"
                            >
                              {column.render('Header')}
                            </div>
                            <div {...column.getResizerProps()} className="rt-resizer" onClick={(e) => e.preventDefault()} />
                          </div>
                        )))
                    }
                  </div>
                  <div {...headerGroup.getHeaderGroupProps()} key="headerGroup_100" className="rt-tr -filters">
                    {headerGroup.headers.map((column) => (
                      <div {...column.getHeaderProps()} className={classnames('rt-th')}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))
            }
          </div>
          {body}
          {showFooter ? (
            <div className="rt-tfoot">
              {footerGroups.map((group) => (
                <div className="rt-tr" {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <div className="rt-td" {...column.getFooterProps()}>{column.render('Footer')}</div>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
          {!page.length ? (
            <div className="rt-no-data">
              {noDataText}
            </div>
          ) : null}
        </div>
        {/* Loading overlay */}
        <div className={classnames('-loading', loading ? '-active' : '')}>
          <div>
            <LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />
          </div>
        </div>
      </div>
      <div className="pagination-7">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} type="button">Назад</button>
        <div className="pagination-7_menu">
          <div className="pagination-7_pages">
            <span>
              Страница{' '}
            </span>
            <span>
              <input
                type="number"
                value={pageOptions.length > 0 ? pageIndex + 1 : 0}
                onChange={(e) => {
                  const newPage = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(newPage);
                }}
              />
            </span>{' '} из {pageOptions.length}
          </div>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 25, 50, 100].map((pageSizeValue) => (
              <option key={pageSizeValue} value={pageSizeValue}>
                {pageSizeValue} строк
              </option>
            ))}
          </select>
        </div>
        <button onClick={() => nextPage()} disabled={!canNextPage} type="button">Вперед</button>
      </div>
    </>
  );
}

export default forwardRef(Table);
