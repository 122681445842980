import React from 'react';
import styled from 'styled-components';

const Dashboard = styled.ul`
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  row-gap: 24px;
  -webkit-column-gap: 1.75%;
  -moz-column-gap: 1.75%;
  column-gap: 1.75%;
  

  @media (max-width: 1023px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    width: 100vw;
    margin-left: -16px;
  }
 `;

export default function DashboardCardsList(props) {
  const {
    children, disabled,
  } = props;

  return (
    <Dashboard disabled={disabled}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </Dashboard>
  );
}
