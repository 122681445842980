import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import LoadingSpinner from '../Layout/LoadingSpinner';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isActiveItem, show) => ({
  display: show === false ? 'none' : 'block',
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',
  background: (isDragging || isActiveItem) ? '#00e646' : 'white',
  ...draggableStyle,
});

// Draggable Item component
const DraggableItem = React.memo(({
  item, index, loadModels, isActiveItem, removingModelFlag, handleRemoveModel, type, show,
}) => (
  <>
    <Draggable draggableId={`${item.id}-${item.name}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, isActiveItem, show)}
          onClick={() => loadModels(item.id)}
        >
          {type === 'reference' ? (
            <>
              <p>{item.name}{item.category ? <b> ({item.category})</b> : null}</p>
              <span><b>Умный полис:</b> {item.smart_name}</span>
              <br />
              <span><b>ИНГУРУ:</b> {item.inguru_name}</span>
              <br />
              <span><b>Инсапп:</b> {item.insapp_name}</span>
              <br />
              <span><b>РГС Direct:</b> {item.rgs_direct_name}</span>
              <br />
              <span><b>Сбер:</b> {item.sber_name}</span>
              <br />
              <span><b>Макс:</b> {item.car_maks_name}</span>
              <br />
              <span><b>Югория:</b> {item.car_ugoria_name}</span>
              <br />
              <span><b>Энергогарант:</b> {item.car_energogarant_name}</span>
              <br />
              <span><b>Согаз:</b> {item.car_sogaz_name}</span>
              <br />
              <span><b>Тинькофф:</b> {item.tinkoff_direct_name}</span>
              <br />
              <span><b>Ингосстрах:</b> {item.ingoss_direct_name}</span>
              <br />
              <span><b>Гелиос:</b> {item.gelios_name}</span>
              <br />
              <span><b>Астро-волга:</b> {item.astro_volga_name}</span>
              <br />
              <span><b>Согласие:</b> {item.soglasie_name}</span>
              <br />
              <span><b>Альфастрахование:</b> {item.alpha_name}</span>
            </>
          ) : <span>{item.name}{item.models_count ? ` (${item.models_count})` : null}{item.category ? <b> ({item.category})</b> : null}</span>}
        </div>
      )}
    </Draggable>
    {handleRemoveModel && (removingModelFlag ? <LoadingSpinner className="loading-circle mb-2" type="spin" height={38} width={38} /> : <button type="button" className="btn btn-danger mb-2" onClick={() => handleRemoveModel(item.id, index)}>Скрыть</button>)}
  </>
));

export default class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (nextProps.list !== this.props.list || nextProps.activeItem !== this.props.activeItem || nextProps.removingModelFlag !== this.props.removingModelFlag);
  }

  render() {
    const {
      list, loadModels, activeItem, removingModelFlag, handleRemoveModel, type,
    } = this.props;
    return list.map((item, index) => (
      <DraggableItem
        show={item.show}
        key={item.id}
        item={item}
        index={index}
        loadModels={loadModels}
        isActiveItem={activeItem === item.id}
        removingModelFlag={removingModelFlag}
        handleRemoveModel={handleRemoveModel}
        type={type}
      />
    ));
  }
}
