import React, { useMemo } from 'react';
import styled from 'styled-components';

export const ButtonBlock = styled.button`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: ${(props) => (props.width || 'auto')};
  max-width: 100%;
  padding: 7px 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => (props.color.text)};
  text-align: center;
  background-color: ${(props) => (props.color.main)};
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  border: ${(props) => (props.type === 'border' ? `2px solid ${props.color.borderColor}` : `2px solid ${props.color.main}`)};
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? 'arrow' : 'pointer')};
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  white-space: nowrap;
  min-width: ${(props) => (props.width || '328px')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  ${(props) => (props.withIcon ? 'padding-left: 12px' : '')};  

  &:not(:last-of-type) {
    margin: 0 12px 0 0;
  }

  .modal__content & {
    margin-top: 40px;
  }
  
  &:hover:not(:disabled) {
    background-color: ${(props) => (props.color.hover)};
    border-color: ${(props) => (props.type === 'border' ? props.color.borderColorHover : props.color.hover)};
    color: ${(props) => (props.type === 'border' ? props.color.textHover : props.color.text)};
  }

  @media (max-width: 1023px) {
    min-width: ${(props) => (props.width || '222px')};
  }
  @media (max-width: 767px) {
    width: 100%;
    &:not(:last-of-type) {
        margin: 0;
    }
  }
 `;

export default function Button(props) {
  const {
    children, type, onClick, width, colorType, disabled, colorSchema, withIcon,
  } = props;

  const color = useMemo(() => {
    let colorObj = {
      main: '#0bd95d',
      hover: '#0ac254',
      text: '#fff',
      textHover: '#fff',
      borderColor: '#0bd95d',
      borderColorHover: '#0bd95d',
    };

    if (colorSchema && colorSchema.main) { return colorSchema; }

    switch (colorType) {
      case 'gray':
        colorObj = {
          main: '#737373',
          hover: '#525252',
          text: '#fff',
          textHover: '#3d3d3b',
          borderColor: '#737373',
          borderColorHover: '#737373',
        };
        break;
      case 'darkGray':
        colorObj = {
          main: '#525252',
          hover: '#737373',
          text: '#fff',
          textHover: '#3d3d3b',
          borderColor: '#525252',
          borderColorHover: '#525252',
        };
        break;
      case 'grayBordered':
        colorObj = {
          main: '#fff',
          hover: '#f7f7f7',
          text: '#3d3d3b',
          textHover: '#3d3d3b',
          borderColor: '#cfcfcf',
          borderColorHover: '#a3a3a3',
        };
        break;
      default:
        break;
    }

    return colorObj;
  }, [colorType, colorSchema]);

  return (
    <ButtonBlock type={type} onClick={(e) => { if (!disabled && onClick) { onClick(e); } }} width={width} color={color} disabled={disabled} withIcon={withIcon}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </ButtonBlock>
  );
}
