import React, {
  useRef, useState, useContext, useEffect,
} from 'react';
import queryString from 'query-string';
import InterfaceToursTable from './InterfaceToursTable';
import InterfaceTourEdit from './InterfaceTourEdit';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';

export default function InterfaceToursAdmin(props) {
  const { history } = props;
  const [tour, setTour] = useState({});
  const tableRef = useRef(null);

  const openModalTableCustomized = () => {
    tableRef.current.openModalTableCustomized(true);
  };

  const { showModalInfo } = useContext(ModalMessagesContext);

  const setNewTour = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    tableRef.current.setLoading();

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/create`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setTour(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const toggleTourIdToUrl = (type = 'add') => {
    const urlParams = queryString.parse(window.location.search);
    let queryParams = '?';
    Object.entries(urlParams).forEach(([key, value]) => {
      if (key !== 'activeTourId' && key !== 'tourEditActiveTab' && key !== '') {
        if (queryParams === '?') {
          queryParams = `${queryParams}${key}=${value}`;
        } else {
          queryParams = `${queryParams}&${key}=${value}`;
        }
      }
    });
    if (type === 'add') {
      queryParams = `${queryParams}&activeTourId=${tour.id}`;
    } else {
      queryParams = `${queryParams}`;
    }
    history.push({ search: queryParams });
  };

  const flushCurrentTour = () => {
    setTour({});
    toggleTourIdToUrl('clear');
  };

  useEffect(() => {
    if (tour.id) {
      toggleTourIdToUrl();
    }
  }, [tour.id]);

  const loadTourData = (id) => {
    tableRef.current.setTourId(id);
  };

  useEffect(() => {
    if (!tour.id) {
      const urlParams = queryString.parse(window.location.search);
      if (!tour.id && 'activeTourId' in urlParams) {
        loadTourData(urlParams.activeTourId);
      }
    }
  }, []);

  return (
    <>
      {!tour.id ? (
        <div className="position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-inline-flex">
                <button type="button" className="btn btn-success mr-3" onClick={setNewTour}>Создать новый демо-тур</button>
                <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
                  <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                  Настроить таблицу
                </Button>
              </div>
            </div>
          </div>
          <br />
          <InterfaceToursTable
            setTour={setTour}
            ref={tableRef}
          />
        </div>
      ) : (
        <InterfaceTourEdit
          tour={tour}
          setTour={setTour}
          flushCurrentTour={flushCurrentTour}
          history={history}
        />
      )}
    </>
  );
}
