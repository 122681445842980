import React, {
  useEffect, useState, useMemo, useContext, useImperativeHandle, forwardRef,
} from 'react';
import classnames from 'classnames';
import Modal from 'react-responsive-modal';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import Sticky from 'react-sticky-el';
import LoadingSpinner from '../Layout/LoadingSpinner';
import getValidation from '../Utils/Validation';
import getMask from '../Utils/Masks';
import { customSelectStyle, customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';

const FileSaver = require('file-saver');

function CommercialOffer(props, ref) {
  const {
    commercialOfferCompanies,
    policyId,
    contactEmail,
    contactPhone,
    product,
    setShowCommercialOfferModalForm,
    showCommercialOfferModalForm,
    setLoadingCommercialOffer,
    productName,
    policyIdHash,
    policySortOrderUp,
    selectedSort,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const sendingTypeOptions = [
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Телефон' },
  ];

  const [refLink, setRefLink] = useState('');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState({
    value: contactEmail,
    errorMessage: false,
    validationType: 'email',
  });
  const [phone, setPhone] = useState({
    value: contactPhone,
    errorMessage: false,
    validationType: 'phone',
  });
  const [comment, setComment] = useState({
    value: '',
    errorMessage: false,
    validationType: 'comment',
  });
  const [sendRefLinkWaitingFlag, setSendRefLinkWaitingFlag] = useState(false);
  const [generatePdfWaitingFlag, setGeneratePdfWaitingFlag] = useState(false);
  const [sendingType, setSendingType] = useState(sendingTypeOptions[0]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
    getPdf: (e) => {
      getPdf(e);
    },
  }));

  const copyLink = () => {
    const copyInput = document.createElement('textarea');
    copyInput.textContent = refLink;
    copyInput.style.height = 0;
    copyInput.style.width = 0;
    document.body.appendChild(copyInput);
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(copyInput);
    window.getSelection().addRange(range);
    try {
      document.execCommand('copy');
      showModalInfo('Ссылка скопирована');
    } catch (err) {
      showModalInfo('Невозможно скопировать');
    }
    window.getSelection().removeAllRanges();
    copyInput.remove();
  };

  const changeCommentInput = (e) => {
    const { target } = e;
    setComment((prev) => ({
      ...prev,
      value: target.value,
      errorMessage: false,
    }));
  };

  const changeEmailInput = (e) => {
    const { target } = e;
    setEmail((prev) => ({
      ...prev,
      value: target.value,
      errorMessage: false,
    }));
  };

  const changePhoneInput = (e) => {
    const { target } = e;
    setPhone((prev) => ({
      ...prev,
      value: target.value,
      errorMessage: false,
    }));
  };

  const validateCommentInput = () => {
    const errorMessage = getValidation(comment.value, comment.validationType);
    setComment((prev) => ({
      ...prev,
      errorMessage,
    }));
  };

  const validateEmailInput = () => {
    const errorMessage = getValidation(email.value, email.validationType);
    setEmail((prev) => ({
      ...prev,
      errorMessage,
    }));
  };

  const validatePhoneInput = () => {
    const errorMessage = getValidation(phone.value, phone.validationType);
    setPhone((prev) => ({
      ...prev,
      errorMessage,
    }));
  };

  const sendLink = (sendType) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    setSendRefLinkWaitingFlag(true);

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/send-ref-link`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        comment: comment.value,
        link: refLink,
        type: sendType,
        phone: phone.value,
        email: email.value,
        commercialOffer: true,
        linkType: product,
        refType: 'curator',
        policyId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          switch (sendType) {
            case 'phone':
              showModalInfo('Ссылка отправлена на телефон');
              break;
            case 'email':
              showModalInfo('Ссылка отправлена на почту');
              break;
            default:
              break;
          }
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setSendRefLinkWaitingFlag(false);
      });
  };

  const getPdf = (e) => {
    if (product === 'mortgage' && showCommercialOfferModalForm !== 'pdf' && !showModal) {
      setShowCommercialOfferModalForm('pdf');
    } else {
      e.preventDefault();
      setGeneratePdfWaitingFlag(true);
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

      let date = new Date();
      date = date.toLocaleString('ru-RU').substring(0, 10).replace(/\./g, '');

      const link = `${process.env.REACT_APP_API_DOMAIN}/commercial-offer/${product}/doc`;
      const templateName = `${product}_commercial_offer_${policyId}_${date}.pdf`;

      customFetch(link, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          draftId: policyId,
          comment: comment.value,
          policySortOrderUp,
          selectedSort,
        }),
      })
        .then((response) => response.blob()).then((response) => {
          FileSaver.saveAs(response, templateName);
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          setGeneratePdfWaitingFlag(false);
          if (product === 'mortgage') {
            setLoadingCommercialOffer(false);
            setShowCommercialOfferModalForm('');
          }
        });
    }
  };

  const createRefLink = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/refLink?type_insurance=${product}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: 'КП',
        type: 'commercialOffer',
        policyHash: policyIdHash,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setRefLink(response.link);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage, 'error');
      });
  };

  const openModal = () => {
    if (!refLink) {
      createRefLink();
    }
    if (product === 'mortgage') {
      setShowCommercialOfferModalForm('send');
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    setPhone({
      value: contactPhone,
      errorMessage: false,
      validationType: 'phone',
    });
    setEmail({
      value: contactEmail,
      errorMessage: false,
      validationType: 'email',
    });
  }, [contactEmail, contactPhone]);

  useEffect(() => {
    setRefLink('');
  }, [policyIdHash]);

  useEffect(() => {
    if (commercialOfferCompanies.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [commercialOfferCompanies]);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
        closeIconSize={16}
        open={showModal}
        onClose={() => setShowModal(false)}
        center
      >
        <h3>Ссылка на предложение по {productName}</h3>
        <div className="quote__styled mb-2">{refLink}</div>
        <button type="button" className="btn custom-btn" onClick={copyLink}>Скопировать</button>
        {generatePdfWaitingFlag
          ? <LoadingSpinner className="loading-circle ml-2 d-inline-block" type="spin" height={37} width={37} />
          : <a aria-label="Скачать форму" title="Скачать форму" rel="noopener noreferrer" target="_blank" href="#" className="btn btn-outline ml-2" onClick={getPdf}><i className="icon icon_print" /></a>}
        <h4>Отправить ссылку клиенту</h4>
        <SimpleNotification offsetBottom="1rem">Укажите e-mail или телефон для отправки ссылки на сформированное предложение клиенту.</SimpleNotification>
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="type">Куда оправляем ссылку</label>
            <Select
              styles={customStyles}
              classNamePrefix="react-select"
              name="type"
              isSearchable={false}
              inputId="type"
              noOptionsMessage={() => 'Не найдено'}
              value={sendingType.value ? sendingType : null}
              onChange={(e) => { setSendingType(e); }}
              options={sendingTypeOptions}
            />
          </div>
        </div>
        {sendingType.value === 'email' ? (
          <>
            <div className="form-group row">
              <div className="col-md-12">
                <label htmlFor="email">Укажите email:</label>
                <InputMask
                  maskChar=""
                  onBlur={(e) => { validateEmailInput(e); }}
                  onChange={(e) => { changeEmailInput(e); }}
                  id="email"
                  placeholder="your@email.ru"
                  name="email"
                  className={classnames('form-control', { error: email.errorMessage })}
                  value={email.value}
                  mask={getMask('email').mask}
                  formatChars={getMask('email').formatChars}
                />
                {email.errorMessage && (
                  <div className="validation-error">
                    {email.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                <label htmlFor="comment">Укажите комментарий:</label>
                <textarea
                  onBlur={(e) => { validateCommentInput(e); }}
                  onChange={(e) => { changeCommentInput(e); }}
                  id="comment"
                  placeholder="Оставьте свой комментарий в письме"
                  name="comment"
                  className={classnames('form-control', { error: comment.errorMessage })}
                  value={comment.value}
                />
                {comment.errorMessage && (
                  <div className="validation-error">
                    {comment.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
        {sendingType.value === 'phone' ? (
          <div className="form-group row">
            <div className="col-md-12">
              <label htmlFor="phone">Укажите телефон:</label>
              <InputMask
                maskChar=""
                onBlur={(e) => { validatePhoneInput(e); }}
                onChange={(e) => { changePhoneInput(e); }}
                id="phone"
                placeholder="+7 (999) 999-99-99"
                name="phone"
                className={classnames('form-control', { error: phone.errorMessage })}
                value={phone.value}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
              />
              {phone.errorMessage && (
                <div className="validation-error">
                  {phone.errorMessage}
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className="form-group row">
          <div className="col-12">
            <button type="button" className="btn btn-success" disabled={sendRefLinkWaitingFlag || (sendingType.value === 'email' && email.errorMessage) || (sendingType.value === 'phone' && phone.errorMessage)} onClick={() => { sendLink(sendingType.value); }}>Отправить</button>
          </div>
        </div>
      </Modal>
      <Sticky
        mode="bottom"
        positionRecheckInterval={1}
        className={show ? 'visible-animation' : 'hidden-animation'}
      >
        <div className="commercial-offer">
          <div className="commercial-offer__info">
            Добавляйте одобренные к оплате договора в предложение.
            Отправьте ссылку клиенту для проверки данных и оплаты.
          </div>
          <div className="commercial-offer__company-count">Выбрано страховых компаний: {commercialOfferCompanies.length}</div>
          <div className="commercial-offer__button">
            {generatePdfWaitingFlag
              ? <LoadingSpinner className="loading-circle mr-2 mb-1 d-inline-block" type="spin" height={37} width={37} />
              : <button className="btn custom-btn mr-2 mb-1" type="button" onClick={getPdf}>Скачать КП</button>}
            {(product === 'osago' || product === 'mortgage') ? <button className="btn custom-btn mb-1" type="button" onClick={openModal}>Отправить КП клиенту</button> : null}
          </div>
        </div>
      </Sticky>
    </>
  );
}

export default forwardRef(CommercialOffer);
