import React, {
  useState, forwardRef, useImperativeHandle, useEffect, useRef, useContext,
} from 'react';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import { format, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import {
  toLocalDate,
  customFetch,
  setTableSorting,
  setTableSettings7, getDefaultSorting7,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Table from '../Layout/Table';

function InterfaceToursTable(props, ref) {
  const { setTour } = props;
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedTourId, setSelectedTourId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [toursList, setToursList] = useState([]);
  const [defaultSorting, setDefaultSorting] = useState([{
    field: 'created_at',
    order: 'true',
  }]);
  const [updateColumnsFlag, setUpdateColumnsFlag] = useState(false);
  const [flagModalTourDelete, setFlagModalTourDelete] = useState(false);
  const [idToSet, setIdToSet] = useState(null);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const simulateClick = new MultiRef();

  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
    setLoading: () => {
      setLoading(true);
    },
    setTourId: (id) => {
      setIdToSet(id);
    },
  }));

  useEffect(() => {
    if (!loading && idToSet) {
      const currentTourIndex = toursList.findIndex((tour) => tour.id === parseInt(idToSet, 10));
      setTour(toursList[currentTourIndex]);
      setIdToSet(null);
    }
  }, [loading, idToSet]);

  const delTour = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/${selectedTourId}/delete`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newToursList = toursList.filter((p) => p.id !== selectedTourId);
          setToursList(newToursList);
          setFlagModalTourDelete(false);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .finally(() => {
        setSelectedTourId(null);
      });
  };

  const updateTableColumns = () => {
    const newColumns = [{
      Header: '№',
      accessor: 'id',
      minWidth: 70,
      width: reactLocalStorage.getObject('interfaceToursListSettings').id,
      show: reactLocalStorage.getObject('interfaceToursListSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование',
      accessor: 'name',
      minWidth: 150,
      width: reactLocalStorage.getObject('interfaceToursListSettings').name,
      show: reactLocalStorage.getObject('interfaceToursListSettings').show_name !== false,
      showCustomized: true,
    }, {
      Header: 'Дата изменения',
      id: 'updated_at',
      minWidth: 150,
      accessor: (d) => format(toLocalDate(parseISO(d.updated_at)), 'yyyy-MM-dd, HH:mm:ss'),
      width: reactLocalStorage.getObject('interfaceToursListSettings').updated_at,
      disableFilters: true,
      show: reactLocalStorage.getObject('interfaceToursListSettings').show_updated_at !== false,
      showCustomized: true,
    }, {
      id: 'steps_not_empty',
      Header: 'Заполнение шагов',
      Filter: 'selectColumnFilter',
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.steps_not_empty) {
          return (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        if (!row.original.steps_not_empty) {
          return (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        return 'Без статуса';
      },
      accessor: (d) => (d.steps_not_empty ? 'Заполнены' : 'Не заполнены'),
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_steps_not_empty !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      accessor: 'status_name',
      minWidth: 150,
      width: reactLocalStorage.getObject('interfaceToursListSettings').status_name,
      Filter: 'selectColumnFilter',
      show: reactLocalStorage.getObject('interfaceToursListSettings').show_status_name,
      showCustomized: true,
    }, {
      Header: () => null, // No header
      id: 'delete',
      width: 40,
      disableFilters: true,
      show: true,
      clickable: true,
      Cell: ({ row }) => (
        <div title="Удалить демо-тур"><FontAwesomeIcon onClick={(e) => { setSelectedTourId(row.original.id); setFlagModalTourDelete(true); }} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    }];
    setColumns(newColumns);
  };

  const loadTours = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours?type=list`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          response = response.map((tour) => ({
            ...tour,
            status_name: tour.active ? 'Активная' : 'Неактивная',
            steps_not_empty: tour.steps && tour.steps.length,
          }));
          setToursList(response);
          setUpdateColumnsFlag(true);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateColumnsFlag) {
      updateTableColumns();
      setUpdateColumnsFlag(false);
    }
  }, [updateColumnsFlag]);

  const handleTableCustomizedSetAll = () => {
    simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  const handleTableCustomizedSetNone = () => {
    simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  const handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columnsArray = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('interfaceToursListSettings');
    columnsArray.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('interfaceToursListSettings', settings);
    setFlagModalTableCustomized(false);
  };

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    window.history.replaceState(null, null, queryParams);
  };

  const customizedColumns = columns.map((column) => {
    const id = column.id ? column.id : column.accessor;
    if (column.showCustomized) {
      return (
        <li key={id} className="form-check">
          <input ref={simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
          <label className="form-check-label" htmlFor={id}>{column.Header}</label>
        </li>
      );
    }
    return null;
  });

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef.current) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    loadTours();
    const newDefaultSorted = getDefaultSorting7('interfaceToursListSettings', defaultSorting);
    setDefaultSorting([...newDefaultSorted]);
  }, []);

  const onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable) {
      if (e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
        setTour({
          ...row.original,
        });
      }
    }
  };

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={handleSubmitTableCustomized} id="tableCustomized">
          <ul className="two-column-list">
            {customizedColumns}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTourDelete}
        onClose={() => { setFlagModalTourDelete(false); }}
        center
      >
        <h4>Вы уверены?</h4>
        <button className="btn btn-success mr-2" type="button" onClick={(e) => delTour(e)}>Да</button>
        <button type="button" className="btn btn-secondary" onClick={() => { setFlagModalTourDelete(false); setSelectedTourId(null); }}>Нет</button>
      </Modal>
      <Table
        data={toursList}
        columns={columns}
        onTableCellClick={onTableCellClick}
        loading={loading}
        noDataText="Демо-туры не найдены"
        defaultFiltered={filtered}
        defaultSorted={defaultSorting}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'interfaceToursListSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'interfaceToursListSettings');
        }}
        onFilteredChange={(filtered) => onFilteredChangeCustom(filtered)}
      />
    </>
  );
}

export default forwardRef(InterfaceToursTable);
