import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import classnames from 'classnames';
import { styled } from 'styled-components';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import { parse, isValid } from 'date-fns';
import InputMask from 'react-input-mask';
import Label from './Label';
import FieldErrorMessage from './FieldErrorMessage';
import IconPrevios from '../../img/previos.svg';
import IconPreviosEnable from '../../img/previos_enable.svg';
import Second from '../../img/second.svg';
import IconSecondEnable from '../../img/second_enable.svg';

const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  
  & .react-datepicker-popper {
    z-index: 999999;
    left: ${(props) => (props.$leftPosition ? '-38.5px' : '0')} !important;
    &[data-placement^="bottom"] {
        margin-top: 2px;
    }
    &[data-placement^="bottom"] .react-datepicker__triangle {
        display: none;
    }
  }

  & .react-datepicker-wrapper{
    width: 100%;
  }  

  & input.styled-datapicker{
      z-index: 99999;
    cursor: pointer;
    width: 100%;
    padding-left: 40px;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    ${(props) => (props.height ? `height: ${props.height};` : '')}
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    -webkit-box-shadow: 0 1px 2px rgba(61,61,59,.05);
    box-shadow: 0 1px 2px rgba(61,61,59,.05);
    border-radius: 6px;
    border: ${(props) => {
    if (props.disabled) {
      return '1px solid #e9e9e9';
    }
    if (props.error_message) {
      return '1px solid #ff4d00';
    }
    return '1px solid #cfcfcf';
  }};
    -webkit-transition: border .15s ease,-webkit-box-shadow .15s ease;
    transition: border .15s ease,box-shadow .15s ease;
    transition: border .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
    outline: 0;
    ${(props) => (props.disabled ? 'background-color: #f6f6f6;' : '')}
    &:hover {
    border: ${(props) => {
    if (props.disabled) {
      return '1px solid #e9e9e9';
    }
    if (props.error_message) {
      return '1px solid #ff4d00';
    }
    return '1px solid #a3a3a3';
  }};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    }
    &:focus {
      -webkit-box-shadow: ${(props) => (props.error_message ? '' : '0 0 0 1px #0bd95d')};
      box-shadow: ${(props) => (props.error_message ? '' : '0 0 0 1px #0bd95d')};
      border: ${(props) => (props.error_message ? '1px solid #ff4d00' : '1px solid #0bd95d')};
    }
  }

  @media (max-width: 767px) {
    & input.styled-datapicker{
      padding-left: 40%;
      padding-top: 8px;
      padding-right: 12px;
      padding-bottom: 8px;
    }
  }
  
  & .title-mobile {
    pointer-events: none;
    left: 12px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    z-index: 10000;
    width: 20px;
    height: 20px;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease;
    color: #a3a3a3;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: none;
    @media (max-width: 767px) {
      left: 23%;
      display: block;
    }
  }

  & svg{
    pointer-events: none;
    left: 12px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    z-index: 10000;
    width: 20px;
    height: 20px;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease;
    @media (max-width: 767px) {
        left: 17%;
    }
  }

  & .react-datepicker__header{
    background-color: #fff;
    border-bottom: none;
    padding-top: 16px;
  }

  & .react-datepicker{    
    background: 0 0;
    margin: -1px 0;
    padding: 0;
    border: 0;
    border-radius: 6px;
    font: 14px/22px "Montserrat", Arial, sans-serif;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-color: #fff;
    -webkit-box-shadow: 0 5px 20px rgba(0,0,0,.3), 0 0 2px rgba(0,0,0,.15);
    box-shadow: 0 5px 20px rgba(0,0,0,.3), 0 0 2px rgba(0,0,0,.15);
  }

  & .react-datepicker__month{
    margin: 0;
    padding: 0 15px 15px 15px;
  }

  & .react-datepicker__day{
    width: 32px;
    height: 30px;
    max-width: 32px;
    text-align: center;
    font-size: 13px;
    color: #3d3d3b;
    cursor: pointer;
    font-weight: 500;
    line-height: 30px !important;
  }

  & .react-datepicker__day--disabled{
    cursor: default;
    color: rgba(57,57,57,.3);
  }

  & .react-datepicker__day:hover {
    border-radius: 6px;
  }

  & .react-datepicker__day-name{
    cursor: default;
    font-weight: 600;
    font-size: 12px;
    color: #a3a3a3;
  }

  & .react-datepicker__current-month{
    display: none;
  }

  & .react-datepicker__month-dropdown-container, & .react-datepicker__year-dropdown-container {
    font-weight: 600;
    color: #737373;
    text-transform: capitalize;
    font-size: 16px;
    margin: 0 8px;
  }

  & .react-datepicker__day-names{
    padding: 10px 15px;
    margin-top: 15px;
    border-top: 1px solid #e9e9e9;
  }
    
    & .react-datepicker__month-select,.react-datepicker__year-select {
      background: 0 0;
      border: 0;
      text-transform: capitalize;
      &:hover {
        background: rgba(0, 0, 0, .05);
      }
      border-radius: 0;
      color: inherit;
      font-size: inherit;
      font-weight: 600;
      height: auto;
      vertical-align: initial;
      cursor: pointer;
      line-height: 22px;
      margin: -1px 0 0 -10px;
      outline: 0;
      position: relative;
      width: auto;

      .flatpickr-monthDropdown-month,.flatpickr-yearDropdown-month {
        background-color: transparent;
        outline: 0;
        padding: 0;
      }
    }

    & .react-datepicker__year-select {
      margin-left: 22px;
    }

  & .react-datepicker__day-name{
    cursor: default;
    font-weight: 600;
    font-size: 14px;
    color: #a3a3a3;
    background: 0 0;
    line-height: 22px;
    text-transform: capitalize;
    margin: 0;
    text-align: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: inline-block;
    width: 14.2857143%;
  }
    
  & .react-datepicker__day--outside-month {
    color: rgba(57, 57, 57, .3);
    background: 0 0;
    border-color: transparent;
    cursor: default;
  }
    
    & .react-datepicker__navigation-icon {
        top: -10px;
        text-indent: initial;
        overflow: visible;
        font-size: 0;
        &:before {
            visibility: hidden;
        }
    }

  & .react-datepicker__navigation {
    top: 20px;
    text-indent: initial;
    overflow: visible;
    font-size: 0;
    &.react-datepicker__navigation--previous {
      &:hover {
        &:before {
          content: url(${IconPreviosEnable});
        }
      }
      border: none;
      &:before {
        content: url(${IconPrevios});
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        left: 6px;
        text-align: center;
        padding-top: 2px;
        padding-right: 1px;
        bottom: 15px;
        width: 24px;
        height: 24px;
        z-index: 3;
        color: #737373;
        fill: rgba(0, 0, 0, .9);
        border: 1px solid #e9e9e9;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        border-radius: 6px;
      }
    }
      
    &.react-datepicker__navigation--next {
      border: none;
      &:hover {
        &:before {
          content: url(${IconSecondEnable});
        }
      }
      &:before {
        content: url(${Second});
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        right: 6px;
        text-align: center;
        padding-top: 2px;
        padding-left: 2px;
        bottom: 15px;
        width: 24px;
        height: 24px;
        z-index: 3;
        color: #737373;
        fill: rgba(0, 0, 0, .9);
        border: 1px solid #e9e9e9;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        border-radius: 6px;
      }
    }
  }

  & .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
  }

  & .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #737373;
    font-weight: 500;
  }
  
  & .react-datepicker__time-container .react-datepicker__time
  .react-datepicker__time-box ul.react-datepicker__time-list
  li.react-datepicker__time-list-item{
    font-size: 13px;
    color: #3d3d3b;
    cursor: pointer;
    font-weight: 500;
  }
  
  & .react-datepicker__time-container .react-datepicker__time
  .react-datepicker__time-box ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover{
    background-color: var(--mainColor);
  }

  & .react-datepicker__time-container .react-datepicker__time
  .react-datepicker__time-box ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected{
    background-color: var(--mainColor);
  }
`;

export default function CustomMultiDatapicker(props) {
  const {
    handleValidate, disabled, label, value, dataKey, maxDate, minDate, showTimeSelect, height, changeStartDate, changeEndDate, startDate, endDate,
  } = props;

  const [startDateIn, setStartDate] = useState(startDate);
  const [endDateIn, setEndDate] = useState(endDate);
  const onChangeDates = (dates) => {
    const [start, end] = dates;
    if (start && isValid(start)) {
      setStartDate(start);
    }
    setStartDate(start);
    setEndDate(end);
  };

  const onChangeDatesRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    const raw:string = event.target.value;
    if (raw != null) {
      if (typeof raw === 'string' && raw.length >= 13) {
        let rawStart = raw;
        let rawEnd = '';
        if (raw.includes(' - ')) {
          rawStart = raw.split(' - ')[0];
          rawEnd = raw.split(' - ')[1];
        }

        const referenceDate = new Date(new Date().getFullYear(), 0, 1);
        let startDate: null | Date = null;
        try {
          startDate = parse(rawStart, 'dd.MM.yyyy', referenceDate);
        } catch (e) {
          console.error('Failed to parse start date', e);
        }

        let endDate: null | Date = null;
        try {
          endDate = parse(rawEnd, 'dd.MM.yyyy', referenceDate);
        } catch (e) {
          console.error('Failed to parse end date', e);
        }

        if (startDate && isValid(startDate)) {
          setStartDate(startDate);
        }
        if (endDate && isValid(endDate)) {
          setEndDate(endDate);
        }
      }
    }
  };

  useEffect(() => {
    changeStartDate(startDateIn);
    changeEndDate(endDateIn);
  }, [startDateIn, endDateIn]);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  const ref = useRef(null);
  const [leftPosition, setLeftPosition] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      right: rect.right - window.scrollX,
    };
  };

  const handleWindowSizeChange = () => {
    const currentLeft = getOffset(ref.current).left;
    const disagree = currentLeft + 291.08;
    setIsMobile(window.innerWidth <= 760);
    if (disagree > window.innerWidth) {
      setLeftPosition(`${disagree - window.innerWidth}px`);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {label ? (<Label dataKey={dataKey} label={label} />) : null}
      <DatePickerContainer
        error_message={value.errorMessage ? value.errorMessage : undefined}
        disabled={disabled}
        height={height}
        ref={ref}
        $leftPosition={leftPosition}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 2C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3V4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V6C18 5.46957 17.7893 4.96086 17.4142 4.58579C17.0391 4.21071 16.5304 4 16 4H15V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V4H7V3C7 2.73478 6.89464 2.48043 6.70711 2.29289C6.51957 2.10536 6.26522 2 6 2ZM6 7C5.73478 7 5.48043 7.10536 5.29289 7.29289C5.10536 7.48043 5 7.73478 5 8C5 8.26522 5.10536 8.51957 5.29289 8.70711C5.48043 8.89464 5.73478 9 6 9H14C14.2652 9 14.5196 8.89464 14.7071 8.70711C14.8946 8.51957 15 8.26522 15 8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7H6Z"
            fill="#737373"
          />
        </svg>
        <span className="title-mobile">Период</span>
        <DatePicker
          popperModifiers={{
            computeStyle: { gpuAcceleration: false },
          }}
          selectsRange
          withPortal={isMobile}
          disabled={disabled}
          startDate={startDateIn}
          className={classnames('styled-datapicker')}
          endDate={endDateIn}
          id={dataKey}
          onChange={onChangeDates}
          onChangeRaw={onChangeDatesRaw}
          maxDate={maxDate}
          minDate={minDate}
          showYearDropdown
          showMonthDropdown
          locale={ru}
          dropdownMode="select"
          showTimeSelect={showTimeSelect}
          onBlur={handleValidate}
          dateFormat={showTimeSelect ? 'dd.MM.yyyy  HH:mm' : 'dd.MM.yyyy'}
          placeholderText={showTimeSelect ? 'ДД.ММ.ГГГГ  ЧЧ:ММ' : 'ДД.ММ.ГГГГ'}
          onCalendarClose={handleValidate}
          onClickOutside={handleValidate}
          customInput={
            <InputMask mask="99.99.9999 - 99.99.9999" inputMode="tel" />
          }
        />
      </DatePickerContainer>
      {value.errorMessage && (
      <FieldErrorMessage type="datapicker">
        {value.errorMessage}
      </FieldErrorMessage>
      )}
    </>
  );
}
