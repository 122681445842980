import React from 'react';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import {
  addYears, format, parseISO, subYears,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Tippy from '@tippyjs/react';
import DatePicker from 'react-datepicker';
import getMask from '../../../Utils/Masks';
import { handleChange } from '../../../Utils/Helpers';

export default function Insurer({ props }) {
  const {
    formData,
    disableField,
    handleValidate,
    toggleFlag,
    setCursorToEnd,
    insurerAddressRegistrationRef,
    setFormData,
  } = props;

  const type = formData.insurerTypeCode.value ? formData.insurerTypeCode.value.value : '';

  return (
    <>{(type === 'individual') ? (
      <div className="card-body">
        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="insurerCompanyName">Наименование</label>
            <InputMask
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerCompanyName', formData, setFormData, null)}
              id="insurerCompanyName"
              name="insurerCompanyName"
              placeholder="Введите наименование компании"
              formatChars={getMask('name').formatChars}
              className={classnames('form-control', { error: formData.insurerCompanyName.errorMessage })}
              value={formData.insurerCompanyName.value}
            />
            {formData.insurerCompanyName.errorMessage && (
            <div className="validation-error">
              {formData.insurerCompanyName.errorMessage}
            </div>
            )}
          </div>

          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="insurerOGRN">ОГРН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerOGRN', formData, setFormData, null, { pureValue: true })}
              id="insurerOGRN"
              name="insurerOGRN"
              mask={getMask('ogrn').mask}
              placeholder="11111111111"
              formatChars={getMask('ogrn').formatChars}
              className={classnames('form-control', { error: formData.insurerOGRN.errorMessage })}
              value={formData.insurerOGRN.value}
            />
            {formData.insurerOGRN.errorMessage && (
            <div className="validation-error">
              {formData.insurerOGRN.errorMessage}
            </div>
            )}
          </div>

          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="insurerINN">ИНН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerINN', formData, setFormData, null, { pureValue: true })}
              id="insurerINN"
              name="insurerINN"
              mask={getMask('inn').mask}
              placeholder="1111111111"
              formatChars={getMask('inn').formatChars}
              className={classnames('form-control', { error: formData.insurerINN.errorMessage })}
              value={formData.insurerINN.value}
            />
            {formData.insurerINN.errorMessage && (
            <div className="validation-error">
              {formData.insurerINN.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_1">
            <label htmlFor="insurerLastName">Фамилия</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="insurerLastName"
              name="insurerLastName"
              placeholder="Иванов"
              query={formData.insurerLastName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'insurerLastName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['SURNAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.insurerLastName.errorMessage })}
            />
            {formData.insurerLastName.errorMessage && (
            <div className="validation-error">
              {formData.insurerLastName.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
            <label htmlFor="insurerFirstName">Имя</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="insurerFirstName"
              name="insurerFirstName"
              placeholder="Иван"
              query={formData.insurerFirstName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'insurerFirstName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['NAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.insurerFirstName.errorMessage })}
            />
            {formData.insurerFirstName.errorMessage && (
            <div className="validation-error">
              {formData.insurerFirstName.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="insurerMiddleName">Отчество</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="insurerMiddleName"
              name="insurerMiddleName"
              placeholder="Иванович"
              query={formData.insurerMiddleName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'insurerMiddleName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['PATRONYMIC']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.insurerMiddleName.errorMessage })}
            />
            {formData.insurerMiddleName.errorMessage && (
            <div className="validation-error">
              {formData.insurerMiddleName.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="insurerPassport">Серия и номер паспорта</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerPassport', formData, setFormData, null, { pureValue: true })}
              id="insurerPassport"
              placeholder="111 1111111"
              name="insurerPassport"
              className={classnames('form-control', { error: formData.insurerPassport.errorMessage })}
              mask={getMask('passportNumberSerie').mask}
              value={formData.insurerPassport.value}
              inputMode="tel"
            />
            {formData.insurerPassport.errorMessage && (
            <div className="validation-error">
              {formData.insurerPassport.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="insurerPassportDate">Дата выдачи паспорта</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
              className={classnames('form-control', { error: formData.insurerPassportDate.errorMessage })}
              selected={formData.insurerPassportDate.value ? parseISO(formData.insurerPassportDate.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'insurerPassportDate', formData, setFormData)}
              name="insurerPassportDate"
              id="insurerPassportDate"
              locale={ru}
              maxDate={new Date()}
              minDate={formData.insurerBirthday.value ? addYears(parseISO(formData.insurerBirthday.value), 14) : subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
                }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.insurerPassportDate.errorMessage && (
            <div className="validation-error">
              {formData.insurerPassportDate.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-9">
            <label htmlFor="insurerPassportDivision">Кем выдан</label>
            <Tippy
              className="tippy-custom"
              arrow={false}
              offset={[0, 5]}
              content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
              hideOnClick={false}
            >
              <div className="d-inline-block">
                <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
              </div>
            </Tippy>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerPassportDivision', formData, setFormData)}
              id="insurerPassportDivision"
              name="insurerPassportDivision"
              placeholder=""
              className={classnames('form-control', { error: formData.insurerPassportDivision.errorMessage })}
              value={formData.insurerPassportDivision.value}
            />
            {formData.insurerPassportDivision.errorMessage && (
            <div className="validation-error">
              {formData.insurerPassportDivision.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="insurerPassportUnitNumber">Код подразделения</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'insurerPassportUnitNumber', formData, setFormData, null, { pureValue: true })}
              id="insurerPassportUnitNumber"
              name="insurerPassportUnitNumber"
              mask={getMask('passportUnitNumber').mask}
              placeholder="123-456"
              formatChars={getMask('passportUnitNumber').formatChars}
              className={classnames('form-control', { error: formData.insurerPassportUnitNumber.errorMessage })}
              value={formData.insurerPassportUnitNumber.value}
            />
            {formData.insurerPassportUnitNumber.errorMessage && (
            <div className="validation-error">
              {formData.insurerPassportUnitNumber.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="insurerBirthday">Дата рождения</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
              className={classnames('form-control', { error: formData.insurerBirthday.errorMessage })}
              selected={formData.insurerBirthday.value ? parseISO(formData.insurerBirthday.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'insurerBirthday', formData, setFormData)}
              name="insurerBirthday"
              id="insurerBirthday"
              locale={ru}
              maxDate={new Date()}
              minDate={subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
                }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.insurerBirthday.errorMessage && (
            <div className="validation-error">
              {formData.insurerBirthday.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="insurerActualAddress">Фактический адрес</label>
            <ReactDadata
              id="insurerActualAddress"
              name="insurerActualAddress"
              className={classnames('form-control', { error: formData.insurerActualAddress.errorMessage })}
              query={formData.insurerActualAddress.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'insurerActualAddress', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField}
              onFocus={setCursorToEnd}
            />
            {formData.insurerActualAddress.errorMessage && (
            <div className="validation-error">
              {formData.insurerActualAddress.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className="form-check custom-checkbox">
              <input
                disabled={disableField}
                onChange={toggleFlag}
                checked={formData.insurerAddressRegistrationFlag.value}
                name="insurerAddressRegistrationFlag"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="insurerAddressRegistrationFlag"
              />
              <label className="form-check-label" htmlFor="insurerAddressRegistrationFlag">Фактический адрес и адрес регистрации совпадают</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="insurerAddressRegistration">Адрес регистрации</label>
            <ReactDadata
              id="insurerAddressRegistration"
              name="insurerAddressRegistration"
              className={classnames('form-control', { error: formData.insurerAddressRegistration.errorMessage })}
              query={formData.insurerAddressRegistration.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'insurerAddressRegistration', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField || formData.insurerAddressRegistrationFlag.value}
              onFocus={setCursorToEnd}
              ref={insurerAddressRegistrationRef}
            />
            {formData.insurerAddressRegistration.errorMessage && (
            <div className="validation-error">
              {formData.insurerAddressRegistration.errorMessage}
            </div>
            )}
          </div>
        </div>
      </div>
    ) : null}
      {(type === 'legal') ? (
        <div className="card-body">
          <div className="form-group row">
            <div className="col-md-6">
              <label htmlFor="insurerCompanyName">Наименование</label>
              <InputMask
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerCompanyName', formData, setFormData, null)}
                id="insurerCompanyName"
                name="insurerCompanyName"
                placeholder="Введите наименование компании"
                formatChars={getMask('name').formatChars}
                className={classnames('form-control', { error: formData.insurerCompanyName.errorMessage })}
                value={formData.insurerCompanyName.value}
              />
              {formData.insurerCompanyName.errorMessage && (
              <div className="validation-error">
                {formData.insurerCompanyName.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerOGRN">ОГРН</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerOGRN', formData, setFormData, null, { pureValue: true })}
                id="insurerOGRN"
                name="insurerOGRN"
                mask={getMask('ogrn').mask}
                placeholder="1111111111111"
                formatChars={getMask('ogrn').formatChars}
                className={classnames('form-control', { error: formData.insurerOGRN.errorMessage })}
                value={formData.insurerOGRN.value}
              />
              {formData.insurerOGRN.errorMessage && (
              <div className="validation-error">
                {formData.insurerOGRN.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerINN">ИНН</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerINN', formData, setFormData, null, { pureValue: true })}
                id="insurerINN"
                name="insurerINN"
                mask={getMask('inn').mask}
                placeholder="1111111111"
                formatChars={getMask('inn').formatChars}
                className={classnames('form-control', { error: formData.insurerINN.errorMessage })}
                value={formData.insurerINN.value}
              />
              {formData.insurerINN.errorMessage && (
              <div className="validation-error">
                {formData.insurerINN.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerKPP">КПП</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerKPP', formData, setFormData, null, { pureValue: true })}
                id="insurerKPP"
                name="insurerKPP"
                mask={getMask('kpp').mask}
                placeholder="11111111111"
                formatChars={getMask('kpp').formatChars}
                className={classnames('form-control', { error: formData.insurerKPP.errorMessage })}
                value={formData.insurerKPP.value}
              />
              {formData.insurerKPP.errorMessage && (
              <div className="validation-error">
                {formData.insurerKPP.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insurerLegalAdress">Юридический адрес</label>
              <ReactDadata
                id="insurerLegalAdress"
                name="insurerLegalAdress"
                className={classnames('form-control', { error: formData.insurerLegalAdress.errorMessage })}
                query={formData.insurerLegalAdress.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insurerLegalAdress', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField}
                onFocus={setCursorToEnd}
              />
              {formData.insurerLegalAdress.errorMessage && (
              <div className="validation-error">
                {formData.insurerLegalAdress.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insurerActualAddress">Фактический адрес</label>
              <ReactDadata
                id="insurerActualAddress"
                name="insurerActualAddress"
                className={classnames('form-control', { error: formData.insurerActualAddress.errorMessage })}
                query={formData.insurerActualAddress.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insurerActualAddress', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField}
                onFocus={setCursorToEnd}
              />
              {formData.insurerActualAddress.errorMessage && (
              <div className="validation-error">
                {formData.insurerActualAddress.errorMessage}
              </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {(type === 'natural') ? (
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_1">
              <label htmlFor="insurerLastName">Фамилия</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="insurerLastName"
                name="insurerLastName"
                placeholder="Иванов"
                query={formData.insurerLastName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurerLastName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['SURNAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurerLastName.errorMessage })}
              />
              {formData.insurerLastName.errorMessage && (
              <div className="validation-error">
                {formData.insurerLastName.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
              <label htmlFor="insurerFirstName">Имя</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="insurerFirstName"
                name="insurerFirstName"
                placeholder="Иван"
                query={formData.insurerFirstName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurerFirstName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['NAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurerFirstName.errorMessage })}
              />
              {formData.insurerFirstName.errorMessage && (
              <div className="validation-error">
                {formData.insurerFirstName.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="insurerMiddleName">Отчество</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="insurerMiddleName"
                name="insurerMiddleName"
                placeholder="Иванович"
                query={formData.insurerMiddleName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurerMiddleName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['PATRONYMIC']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurerMiddleName.errorMessage })}
              />
              {formData.insurerMiddleName.errorMessage && (
              <div className="validation-error">
                {formData.insurerMiddleName.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerPassport">Серия и номер паспорта</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerPassport', formData, setFormData, null, { pureValue: true })}
                id="insurerPassport"
                placeholder="1111 111111"
                name="insurerPassport"
                className={classnames('form-control', { error: formData.insurerPassport.errorMessage })}
                mask={getMask('passportNumberSerie').mask}
                value={formData.insurerPassport.value}
                inputMode="tel"
              />
              {formData.insurerPassport.errorMessage && (
              <div className="validation-error">
                {formData.insurerPassport.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="insurerPassportDate">Дата выдачи паспорта</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'insurerPassportDate' })}
                className={classnames('form-control', { error: formData.insurerPassportDate.errorMessage })}
                selected={formData.insurerPassportDate.value ? parseISO(formData.insurerPassportDate.value) : null}
                onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'insurerPassportDate', formData, setFormData)}
                name="insurerPassportDate"
                id="insurerPassportDate"
                locale={ru}
                maxDate={new Date()}
                minDate={formData.insurerBirthday.value ? addYears(parseISO(formData.insurerBirthday.value), 14) : subYears(new Date(), 100)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                    }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.insurerPassportDate.errorMessage && (
              <div className="validation-error">
                {formData.insurerPassportDate.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-9">
              <label htmlFor="insurerPassportDivision">Кем выдан</label>
              <Tippy
                className="tippy-custom"
                arrow={false}
                offset={[0, 5]}
                content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
                hideOnClick={false}
              >
                <div className="d-inline-block">
                  <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                </div>
              </Tippy>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerPassportDivision', formData, setFormData)}
                id="insurerPassportDivision"
                name="insurerPassportDivision"
                placeholder=""
                className={classnames('form-control', { error: formData.insurerPassportDivision.errorMessage })}
                value={formData.insurerPassportDivision.value}
              />
              {formData.insurerPassportDivision.errorMessage && (
              <div className="validation-error">
                {formData.insurerPassportDivision.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="insurerPassportUnitNumber">Код подразделения</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerPassportUnitNumber', formData, setFormData, null, { pureValue: true })}
                id="insurerPassportUnitNumber"
                name="insurerPassportUnitNumber"
                mask={getMask('passportUnitNumber').mask}
                placeholder="123-456"
                formatChars={getMask('passportUnitNumber').formatChars}
                className={classnames('form-control', { error: formData.insurerPassportUnitNumber.errorMessage })}
                value={formData.insurerPassportUnitNumber.value}
              />
              {formData.insurerPassportUnitNumber.errorMessage && (
              <div className="validation-error">
                {formData.insurerPassportUnitNumber.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insurerBirthday">Дата рождения</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'insurerBirthday' })}
                className={classnames('form-control', { error: formData.insurerBirthday.errorMessage })}
                selected={formData.insurerBirthday.value ? parseISO(formData.insurerBirthday.value) : null}
                onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'insurerBirthday', formData, setFormData)}
                name="insurerBirthday"
                id="insurerBirthday"
                locale={ru}
                maxDate={new Date()}
                minDate={subYears(new Date(), 100)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.insurerBirthday.errorMessage && (
              <div className="validation-error">
                {formData.insurerBirthday.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerINN">ИНН</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'insurerINN', formData, setFormData, null, { pureValue: true })}
                id="insurerINN"
                name="insurerINN"
                mask={getMask('inn').mask}
                placeholder="1111111111"
                formatChars={getMask('inn').formatChars}
                className={classnames('form-control', { error: formData.insurerINN.errorMessage })}
                value={formData.insurerINN.value}
              />
              {formData.insurerINN.errorMessage && (
              <div className="validation-error">
                {formData.insurerINN.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <label htmlFor="insurerSnils">СНИЛС</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                placeholder="123–321–987 12"
                formatChars={getMask('snils').formatChars}
                mask={getMask('snils').mask}
                onChange={(e) => handleChange(e, 'insurerSnils', formData, setFormData)}
                id="insurerSnils"
                name="insurerSnils"
                className={classnames('form-control', { error: formData.insurerSnils.errorMessage })}
                value={formData.insurerSnils.value}
              />
              {formData.insurerSnils.errorMessage && (
              <div className="validation-error">
                {formData.insurerSnils.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insurerActualAddress">Фактический адрес</label>
              <ReactDadata
                id="insurerActualAddress"
                name="insurerActualAddress"
                className={classnames('form-control', { error: formData.insurerActualAddress.errorMessage })}
                query={formData.insurerActualAddress.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insurerActualAddress', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField}
                onFocus={setCursorToEnd}
              />
              {formData.insurerActualAddress.errorMessage && (
              <div className="validation-error">
                {formData.insurerActualAddress.errorMessage}
              </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className="form-check custom-checkbox">
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.insurerAddressRegistrationFlag.value}
                  name="insurerAddressRegistrationFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="insurerAddressRegistrationFlag"
                />
                <label className="form-check-label" htmlFor="insurerAddressRegistrationFlag">Фактический адрес и адрес регистрации совпадают</label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insurerAddressRegistration">Адрес регистрации</label>
              <ReactDadata
                id="insurerAddressRegistration"
                name="insurerAddressRegistration"
                className={classnames('form-control', { error: formData.insurerAddressRegistration.errorMessage })}
                query={formData.insurerAddressRegistration.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insurerAddressRegistration', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField || formData.insurerAddressRegistrationFlag.value}
                onFocus={setCursorToEnd}
                ref={insurerAddressRegistrationRef}
              />
              {formData.insurerAddressRegistration.errorMessage && (
              <div className="validation-error">
                {formData.insurerAddressRegistration.errorMessage}
              </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
