import React, { useMemo } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, format, parseISO } from 'date-fns';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import { ReactDadata } from 'react-dadata';
import getMask from '../../Utils/Masks';
import { customSelectStyle, handleChange } from '../../Utils/Helpers';
import Insurer from './forms/Insurer';
import Owner from './forms/Owner';

export default function UniversalForm(props) {
  const {
    setCursorToEnd,
    formData,
    disableField,
    handleValidate,
    productType,
    saveDraft,
    setFormData,
    optionType,
    ownerOptionType,
    regionRef,
  } = props;

  const minEndDay = useMemo(() => addDays(new Date(), 1), [formData.startDate.value]);

  return (
    <>
      <form name="universalForm" id="universal_form">
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">1</span>
            <span>Страхователь</span>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="insurerTypeCode">Тип</label>
                <Select
                  isDisabled={disableField}
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="insurerTypeCode"
                  isSearchable={false}
                  placeholder="Выберите тип"
                  id="insurerTypeCode"
                  value={formData.insurerTypeCode.value ? formData.insurerTypeCode.value : null}
                  onChange={(e) => handleChange(e, 'insurerTypeCode', formData, setFormData)}
                  options={optionType}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <Insurer
            props={props}
          />
        </div>
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">2</span>
            <span>Собственник</span>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="ownerTypeCode">Тип</label>
                <Select
                  isDisabled={disableField}
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="ownerTypeCode"
                  isSearchable={false}
                  placeholder="Выберите тип"
                  id="ownerTypeCode"
                  value={formData.ownerTypeCode.value ? formData.ownerTypeCode.value : null}
                  onChange={(e) => handleChange(e, 'ownerTypeCode', formData, setFormData)}
                  options={ownerOptionType}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <Owner
            props={props}
          />
        </div>
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">3</span>
            <span>Продукт</span>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="listProductType">Тип</label>
                <Select
                  isDisabled={disableField}
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="listProductType"
                  isSearchable={false}
                  placeholder="Выберите продукт"
                  id="listProductType"
                  value={formData.listProductType.value ? formData.listProductType.value : null}
                  onChange={(e) => handleChange(e, 'listProductType', formData, setFormData)}
                  options={productType}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">4</span>
            <span>Данные</span>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-12 col-xl-6">
                <label htmlFor="insuranceObject">Объект страхования</label>
                <InputMask
                  maskChar=""
                  onBlur={handleValidate}
                  disabled={disableField}
                  placeholder="Объект страхования"
                  formatChars={getMask('fullName').formatChars}
                  mask={getMask('fullName').mask}
                  onChange={(e) => handleChange(e, 'insuranceObject', formData, setFormData)}
                  id="insuranceObject"
                  name="insuranceObject"
                  className={classnames('form-control', { error: formData.insuranceObject.errorMessage })}
                  value={formData.insuranceObject.value}
                />
                {formData.insuranceObject.errorMessage && (
                <div className="validation-error">
                  {formData.insuranceObject.errorMessage}
                </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12 col-xl-6">
                <label htmlFor="region">Регион</label>
                <ReactDadata
                  id="region"
                  name="region"
                  className={classnames('form-control', { error: formData.region.errorMessage })}
                  query={formData.region.value.value}
                  onBlur={handleValidate}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => handleChange(e, 'region', formData, setFormData)}
                  disabled={disableField}
                  onFocus={setCursorToEnd}
                  bounds="city"
                  ref={regionRef}
                />
                {formData.region.errorMessage && (
                  <div className="validation-error">
                    {formData.region.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 mb-3 mb-lg-0">
                <label htmlFor="startDate">Дата начала действия</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={disableField}
                  onBlur={() => handleValidate(null, { fieldName: 'startDate' })}
                  onCalendarClose={() => handleValidate(null, { fieldName: 'startDate' })}
                  onClickOutside={() => handleValidate(null, { fieldName: 'startDate' })}
                  className={classnames('form-control', { error: formData.startDate.errorMessage })}
                  selected={formData.startDate.value ? parseISO(formData.startDate.value) : null}
                  onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'startDate', formData, setFormData)}
                  name="startDate"
                  id="startDate"
                  locale={ru}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 60)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.startDate.errorMessage && (
                  <div className="validation-error">
                    {formData.startDate.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <label htmlFor="endDate">Дата окончания действия</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={disableField}
                  onBlur={() => handleValidate(null, { fieldName: 'endDate' })}
                  onCalendarClose={() => handleValidate(null, { fieldName: 'endDate' })}
                  onClickOutside={() => handleValidate(null, { fieldName: 'endDate' })}
                  className={classnames('form-control', { error: formData.endDate.errorMessage })}
                  selected={formData.endDate.value ? parseISO(formData.endDate.value) : null}
                  onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'endDate', formData, setFormData)}
                  name="endDate"
                  id="endDate"
                  locale={ru}
                  minDate={minEndDay}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.endDate.errorMessage && (
                  <div className="validation-error">
                    {formData.endDate.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row row-grid">
          <div className="col-lg-12">
            <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={saveDraft}>Сохранить</button>
          </div>
        </div>
      </form>
    </>
  );
}
