import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: 0;
    padding: 0;
    list-style: none;
    
    @media (max-width: 1023px) {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-right: -24px;
    }

    @media (max-width: 767px) {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
    }
`;

const Form = styled.div`
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`;

const List = styled.ul`
    margin: 0 -10px -10px 0;

    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;

    width: calc(100% + 10px);

    @media (max-width: 1023px) {
        margin: 0;
        padding-right: 24px;
        padding-left: 24px;
        width: max-content;
        flex-wrap: nowrap;
    }

    @media (max-width: 767px) {
        padding-right: 16px;
        padding-left: 16px;
    }
`;
function TabNavList(props) {
  const { children } = props;

  return (
    <Container>
      <Form>
        <List>
          {Array.isArray(children) ? (
            children.map((child) => child)
          ) : (
            children
          )}
        </List>
      </Form>
    </Container>
  );
}

export default TabNavList;
