import React, { useContext, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../../Utils/Helpers';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function InsurexWidget() {
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );
  const { showModalInfo } = useContext(ModalMessagesContext);

  const [insurexId, setInsurexId] = useState(null);

  const widgetBlockId = 'polis-online-insurex-widget';

  const tsIdTypeMapper = {
    vin: 1,
    body: 2,
    chassis: 3,
  };

  const carDocumentTypeMapper = {
    sertificate: 31,
    passport: 30,
    epassport: 41,
  };

  const purposeOfUseMapper = {
    personal: 'Личная',
    taxi: 'Такси',
    training: 'Учебная езда',
    dangerous: 'Перевозка опасных и легко воспламеняющихся грузов',
    rental: 'Прокат/Краткосрочная аренда',
    passenger: 'Регулярные/по заказам пассажирские перевозки',
    special: 'Дорожные и специальные ТС',
    services: 'Экстренные и коммунальные службы',
    other: 'Прочее',
  };

  const getInsurexId = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurex/getId`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        }
        if (!response.error) {
          setInsurexId(response.insurex_id);
        }
      })
      .catch((error) => console.log(error));
  };

  const fillFieldsToWidget = (formData) => {
    const fields = {
      comment: 'Проверьте правильность заполнения полей, КБМ!',
      dateDeadline: '',
      formData: {
        policy: {
          startDate: formData.policyDate ? (formData.policyDate.split('.').reverse().join('-')) : null,
          followToRegistration: false,
          insurancePeriod: null,
          purposeOfUse: purposeOfUseMapper[formData.targetOfUsing],
        },
        vehicle: {
          regNumber: formData.registrationNumberBlank ? null : formData.carNumber,
          isRegNumForeign: false,
          tsIdType: tsIdTypeMapper[formData.vehicleIdentificationType],
          tsIdValue: formData.vehicleIdentificationNumberVin || formData.vehicleIdentificationNumberBody || formData.vehicleIdentificationNumberChassis || null,
          make: formData.markName || null,
          model: formData.modelName || null,
          power: formData.horsePower || null,
          yearIssue: formData.carYear || null,
          category: formData.category || null,
          maxMass: formData.category === 'C' ? formData.allowedMaxWeight : null,
          unladenMass: null,
          passQuantity: formData.category === 'D' ? formData.vehiclePassengersCapacity : null,
          trailerUsage: formData.useTrailer,
          carDocument: {
            type: carDocumentTypeMapper[formData.vehicleTypeDocument],
            series: formData.vehicleTypeDocument !== 'epassport' ? formData.serialDocument : null,
            number: formData.vehicleTypeDocument !== 'epassport' ? formData.numberDocument : formData.numberEDocument,
            issueDate: formData.dateDocument ? (formData.dateDocument.split('.').reverse().join('-')) : null,
          },
        },
        insurerIsOwner: formData.checkboxOwner,
        insurer: {
          email: formData.email,
          name: formData.firstNameInsured,
          surname: formData.lastNameInsured,
          patronymic: formData.middleNameOwner,
          birthdate: formData.insuredBirthday.split('.').reverse().join('-'),
          address: formData.insuredAddress,
          region: formData.insuredAddressData.region_with_type,
          passport: {
            series: formData.insuredPassportNumberSerial.substring(0, 4),
            number: formData.insuredPassportNumberSerial.substring(4, 10),
            isOtherDoc: false,
            issueDate: formData.insuredPassportDate.split('.').reverse().join('-'),
          },
        },
        driversRestriction: !formData.unlimitedDrivers,
        drivers: [],
      },
      productId: 1,
      rewardAmount: null,
    };
    if (formData.prevPolicyCheck) {
      fields.formData.policy.prevPolicy = {
        series: formData.prevPolicySerial,
        number: formData.prevPolicyNumber,
        date: formData.prevPolicyDate ? (formData.prevPolicyDate.split('.').reverse().join('-')) : '',
      };
    }
    if (!formData.checkboxOwner) {
      fields.formData.owner = {
        name: formData.firstNameOwner,
        surname: formData.lastNameOwner,
        patronymic: formData.middleNameOwner,
        birthdate: formData.ownerBirthday.split('.').reverse().join('-'),
        address: formData.ownerAddress,
        region: formData.ownerAddressData.region_with_type,
        passport: {
          series: formData.ownerPassportNumberSerial.substring(0, 4),
          number: formData.ownerPassportNumberSerial.substring(4, 10),
          isOtherDoc: false,
          issueDate: formData.ownerPassportDate.split('.').reverse().join('-'),
        },
      };
    }
    if (!formData.unlimitedDrivers) {
      const kbmArray = formData.kbms ? formData.kbms.split(',') : null;
      formData.lastNameDriver.forEach((lastNameDriver, index) => {
        fields.formData.drivers.push({
          name: formData.firstNameDriver[index],
          surname: lastNameDriver,
          patronymic: formData.middleNameDriver[index],
          birthdate: formData.driverBirthday[index].split('.').reverse().join('-'),
          driverLicense: {
            series: formData.driverLicenceSerial[index],
            number: formData.driverLicenceNumber[index],
            isForeign: !!formData.driverForeignLicence[index],
          },
          experienceStartDate: formData.driverExperienceDate[index].split('.').reverse().join('-'),
          kbm: kbmArray?.[index],
          prevDriverLicense: {
            series: formData.driverPrevLicenceSerial[index] ? formData.driverPrevLicenceSerial[index] : '',
            number: formData.driverPrevLicenceNumber[index] ? formData.driverPrevLicenceNumber[index] : '',
            isForeign: !!formData.driverPrevForeignLicence[index],
          },
        });
      });
    }

    switch (formData.policyPeriod) {
      case '12':
        fields.formData.policy.insurancePeriod = '12 месяцев';
        break;
      case '6':
        fields.formData.policy.insurancePeriod = '6 месяцев';
        break;
      case '3':
        fields.formData.policy.insurancePeriod = '3 месяца';
        break;
      default:
        fields.formData.policy.insurancePeriod = null;
        break;
    }
    return fields;
  };

  useEffect(() => {
    if (userInfo.id && !userInfo.insurex_id) {
      getInsurexId();
    }
    if (userInfo.id && userInfo.insurex_id) {
      setInsurexId(userInfo.insurex_id);
    }
  }, [userInfo]);

  useEffect(() => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
    if (insurexId) {
      const policyId = new URL(document.location.toString()).searchParams.get('policyId');
      if (policyId) {
        const token = `Bearer ${localStorage.getItem('id_token')}`;
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policyId}`, {
          headers: {
            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((policy) => {
            const formData = policy.form_data;
            formData.kbms = policy.kbms;
            const fields = fillFieldsToWidget(formData);
            script2.innerHTML = `window.addEventListener("exchangeLoaded", (function(e) {widgetExchange({apiKey:"${insurexId}", id: "${widgetBlockId}", linkFAQ: "https://docs.google.com/spreadsheets/d/13NqFXdOSaz32n0HkF72AQqwq_4zG6xDsw0jC8GrmG6k/edit#gid=559832677", dark: "false", theme: "brightGreen", productsId: [1,4,5], fields:${JSON.stringify(fields)}})}))`;
            document.body.appendChild(script2);
            script.src = 'https://app.insrex.ru/js/widget/widget.js'; // боевой виджет
            document.body.appendChild(script);
          });
      }
      if (!policyId) {
        script2.innerHTML = `window.addEventListener("exchangeLoaded", (function(e) {widgetExchange({apiKey:"${insurexId}", id: "${widgetBlockId}", linkFAQ: "https://docs.google.com/spreadsheets/d/13NqFXdOSaz32n0HkF72AQqwq_4zG6xDsw0jC8GrmG6k/edit#gid=559832677", dark: "false", theme: "brightGreen", productsId: [1,4,5]})}))`;
        document.body.appendChild(script2);
        script.src = 'https://app.insrex.ru/js/widget/widget.js'; // боевой виджет
        document.body.appendChild(script);
      }
      return () => {
        if (insurexId) {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        }
      };
    }
  }, [insurexId]);

  return (
    <LoadingBanner loadingFlag={!insurexId}>
      <div id={widgetBlockId} />
    </LoadingBanner>
  );
}
