import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customSelectStyle, customFetch } from '../Utils/Helpers';
import AgentsForm from './AgentsForm';
import AgentsTable from './AgentsTable';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const insuranceList = [
  { label: 'Ренессанс Страхование', value: 'renessans' },
];

const customStyles = customSelectStyle();

class Agents extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      generateXlsxTableFlag: false,
      agent: [],
      activeTab: '1',
      agentsInsuranceIdModalFlag: false,
      selectedInsurance: insuranceList[0],
      setInsuranceIdForAllAgentsFlag: false,
    };
  }

  componentDidMount() {
    document.title = 'Список пользователей';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  toggleAgentsInsuranceIdModal = () => {
    this.setState((prev) => ({ agentsInsuranceIdModalFlag: !prev.agentsInsuranceIdModalFlag }));
  }

  handleChangeSelect = (event) => {
    this.setState({
      selectedInsurance: event,
    });
  }

  setInsuranceIdForAllAgents = (e) => {
    e.preventDefault();

    const { selectedInsurance } = this.state;
    const { showModalInfo } = this.context;
    this.setState({ setInsuranceIdForAllAgentsFlag: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/all/insurance-info/set`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance: selectedInsurance.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo(`Запущена процедура получения id в ${selectedInsurance.label} для всех пользователей`);
          this.toggleAgentsInsuranceIdModal();
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .finally(() => {
        this.setState({ setInsuranceIdForAllAgentsFlag: false });
      });
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  setGenerateXlsxTableFlag = (value) => {
    this.setState({ generateXlsxTableFlag: value });
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  }

  render() {
    const {
      agent, activeTab, agentsInsuranceIdModalFlag, selectedInsurance, setInsuranceIdForAllAgentsFlag, generateXlsxTableFlag,
    } = this.state;
    const {
      history, location, userInfo,
    } = this.props;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={agentsInsuranceIdModalFlag}
          onClose={this.toggleAgentsInsuranceIdModal}
          center
        >
          <h2>Получение id страховой компании для всех пользователей</h2>
          <div className="form-group row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <Select
                menuPlacement="auto"
                className="form-control-custom"
                classNamePrefix="react-select"
                styles={customStyles}
                name="insurance"
                noOptionsMessage={() => 'Не найдено'}
                inputId="insurance"
                isSearchable={false}
                value={selectedInsurance}
                onChange={(e) => this.handleChangeSelect(e)}
                options={insuranceList}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <button type="button" className="btn btn-success" onClick={this.setInsuranceIdForAllAgents} disabled={setInsuranceIdForAllAgentsFlag}>Получить данные</button>
            </div>
            {setInsuranceIdForAllAgentsFlag ? (
              <div>
                <LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} />
              </div>
            ) : null}
          </div>
        </Modal>
        <h1 className="page-wrapper__title d-inline-block mr-3">Список пользователей</h1>
        <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
          {activeTab === '4' ?
            generateXlsxTableFlag
              ? <LoadingSpinner className="loading-circle mr-1 d-inline-block" type="spin" height={38} width={38} />
              : (
                <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
                  <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                  Скачать в Excel
                </Button>
              ) : null}
          <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
            <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
            Настроить таблицу
          </Button>
        </div>
        <h2>Детализация вашей сети пользователей</h2>
        {(userInfo.role === 'admin' || userInfo.role === 'superadmin')
          ? (
            <div className="form-group row">
              <div className="col-lg-12">
                <button type="button" className="btn btn-success mr-3" onClick={this.toggleAgentsInsuranceIdModal}>Получить id страховой компании для всех пользователей</button>
              </div>
            </div>
          )
          : null}
        {(userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' || userInfo.agent_type_code === 'self_employed' || userInfo.full_time_employee === 1)
          ? (
            <div className="form-group row">
              <div className="col-lg-12">
                <button type="button" className="btn btn-success mr-3" onClick={() => this.toggle('2')}>Добавить пользователя</button>
              </div>
            </div>
          )
          : null}
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { this.toggle('1'); }}
            text="Список пользователей"
          />
          <TabNavItem
            active={activeTab === '3'}
            onClick={() => { this.toggle('3'); }}
            text="Заблокированные пользователи"
          />
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabNavItem
              active={activeTab === '4'}
              onClick={() => { this.toggle('4'); }}
              text="Все пользователи (админ)"
            />
          )}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            { activeTab === '1' ? <AgentsTable ref={activeTab === '1' ? this.child : undefined} history={history} tab="1" agent={agent} location={location} /> : null }
          </TabPane>
          {(userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' || userInfo.agent_type_code === 'self_employed' || userInfo.full_time_employee === 1)
            ? (
              <TabPane tabId="2">
                { activeTab === '2' ? <AgentsForm history={history} tab="2" location={location} /> : null }
              </TabPane>
            ) : null}
          <TabPane tabId="3">
            { activeTab === '3' ? <AgentsTable ref={activeTab === '3' ? this.child : undefined} history={history} tab="3" blockedAgents agent={agent} location={location} /> : null }
          </TabPane>
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
          <TabPane tabId="4">
            { activeTab === '4' ? <AgentsTable ref={activeTab === '4' ? this.child : undefined} setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} history={history} tab="4" agent={agent} showAll location={location} /> : null }
          </TabPane>
          )}
        </TabContent>
      </>
    );
  }
}

Agents.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(Agents);
