import React, { useMemo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import car from '../../img/cards/new-car.png';

const Card = styled.div`
    box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    background-color: #fff;
    transition: box-shadow .15s ease, -webkit-box-shadow .15s ease;
    
    &:hover{
        box-shadow: 0 16px 30px rgba(132, 132, 132, .10);
    }
`;

export default function ProductBlock(props) {
  const {
    history, badge, name, type, disabled, path, children, target, img, category, customClassNames,
  } = props;

  const goToPath = (e) => {
    e.preventDefault();
    if (path) {
      if (target === '_self') {
        history.push(path);
      }
      if (target === '_blank') {
        window.open(path, '_blank');
      }
    }
  };

  const visible = useMemo(() => {
    const result = false;

    if (type === 'Сервис' && category === 'service') {
      return true;
    }

    if (category === 'all') {
      return true;
    }

    if (type === 'Оформление' && category === 'insurance') {
      return true;
    }

    return result;
  }, [category]);

  return (
    <div style={{ display: visible ? 'block' : 'none' }} className={classnames(`product-cards__item ${name === 'ОСАГО' ? 'product-cards__item--large' : ''}`, { disabled }, `${customClassNames || ''}`)}>
      <Card className={classnames('card product-block h-100', { disabled })}>
        <a href="#" onClick={(e) => goToPath(e)} className="product-block__link">
          <div className="card-img-overlay">
            {img ? (
              <img
                style={{
                  maxHeight: '20px', position: 'absolute', left: img.left, top: '15px',
                }}
                src={img.path}
                alt=""
              />
            ) : null}
            <div className="product-block__type">{type}</div>
            <div className="product-block__title">{name}</div>
            <div className="product-block__description">
              {children}
            </div>
            {badge ? (
              <div className="product-block__badge">{badge}</div>
            ) : null}
            {name === 'ОСАГО' ? (
              <div className="product-cards__item--osago">
                <div className="tags">
                  <div className="tag tag-payment">Оплата онлайн</div>
                  <div className="tag">Электронный полис</div>
                </div>
                <img src={car} className="imagine" alt="car-img" />
              </div>
            ) : null}
          </div>
        </a>
      </Card>
    </div>
  );
}
