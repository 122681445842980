import styled, { css } from 'styled-components';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { ButtonBlock } from '../Button';
import useUserInfoStore from '../../Stores/UserInfoStore';

export const TableAnswersAmountContainer = styled.div`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  ${ButtonBlock} {
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    min-width: initial;
    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    svg {
      color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#0bd95d')};
      transition: color .15s ease;
      @media (max-width: 1023px) {
        width: 24px;
        height: 24px;
      }
    }
    &:hover {
        background-color: #e9e9e9;
        border: 1px solid #e9e9e9;
    }
  }
  ${(props) => props.block === 'commission' && css`
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  `}
`;

export const TableAnswersAmountContainerValue = styled.span`
  white-space: nowrap;
  margin: 0 8px 0 0;
  @media (max-width: 767px) {
    margin: 0;
  }
  span {
    margin: 0 3px 0 0;
    font-size: 16px;
    font-weight: 600;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
`;

export const TableAnswersAmountContainerDiscount = styled.span`
  padding: 0 7px;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  color: #a3a3a3;
  font-size: 12px;
  line-height: 22px;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .text,.bracket {
    display: none;
    @media (max-width: 767px) {
      color: #a3a3a3;
      font-size: 12px;
      line-height: 22px;
      display: inline;
      width: initial;
    }
  }
  .value {
    margin: 0 3px 0 0;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
`;

export const TableAnswersAmountContainerRef = styled.span`
  display: none;
  color: #a3a3a3;
  font-size: 12px;
  line-height: 22px;
  @media (max-width: 767px) {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
`;

export default function TableAnswersAmount(props) {
  const {
    children, block,
  } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <TableAnswersAmountContainer block={block} theme={theme}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableAnswersAmountContainer>
  );
}
