import React, {
  useContext, useEffect, useState,
} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { faCircle } from '@fortawesome/fontawesome-free-solid';
import { format, parseISO } from 'date-fns';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch, getDefaultSorting7, onFilteredChangeCustom,
  setTableSettings7,
  setTableSorting,
} from '../../Utils/Helpers';
import Table from '../../Layout/Table';
import CustomIcon from '../../Layout/CustomIcon';

export default function CustomContractsTable(props) {
  const {
    agent, tab, setSelectedContract, setShowForm,
  } = props;
  const defaultSorting = getDefaultSorting7('customContractsTableSettings');
  const [filtered, setFiltered] = useState([]);
  const [columns, setColumns] = useState([]);
  const [visiblePassword, setVisiblePassword] = useState({});
  const [loadingCustomContractsFlag, setLoadingCustomContractsFlag] = useState(false);
  const [customContracts, setCustomContracts] = useState([]);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const getCustomContracts = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingCustomContractsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/customContracts?agentId=${agent.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCustomContracts(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingCustomContractsFlag(false);
      });
  };

  const deleteCustomContract = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/customContracts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          getCustomContracts();
          showModalInfo('Кастомизация удалена');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      });
  };

  const changeVisiblePassword = (id) => {
    setVisiblePassword((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Переключаем значение на противоположное
    }));
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'ID',
      accessor: 'id',
      width: reactLocalStorage.getObject('customContractsTableSettings').id,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_id !== false,
    }, {
      Header: 'Страховая',
      id: 'insurance',
      accessor: (d) => d.insurance.name,
      width: reactLocalStorage.getObject('customContractsTableSettings').insurance,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_insurance !== false,
    }, {
      Header: 'Продукт',
      id: 'product',
      accessor: (d) => d.product.name,
      width: reactLocalStorage.getObject('customContractsTableSettings').product,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_product !== false,
    }, {
      Header: 'Риск',
      id: 'risk',
      accessor: (d) => (d.risk ? d.risk.name : 'Общий'),
      width: reactLocalStorage.getObject('customContractsTableSettings').risk,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_risk !== false,
    }, {
      Header: 'Канал продаж',
      width: reactLocalStorage.getObject('customContractsTableSettings').channel,
      accessor: 'channel',
      Cell: ({ row }) => {
        if (row.original.channel === 'both') {
          return 'Оба';
        }
        return row.original.channel;
      },
      show: reactLocalStorage.getObject('customContractsTableSettings').show_channel !== false,
    }, {
      Header: 'Логин',
      accessor: 'login',
      width: reactLocalStorage.getObject('customContractsTableSettings').login,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_login !== false,
    }, {
      Header: 'Пароль',
      accessor: 'password',
      clickable: true,
      id: 'password',
      Cell: ({ row }) => (
        <div className="d-flex"><div className="mr-1" onClick={() => changeVisiblePassword(row.original.id)} title="Скрыть/показать"><CustomIcon icon="eye" width="14px" height="14px" pureSvg /></div><div>{visiblePassword[row.original.id] ? row.original.password : '******'}</div></div>
      ),
      width: reactLocalStorage.getObject('customContractsTableSettings').password,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_password !== false,
    }, {
      Header: 'Активность',
      accessor: 'active',
      Cell: ({ row }) => {
        if (row.original.active) {
          return (<FontAwesomeIcon color="#00E646" icon={faCircle} className="fa-fw" />);
        }
        return (<FontAwesomeIcon color="#ff5f00" icon={faCircle} className="fa-fw" />);
      },
      width: reactLocalStorage.getObject('customContractsTableSettings').active,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_active !== false,
    }, {
      Header: 'Создано',
      accessor: 'created_at',
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('customContractsTableSettings').created_at,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_created_at !== false,
    }, {
      Header: 'Изменено',
      accessor: 'updated_at',
      Cell: ({ row }) => format(parseISO(row.original.updated_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('customContractsTableSettings').updated_at,
      show: reactLocalStorage.getObject('customContractsTableSettings').show_updated_at !== false,
    }, {
      Header: () => null, // No header
      id: 'delete',
      maxWidth: 10,
      disableFilters: true,
      show: true,
      clickable: true,
      Cell: ({ row }) => (
        <div title="Удалить кастомизацию"><FontAwesomeIcon onClick={() => deleteCustomContract(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    setColumns(updatedColumns);
  };

  const onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable) {
      setSelectedContract(row.original.id);
      setShowForm(true);
    }
  };

  useEffect(() => {
    if (agent.id) {
      getCustomContracts();
      updateTableColumns();
    }
  }, []);

  useEffect(() => {
    updateTableColumns();
  }, [visiblePassword]);

  return (
    <>
      <Table
        onTableCellClick={onTableCellClick}
        defaultSorted={defaultSorting}
        defaultFiltered={filtered}
        data={customContracts}
        columns={columns}
        loading={loadingCustomContractsFlag}
        noDataText="Интеграции не найдены"
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'customContractsTableSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'customContractsTableSettings');
        }}
        onFilteredChange={(newFiltered) => {
          setFiltered(onFilteredChangeCustom(newFiltered, null, tab));
        }}
      />
    </>
  );
}
