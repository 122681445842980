import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import Modal from 'react-responsive-modal';
import classnames from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch, validateFields } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';

export default function InterfaceTourOptions(props) {
  const { setTour, tour, flushCurrentTour } = props;
  const [flagModalTourDelete, setFlagModalTourDelete] = useState(false);
  const [savingTourFlag, setSavingTourFlag] = useState();
  const [tourData, setTourData] = useState({
    name: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'plain',
    },
    code: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'plain',
    },
    button_info: {
      value: null,
      validationRequired: false,
      errorMessage: '',
      validationType: 'plain',
    },
    url_show_at: {
      value: null,
      validationRequired: false,
      errorMessage: '',
      validationType: 'plain',
    },
    steps: [
      {
        selector: 'new_dt_step_1',
        name: '',
        content: EditorState.createEmpty(),
        domainsToExclude: '',
      },
    ],
  });
  const [resetStepsSelectorsFlag, setResetStepsSelectorsFlag] = useState(false);

  const NEW_STEP = {
    selector: '',
    content: '',
    name: '',
    domainsToExclude: '',
  };

  const { showModalInfo } = useContext(ModalMessagesContext);

  const resetStepsSelectors = () => {
    const newSteps = tourData.steps.map((step, index) => ({
      ...step,
      selector: `.${tourData.code.value}_dt_step_${index + 1}`,
    }));

    setTourData((prev) => ({
      ...prev,
      steps: newSteps,
    }));
  };

  useEffect(() => {
    if (resetStepsSelectorsFlag) {
      resetStepsSelectors();
      setResetStepsSelectorsFlag(false);
    }
  }, [resetStepsSelectorsFlag]);

  const loadTourData = () => {
    setTourData({
      name: {
        value: tour.name,
        validationRequired: true,
        errorMessage: '',
        validationType: 'plain',
      },
      code: {
        value: tour.code,
        validationRequired: true,
        errorMessage: '',
        validationType: 'plain',
      },
      button_info: {
        value: tour.button_info,
        validationRequired: false,
        errorMessage: '',
        validationType: 'plain',
      },
      url_show_at: {
        value: tour.url_show_at,
        validationRequired: false,
        errorMessage: '',
        validationType: 'plain',
      },
      steps: tour.steps ? tour.steps.map((step) => {
        try {
          return {
            selector: step.selector,
            name: step.name,
            content: EditorState.createWithContent(convertFromRaw(JSON.parse(step.content))),
            domainsToExclude: step.domainsToExclude,
          };
        } catch {
          const blocks = {
            blocks: [
              {
                key: 'b08o6',
                text: step.content,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };

          return {
            selector: step.selector,
            name: step.name,
            content: EditorState.createWithContent(convertFromRaw(blocks)),
            domainsToExclude: step.domainsToExclude,
          };
        }
      }) : [{
        selector: `.${tourData.code.value}_dt_step_1`, name: '', content: EditorState.createEmpty(), domainsToExclude: '',
      }],
    });
  };

  useEffect(() => {
    if (tour.id) {
      loadTourData();
    }
  }, [tour]);

  const handleChangeInput = (e) => {
    const { target } = e;
    setTourData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: target.value,
        errorMessage: '',
      },
    }));
  };

  const handleBlurInput = (e) => {
    const { target } = e;
    if (target.id === 'code') {
      setResetStepsSelectorsFlag(true);
    }
  };

  const delTour = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/${tour.id}/delete`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          flushCurrentTour();
          setFlagModalTourDelete(false);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const validateTourData = () => {
    const formFields = { ...tourData };
    delete formFields.steps;
    const validationResult = validateFields(formFields);
    const validatedData = { ...validationResult.result };
    validatedData.steps = [...tourData.steps];
    setTourData(validatedData);
    return validationResult.validationSuccess;
  };

  const handleSaveTour = (e) => {
    e.preventDefault();

    const isValid = validateTourData();
    if (isValid) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      const tourInfo = {
        name: tourData.name.value,
        code: tourData.code.value,
        button_info: tourData.button_info.value,
        url_show_at: tourData.url_show_at.value,
        steps: tourData.steps.map((step) => ({
          ...step,
          content: step.content ? JSON.stringify(convertToRaw(step.content.getCurrentContent())) : '',
        })),
      };

      setSavingTourFlag(true);

      customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/${tour.id}/update`, {
        method: 'put',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tourInfo }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            setTour((prev) => ({
              ...prev,
              name: tourInfo.name,
              code: tourInfo.code,
              button_info: tourInfo.button_info,
              url_show_at: tourInfo.url_show_at,
              steps: [...tourInfo.steps],
            }));
            showModalInfo(`Демо-тур "${tourInfo.name}" успешно сохранен`);
          } else {
            showModalInfo(response.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => { setSavingTourFlag(false); });
    }
  };

  const deleteSale = (e, index) => {
    e.preventDefault();
    const newSteps = [...tourData.steps];
    newSteps.splice(index, 1);

    setTourData((prev) => ({
      ...prev,
      steps: newSteps,
    }));
    setResetStepsSelectorsFlag(true);
  };

  const handleEditorChange = (state, index) => {
    setTourData((prev) => {
      const newSteps = [...prev.steps];
      newSteps[index].content = state;
      return { ...prev, steps: newSteps };
    });
  };

  const handleStepNameChange = (e, index) => {
    setTourData((prev) => {
      const newSteps = [...prev.steps];
      newSteps[index].name = e.target.value;
      return { ...prev, steps: newSteps };
    });
  };

  const handleStepDomainsChange = (e, index) => {
    setTourData((prev) => {
      const newSteps = [...prev.steps];
      newSteps[index].domainsToExclude = e.target.value;
      return { ...prev, steps: newSteps };
    });
  };

  const addNewStep = (e) => {
    e.preventDefault();
    const newSteps = [...tourData.steps];
    newSteps.push(NEW_STEP);

    setTourData((prev) => ({
      ...prev,
      steps: newSteps,
    }));
    setResetStepsSelectorsFlag(true);
  };

  const stepsBlock = useMemo(() => tourData.steps.map((step, index) => (
    <div key={index} className="mb-4">
      <p style={{ marginBottom: '5px' }}>Шаг №{index + 1}<a onClick={(e) => { deleteSale(e, index); }} className="close-driver">×</a></p>
      <div className="form-row">
        <div className="form-group col-lg-4 col-xl-4">
          <label htmlFor={`selector_${index}`}>Селектор</label>
          <input
            value={step.selector}
            type="text"
            className="form-control"
            id={`selector_${index}`}
            name={`selector_${index}`}
            disabled
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-lg-4 col-xl-4">
          <label htmlFor={`name_${index}`}>Наименование</label>
          <input
            value={step.name}
            type="text"
            className="form-control"
            id={`name_${index}`}
            name={`name_${index}`}
            onChange={(e) => { handleStepNameChange(e, index); }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <label htmlFor={`content_${index}`}>Текст</label>
          <Editor
            stripPastedStyles
            editorState={tourData.steps[index].content}
            wrapperClassName="demo-wrapper"
            editorClassName="form-control"
            onEditorStateChange={(state) => { handleEditorChange(state, index); }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <label htmlFor={`domains_${index}`}>Скрывать шаг для следующих доменов (через / без пробелов)</label>
          <input
            value={step.domainsToExclude}
            type="text"
            className="form-control"
            id={`domains_${index}`}
            name={`domains_${index}`}
            onChange={(e) => {
              handleStepDomainsChange(e, index);
            }}
          />
        </div>
      </div>
    </div>
  )), [tourData.steps]);

  return (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <div className="position-relative">
            <div className="form-row mb-2">
              <SimpleNotification>Код демо-тура используется для его запуска в системе и создания уникальных идентификаторов шагов демо-тура</SimpleNotification>
            </div>
            <div className="form-group row">
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="name">Наименование</label>
                <input
                  value={tourData.name.value}
                  onChange={handleChangeInput}
                  name="name"
                  className={classnames('form-control', { error: tourData.name.errorMessage })}
                  id="name"
                />
                {tourData.name.errorMessage && (
                  <div className="validation-error">
                    {tourData.name.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="code">Код</label>
                <input
                  value={tourData.code.value}
                  onChange={handleChangeInput}
                  name="code"
                  className={classnames('form-control', { error: tourData.code.errorMessage })}
                  id="code"
                  onBlur={handleBlurInput}
                />
                {tourData.code.errorMessage && (
                  <div className="validation-error">
                    {tourData.code.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row mb-2">
              <SimpleNotification>Вы можете указать текст кнопки окончания демо-тура (например «Оформите полис ОСАГО») и url показа демо тура (тур будет загружен и отображен на данном url, например на определенном черновике)</SimpleNotification>
            </div>
            <div className="form-group row">
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="button_info">Текст кнопки</label>
                <input
                  value={tourData.button_info.value}
                  onChange={handleChangeInput}
                  name="button_info"
                  className="form-control"
                  id="button_info"
                />
              </div>
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="button_info">Url показа тура</label>
                <input
                  value={tourData.url_show_at.value}
                  onChange={handleChangeInput}
                  name="url_show_at"
                  className="form-control"
                  id="url_show_at"
                />
              </div>
            </div>
          </div>
          <h5 className="mb-2">Шаги</h5>
          <div className="form-row mb-2">
            <SimpleNotification>Селектор заполняется автоматичкески и используется в програмном коде для отображения демо-тура</SimpleNotification>
          </div>
          {stepsBlock}
          <div className="row">
            <div className="col-lg-3">
              <button onClick={addNewStep} type="button" className="btn btn-border btn-success">+ Еще один шаг</button>
            </div>
          </div>
          <br />
        </div>
      </div>
      {savingTourFlag ? (
        <div className="form-group row">
          <div className="col-lg-12">
            <LoadingSpinner className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={20} width={20} />
          </div>
        </div>
      ) : null}
      <div className="form-group row">
        <div className="col-lg-12">
          <button type="button" disabled={savingTourFlag} className="btn btn-success mr-3 mb-3" onClick={(e) => { handleSaveTour(e); }}>Сохранить</button>
          <button type="button" className="btn btn-danger mr-3 mb-3" onClick={() => { setFlagModalTourDelete(true); }}>Удалить демо-тур</button>
        </div>
      </div>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTourDelete}
        onClose={() => { setFlagModalTourDelete(false); }}
        center
      >
        <h4>Вы уверены?</h4>
        <button className="btn btn-success mr-2" type="button" onClick={(e) => delTour(e)}>Да</button>
        <button type="button" className="btn btn-secondary" onClick={() => { setFlagModalTourDelete(false); }}>Нет</button>
      </Modal>
    </>
  );
}
