import React, { Component } from 'react';
import { format, parse, parseISO } from 'date-fns';
import ReactTable from 'react-table-6';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

class MailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      emailList: [],
      email: this.props.email,
    };
  }

  componentDidMount() {
    const { email } = this.state;
    if (email) {
      this.downloadMails(email);
    }
  }

  componentDidUpdate(prevProps) {
    const { email } = this.props;
    if (prevProps.email !== email) {
      this.downloadMails(email);
    }
  }

  downloadMails = (email) => {
    const { showModalInfo } = this.context;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mail/getList?email=${email}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({ emailList: response, loading: false });
        } else {
          showModalInfo('Ошибка загрузки данных');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки данных');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      loading, emailList,
    } = this.state;
    const columns = [{
      Header: 'Email',
      accessor: 'mailto',
    }, {
      Header: 'Тема письма',
      accessor: 'subject',
    }, {
      Header: 'Дата отправки',
      id: 'date',
      accessor: (d) => format(parseISO(d.date), 'dd.MM.yyyy'),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
    }, {
      Header: 'Доставлено',
      accessor: 'status',
      Cell: ({ original }) => {
        if (original.status === 'sent') return 'Да';
        return 'Нет';
      },
    }, {
      Header: 'Открыто',
      accessor: 'is_open',
      Cell: ({ original }) => {
        if (original.is_open) return 'Да';
        return 'Нет';
      },
    },
    ];
    return (
      <>
        <ReactTable
          className="table"
          data={emailList}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
        />
      </>
    );
  }
}

MailList.contextType = ModalMessagesContext;

export default MailList;
