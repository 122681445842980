import React from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../../Stores/UserInfoStore';

const Container = styled.li`
    margin: 0 10px 10px 0;
    box-shadow: none;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    background-color: #fff;
    color: #3d3d3b;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    transition: background-color .15s ease, border-color .15s ease;
    
    &:hover{
        border-color: #a3a3a3;
        background-color: #fff;
    }
    
    ${(props) => {
    const backGround = props.theme && props.theme.subColor ? props.theme.subColor : '#f0f000';
    if (props.active) {
      return `border-color: ${backGround}; background-color: ${backGround} !important`;
    }
    return '';
  }};

    @media (max-width: 1023px) {
        margin: 0 0 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

function TabNavItem(props) {
  const { text, onClick, active } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <Container onClick={onClick} active={active} theme={theme}>{text}</Container>
  );
}

export default TabNavItem;
