import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format } from 'date-fns';
import styled from 'styled-components';
import AuthService from '../Utils/AuthService';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Footer from '../Layout/Footer';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const ContentContainer = styled.div`
    background-color: #f6f6f6;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();
    this.state = {
      toggleSendPasswordForm: false,
      openModalWindow: false,
      passwordInputType: 'password',
      resetingPassword: false,
      isFramed: 0,
    };
  }

  componentWillMount() {
    const { history, location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (reactLocalStorage.get('financesBlock') && reactLocalStorage.get('financesBlock') < format(new Date(), 'dd')) {
      reactLocalStorage.remove('financesBlock');
    }
    if (urlParams.token) {
      localStorage.setItem('id_token', urlParams.token);
    }
    if (this.Auth.loggedIn()) { history.replace('/products'); }
  }

  componentDidMount() {
    let isFramed;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    document.title = `Вход в личный кабинет | ${window.location.host}`;
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { history, location } = this.props;
    const { email, passwordUser } = this.state;
    this.Auth.login(email, passwordUser)
      .then((res) => {
        if (!res.error) {
          const { from } = location.state
          && location.state.from !== ''
          && location.state.from !== '/'
            ? location.state
            : { from: '/dashboard' };
          history.replace(from);
        } else {
          showModalInfo(res.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Неверный логин/пароль', 'error');
      });
  }

  passwordShowHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { passwordInputType } = this.state;
    this.setState({
      passwordInputType: passwordInputType === 'input' ? 'password' : 'input',
    });
  };

  sendPassword = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { email } = this.state;
    this.setState({ resetingPassword: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/password/create`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({ openModalWindow: true });
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        this.setState({ resetingPassword: false });
      });
  };

  showSendPassword = () => {
    this.setState({ toggleSendPasswordForm: true });
  };

  onCloseModalWindow = () => {
    this.setState({
      openModalWindow: false,
      toggleSendPasswordForm: false,
    });
  };

  render() {
    const {
      toggleSendPasswordForm, openModalWindow, passwordInputType, resetingPassword, isFramed,
    } = this.state;
    const { history, theme } = this.props;
    const values = queryString.parse(window.location.search);
    const refString = values.ref ? `?ref=${values.ref}` : '';
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        {!isFramed ? (
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand mr-auto" href={theme.domain === 'agents' ? 'https://b2b.polis.online' : '/'}>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
            </a>
          </nav>
        ) : null}
        <ContentContainer className="container-fluid d-flex flex-fill flex-column">
          {!toggleSendPasswordForm ? (
            <form className="form-signin text-center" onSubmit={this.handleFormSubmit}>
              <h1 className="h3 mb-3 font-weight-normal">Вход в личный кабинет</h1>
              <label htmlFor="inputEmail" className="sr-only">Email address</label>
              <input id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={this.handleChange} />
              <label htmlFor="inputPassword" className="sr-only">Password</label>
              <input id="inputPassword" name="passwordUser" className="form-control" placeholder="Password" required="" type={passwordInputType} onChange={this.handleChange} />
              <FontAwesomeIcon className="password-show" onClick={this.passwordShowHide} icon={passwordInputType === 'input' ? faEyeSlash : faEye} />
              <button className="btn btn-lg btn-success btn-block" type="submit">Войти</button>
              <div><a tabIndex="-1" role="button" onClick={this.showSendPassword} className="dotted_link">Забыли пароль?</a></div>
              {!isFramed ? (
                <div><a tabIndex="-1" role="button" onClick={() => history.replace(`/registration${refString}`)} className="dotted_link">Зарегистрироваться</a></div>
              ) : null}
            </form>
          ) : (
            <form className="form-signin text-center" onSubmit={this.sendPassword}>
              <h1 className="h3 mb-3 font-weight-normal">Восстановление пароля на {theme.custom_full_domain ? theme.custom_full_domain : `${theme.domain}.polis.online`}</h1>
              <label htmlFor="inputEmail" className="sr-only">Email address</label>
              <input id="inputEmail" name="email" className="form-control" placeholder="Email" required="" type="email" onChange={this.handleChange} />
              <button disabled={resetingPassword} className="btn btn-lg btn-success btn-block mt-2" type="submit">{resetingPassword ? 'Секунду...' : 'Восстановить'}</button>
            </form>
          )}
          <Modal classNames={{ overlay: 'modal-window' }} open={openModalWindow} onClose={this.onCloseModalWindow} center>
            <h4>Пароль выслан вам на почту.</h4>
          </Modal>
          {!isFramed ? (<Footer history={history} theme={theme} />) : null}
        </ContentContainer>
      </div>
    );
  }
}

Login.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(Login);
