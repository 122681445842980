import React, { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  handleChange,
  toLocalDate,
  validateFields,
} from '../../Utils/Helpers';
import getMask from '../../Utils/Masks';
import LoadingSpinner from '../../Layout/LoadingSpinner';

export default function AgentEnforcementProceedingsForm(props) {
  const {
    agent, setShowForm, selectedEnforcementProceeding, setSelectedEnforcementProceeding,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [formData, setFormData] = useState({
    active: {
      value: false,
      errorMessage: '',
      validationType: 'checkbox',
      validationRequired: false,
    },
    date: {
      value: null,
      errorMessage: '',
      validationType: 'date',
      validationRequired: true,
    },
    paymentDate: {
      value: null,
      errorMessage: '',
      validationType: 'date',
      validationRequired: false,
    },
    number: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: true,
    },
    amount: {
      value: false,
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: true,
    },
    percentage: {
      value: false,
      errorMessage: '',
      validationType: 'decimalPercent',
      validationRequired: true,
    },
    document: {
      value: null,
      errorMessage: '',
      validationType: 'image',
      validationRequired: false,
    },
  });

  const validateForm = () => {
    const formFields = { ...formData };
    const validationResult = validateFields(formFields);
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });
    setFormData(newFormData);
    return validationResult.validationSuccess;
  };

  const saveEnforcementProceeding = (e) => {
    e.preventDefault();
    const validateSuccess = validateForm();
    if (validateSuccess) {
      setLoadingFlag(true);
      const token = `Bearer ${localStorage.getItem('id_token')}`;

      const url = selectedEnforcementProceeding ? `enforcementProceeding/${selectedEnforcementProceeding}` : 'enforcementProceeding';
      const formDataObj = new FormData();
      Object.keys(formData).forEach((field) => {
        formDataObj.append(field, formData[field].value);
      });
      formDataObj.append('agent_id', agent.id);
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/${url}`, {
        method: 'POST',
        headers: {
          Authorization: token,
          Accept: 'application/json',
        },
        body: formDataObj,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.id) {
            setSelectedEnforcementProceeding(false);
            setShowForm(false);
          } else {
            showModalInfo('Ошибка', 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          setLoadingFlag(false);
        });
    }
  };

  const downloadEnforcementProceeding = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/enforcementProceeding/${selectedEnforcementProceeding}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          const data = {};
          Object.entries(formData).forEach(([key]) => {
            data[key] = {
              ...formData[key],
              value: response[key],
            };
          });
          setFormData(data);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch((err) => {
        showModalInfo(err, 'error');
      });
  };

  useEffect(() => {
    if (selectedEnforcementProceeding) {
      downloadEnforcementProceeding();
    }
    return () => {};
  }, []);

  return (
    <>
      {selectedEnforcementProceeding ? <>Исполнительное производство № {selectedEnforcementProceeding}</> : null}
      <form onSubmit={saveEnforcementProceeding}>
        <div className="form-group row">
          <div className="col-lg-12">
            <div className="form-check custom-checkbox">
              <input
                onChange={(e) => handleChange(e, 'active', formData, setFormData)}
                checked={formData.active.value ? formData.active.value : false}
                className="form-check-input checkbox-styled"
                type="checkbox"
                name="active"
                id="active"
              />
              <label className="form-check-label" htmlFor="active">
                Активировать
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="number">Номер ИП</label>
            <InputMask
              maskChar=""
              onChange={(e) => handleChange(e, 'number', formData, setFormData)}
              id="number"
              placeholder="99999"
              name="number"
              className={classnames('form-control', { error: formData.number.errorMessage })}
              value={formData.number.value}
            />
            {formData.number.errorMessage && (
              <div className="validation-error">
                {formData.number.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="date">Дата ИП</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control', { error: formData.date.errorMessage })}
              selected={formData.date.value ? parseISO(formData.date.value) : null}
              onChange={(e) => handleChange(toLocalDate(e).toISOString(), 'date', formData, setFormData)}
              name="date"
              id="date"
              locale={ru}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            {formData.date.errorMessage && (
              <div className="validation-error">
                {formData.date.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="amount">Сумма долга</label>
            <InputMask
              maskChar=""
              mask={getMask('decimal').mask}
              formatChars={getMask('decimal').formatChars}
              onChange={(e) => handleChange(e, 'amount', formData, setFormData)}
              id="amount"
              placeholder="99999"
              name="amount"
              className={classnames('form-control', { error: formData.amount.errorMessage })}
              value={formData.amount.value}
            />
            {formData.amount.errorMessage && (
              <div className="validation-error">
                {formData.amount.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="percentage">Процент удержания</label>
            <InputMask
              maskChar=""
              mask={getMask('percent').mask}
              formatChars={getMask('percent').formatChars}
              onChange={(e) => handleChange(e, 'percentage', formData, setFormData)}
              id="percentage"
              placeholder="99999"
              name="percentage"
              className={classnames('form-control', { error: formData.percentage.errorMessage })}
              value={formData.percentage.value}
            />
            {formData.percentage.errorMessage && (
              <div className="validation-error">
                {formData.percentage.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="paymentDate">Дата оплаты</label>
            <DatePicker
              disabled
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control', { error: formData.paymentDate.errorMessage })}
              selected={formData.paymentDate.value ? parseISO(formData.paymentDate.value) : null}
              onChange={(e) => handleChange(toLocalDate(e).toISOString(), 'paymentDate', formData, setFormData)}
              name="paymentDate"
              id="paymentDate"
              locale={ru}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            <label className="d-block" htmlFor="document">Скан документа</label>
            <div className="jq-file styler">
              <div className="jq-file__name">
                {(formData.document.value && typeof formData.document.value === 'string') ? formData.document.value.split('/').pop() : null}
                {(formData.document.value && formData.document.value.name) ? formData.document.value.name : null}
                {!formData.document.value ? 'Не загружен' : null}
              </div>
              <div className="jq-file__browse" />
              <input onChange={(e) => handleChange(e, 'document', formData, setFormData)} type="file" id="document" name="document" className="custom-input-file" />
            </div>
            {(formData.document.value && typeof formData.document.value === 'string') ? (
              <div className="jq-file__download">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${formData.document.value}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
              </div>
            ) : null}
          </div>
        </div>
        {loadingFlag
          ? <LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />
          : (
            <>
              <button type="submit" className="btn btn-success mr-2">{selectedEnforcementProceeding ? 'Сохранить' : 'Добавить'}</button>
              <button type="button" className="btn btn-secondary" onClick={() => { setShowForm(false); setSelectedEnforcementProceeding(false); }}>Отмена</button>
            </>
          )
        }
      </form>
    </>
  );
}
