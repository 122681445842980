import React, { Component } from 'react';
import classnames from 'classnames';
import ProductBlock from './ProductBlock';
import bksLogo from '../../img/products/bks_logo.png';
import renessansLogo from '../../img/companies/renessans.png';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'all',
    };
  }

  componentDidMount() {
    document.title = 'Выберите продукт';
    const { setTour, userId } = this.props;
    if (userId) {
      setTour('tour_2');
    }
  }

  componentDidUpdate(prevProps) {
    const { setTour, userId } = this.props;
    if (!prevProps.userId && userId) {
      setTour('tour_2');
    }
  }

  changeCategory(value) {
    this.setState({
      category: value,
    });
  }

  render() {
    const { history, theme, userInfo } = this.props;
    const { category } = this.state;

    return (
      <>
        <h1 className="page-wrapper__title">Оформить</h1>
        <div className="filters-list">
          <span
            className={classnames('filters-list-item', { 'filters-list-item__active': category === 'all' })}
            onClick={() => this.changeCategory('all')}
          >
            Все
          </span>
          <span
            className={classnames('filters-list-item', { 'filters-list-item__active': category === 'insurance' })}
            onClick={() => this.changeCategory('insurance')}
          >
            Страхование
          </span>
          <span
            className={classnames('filters-list-item', { 'filters-list-item__active': category === 'service' })}
            onClick={() => this.changeCategory('service')}
          >
            Сервисы
          </span>
        </div>
        <div className="product-cards">
          {(theme.domain !== 'macro') ? (
            <ProductBlock
              type="Оформление"
              name="ОСАГО"
              category={category}
              path="/osago"
              target="_self"
              history={history}
            >
              Договор ОСАГО обязателен для всех автомобилистов, он защищает интересы виновника ДТП перед
              пострадавшей стороной.
            </ProductBlock>
          ) : null}
          {theme.domain !== 'kontur' ? (
            <ProductBlock
              type="Оформление"
              name="Ипотечное страхование"
              path="/mortgage"
              category={category}
              target="_self"
              history={history}
            >
              Страхование недвижимости обязательно при заключении кредитного договора, который эта недвижимость
              обеспечивает.
            </ProductBlock>
          ) : null}
          <ProductBlock
            type="Оформление"
            name="Страхование имущества"
            path="/ifl"
            category={category}
            target="_self"
            history={history}
            img={{
              path: renessansLogo,
              left: '130px',
            }}
          >
            Коробочное страхование квартиры, апартаментов и жилых домов на выгодных условиях
          </ProductBlock>
          {(theme.domain !== 'igk23' && theme.domain !== 'macro') ? (
            <ProductBlock
              type="Оформление"
              name="Биржа ОСАГО (не сегмент)"
              path="/insurex_widget"
              target="_self"
              category={category}
              badge="Новинка"
              history={history}
              customClassNames="tour_2_dt_step_15"
            >
              Более 100+ проверенных исполнителей, которые помогут оформить не сегмент!
            </ProductBlock>
          ) : null}
          {theme.domain !== 'igk23' ? (
            <ProductBlock
              type="Оформление"
              name="Заявки на страхование"
              target="_self"
              category={category}
              path="/insurance_request"
              history={history}
              customClassNames="tour_2_dt_step_13"
            >
              Индивидуальное согласование любых видов страхования
            </ProductBlock>
          ) : null}
          {(theme.domain !== 'macro') ? (
            <ProductBlock
              type="Оформление"
              name="Страхование грузов"
              path="/cargoInsurance"
              target="_self"
              category={category}
              badge="Новинка"
              history={history}
            >
              Страхование грузов обязательно для всех перевозок.
            </ProductBlock>
          ) : null}
          <ProductBlock
            type="Оформление"
            name="Страхование от несчастных случаев"
            path="/ns_request"
            target="_blank"
            category={category}
            history={history}
          >
            Оформляйте полис для взрослых, детей и спортсменов, от компании Ингосстрах. Вознаграждение до 25%.
          </ProductBlock>
          <ProductBlock
            type="Оформление"
            name="Потеря работы"
            path="/zpr"
            target="_self"
            badge="Новинка"
            category={category}
            history={history}
            img={{
              path: renessansLogo,
              left: '130px',
            }}
          >
            Страхование от недобровольной потери работы
          </ProductBlock>
          {(theme.domain !== 'macro') ? (
            <ProductBlock
              type="Сервис"
              name="Проверка ОСАГО"
              path="/check-osago"
              target="_self"
              category={category}
              history={history}
            >
              По ВИН номеру узнать страховую компанию, дату окончания и номер полиса.
            </ProductBlock>
          ) : null}
          {(theme.domain !== 'macro') ? (
            <ProductBlock
              type="Сервис"
              name="Договор купли-продажи"
              path="/dkp"
              target="_self"
              category={category}
              history={history}
            >
              ДКП автомобиля между физическими лицами или организациями подтверждает факт продажи конкретного транспортного средства.
            </ProductBlock>
          ) : null}
          {(theme.domain !== 'macro') ? (
            <ProductBlock
              type="Финансы"
              name="Выгодные накопления"
              target="_self"
              path="/finances"
              category={category}
              history={history}
              img={{
                path: bksLogo,
                left: '100px',
              }}
            >
              Подобрать решение с высоким доходом для клиента
            </ProductBlock>
          ) : null}
          {(theme.domain !== 'igk23' && theme.domain !== 'samoletplus' && theme.domain !== 'macro') ? (
            <ProductBlock
              type="Сервис"
              name="Заявки на КАСКО"
              target="_self"
              category={category}
              path="/kasko_request"
              history={history}
            >
              Подобрать решение с высоким доходом для клиента
            </ProductBlock>
          ) : null}
          {(theme.domain !== 'samoletplus' && theme.domain !== 'm2' && theme.domain !== 'insurance') ? (
            <ProductBlock
              type="Сервис"
              name="Подбор ИПОТЕКИ"
              target="_blank"
              category={category}
              history={history}
              path="https://ipoteka.polis.online"
              customClassNames="tour_2_dt_step_14"
            >
              Зарабатывайте на ипотеке для ваших клиентов, более 20 банков партнеров.
            </ProductBlock>
          ) : null}
          {/*<ProductBlock
            type="Сервис"
            name="Проверка недвижимости"
            path="/check_property"
            category={category}
            target="_self"
            badge="Новинка"
            history={history}
          >
            Проверка и получение данных о недвижимости
          </ProductBlock>*/}
          <ProductBlock
            type="Оформление"
            name="Страхование дома"
            badge="Скоро"
            category={category}
            disabled
            target="_self"
            history={history}
          >
            Вы сами выбираете от каких рисков страховать дом. Самые частые из них: взломы, кражи, пожар, затопление.
          </ProductBlock>
          <ProductBlock
            type="Оформление"
            name="Страхование квартиры"
            badge="Скоро"
            category={category}
            disabled
            target="_self"
            history={history}
          >
            Страхование квартиры обеспечивает вам защиту имущества и гражданской ответственности в случае наступление форс-мажора.
          </ProductBlock>
          <ProductBlock
            type="Оформление"
            name="Страхование туристов"
            badge="Скоро"
            category={category}
            disabled
            target="_self"
            history={history}
          >
            Страховка покрывает все расходы, включая перемещение родственников к месту происшествия и обратно.
          </ProductBlock>
          <ProductBlock
            type="Сервис"
            name="Тендеры"
            path="/tenders"
            category={category}
            target="_self"
            history={history}
            disabled
          >
            Тендеры на страхование.
          </ProductBlock>
          <ProductBlock
            type="Сервис"
            name="Лиды"
            path="/leads"
            category={category}
            target="_self"
            history={history}
            disabled
          >
            Лиды на страхование.
          </ProductBlock>
          <ProductBlock
            type="Оформление"
            name="Каско"
            path="/kasko"
            category={category}
            target="_self"
            disabled={!userInfo.full_time_employee}
            history={history}
          >
            КАСКО страхует автомобиль от всех рисков, которые могут возникнуть с авто (ущерб, угон). КАСКО и ОСАГО не заменяют друг друга, а дополняют.
          </ProductBlock>
          {userInfo.full_time_employee ? (
            <ProductBlock
              type="Оформление"
              name="Универсальная"
              path="/universal"
              category={category}
              target="_self"
              disabled={!userInfo.full_time_employee}
              history={history}
            >
              Универсальная форма
            </ProductBlock>
          ) : null}
        </div>
      </>
    );
  }
}

export default withUserInfoStoreStore(Products);
