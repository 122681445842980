import React, { Component } from 'react';
import './refWidgetPage.scss';
import queryString from 'query-string';
import crypto from 'crypto-md5';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';
import { scroller } from 'react-scroll';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getCompanies from '../Utils/InsurancesCompaniesStyle';
import AuthService from '../Utils/AuthService';
import payment1 from '../../img/prolongation/payment1.png';
import payment2 from '../../img/prolongation/payment2.png';
import payment3 from '../../img/prolongation/payment3.png';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" className="slider-company__btn slider-company__btn--next" role="button" onClick={onClick}>
      <svg id="slider-next" viewBox="0 0 23 23">
        <rect width="3" height="15" transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.5 0.893372)" fill="currentColor" />
        <rect width="3" height="15" transform="matrix(0.707107 0.707107 0.707107 -0.707107 9.37891 19.9852)" fill="currentColor" />
      </svg>
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" className="slider-company__btn slider-company__btn--prev" role="button" onClick={onClick}>
      <svg id="slider-prev" viewBox="0 0 23 23">
        <rect x="11.5" y="0.893372" width="3" height="15" transform="rotate(45 11.5 0.893372)" fill="currentColor" />
        <rect x="13.6213" y="19.9852" width="3" height="15" transform="rotate(135 13.6213 19.9852)" fill="currentColor" />
      </svg>
    </button>
  );
}

class RefWidgetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: '',
      widgetId: '',
      companies: {},
      isFramed: 0,
      loaded: false,
    };
  }

  componentDidMount = () => {
    let isFramed = false;
    try {
      isFramed = window != window.top || document != top.document || self.location != top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed: isFramed });
    const urlParams = queryString.parse(window.location.search);
    this.Auth = new AuthService();
    if (urlParams.refHash) {
      this.getWidgetKey(urlParams.refHash);
    } else if (urlParams.commercialOffer) {
      this.getWidgetKey(urlParams.commercialOffer);
    } else if (urlParams.apiKey && urlParams.widgetId) {
      this.login(urlParams.apiKey, urlParams.widgetId);
    }
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const scrolled = window.scrollY;

    const pageHeader = document.getElementsByClassName('page-header')[0];
    const scrollPrev = pageHeader.dataset.scroll;

    if (scrolled > 100 && scrolled > scrollPrev) {
      document.getElementsByClassName('page-header')[0].classList.add('page-header--fixed');
    } else {
      document.getElementsByClassName('page-header')[0].classList.remove('page-header--fixed');
    }
    pageHeader.dataset.scroll = scrolled;
  };

  getWidgetKey = (hash) => {
    const { showModalInfo } = this.context;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/getBaseWidgetByRefLink?hash=${hash}`, {
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          const idString = `${response.widgetId}${response.apiKey}`;
          const apiHash = crypto(idString, 'hex');
          const { product } = this.props;
          this.Auth.loginWidget(response.widgetId, apiHash, product)
            .then(() => {
              const token = `Bearer ${localStorage.getItem('id_token_widget')}`;
              getCompanies(token, product).then((companies) => {
                this.setState({ companies });
              });
              this.setState({
                apiKey: response.apiKey,
                widgetId: response.widgetId,
                loaded: true,
              });
            })
            .catch((error) => {
              error.customMessage = error.customMessage ? error.customMessage : 'Невозможно авторизоваться на сервере';
              showModalInfo(error.customMessage);
            });
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      });
  };

  login = (apiKey, widgetId) => {
    const { product } = this.props;
    const idString = `${widgetId}${apiKey}`;
    const apiHash = crypto(idString, 'hex');
    this.Auth.loginWidget(widgetId, apiHash, product)
      .then(() => {
        const token = `Bearer ${localStorage.getItem('id_token_widget')}`;
        getCompanies(token, product).then((companies) => {
          this.setState({ companies });
        });
        this.setState({
          apiKey,
          widgetId,
          loaded: true,
        });
      })
      .catch(() => alert('Невозможно авторизоваться на сервере'));
  };

  toggleActive = (e) => {
    const { target } = e;
    const contentWrapperElement = target.nextElementSibling;
    if (target.classList.contains('active')) {
      e.target.classList.remove('active');
      contentWrapperElement.style.maxHeight = 0;
    } else {
      target.classList.add('active');
      contentWrapperElement.style.maxHeight = '1000px';
    }
  };

  handleScrollToElement = (e, element) => {
    e.preventDefault();
    scroller.scrollTo(element, {
      duration: 1000,
      smooth: true,
      offset: -140,
    });
  };

  render() {
    const {
      companies, widgetId, apiKey, loaded, isFramed,
    } = this.state;
    const { product, theme } = this.props;
    const phoneLink = `tel:${theme.phone}`;
    const mailLink = `mailto:${theme.email}`;
    return (
      <div id="ref-link-page">
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet" />
        </Helmet>
        {!isFramed ? (
          <header className="page-header">
            <div className="page-header__main-menu">
              <div className="page-header__menu-container container">
                <a href="/" className="page-header__logo logo" aria-label="логотип">
                  <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logo}`} alt="логотип" />
                </a>
                <nav className="page-header__nav main-nav">
                  <ul className="main-nav__list">
                    <li className="main-nav__item">
                      <a href="" onClick={(e) => this.handleScrollToElement(e, 'steps')} className="main-nav__link js-anchor-link">Как это работает?</a>
                    </li>
                    <li className="main-nav__item">
                      <a href="" onClick={(e) => this.handleScrollToElement(e, 'key-points')} className="main-nav__link js-anchor-link">Полезно знать</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>
        ) : null }
        <main>
          <section className="main-screen main-screen--b2c">
            <div className="main-screen__widget-container container">
              {loaded ? (
                <>
                  {product === 'osago' ? (
                    <>
                      <polis-online-widget-osago name="polis-online-widget-osago" data-api-key={apiKey} data-widget-id={widgetId} />
                      <Helmet><script async src={`${process.env.REACT_APP_FRONT_DOMAIN}/widget_v2.min.js`} /></Helmet>
                    </>
                  ) : null}
                  {product === 'mortgage' ? (
                    <>
                      <polis-online-widget-mortgage name="polis-online-widget-mortgage" data-api-key={apiKey} data-widget-id={widgetId} />
                      <Helmet>
                        <script async src={`${process.env.REACT_APP_FRONT_DOMAIN}/widget_mortgage_v2.min.js`} />
                      </Helmet>
                    </>
                  ) : null}
                  {product === 'mortgageLife' ? (
                    <>
                      <polis-online-widget-mortgage-life name="polis-online-widget-mortgage-life" data-api-key={apiKey} data-widget-id={widgetId} />
                      <Helmet>
                        <script async src={`${process.env.REACT_APP_FRONT_DOMAIN}/widget_mortgage_life_v2.min.js`} />
                      </Helmet>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="main-screen__container container">
              <div className="main-screen__steps steps" name="steps">
                <h2 className="steps__title">5&nbsp;минут<br /> и&nbsp;страховка<br /> готова</h2>
                <ol className="steps__list">
                  <li className="steps__item">
                    <h3 className="steps__item-title"><span className="steps__item-number">1</span>Выберите<br /> предложение</h3>
                    <p className="steps__item-text"> Сравните несколько вариантов и&nbsp;остановитесь на&nbsp;наиболее выгодном для&nbsp;вас </p>
                  </li>
                  <li className="steps__item">
                    <h3 className="steps__item-title"><span className="steps__item-number">2</span>Оформите и&nbsp;оплатите страховку</h3>
                    <p className="steps__item-text"> Укажите только необходимые сведения и&nbsp;произведите оплату </p>
                  </li>
                  <li className="steps__item steps__item--spacious">
                    {product === 'osago' ? (
                      <h3 className="steps__item-title"><span className="steps__item-number">3</span>Можете<br /> ехать</h3>
                    ) : null}
                    {product === 'mortgage' || product === 'mortgageLife' ? (
                      <h3 className="steps__item-title"><span className="steps__item-number">3</span>Полис у Вас!</h3>
                    ) : null}
                    <p className="steps__item-text"> Полис будет отправлен на&nbsp;электронную почту, его можно открыть на&nbsp;вашем смартфоне и&nbsp;компьютере </p>
                  </li>
                </ol>
              </div>
            </div>
          </section>
          <section className="slider-company slider-company--b2c">
            <div className="slider-company__container container">
              <h2 className="visually-hidden">Страховые компании</h2>
              <Slider
                infinite
                speed={500}
                slidesToShow={4}
                slidesToScroll={1}
                autoplay
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                responsive={[
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ]}
              >
                {Object.values(companies).map((e) => <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${e.logo}`} alt={e.name} />)}
              </Slider>
            </div>
          </section>
          <section className="features sal-animate">
            <div className="features__container container">
              <h2 className="features__title visually-hidden">Преимущества</h2>
              <ul className="features__list">
                <li className="features__item">
                  <h3 className="features__item-title">Просто</h3>
                  <p className="features__item-text"> Оформляется элементарно, полис сразу приходит на&nbsp;электронную почту </p>
                </li>
                <li className="features__item">
                  <h3 className="features__item-title">Практично</h3>
                  <p className="features__item-text"> Экономия до&nbsp;40% процентов из-за разницы базовых ставок тарифов страховых компаний </p>
                </li>
                <li className="features__item">
                  <h3 className="features__item-title">Надежно</h3>
                  <p className="features__item-text"> Все полисы оригинальные и&nbsp;имеют такую&nbsp;же силу, как бумажные </p>
                </li>
              </ul>
            </div>
          </section>
          {product === 'osago' ? (
            <section className="key-points sal-animate">
              <div className="key-points__container container" name="key-points">
                <h2 className="key-points__title visually-hidden">Вопрос-ответ</h2>
                <ul className="key-points__accordion accordion js-accordion">
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Страховка ОСАГО</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Рынок страхования постоянно меняется. Появляются новые продукты. Совершенствуются уже имеющиеся программы. Но&nbsp;неизменным остается обязательное страхование автогражданской ответственности&nbsp;— ОСАГО. Каждый водитель на&nbsp;территории России обязан получить данный полис, обеспечивающий защиту при попадании в&nbsp;дорожно-транспортное происшествие. </p>
                      <p> Если вы&nbsp;недавно купили новый автомобиль, или подошел к&nbsp;концу срок предыдущей страховки, предлагаем воспользоваться профессиональным сервисом Polis.online. На&nbsp;одном сайте собрана актуальная информация о&nbsp;программах в&nbsp;крупнейших компаниях. С&nbsp;нашей помощью вы&nbsp;получите наиболее выгодное предложение, на&nbsp;оформление которого уйдет около 5&nbsp;минут. </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Что такое ОСАГО?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> ОСАГО расшифровывается, как обязательное страхование автогражданской ответственности. Уже из&nbsp;названия понятно, что полис должен быть у&nbsp;каждого владельца автотранспорта. В&nbsp;противном случае предусмотрены штрафные санкции. </p>
                      <p> В&nbsp;договоре четко распределены обязательства компании по&nbsp;отношении к&nbsp;клиенту&nbsp;— застраховать интересы владельца машины и&nbsp;компенсировать ущерб при повреждении имущества, причинении вреда здоровью/жизни пострадавшей стороне. Возмещение происходит при наступлении страхового случая. Оно возможно в&nbsp;нескольких формах: </p>
                      <ul>
                        <li>В&nbsp;денежном эквиваленте.</li>
                        <li>В&nbsp;натуре&nbsp;— предусмотрено в&nbsp;том случае, если вред нанесен только автомобилю.</li>
                      </ul>
                      <p> Если страховая компания компенсирует ущерб пострадавшей стороны, то&nbsp;расходы на&nbsp;восстановление авто виновника аварии ложатся на&nbsp;плечи самого страхователя. В&nbsp;этом суть обязательного полиса ОСАГО. </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>От&nbsp;чего защищает ОСАГО?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Полис обязательного страхования ОСАГО предполагает покрытие расходов в&nbsp;результате дорожно-транспортного происшествия, случившегося по&nbsp;вине страхователя. Получается, если в&nbsp;аварии виноват носитель документа, СК&nbsp;выплатит компенсацию пострадавшей стороне (в&nbsp;рамках установленного государством лимита). </p> <p> Защита по&nbsp;ОСАГО предусмотрена в&nbsp;следующих случаях: </p>
                        <ul>
                          <li>Страхователь сбил человека. В&nbsp;результате происшествия пострадавший получил телесные повреждения любой степени тяжести, в&nbsp;том числе приведшие к&nbsp;летальному исходу.</li>
                          <li>Во&nbsp;время дорожно-транспортного происшествия владелец застрахованного по&nbsp;ОСАГО автомобиля неумышленно нанес ущерб другим машинам.</li>
                          <li>Во&nbsp;время аварии страхователь нанес ущерб не&nbsp;только транспортному средству, но&nbsp;и&nbsp;здоровью/жизни людей, которые находились в&nbsp;нем.</li>
                          <li>Во&nbsp;время дорожно-транспортного происшествия страхователь нанес ущерб человеку, животному, зданию или любому другому предмету или объекту.</li>
                        </ul>
                        <p> На&nbsp;данные страховые случаи полностью распространяется действие полиса ОСАГО. </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>В&nbsp;каких случаях полис не&nbsp;работает?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Есть перечень ситуаций, которые не&nbsp;подпадают под действие полиса обязательного страхования. Если какой-то из&nbsp;этих случаев произошел, выплата не&nbsp;предусмотрена: </p>
                        <ul>
                          <li>Страхователь причинил ущерб имуществу, здоровью или жизни третьих лиц, при этом находился в&nbsp;автомобиле, который не&nbsp;указан в&nbsp;страховке. </li>
                          <li>Пострадавшая сторона требует компенсировать моральный ущерб.</li>
                          <li>В&nbsp;результате дорожно-транспортного происшествия был нанесен ущерб памятникам архитектуры, антиквариату, ценным бумагам, объектам интеллектуальной собственности, старинным строениям. </li>
                          <li>Ущерб был нанесен во&nbsp;время спортивных мероприятий, городских турниров, учений, проходящих в&nbsp;специально отведенных для этого местах.</li>
                          <li>Виновника аварии обвиняют в&nbsp;загрязнении окружающей среды.</li> <li>Авария была заранее спланирована страхователем.</li>
                          <li>Происшествие произошло по&nbsp;вине природных катаклизмов или иных обстоятельств непреодолимой силы.</li>
                        </ul>
                        <p> Проконсультируйтесь по&nbsp;страховым выплатам и&nbsp;возможным отказам&nbsp;СК с&nbsp;менеджером Polis.online. Предоставим актуальную информацию. Расскажем об&nbsp;условиях обязательного страхования автогражданской ответственности. </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h3 className="accordion__item-title" onClick={this.toggleActive}>Тарификация</h3>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Стоимость обязательного страхования регламентируется государством. Существует базовый тариф. Каждая страховая компания устанавливает свое значение ставки, но&nbsp;строго в&nbsp;рамках принятого «коридора». Окончательная цена полиса определяется уже за&nbsp;счет применения поправочных коэффициентов, которые, в&nbsp;зависимости от&nbsp;условий и&nbsp;особенностей&nbsp;ТС, повышают или понижают&nbsp;ее: </p>
                        <ul>
                          <li>Тип транспортного средства. Для грузовых и&nbsp;легковых автомобилей предусмотрены разные коэффициенты.</li>
                          <li>Регион регистрации/нахождения ТС. Чем населённее город, тем выше вероятность возникновения аварий, соответственно больше коэффициент и&nbsp;дороже страховой полис ОСАГО.</li>
                          <li>Возраст и&nbsp;стаж. В&nbsp;страховом полисе обязательно указываются эти два параметра. Если страхователю больше 22&nbsp;лет, а&nbsp;его опыт вождения превышает 4&nbsp;лет, то&nbsp;предусмотрено минимальное значение коэффициента. Если&nbsp;же стажа и&nbsp;возраста не&nbsp;хватает, ОСАГО обойдется дороже.</li>
                          <li>Бонус-малус или коэффициент безаварийной езды. Чем аккуратнее управляет авто водитель, тем дешевле страховка. За&nbsp;каждый год безаварийной езды владелец&nbsp;ТС получает скиду на&nbsp;покупку полиса в&nbsp;размере 5%.</li>
                          <li>Количество лиц, допущенных к&nbsp;управлению. ОСАГО бывает ограниченным и&nbsp;неограниченным. В&nbsp;первом случае страхователь имеет право вписать в&nbsp;полис определенное количество человек. Во&nbsp;втором&nbsp;— на&nbsp;данном пункте ставится прочерк.</li>
                        </ul>
                        <p> Чтобы рассчитать стоимость страхового полиса ОСАГО, существует специальный онлайн-калькулятор. В&nbsp;пустые поля вбиваете требуемые данные и&nbsp;получаете результат. Далее можно сразу&nbsp;же заказать страховку. </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Где быстро и&nbsp;выгодно купить страховку ОСАГО?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Ответ на&nbsp;этот вопрос знают специалисты сервиса Polis.online. Нужно застраховать авто? Закажите полис с&nbsp;помощью профессионалов. Гарантируем: </p>
                        <ul>
                          <li>Прозрачные условия.</li>
                          <li>Лучшие программы.</li>
                          <li>Оперативность.</li>
                          <li>Удобство оформления.</li>
                          <li>Квалифицированные консультации.</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Как сделать ОСАГО онлайн?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Сегодня воспользоваться услугой автострахования можно в&nbsp;режиме онлайн, не&nbsp;посещая никаких офисов. Для этого с&nbsp;сервисом Polis.online достаточно пройти несколько шагов: </p>
                        <ul>
                          <li>Выбираем страховую компанию. С&nbsp;Polis.online сделать это очень просто.</li>
                          <li>Проходим регистрацию и&nbsp;получаем доступ к&nbsp;личному кабинету.</li>
                          <li>Заполняем пустые поля формы. Внимательно перепроверяем введенные данные. Даже малейшая ошибка делает ваш полис недействительным.</li>
                          <li>Получаем расчет и&nbsp;вносим оплату любым удобным способом.</li> <li>Получаем готовый полис ОСАГО по&nbsp;указанному адресу электронной почты.</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          ) : null}
          {product === 'mortgage' ? (
            <section className="key-points sal-animate">
              <div className="key-points__container container" name="key-points">
                <h2 className="key-points__title visually-hidden">Вопрос-ответ</h2>
                <ul className="key-points__accordion accordion js-accordion">
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Что такое страхование ипотеки?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Страхование ипотеки — это страхование риска убытков от кредиторов, которое может появится в случае банкротства ипотечных заемщиков и последующей реализации заложенного имущества.</p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Обязательно ли страхование при ипотеке?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p>Да, обязательно.</p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Что влияет на стоимость страховки?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p>На стоимость влияет сумма задолженности по кредиту, характеристики залогового имущества, пол, возраст и профессия заемщика.</p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Какие документы нужны для оформления?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p> Основные документы:</p>
                        <ul>
                          <li>Анкету-заявление, заполненное по форме банка.</li>
                          <li>Паспорт заемщика.</li>
                          <li>Кредитный договор.</li>
                        </ul>
                        <p>Список документов зависит от выбранного типа полиса.</p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h3 className="accordion__item-title" onClick={this.toggleActive}>На какой период заключается договор?</h3>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p>На 1 год с последующим продлением. Каждый год сумма остатка кредита уменьшается, соответственно понижается и стоимость страховки.</p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Что делать при страховом случае?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p>Основные причины:</p>
                        <ul>
                          <li>Многие компании не страхуют дома, которым более 70 лет.</li>
                          <li>При наличии деревянных перекрытий в доме СК может отказать в страховании.</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="accordion__item">
                    <h2 className="accordion__item-title" onClick={this.toggleActive}>Почему могут отказать в страховой выплате?</h2>
                    <div className="accordion__item-content-wrapper">
                      <div className="accordion__item-content">
                        <p>Порядок действий при наступлении страхового случая:</p>
                        <ul>
                          <li>Вызовите специальную службу.</li>
                          <li>Примите меры, по спасению застрахованного имущества или снижению ущерба.</li>
                          <li>Получите справку о произошедшем от компетентных органов.</li>
                          <li>Обратитесь в страховую компанию.</li>
                          <li>После того, как будет проведена оценка ущерба, выплачивается компенсация в течение срока указанного в договоре.</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          ) : null}
        </main>
        {!isFramed ? (
          <footer className="footer">
            <div className="container">
              <div className="footer-row">
                <div className="footer-column footer-info">
                  <ul className="footer-contact">
                    <li className="footer-contact-email footer-text">
                      <a className="footer-link" href={mailLink} aria-label="Написать нам на почту">{theme.email}</a>
                    </li>
                    <li className="footer-text">
                      <a className="footer-link footer-link--email" aria-label="Позвонить по телефону" href={phoneLink}>{theme.phone}</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-column footer-payment">
                  <div className="payment-logos">
                    <div className="payment-logo">
                      <img className="payment-logo__image" src={payment1} width="81" height="36" alt="Карта Mир" />
                    </div>
                    <div className="payment-logo">
                      <img className="payment-logo__image" src={payment2} width="41" height="33" alt="Карта Мастеркард" />
                    </div>
                    <div className="payment-logo">
                      <img className="payment-logo__image" src={payment3} width="61" height="20" alt="Карта Виза" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        ) : null }
      </div>
    );
  }
}

RefWidgetPage.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(RefWidgetPage);
