import React, { Component } from 'react';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default class WidgetAnalyticsIntegrationCalltouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      showMenu,
      calltouchId,
      changeWidgetAnalyticsIntegrationId,
      updateWidgetWithInfo,
      widget,
    } = this.props;

    const marginInLine = { marginLeft: '10px' };

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку систем аналитики</LinkBreadcrumbs>
            </div>
          </div>
          <div className="card">
            <div className="form-group row">
              <div className="col-lg-12">
                <SimpleNotification>
                  <p>Для корректной работы необходимо указать идентификатор скрипта и добавить следующие цели в настройках вашего аккаунта Calltouch:</p>
                  <ul>
                    {widget.policy_type.code === 'osago' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Идентификатор цели:</strong> phoneValidate_widgetOsago</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Идентификатор цели:</strong> createQuote_widgetOsago</li>
                        <li><strong>Название:</strong> Автомобиль заполнен, <strong style={marginInLine}>Идентификатор цели:</strong> carFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Страхователь заполнен, <strong style={marginInLine}>Идентификатор цели:</strong> insuranceFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Собственник заполнен, <strong style={marginInLine}>Идентификатор цели:</strong> ownerFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Водитель заполнен, <strong style={marginInLine}>Идентификатор цели:</strong> driverFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Идентификатор цели:</strong> getResult_widgetOsago</li>
                        <li><strong>Название:</strong> Выбор страховой, <strong style={marginInLine}>Идентификатор цели:</strong> chooseInsurance_widgetOsago</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Идентификатор цели:</strong> buyPolicy_widgetOsago</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'mortgage' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Идентификатор цели:</strong> phoneValidate_widgetMortgage</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Идентификатор цели:</strong> createQuote_widgetMortgage</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Идентификатор цели:</strong> getResult_widgetMortgage</li>
                        <li><strong>Название:</strong> Выбор страховой, <strong style={marginInLine}>Идентификатор цели:</strong> chooseInsurance_widgetMortgage</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Идентификатор цели:</strong> buyPolicy_widgetMortgage</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'greenCard' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Идентификатор цели:</strong> getResult_widgetGreenCard</li>
                        <li><strong>Название:</strong> Выбор страховой, <strong style={marginInLine}>Идентификатор цели:</strong> chooseInsurance_widgetGreenCard</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Идентификатор цели:</strong> buyPolicy_widgetGreenCard</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dkp' ? (
                      <>
                        <li><strong>Название:</strong> Переход к оформлению ДКП, <strong style={marginInLine}>Идентификатор цели:</strong> checkDocumentData_widgetDKP</li>
                        <li><strong>Название:</strong> Оформление ДКП, <strong style={marginInLine}>Идентификатор цели:</strong> getDocument_widgetDKP</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'kbm' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки КБМ, <strong style={marginInLine}>Идентификатор цели:</strong> getKbm_widgetKbm</li>
                        <li><strong>Название:</strong> Отправлен запрос на восстановление КБМ, <strong style={marginInLine}>Идентификатор цели:</strong> refreshKbm_widgetKbm</li>
                        <li><strong>Название:</strong> Переход к оформлению ОСАГО, <strong style={marginInLine}>Идентификатор цели:</strong> getOsago_widgetKbm</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'property' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки недвижимости, <strong style={marginInLine}>Идентификатор цели:</strong> getKbm_widgetProperty</li>
                        <li><strong>Название:</strong> Отправлен запрос на восстановление недвижимости, <strong style={marginInLine}>Идентификатор цели:</strong> refreshKbm_widgetProperty</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dmsStudent' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Идентификатор цели:</strong> getResult_widgetDmsStudent</li>
                        <li><strong>Название:</strong> Выбор страховой, <strong style={marginInLine}>Идентификатор цели:</strong> chooseInsurance_widgetDmsStudent</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Идентификатор цели:</strong> buyPolicy_widgetDmsStudent</li>
                      </>
                    ) : null}
                  </ul>
                </SimpleNotification>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <label htmlFor="widgetCalltouchId">Идентификатор отслеживания в Calltouch</label>
                <input value={calltouchId} onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'calltouch_id')} onBlur={updateWidgetWithInfo} name="widgetCalltouchId" type="text" className="form-control" id="widgetCalltouchId"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
