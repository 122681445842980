import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import {
  faCheck, faTimes,
} from '@fortawesome/fontawesome-free-solid';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default class WidgetAnalyticsIntegrationYandex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenExpired: false,
      yandexLoginError: false,
    };
  }

  componentDidMount() {
    const {
      tokenDate,
      tokenExpires,
    } = this.props;

    const tokenCalcDate = new Date(tokenDate);
    tokenCalcDate.setSeconds(tokenCalcDate.getSeconds() + parseInt(tokenExpires, 10));
    if (new Date() > tokenCalcDate) {
      this.setState({ tokenExpired: true });
    }
  }

  getOAuthToken = () => {
    const { login, widgetId } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const url = window.location.href;
    const yandexUrl = `https://oauth.yandex.ru/authorize?response_type=token&client_id=8f70a6d73c1040398835cefdf40a9a4d&redirect_uri=https://agents.polis.online/set-yandex-oauth-token&login_hint=${login}&state=${widgetId}__${token}__${url}`;
    if (!login) {
      this.setState({ yandexLoginError: true });
    } else {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: yandexUrl,
      }).click();
    }
  }

  render() {
    const {
      showMenu,
      yandexId,
      changeWidgetAnalyticsIntegrationId,
      updateWidgetWithInfo,
      login,
      token,
      widget,
    } = this.props;

    const {
      tokenExpired,
      yandexLoginError,
    } = this.state;

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку систем аналитики</LinkBreadcrumbs>
            </div>
          </div>
          <div className="card">
            <div className="form-group row">
              <div className="col-lg-12">
                <SimpleNotification>
                  <p>Для корректной работы необходимо указать номер вашего счетчика, добавить следующие цели в настройках вашего аккаунта Яндекс.Метрики(в качестве условия использовать «событие: идентификатор»):</p>
                  <ul>
                    {widget.policy_type.code === 'osago' ? (
                      <>
                        <li><strong>Телефон прошел валидацию:</strong> phoneValidate_widget</li>
                        <li><strong>Старт расчета:</strong> createQuote_widget</li>
                        <li><strong>Автомобиль заполнен:</strong> carFilled_widget</li>
                        <li><strong>Страхователь заполнен:</strong> insuranceFilled_widget</li>
                        <li><strong>Собственник заполнен:</strong> ownerFilled_widget</li>
                        <li><strong>Водитель заполнен:</strong> driverFilled_widget</li>
                        <li><strong>Получены результаты:</strong> getResult_widget</li>
                        <li><strong>Выбор страховой:</strong> chooseInsurance_widget</li>
                        <li><strong>Попытка покупки:</strong> buyPolicy_widget</li>
                        <li><strong>Полис успешно оплачен:</strong> acceptPayment_widget</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'mortgage' ? (
                      <>
                        <li><strong>Телефон прошел валидацию:</strong> phoneValidate_widgetMortgage</li>
                        <li><strong>Старт расчета:</strong> createQuote_widgetMortgage</li>
                        <li><strong>Получены результаты:</strong> getResult_widgetMortgage</li>
                        <li><strong>Выбор страховой:</strong> chooseInsurance_widgetMortgage</li>
                        <li><strong>Попытка покупки:</strong> buyPolicy_widgetMortgage</li>
                        <li><strong>Полис успешно оплачен:</strong> acceptPayment_widgetMortgage</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'greenCard' ? (
                      <>
                        <li><strong>Получены результаты:</strong> getResult_widget_greenCard</li>
                        <li><strong>Выбор страховой:</strong> chooseInsurance_widget_greenCard</li>
                        <li><strong>Отправка заявки:</strong> buyPolicy_widget_greenCard</li>
                        <li><strong>Полис успешно оплачен:</strong> acceptPayment_widget_greenCard</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dkp' ? (
                      <>
                        <li><strong>Переход к оформлению ДКП:</strong> checkDocumentData_widgetDKP</li>
                        <li><strong>Оформление ДКП:</strong> getDocument_widgetDKP</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'kbm' ? (
                      <>
                        <li><strong>Получен результат проверки КБМ:</strong> getKbm_widgetKbm</li>
                        <li><strong>Отправлен запрос на восстановление КБМ:</strong> refreshKbm_widgetKbm</li>
                        <li><strong>Переход к оформлению ОСАГО:</strong> getOsago_widgetKbm</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'property' ? (
                      <>
                        <li><strong>Получен результат проверки КБМ:</strong> getKbm_widgetProperty</li>
                        <li><strong>Отправлен запрос на восстановление КБМ:</strong> refreshKbm_widgetProperty</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dmsStudent' ? (
                      <>
                        <li><strong>Получены результаты:</strong> getResult_widgetDmsStudent</li>
                        <li><strong>Выбор страховой:</strong> chooseInsurance_widgetDmsStudent</li>
                        <li><strong>Отправка заявки:</strong> buyPolicy_widgetDmsStudent</li>
                        <li><strong>Полис успешно оплачен:</strong> acceptPayment_widgetDmsStudent</li>
                      </>
                    ) : null}
                  </ul>
                </SimpleNotification>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="widgetYandexId">Номер счетчика Яндекс метрики</label>
                <input value={yandexId} onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'yandexId')} onBlur={updateWidgetWithInfo} name="widgetYandexId" type="text" className="form-control" id="widgetYandexId" />
              </div>
            </div>
            {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'mortgage' ? (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <SimpleNotification offsetBottom="1rem">
                      <p>Для передачи цели «Полис успешно оплачен» в Яндекс.Метрику необходимо:</p>
                      <ul>
                        <li>Указать вашу почту в Яндекс <strong>(к которой привязан счетчик)</strong> и получить токен:</li>
                        <li>В настройках Яндекс.Метрики включить опцию «Офлайн-конверсии» (Настройка-&gt;Загрузка данных-&gt;Офлайн-конверсии). <strong>Загрузка офлайн-конверсий станет доступна в течение суток после включения опции в настройках!</strong></li>
                        <li>Не должна являться частью составной цели</li>
                      </ul>
                    </SimpleNotification>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="widgetYandexEmail">Адрес почты</label>
                    <input value={login} onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'yandex_login')} onBlur={updateWidgetWithInfo} onFocus={() => {this.setState({ yandexLoginError: false }); }} name="widgetYandexEmail" type="text" className={classnames('form-control', { error: yandexLoginError })} id="widgetYandexEmail" />
                    {yandexLoginError && (
                      <div className="validation-error">
                        Заполните поле!
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <button type="button" className={classnames('btn', 'btn-success', 'mr-3')} onClick={this.getOAuthToken}>Получить токен</button>
                  </div>
                </div>
                <div className="form-group row">
                  {token !== undefined && token !== null && !tokenExpired ? (
                    <div className="col-lg-3">
                      <FontAwesomeIcon icon={faCheck} className="fa-fw" color="green" size="2x" />
                      <span style={{ marginLeft: '15px', verticalAlign: 'super', color: 'green' }}>Токен получен</span>
                    </div>
                  ) : (
                    <div className="col-lg-3">
                      <FontAwesomeIcon icon={faTimes} className="fa-fw" color="red" size="2x" />
                      <span style={{ marginLeft: '15px', verticalAlign: 'super', color: 'red' }}>Токен не получен</span>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
