import React from 'react';
import styled from 'styled-components';
import SocialLink from './SocilaLink';

const SocialLinksContainer = styled.div`
`;

export default function SocialLinks(props) {
  const { link } = props;
  return (
    <SocialLinksContainer>
      <SocialLink text="Телеграм канал для пользователей" type="telegram" link={link} />
    </SocialLinksContainer>
  );
}
