import React from 'react';
import {
  handleChange,
} from '../../Utils/Helpers';

export default function IflOptionsForm(props) {
  const { formData, setFormData } = props;

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h3 className="mortgage-options-header">
              Дополнительные сведения
            </h3>
            <ul className="mortgage-options">
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    onChange={(e) => handleChange(e, 'hasGasFlag', formData, setFormData)}
                    checked={formData.hasGasFlag.value}
                    name="hasGasFlag"
                    type="checkbox"
                    id="hasGasFlag"
                  />
                  <span className="slider round" />
                </label>
                <span className="switch-text">Есть газосодержащие коммуникации</span>
              </li>
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    onChange={(e) => handleChange(e, 'fireDangerFlag', formData, setFormData)}
                    checked={formData.fireDangerFlag.value}
                    name="fireDangerFlag"
                    type="checkbox"
                    id="fireDangerFlag"
                  />
                  <span className="slider round" />
                </label>
                <span className="switch-text">Есть деревянные перекрытия</span>
              </li>
              <li className="mortgage-options__item">
                <label className="switch">
                  <input
                    onChange={(e) => handleChange(e, 'repairFlag', formData, setFormData)}
                    checked={formData.repairFlag.value}
                    name="repairFlag"
                    type="checkbox"
                    id="repairFlag"
                  />
                  <span className="slider round" />
                </label>
                <span className="switch-text">Проводится ремонт</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
