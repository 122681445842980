import styled, { css } from 'styled-components';
import React from 'react';

export const TableTheadAnswers = styled.thead`
    background-color: #f6f6f6;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    @media (max-width: 1023px) {
        display: block;
    }
    @media (max-width: 767px) {
        margin-bottom: 29px;
        background: transparent;
        border: 0;
    }

  ${(props) => props.block === 'results' && css`
    background-color: transparent;
    border: 0;
    @media (max-width: 767px) {
      display: none;
    }
  `}
`;

export default function TableThead(props) {
  const {
    children, block,
  } = props;

  return (
    <TableTheadAnswers block={block}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTheadAnswers>
  );
}
