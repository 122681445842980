import React from 'react';
import styled from 'styled-components';

export const WrapperFooterText = styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #a3a3a3;
    margin-top: 4px;
    margin-bottom: 27px;
 `;

export default function DashboardWrapperFooterText(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <WrapperFooterText disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </WrapperFooterText>
  );
}
