import styled from 'styled-components';
import React from 'react';

export const PageWrapperContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export default function PageWrapperAnswers(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <PageWrapperContainer disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </PageWrapperContainer>
  );
}
