import React, {
  forwardRef, useContext, useImperativeHandle, useMemo,
} from 'react';
import classnames from 'classnames';
import { customFetch, validateFields } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function InsuranceProgramOptions(props, ref) {
  const {
    program, optionsList, setOptionsList, setSavingProgramFlag,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const NEW_OPTION = {
    name: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    cost: {
      value: 0,
      validationRequired: true,
      errorMessage: '',
      validationType: 'decimal',
    },
    changed: true,
    id: null,
  };

  const handleChangeInput = (e) => {
    const { target } = e;
    const fieldIdData = target.id.split('_');
    const fieldName = fieldIdData[0];
    const index = parseInt(fieldIdData[1], 10);

    setOptionsList((prev) => {
      prev[index][fieldName].value = target.value;
      prev[index][fieldName].errorMessage = '';
      prev[index].changed = true;
      return [...prev];
    });
  };

  const validateProgramOptions = () => {
    let validationSuccess = true;
    const checkedOptionsList = [...optionsList];
    checkedOptionsList.forEach((option) => {
      const formFields = { ...option };
      const validationResult = validateFields(formFields);
      option = { ...validationResult.result };
      if (!validationResult.validationSuccess) {
        validationSuccess = false;
      }
    });
    setOptionsList(checkedOptionsList);
    return validationSuccess;
  };

  const saveOptions = () => {
    const isValid = validateProgramOptions();
    if (isValid) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      setSavingProgramFlag(true);

      const optionsToSave = optionsList.map((option) => ({
        ...option,
        name: option.name.value,
        cost: option.cost.value,
      }));

      customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/options-update`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          programId: program.id,
          options: optionsToSave,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            const savedPotionsList = optionsList.map((option, index) => ({
              ...option,
              changed: false,
              id: response[index].id,
            }));
            setOptionsList(savedPotionsList);
            showModalInfo('Изменения сохранены');
          } else {
            showModalInfo(response.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          setSavingProgramFlag(false);
        });
    }
  };

  const deleteOption = (e, index) => {
    e.preventDefault();

    setOptionsList((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      saveOptions();
    },
  }), [optionsList, program]);

  const optionsBlock = useMemo(() => {
    return optionsList.map((option, index) => (
      <div key={index}>
        <p style={{ marginBottom: '5px' }}>Опция №{index + 1}<a onClick={(e) => { deleteOption(e, index); }} className="close-driver">×</a></p>
        <div className="form-group row">
          <div className="col-lg-4">
            <label htmlFor={`name_${index}`}>Наименование</label>
            <input
              value={option.name.value}
              name={`name_${index}`}
              onChange={handleChangeInput}
              type="text"
              className={classnames('form-control', { error: option.name.errorMessage })}
              id={`name_${index}`}
            />
            {option.name.errorMessage && (
              <div className="validation-error">
                {option.name.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-2">
            <label htmlFor={`cost_${index}`}>Цена</label>
            <input
              value={option.cost.value}
              name={`cost_${index}`}
              onChange={handleChangeInput}
              type="number"
              className={classnames('form-control', { error: option.cost.errorMessage })}
              id={`cost_${index}`}
            />
            {option.cost.errorMessage && (
              <div className="validation-error">
                {option.cost.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  }, [optionsList]);

  return (
    <>
      <h5>Доп. опции</h5>
      {optionsBlock}

      <div className="row">
        <div className="col-lg-3">
          <button onClick={() => { setOptionsList([...optionsList, NEW_OPTION]); }} type="button" className="btn btn-border btn-success">+ Еще одна опция</button>
        </div>
      </div>
    </>
  );
}

export default forwardRef(InsuranceProgramOptions);
