import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import CustomIcon from './CustomIcon';

const Container = styled.div`
  margin: 0 -16px 0 -24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #2f2f2d;
  }
`;

const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  font-weight: 600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color .15s ease;
  cursor: pointer;

  &:hover{
    background-color: #3d3d3b;
  }

  svg {
    color: ${(props) => (props.theme ? props.theme.mainColor : '#01e250')};
  }

  span{
    margin-right: 12px;
  }
`;

export default function NavbarPopupLink(props) {
  const {
    link, text, theme, closeTippy,
  } = props;

  return (
    <Container onClick={closeTippy}>
      <NavLink to={link || ''}>
        <Link theme={theme}>
          <span>{text}</span>
          <CustomIcon icon="longArrow" width="32px" height="19px" />
        </Link>
      </NavLink>
    </Container>
  );
}
