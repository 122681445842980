import styled, { css } from 'styled-components';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { ButtonBlock } from '../Button';
import useUserInfoStore from '../../Stores/UserInfoStore';

export const TableTbodyAnswersTd = styled.td`
  display: flex;
  padding: 17px 16px;
  min-height: 92px;
  vertical-align: middle;
  @media (max-width: 1023px) {
    min-height: 0;
    height: auto;
    padding: 0;
  }
  ${(props) => props.block === 'name' && css`
    width: 26.5%;
    position: relative;
    padding-left: 24px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-shrink: 3;
    div {
      display: flex;
      align-items: center;
    }
    @media (max-width: 1279px) {
      width: 21%;
    }
    @media (max-width: 1023px) {
      padding: 0 16px 0 0;
      width: 29.5%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 25px;
      padding: 0 0 12px;
      border-bottom: 1px solid #e9e9e9;
    }
  `}

  ${(props) => props.block === 'val' && css`
    width: 14.5%;
    .page-popup .tippy-content {
      background-color: transparent;
      width: 180px;
    }
    @media (max-width: 1279px) {
      width: 16%;
    }
    @media (max-width: 1023px) {
      padding-right: 16px;
      margin-top: 0;
      width: 25%;
    }
    @media (max-width: 767px) {
      width: 57%;
    }
  `}

  ${(props) => props.block === 'sum' && css`
    width: 13.9%;
    @media (max-width: 1023px) {
      margin-top: 0;
      width: 25%;
    }
    @media (max-width: 767px) {
      width: 43%;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  `}

  ${(props) => props.block === 'desc' && css`
    margin: 0;
    width: 41%;
    color: #ff4d00;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        width: 100%;
    }
  `}

  ${(props) => props.block === 'admin-btn' && css`
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    padding-right: 24px;
    @media (max-width: 1023px) {
      order: -1;
      width: 9%;
      margin-left: 0;
      padding: 0 0 0 18px;

      ${(props) => props.active && css`
        ${ButtonBlock} {
          background-color: #f5f5f5;
        }
      `}
    }
    @media (max-width: 767px) {
      order: 1;
      width: 100%;
      margin: 16px 0 0;
      padding: 0;
    }
    ${ButtonBlock} {
      font-weight: 500;
      padding: 5px 0;
      color: #737373;
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: color .15s ease;
      transition: color .15s ease;
      border: none;
      min-width: initial;
      &:hover {
        background-color: transparent;
        color: #3d3d3b;
      }
      @media (max-width: 1023px) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      @media (max-width: 767px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        width: 100%;
        height: auto;
        padding: 7px 18px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #3d3d3b;
        border: 2px solid #cfcfcf;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
        border-radius: 6px;
        &:hover {
          color: #3d3d3b;
          border: 2px solid #a3a3a3;
        }
      }
      .title {
        margin-right: 4px;
        &:hover {
          color: #3d3d3b;
          background-color: transparent;
        }
        @media (max-width: 1023px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: block;
          margin: 0 0 0 8px;
        }
      }
      .icon-desk {
        color: #737373;
        width: 20px;
        height: 20px;
        @media (max-width: 1023px) {
          color: #fff;
        }
        @media (max-width: 767px) {
          display: none;
        }
        &.rotate-desk {
          transform: rotate(180deg);
        }
        svg {
          color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#0bd95d')};
          height: 14px;
          width: 14px;
          @media (max-width: 1023px) {
            color: #737373;
            &:hover {
              color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#0bd95d')};
            }
          }
        }
      }
      .icon-mob {
        display: none;
        @media (max-width: 767px) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          color: #cfcfcf;
        }
        svg {
          color: #737373;
        }
      }
    }
  `}

  ${(props) => props.block === 'results' && css`
    max-height: 100%;
    width: 100%;
    display: flex;
    padding: 0 24px 0 32px;
    @media (max-width: 1023px) {
      padding-left: 24px;
      height: auto;
      min-height: 0;
    }
    @media (max-width: 767px) {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
      position: fixed;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      z-index: 90;
      background: rgba(0, 0, 0, .3);
      overflow: hidden;
      -webkit-transition: visibility .15s ease, opacity .15s ease;
      transition: visibility .15s ease, opacity .15s ease;
    }
  `}

  ${(props) => props.block === 'integration' && css`
    width: 10.5%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 134px;
      padding-left: 24px;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
    }
  `}

  ${(props) => props.block === 'status' && css`
    width: 11.5%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 145px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      span:first-of-type {
        display: inline;
      }
      span {
        color: #fff;
      }
    }
  `}

  ${(props) => props.block === 'sumsp' && css`
    width: 23%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 417px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      span:first-of-type {
        display: inline;
      }
      span {
        color: #fff;
      }
    }
  `}

  ${(props) => props.block === 'id' && css`
    width: 10%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 417px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      span:first-of-type {
        display: inline;
      }
      span {
        color: #fff;
      }
    }
  `}

  ${(props) => props.block === 'into-com' && css`
    width: 13%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 165px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      span:first-of-type {
        display: inline;
      }
      span {
        color: #fff;
      }
    }
  `}

  ${(props) => props.block === 'out-com' && css`
    width: 10.5%;
    min-height: auto;
    padding: 16px;
    @media (max-width: 1023px) {
      width: 130px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      span:first-of-type {
        display: inline;
      }
      span {
        color: #fff;
      }
    }
  `}

  ${(props) => props.block === 'analize' && css`
    width: 21.5%;
    min-height: auto;
    padding-right: 24px;
    @media (max-width: 1023px) {
      width: 260px;
    }
    span:first-of-type {
      display: none;
      margin-right: 4px;
      font-weight: 500;
      color: #cfcfcf;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      padding: 4px 20px;
      @media (max-width: 767px) {
        display: block;
        width: auto;
        padding: 4px 20px;
        span:first-of-type {
          display: inline;
        }
        span {
          color: #fff;
        }
      }
    }
  `}
`;

export default function TableTbodyTd(props) {
  const {
    children, block, active,
  } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );
  return (
    <TableTbodyAnswersTd block={block} active={active} theme={theme}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTbodyAnswersTd>
  );
}
