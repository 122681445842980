import React, { Component } from 'react';
import Footer from './Footer';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class TechWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFramed: 0,
    };
  }

  componentDidMount() {
    let isFramed = false;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    document.title = 'Polis Online | Технические работы';
    const { history } = this.props;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/checkLastBuild`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.status !== 207) {
          history.push('/');
        }
      });
  }

  render() {
    const { history, theme } = this.props;
    const { isFramed } = this.state;
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        {!isFramed ? (
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand mr-auto" href="/">
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
            </a>
          </nav>
        ) : null}
        <div id="tech-work" className="container-fluid d-flex flex-fill flex-column">
          <div className="flex-fill">
            <h1>
              Обновляем сервис! Скоро вернемся!
            </h1>
          </div>
          {!isFramed ? (
            <Footer history={history} />
          ) : null }
        </div>
      </div>
    );
  }
}

export default withUserInfoStoreStore(TechWork);
