import React from 'react';
import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
    width: ${(props) => (props.width || 'initial')};
    h2 {
        margin-top: 3px;
        margin-bottom: 3px;
        color: #3d3d3b;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
    div.big-icon {
        margin-top: 55px;
        padding-left: 18px;
    }
`;

const opacity = keyframes`
    from {
        opacity: 0.3;  
    }
    to {
        transform: rotate(360deg);
        opacity: 1;
    }
`;

const WrapperIcon = styled.div`
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    animation: ${opacity} 0.6s linear;
    &:active {
        animation: ${opacity} 0.6s linear;
    }

    @media (max-width: 767px) {
        top: 15px;
        right: 15px;
    }
`;

export default function DashboardTopWrapper(props) {
  const {
    children, disabled, width, icon, onClick,
  } = props;

  return (
    <Wrapper disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
      <WrapperIcon onClick={(e) => { if (!disabled) { onClick(e); } }}>{icon}</WrapperIcon>
    </Wrapper>
  );
}
