import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { ReactDadata } from 'react-dadata';
import LoadingSpinner from '../Layout/LoadingSpinner';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

export default function AgentInsuranceIdList(props) {
  const { agent, updateAgent } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [savingRegionsFlag, setSavingRegionsFlag] = useState(false);
  const [loadingInsuranceRegionsFlag, setLoadingInsuranceRegionsFlag] = useState(true);
  const [loadingAgentInsuranceRegionFlag, setLoadingAgentInsuranceRegionFlag] = useState(true);
  const [loadingInsurancesFlag, setLoadingInsurancesFlag] = useState(true);
  const [insuranceRegion, setInsuranceRegion] = useState({});
  const [insurancesList, setInsurancesList] = useState([]);
  const [insuranceAllRegions, setInsuranceAllRegions] = useState([]);
  const [optionRegions, setOptionRegions] = useState({});
  const [insuranceLoadingFlags, setInsuranceLoadingFlags] = useState([]);
  const insurancesWithApi = useMemo(() => [
    'renessans',
  ], []);

  const loadInsuranceRegions = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insuranceRegions`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setInsuranceAllRegions(response);
          const options = {};
          Object.entries(response).forEach(([insurance, regions]) => {
            options[insurance] = regions.map((region) => ({ value: region, label: region }));
          });
          setOptionRegions(options);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки', 'error');
      })
      .finally(() => {
        setLoadingInsuranceRegionsFlag(false);
      });
  };

  const loadAgentInsuranceRegion = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/insuranceRegions`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const regions = {};
          Object.values(response).forEach((value) => {
            if (value.dadata) {
              regions[value.insurance_code] = { value: value.region, data: value.dadata };
            } else {
              regions[value.insurance_code] = value.region;
            }
          });
          setInsuranceRegion(regions);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки', 'error');
      })
      .finally(() => {
        setLoadingAgentInsuranceRegionFlag(false);
      });
  };

  const loadInsuranceList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurances?filterType=integration&filter=direct`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setInsurancesList(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки', 'error');
      })
      .finally(() => {
        setLoadingInsurancesFlag(false);
      });
  };

  const saveRegions = (e) => {
    e.preventDefault();
    setSavingRegionsFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/insuranceRegions`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insuranceRegion,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('Регионы обновлены');
        } else {
          showModalInfo('Ошибка при сохранении регионов', 'error');
        }
      })
      .catch(() => {})
      .finally(() => {
        setSavingRegionsFlag(false);
      });
  };

  const saveAgent = (e, insurance) => {
    e.preventDefault();
    setInsuranceLoadingFlags((prev) => [...prev, insurance]);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/insurance-info/set`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newAgent = { ...agent };
          newAgent.insurance_id = newAgent.insurance_id === null ? {} : newAgent.insurance_id;
          newAgent.insurance_id[insurance] = response.id;
          updateAgent(newAgent);
        } else {
          showModalInfo('Ошибка при добавлении пользователя', 'error');
        }
      })
      .finally(() => {
        const newInsuranceLoadingFlags = insuranceLoadingFlags.filter((item) => item !== insurance);
        setInsuranceLoadingFlags(newInsuranceLoadingFlags);
      });
  };

  useEffect(() => {
    loadInsuranceList();
    loadInsuranceRegions();
    loadAgentInsuranceRegion();
  }, []);

  return (
    <>
      <h2>Список страховых компаний</h2>
      <LoadingBanner loadingFlag={loadingInsuranceRegionsFlag || loadingInsurancesFlag || loadingAgentInsuranceRegionFlag}>
        <button disabled={savingRegionsFlag} type="button" onClick={saveRegions} className="btn btn-success">Сохранить регионы</button>
        {savingRegionsFlag && <LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />}
        <div className="search_results__table mt-4">
          {insurancesList.map((insurance) => (
            <div key={insurance.code} className="search_results__table--row search_results__table--row">
              <div className="search_results__table--cell">
                <div>
                  <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${insurance.logo}`} alt="logo" className="search_results__logo" />
                </div>
                <div className="search_results__company-name">
                  {insurance.name}
                </div>
              </div>
              <div className="search_results__table--cell w-25">
                {insuranceAllRegions[insurance.code] ? (
                  <Select
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name={insurance.code}
                    isSearchable
                    placeholder="Выберите регион"
                    onChange={(e) => setInsuranceRegion((prevState) => ({
                      ...prevState,
                      [insurance.code]: e.value,
                    }))}
                    value={insuranceRegion[insurance.code] ? { value: insuranceRegion[insurance.code], label: insuranceRegion[insurance.code] } : { value: null, label: 'Не выбран' }}
                    options={optionRegions[insurance.code]}
                  />
                ) : (
                  <ReactDadata
                    className={classnames('form-control')}
                    query={insuranceRegion[insurance.code] ? insuranceRegion[insurance.code].value : null}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => setInsuranceRegion((prevState) => ({
                      ...prevState,
                      [insurance.code]: e,
                    }))}
                    bounds="city"
                    placeholder="Введите город"
                  />
                )}
              </div>
              <div className="search_results__table--cell">
                {insurancesWithApi.findIndex((item) => item === insurance.code) !== -1
                && (!agent.insurance_id || Object.keys(agent.insurance_id).findIndex((key) => key === insurance.code) === -1) ?
                  agent.insurance_id && Object.keys(agent.insurance_id).findIndex((key) => key === insurance.code) !== -1 ? (
                    agent.insurance_id[insurance.code]
                  ) : (
                    <>
                      {insuranceLoadingFlags.findIndex((item) => item === insurance.code) !== -1 ? (
                        <LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} />
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => { saveAgent(e, insurance.code); }}
                          className={classnames('btn btn-success')}
                        >
                          Отправить пользователя в СК
                        </button>
                      )}
                    </>
                  )
                  : '-'}
              </div>
            </div>
          ))}
        </div>
      </LoadingBanner>
    </>
  );
}
