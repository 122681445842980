import React, { useState, useEffect, useRef } from 'react';

export default function ProgressBar(props) {
  const { durationSeconds } = props;
  const [percentProgress, setPercentProgress] = useState(0);
  const timerId = useRef(null);

  const runPercentProgress = () => {
    const duration = durationSeconds ? parseInt(durationSeconds, 10) : 100;
    const step = 10 / duration;
    timerId.current = setInterval(() => {
      setPercentProgress((prev) => {
        if (prev > 99) {
          clearInterval(timerId);
        }
        return prev + step;
      });
    }, 100);
  };

  useEffect(() => {
    runPercentProgress();
  }, []);

  useEffect(() => () => {
    clearInterval(timerId.current);
  }, []);

  return (
    <>
      {percentProgress < 99 ? (
        <div className="loader-procent">
          <div className="loader-procent__namber">{Math.round(percentProgress)}%</div>
          <div className="loader-procent__line"><span style={{ width: `${percentProgress}%` }} /></div>
        </div>
      ) : null}
    </>
  );
}
