import React, { useState } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { shallow } from 'zustand/shallow';
import SidebarList from './Sidebar/SidebarList';
import Overlay from './Overlay';
import CustomIcon from './CustomIcon';
import useUserInfoStore from '../Stores/UserInfoStore';

const Nav = styled.nav`
    position: fixed;
    background: #fff;
    /* color: #fff; */
    height: 100%;
    top: 0;
    display: block;
    z-index: ${(props) => (props.active && !props.$sidebarIsFull ? '15000' : '15')};
    left: 0;
    clip-path: ${(props) => (props.active ? 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' : 'polygon(0 0, 56px 0, 56px 100%, 0% 100%)')};
    transition: clip-path .15s ease;
    
    @media (max-width: 768px) {
      clip-path: ${(props) => (props.active ? 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' : 'polygon(0 0, 0 0, 0 100%, 0% 100%)')};
    } 

    @media (max-width: 768px) {
      width: 100%;
    } 

    &:after{
      left: ${(props) => (props.active ? '264px' : '55px')};
      width: 1px;
      opacity: 1;
      background-color: #e9e9e9;
      transition: left .15s ease;
      position: absolute;
      top: 0;
      content: "";
      height: 100%;

      @media (max-width: 768px) {
        opacity: 0;
      } 
    }
`;

const ScrollContainer = styled.div`
  margin-top: 64px;
  display: block;
  height: 100%;
  width: 264px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  } 
`;

const TopBlock = styled.div`
  position: absolute;
  height: 70px;
  width: 100%;
  background-color: #F6F6F6;
  display: ${(props) => (props.$sidebarIsFull ? 'none' : 'flex')};
  align-items: center;

  &:after {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    opacity: ${(props) => (props.$sidebarScrolled ? 1 : 0)};
    background-color: #e9e9e9;
    transition: left .15s ease;
  }

  img {
    max-height: 35px;
    max-width: 180px;
    margin-left: 16px;
  }
    
  .menu-cross {
      position: absolute;
      left: 0;
  }

  @media (max-width: 767px) {
    justify-content: space-between;
    .menu-cross {
        position: static;
        flex: 1;
    }
    img { 
      //flex: 1;
      max-width: 155px;
    }
    &:after {
        flex: 1;
        position: static;
    }
  }
`;

const MenuIcon = styled.div`
  & > div{
    cursor: pointer;
    margin-right: 16px;
    margin-left: 16px;
    color: ${(props) => (props.theme ? props.theme.mainColor : 'inherit')}
  }
`;

const TopBlockText = styled.span`
  font-size: 16px;
  cursor: pointer;
`;

export default function Sidebar(props) {
  const {
    faq, getTourList, setTour, toggleSidebar, sidebarActive, sidebarIsFull, handleLogout, setSidebarFull,
  } = props;

  const { theme } = useUserInfoStore(
    (state) => ({
      setUser: state.setUserInfo,
      theme: state.theme,
    }),
    shallow,
  );

  const [sidebarScrolled, setSidebarScrolled] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !sidebarScrolled) {
      setSidebarScrolled(true);
    } else if (e.target.scrollTop === 0 && sidebarScrolled) {
      setSidebarScrolled(false);
    }
  };

  const changeSideBarType = () => {
    setSidebarFull(true);
    toggleSidebar(false);
  };

  return (
    <>
      <Overlay active={sidebarActive} />
      <Nav
        onMouseLeave={() => { toggleSidebar(false); }}
        onMouseEnter={() => { toggleSidebar(true); }}
        active={sidebarActive || sidebarIsFull}
        $sidebarIsFull={sidebarIsFull}
      >
        <TopBlock $sidebarScrolled={sidebarScrolled} $sidebarIsFull={sidebarIsFull} theme={theme}>
          {window.innerWidth < 769 ? (
            <>
              <MenuIcon className="menu-cross">
                <CustomIcon icon="menu" width="24px" height="24px" onClick={() => { toggleSidebar(false); }} />
              </MenuIcon>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
            </>
          ) : (
            <>
              <MenuIcon>
                <CustomIcon icon="menu" width="24px" height="24px" onClick={changeSideBarType} />
              </MenuIcon>
              <TopBlockText onClick={changeSideBarType}>Развернуть меню</TopBlockText>
            </>
          )}
        </TopBlock>
        <ScrollContainer>
          <Scrollbars
            autoHeightMin="100%"
            autoHeightMax="100%"
            autoHide
            onScroll={handleScroll}
          >
            <SidebarList
              sidebarActive={sidebarActive}
              getTourList={getTourList}
              setTour={setTour}
              faq={faq}
              sidebarIsFull={sidebarIsFull}
              toggleSidebar={toggleSidebar}
              handleLogout={handleLogout}
            />
          </Scrollbars>
        </ScrollContainer>
      </Nav>
    </>
  );
}
