import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import { parse, parseISO, format } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import matchSorter from 'match-sorter';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch } from '../Utils/Helpers';

export default class AgentNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      agents: [],
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { email } = this.props;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/getNetwork?email=${email}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((agents) => {
        this.setState({ agents, loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const { email } = this.props;
    if (prevProps.email !== email) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/getNetwork?email=${email}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((agents) => {
          this.setState({ agents, loading: false });
        });
    }
  }

  setTableSettings = (newResized) => {
    const settings = reactLocalStorage.getObject('agentsNetworkTableSettings');
    newResized.forEach((item) => {
      settings[item.id] = item.value;
    });
    reactLocalStorage.setObject('agentsNetworkTableSettings', settings);
  };

  filterMethod = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
  };

  setTableSorting = (newSort) => {
    const settings = reactLocalStorage.getObject('agentsNetworkTableSettings');
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
    reactLocalStorage.setObject('agentsNetworkTableSettings', settings);
  };

  render() {
    const {
      loading, agents,
    } = this.state;
    const { history } = this.props;
    const columns = [{
      Header: 'Пользователь',
      id: 'agentName',
      accessor: (d) => `${d.last_name} ${d.first_name} ${d.middle_name}`,
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').agentName,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agentName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
    }, {
      Header: 'E-mail',
      accessor: 'email',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').email,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
      filterAll: true,
    }, {
      Header: 'Город',
      accessor: 'city',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').city,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['city'] }),
      filterAll: true,
    }, {
      Header: 'Дата регистрации',
      id: 'created_at',
      accessor: (d) => format(parseISO(d.created_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').created_at,
      Filter: () => (<div className="hideFilterInput" />),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
    }, {
      Header: 'Последняя активность',
      accessor: 'last_activity',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').last_activity,
      Filter: () => (<div className="hideFilterInput" />),
      Cell: ({ original }) => ((original.last_activity === null) ? 'Пользователь не авторизовался' : format(parseISO(original.last_activity), 'dd.MM.yyyy')),
    }, {
      Header: 'Юр. лицо',
      accessor: 'company',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').company,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
    }, {
      Header: 'Дата последней продажи',
      accessor: 'lastPolicyDate',
      Cell: ({ original }) => (original.lastPolicyDate ? format(parseISO(original.lastPolicyDate), 'dd.MM.yyyy') : null),
      filter: 'dateTextFilterFn',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').lastPolicyDate,
      minWidth: 150,
    }, {
      Header: 'Комментарий',
      accessor: 'comment',
      width: reactLocalStorage.getObject('agentsNetworkTableSettings').comment,
      filterAll: true,
      minWidth: 150,
    }, {
      id: 'status',
      Header: 'Статус',
      accessor: (d) => {
        if (d.status === 'active') {
          return 'Активирован';
        }
        if (d.status === 'inactive') {
          return 'Не активирован';
        }
        if (d.status === 'ban') {
          return 'Заблокирован';
        }
        if (d.status === 'black_list') {
          return 'В черном списке';
        }
        return 'Без статуса';
      },
      minWidth: 100,
    },
    ];
    return (
      <>
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    history.push(`/agent/${rowInfo.original.id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/agent/${rowInfo.original.id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          defaultFilterMethod={this.filterMethod}
          className="table"
          data={agents}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          onSortedChange={(newSort) => {
            this.setTableSorting(newSort);
          }}
          onResizedChange={(newResized) => {
            this.setTableSettings(newResized);
          }}
        />
      </>
    );
  }
}
