import React, {
  forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import queryString from 'query-string';
import { format, parseISO } from 'date-fns';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/fontawesome-free-solid';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  abortFetching,
  customFetch, getCustomFieldOptions,
  getDeepObjectValue,
  setDeepObjectValidationFlag,
  setDeepObjectValue,
  setNewPartnerForDigitalBroker,
  validateFields,
} from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import ModalCommercialOfferForm from '../Ifl/ModalCommercialOfferForm';
import CargoCheckInfo from './CargoCheckInfo';
import CargoShortForm from './CargoShortForm';
import CargoChosenOffer from './CargoChosenOffer';
import CargoForm from './CargoForm';
import { inputStructure } from './modules/input-structure';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../../Stores/UserInfoStore';

function CargoFormContainer(props, ref) {
  const {
    policyStatus,
    policy,
    history,
    copyDraft,
    match,
    location,
  } = props;

  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const [goingSaveDraft, setGoingSaveDraft] = useState(0);
  const [gonnaCalc, setGonnaCalc] = useState(false);
  const [curE, setCurE] = useState(null);

  const urlParams = useMemo(() => queryString.parse(location.search), []);

  const insuredAddressRegistrationRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const commercialOfferRef = useRef(null);
  const regionRef = useRef();

  const [customFieldName, setCustomFieldName] = useState('');
  const [customField, setCustomField] = useState(null);
  const [customFieldOptions, setCustomFieldOptions] = useState([]);
  const [linkToDeal, setLinkToDeal] = useState(null);
  const [leadSource, setLeadSource] = useState('');
  const [managerAttracted, setManagerAttracted] = useState('');
  const [leadSourcesOptions, setLeadSourcesOptions] = useState([]);
  const [managerAttractedOptions, setManagerAttractedOptions] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [errorBlockName, setErrorBlockName] = useState('');
  const [loadInfoFlags, setLoadingInfoFlags] = useState({
    companies: true,
    integrationsClassifiers: false,
  });
  const [loadingCargoInfo, setLoadingCargoInfo] = useState(true);
  const [draftInfo, setDraftInfo] = useState({
    hash: 0,
    id: 0,
    type: 'cargoInsurance',
  });
  const [showNSCheckInfo, setShowNSCheckInfo] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [blurClass, setBlurClass] = useState('');

  const [formData, setFormData] = useState(inputStructure);

  const [companies, setCompanies] = useState({});
  const [chosenOffer, setChosenOffer] = useState({});
  const [scrollTo, setScrollTo] = useState('');
  const [goCalcFlag, setGoCalcFlag] = useState(false);
  const [phoneCheckRequestFlag, setPhoneCheckRequestFlag] = useState(false);
  const [showForm, setShowForm] = useState('short');
  const [loadingInit, setLoadingInit] = useState(false);
  const [selectedSort, setSelectedSort] = useState('commissionCurrency');
  const [policySortOrderUp, setPolicySortOrderUp] = useState(false);
  const [policyIntegrationsData, setPolicyIntegrationsData] = useState({
    cargoInsurance: [],
  });
  const [allPolices, setAllPolices] = useState({
    cargoInsurance: [],
  });
  const [showCommission, setShowCommission] = useState(true);
  const [commercialOfferCompanies, setCommercialOfferCompanies] = useState([]);
  const [showCommercialOfferModalForm, setShowCommercialOfferModalForm] = useState('');
  const [loadingCommercialOffer, setLoadingCommercialOffer] = useState(false);
  const [newAgent, setNewAgent] = useState({ value: false, label: false });

  useEffect(() => {
    if (showForm !== 'short') {
      setShowForm(draftInfo.type);
    }
  }, [draftInfo.type]);

  const prepValueToValidate = (value, fieldName) => {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string'
            && fieldName === 'passport'
    ) {
      value = value.replace(/ +/g, '');
    }
    return value;
  };

  const handleValidate = (e, args = {}) => {
    let fieldName = '';
    if (e) {
      const { target } = e;
      fieldName = target.id;
    } else {
      fieldName = args.fieldName;
    }
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);
    let errorMessage = value.error;
    value = value.data;
    if (errorMessage && value) {
      errorMessage = value.error;
    } else {
      value = prepValueToValidate(value, fieldName);
      if (typeof value === 'string') {
        value = value.trim();
      }

      errorMessage = getValidation(value, formData[fieldName].validationType);
      if (formData[fieldName].validationType === 'select' && !formData[fieldName].value.value) {
        errorMessage = 'Выберите пункт';
      }

      if (formData[fieldName].validationType === 'plain' && !formData[fieldName].value?.value) {
        errorMessage = 'Заполните либо ИНН, либо название';
      }

      if (!formData[fieldName].value) {
        switch (formData[fieldName].validationType) {
          case 'num':
            errorMessage = 'Заполните поле';
            break;
          case 'text':
            errorMessage = 'Заполните поле';
            break;
        }
      }

      if (fieldName === 'chooseInsurance' || fieldName === 'costFull' || fieldName === 'valute'
          || fieldName === 'transport' || fieldName === 'range' || fieldName === 'cargo'
          || fieldName === 'franchise' || fieldName === 'usedcargo' || fieldName === 'loadunload' || fieldName === 'lrefrisks') {
        setBlurClass('blur');
      }
    }
    setDeepObjectValue(formData, setFormData, path, value, errorMessage);
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const toggleFlag = (e) => {
    const { target } = e;
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
  };

  const getCompaniesList = (type) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getCompanies(lsToken, type).then((response) => {
      setCompanies(response);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        companies: false,
      }));
    });
  };

  const getPrivacyPolicy = () => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        setPrivacyPolicy(response.path);
      });
  };

  const validateForm = () => {
    let formFields = { ...formData };
    formFields = {
      cargo: formFields.cargo,
      range: formFields.range,
      franchise: formFields.franchise,
      transport: formFields.transport,
      valute: formFields.valute,
      costFull: formFields.costFull,
      overload: formFields.overload,
    };

    const validationResult = validateFields(formFields);
    let newErrorBlockName = '';
    newErrorBlockName = Object.keys(validationResult.result).find(((key) => validationResult.result[key].errorMessage));
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });

    if (showForm === 'cargoInsurance') {
      Object.entries(newFormData).map((data) => {
        if (newFormData[data[0]].validationRequired) {
          if (data[1].validationType === 'plain' || data[1].validationType === 'address' || data[1].validationType === 'select') {
            if (!data[1].value.value) {
              newFormData[data[0]].errorMessage = 'Заполните поле';
              newFormData[data[0]].errorVisible = true;
              validationResult.validationSuccess = false;
            }
          } else if (!data[1].value) {
            newFormData[data[0]].errorMessage = 'Заполните поле';
            newFormData[data[0]].errorVisible = true;
            validationResult.validationSuccess = false;
          }
        }
      });
      newErrorBlockName = Object.keys(newFormData).find(((key) => newFormData[key].errorMessage));
    }

    if (!newFormData.range.value) {
      newFormData.range.errorMessage = 'Заполните поле';
      newFormData.range.errorVisible = true;
      validationResult.validationSuccess = false;
    }

    if (!newFormData.costFull.value) {
      newFormData.costFull.errorMessage = 'Заполните поле';
      newFormData.costFull.errorVisible = true;
      validationResult.validationSuccess = false;
    }

    setFormData(newFormData);
    if (newErrorBlockName || !validationResult.validationSuccess) {
      setErrorBlockName(newErrorBlockName);
      setScrollTo('errorBlock');
    }
    return validationResult.validationSuccess;
  };

  useEffect(() => {
    const showFormFlag = showForm !== 'short';
    const flagPaths = {
      usedcargo: false,
      chooseInsurance: showFormFlag,
      loadunload: false,
      refrisks: false,
      overloadAddress_1: false,
      overloadAddress_2: false,
      overloadAddress_3: false,
      overloadAddress_4: false,
      overloadAddress_5: false,
      costAgreement: false,
      insuredAddressUp: showFormFlag,
      passportDate: !formData.chooseFace.value.value && showFormFlag,
      passportDivision: !formData.chooseFace.value.value && showFormFlag,
      passportUnitNumber: !formData.chooseFace.value.value && showFormFlag,
      passport: !formData.chooseFace.value.value && showFormFlag,
      tnDate: showFormFlag,
      valute: showForm === 'short',
      chooseFace: !formData.chooseFace.value.value && showFormFlag,
      chooseBeneficiaryFace: showFormFlag,
      franchise: showForm === 'short',
      docs: showFormFlag,
      ride: showFormFlag,
      cargo: showForm === 'short',
      transport: showForm === 'short',
      costFull: showForm === 'short',
      weight: showFormFlag,
      spaces: chosenOffer.companyCode === 'pari' && showFormFlag,
      mark: showFormFlag,
      packaging: showFormFlag,
      cargoFull: showFormFlag,
      transDoc: showFormFlag,
      range: showForm === 'short',
      overload: showForm === 'short',
      insuredAddressDown: showFormFlag,
      contactPhone: showFormFlag,
      lastName: !formData.chooseFace.value.value && showFormFlag,
      firstName: !formData.chooseFace.value.value && showFormFlag,
      middleName: !formData.chooseFace.value.value && showFormFlag,
      contactEmail: showFormFlag,
      personalDataFlag: showFormFlag,
      contractInfoFlag: showFormFlag,
      beneficiaryFlag: false,
      insuranceRulesFlag: showFormFlag,
      organizationName: formData.chooseFace.value.value && showFormFlag,
      carrier: showFormFlag,
      sender: chosenOffer.companyCode === 'pari' && showFormFlag,
      payee: chosenOffer.companyCode === 'pari' && showFormFlag,
      beneficiaryName: !formData.beneficiaryFlag.value && showFormFlag,
      startDate: showFormFlag,
      endDate: showFormFlag,
      birthday: !formData.chooseFace.value.value && showFormFlag,
      insuredAddressRegistration: !formData.chooseFace.value.value && showFormFlag,
    };
    setDeepObjectValidationFlag(formData, setFormData, flagPaths);
  }, [formData.chooseFace.value, formData.beneficiaryFlag.value, showForm, showCommercialOfferModalForm, chosenOffer.companyCode]);

  const saveDraft = (e, goCalc = false, skipValidation = false) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    setGonnaCalc(goCalc);
    const formIsValid = skipValidation || validateForm();

    if (formIsValid) {
      setGoingSaveDraft(1);
      setCurE(e);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (goingSaveDraft) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      const data = {};
      Object.entries(formData).map((input) => {
        const key = input[0];
        const { value } = input[1];
        data[key] = value;
        if ((key === 'startDate' || key === 'endDate' || key === 'tnDate') && value) {
          data[key] = new Date(format(parseISO(value.toISOString()), 'yyyy-MM-dd'));
        }
      });
      try {
        if (draftInfo.id) {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${draftInfo.id}`, {
            method: 'put',
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'cargoInsurance',
              formData: data,
              service: true,
              product: draftInfo.type,
              leadSource,
              linkToDeal,
              managerAttracted,
              customField: customField ? customField.value : null,
              newAgent: newAgent.value,
            }),
          }, controller).then((response) => response.json())
            .then(() => {
              setGoingSaveDraft(0);
              if (gonnaCalc) {
                if (showForm === 'short') {
                  setGoCalcFlag(true);
                } else {
                  setShowNSCheckInfo(true);
                  setScrollTo('check_info');
                }
              } else {
                if (showCommercialOfferModalForm) {
                  if (showCommercialOfferModalForm === 'send') {
                    commercialOfferRef.current.open();
                    setShowCommercialOfferModalForm('');
                  } else {
                    setLoadingCommercialOffer(true);
                    commercialOfferRef.current.getPdf(curE);
                  }
                }
                showModalInfo('Черновик сохранен');
              }
              setGoingSaveDraft(0);
            })
            .catch(() => {
              setGoingSaveDraft(0);
              showModalInfo('Ошибка', 'error');
            });
        } else {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
            method: 'post',
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'cargoInsurance',
              formData: data,
              service: true,
              product: draftInfo.type,
              leadSource,
              linkToDeal,
              managerAttracted,
              customField: customField ? customField.value : null,
              newAgent: newAgent.value,
            }),
          }, controller).then((response) => response.json())
            .then((newDraft) => {
              setDraftInfo((prev) => ({
                ...prev,
                id: newDraft.id,
                hash: newDraft.hash,
              }));
              setGoingSaveDraft(0);
              if (gonnaCalc) {
                setGoCalcFlag(true);
              } else {
                showModalInfo('Черновик сохранен');
              }
            })
            .catch(() => {
              setGoingSaveDraft(0);
              showModalInfo('Ошибка', 'error');
            });
        }
      } catch (er) {
        setGoingSaveDraft(0);
        showModalInfo('Ошибка', 'error');
      }
    }

    return function cleanup() {
      abortFetching(controller);
    };
  }, [goingSaveDraft]);

  const loadPolicyData = () => {
    const draftData = policy.form_data;

    setDraftInfo({
      type: policy.type_insurance,
      id: policy.id,
      hash: policy.hash,
    });
    setLeadSource(policy.lead_source ? policy.lead_source : '');
    setManagerAttracted(policy.manager_attracted ? policy.manager_attracted : '');
    setCustomField(policy.custom_field ? { label: policy.custom_field.name, value: policy.custom_field.id } : null);
    setLinkToDeal(policy.link_to_deal ? policy.link_to_deal : '');
    let data = [];
    Object.entries(formData).map((prev) => {
      const key = prev[0];
      let valueFormData = draftData[key];
      if ((key === 'startDate' || key === 'endDate' || key === 'tnDate') && draftData[key]) {
        valueFormData = new Date(format(parseISO(draftData[key]), 'yyyy-MM-dd'));
      }
      data.push([
        key,
        {
          ...formData[key],
          value: valueFormData ?? formData[key].value,
        },
      ]);
    });
    data = Object.fromEntries(data);

    setNewPartnerForDigitalBroker(policy, setNewAgent);

    setFormData(data);
    if (policy.type_integration && policy.company) {
      const obja = {
        companyCode: policy.company_code,
        companyName: policy.company,
        integration: policy.type_integration,
        price: parseInt(policy.cost_currency, 10) ? policy.cost_currency : policy.cost,
        agreement: policy.need_agreement ? policy.need_agreement : false,
      };

      setChosenOffer(obja);
      setShowForm('cargoInsurance');
    }
    setDisableField(policy.status !== 'pending');
    setLoadingCargoInfo(false);
  };

  const copyForm = () => {
    copyDraft();
    setLoadingInit(false);
    setShowForm('short');
    setShowNSCheckInfo(false);
    setChosenOffer({});
    setPolicyIntegrationsData({
      cargoInsurance: [],
    });
    setAllPolices({
      cargoInsurance: [],
    });
    setDisableField(false);
  };

  const goToBuying = (e) => {
    e.preventDefault();
    if (!policy.is_demo) {
      saveDraft(e, true);
    } else {
      setShowNSCheckInfo(true);
    }
  };

  const goToFullForm = (e, companyCode, price, agreement, companyName, integration) => {
    setChosenOffer({
      companyCode,
      companyName,
      integration,
      price,
      agreement,
    });
    const newFormData = { ...formData };
    setFormData(newFormData);
    setShowForm(draftInfo.type);
    setScrollTo('chosen-offer');
  };

  const backToForm = (e, scrollTarget = '', onlyShowData = false) => {
    e.preventDefault();
    setShowNSCheckInfo(false);
    if (scrollTarget === 'short') {
      setShowForm('short');
      if (!allPolices[draftInfo.type].length && !onlyShowData) {
        setGoCalcFlag(true);
        scrollTarget = 'searchResults';
      } else {
        scrollTarget = 'ns_container';
      }
    }
    setScrollTo(scrollTarget);
  };

  useEffect(() => {
    if (scrollTo !== '') {
      switch (scrollTo) {
        case 'searchResults':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
          break;
        case 'chosen-offer':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        case 'top':
          scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: true,
          });
          break;
        case 'errorBlock':
          scroller.scrollTo(errorBlockName, {
            duration: 1200,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        default:
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
      }
    }
    setScrollTo('');
  }, [scrollTo]);

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  useEffect(() => {
    if (policy.id !== 0) {
      if (policy.status !== 'pending') {
        getCompaniesList('all');
      } else {
        getCompaniesList('cargoInsurance');
      }
    } else if (!match.params.id) {
      getCompaniesList('cargoInsurance');
    }
  }, [policy.id]);

  useEffect(() => {
    if (!loadInfoFlags.companies) {
      if (match.params.id) {
        if (policy.id !== 0) {
          loadPolicyData(policy.id);
        }
      } else {
        setLoadingCargoInfo(false);
      }
    }
  }, [loadInfoFlags.companies, policy.id]);

  useEffect(() => {
    if (draftInfo.id) {
      let link = `/cargoInsurance/${draftInfo.id}`;
      if (urlParams.adminEdit) {
        link = `/cargoInsurance/${draftInfo.id}?adminEdit=1`;
      }
      window.history.pushState(null, null, link);
    }
  }, [draftInfo.id]);

  useEffect(() => {
    if (draftInfo.id) {
      setBlurClass('blur');
    }
  }, [draftInfo.type]);

  useEffect(() => {
    if (!formData.startDate.errorMessage && formData.endDate.value && formData.endDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.startDate.value]);

  useEffect(() => {
    if (!formData.endDate.errorMessage && formData.startDate.value && formData.startDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        startDate: {
          ...prev.startDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.endDate.value]);

  useEffect(() => {
    if (userInfo.full_time_employee === 1 && process.env.REACT_APP_API_DOMAIN === 'https://api.polis.online/api') {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/lead_sources`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setLeadSourcesOptions(response.map((source) => ({ value: source.id, label: source['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setManagerAttractedOptions(response.map((manager) => ({ value: manager.id, label: manager['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
    }
  }, [userInfo.full_time_employee]);

  useEffect(() => {
    getPrivacyPolicy();
    getCustomFieldOptions().then((response) => {
      if (response.options) {
        setCustomFieldOptions(response.options.map((option) => ({ value: option.id, label: option.name })));
        setCustomFieldName(response.name);
      }
    });
  }, []);

  return (
    <>
      <FormNotificationColorLine offsetBottom="1rem">
        Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.
      </FormNotificationColorLine>
      {showNSCheckInfo && (
        <CargoCheckInfo
          history={history}
          formData={formData}
          companies={companies}
          backToForm={backToForm}
          chosenOffer={chosenOffer}
          loadPolicy={loadPolicyData}
          setChosenOffer={setChosenOffer}
          draftInfo={draftInfo}
          setDisableField={setDisableField}
          blurClass={blurClass}
          policy={policy}
        />
      )}
      <div name="ns_container" style={{ display: showNSCheckInfo ? 'none' : 'block' }}>
        <LoadingBanner loadingFlag={loadingCargoInfo}>
          {!chosenOffer.companyCode || showForm === 'short' ? (
            <>
              {showCommercialOfferModalForm ? (
                <ModalCommercialOfferForm
                  formData={formData}
                  setFormData={setFormData}
                  saveDraft={saveDraft}
                  phoneCheckRequestFlag={phoneCheckRequestFlag}
                  handleValidate={handleValidate}
                  disableField={disableField}
                  showCommercialOfferModalForm={showCommercialOfferModalForm}
                  setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                  loadingCommercialOffer={loadingCommercialOffer}
                  showForm={showForm}
                  toggleFlag={toggleFlag}
                />
              ) : null}
              <CargoShortForm
                disableField={disableField}
                formData={formData}
                setFormData={setFormData}
                saveDraft={saveDraft}
                regionRef={regionRef}
                handleValidate={handleValidate}
                companies={companies}
                goToFullForm={goToFullForm}
                draftInfo={draftInfo}
                goCalcFlag={goCalcFlag}
                setGoCalcFlag={setGoCalcFlag}
                blurClass={blurClass}
                setBlurClass={setBlurClass}
                showForm={showForm}
                toggleFlag={toggleFlag}
                backToForm={backToForm}
                loadingInit={loadingInit}
                setLoadingInit={setLoadingInit}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
                policySortOrderUp={policySortOrderUp}
                setPolicySortOrderUp={setPolicySortOrderUp}
                policyIntegrationsData={policyIntegrationsData}
                setPolicyIntegrationsData={setPolicyIntegrationsData}
                allPolices={allPolices}
                setAllPolices={setAllPolices}
                showCommission={showCommission}
                setShowCommission={setShowCommission}
                commercialOfferCompanies={commercialOfferCompanies}
                setCommercialOfferCompanies={setCommercialOfferCompanies}
                setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                showCommercialOfferModalForm={showCommercialOfferModalForm}
                commercialOfferRef={commercialOfferRef}
                setLoadingCommercialOffer={setLoadingCommercialOffer}
                setScrollTo={setScrollTo}
                policy={policy}
                setCursorToEnd={setCursorToEnd}
              />
            </>
          ) : null}
          {showForm !== 'short' ? (
            <>
              {chosenOffer.companyCode ? (
                <CargoChosenOffer
                  disableField={disableField}
                  chosenOffer={chosenOffer}
                  companies={companies}
                  backToForm={backToForm}
                  formData={formData}
                  draftInfo={draftInfo}
                  policyStatus={policyStatus}
                  setFormData={setFormData}
                  showModalInfo={showModalInfo}
                  policy={policy}
                  setChosenOffer={setChosenOffer}
                />
              ) : null}
              <div className="form-group row">
                <div className="col-lg-12">
                  <h3>Оформление страхового полиса</h3>
                </div>

                {!policy.is_demo && !disableField ? (
                  <div className="pl-3">
                    <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {showForm !== 'short' ? (
            <CargoForm
              customFieldName={customFieldName}
              customFieldOptions={customFieldOptions}
              setCustomField={setCustomField}
              customField={customField}
              linkToDeal={linkToDeal}
              regionRef={regionRef}
              leadSource={leadSource}
              setLeadSource={setLeadSource}
              managerAttracted={managerAttracted}
              setManagerAttracted={setManagerAttracted}
              setLinkToDeal={setLinkToDeal}
              leadSourcesOptions={leadSourcesOptions}
              managerAttractedOptions={managerAttractedOptions}
              setFormData={setFormData}
              companies={companies}
              privacyPolicy={privacyPolicy}
              formData={formData}
              disableField={disableField}
              handleValidate={handleValidate}
              toggleFlag={toggleFlag}
              setCursorToEnd={setCursorToEnd}
              insuredAddressRegistrationRef={insuredAddressRegistrationRef}
              phoneCheckRequestFlag={phoneCheckRequestFlag}
              policy={policy}
              urlParams={urlParams}
              saveDraft={saveDraft}
              goToBuying={goToBuying}
              draftInfo={draftInfo}
              chosenOffer={chosenOffer}
              showForm={showForm}
              newAgent={newAgent}
              setNewAgent={setNewAgent}
            />
          ) : null}
        </LoadingBanner>
      </div>
    </>
  );
}

export default forwardRef(CargoFormContainer);
