import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Card = styled.li`
  width: ${(props) => (props.width || '32.1%')};
  

  @media (max-width: 1023px) {
    width: 100%;
  }
 `;

const CardComponents = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
  box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
  background-color: #fff;
  overflow: hidden;
  outline: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px 24px 18px;
  -webkit-transition: -webkit-box-shadow .15s ease;
  transition: box-shadow .15s ease;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: initial;


  @media (max-width: 767px) {
    padding: 12px 16px 16px;
  }
`;

export default function DashboardCard(props) {
  const {
    children, disabled, width, setMainContainerElement,
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    setMainContainerElement(ref);
  }, []);
  return (
    <Card disabled={disabled} width={width} ref={ref}>
      <CardComponents>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </CardComponents>
    </Card>
  );
}
