import React, {
  useEffect, useState, forwardRef, useImperativeHandle, useRef,
} from 'react';
import parse from 'html-react-parser';
import { shallow } from 'zustand/shallow';
import draftToHtml from 'draftjs-to-html';
import { createGlobalStyle } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Tour from 'reactour';
import { customFetch } from '../Utils/Helpers';
import InterfaceTourPanel from './InterfaceTourPanel';
import Button from '../Layout/Button';
import useUserInfoStore from '../Stores/UserInfoStore';

const GlobalStyle = createGlobalStyle`
  .reactour__helper {
    border-radius: 8px;
    padding: 26px 16px 16px 16px;
    margin: ${(props) => (props.$inMenu ? '10px' : 0)};

    div[data-tour-elem="controls"] {
      margin-top: 0;
    }

    button[data-tour-elem="left-arrow"] {
      margin-right: 0;
    }

    button[data-tour-elem="right-arrow"] {
      margin-left: 0;
    }

    button button {
      margin-top: 24px;
      white-space: normal;
    }

    p:last-of-type, h1:last-of-type, h2:last-of-type, h3:last-of-type, h4:last-of-type, h5:last-of-type, h6:last-of-type {
      margin-bottom: 0;
    }
  }
  #___reactour {
    z-index: 16000;
    position: relative;
  }
`;

function ServiceTour(props, ref) {
  const EMPTY_TOUR = {
    id: 0,
    name: '',
    code: '',
    steps: [],
    buttonInfo: '',
  };

  const {
    toggleSidebar, sidebarActive,
  } = props;
  const history = useHistory();

  const [tourIsOpen, setTourIsOpen] = useState(false);
  const [currentTour, setCurrentTour] = useState(EMPTY_TOUR);
  const [loadingTours, setLoadingTours] = useState(true);
  const [tourToGo, setTourToGo] = useState('');
  const [userTours, setUserTours] = useState([]);
  const [next, setNext] = useState('');
  const loadDemoData = useRef(null);
  const loadRealData = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [targetStep, setTargetStep] = useState(0);
  const [inMenu, setInMenu] = useState(false);

  const { userInfo, theme, setUserInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      theme: state.theme,
      setUserInfo: state.setUserInfo,
    }),
    shallow,
  );

  const tourRef = useRef(null);
  const panelRef = useRef(null);

  const loadInterfaceTours = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error && !response.message) {
          setUserTours(response);
        }
      })
      .finally(() => {
        setLoadingTours(false);
      });
  };

  const setFinished = (tourId) => {
    const newTours = userTours.filter((tour) => tour.id !== tourId);
    setUserTours(newTours);

    if (loadRealData.current) {
      loadRealData.current();
      loadDemoData.current = null;
      loadRealData.current = null;
    }

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/${tourId}/set-finished`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const newUserInfo = { ...userInfo };
        if (response.showToursFlag === false) {
          newUserInfo.show_interface_tours = false;
        }
        newUserInfo.interface_tours_percent = response.interface_tours_percent;
        setUserInfo(newUserInfo);
        let localTourData = localStorage.getItem('interfaceTourData');
        if (localTourData) {
          localTourData = new Map(JSON.parse(localTourData));
          localTourData.delete(currentTour.id);
          localStorage.setItem('interfaceTourData', JSON.stringify(Array.from(localTourData.entries())));
        }
        localStorage.removeItem('interfaceTourData');
      });
  };

  const closeTour = (isFinished = false) => {
    if (isFinished) {
      setFinished(currentTour.id);
    }
    setTourIsOpen(false);
    setCurrentTour(EMPTY_TOUR);
    if (next) {
      history.push(next);
      setNext('');
    }
  };

  const openTour = (selector) => {
    let count = 0;
    const interval = setInterval(() => {
      if (count > 4) {
        setTourIsOpen(true);
        clearInterval(interval);
      } else {
        const firstBlock = document.querySelector(selector);
        if (firstBlock) {
          setTourIsOpen(true);
          clearInterval(interval);
        }
      }
      count += 1;
    }, 100);
  };

  const fixMaskHeight = () => {
    setTimeout(() => {
      const tourSvg = document.getElementById('___reactour').getElementsByTagName('svg')[0];
      const maskRect = tourSvg.getElementById('mask-main').getElementsByTagName('rect')[1];
      if (parseFloat(maskRect.getAttribute('y')) === 0) {
        const clipRect = tourSvg.getElementById('clip-path').getElementsByTagName('rect')[3];
        maskRect.setAttribute('height', clipRect.getAttribute('y'));
      }
    }, 20);
  };

  const stepIsChanged = (curr) => {
    fixMaskHeight();
    setCurrentStep(curr);
  };

  useImperativeHandle(ref, () => ({
    setTour: (tourName) => {
      if (window.innerWidth > 768) {
        setTourToGo(tourName);
      }
    },
    setDataLoadFunctions: (loadDemoDataFunc, loadRealDataFunc) => {
      loadDemoData.current = loadDemoDataFunc;
      loadRealData.current = loadRealDataFunc;
    },
    getTourList: () => userTours,
  }));

  const parseHTMLInSteps = (tour) => {
    const newTour = { ...tour };
    newTour.steps = newTour.steps.map((step) => ({
      ...step,
      content: parse(draftToHtml(JSON.parse(step.content))),
    }));
    return newTour;
  };

  useEffect(() => {
    if (!loadingTours && tourToGo && userInfo.show_interface_tours) {
      const tourIndex = userTours.findIndex((tour) => tour.code === tourToGo);
      if (tourIndex !== -1) {
        let url = userTours[tourIndex].url_show_at ? `${userTours[tourIndex].url_show_at}` : '';
        if (url && !url.startsWith('/')) {
          url = `/${url}`;
        }
        if (url && history.location.pathname !== url) {
          setNext(history.location.pathname);
          history.push(url);
        }
        if (loadDemoData.current) {
          loadDemoData.current();
        }
        const preparedTour = parseHTMLInSteps(userTours[tourIndex]);
        setCurrentTour(preparedTour);
        openTour(userTours[tourIndex].steps[0].selector);
        setTourToGo('');
      }
    }
  }, [loadingTours, tourToGo, userInfo.show_interface_tours]);

  useEffect(() => {
    loadInterfaceTours();
  }, []);

  useEffect(() => {
    if (tourIsOpen) {
      document.body.style.overflow = 'hidden';
      const tourSvg = document.getElementById('___reactour').getElementsByTagName('svg')[0];
      const maskRect = tourSvg.getElementById('mask-main').getElementsByTagName('rect')[1];
      if (!maskRect.getAttribute('rx')) {
        maskRect.setAttribute('rx', '8');
      }
      if (!maskRect.getAttribute('ry')) {
        maskRect.setAttribute('ry', '8');
      }
      fixMaskHeight();
    } else {
      document.body.style.overflow = null;
    }
  }, [tourIsOpen]);

  const fixBottomScroll = (step) => {
    const targets = document.getElementsByClassName(currentTour.steps[step].selector.replace('.', ''));
    if (targets.length) {
      const target = targets[0];
      const diff = (target.offsetTop + target.clientHeight) - (window.scrollY + window.innerHeight - panelRef.current.clientHeight - 20);
      if (diff > 0) {
        window.scrollTo(0, window.scrollY + diff);
      }
    }
  };

  const checkMenu = (step) => {
    let delay = 0;
    const classOfTargetButton = `${currentTour.steps[step].selector}_open`;
    let targetButton = document.getElementsByClassName(classOfTargetButton.replace('.', ''));
    if (targetButton.length) {
      targetButton = targetButton[0];
      if (targetButton.hasAttribute('data-sleep')) {
        delay = targetButton.dataset.sleep;
      }
      targetButton.click();
    } else {
      document.getElementById('content').click();
    }

    const targets = document.getElementsByClassName(currentTour.steps[step].selector.replace('.', ''));
    if (targets.length) {
      const target = targets[0];
      if (target.classList.contains('sidebar-item')) {
        setInMenu(true);
        if (!sidebarActive) {
          toggleSidebar(true);
        }
      } else if (!target.classList.contains('sidebar-item')) {
        setInMenu(false);
        if (sidebarActive) {
          toggleSidebar(false);
        }
      }
    } else if (sidebarActive) {
      toggleSidebar(false);
    }
    return delay;
  };

  const goNextStep = (e) => {
    e.preventDefault();
    fixBottomScroll(targetStep + 1);
    const delay = checkMenu(targetStep + 1);
    setTimeout(() => {
      setTargetStep((prev) => prev + 1);
    }, delay);
  };

  const goPrevStep = (e) => {
    e.preventDefault();
    fixBottomScroll(targetStep - 1);
    const delay = checkMenu(targetStep - 1);
    setTimeout(() => {
      setTargetStep((prev) => prev - 1);
    }, delay);
  };

  const goToStep = (e, step) => {
    e.preventDefault();
    fixBottomScroll(step);
    const delay = checkMenu(step);
    setTimeout(() => {
      setTargetStep(step);
    }, delay);
  };

  useEffect(() => {
    if (currentTour.id && (currentStep || currentStep === 0)) {
      let localTourData = localStorage.getItem('interfaceTourData');
      if (!localTourData) {
        localTourData = new Map();
      } else {
        localTourData = new Map(JSON.parse(localTourData));
      }
      localTourData.set(currentTour.id, currentStep);
      localStorage.setItem('interfaceTourData', JSON.stringify(Array.from(localTourData.entries())));
    }
  }, [currentStep]);

  useEffect(() => {
    if (tourIsOpen) {
      let localTourData = localStorage.getItem('interfaceTourData');
      if (localTourData) {
        localTourData = new Map(JSON.parse(localTourData));
        if (localTourData.has(currentTour.id)) {
          const goStep = localTourData.get(currentTour.id);
          fixBottomScroll(goStep);
          const delay = checkMenu(goStep);
          setTimeout(() => {
            setTargetStep(goStep);
          }, delay);
        }
      }
    }
  }, [tourIsOpen]);

  return (
    <>
      <GlobalStyle $inMenu={inMenu} />
      <Tour
        steps={currentTour.steps}
        isOpen={tourIsOpen}
        onRequestClose={closeTour}
        badgeContent={(curr, tot) => `${curr} из ${tot}`}
        closeWithMask={false}
        lastStepNextButton={<Button width="300px" onClick={() => { closeTour('isFinished'); }}>{currentTour.button_info ? currentTour.button_info : 'Теперь попробуйте сами...'}</Button>}
        showNavigation={false}
        showCloseButton={false}
        prevButton={(<div />)}
        inViewThreshold={1}
        nextButton={(<div />)}
        accentColor={theme.mainColor}
        startAt={targetStep}
        disableInteraction
        highlightedMaskClassName="react-tour-highlighted"
        ref={tourRef}
        getCurrentStep={stepIsChanged}
        goToStep={targetStep}
        maskSpace={inMenu ? 0 : 10}
      />
      {tourIsOpen ? (
        <InterfaceTourPanel
          closeTour={closeTour}
          steps={currentTour.steps}
          currentStep={currentStep}
          nextStep={goNextStep}
          prevStep={goPrevStep}
          goToStep={goToStep}
          domRef={panelRef}
          userInfo={userInfo}
          updateUser={setUserInfo}
        />
      ) : null}
    </>
  );
}

export default forwardRef(ServiceTour);
