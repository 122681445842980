const defaultSelectValue = { value: '', label: '' };
// eslint-disable-next-line import/prefer-default-export
export const inputStructure = {
  usedcargo: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'usedcargo',
  },
  chooseInsurance: {
    value: 'all',
    errorMessage: '',
    validationRequired: true,
    validationType: 'chooseInsurance',
  },
  loadunload: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'loadunload',
  },
  refrisks: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'refrisks',
  },
  overloadAddress_1: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  overloadAddress_2: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  overloadAddress_3: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  costAgreement: {
    value: '',
    errorMessage: '',
    validationRequired: false,
    validationType: 'discountPercent',
  },
  overloadAddress_4: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  overloadAddress_5: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  insuredAddressUp: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  passportDate: {
    value: null,
    errorMessage: '',
    validationRequired: true,
    validationType: 'date',
  },
  passportDivision: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'division',
  },
  passportUnitNumber: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'passportUnitNumber',
  },
  passport: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'passportNumberSerie',
  },
  tnDate: {
    value: null,
    errorMessage: '',
    validationRequired: true,
    validationType: 'date',
  },
  valute: {
    value: { value: 'ruble', label: '₽' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  chooseFace: {
    value: { value: true, label: 'Юр.лицо' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  chooseBeneficiaryFace: {
    value: { value: true, label: 'Юр.лицо' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  franchise: {
    value: defaultSelectValue,
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  docs: {
    value: defaultSelectValue,
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  ride: {
    value: defaultSelectValue,
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  cargo: {
    value: defaultSelectValue,
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  transport: {
    value: defaultSelectValue,
    errorMessage: '',
    validationRequired: true,
    validationType: 'select',
  },
  costFull: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'costFull',
  },
  weight: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'num',
  },
  spaces: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'num',
  },
  mark: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  packaging: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  cargoFull: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  transDoc: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  range: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'num',
  },
  overload: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'overload',
  },
  insuredAddressDown: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'text',
  },
  contactPhone: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'phone',
  },
  lastName: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'name',
  },
  firstName: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'name',
  },
  middleName: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'name',
  },
  contactEmail: {
    value: '',
    errorMessage: '',
    validationRequired: true,
    validationType: 'email',
  },
  personalDataFlag: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'checkbox',
  },
  contractInfoFlag: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'checkbox',
  },
  beneficiaryFlag: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'checkbox',
  },
  insuranceRulesFlag: {
    value: false,
    errorMessage: '',
    validationRequired: true,
    validationType: 'checkbox',
  },
  organizationName: {
    value: { data: { inn: '' }, value: '' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'plain',
  },
  carrier: {
    value: { data: { inn: '' }, value: '' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'plain',
  },
  sender: {
    value: { data: { inn: '' }, value: '' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'plain',
  },
  payee: {
    value: { data: { inn: '' }, value: '' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'plain',
  },
  beneficiaryName: {
    value: { data: { inn: '' }, value: '' },
    errorMessage: '',
    validationRequired: true,
    validationType: 'plain',
  },
  startDate: {
    value: null,
    errorMessage: '',
    validationRequired: true,
    validationType: 'date',
  },
  endDate: {
    value: null,
    errorMessage: '',
    validationRequired: true,
    validationType: 'date',
  },
  birthday: {
    value: null,
    errorMessage: '',
    validationRequired: true,
    validationType: 'date',
  },
  insuredAddressRegistration: {
    value: {
      value: '',
      data: {},
    },
    errorMessage: '',
    validationRequired: true,
    validationType: 'address',
  },
};
