import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import InterfaceTourOptions from './InterfaceTourOptions';
import InterfaceTourAgentFlagsTable from './InterfaceTourAgentFlagsTable';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default function InterfaceTourEdit(props) {
  const {
    tour,
    setTour,
    flushCurrentTour,
    history,
  } = props;

  const [activeTab, setActiveTab] = useState('1');
  const [agentsFlagsList, setAgentsFlagList] = useState([]);

  const tableRef = useRef(null);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const changeTab = (tab) => {
    setActiveTab(tab);
    const urlParams = queryString.parse(window.location.search);
    let queryParams = '?';
    Object.entries(urlParams).forEach(([key, value]) => {
      if (key !== 'tourEditActiveTab' && key !== '') {
        if (queryParams === '?') {
          queryParams = `${queryParams}${key}=${value}`;
        } else {
          queryParams = `${queryParams}&${key}=${value}`;
        }
      }
    });
    queryParams = `${queryParams}&tourEditActiveTab=${tab}`;
    history.push({ search: queryParams });
  };

  const loadAgentsFlags = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/get-agent-flags-by-tour/${tour.id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          response = response.map((flag) => ({
            ...flag,
            agentFio: `${flag.last_name} ${flag.first_name} ${flag.middle_name}`,
          }));
          setAgentsFlagList(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const openModalTableCustomized = () => {
    tableRef.current.openModalTableCustomized(true);
  };

  useEffect(() => {
    loadAgentsFlags();
  }, []);

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (tour.id && 'tourEditActiveTab' in urlParams) {
      setActiveTab(urlParams.tourEditActiveTab);
    }
  }, [tour.id]);

  return (
    <>
      <div className="position-relative">
        <div className="row">
          <div className="col-12 mb-3">
            <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); flushCurrentTour(); }}>К списку демо-туров</LinkBreadcrumbs>
          </div>
          {activeTab === '2' ? (
            <div className="col-12 mb-3">
              <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
                <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                Настроить таблицу
              </Button>
            </div>
          ) : null}
        </div>
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { changeTab('1'); }}
            text="Настройки"
          />
          <TabNavItem
            active={activeTab === '2'}
            onClick={() => { changeTab('2'); }}
            text="Статус проходжения тура пользователями"
          />
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            <InterfaceTourOptions
              tour={tour}
              setTour={setTour}
              flushCurrentTour={flushCurrentTour}
            />
          </TabPane>
          <TabPane tabId="2">
            <InterfaceTourAgentFlagsTable
              agentsFlagsList={agentsFlagsList}
              ref={tableRef}
              type="tour"
              setAgentsFlagsList={setAgentsFlagList}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}
