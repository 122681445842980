import decode from 'jwt-decode';

export default class AuthService {
  constructor(domain) {
    this.domain = domain || process.env.REACT_APP_API_DOMAIN;
    this.fetch = this.fetch.bind(this);
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  loginWidget(widgetId, apiHash, product) {
    return this.fetch(`${this.domain}/widget-login`, {
      method: 'POST',
      body: JSON.stringify({
        widgetId,
        apiHash,
        product,
      }),
    }).then((res) => {
      this.setWidgetToken(res.token);
      return Promise.resolve(res);
    });
  }

  login(email, password) {
    return this.fetch(`${this.domain}/sign-in-user`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((res) => {
      this.setToken(res.token);
      if (res.domain !== window.location.host.split('.')[0] && this.theme !== 'localhost:3000' && res.role !== 'admin' && res.role !== 'superadmin' && !res.error) {
        localStorage.removeItem('id_token');
        window.location.href = `https://${res.domain}.polis.online/login`;
        return 0;
      }
      return Promise.resolve(res);
    });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  };

  setWidgetToken = (idToken) => {
    localStorage.setItem('id_token_widget', idToken);
  };

  setToken = (idToken) => {
    localStorage.setItem('id_token', idToken);
  };

  getToken = () => localStorage.getItem('id_token');

  logout = () => {
    localStorage.removeItem('id_token');
  };

  getProfile() {
    return decode(this.getToken());
  }

  fetch(url, options) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (this.loggedIn()) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this.checkStatus)
      .then((response) => response.json());
  }

  checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
