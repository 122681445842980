import React from 'react';
import {
  CalculatorIcon, ChatBubbleLeftRightIcon, CalendarIcon, BanknotesIcon,
} from '@heroicons/react/24/outline';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

export default function InsuranceRequest() {
  return (
    <>
      <h1 className="page_title">Заявка на страхование</h1>
      <h2>Индивидуальное согласование любых видов страхования</h2>
      <div className="partner-scheme mb-5">
        <div className="partner-header mb-2">Схема работы</div>
        <div className="partner-scheme-inner mb-4">
          <div className="partner-scheme-item">
            <div>
              <CalculatorIcon />
            </div>
            <div className="partner-scheme-text">Заполните форму заявки</div>
          </div>
          <div className="partner-scheme-item">
            <div>
              <ChatBubbleLeftRightIcon />
            </div>
            <div className="partner-scheme-text">Свяжемся с вами, уточним детали</div>
          </div>
          <div className="partner-scheme-item">
            <div>
              <CalendarIcon />
            </div>
            <div className="partner-scheme-text">Согласуем полис со страховой и пришлём ссылку на оплату</div>
          </div>
          <div className="partner-scheme-item">
            <div>
              <BanknotesIcon />
            </div>
            <div className="partner-scheme-text">Вышлем полис и начислим комиссию на ваш баланс</div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <a rel="noreferrer" target="_blank" href="https://docs.google.com/forms/d/1MRH9SGtRqBOasKlGRzpEjldPB1aQkXDsT6TsAgVnG3w/edit">
            <button type="submit" className="btn btn-success">Заполнить заявку</button>
          </a>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12">
          <FormNotificationColorLine offsetBottom="1rem">
            <ul className="mb-0">
              <li>Оформление полиса происходит в несколько этапов;</li>
              <li>Заполняете заявку;</li>
              <li>Менеджер связывается с Вами и уточняет все детали, запрашивает документы по списку;</li>
              <li>Оплата полиса производится по ссылке в страховую компанию;</li>
              <li>В течение 3-х рабочих дней полис появится у Вас в личном кабинете в разделе «Полисы»;</li>
              <li>Вознаграждение автоматически начисляется на баланс;</li>
              <li>Вывод средств происходит в стандартном режиме в зависимости от вашего юр. статуса;</li>
            </ul>
          </FormNotificationColorLine>
        </div>
      </div>
    </>
  );
}
