import styled from 'styled-components';
import React from 'react';

export const ContainerMainInfo = styled.div`
  margin-top: 4%;
  display: flex;
  p {
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    margin: 0 10px 0 0;
  }
`;

export default function DashboardMainInfo(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <ContainerMainInfo disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </ContainerMainInfo>
  );
}
