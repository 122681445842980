import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import {handleChange} from "../../Utils/Helpers";
import {ReactDadata} from "react-dadata";

const CargoInsuranceResearch = ({formData, setFormData, disabled}) => {
    const [countOverload, setCountOverload] = useState([
        {
            exist: true,
        },
        {
            exist: !!formData['overloadAddress_2']['value'],
        },
        {
            exist: !!formData['overloadAddress_3']['value'],
        },
        {
            exist: !!formData['overloadAddress_4']['value'],
        },
        {
            exist: !!formData['overloadAddress_5']['value'],
        },
    ]);

    const [disabledBtn, setDisabledBtn] = useState(false);

    const addNewOverloadReactData = () => {
        const lastExist = countOverload.findIndex((child)=> {
            return !child.exist;
        });

        if (lastExist !== -1) {
            const newCountOverload = countOverload.map((child, index) => {
               if (index === lastExist) {
                   child.exist = true;
               }

               return child;
            });

            setCountOverload(prev => newCountOverload);
            countExistOverloadData();
        }
    }

    const countExistOverloadData = () => {
        let countExist = 0;
        countOverload.map((overload) => {
            if (overload.exist)
            countExist += 1;
        })

        if (countExist === 5)
            setDisabledBtn(true)
        else
            setDisabledBtn(false)
    }

    useEffect(() => {
        for (let i = 0; i < 5; i++) {
            setFormData(prev => ({
                ...prev,
                [`overloadAddress_${i + 1}`]: {
                    ...prev[`overloadAddress_${i + 1}`],
                    value: ''
                }
            }));
        }

    }, [formData.overload.value]);

    const deleteOverloadAddress = (indexChild) => {
        const newCountOverload = countOverload.map((child, index) => {
            if (index === indexChild) {
                child.exist = false;
            }

            return child;
        });

        setCountOverload(prev => newCountOverload);
        setFormData(prev => ({
            ...prev,
            [`overloadAddress_${indexChild + 1}`]: {
                ...prev[`overloadAddress_${indexChild + 1}`],
                value: ''
            }
        }));
        countExistOverloadData();
    }

    return (
        <>
            <div className="form-group row">
                {countOverload.map((child, index) => {
                        let id = index + 1;

                        if (child.exist) {
                            return (
                                <div className="col-lg-12  mb-3 mb-lg-0" key={id}>
                                    <label className="font-weight-bold">Пункт перегрузки {id}
                                        {index !== 0 ? (<a onClick={(e) => {
                                            deleteOverloadAddress(index);
                                        }} className="close-driver">×</a>) : null}
                                    </label>
                                    <ReactDadata
                                        id="insuredAddress"
                                        disabled={disabled}
                                        name="insuredAddressUp"
                                        className={classnames('form-control', {error: formData[`overloadAddress_${id}`].errorMessage})}
                                        query={formData[`overloadAddress_${id}`].value?.value}
                                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                                        onChange={(e) => handleChange(e, `overloadAddress_${id}`, formData, setFormData)}
                                        placeholder="Укажите адрес"
                                    />
                                </div>
                            );
                        }
                    }
                )}
            </div>
            <button className="btn btn-border btn-success" disabled={disabledBtn}
                    onClick={addNewOverloadReactData}>Добавить пункт перегрузки
            </button>
        </>
    );
};

export default CargoInsuranceResearch;