import React from 'react';
import styled from 'styled-components';

const DropdownTableControls = styled.div`
  z-index: 1;
  padding: 0;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  margin-top: -15px;
  position: sticky;
  top: 62px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
 `;

const DropdownTableControlsMain = styled.div`
    @media (max-width: 1023px) {
        width: 100%;
    }
 `;

const DropdownTableControlsWrapper = styled.div`
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @media (max-width: 1023px) {
    justify-content: flex-start;
    margin-top: 27px;
    margin-bottom: 16px;
  }
 `;

export default function DropdownDashboardControls(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <DropdownTableControlsMain>
      <DropdownTableControls disabled={disabled} width={width}>
        <DropdownTableControlsWrapper>
          {Array.isArray(children) ? (
            children.map((child) => child)
          ) : (
            children
          )}
        </DropdownTableControlsWrapper>
      </DropdownTableControls>
    </DropdownTableControlsMain>
  );
}
