import React, { useEffect, useState } from 'react';
import {
  LineChart, ResponsiveContainer, Tooltip, Line,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity } from '@fortawesome/fontawesome-free-solid';
import styled from 'styled-components';
import DashboardTopWrapper from '../Layout/Dashboard/DashboardTopWrapper';
import DashboardMainInfo, { ContainerMainInfo } from '../Layout/Dashboard/DashboardMainInfo';
import DashboardProgressPercent, { ContainerWrapperIcon } from '../Layout/Dashboard/DashboardProgressPercent';
import DashboardWrapperFooterText, { WrapperFooterText } from '../Layout/Dashboard/DashboardWrapperFooterText';
import ProgressBarLines, { LinesContainer } from '../Layout/ProgressBarLines';
import ProgressBarLine from '../Layout/ProgressBarLine';
import DashboardPointList from '../Layout/Dashboard/DashboardPointList';
import DashboardPointListItem from '../Layout/Dashboard/DashboardPointListItem';
import DashboardCard from '../Layout/Dashboard/DashboardCard';
import DashboardTooltipGraphs from '../Layout/Dashboard/DashboardTooltipGraphs';

export default function DashboardCardLinesBoard(props) {
  const {
    titleBoard,
    iconTitle,
    progressBarData,
    listDashboardData,
    mainInfoData,
    mainInfoTitle,
    footerText,
    iconGraph,
    listDashboardDotVisible,
  } = props;
  const [changeVisible, setChangeVisible] = useState(true);
  const WrapperGraph = styled.div`
    width: 100%;
    height: 37%;
    margin-top: -16px;
    @media (max-width: 1023px) {
      width: 100%;
      grid-column: 2 / 3;
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;
      grid-row: 1 / 4;
      height: 100%;
      margin: 0;
    }
    @media (max-width: 767px) {
      grid-row: 2 / 4;
      top: 25%;
      .recharts-tooltip-wrapper {
          right: 0;
          left: unset !important;
      }
    }
  `;
  const WrapperContentCard = styled.div`
    @media (max-width: 1023px) {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: -webkit-max-content 1fr;
      -ms-grid-columns: max-content 1fr;
      grid-template-columns: -webkit-max-content 1fr;
      grid-template-columns: max-content 1fr;
      -webkit-column-gap: 21%;
      -moz-column-gap: 21%;
      column-gap: 21%;
      grid-auto-rows: -webkit-min-content;
      grid-auto-rows: min-content;
      h2 {
        grid-column: 1 / 2;
      }
      
      ${WrapperFooterText} {
        grid-column: 1 / 2;
        margin-top: 0;
      }
      
      ${LinesContainer} {
        grid-column: 1 / 3;
        margin-top: 0;
        margin-bottom: 22px;
      }

      ${ContainerMainInfo} {
        grid-column: 1 / 2;
        margin-top: 14px;
        margin-bottom: 4px;
          
        ${ContainerWrapperIcon} {
          margin-top: -2px;
        }
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 215px 1fr;
      -webkit-column-gap: 5%;
      -moz-column-gap: 5%;
      column-gap: 5%;
      h2 {
        grid-column: 1 / 3;
      }

      ${WrapperFooterText} {
        margin-top: 8px;
        margin-bottom: 16px;
        font-size: 10px;
        line-height: 12px;
      }

      ${LinesContainer} {
        margin-top: -2px;
        margin-bottom: 0;
      }

      ${ContainerMainInfo} {
        margin-top: 17px;
        margin-bottom: 2px;
        p {
          font-size: 24px;
          line-height: 29px;
          white-space: nowrap;
        }

        ${ContainerWrapperIcon} {
          margin-top: 0;
        }
      }
    }
 `;
  useEffect(() => {
    if (mainInfoData.startWithGraphs) {
      setChangeVisible(false);
    }
  }, []);
  const [mainContainerElement, setMainContainerElement] = useState(null);

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      return (
        <DashboardTooltipGraphs>
          <p className="title">{payload[0].payload.created_at}</p>
          {mainInfoData.graphs.hugeGraph ? (
            <>
              {listDashboardData.map((item, index) => (
                <p className="text">{item.title}: {mainInfoData.graphs.mark ? new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(payload[index]?.value) : payload[index]?.value}</p>
              ))}
            </>
          ) : (
            <p className="text">{mainInfoData.graphs.textTooltip} {mainInfoData.graphs.mark ? new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(payload[0]?.value) : payload[0]?.value}</p>
          )}
        </DashboardTooltipGraphs>
      );
    }

    return null;
  }

  return (
    <DashboardCard width={mainInfoData.width} setMainContainerElement={setMainContainerElement}>
      <WrapperContentCard>
        <DashboardTopWrapper icon={changeVisible ? iconTitle : iconGraph} onClick={() => setChangeVisible(!changeVisible)}>
          <h2>{titleBoard}</h2>
        </DashboardTopWrapper>
        <DashboardMainInfo>
          <p>{mainInfoTitle}</p>
          <DashboardProgressPercent
            rotate={mainInfoData.rotate}
            iconBackground={mainInfoData.iconBackground}
            iconColor={mainInfoData.iconColor}
            topIcon
          >
            {mainInfoData.icon}
            <p>{mainInfoData.title === Infinity ? (<FontAwesomeIcon icon={faInfinity} />) : mainInfoData.title }{mainInfoData.mark ?? '%'}</p>
          </DashboardProgressPercent>
        </DashboardMainInfo>
        <DashboardWrapperFooterText>{footerText}</DashboardWrapperFooterText>
        {changeVisible ? (
          <ProgressBarLines>
            {progressBarData.map((line) => (
              <ProgressBarLine width={`${parseInt(line.value, 10)}%`} background={line.color} titleTooltip={line.title} refMain={mainContainerElement} />
            ))}
          </ProgressBarLines>
        ) : (
          <WrapperGraph>
            <ResponsiveContainer>
              <LineChart data={mainInfoData.graphs ? mainInfoData.graphs.data : []}>
                <Tooltip content={<CustomTooltip />} position={{ y: -15 }} />
                {mainInfoData.graphs ? mainInfoData.graphs.lineObjectName.map((line, index) => (
                  <Line
                    type="monotone"
                    dataKey={line}
                    dot={false}
                    stroke={mainInfoData.graphs.colorLine ? mainInfoData.graphs.colorLine[index] : progressBarData[index]?.color}
                    strokeWidth={1.5}
                  />
                ))
                  : null}
              </LineChart>
            </ResponsiveContainer>
          </WrapperGraph>
        )}
      </WrapperContentCard>
      <DashboardPointList listStyle={listDashboardDotVisible ? false : '0'}>
        {listDashboardData.map((item, index) => (
          <DashboardPointListItem color={progressBarData[index]?.color} listStyle={listDashboardDotVisible || 'none'}>
            <div>
              <p className="point-text">{item.title}</p>
              <div className="info">
                <span>{item.value}</span>
                <DashboardProgressPercent
                  rotate={item.rotate}
                  iconBackground={item.iconBackground}
                  iconColor={item.iconColor}
                >
                  {item.icon}
                  <p>{item.valueIcon === Infinity ? (<FontAwesomeIcon icon={faInfinity} />) : item.valueIcon}{item.mark ?? '%'}</p>
                </DashboardProgressPercent>
              </div>
            </div>
          </DashboardPointListItem>
        ))}
      </DashboardPointList>
    </DashboardCard>
  );
}
