import React, { Component } from 'react';
import classnames from 'classnames';
import LoadingSpinner from "../Layout/LoadingSpinner";
import Tippy from '@tippyjs/react';
import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import {
  customFetch,
  customSelectStyle,
  loadCRMOptionsLists,
  switchLanguage,
} from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

class ThemeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPickerMain: false,
      displayColorPickerSub: false,
      displayColorPickerRunningBackground: false,
      displayColorPickerRunningFont: false,
      displayColorPickerRunningIcon: false,
      displayColorPickerButtonFont: false,
      displayColorPickerMainFont: false,
      displayColorPickerNavigationFont: false,
      displayColorPickerFooterBackground: false,
      displayColorPickerFooterFont: false,
      sourceList: [],
      userList: [],
      theme: {
        fields: {
          id: '',
          domain: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'domain',
          },
          title: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'plain',
          },
          phone: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'disable',
          },
          email: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'email',
          },
          agentProlongationDayOffset: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'dayOffset',
          },
          clientProlongationDayOffset: {
            value: '',
            errorMessage: '',
            errorVisible: false,
            validationType: 'dayOffset',
          },
          banners_hide: {},
          faq_knowledge_hide: {},
          link_to_faq: '',
          link_to_telegram: '',
          homepage: '',
          callbackUrl: '',
          mainColor: '',
          mainFontColor: '',
          subColor: '',
          runningBackgroundColor: '',
          runningFontColor: '',
          runningIconColor: '',
          buttonFontColor: '',
          navigationFontColor: '',
          footerBackgroundColor: '',
          footerFontColor: '',
          bitrix_widget_active: false,
          show_full_time_employee_as_curator: false,
          bitrix_widget_custom_code: '',
          bitrix_source_id_for_sub_agents: '',
          default_crm_assigned: null,
          bitrix_source_id_for_agents: '',
        },
        files: {
          favicon: {
            name: '',
            file: null,
            downloaded: false,
            error: null,
            path: '',
          },
          logo: {
            name: '',
            file: null,
            downloaded: false,
            error: null,
            path: '',
          },
          logoNav: {
            name: '',
            file: null,
            downloaded: false,
            error: null,
            path: '',
          },
        },
      },
      faqKnowledge: [],
      editForm: false,
      uploading: false,
    };
  }

  componentDidMount() {
    document.title = 'Кастомизация';
    this.downloadData();
    this.downloadFaqKnowledge();
    this.getFullTimeEmployee();
    loadCRMOptionsLists(null, 'admin', ['source', 'user']).then((data) => {
      if (data !== 'empty integration' && data !== 'no integration') {
        const sources = data.sources.map((item) => ({ value: item.STATUS_ID, label: item.NAME }));
        this.setState({ sourceList: sources });
      }
    });
  }

  getFullTimeEmployee = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents/full_time_employee`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const users = response.map((item) => ({ value: item.id, label: `${item.last_name} ${item.first_name} ${item.middle_name}` }));
          this.setState({ userList: users.concat([{ value: null, label: 'Отсутствует' }]) });
        }
      });
  };

  downloadData = () => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { match } = this.props;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/themes/${match.params.id}`, {
      method: 'get',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const { theme } = this.state;
        Object.keys(response).forEach((field) => {
          if (field in theme.files) {
            if (response[field]) {
              theme.files[field].downloaded = true;
              theme.files[field].path = response[field];
            }
          } else if (field in theme.fields) {
            if (theme.fields[field] && theme.fields[field].validationType) {
              theme.fields[field].value = response[field];
            } else {
              theme.fields[field] = response[field];
            }
          }
        });
        this.setState({ theme });
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки данных');
      });
  };

  downloadFaqKnowledge = () => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          this.setState({ faqKnowledge: res });
        } else {
          showModalInfo(res.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки списка FAQ', 'error');
      });
  }

  handleValidate = (e, equalityField = null) => {
    const { theme } = this.state;
    let { value } = e.target;
    if (equalityField) {
      value = { [e.target.id]: e.target.value, [equalityField]: theme.fields[equalityField].value };
    }
    theme.fields[e.target.id].errorMessage = getValidation(value, theme.fields[e.target.id].validationType);
    if (theme.fields[e.target.id].errorMessage) {
      theme.fields[e.target.id].errorVisible = true;
    }
    this.setState({ theme });
  };

  handleChangeSelect = (e, actionMeta) => {
    this.setState((prevState) => ({
      ...prevState,
      theme: {
        ...prevState.theme,
        fields: {
          ...prevState.theme.fields,
          [actionMeta.name]: e.value,
        },
      },
    }));
  }

  handleChangeBanners = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      theme: {
        ...prevState.theme,
        fields: {
          ...prevState.theme.fields,
          banners_hide: e,
        },
      },
    }));
  };

  handleChangeFaqKnowledge = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      theme: {
        ...prevState.theme,
        fields: {
          ...prevState.theme.fields,
          faq_knowledge_hide: e,
        },
      },
    }));
  };

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { theme } = this.state;
    if (typeof theme.fields[e.target.id] === 'object' && typeof theme.fields[e.target.id]?.value !== 'undefined') {
      theme.fields[e.target.id].value = value;
      if (theme.fields[e.target.id].errorVisible) {
        theme.fields[e.target.id].errorVisible = false;
      }
    } else {
      theme.fields[e.target.id] = value;
    }
    this.setState({ theme });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    this.setState({ uploading: true });
    const validationSuccess = this.validateAllFields();
    if (validationSuccess) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const { theme } = this.state;
      const formData = new FormData();
      Object.keys(theme.files).forEach((file) => {
        if (theme.files[file].file) {
          formData.append(file, theme.files[file].file);
        } else {
          formData.append(file, theme.files[file].path);
        }
      });
      Object.keys(theme.fields).forEach((field) => {
        if (theme.fields[field] && theme.fields[field].validationType) {
          formData.append(field, theme.fields[field].value);
        } else if (field === 'banners_hide') {
          formData.append(field, JSON.stringify(theme.fields[field]));
        } else if (field === 'faq_knowledge_hide') {
          formData.append(field, JSON.stringify(theme.fields[field]));
        } else {
          formData.append(field, theme.fields[field]);
        }
      });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/themes/${theme.fields.id}`, {
        method: 'post',
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error);
          } else if (response.errors) {
            Object.keys(response.error).forEach((document) => {
              theme.files[document].error = response.error[document][0];
            });
            this.setState({ theme });
          } else {
            Object.keys(response).forEach((field) => {
              if (field in theme.files) {
                if (response[field]) {
                  theme.files[field].downloaded = true;
                  theme.files[field].error = null;
                  theme.files[field].path = response[field];
                }
              } else if (field in theme.fields) {
                if (theme.fields[field] && theme.fields[field].validationType) {
                  theme.fields[field].value = response[field];
                } else {
                  theme.fields[field] = response[field];
                }
              }
            });
            this.setState({ theme, editForm: false });
          }
        })
        .catch(() => {
          showModalInfo('Ошибка');
          this.setState({
            editForm: false,
          });
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    } else {
      this.setState({ uploading: false });
    }
  };

  validateAllFields = () => {
    const { theme } = this.state;
    let validationSuccess = true;
    Object.keys(theme.fields).forEach((field) => {
      if (theme.fields[field] && theme.fields[field].validationType) {
        const { value } = theme.fields[field];
        theme.fields[field].errorMessage = getValidation(value, theme.fields[field].validationType);
        if (theme.fields[field].errorMessage) {
          validationSuccess = false;
          theme.fields[field].errorVisible = true;
        }
      }
    });
    this.setState({ theme });
    return validationSuccess;
  };

  handleCancel = () => {
    this.downloadData();
    loadCRMOptionsLists(null, 'admin', ['source', 'user']).then((data) => {
      if (data !== 'empty integration' && data !== 'no integration') {
        const sources = data.sources.map((item) => ({ value: item.STATUS_ID, label: item.NAME }));
        const users = data.users.map((item) => ({ value: item.ID, label: `${item.NAME ? item.NAME : '-'} ${item.LAST_NAME ? item.LAST_NAME : '-'} (${item.EMAIL})` }));
        this.setState({
          sourceList: sources,
          userList: users.concat([{ value: null, label: 'Отсутствует' }]),
        });
      }
    });
    this.setState({ editForm: false });
  };

  handleEdit = () => {
    this.setState({ editForm: true });
  };

  handleAddDns = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { theme } = this.state;
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dns/add`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ domain: theme.fields.domain.value }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('DNS-запись добавлена');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  openColorPicker = (type) => {
    const typeName = `displayColorPicker${type}`;
    this.setState({ [typeName]: true });
  };

  closeColorPicker = () => {
    this.setState({
      displayColorPickerMain: false,
      displayColorPickerSub: false,
      displayColorPickerRunningBackground: false,
      displayColorPickerFooterBackground: false,
      displayColorPickerRunningFont: false,
      displayColorPickerRunningIcon: false,
      displayColorPickerButtonFont: false,
      displayColorPickerMainFont: false,
      displayColorPickerNavigationFont: false,
      displayColorPickerFooterFont: false,
    });
  };

  handleChangeColor = (color, type) => {
    const { theme } = this.state;
    this.setState({
      theme: {
        ...theme,
        fields: {
          ...theme.fields,
          [type]: color.hex,
        },
      },
    });
  };

  handleDeleteFile = (fileName) => {
    const { theme } = this.state;
    theme.files[fileName].name = '';
    theme.files[fileName].file = null;
    theme.files[fileName].downloaded = false;
    theme.files[fileName].error = null;
    theme.files[fileName].path = '';
    this.setState({ theme });
  };

  handleChangeFile = (e) => {
    const { theme } = this.state;
    if (e.target.files) {
      theme.files[e.target.name].name = e.target.files[0].name;
      theme.files[e.target.name].file = e.target.files[0];
      theme.files[e.target.name].downloaded = false;
    }
    this.setState({ theme });
  };

  handleDeleteTheme = () => {
    const { showModalInfo } = this.context;
    const { theme } = this.state;
    const { history } = this.props;
    this.setState({ uploading: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/themes/${theme.fields.id}`, {
      method: 'delete',
      headers: {
        Authorization: token,
      },
    })
      .then(() => {
        history.push('/admin?activeTab=9');
      })
      .catch(() => {
        showModalInfo('Ошибка');
        this.setState({ uploading: false });
      });
  };

  render() {
    const {
      theme,
      uploading,
      editForm,
      displayColorPickerMain,
      displayColorPickerSub,
      displayColorPickerRunningBackground,
      displayColorPickerFooterBackground,
      displayColorPickerRunningFont,
      displayColorPickerRunningIcon,
      displayColorPickerButtonFont,
      displayColorPickerMainFont,
      displayColorPickerNavigationFont,
      displayColorPickerFooterFont,
      sourceList,
      userList,
    } = this.state;
    const { history } = this.props;
    const { faqKnowledge } = this.state;
    const faqKnowledgeOptions = faqKnowledge && faqKnowledge.map((item) => (
      {
        value: item.id,
        label: item.question,
      }
    ));
    return (
      <>
        <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); history.push('/admin?activeTab=9'); }}>К списку кастомизаций</LinkBreadcrumbs>
        <h1 className="page_title mt-1">Кастомизация №{theme.fields.id}</h1>
        {editForm
          ? <h2>Редактирование</h2>
          : <button type="button" className="btn btn-success mb-3 mr-3" onClick={this.handleEdit}>Редактировать</button>}
        <button type="button" className="btn btn-success mb-3" onClick={this.handleAddDns}>Добавить DNS-запись</button>
        <form onSubmit={this.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <label htmlFor="domain">Домен</label>
                  <Tippy
                    content={theme.fields.domain.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.domain.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.domain.errorVisible })}
                      value={theme.fields.domain.value}
                      disabled={!editForm}
                      type="text"
                      id="domain"
                      name="domain"
                      placeholder="infull"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="title">Наименование</label>
                  <Tippy
                    content={theme.fields.title.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.title.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.title.errorVisible })}
                      value={theme.fields.title.value}
                      disabled={!editForm}
                      type="text"
                      id="title"
                      name="title"
                      placeholder="ООО Рога и Копыта"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="phone">Телефон</label>
                  <Tippy
                    content={theme.fields.phone.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.phone.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.phone.errorVisible })}
                      value={theme.fields.phone.value}
                      disabled={!editForm}
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="8-999-99-99"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="email">E-mail</label>
                  <Tippy
                    content={theme.fields.email.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.email.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.email.errorVisible })}
                      value={theme.fields.email.value}
                      disabled={!editForm}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <label htmlFor="email">Ссылка на телеграм</label>
                  <input
                    required
                    className="form-control mb-2"
                    value={theme.fields.link_to_telegram}
                    disabled={!editForm}
                    type="text"
                    id="link_to_telegram"
                    name="link_to_telegram"
                    placeholder=""
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="email">Ссылка на инструкцию</label>
                  <input
                    className="form-control mb-2"
                    value={theme.fields.link_to_faq}
                    disabled={!editForm}
                    type="text"
                    id="link_to_faq"
                    name="link_to_faq"
                    placeholder=""
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="email">Ссылка на сайт (указывать без https://)</label>
                  <input
                    className="form-control mb-2"
                    value={theme.fields.homepage}
                    disabled={!editForm}
                    type="text"
                    id="homepage"
                    name="homepage"
                    placeholder=""
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Основной цвет</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.mainColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('Main')} />
                  { displayColorPickerMain ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.mainColor} onChange={(color) => this.handleChangeColor(color, 'mainColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Второстепенный цвет</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.subColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('Sub')} />
                  { displayColorPickerSub ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.subColor} onChange={(color) => this.handleChangeColor(color, 'subColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Основной цвет шрифта</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.mainFontColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('MainFont')} />
                  { displayColorPickerMainFont ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.mainFontColor} onChange={(color) => this.handleChangeColor(color, 'mainFontColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет шрифта в кнопках</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.buttonFontColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('ButtonFont')} />
                  { displayColorPickerButtonFont ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.buttonFontColor} onChange={(color) => this.handleChangeColor(color, 'buttonFontColor')} />
                    </div>
                  ) : null }
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет шапки</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.runningBackgroundColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('RunningBackground')} />
                  { displayColorPickerRunningBackground ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.runningBackgroundColor} onChange={(color) => this.handleChangeColor(color, 'runningBackgroundColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет шрифта в шапке</span>
                  <div aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('RunningFont')} className="color-picker-button" style={{ backgroundColor: theme.fields.runningFontColor }} />
                  { displayColorPickerRunningFont ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.runningFontColor} onChange={(color) => this.handleChangeColor(color, 'runningFontColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет иконок в шапке</span>
                  <div aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('RunningIcon')} className="color-picker-button" style={{ backgroundColor: theme.fields.runningIconColor }} />
                  { displayColorPickerRunningIcon ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.runningIconColor} onChange={(color) => this.handleChangeColor(color, 'runningIconColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет навигационных кнопок</span>
                  <div aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('NavigationFont')} className="color-picker-button" style={{ backgroundColor: theme.fields.navigationFontColor }} />
                  { displayColorPickerNavigationFont ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.navigationFontColor} onChange={(color) => this.handleChangeColor(color, 'navigationFontColor')} />
                    </div>
                  ) : null }
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет подвала</span>
                  <div className="color-picker-button" style={{ backgroundColor: theme.fields.footerBackgroundColor }} aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('FooterBackground')} />
                  { displayColorPickerFooterBackground ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.footerBackgroundColor} onChange={(color) => this.handleChangeColor(color, 'footerBackgroundColor')} />
                    </div>
                  ) : null }
                </div>
                <div className="form-group col-lg-3">
                  <span className="color-picker-label">Цвет шрифта в подвале</span>
                  <div aria-disabled={!editForm} onClick={() => editForm && this.openColorPicker('FooterFont')} className="color-picker-button" style={{ backgroundColor: theme.fields.footerFontColor }} />
                  { displayColorPickerFooterFont ? (
                    <div className="color-picker-popover">
                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                      <ChromePicker disableAlpha color={theme.fields.footerFontColor} onChange={(color) => this.handleChangeColor(color, 'footerFontColor')} />
                    </div>
                  ) : null }
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <label className="d-block" htmlFor="favicon">Favicon</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{theme.files.favicon.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : theme.files.favicon.name}</div>
                    {(theme.files.favicon.path || theme.files.favicon.file)
                      ? (<div aria-disabled={!editForm} onClick={() => editForm && this.handleDeleteFile('favicon')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={!editForm} onChange={this.handleChangeFile} type="file" name="favicon" className="custom-input-file" />
                  </div>
                  {theme.files.favicon.path && (
                    <div className="jq-file__download">
                      <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.files.favicon.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {theme.files.favicon.error && (
                    <div className="mt-2 error_block">{theme.files.favicon.error}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <label className="d-block" htmlFor="favicon">Основной логотип</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{theme.files.logo.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : theme.files.logo.name}</div>
                    {(theme.files.logo.path || theme.files.logo.file)
                      ? (<div aria-disabled={!editForm} onClick={() => editForm && this.handleDeleteFile('logo')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={!editForm} onChange={this.handleChangeFile} type="file" name="logo" className="custom-input-file" />
                  </div>
                  {theme.files.logo.path && (
                    <div className="jq-file__download">
                      <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.files.logo.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {theme.files.logo.error && (
                    <div className="mt-2 error_block">{theme.files.logo.error}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <label className="d-block" htmlFor="favicon">Логотип в шапке</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{theme.files.logoNav.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : theme.files.logoNav.name}</div>
                    {(theme.files.logoNav.path || theme.files.logoNav.file)
                      ? (<div aria-disabled={!editForm} onClick={() => editForm && this.handleDeleteFile('logoNav')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={!editForm} onChange={this.handleChangeFile} type="file" name="logoNav" className="custom-input-file" />
                  </div>
                  {theme.files.logoNav.path && (
                    <div className="jq-file__download">
                      <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.files.logoNav.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {theme.files.logoNav.error && (
                    <div className="mt-2 error_block">{theme.files.logoNav.error}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <label htmlFor="callBackUrl">url переадресации</label>
                  <input
                    className="form-control mb-2"
                    value={theme.fields.callbackUrl}
                    disabled={!editForm}
                    type="text"
                    id="callbackUrl"
                    name="callbackUrl"
                    placeholder="url переадресации после покупки с виджета"
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <p>За какое количество дней высылать письма с информацией о пролонгации полиса:</p>
                </div>
                <div className="form-group col-lg-2">
                  <label htmlFor="agentProlongationDayOffset">Для пользователя</label>
                  <Tippy
                    content={theme.fields.agentProlongationDayOffset.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.agentProlongationDayOffset.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.agentProlongationDayOffset.errorVisible })}
                      value={theme.fields.agentProlongationDayOffset.value}
                      disabled={!editForm}
                      type="number"
                      id="agentProlongationDayOffset"
                      name="agentProlongationDayOffset"
                      placeholder="10"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
                <div className="form-group col-lg-2">
                  <label htmlFor="clientProlongationDayOffset">Для клиента</label>
                  <Tippy
                    content={theme.fields.clientProlongationDayOffset.errorMessage}
                    arrow
                    placement="bottom"
                    visible={!!theme.fields.clientProlongationDayOffset.errorVisible}
                    hideOnClick={false}
                    theme="translucent"
                  >
                    <input
                      className={classnames('form-control mb-2', { error: theme.fields.clientProlongationDayOffset.errorVisible })}
                      value={theme.fields.clientProlongationDayOffset.value}
                      disabled={!editForm}
                      type="number"
                      id="clientProlongationDayOffset"
                      name="clientProlongationDayOffset"
                      placeholder="7"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                  </Tippy>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label className="form-check-label" htmlFor="banners_hide">Скрыть баннеры</label>
                  <Select
                    isDisabled={!editForm}
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name="banners_hide"
                    isSearchable={false}
                    placeholder="Выберите номер баннера"
                    id="banners_hide"
                    isMulti
                    value={theme.fields.banners_hide}
                    onChange={this.handleChangeBanners}
                    options={[
                      { value: 0, label: '1' },
                      { value: 1, label: '2' },
                      { value: 2, label: '3' },
                      { value: 3, label: '4' },
                      { value: 4, label: '5' },
                    ]}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="form-check-label" htmlFor="faq_knowledge_hide">Скрыть FAQ-контент</label>
                  <Select
                    isDisabled={!editForm}
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name="faq_knowledge_hide"
                    isSearchable={false}
                    placeholder="Выберите контент"
                    id="faq_knowledge_hide"
                    isMulti
                    value={theme.fields.faq_knowledge_hide}
                    onChange={this.handleChangeFaqKnowledge}
                    options={faqKnowledgeOptions}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="form-check custom-checkbox">
                    <input
                      onChange={this.handleChangeInput}
                      checked={theme.fields.show_full_time_employee_as_curator ? theme.fields.show_full_time_employee_as_curator : false}
                      className="form-check-input checkbox-styled"
                      type="checkbox"
                      name="show_full_time_employee_as_curator"
                      id="show_full_time_employee_as_curator"
                      disabled={!editForm}
                    />
                    <label className="form-check-label" htmlFor="show_full_time_employee_as_curator">
                      Отображать штатного куратора
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="form-check custom-checkbox">
                    <input
                      onChange={this.handleChangeInput}
                      checked={theme.fields.bitrix_widget_active ? theme.fields.bitrix_widget_active : false}
                      className="form-check-input checkbox-styled"
                      type="checkbox"
                      name="bitrix_widget_active"
                      id="bitrix_widget_active"
                      disabled={!editForm}
                    />
                    <label className="form-check-label" htmlFor="bitrix_widget_active">
                      Включить виджет
                    </label>
                  </div>
                </div>
                <div className="col-lg-8">
                  <label htmlFor="bitrix_widget_custom_code">Кастомный код виджета партнера</label>
                  <input
                    className={classnames('form-control mb-2')}
                    value={theme.fields.bitrix_widget_custom_code}
                    disabled={!editForm}
                    type="text"
                    id="bitrix_widget_custom_code"
                    name="bitrix_widget_custom_code"
                    placeholder="Код"
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label htmlFor="bitrix_source_id_for_agents">ID источника пользователей в CRM</label>
                  <Select
                    isDisabled={!editForm}
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name="bitrix_source_id_for_agents"
                    placeholder="Выберите источник пользователей"
                    id="bitrix_source_id_for_agents"
                    value={sourceList.filter(({ value }) => value === theme.fields.bitrix_source_id_for_agents)}
                    onChange={this.handleChangeSelect}
                    options={sourceList}
                  />
                </div>
                <div className="col-lg-4">
                  <label htmlFor="bitrix_source_id_for_sub_agents">ID источника субпользователей в CRM</label>
                  <Select
                    isDisabled={!editForm}
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name="bitrix_source_id_for_sub_agents"
                    placeholder="Выберите источник субпользователей"
                    id="bitrix_source_id_for_sub_agents"
                    value={sourceList.filter(({ value }) => value === theme.fields.bitrix_source_id_for_sub_agents)}
                    onChange={this.handleChangeSelect}
                    options={sourceList}
                  />
                </div>
                <div className="col-lg-4">
                  <label htmlFor="default_crm_assigned">Ответственный в CRM</label>
                  <Select
                    isDisabled={!editForm}
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    name="default_crm_assigned"
                    placeholder="Выберите куратора"
                    id="default_crm_assigned"
                    value={userList.filter(({ value }) => (theme.fields.default_crm_assigned ? parseInt(value, 10) === theme.fields.default_crm_assigned : value === theme.fields.default_crm_assigned))}
                    onChange={this.handleChangeSelect}
                    options={userList}
                  />
                </div>
              </div>
            </div>
          </div>
          {editForm && (
            <div className="form-row mt-3">
              <div className="form-group col-md-9">
                <button disabled={uploading} type="submit" className="btn btn-success mr-2">Сохранить</button>
                <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.handleCancel}>Отмена</button>
                {uploading && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
                <button disabled={uploading} type="button" className="btn btn-danger float-right" onClick={this.handleDeleteTheme}>Удалить</button>
              </div>
            </div>
          )}
        </form>
      </>
    );
  }
}

ThemeForm.contextType = ModalMessagesContext;

export default ThemeForm;
