import React, { useState, useRef, useContext } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import CustomIcon from '../CustomIcon';
import BalancePopUp from './BalancePopUp';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

const Wrapper = styled.div`
  position: relative;
  margin: 0 8px 0 0;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility .15s ease, opacity .15s ease;
  transition: visibility .15s ease, opacity .15s ease;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Button = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .15s ease;
  cursor: pointer;

  &:focus, &:hover{
    background-color: #cfcfcf;
  }

  ${(props) => {
    if (!props.$activeFlag) {
      return '';
    }
    return props.theme ? `background-color: ${props.theme.subColor} !important;` : 'background-color: #f0f000 !important;';
  }}
`;

const Icon = styled.div`
  color: #3d3d3b;
  line-height: 1;
  cursor: pointer;
`;

const InfoDataTemplate = {
  income: {
    name: 'ПРИБЫЛЬ',
    sum: 0,
    diff: 0,
    diffUp: false,
    type: 'rub',
  },
  count: {
    name: 'КОЛ-ВО ПРОДАЖ',
    sum: 0,
    diff: 0,
    diffUp: true,
    type: 'шт',
  },
  commission: {
    name: 'ОБЩАЯ СП',
    sum: 0,
    diff: 0,
    diffUp: true,
    type: 'rub',
  },
};

export default function NavbarUserBalance(props) {
  const { userInfo, theme } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      theme: state.theme,
    }),
    shallow,
  );

  const [activeFlag, setActiveFlag] = useState(false);
  const [infoData, setInfoData] = useState({ ...InfoDataTemplate });
  const [userBalance, setUserBalance] = useState(0);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const tippyRef = useRef(null);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const loadData = async () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingFlag(true);
    let balanceData = await customFetch(`${process.env.REACT_APP_API_DOMAIN}/user-balance-info/${userInfo.id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    });

    balanceData = await balanceData.json();
    try {
      if (!balanceData.error) {
        setInfoData((prev) => {
          const newBalance = { ...prev };
          newBalance.count.sum = balanceData.policesCount.sum;
          newBalance.count.diff = balanceData.policesCount.diff;
          newBalance.count.diffUp = balanceData.policesCount.diffUp;

          newBalance.income.sum = balanceData.profit.sum;
          newBalance.income.diff = balanceData.profit.diff;
          newBalance.income.diffUp = balanceData.profit.diffUp;

          newBalance.commission.sum = balanceData.premium.sum;
          newBalance.commission.diff = balanceData.premium.diff;
          newBalance.commission.diffUp = balanceData.premium.diffUp;

          return newBalance;
        });
        setUserBalance(balanceData.balance);
      } else {
        showModalInfo(balanceData.error);
      }
    } catch (error) {
      showModalInfo('Ошибка при получении информации о балансе');
    } finally {
      setLoadingFlag(false);
    }
  };

  const closeTippy = () => {
    tippyRef.current.click();
  };

  return (
    <Tippy
      placement="bottom-start"
      interactive
      trigger="click"
      className="page-popup page-popup--agents-list page-popup_auto-width"
      arrow={false}
      offset={[0, 12]}
      ref={tippyRef}
      content={(
        <BalancePopUp infoData={infoData} theme={theme} userBalance={userBalance} loadingFlag={loadingFlag} closeTippy={closeTippy} />
      )}
      hideOnClick
      onShown={() => setActiveFlag(true)}
      onShow={loadData}
      onHidden={() => setActiveFlag(false)}
    >
      <Wrapper className="tour_2_dt_step_3_open" data-sleep="500">
        <Button theme={theme} $activeFlag={activeFlag}>
          <Icon>
            <CustomIcon icon="ruble" height="20px" width="20px" />
          </Icon>
        </Button>
      </Wrapper>
    </Tippy>
  );
}
