import React, {
  useMemo, useState, useEffect, useContext, useRef,
} from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
  faStar,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/fontawesome-free-solid';
import { Element } from 'react-scroll';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Tippy from '@tippyjs/react';
import CurrencyInput from 'react-currency-input-field';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import {
  customFetch,
  sortAllPolicesArray,
  handleChange,
  customSelectStyle,
  validateField,
  toggleCompanyCommercialOffer,
  toggleAllCompaniesCommercialOffer,
} from '../../Utils/Helpers';
import CommercialOffer from '../CommercialOffer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import getMask from '../../Utils/Masks';
import CargoOptionsForm from './CargoOptionsForm';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function CargoShortForm(props) {
  const {
    disableField,
    formData,
    setFormData,
    saveDraft,
    handleValidate,
    blurClass,
    companies,
    goToFullForm,
    draftInfo,
    goCalcFlag,
    setGoCalcFlag,
    setBlurClass,
    showForm,
    backToForm,
    setLoadingInit,
    loadingInit,
    selectedSort,
    setSelectedSort,
    policySortOrderUp,
    setPolicySortOrderUp,
    policyIntegrationsData,
    setPolicyIntegrationsData,
    allPolices,
    setAllPolices,
    showCommission,
    setShowCommission,
    commercialOfferCompanies,
    setCommercialOfferCompanies,
    setShowCommercialOfferModalForm,
    showCommercialOfferModalForm,
    commercialOfferRef,
    setLoadingCommercialOffer,
    setScrollTo,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  let abortControllerCalculate = new AbortController();
  let abortControllerCalculationStatus = new AbortController();
  const defaultSelectValue = { value: '', label: '' };
  const intervalId = useRef(null);
  const [loadingResults, setLoadingResults] = useState(false);
  const [gonnaCalc, setGonnaCalc] = useState(0);
  const [calced, setCalced] = useState(0);
  const [typeThings, setTypeThings] = useState([defaultSelectValue]);
  const [typeFranchise, setTypeFranchise] = useState([defaultSelectValue]);
  const [typeValutes, setTypeValutes] = useState([defaultSelectValue]);
  const [typeTransports, setTypeTransports] = useState([defaultSelectValue]);
  const [checkingCalc, setCheckingCalc] = useState(0);
  const [discountPercentLoadingFlag, setDiscountPercentLoadingFlag] = useState({
    gelios: false,
    pari: false,
  });
  const [discountPercent, setDiscountPercent] = useState({
    gelios: {
      value: 0,
      errorMessage: '',
      validationRequired: true,
      validationType: 'discountPercent',
    },
    pari: {
      value: 0,
      errorMessage: '',
      validationRequired: true,
      validationType: 'discountPercent',
    },
  });
  const [allowancePercent, setAllowancePercent] = useState({
    gelios: {
      value: 0,
      errorMessage: '',
      validationRequired: true,
      validationType: 'allowancePercent',
    },
    pari: {
      value: 0,
      errorMessage: '',
      validationRequired: true,
      validationType: 'allowancePercent',
    },
  });
  const [adjustmentType, setAdjustmentType] = useState({
    gelios: {
      value: { label: 'Надбавка', value: 'allowance' },
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    pari: {
      value: { label: 'Надбавка', value: 'allowance' },
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
  });
  const makeAllPolicesArray = (product) => {
    const allPolicesArray = [];

    policyIntegrationsData[product].forEach((policyData) => {
      if ((policyData.price || policyData.errorMessage || policyData.errorCriticalMessage) && Object.prototype.hasOwnProperty.call(companies, policyData.polis_online_code)) {
        allPolicesArray.push({
          policyData,
          name: policyData.name,
          insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type])
          || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type])
          || (companies[policyData.polis_online_code].presentation && companies[policyData.polis_online_code].presentation[draftInfo.type]) ? 'close' : 'no',
          integration: policyData.integration,
          price: policyData.price ? parseFloat(policyData.price) : 0,
          commission: policyData.agentCommission ? policyData.agentCommission : 0,
          commissionCurrency: parseFloat((policyData.agentCommission * parseFloat(policyData.price)) / 100),
          errorMessage: policyData.errorMessage ? policyData.errorMessage : '',
          errorCriticalMessage: policyData.errorCriticalMessage ? policyData.errorCriticalMessage : '',
          integrationCompanyCode: policyData.polis_online_code,
          risks: policyData.risks,
          insuranceLimit: policyData.insuranceLimit,
        });
        if (policyData.upSales && policyData.upSales.length) {
          policyData.upSales.forEach((item) => {
            allPolicesArray.push({
              policyData,
              name: item.name,
              insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type])
              || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type])
              || (companies[policyData.polis_online_code].presentation && companies[policyData.polis_online_code].presentation[draftInfo.type]) ? 'close' : 'no',
              integration: policyData.integration,
              price: parseFloat(item.price),
              commission: item.commission,
              commissionCurrency: parseFloat((item.commission * item.price) / 100),
              integrationCompanyCode: policyData.polis_online_code,
              risks: item.risks,
              insuranceLimit: item.insuranceLimit,
            });
          });
        }
      }
      return false;
    });
    return allPolicesArray;
  };

  useEffect(() => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/cargo_insurance`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json()).then((response) => {
        setTypeThings(response.cargo);
        setTypeFranchise(response.franchise);
        setTypeTransports(response.transport);
        setTypeValutes(response.valute);
      }).catch((ex) => console.log(ex.messages));
  }, []);

  const changeSort = (e, newSort) => {
    e.preventDefault();
    if (selectedSort === newSort) {
      const newAllPolices = { ...allPolices };
      const direction = policySortOrderUp ? 'down' : 'up';
      const newPolices = sortAllPolicesArray(newAllPolices.cargoInsurance, selectedSort, direction, policySortOrderUp);
      setAllPolices({
        cargoInsurance: newPolices,
      });
      setPolicySortOrderUp((prev) => !prev);
    } else {
      const direction = newSort === 'price' ? 'up' : 'down';
      const newAllPolices = { ...allPolices };
      const newPolices = sortAllPolicesArray(newAllPolices.cargoInsurance, newSort, direction, policySortOrderUp);
      setAllPolices({
        cargoInsurance: newPolices,
      });
      setSelectedSort(newSort);
      setPolicySortOrderUp(newSort === 'price');
    }
  };

  useEffect(() => {
    abortControllerCalculationStatus.abort();
    abortControllerCalculationStatus = new AbortController();
    if (calced && checkingCalc) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      let intervalCount = 0;
      const checkInterval = setInterval(() => {
        if (intervalCount === 100 || !calced || !checkingCalc) {
          setLoadingResults(false);
          clearInterval(intervalId.current);
          clearInterval(checkInterval);
        } else {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation-status?draftHash=${draftInfo.hash}`, {
            signal: abortControllerCalculationStatus.signal,
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
          }).then((response) => response.json())
            .then((response) => {
              if (!response.error) {
                if (response.resultStatus === 'changed') {
                  const newPolicesData = { ...policyIntegrationsData };
                  Object.keys(newPolicesData).forEach((type) => {
                    if (response.result[type]) {
                      newPolicesData[type] = response.result[type];
                    }
                  });
                  setPolicyIntegrationsData(newPolicesData);
                }
                if (response.calcStatus === 'complete') {
                  setCheckingCalc(0);
                  setCalced(0);
                  const newPolicesData = { ...policyIntegrationsData };
                  Object.keys(newPolicesData).forEach((type) => {
                    if (response.result) {
                      newPolicesData[type] = response.result;
                    }
                  });
                  setPolicyIntegrationsData(newPolicesData);
                  setLoadingResults(false);
                  clearInterval(checkInterval);
                }
              }
            })
            .catch(() => {
              setCheckingCalc(0);
              setCalced(0);
              setLoadingResults(false);
              clearInterval(checkInterval);
              showModalInfo('Ошибка', 'error');
            });
          intervalCount += 5;
        }
      }, 5000);
      intervalId.current = checkInterval;
    }

    return function cleanup() {
      clearInterval(intervalId.current);
      abortControllerCalculationStatus.abort();
    };
  }, [calced, checkingCalc]);

  const checkCalc = () => {
    setCheckingCalc(1);
  };

  useEffect(() => () => {
    if (!blurClass) {
      setLoadingResults(false);
      clearInterval(intervalId.current);
      abortControllerCalculationStatus.abort();
      abortControllerCalculate.abort();
    }
  }, [blurClass]);

  useEffect(() => {
    abortControllerCalculate.abort();
    abortControllerCalculate = new AbortController();
    if (gonnaCalc) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      setScrollTo('searchResults');
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
        signal: abortControllerCalculate.signal,
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          calcType: 'short',
          draftHash: draftInfo.hash,
          useEvents: false,
        }),
      })
        .then(() => {
          setGonnaCalc(0);
          setCalced(1);
          checkCalc();
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
          setGonnaCalc(0);
        });
    }

    return function cleanup() {
      abortControllerCalculate.abort();
    };
  }, [gonnaCalc]);

  const calculate = () => {
    setCalced(0);
    setGonnaCalc(1);
  };

  const stopCalculation = () => {
    setLoadingResults(false);
  };

  const makeCalculation = () => {
    setBlurClass('');
    setLoadingInit(true);
    setLoadingResults(true);
    setAllPolices({
      cargoInsurance: [],
    });
    setPolicyIntegrationsData({
      cargoInsurance: [],
    });
    calculate();
  };

  const toggleInsuranceInfoFlag = (e, targetOffer) => {
    e.preventDefault();
    const newPolicyArr = [...allPolices[draftInfo.type]];
    const offerIndex = newPolicyArr.findIndex((offer) => offer.integrationCompanyCode === targetOffer.integrationCompanyCode);
    newPolicyArr[offerIndex].insuranceInfoFlag = newPolicyArr[offerIndex].insuranceInfoFlag === 'close' ? 'open' : 'close';
    setAllPolices((prev) => ({
      ...prev,
      [draftInfo.type]: newPolicyArr,
    }));
  };

  useEffect(() => {
    if (draftInfo.hash && goCalcFlag) {
      makeCalculation();
      setGoCalcFlag(false);
    }
  }, [draftInfo.hash, goCalcFlag]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults))) {
      let allPolicyDataArr = makeAllPolicesArray('cargoInsurance');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp);
      setAllPolices((prev) => ({
        ...prev,
        cargoInsurance: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults]);

  useEffect(() => {
    if (draftInfo.id && loadingInit) {
      setCommercialOfferCompanies([]);
      saveDraft(undefined);
    }
  }, [draftInfo.type]);

  const calculateDiscount = async (insurance, calcType) => {
    abortControllerCalculate.abort();
    abortControllerCalculate = new AbortController();
    setDiscountPercentLoadingFlag((prev) => ({
      ...prev,
      [insurance]: true,
    }));
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let adjustmentPercent;
    if (calcType === 'discount') {
      adjustmentPercent = discountPercent[insurance].value;
    } else {
      adjustmentPercent = allowancePercent[insurance].value;
    }
    await customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      method: 'post',
      signal: abortControllerCalculate.signal,
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        calcType,
        draftHash: draftInfo.hash,
        insurance,
        adjustmentPercent,
      }),
    }).then((response) => response.json())
      .then((response) => {
        if (response.price) {
          setAllPolices((prev) => ({
            ...prev,
            [draftInfo.type]: allPolices[draftInfo.type].map((item) => (item.policyData.polis_online_code === insurance ? {
              ...item,
              price: response.price,
              policyData: {
                ...item.policyData,
                price: response.price,
              },
            } : item)),
          }));
        } else if (response.error) {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при применении скидки', 'error');
      })
      .finally(() => {
        setDiscountPercentLoadingFlag((prev) => ({
          ...prev,
          [insurance]: false,
        }));
      });
  };

  const allPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];

    return policesList.filter((offer) => offer.price && !offer.errorMessage && !offer.errorCriticalMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row', { info: offer.insuranceInfoFlag === 'open', 'has-info': offer.insuranceInfoFlag !== 'no' })}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>
                {new Intl.NumberFormat('ru-RU').format(offer.price)} {formData.valute.value.label}
              </div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>
                  <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU').format(((offer.commission * offer.price) / 100).toFixed(2))}  {formData.valute.value.label}</div>
                  <div className="search_results__commission--percentage">{offer.commission}%</div>
                </div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div className="d-flex flex-column">
              <div className="mb-1">Договор успешно согласован!</div>
              {allowancePercent[offer.policyData.polis_online_code] ? (
                <Tippy
                  placement="bottom"
                  className="tippy-custom"
                  arrow={false}
                  offset={[0, 5]}
                  content={(
                    <div>Допустимая надбавка которая предоставляется страховой компанией 100%</div>
                    )}
                  hideOnClick={false}
                >
                  <div className="discount_plate">
                    <div className="mr-1 d-flex">
                      {adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? (
                        <>
                          <InputMask
                            placeholder="Скидка %"
                            maskChar=""
                            onBlur={() => validateField(offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                            disabled={disableField}
                            onChange={(e) => handleChange(e, offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                            id="discountPercent"
                            name="discountPercent"
                            className={classnames('form-control', { error: discountPercent[offer.policyData.polis_online_code].errorMessage })}
                            value={discountPercent[offer.policyData.polis_online_code].value}
                            mask={getMask('discountPercent').mask}
                            formatChars={getMask('discountPercent').formatChars}
                          />
                          {discountPercent[offer.policyData.polis_online_code].errorMessage && (
                          <div className="validation-error">
                            {discountPercent[offer.policyData.polis_online_code].errorMessage}
                          </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="percent-after-select">
                            <InputMask
                              placeholder=""
                              maskChar=""
                              onBlur={() => validateField(offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                              disabled={disableField}
                              onChange={(e) => handleChange(e, offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                              id="allowancePercent"
                              name="allowancePercent"
                              className={classnames('form-control', { error: allowancePercent[offer.policyData.polis_online_code].errorMessage })}
                              value={allowancePercent[offer.policyData.polis_online_code].value}
                              mask={getMask('bloodPressure').mask}
                              formatChars={getMask('bloodPressure').formatChars}
                            />
                          </div>
                          {allowancePercent[offer.policyData.polis_online_code].errorMessage && (
                            <div className="validation-error">
                              {allowancePercent[offer.policyData.polis_online_code].errorMessage}
                            </div>
                          )}
                        </>
                      )}
                      <Select
                        menuPlacement="top"
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        name="adjustmentType"
                        isDisabled={disableField}
                        noOptionsMessage={() => 'Не найдено'}
                        inputId="adjustmentType"
                        onBlur={() => validateField(offer.policyData.polis_online_code, adjustmentType, setAdjustmentType)}
                        value={adjustmentType[offer.policyData.polis_online_code].value}
                        onChange={(e) => {
                          setAllowancePercent((prev) => ({
                            ...prev,
                            [offer.policyData.polis_online_code]: {
                              ...prev[offer.policyData.polis_online_code],
                              value: 0,
                              errorMessage: '',
                            },
                          }));
                          handleChange(e, offer.policyData.polis_online_code, adjustmentType, setAdjustmentType);
                        }}
                        options={[{ label: 'Надбавка', value: 'allowance' }]}
                      />
                    </div>
                    <button
                      disabled={discountPercent[offer.policyData.polis_online_code].errorMessage || allowancePercent[offer.policyData.polis_online_code].errorMessage}
                      type="button"
                      onClick={() => calculateDiscount(offer.policyData.polis_online_code, adjustmentType[offer.policyData.polis_online_code].value.value)}
                      className={classnames('btn search_results__table--button discount_button', { disabled: blurClass || discountPercent[offer.policyData.polis_online_code].errorMessage })}
                    >
                      Применить
                    </button>
                  </div>
                </Tippy>
              ) : null}
            </div>
          </div>
          <div className="search_results__table--cell">
            <div>
              <button
                type="button"
                onClick={(e) => goToFullForm(e, offer.policyData.polis_online_code, offer.price, offer.agreement, companies[offer.policyData.polis_online_code].name, offer.integration, offer.insuranceLimit, offer.risks)}
                className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
              >
                Оформить
              </button>
              <button
                type="button"
                onClick={(e) => toggleCompanyCommercialOffer(e, offer.policyData.polis_online_code, commercialOfferCompanies, setCommercialOfferCompanies, 'cargoInsurance', draftInfo.id, showModalInfo)}
                className={classnames('btn btn-secondary search_results__table--button btn-commercial-offer', {
                  disabled: blurClass.length > 0,
                  active: commercialOfferCompanies.findIndex((company) => company.companyCode === offer.policyData.polis_online_code) !== -1,
                })}
              >
                {commercialOfferCompanies.findIndex((company) => company.companyCode === offer.policyData.polis_online_code) !== -1 ? (<><strong>-</strong> из предложения</>) : (<><strong>+</strong> в предложение</>)}
              </button>
            </div>
            <div className="insurance-info-toggle" onClick={(e) => toggleInsuranceInfoFlag(e, offer)}>
              {offer.insuranceInfoFlag === 'open' ? (
                <div className="insurance-info-toggle__button">
                  <span>Скрыть подробности</span>
                  <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
                </div>
              ) : null}
              {(offer.insuranceInfoFlag === 'close') ? (
                <div className="insurance-info-toggle__button">
                  <span>Подробности о полисе</span>
                  <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {offer.insuranceInfoFlag !== 'no' ? (
          <div className={classnames('insurance-info d-flex flex-row justify-content-end')}>
            {companies[offer.policyData.polis_online_code].policy_template && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].policy_template[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>{offer.policyData.polis_online_code === 'pari' ? 'Шаблон полиса Все риски' : 'Пример полис'}</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].rules && companies[offer.policyData.polis_online_code].rules[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].rules[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>{offer.policyData.polis_online_code === 'pari' ? 'Шаблон полиса риски крушения' : 'Правила страхования'}</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].presentation && companies[offer.policyData.polis_online_code].presentation[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].presentation[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Презентация</span>
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    ));
  }, [
    allPolices.cargoInsurance,
    draftInfo.type,
    allowancePercent,
    setAllowancePercent,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
    commercialOfferCompanies.length]);

  const errorPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }

    return allPolices[draftInfo.type].filter((offer) => offer.errorMessage || offer.errorCriticalMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row')}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img
                src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`}
                alt="logo"
                className="search_results__logo"
              />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>{offer.price ? new Intl.NumberFormat('ru-RU').format(offer.price) : '-'} {offer.price ? formData.valute.value.label : ''}</div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>
                  <div className="search_results__commission--currency">{offer.price ? new Intl.NumberFormat('ru-RU').format(((offer.commission * offer.price) / 100).toFixed(2)) : '-'} {offer.price ? formData.valute.value.label : ''}</div>
                  <div className="search_results__commission--percentage">{offer.price ? `${offer.commission}%` : ''}</div>
                </div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => (
                    <FontAwesomeIcon
                      key={i}
                      icon={faStar}
                      className="fa-fw"
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div>
              {offer.errorCriticalMessage ? (
                <div className="text-danger mb-1">{offer.errorCriticalMessage}</div>
              ) : (
                <div className="text-danger mb-1">{offer.errorMessage}{formData.valute.value.value !== 'ruble' ? ' .Вознаграждение будет начислено в рублях по курсу ЦБ на момент оплаты полиса' : null}
                  {allowancePercent[offer.policyData.polis_online_code] && offer.price ? (
                    <Tippy
                      placement="bottom"
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={(
                        <div>Допустимая надбавка которая предоставляется страховой компанией 100%</div>
                        )}
                      hideOnClick={false}
                    >
                      <div className="discount_plate">
                        <div className="mr-1 d-flex">
                          {adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? (
                            <>
                              <InputMask
                                placeholder="Скидка %"
                                maskChar=""
                                onBlur={() => validateField(offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                                disabled={disableField}
                                onChange={(e) => handleChange(e, offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                                id="discountPercent"
                                name="discountPercent"
                                className={classnames('form-control', { error: discountPercent[offer.policyData.polis_online_code].errorMessage })}
                                value={discountPercent[offer.policyData.polis_online_code].value}
                                mask={getMask('discountPercent').mask}
                                formatChars={getMask('discountPercent').formatChars}
                              />
                              {discountPercent[offer.policyData.polis_online_code].errorMessage && (
                                <div className="validation-error">
                                  {discountPercent[offer.policyData.polis_online_code].errorMessage}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="percent-after-select">
                                <InputMask
                                  placeholder=""
                                  maskChar=""
                                  onBlur={() => validateField(offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                                  disabled={disableField}
                                  onChange={(e) => handleChange(e, offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                                  id="allowancePercent"
                                  name="allowancePercent"
                                  className={classnames('form-control', { error: allowancePercent[offer.policyData.polis_online_code].errorMessage })}
                                  value={allowancePercent[offer.policyData.polis_online_code].value}
                                  mask={getMask('bloodPressure').mask}
                                  formatChars={getMask('bloodPressure').formatChars}
                                />
                              </div>
                              {allowancePercent[offer.policyData.polis_online_code].errorMessage && (
                                <div className="validation-error">
                                  {allowancePercent[offer.policyData.polis_online_code].errorMessage}
                                </div>
                              )}
                            </>
                          )}
                          <Select
                            menuPlacement="top"
                            classNamePrefix="react-select"
                            styles={customSelectStyle()}
                            name="adjustmentType"
                            isDisabled={disableField}
                            noOptionsMessage={() => 'Не найдено'}
                            inputId="adjustmentType"
                            onBlur={() => validateField(offer.policyData.polis_online_code, adjustmentType, setAdjustmentType)}
                            value={adjustmentType[offer.policyData.polis_online_code].value}
                            onChange={(e) => {
                              setAllowancePercent((prev) => ({
                                ...prev,
                                [offer.policyData.polis_online_code]: {
                                  ...prev[offer.policyData.polis_online_code],
                                  value: 0,
                                  errorMessage: '',
                                },
                              }));
                              handleChange(e, offer.policyData.polis_online_code, adjustmentType, setAdjustmentType);
                            }}
                            options={[{ label: 'Надбавка', value: 'allowance' }]}
                          />
                        </div>
                        <button
                          disabled={discountPercent[offer.policyData.polis_online_code].errorMessage || allowancePercent[offer.policyData.polis_online_code].errorMessage}
                          type="button"
                          onClick={() => calculateDiscount(offer.policyData.polis_online_code, adjustmentType[offer.policyData.polis_online_code].value.value)}
                          className={classnames('btn search_results__table--button discount_button', { disabled: blurClass || discountPercent[offer.policyData.polis_online_code].errorMessage })}
                        >
                          Применить
                        </button>
                      </div>
                    </Tippy>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {!offer.errorCriticalMessage ? (
            <div className="search_results__table--cell">
              <div className="buttons">
                <button
                  type="button"
                  onClick={(e) => goToFullForm(e, offer.policyData.polis_online_code, offer.price, offer.agreement, companies[offer.policyData.polis_online_code].name, offer.integration, offer.insuranceLimit, offer.risks)}
                  className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
                >
                  Оформить
                </button>
              </div>
              <div className="insurance-info-toggle" onClick={(e) => toggleInsuranceInfoFlag(e, offer)}>
                {offer.insuranceInfoFlag === 'open' ? (
                  <div className="insurance-info-toggle__button">
                    <span>Скрыть подробности</span>
                    <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
                  </div>
                ) : null}
                {(offer.insuranceInfoFlag === 'close') ? (
                  <div className="insurance-info-toggle__button">
                    <span>Подробности о полисе</span>
                    <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        {offer.insuranceInfoFlag !== 'no' && offer.insuranceInfoFlag !== 'close' && !offer.errorCriticalMessage ? (
          <div className={classnames('insurance-info d-flex flex-row justify-content-end')}>
            {companies[offer.policyData.polis_online_code].policy_template && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type] ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].policy_template[draftInfo.type]}`}
              >
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Пример полиса</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].rules && companies[offer.policyData.polis_online_code].rules[draftInfo.type] ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].rules[draftInfo.type]}`}
              >
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Правила страхования</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].presentation && companies[offer.policyData.polis_online_code].presentation[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].presentation[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Презентация</span>
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    ));
  }, [
    loadingInit,
    allPolices.cargoInsurance,
    draftInfo.type,
    allowancePercent,
    setAllowancePercent,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
  ]);

  const otherCompaniesBlock = useMemo(() => {
    if (Object.keys(companies).length === 0 || !loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return Object.entries(companies).filter(([companyName, companyData]) => policesList.findIndex((company) => company.policyData.polis_online_code === companyName) === -1 && !companyData.hide).map(([companyName, value]) => (
      <div key={companyName} className="search_results__table--row">
        <div className="search_results__table--cell">
          <span className="color-light label">Страховая:</span>
          <div>
            <img
              src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${value.logo}`}
              alt="logo"
              className="search_results__logo"
            />
          </div>
          <div className="search_results__company-name">
            {value.name}
          </div>
        </div>
        {(loadingResults)
          ? (
            <div className="search_results__table--cell">
              <LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} />
            </div>
          )
          : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>-</div>
              </div>
              <div className="search_results__table--cell">
                { showCommission && userInfo.hide_commission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>-</div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[companyName].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                    </div>
                  </>
                )}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div>
                  <span className="text-danger">Отказ</span>
                </div>
              </div>
            </>
          )}
      </div>
    ));
  }, [
    loadingInit,
    allPolices.cargoInsurance,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission]);

  return (
    <>
      <form name="CargoShortForm" id="Cargo_short_form" onSubmit={(e) => saveDraft(e, true)}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="d-flex form-group row flex-column flex-lg-row flex-md-row flex-sm-row flex-xl-row">
              <h3 className="mortgage-product-switcher__header ml-3">Вариант страхования:</h3>
              <div
                id="chooseInsurance"
                disabled={disableField}
                name="chooseInsurance"
                onClick={(e) => {
                  handleValidate(e);
                  handleChange({ target: { value: 'all' } }, 'chooseInsurance', formData, setFormData);
                }}
                className={`switcher-item ${formData.chooseInsurance.value === 'all' ? 'switcher-item--active' : ''}`}
              >
                Все риски
              </div>
              <div
                id="chooseInsurance"
                disabled={disableField}
                name="chooseInsurance"
                onClick={(e) => {
                  handleValidate(e);
                  handleChange({ target: { value: 'crash' } }, 'chooseInsurance', formData, setFormData);
                }}
                className={`switcher-item ${formData.chooseInsurance.value === 'crash' ? 'switcher-item--active' : ''}`}
              >
                Крушение
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-4  mb-3 mb-lg-0">
                <label>Страховая сумма</label>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <CurrencyInput
                    inputMode="decimal"
                    id="costFull"
                    disabled={disableField}
                    name="costFull"
                    decimalSeparator="."
                    placeholder="10 000 000"
                    value={formData.costFull.value}
                    decimalsLimit={2}
                    onBlur={handleValidate}
                    onValueChange={(e) => handleChange(e, 'costFull', formData, setFormData)}
                    className={classnames('form-control w-75', { error: formData.costFull.errorMessage })}
                  />
                  {formData.costFull.errorMessage && (
                  <div className="validation-error">
                    {formData.costFull.errorMessage}
                  </div>
                  )}
                  <Select
                    menuPlacement="auto"
                    classNamePrefix="react-select"
                    disabled={disableField}
                    styles={customSelectStyle()}
                    name="valute"
                    onBlur={handleValidate}
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="valute"
                    isSearchable={false}
                    value={formData.valute.value}
                    onChange={(e) => handleChange(e, 'valute', formData, setFormData)}
                    options={typeValutes}
                  />
                  {formData.valute.errorMessage && (
                  <div className="validation-error">
                    {formData.valute.errorMessage}
                  </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4  mb-3 mb-lg-0">
                <label>Вид Транспорта</label>
                <Select
                  menuPlacement="auto"
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  onBlur={handleValidate}
                  name="transport"
                  disabled={disableField}
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="transport"
                  isSearchable
                  value={formData.transport.value}
                  onChange={(e) => handleChange(e, 'transport', formData, setFormData)}
                  options={typeTransports}
                />
                {formData.transport.errorMessage && (
                <div className="validation-error">
                  {formData.transport.errorMessage}
                </div>
                )}
              </div>
              <div className="col-lg-4  mb-3 mb-lg-0">
                <label>Дальность перевозки (км)</label>
                <InputMask
                  maskChar=""
                  disabled={disableField}
                  onChange={(e) => handleChange(e, 'range', formData, setFormData)}
                  id="range"
                  onBlur={handleValidate}
                  placeholder="2000"
                  name="range"
                  inputMode="number"
                  className={classnames('form-control', { error: formData.range.errorMessage })}
                  mask={getMask('sum').mask}
                  formatChars={getMask('sum').formatChars}
                  value={formData.range.value}
                />
                {formData.range.errorMessage && (
                <div className="validation-error">
                  {formData.range.errorMessage}
                </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4  mb-3 mb-lg-0">
                <label>Застрахованный груз</label>
                <Tippy
                  className="tippy-custom"
                  arrow={false}
                  offset={[0, 5]}
                  content={<div style={{ maxWidth: 450 }}>При дальнейшом оформлении нужно будет указать какой именно груз этого типа вы везёте.</div>}
                  hideOnClick={false}
                >
                  <div className="d-inline-block">
                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                  </div>
                </Tippy>
                <Select
                  menuPlacement="auto"
                  disabled={disableField}
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  onBlur={handleValidate}
                  name="cargo"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="cargo"
                  isSearchable
                  value={formData.cargo.value}
                  onChange={(e) => handleChange(e, 'cargo', formData, setFormData)}
                  options={typeThings}
                />
                {formData.cargo.errorMessage && (
                <div className="validation-error">
                  {formData.cargo.errorMessage}
                </div>
                )}
              </div>
              <div className="col-lg-4  mb-3 mb-lg-0">
                <label>Франшиза</label>
                <Select
                  menuPlacement="auto"
                  classNamePrefix="react-select"
                  disabled={disableField}
                  onBlur={handleValidate}
                  styles={customSelectStyle()}
                  name="franchise"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="franchise"
                  isSearchable
                  value={formData.franchise.value}
                  onChange={(e) => handleChange(e, 'franchise', formData, setFormData)}
                  options={typeFranchise}
                />
                {formData.franchise.errorMessage && (
                <div className="validation-error">
                  {formData.franchise.errorMessage}
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CargoOptionsForm
          formData={formData}
          setFormData={setFormData}
          handleValidate={handleValidate}
        />
        <div className="row row-grid">
          <div className="col-lg-12">
            {showForm === 'cargoInsurance' ? (
              <button
                disabled={disableField}
                type="button"
                className="btn btn-success"
                onClick={(e) => backToForm(e, 'short')}
              >Изменить данные
              </button>
            ) : (
              <button disabled={disableField} type="submit" className="btn btn-success">Показать цены</button>
            )}
          </div>
          {(loadingResults) && (
          <div className="col-lg-6">
            <LoadingSpinner className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
            <span>Идет подбор предложений..</span>
          </div>
          )}
        </div>
      </form>
      {showForm === 'short' ? (
        <>
          {(loadingResults) && (
            <ProgressBar stopCalculation={stopCalculation} />
          )}
          {!loadingResults && (Object.prototype.hasOwnProperty.call(allPolices, draftInfo.type) && !allPolices[draftInfo.type].length) && loadingInit && !blurClass ? (
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="error_block">Повторите попытку позже</div>
              </div>
            </div>
          ) : null}
          {loadingInit && (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults">
                  <div className="search_results__title h1">Результаты поиска</div>
                </Element>
                {userInfo.hide_commission === 0 && (
                <div className="form-inline mt-2 mb-4">
                  <span className="switch-text">Режим работы с клиентом</span>
                  <label className="switch">
                    <input type="checkbox" />
                    <span onClick={() => setShowCommission((prev) => !prev)} className="slider round" />
                  </label>
                </div>
                )}
                <div className="search_results__table">
                  {allPolices[draftInfo.type].length ? (
                    <button
                      onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type], commercialOfferCompanies, setCommercialOfferCompanies, 'cargoInsurance', draftInfo.id, showModalInfo)}
                      type="button"
                      className="btn btn-secondary btn-commercial-offer-add-all show-mobile"
                    >
                      {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                    </button>
                  ) : null}
                  <div className="search_results__table--head">
                    <div className="search_results__table--row">
                      <div className="search_results__table--cell">Страховая компания</div>
                      <div className="search_results__table--cell">
                        <a className={classnames({ dotted_link: selectedSort === 'price' })} href="" onClick={(e) => { changeSort(e, 'price'); }}>
                          Стоимость
                          {policySortOrderUp && selectedSort === 'price' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                            : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                        </a>
                      </div>
                      <div className="search_results__table--cell">
                        {showCommission && userInfo.hide_commission === 0 ? (
                          <a className={classnames({ dotted_link: selectedSort === 'commissionCurrency' })} href="" onClick={(e) => { changeSort(e, 'commissionCurrency'); }}>
                            Вознаграждение
                            {policySortOrderUp && selectedSort === 'commissionCurrency' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        ) : (
                          <a className={classnames({ dotted_link: selectedSort === 'rating' })} href="" onClick={(e) => { changeSort(e, 'rating'); }}>
                            Рейтинг
                            {policySortOrderUp && selectedSort === 'rating' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        )}
                      </div>
                      <div className="search_results__table--cell">Комментарий</div>
                      <div className="search_results__table--cell">
                        {allPolices[draftInfo.type].length ? (
                          <button
                            onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type], commercialOfferCompanies, setCommercialOfferCompanies, 'cargoInsurance', draftInfo.id, showModalInfo)}
                            type="button"
                            className="btn btn-secondary btn-commercial-offer-add-all"
                          >
                            {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="search_results__table--body">
                    {loadingInit ? (
                      <>
                        {allPolicyBlock}
                        {errorPolicyBlock}
                        {otherCompaniesBlock}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {blurClass && (
              <div className="blur_message">
                Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «рассчитать»
              </div>
              )}
            </div>
          )}
        </>
      ) : null}
      <CommercialOffer
        policySortOrderUp={policySortOrderUp}
        selectedSort={selectedSort}
        setLoadingCommercialOffer={setLoadingCommercialOffer}
        commercialOfferCompanies={commercialOfferCompanies}
        policyId={draftInfo.id}
        policyIdHash={draftInfo.hash}
        product="cargoInsurance"
        productName="Страхование грузов"
        setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
        showCommercialOfferModalForm={showCommercialOfferModalForm}
        ref={commercialOfferRef}
      />
    </>
  );
}
