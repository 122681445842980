import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import logoBitrix from '../../img/crm/Bitrix24.png';
import logoAmo from '../../img/crm/amo.png';
import WidgetCRMBitrix24 from './WidgetCRMBitrix24';
import WidgetCRMAmo from './WidgetCRMAmo';
import WidgetPolicesList from './WidgetPolicesList';

class WidgetCRMList extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      showList: true,
      showCRM: '',
    };
  }

  showCRM = (e, type) => {
    e.preventDefault();
    this.setState({
      showList: false,
      showCRM: type,
    });
  };

  showMenu = (e) => {
    e.preventDefault();
    this.setState({
      showList: true,
      showCRM: '',
    });
  };

  customizeTable = () => {
    this.child.current.customizeTable();
  };

  render() {
    const {
      showList,
      showCRM,
    } = this.state;

    const {
      widget,
      history,
      tab,
      changeCRMIntegrationActive,
      integrationType,
    } = this.props;
    const activeButtonStyle = {
      fontSize: '12px',
      padding: '6px 12px',
      minHeight: 'auto',
      marginTop: '10px',
    };
    const buttonStyle = {
      ...activeButtonStyle,
      opacity: 0.6,
    };
    const activeFlag = integrationType === 'agent' ? widget.settings.bitrix24IntegrationIsActive : widget.settings.send_lead_to_infull;
    return (
      <>
        {showList ? (
          <>
            <div className="widget-crm-list">
              <a className="widget-crm-list__item" style={!activeFlag ? { borderColor: 'lightgray' } : {}}>
                <img src={logoBitrix} alt="bitrix24" style={!activeFlag ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" className="btn btn-success mr-3" style={activeFlag ? activeButtonStyle : buttonStyle} onClick={(e) => this.showCRM(e, 'bitrix24')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
              <a className="widget-crm-list__item" style={!widget.settings.amoIntegrationIsActive ? { borderColor: 'lightgray' } : {}}>
                <img src={logoAmo} alt="amo" style={!widget.settings.amoIntegrationIsActive ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" disabled className="btn btn-success mr-3" style={widget.settings.amoIntegrationIsActive ? activeButtonStyle : buttonStyle} onClick={(e) => this.showCRM(e, 'amo')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
            </div>
            <WidgetPolicesList ref={this.child} history={history} tab={tab} widgetId={widget.id} type="crm" widgetType={widget.policy_type.code} />
          </>
        ) : null}
        {showCRM === 'bitrix24' ? (<WidgetCRMBitrix24 integrationType={integrationType} changeCRMIntegrationActive={changeCRMIntegrationActive} showMenu={this.showMenu} widgetType={widget.policy_type.code} widgetId={widget.id} activeFlag={activeFlag} />)
          : null}
        {showCRM === 'amo' ? (<WidgetCRMAmo changeCRMIntegrationActive={changeCRMIntegrationActive} showMenu={this.showMenu} widgetId={widget.id} activeFlag={widget.settings.amoIntegrationIsActive} />)
          : null}
      </>
    );
  }
}

export default WidgetCRMList;
