import React, {
  useMemo, useState, useEffect, useContext, useRef,
} from 'react';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import { addDays, subYears } from 'date-fns';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFile } from '@fortawesome/fontawesome-free-regular';
import {
  faLongArrowAltUp, faLongArrowAltDown, faStar, faChevronDown, faChevronUp,
} from '@fortawesome/fontawesome-free-solid';
import { Element } from 'react-scroll';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import {
  customSelectStyle, customFetch, sortAllPolicesArray, handleChange,
} from '../../Utils/Helpers';
import CommercialOffer from '../CommercialOffer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function ZprShortForm(props) {
  const {
    disableField,
    formData,
    setFormData,
    saveDraft,
    handleValidate,
    blurClass,
    companies,
    goToFullForm,
    draftInfo,
    goCalcFlag,
    setGoCalcFlag,
    setBlurClass,
    showForm,
    backToForm,
    genderOptions,
    setLoadingInit,
    loadingInit,
    selectedSort,
    setSelectedSort,
    policySortOrderUp,
    setPolicySortOrderUp,
    policyIntegrationsData,
    setPolicyIntegrationsData,
    allPolices,
    setAllPolices,
    showCommission,
    setShowCommission,
    commercialOfferCompanies,
    setCommercialOfferCompanies,
    setShowCommercialOfferModalForm,
    showCommercialOfferModalForm,
    commercialOfferRef,
    setLoadingCommercialOffer,
    setScrollTo,
  } = props;

  let abortControllerCalculate = new AbortController();
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  const abortController = new AbortController();

  const intervalId = useRef(null);

  const customStyles = useMemo(() => customSelectStyle(), []);
  const [loadingResults, setLoadingResults] = useState(false);

  const makeAllPolicesArray = (product) => {
    const allPolicesArray = [];
    policyIntegrationsData[product].forEach((policyData) => {
      if ((policyData.price || policyData.errorMessage) && Object.prototype.hasOwnProperty.call(companies, policyData.polis_online_code)) {
        allPolicesArray.push({
          policyData,
          insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type])
          || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type])
          || (companies[policyData.polis_online_code].presentation && companies[policyData.polis_online_code].presentation[draftInfo.type]) ? 'close' : 'no',
          integration: policyData.iid,
          rating: companies[policyData.polis_online_code].rating,
          price: policyData.price ? parseFloat(policyData.price) : 0,
          commission: policyData.agentCommission ? policyData.agentCommission : 0,
          commissionCurrency: parseFloat((policyData.agentCommission * parseFloat(policyData.price)) / 100),
          errorMessage: policyData.errorMessage ? policyData.errorMessage : '',
          integrationCompanyCode: policyData.polis_online_code,
        });
      }
      return false;
    });
    return allPolicesArray;
  };

  const changeSort = (e, newSort) => {
    e.preventDefault();
    if (selectedSort === newSort) {
      const newAllPolices = { ...allPolices };
      const direction = policySortOrderUp ? 'down' : 'up';
      const newZprPolices = sortAllPolicesArray(newAllPolices.zpr, selectedSort, direction, policySortOrderUp);
      setAllPolices({
        zpr: newZprPolices,
      });
      setPolicySortOrderUp((prev) => !prev);
    } else {
      const direction = newSort === 'price' ? 'up' : 'down';
      const newAllPolices = { ...allPolices };
      const newZprPolices = sortAllPolicesArray(newAllPolices.zpr, newSort, direction, policySortOrderUp);
      setAllPolices({
        zpr: newZprPolices,
      });
      setSelectedSort(newSort);
      setPolicySortOrderUp(newSort === 'price');
    }
  };

  const toogleCompanyCommercialOffer = (e, companyCode) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    const insuranceIndex = commercialOfferCompanies.findIndex((code) => code === companyCode);
    const newCommercialOfferCompanies = [...commercialOfferCompanies];
    let type = 'set';
    if (insuranceIndex !== -1) {
      type = 'delete';
      newCommercialOfferCompanies.splice(insuranceIndex, 1);
    } else {
      newCommercialOfferCompanies.push(companyCode);
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/zpr/${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance: { companyCode, crossProductCode: null },
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  const toggleAllCompaniesCommercialOffer = (e, allPolicyData) => {
    e.preventDefault();

    let type = 'set';
    let newCommercialOfferCompanies;
    if (commercialOfferCompanies.length) {
      type = 'delete';
      newCommercialOfferCompanies = [];
    } else {
      newCommercialOfferCompanies = allPolicyData.map((policy) => ({
        companyCode: policy.policyData.polis_online_code,
        crossProductCode: null,
      }));
    }

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/zpr/all-insurances-${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurances: newCommercialOfferCompanies,
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  const checkCalc = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let intervalCount = 0;
    const checkInterval = setInterval(() => {
      if (intervalCount === 100) {
        setLoadingResults(false);
        clearInterval(checkInterval);
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation-status?draftHash=${draftInfo.hash}`, {
          signal: abortController.signal,
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((response) => {
            if (!response.error) {
              if (response.resultStatus === 'changed') {
                const newPolicesData = { ...policyIntegrationsData };
                Object.keys(newPolicesData).forEach((type) => {
                  if (response.result) {
                    newPolicesData[type] = response.result;
                  }
                });
                setPolicyIntegrationsData(newPolicesData);
              }
              if (response.calcStatus === 'complete') {
                const newPolicesData = { ...policyIntegrationsData };
                Object.keys(newPolicesData).forEach((type) => {
                  if (response.result) {
                    newPolicesData[type] = response.result;
                  }
                });
                setPolicyIntegrationsData(newPolicesData);
                setLoadingResults(false);
                clearInterval(checkInterval);
              }
            }
          });
        intervalCount += 5;
      }
    }, 3500);
    intervalId.current = checkInterval;
  };

  useEffect(() => () => {
    clearInterval(intervalId.current);
  }, []);

  useEffect(() => () => {
    if (!blurClass) {
      setLoadingResults(false);
      clearInterval(intervalId.current);
      abortController.abort();
    }
  }, [blurClass]);

  const calculate = () => {
    abortControllerCalculate.abort();
    abortControllerCalculate = new AbortController();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    setScrollTo('searchResults');
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      signal: abortControllerCalculate.signal,
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        calcType: 'short',
        draftHash: draftInfo.hash,
        useEvents: false,
      }),
    })
      .then(() => {
        checkCalc();
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const stopCalculation = () => {
    setLoadingResults(false);
  };

  const makeCalculation = () => {
    setBlurClass('');
    setLoadingInit(true);
    setLoadingResults(true);
    setAllPolices({
      zpr: [],
    });
    setPolicyIntegrationsData({
      zpr: [],
    });
    calculate();
  };

  const toggleInsuranceInfoFlag = (e, targetOffer) => {
    e.preventDefault();
    const newPolicyArr = [...allPolices[draftInfo.type]];
    const offerIndex = newPolicyArr.findIndex((offer) => offer.policyData.polis_online_code === targetOffer.policyData.polis_online_code);
    newPolicyArr[offerIndex].insuranceInfoFlag = newPolicyArr[offerIndex].insuranceInfoFlag === 'close' ? 'open' : 'close';
    setAllPolices((prev) => ({
      ...prev,
      [draftInfo.type]: newPolicyArr,
    }));
  };

  useEffect(() => {
    if (draftInfo.hash && goCalcFlag) {
      makeCalculation();
      setGoCalcFlag(false);
    }
  }, [draftInfo.hash, goCalcFlag]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults && policyIntegrationsData.zpr.length))) {
      let allPolicyDataArr = makeAllPolicesArray('zpr');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp);
      setAllPolices((prev) => ({
        ...prev,
        zpr: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults, policyIntegrationsData.zpr.length]);

  useEffect(() => {
    if (draftInfo.id && loadingInit) {
      setCommercialOfferCompanies([]);
      saveDraft(undefined);
    }
  }, [draftInfo.type]);

  const allPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return policesList.filter((offer) => offer.price && !offer.errorMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row', { info: offer.insuranceInfoFlag === 'open', 'has-info': offer.insuranceInfoFlag !== 'no' })}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>
                {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(offer.price)}
              </div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>
                  <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((offer.commission * offer.price) / 100)}</div>
                  <div className="search_results__commission--percentage">{offer.commission}%</div>
                </div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            {offer.policyData.customContract ? <div className="main-color">Оформление через личный договор</div> : null}
          </div>
          <div className="search_results__table--cell">
            <div className="buttons">
              <button
                type="button"
                onClick={(e) => goToFullForm(e, offer.policyData.polis_online_code, offer.price, companies[offer.policyData.polis_online_code].name, offer.integration)}
                className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
              >
                Оформить
              </button>
            </div>
            <div className="insurance-info-toggle" onClick={(e) => toggleInsuranceInfoFlag(e, offer)}>
              {offer.insuranceInfoFlag === 'open' ? (
                <div className="insurance-info-toggle__button">
                  <span>Скрыть подробности</span>
                  <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
                </div>
              ) : null}
              {offer.insuranceInfoFlag === 'close' ? (
                <div className="insurance-info-toggle__button">
                  <span>Подробности о полисе</span>
                  <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {offer.insuranceInfoFlag !== 'no' ? (
          <div className={classnames('insurance-info')}>
            {companies[offer.policyData.polis_online_code].policy_template && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].policy_template[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Пример полиса</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].rules && companies[offer.policyData.polis_online_code].rules[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].rules[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Правила страхования</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].presentation && companies[offer.policyData.polis_online_code].presentation[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].presentation[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Презентация</span>
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    ));
  }, [
    allPolices.zpr,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
    commercialOfferCompanies.length]);

  const errorPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    return allPolices[draftInfo.type].filter((offer) => !offer.price && offer.errorMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row')}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>-</div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>-</div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div>
              {offer.price ? 'Договор успешно согласован!' : <span className="text-danger">{offer.errorMessage}</span>}
            </div>
          </div>
        </div>
      </div>
    ));
  }, [
    loadingInit,
    allPolices.zpr,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
  ]);

  const otherCompaniesBlock = useMemo(() => {
    if (Object.keys(companies).length === 0 || !loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return Object.entries(companies).filter(([companyName, companyData]) => policesList.findIndex((company) => company.policyData.polis_online_code === companyName) === -1 && !companyData.hide).map(([companyName, value]) => (
      <div key={companyName} className="search_results__table--row">
        <div className="search_results__table--cell">
          <span className="color-light label">Страховая:</span>
          <div>
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${value.logo}`} alt="logo" className="search_results__logo" />
          </div>
          <div className="search_results__company-name">
            {value.name}
          </div>
        </div>
        {(loadingResults)
          ? <div className="search_results__table--cell"><LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} /></div>
          : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>-</div>
              </div>
              <div className="search_results__table--cell">
                { showCommission && userInfo.hide_commission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>-</div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[companyName].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                    </div>
                  </>
                )}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div>
                  <span className="text-danger">Отказ</span>
                </div>
              </div>
            </>
          )}
      </div>
    ));
  }, [
    loadingInit,
    allPolices.zpr,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission]);

  const insLimOptions = [
    { label: '210 000', value: '210000' },
    { label: '310 000', value: '310000' },
    { label: '560 000', value: '560000' },
  ];

  const periodOptions = [
    { value: 1, label: 'На год' },
    // { value: 2, label: 'На период' },
  ];

  return (
    <>
      <form name="zprShortForm" id="zpr_short_form" onSubmit={(e) => saveDraft(e, true)}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className={classnames('col-lg-3 mb-lg-0')}>
                <label htmlFor="insuranceLimitOptions">Сумма</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  onBlur={handleValidate}
                  isDisabled={disableField}
                  name="insuranceLimitOptions"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="insuranceLimitOptions"
                  isSearchable={false}
                  value={formData.insuranceLimitOptions.value.value ? formData.insuranceLimitOptions.value : null}
                  onChange={(e) => handleChange(e, 'insuranceLimitOptions', formData, setFormData)}
                  options={insLimOptions}
                />
              </div>
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="birthday">Дата рождения</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={disableField}
                  onBlur={() => handleValidate(null, { fieldName: 'birthday' })}
                  onCalendarClose={() => handleValidate(null, { fieldName: 'birthday' })}
                  onClickOutside={() => handleValidate(null, { fieldName: 'birthday' })}
                  className={classnames('form-control', { error: formData.birthday.errorMessage })}
                  selected={formData.birthday.value ? formData.birthday.value : null}
                  onChange={(e) => handleChange(e, 'birthday', formData, setFormData)}
                  name="birthday"
                  id="birthday"
                  locale={ru}
                  maxDate={subYears(new Date(), 16)}
                  minDate={subYears(new Date(), 70)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel"/>
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/>
                {formData.birthday.errorMessage && (
                  <div className="validation-error">
                    {formData.birthday.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <label htmlFor="gender">Пол</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  onBlur={handleValidate}
                  isDisabled={disableField}
                  name="gender"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="gender"
                  isSearchable={false}
                  value={formData.gender.value.value ? formData.gender.value : null}
                  onChange={(e) => handleChange(e, 'gender', formData, setFormData)}
                  options={genderOptions}
                />
              </div>
              <div className={classnames('col-lg-3 mb-lg-0')} style={{ display: 'none' }}>
                <label htmlFor="period">Тип полиса</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  isDisabled={disableField || formData.period.value.value === 1}
                  onBlur={handleValidate}
                  name="period"
                  inputId="period"
                  isSearchable={false}
                  value={formData.period.value.value ? formData.period.value : null}
                  onChange={(e) => handleChange(e, 'period', formData, setFormData)}
                  options={periodOptions}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className={classnames('col-lg-3 mb-lg-0')}>
                <label htmlFor="startDate">Дата начала полиса</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={disableField}
                  className={classnames('form-control', { error: formData.startDate.errorMessage })}
                  selected={formData.startDate.value ? formData.startDate.value : null}
                  onChange={(e) => handleChange(e, 'startDate', formData, setFormData)}
                  name="startDate"
                  id="startDate"
                  onBlur={() => handleValidate(null, {
                    fieldName: 'startDate',
                    periodType: formData.period.value.value,
                  })}
                  onCalendarClose={() => handleValidate(null, {
                    fieldName: 'startDate',
                    periodType: formData.period.value.value,
                  })}
                  onClickOutside={() => handleValidate(null, {
                    fieldName: 'startDate',
                    periodType: formData.period.value.value,
                  })}
                  locale={ru}
                  minDate={addDays(new Date(), 1)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.startDate.errorMessage && (
                  <div className="validation-error">
                    {formData.startDate.errorMessage}
                  </div>
                )}
              </div>
              <div className={classnames('col-lg-3 mb-lg-0')}>
                <label htmlFor="endDate">Дата окончания полиса</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={formData.period.value.value === 1 || disableField}
                  className={classnames('form-control', { error: formData.endDate.errorMessage })}
                  selected={formData.endDate.value ? formData.endDate.value : null}
                  onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
                  name="endDate"
                  id="endDate"
                  onBlur={() => handleValidate(null, { fieldName: 'endDate' })}
                  onCalendarClose={() => handleValidate(null, { fieldName: 'endDate' })}
                  onClickOutside={() => handleValidate(null, { fieldName: 'endDate' })}
                  locale={ru}
                  minDate={addDays(new Date(), 5)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.endDate.errorMessage && (
                  <div className="validation-error">
                    {formData.endDate.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row row-grid">
          <div className="col-lg-12">
            {showForm === 'zpr' ? (
              <button disabled={disableField} type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short')}>Изменить данные</button>
            ) : (
              <button disabled={disableField} type="submit" className="btn btn-success">Показать цены</button>
            )}
          </div>
          {(loadingResults) && (
            <div className="col-lg-6">
              <LoadingSpinner className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
              <span>Идет подбор предложений..</span>
            </div>
          )}
        </div>
      </form>
      {showForm === 'short' ? (
        <>
          {(loadingResults) && (
            <ProgressBar stopCalculation={stopCalculation} />
          )}
          {!loadingResults && !allPolices[draftInfo.type].length && loadingInit && !blurClass ? (
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="error_block">Повторите попытку позже</div>
              </div>
            </div>
          ) : null}
          {(loadingInit) && (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults"><div className="search_results__title h1">Результаты поиска</div></Element>
                {userInfo.hide_commission === 0 && (
                  <div className="form-inline mt-2 mb-4">
                    <span className="switch-text">Режим работы с клиентом</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span onClick={() => setShowCommission((prev) => !prev)} className="slider round" />
                    </label>
                  </div>
                )}
                <div className="search_results__table">
                  <div className="search_results__table--head">
                    <div className="search_results__table--row">
                      <div className="search_results__table--cell">Страховая компания</div>
                      <div className="search_results__table--cell">
                        <a className={classnames({ dotted_link: selectedSort === 'price' })} href="" onClick={(e) => { changeSort(e, 'price'); }}>
                          Стоимость
                          {policySortOrderUp && selectedSort === 'price' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                            : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                        </a>
                      </div>
                      <div className="search_results__table--cell">
                        {showCommission && userInfo.hide_commission === 0 ? (
                          <a className={classnames({ dotted_link: selectedSort === 'commissionCurrency' })} href="" onClick={(e) => { changeSort(e, 'commissionCurrency'); }}>
                            Вознаграждение
                            {policySortOrderUp && selectedSort === 'commissionCurrency' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        ) : (
                          <a className={classnames({ dotted_link: selectedSort === 'rating' })} href="" onClick={(e) => { changeSort(e, 'rating'); }}>
                            Рейтинг
                            {policySortOrderUp && selectedSort === 'rating' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        )}
                      </div>
                      <div className="search_results__table--cell">Комментарий</div>
                      <div className="search_results__table--cell" />
                    </div>
                  </div>
                  <div className="search_results__table--body">
                    {loadingInit ? (
                      <>
                        {allPolicyBlock}
                        {errorPolicyBlock}
                        {otherCompaniesBlock}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {blurClass && (
                <div className="blur_message">
                  Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «Показать цены»
                </div>
              )}
            </div>
          )}
        </>
      ) : null}
      <CommercialOffer
        policySortOrderUp={policySortOrderUp}
        selectedSort={selectedSort}
        setLoadingCommercialOffer={setLoadingCommercialOffer}
        commercialOfferCompanies={commercialOfferCompanies}
        policyId={draftInfo.id}
        policyIdHash={draftInfo.hash}
        product="zpr"
        productName="Потеря работы"
        setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
        showCommercialOfferModalForm={showCommercialOfferModalForm}
        ref={commercialOfferRef}
      />
    </>
  );
}
