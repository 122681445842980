import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { ReactDadata } from 'react-dadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ym from 'react-yandex-metrika';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import getValidation from '../../Utils/Validation';
import {
  customFetch,
  customSelectStyle, getDeepObjectValue, handleChange, switchLanguage,
} from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import CheckPropertyResult from './CheckPropertyResult';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';
import useUserInfoStore from '../../Stores/UserInfoStore';

const typeOptions = [
  { value: 'address', label: 'Адрес' },
  { value: 'kadNumber', label: 'Кадастровый номер' },
];

export default function CheckPropertyForm(props) {
  const {
    history,
    match,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);
  const [loadingInfoFlags, setLoadingInfoFlags] = useState({ all: true });
  const [disableField, setDisableField] = useState(false);
  const [formId, setFormId] = useState(null);
  const [emailForReport, setEmailForReport] = useState('');
  const [propertyFormLoadingError, setPropertyFormLoadingError] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [status, setStatus] = useState('pending');
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentLink, setPaymentLink] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);
  const [openModalSendReportToEmail, setOpenModalSendReportToEmail] = useState(false);
  const [loadingReportFlag, setLoadingReportFlag] = useState(false);
  const [propertyData, setPropertyData] = useState(null);

  const customStyles = useMemo(() => customSelectStyle());
  const [type, setType] = useState(typeOptions[0]);
  const [formData, setFormData] = useState({
    address: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    kadNumber: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: false,
    },
  });

  useEffect(() => {
    if (type.value === 'address') {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          validationRequired: true,
        },
        kadNumber: {
          ...prev.kadNumber,
          validationRequired: false,
        },
      }));
    } else if (type.value === 'kadNumber') {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          validationRequired: false,
        },
        kadNumber: {
          ...prev.kadNumber,
          validationRequired: true,
        },
      }));
    }
  }, [type.value]);

  const validateInput = (e, args = {}) => {
    const fieldName = e.target.name;
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);
    value = value.data;
    const fieldErrorMessage = getValidation(value, formData[fieldName].validationType);
    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        errorMessage: fieldErrorMessage,
      },
    }));
  };

  const downloadData = (requestFormId) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request/${requestFormId}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((propertyRequest) => {
        if (propertyRequest.error) {
          setPropertyFormLoadingError('Недостаточно прав');
        } else {
          const requestFormData = propertyRequest.form_data;
          const newFormData = {
            address: {
              ...formData.address,
              value: requestFormData.address,
            },
            kadNumber: {
              ...formData.kadNumber,
              value: requestFormData.kadNumber,
            },
          };
          if (!requestFormData.address.value && requestFormData.kadNumber) {
            setType({ value: 'kadNumber', label: 'Кадастровый номер' });
          }
          setFormData(newFormData);

          if (propertyRequest.response_data) {
            setPropertyData(propertyRequest.response_data);
          }

          if (propertyRequest.link) {
            setDownloadLink(propertyRequest.link);
          }

          setStatus(propertyRequest.status);

          if (propertyRequest.error_message) {
            setErrorMessage(propertyRequest.error_message);
          }

          if (propertyRequest.status !== 'pending') {
            setDisableField(true);
          }

          if (propertyRequest.status === 'reserved' && propertyRequest.yandex_kassa_payment_link) {
            setPaymentLink(propertyRequest.yandex_kassa_payment_link);
          }
        }
      })
      .catch(() => {
        setPropertyFormLoadingError('Ошибка загрузки формы');
      })
      .finally(() => {
        setLoadingInfoFlags({ ...loadingInfoFlags, all: false });
      });
  };

  const createQuote = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request/createQuote`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ id }),
    })
      .then((responseQuote) => responseQuote.json())
      .then((responseQuote) => {
        if (responseQuote.data) {
          setPropertyData(responseQuote.data);
        }
        if (responseQuote.error) {
          showModalInfo(responseQuote.error);
          setDisableField(false);
        }
        if (responseQuote.confirmation) {
          setPaymentLink(responseQuote.confirmation.confirmation_url);
          window.open(responseQuote.confirmation.confirmation_url, '_blank');
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
        setDisableField(false);
      })
      .finally(() => {
        setLoadingFlag(false);
      });
  };

  const handleSaveForm = (createQuoteFlag = false) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const flatFormData = Object.keys(formData).reduce((acc, key) => {
      acc[key] = formData[key].value;
      return acc;
    }, {});
    const link = `${process.env.REACT_APP_API_DOMAIN}/property_request${formId ? `/${formId}` : ''}`;
    const method = formId ? 'put' : 'post';
    const body = formId ? JSON.stringify({ formData: flatFormData }) : JSON.stringify({ formData: flatFormData });
    customFetch(link, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body,
    })
      .then((response) => response.json())
      .then((response) => {
        if (method === 'post') {
          history.push({
            pathname: `/check_property/${response.id}`,
          });
        }
        if (createQuoteFlag) {
          createQuote(response.id);
        }
        showModalInfo('Черновик обновлен');
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      });
  };

  useEffect(() => {
    if (match.params.id) {
      const newFormId = match.params.id;
      setFormId(newFormId);
      downloadData(newFormId);
    } else {
      setLoadingInfoFlags((prev) => ({
        ...prev,
        all: false,
      }));
    }
  }, []);

  const changeInput = (e, translate = false, upperCase = false) => {
    e.preventDefault();
    const { target } = e;
    const fieldName = target.id;

    let { value } = target;
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value,
        errorMessage: '',
      },
    }));
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableField(true);
    setLoadingFlag(true);
    ym('reachGoal', 'paymentProperty');
    handleSaveForm(true);
  };

  const statusMessage = useMemo(() => {
    switch (status) {
      case 'processing':
        return 'В работе';
      case 'reserved':
        return 'Ожидает оплаты';
      case 'complete':
        return 'Завершён';
      case 'error':
        return `Ошибка - ${errorMessage}`;
      default:
        return '-';
    }
  }, [status]);

  const copyForm = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request/copy`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formId }),
    })
      .then((response) => response.json())
      .then((newFormId) => {
        setFormId(newFormId);
        history.push({
          pathname: `/check_property/${newFormId}`,
        });
        showModalInfo('Черновик скопирован');
        setDisableField(false);
      })
      .catch(() => {
        showModalInfo('Произошла ошибка', 'error');
      });
  };

  const sendReportToEmail = (e) => {
    e.preventDefault();
    setLoadingReportFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request/sendMail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formId,
        emailForReport,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Отчет отправлен на почту');
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setOpenModalSendReportToEmail((prev) => !prev);
        setLoadingReportFlag(false);
      });
  };

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
        closeIconSize={16}
        open={openModalSendReportToEmail}
        onClose={() => setOpenModalSendReportToEmail((prev) => !prev)}
        center
      >
        <form onSubmit={sendReportToEmail}>
          <label style={{ marginBottom: '10px' }} htmlFor="inputEmail">Укажите адрес электронной почты</label>
          <input value={emailForReport} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={(e) => setEmailForReport(e.target.value)} />
          <SimpleNotification>На указанный адрес будет отправлено письмо с отчетом по проверке недвижимости</SimpleNotification>
          <div className="d-flex align-content-lg-center">
            <button disabled={loadingReportFlag} className="btn btn-success mt-2" type="submit">Отправить</button>
            {loadingReportFlag && <LoadingSpinner className="loading-circle ml-2 mt-2" type="spin" height={37} width={37} />}
          </div>
        </form>
      </Modal>
      <div className="position-relative">
        {propertyFormLoadingError && (
          <div className="row mb-3">
            <div className="col-lg-3">
              <div className="error_block">{propertyFormLoadingError}</div>
            </div>
          </div>
        )}
        {formId ? (
          <>
            <h1 className="page_title d-inline-block mr-3">Проверка недвижимости</h1>
            <div className="page_header__control mb-2 mb-md-0">
              <div onClick={copyForm} className="btn btn-outline">Скопировать</div>
            </div>
            <div className="policy_info">
              {!propertyFormLoadingError && (
              <div className="page_title d-inline-block mr-3">{(status === 'pending' || status === 'reserved') ? 'Черновик' : 'Заявка'} №{formId}</div>
              )}
              {status !== 'pending' ? (
                <div><b>Статус:</b> {statusMessage}</div>
              ) : null}
            </div>
          </>
        ) : null}
        {status !== 'pending' ? (
          <FormNotificationColorLine offsetBottom="1rem">Данный черновик уже обработан и заблокирован для дальнейших изменений.</FormNotificationColorLine>
        ) : null}
        <LoadingBanner loadingFlag={loadingInfoFlags.all}>
          {status !== 'complete' ? (
            <FormNotificationColorLine offsetBottom="1rem">Уникальная технология моментальной проверки недвижимости без выписки ЕГРН с полным соблюдением закона о персональных данных и реальной пользой.</FormNotificationColorLine>
          ) : null }
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                <Select
                  isDisabled={disableField}
                  classNamePrefix="react-select"
                  inputId="inputType"
                  className="form-control-custom"
                  name="inputType"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={type}
                  styles={customStyles}
                  onChange={(e) => setType(e)}
                  options={typeOptions}
                />
              </div>
            </div>
            {type.value === 'address' ? (
              <div className="form-group row">
                <div className="col-xl-6 col-lg-4 col-sm-6 col-12">
                  <label htmlFor="address">Адрес</label>
                  <ReactDadata
                    id="address"
                    name="address"
                    onBlur={validateInput}
                    className={classnames('form-control', { error: formData.address.errorMessage })}
                    query={formData.address.value.value}
                    disabled={disableField}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'address', formData, setFormData)}
                    placeholder="Можете начать с ввода улицы"
                    onFocus={setCursorToEnd}
                  />
                  {formData.address.errorMessage && (
                    <div className="validation-error">
                      {formData.address.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="form-group row">
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                  <label htmlFor="kadNumber">Кадастровый номер</label>
                  <InputMask
                    maskChar=""
                    onBlur={validateInput}
                    onChange={(e) => changeInput(e, false, true)}
                    id="kadNumber"
                    disabled={disableField}
                    placeholder="АА:ВВ:CCCCСCC:КК"
                    name="kadNumber"
                    className={classnames('form-control column-margin upperCase', { error: formData.kadNumber.errorMessage })}
                    value={formData.kadNumber.value}
                  />
                  {formData.kadNumber.errorMessage && (
                    <div className="validation-error">
                      {formData.kadNumber.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="form-group row">
              {paymentLink ? (
                <div className="col-12"><a className="dotted_link" href={paymentLink}>{paymentLink}</a></div>
              ) : (
                <div className="col-12" style={{ display: 'flex' }}>
                  {status !== 'complete' ? <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-1 mb-md-0" onClick={() => handleSaveForm()}>Сохранить</button> : null}
                  {status !== 'complete' ? <button disabled={disableField} type="submit" className="btn btn-success mr-3">{(userInfo.post_paid && userInfo.balance >= userInfo.property_cost) ? 'Оплатить с баланса' : 'Оплатить'}</button> : null}
                  {status === 'complete' && downloadLink ? (
                    <>
                      <a href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${downloadLink}`} target="_blank" rel="noreferrer">
                        <button type="button" className="btn btn-success mr-3">Скачать отчет</button>
                      </a>
                      <button type="button" title="Отправить отчет на почту" onClick={() => setOpenModalSendReportToEmail(true)} className="btn btn-outline btn-square">
                        <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                      </button>
                    </>
                  ) : null}
                  {loadingFlag && <LoadingSpinner className="loading-circle ml-2" type="spin" height={37} width={37} />}
                </div>
              )}
            </div>
          </form>
          {propertyData ? (
            <div className="row">
              <div className="col-12">
                <h2>Полученные данные</h2>
                <CheckPropertyResult propertyData={propertyData} />
              </div>
            </div>
          ) : null}
          {errorMessage ? (
            <div className="row error_block">
              <div className="col-12">
                <h2>{errorMessage}</h2>
              </div>
            </div>
          ) : null}
        </LoadingBanner>
      </div>
    </>
  );
}
