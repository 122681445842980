import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  format, parseISO, parse, compareAsc,
} from 'date-fns';
import ru from 'date-fns/locale/ru';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt, faCheckCircle, faTimesCircle, faTrashAlt,
} from '@fortawesome/fontawesome-free-regular';
import ReactTable from 'react-table-6';
import { reactLocalStorage } from 'reactjs-localstorage';
import matchSorter from 'match-sorter';
import { EditorState, convertToRaw } from 'draft-js';
import queryString from 'query-string';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import {
  toUTC, customFetch, customSelectStyle, getThemes, uploadImage,
} from '../Utils/Helpers';
import LoadingSpinner from "../Layout/LoadingSpinner";

export default class AdminNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      files: [],
      uploading: false,
      isToggleForm: false,
      news: [],
      loading: true,
      themesOptions: [],
      theme: [],
      newsStartDate: null,
      newsEndDate: null,
      newsHeader: '',
      newsText: '',
      newsTags: '',
      newsMainBlock: false,
      newsTopBlock: false,
      newsLeftBlock: false,
      newsFirstLevelOnly: false,
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { location, tab } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    getThemes().then((response) => {
      const themesOptions = response.map((theme) => ({ value: theme.domain, label: theme.domain }));
      this.setState({ themesOptions });
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news?type=all`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((news) => {
        this.setState({ news, loading: false });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      newsStartDate,
      newsEndDate,
      newsHeader,
      newsTags,
      newsMainBlock,
      newsTopBlock,
      newsLeftBlock,
      newsFirstLevelOnly,
      editorState,
      files,
      theme,
    } = this.state;
    this.setState({ uploading: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const formData = new FormData();
    Object.keys(files).map((file) => {
      formData.append(file, files[file]);
    });
    formData.append('date_start', format(toUTC(newsStartDate), 'yyyy-MM-dd HH:mm:ss'));
    formData.append('date_end', newsEndDate ? format(toUTC(newsEndDate), 'yyyy-MM-dd HH:mm:ss') : '');
    formData.append('title', newsHeader);
    formData.append('txt', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    formData.append('tags', newsTags);
    formData.append('main_block', +newsMainBlock);
    formData.append('top_block', +newsTopBlock);
    formData.append('left_block', +newsLeftBlock);
    formData.append('first_level_only', +newsFirstLevelOnly);
    formData.append('themes', JSON.stringify(theme));
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((news) => {
        this.setState((prevState) => ({
          editorState: EditorState.createEmpty(),
          newsStartDate: null,
          newsEndDate: null,
          newsHeader: '',
          newsText: '',
          newsTags: '',
          newsMainBlock: false,
          newsTopBlock: false,
          newsLeftBlock: false,
          newsFirstLevelOnly: false,
          uploading: false,
          isToggleForm: !prevState.isToggleForm,
          news,
          theme: [],
        }));
      });
  };

  handleChangeDate = (type, date) => {
    this.setState({
      [type]: date,
    });
  };

  handleChangeInput = (key, e) => {
    this.setState({ [key]: e.target.value });
  };

  handleChangeSelect = (key, e) => {
    this.setState({ [key]: e });
  }

  handleChangeCheckbox = (key, e) => {
    let {
      newsText,
    } = this.state;
    this.setState({
      [key]: e.target.checked,
    }, () => {
      const {
        newsTopBlock, newsLeftBlock,
      } = this.state;
      newsText = newsText.substr(0, 100000);
      if (newsTopBlock) {
        newsText = newsText.substr(0, 3000);
      }
      if (newsLeftBlock) {
        newsText = newsText.substr(0, 300);
      }
      this.setState({
        newsText,
      });
    });
  };

  setTableSettings = (newResized) => {
    const settings = reactLocalStorage.getObject('newsTableSettings');
    newResized.forEach((item) => {
      settings[item.id] = item.value;
    });
    reactLocalStorage.setObject('newsTableSettings', settings);
  };

  setTableSorting = (newSort) => {
    const settings = reactLocalStorage.getObject('newsTableSettings');
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
    reactLocalStorage.setObject('newsTableSettings', settings);
  };

  toggleForm = () => {
    this.setState((prevState) => ({
      isToggleForm: !prevState.isToggleForm,
    }));
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  deleteNews = (id) => {
    this.setState({ loading: true, news: [] }, () => {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/news/${id}?type=all`, {
        headers: {
          Authorization: lsToken,
        },
        method: 'delete',
      })
        .then((response) => response.json())
        .then((news) => {
          this.setState({ news, loading: false });
        });
    });
  };

  uploadFile = (acceptedFiles) => {
    const { files } = this.state;
    acceptedFiles.forEach((file) => {
      files.push(file);
    });
    this.setState({ files });
  };

  removeFile = (index) => {
    const { files } = this.state;
    files.splice(index, 1);
    this.setState({ files });
  };

  uploadImageNews = (file) => new Promise((resolve) => {
    const formData = new FormData();
    formData.append('file', file);
    uploadImage(file, formData)
      .then((res) => {
        if (!res.error) {
          resolve({ data: { link: res } });
        }
      });
  });

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    const { tab } = this.props;
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  render() {
    const {
      newsStartDate, newsEndDate, loading, news, isToggleForm, editorState, uploading, files, themesOptions, theme,
    } = this.state;
    const { history } = this.props;
    let defaultSortingField = 'order_date';
    let defaultSortingOrder = 'true';
    if (reactLocalStorage.getObject('newsTableSettings').defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject('newsTableSettings').defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject('newsTableSettings').defaultSortingOrder;
    }

    const columns = [{
      Header: '№',
      accessor: 'id',
      width: reactLocalStorage.getObject('newsTableSettings').id,
      filterAll: true,
      maxWidth: 70,
    }, {
      Header: 'Автор',
      id: 'agent',
      accessor: (d) => (d.agent ? `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}` : ''),
      width: reactLocalStorage.getObject('newsTableSettings').agent,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent'] }),
      filterAll: true,
    }, {
      Header: 'Заголовок',
      accessor: 'title',
      width: reactLocalStorage.getObject('newsTableSettings').title,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['title'] }),
      filterAll: true,
    }, {
      Header: 'Дата создания',
      id: 'created_at',
      accessor: (d) => parseISO(d.created_at).toLocaleDateString(),
      width: reactLocalStorage.getObject('newsTableSettings').created_at,
      sortMethod: (a, b) => compareAsc(parse(a, 'dd.MM.yyyy', new Date()), parse(b, 'dd.MM.yyyy', new Date())),
    }, {
      Header: 'Дата начала',
      id: 'date_start',
      accessor: (d) => parseISO(d.date_start).toLocaleDateString(),
      width: reactLocalStorage.getObject('newsTableSettings').date_start,
      sortMethod: (a, b) => compareAsc(parse(a, 'dd.MM.yyyy', new Date()), parse(b, 'dd.MM.yyyy', new Date())),
    }, {
      Header: 'Дата окончания',
      id: 'date_end',
      accessor: (d) => (d.date_end ? parseISO(d.date_end).toLocaleDateString() : ''),
      width: reactLocalStorage.getObject('newsTableSettings').date_end,
      sortMethod: (a, b) => compareAsc(parse(a, 'dd.MM.yyyy', new Date()), parse(b, 'dd.MM.yyyy', new Date())),
    }, {
      accessor: 'main_block',
      Header: 'Главная новость',
      Cell: ({ row }) => (row._original.main_block === 1
        ? (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)
        : (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)),
    }, {
      accessor: 'top_block',
      Header: 'Блок сверху',
      Cell: ({ row }) => (row._original.top_block === 1
        ? (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)
        : (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)),
    }, {
      Header: 'Блок слева',
      Cell: ({ row }) => (row._original.left_block === 1
        ? (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)
        : (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)),
      accessor: 'left_block',
    }, {
      Header: '',
      width: 40,
      filterable: false,
      Cell: (row) => (
        <div title="Удалить новость"><FontAwesomeIcon onClick={() => this.deleteNews(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    return (
      <>
        {isToggleForm
          ? (
            <form onSubmit={this.handleSubmit} className="mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-sm-6 col-xl-4">
                      <label htmlFor="policyStartDate">Дата начала</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        selected={newsStartDate}
                        onChange={(date) => this.handleChangeDate('newsStartDate', date)}
                        className="form-control"
                        name="newsStartDate"
                        id="newsStartDate"
                        locale={ru}
                        minDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        showTimeSelect
                        timeCaption="Время"
                        timeFormat="HH:mm"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText="12.12.1990 12:30"
                        dropdownMode="scroll"
                        required
                        customInput={
                          <InputMask mask="99.99.9999 99:99" />
                          }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <label htmlFor="policyStartDate">Дата окончания</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        selected={newsEndDate}
                        onChange={(date) => this.handleChangeDate('newsEndDate', date)}
                        className="form-control"
                        name="newsEndDate"
                        id="newsEndDate"
                        locale={ru}
                        minDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        showTimeSelect
                        timeCaption="Время"
                        timeFormat="HH:mm"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText="12.12.1990 12:30"
                        dropdownMode="scroll"
                        customInput={
                          <InputMask mask="99.99.9999 99:99" />
                          }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="newsHeader">Заголовок новости</label>
                      <input
                        type="text"
                        className="form-control"
                        id="newsHeader"
                        onChange={(e) => this.handleChangeInput('newsHeader', e)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-12">
                      <label htmlFor="newsHeader">Выбрать кастомицазию</label>
                      <div className="d-flex flex-row align-items-center w-100">
                        <Select
                          isMulti
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="theme"
                          placeholder="Только для выбранных"
                          id="theme"
                          value={theme}
                          onChange={(e) => this.handleChangeSelect('theme', e)}
                          options={themesOptions}
                        />
                        <button type="button" className="btn btn-success ml-2" onClick={() => this.handleChangeSelect('theme', themesOptions)}>Добавить все</button>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label htmlFor="newsText">Текст новости</label>
                      <Editor
                        toolbar={{
                          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                          image: {
                            uploadEnabled: true,
                            uploadCallback: (file) => this.uploadImageNews(file),
                            previewImage: true,
                          },
                        }}
                        stripPastedStyles
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="form-control"
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="newsTags">Тэги (указывать через запятую)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="newsTags"
                        onChange={(e) => this.handleChangeInput('newsTags', e)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Файлы</label>
                      {files.length > 0 && (
                      <ul>
                        {files.map((file, index) => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes <FontAwesomeIcon
                              onClick={() => this.removeFile(index)}
                              icon={faTrashAlt}
                              className="fa-fw"
                            />
                          </li>
                        ))}
                      </ul>
                      )}
                      <Dropzone onDrop={(acceptedFiles) => this.uploadFile(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p>Загрузить файл или картинку</p>
                              <p>Перетащить с помощью Drag'n'Drop</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <div className="form-check custom-checkbox">
                        <input
                          className="form-check-input checkbox-styled"
                          onChange={(e) => this.handleChangeCheckbox('newsMainBlock', e)}
                          type="checkbox"
                          id="newsMainBlock"
                        />
                        <label className="form-check-label" htmlFor="newsMainBlock">
                          Новость
                        </label>
                      </div>
                      <div className="form-check custom-checkbox">
                        <input
                          className="form-check-input checkbox-styled"
                          onChange={(e) => this.handleChangeCheckbox('newsTopBlock', e)}
                          type="checkbox"
                          id="newsTopBlock"
                        />
                        <label className="form-check-label" htmlFor="newsTopBlock">
                          Уведомление сверху
                        </label>
                      </div>
                      <div className="form-check custom-checkbox">
                        <input
                          className="form-check-input checkbox-styled"
                          onChange={(e) => this.handleChangeCheckbox('newsLeftBlock', e)}
                          type="checkbox"
                          id="newsLeftBlock"
                        />
                        <label className="form-check-label" htmlFor="newsLeftBlock">
                          Уведомление слева
                        </label>
                      </div>
                      <div className="form-check custom-checkbox">
                        <input
                          className="form-check-input checkbox-styled"
                          onChange={(e) => this.handleChangeCheckbox('newsFirstLevelOnly', e)}
                          type="checkbox"
                          id="newsFirstLevelOnly"
                        />
                        <label className="form-check-label" htmlFor="newsFirstLevelOnly">
                          Только для пользователей 1 уровня
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success mr-2">Сохранить</button>
                      <button type="button" className="btn btn-secondary" onClick={this.toggleForm}>Отмена</button>
                      {uploading && (
                      <LoadingSpinner
                        className="loading-circle ml-3 d-inline-block"
                        type="spin"
                        height={38}
                        width={38}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )
          : <button type="button" className="btn btn-success mb-4" onClick={this.toggleForm}>Добавить новость</button>}
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    history.push(`news/${rowInfo.row._original.id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/news/${rowInfo.row._original.id}`, '_blank');
                  }
                },
              };
            }
            return {};
          }}
          className="table"
          data={news}
          columns={columns}
          defaultFiltered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            this.setTableSorting(newSort);
          }}
          onResizedChange={(newResized) => {
            this.setTableSettings(newResized);
          }}
        />
      </>
    );
  }
}
