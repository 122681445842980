import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import DraftsTable from './DraftsTable';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Drafts extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Черновики';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const { activeTab } = this.state;
    const {
      history, location, userInfo,
    } = this.props;
    return (
      <div className="position-relative">
        <h1 className="page-wrapper__title d-inline-block mr-3">Черновики</h1>
        <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
          {(activeTab === '1' || activeTab === '2' || userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
          ) : null}
          <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
            <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
            Настроить таблицу
          </Button>
        </div>
        <h2>Ваши сохраненные расчеты и черновики</h2>
        <TabNavList>
          <TabNavItem
            onClick={() => { this.toggle('1'); }}
            active={activeTab === '1'}
            text="Мои черновики"
          />
          <TabNavItem
            onClick={() => { this.toggle('2'); }}
            active={activeTab === '2'}
            text="Черновики пользователей"
          />
          {userInfo.full_time_employee === 1 && userInfo.remote_employee === 0 && (
            <TabNavItem
              onClick={() => { this.toggle('3'); }}
              active={activeTab === '3'}
              text="Все черновики (штатные)"
            />
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabNavItem
              onClick={() => { this.toggle('4'); }}
              active={activeTab === '4'}
              text="Все черновики (админ)"
            />
          )}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            { activeTab === '1' ? <DraftsTable ref={activeTab === '1' ? this.child : undefined} tab="1" updateData={activeTab} location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="2">
            { activeTab === '2' ? <DraftsTable ref={activeTab === '2' ? this.child : undefined} tab="2" updateData={activeTab} location={location} history={history} show="subagents" /> : null }
          </TabPane>
          {userInfo.full_time_employee === 1 && userInfo.remote_employee === 0 && (
            <TabPane tabId="3">
              { activeTab === '3' ? <DraftsTable ref={activeTab === '3' ? this.child : undefined} tab="3" updateData={activeTab} location={location} history={history} show="full_time_employee" /> : null }
            </TabPane>
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabPane tabId="4">
              { activeTab === '4' ? <DraftsTable ref={activeTab === '4' ? this.child : undefined} tab="4" updateData={activeTab} location={location} history={history} show="all" /> : null }
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

export default withUserInfoStoreStore(Drafts);
