import React, { useEffect, useRef } from 'react';

const AutoResizingTextarea = ({ className, disabled, onBlur, onChange, value, ...props }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      className={className}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      style={{ overflowY: 'hidden', resize: 'none', ...props.style }}
      {...props}
    />
  );
};

export default AutoResizingTextarea;
