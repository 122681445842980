import React, {
  useState, useContext, useMemo, useRef, useEffect, useImperativeHandle, forwardRef,
} from 'react';
import Select from 'react-select';
import { CSVReader } from 'react-papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import LoadingSpinner from "../Layout/LoadingSpinner";
import ReactTable from 'react-table-6';
import matchSorter from 'match-sorter';
import ReactExport from 'react-data-export';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
} from '../Utils/Helpers';
import getValidation from '../Utils/Validation';

function BlackList(props, ref) {
  const [requestWaitingFlag, setRequestWaitingFlag] = useState(false);
  const [loadingBlackListFlag, setLoadingBlackListFlag] = useState(false);
  const [columns, setColumns] = useState([]);
  const [blackList, setBlackList] = useState([]);
  const [blockType, setBlockType] = useState({ label: 'Почта', value: 'email' });
  const [fileData, setFileData] = useState([]);
  const [fileError, setFileError] = useState('');
  const [goDownloadFile, setGoDownloadFile] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;

  const excelFile = useRef(null);
  const fileRef = useRef(null);
  const tableRef = useRef(null);

  const { showModalInfo } = useContext(ModalMessagesContext);
  const customStyles = useMemo(() => customSelectStyle());

  const validFileExt = () => {
    let isValid = true;
    const fileNameArr = fileRef.current.state.file.name.split('.');
    if (fileNameArr[fileNameArr.length - 1] !== 'csv') {
      isValid = false;
    }
    return isValid;
  };

  const updateTableColumns = () => {
    const newColumns = [{
      Header: 'Email',
      accessor: 'email',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
      filterAll: true,
      show: blockType.value === 'email',
    }, {
      Header: 'Телефон',
      accessor: 'phone',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
      filterAll: true,
      show: blockType.value === 'phone',
    }];
    setColumns([...newColumns]);
  };

  const handleOnDrop = (fileParsedData) => {
    if (validFileExt()) {
      if (fileParsedData.length > 0) {
        const numbers = fileParsedData.filter((row) => row.data[0] !== '').map((row) => row.data[0]);
        const errors = numbers.filter((value) => getValidation(value, blockType.value));
        if (errors.length > 0) {
          setFileError('Неверный формат полей.');
        } else {
          setFileError('');
          setFileData([...numbers]);
        }
      } else {
        setFileError('Пустой файл');
      }
    } else {
      setFileError('Неверный формат файла. Допустимы только csv файлы.');
    }
  };

  const handleOnError = () => {
    setFileError('Ошибка обработки файла');
    showModalInfo('Ошибка обработки файла');
  };

  const handleOnRemoveFile = () => {
    setFileError('');
    setFileData([]);
  };

  const downloadBlackList = () => {
    setLoadingBlackListFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/block_list?type=${blockType.value}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          setBlackList([...response]);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage, 'error');
      })
      .finally(() => {
        setLoadingBlackListFlag(false);
      });
  };

  const uploadBlockList = () => {
    setBlackList([]);
    setLoadingBlackListFlag(true);
    setRequestWaitingFlag(true);

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/block_list`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        list: fileData,
        type: blockType.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          setBlackList([...response]);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage, 'error');
      })
      .finally(() => {
        setLoadingBlackListFlag(false);
        setRequestWaitingFlag(false);
        setFileError('');
        setFileData([]);
        fileRef.current.removeFile();
      });
  };

  const downloadTableData = () => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    const currentRecords = tableRef.current.getResolvedState().sortedData;
    const dataToDownload = [];
    for (let index = 0; index < currentRecords.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (currentRecords[index][columns[colIndex].accessor]) {
            if (currentRecords[index][columns[colIndex].accessor] && currentRecords[index][columns[colIndex].accessor].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
            }
          } else {
            if (currentRecords[index][columns[colIndex].id] && currentRecords[index][columns[colIndex].id].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
            }
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.filter((column) => column.show !== false).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);

    setExcelColumns([...excelcolumns]);
    setDataSet([...dataToDownload]);
    setGoDownloadFile(true);
  };

  useImperativeHandle(ref, () => ({
    downloadTableData: () => {
      downloadTableData();
    },
  }));

  useEffect(() => {
    updateTableColumns();
    downloadBlackList();
    setFileError('');
    setFileData([]);
    fileRef.current.removeFile();
  }, [blockType]);

  useEffect(() => {
    if (goDownloadFile) {
      excelFile.current.handleDownload();
      setGoDownloadFile(false);
    }
  }, [goDownloadFile]);

  useEffect(() => {
    updateTableColumns();
    downloadBlackList();
  }, []);

  return (
    <>
      <h2>Черный список</h2>
      <div className="card mb-4">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-xl-4 col-lg-6 col-sm-12" style={{ zIndex: 100 }}>
              <label htmlFor="blockType">Тип</label>
              <Select
                classNamePrefix="react-select"
                inputId="blockType"
                className="form-control-custom"
                name="blockType"
                placeholder="Тип"
                noOptionsMessage={() => 'Не найдено'}
                value={blockType}
                styles={customStyles}
                onChange={(e) => setBlockType(e)}
                options={[
                  { label: 'Телефон', value: 'phone' },
                  { label: 'Почта', value: 'email' },
                ]}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-4 col-lg-6 col-sm-12">
              <CSVReader
                ref={fileRef}
                onDrop={handleOnDrop}
                onError={handleOnError}
                noDrag
                noProgressBar
                onRemoveFile={handleOnRemoveFile}
                accept="text/csv, .csv, application/vnd.ms-excel"
              >
                {({ file }) => (
                  <>
                    <label className="d-block" htmlFor="passport_page_two">Файл со списком телефонов или почтовых ящиков (в формате CSV)</label>
                    <div className="jq-file styler">
                      <div className="jq-file__name">{file ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /> {file.name}</> : null}</div>
                      {file
                        ? (<div onClick={(e) => fileRef.current.removeFile(e)} className="jq-file__remove" />)
                        : (<div className="jq-file__browse" />
                        )}
                      <input type="text" onClick={(e) => fileRef.current.open(e)} id="passport_page_two" name="passport_page_two" className="custom-input-file" />
                    </div>
                    {fileError && (
                      <div className="validation-error">
                        {fileError}
                      </div>
                    )}
                  </>
                )}
              </CSVReader>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-12" style={{ display: 'flex' }}>
          <button className="btn btn-success" type="button" disabled={!fileData.length || requestWaitingFlag} onClick={uploadBlockList}>Загрузить</button>
          {requestWaitingFlag && <LoadingSpinner className="loading-circle ml-2" type="spin" height={37} width={37} />}
        </div>
      </div>
      <div className="d-none">
        <ExcelFile ref={excelFile} element={<button type="button">Download Data</button>}>
          <ExcelSheet data={dataSet} name="OsagoPoliciesInfo">
            {excelColumns}
          </ExcelSheet>
        </ExcelFile>
      </div>
      <ReactTable
        ref={tableRef}
        className="table"
        data={blackList}
        columns={columns}
        previousText="Назад"
        nextText="Вперед"
        loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
        noDataText="Информация не найдена"
        pageText="Страница"
        ofText="Из"
        rowsText="строк"
        loading={loadingBlackListFlag}
        defaultPageSize={50}
        filterable
        minRows={1}
      />
    </>
  );
}

export default forwardRef(BlackList);
