import React, { Component } from 'react';
import classnames from 'classnames';

export default class ModalInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { message, isActive } = this.props;
    return (
      <div className={classnames('notification notification--lk js-notification-lk', { 'notification--hide': !isActive }, { 'notification--show': isActive })}>
        <div className="notification__container">
          <div className="notification__content">
            <p className="profile__littletext profile__littletext--dark">{message}</p>
            <div className="notification__progress">
              <div className="notification__progress-inner" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
