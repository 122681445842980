import React from 'react';
import {
  AcademicCapIcon,
  FireIcon,
  PlusCircleIcon,
  WrenchScrewdriverIcon,
  UserIcon,
  BoltIcon,
  FlagIcon,
  BeakerIcon,
  HeartIcon,
  ShieldCheckIcon,
  BellIcon,
  BanknotesIcon,
  CreditCardIcon,
  GlobeAltIcon,
  SparklesIcon,
  HomeIcon,
  PercentBadgeIcon,
} from '@heroicons/react/24/solid';

export default function DynamicIcon(props) {
  const { nameIcon } = props;

  let icon = '';

  switch (nameIcon) {
    case 'AcademicCapIcon':
      icon = (
        <AcademicCapIcon />
      );
      break;
    case 'FireIcon':
      icon = (
        <FireIcon />
      );
      break;
    case 'PlusCircleIcon':
      icon = (
        <PlusCircleIcon />
      );
      break;
    case 'UserIcon':
      icon = (
        <UserIcon />
      );
      break;
    case 'WrenchScrewdriverIcon':
      icon = (
        <WrenchScrewdriverIcon />
      );
      break;
    case 'BoltIcon':
      icon = (
        <BoltIcon />
      );
      break;
    case 'FlagIcon':
      icon = (
        <FlagIcon />
      );
      break;
    case 'BeakerIcon':
      icon = (
        <BeakerIcon />
      );
      break;
    case 'HeartIcon':
      icon = (
        <HeartIcon />
      );
      break;
    case 'ShieldCheckIcon':
      icon = (
        <ShieldCheckIcon />
      );
      break;
    case 'BellIcon':
      icon = (
        <BellIcon />
      );
      break;
    case 'BanknotesIcon':
      icon = (
        <BanknotesIcon />
      );
      break;
    case 'CreditCardIcon':
      icon = (
        <CreditCardIcon />
      );
      break;
    case 'GlobeAltIcon':
      icon = (
        <GlobeAltIcon />
      );
      break;
    case 'SparklesIcon':
      icon = (
        <SparklesIcon />
      );
      break;
    case 'HomeIcon':
      icon = (
        <HomeIcon />
      );
      break;
    case 'PercentBadgeIcon':
      icon = (
        <PercentBadgeIcon />
      );
      break;
    default:
      break;
  }

  return (
    <div className={props.className}>
      {
        nameIcon && icon ? (
          <div>
            {icon}
          </div>
        ) : null
      }
    </div>
  );
}
