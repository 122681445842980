import React, {
  useState, useEffect, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faStar } from '@fortawesome/fontawesome-free-solid';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  faClone, faEnvelope,
} from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import { format, parseISO } from 'date-fns';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

export default function CargoCheckInfo(props) {
  const {
    formData,
    chosenOffer,
    companies,
    backToForm,
    setChosenOffer,
    draftInfo,
    setDisableField,
    policy,
    loadPolicy,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [loadingFinalResult, setLoadingFinalResults] = useState(false);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const [loadingAgreement, setLoadingAgreement] = useState({ loading: false, send: false });
  const [loadingOfferta, setLoadingOfferta] = useState(false);
  const [visibleTooltipCopyToClipboard, setVisibleTooltipCopyToClipboard] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [paymentLinkCard, setPaymentLinkCard] = useState('');
  const [linkOfferta, setLinkOfferta] = useState('');
  const [emailForLink, setEmailForLink] = useState('');
  const [openModalSendLinkToEmail, setOpenModalSendLinkToEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [criticalErrorMessage, setCriticalErrorMessage] = useState('');
  const [paymentInfo, setPaymentInfo] = useState('');

  const sendAgreement = () => {
    setLoadingAgreement((prev) => ({ ...prev, loading: true }));
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/cargo_insurance/send_agreement`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ draftHash: draftInfo.hash, chosenOffer, company: chosenOffer.companyCode }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          setDisableField(true);
          setLoadingAgreement(() => ({ send: true }));
          loadPolicy(policy.id);
          showModalInfo('Полис отправлен на согласование!');
        }
      })
      .catch((ex) => {
        showModalInfo(`Ошибка ${ex.message}`, 'error');
      })
      .finally(() => {
        setLoadingAgreement((prev) => ({ ...prev, loading: false }));
      });
  };

  const submitPayment = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    showModalInfo('Полис проходит обработку!');
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/payment?draftHash=${draftInfo.hash}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          if (chosenOffer.agreement) {
            sendAgreement();
          }
          showModalInfo('Полис прошёл обработку!');
        }
        loadPolicy(policy.id);
        setDisableField(true);
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const getPaymentLink = () => {
    setLoadingPaymentLink(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/getPaymentLink?draftHash=${draftInfo.hash}`, {
      method: 'GET',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getCheckOfferta();
        if (!response.error) {
          setPaymentLink(response.paymentLink);
          let linkCard = response.paymentLinkCard;
          try {
            linkCard = JSON.parse(response.paymentLinkCard);
          } catch (e) {

          }
          if (linkCard?.type === 'error') {
            showModalInfo('Получение оплаты по карте с данной валютой невозможно', 'error');
          } else {
            setPaymentLinkCard(response.paymentLinkCard);
          }
        } else {
          showModalInfo(response.error, 'error');
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при получении ссылки на оплату');
      })
      .finally(() => {
        setLoadingPaymentLink(false);
      });
  };

  const getCheckOfferta = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const data = {};
    Object.entries(formData).map((input) => {
      const key = input[0];
      const { value } = input[1];
      data[key] = value;
      if ((key === 'startDate' || key === 'endDate' || key === 'tnDate') && value) {
        data[key] = new Date(format(parseISO(value.toISOString()), 'yyyy-MM-dd'));
      }
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/cargo_insurance/prop_offerta`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData: data,
        draftHash: draftInfo.hash,
      }),
    })
      .then((response) => response.json()).then((response) => {
        setLoadingOfferta(false);
        setLinkOfferta(response.link);
      }).catch(() => {
        setLoadingOfferta(false);
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при получении файла');
      });
  };

  const recalculate = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash: draftInfo.hash,
        insurance: chosenOffer.companyCode,
        calcType: 'final',
      }),
    }).then((response) => response.json())
      .then((response) => {
        if (!response.errorMessage && !response.error && !response.errors && !response.criticalError) {
          const { price } = response;
          setChosenOffer((prev) => ({
            ...prev,
            price,
            agreement: false,
          }));
          getPaymentLink();
        } else {
          const { agreement } = response;
          setChosenOffer((prev) => ({
            ...prev,
            agreement,
          }));
          if (agreement) {
            getCheckOfferta();
          }
          const error = response.errorMessage || response.criticalError || response.error || response.errors.errors[0].message;
          showModalInfo(error, 'error');
          setErrorMessage(error);
          setCriticalErrorMessage(response.criticalError);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при выполнении финального расчета');
      })
      .finally(() => {
        setLoadingFinalResults(false);
      });
  };

  const copyToClipBoard = () => {
    setVisibleTooltipCopyToClipboard(true);
    submitPayment();
  };

  useEffect(() => {
    if (!policy.is_demo) {
      setLoadingOfferta(true);
      setLoadingFinalResults(true);
      recalculate(chosenOffer.integration);
    }
  }, []);

  useEffect(() => {
    if (visibleTooltipCopyToClipboard) {
      setTimeout(() => {
        setVisibleTooltipCopyToClipboard(false);
      }, 3000);
    }
  }, [visibleTooltipCopyToClipboard]);

  useEffect(() => {
    if (formData.contactEmail.value) {
      setEmailForLink(formData.contactEmail.value);
    }
  }, [formData.contactEmail.value]);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
        closeIconSize={16}
        open={openModalSendLinkToEmail}
        onClose={() => setOpenModalSendLinkToEmail((prev) => !prev)}
        center
      >
        <form onSubmit={submitPayment}>
          <label htmlFor="inputEmail">Укажите адрес электронной почты</label>
          <input value={emailForLink} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={(e) => setEmailForLink(e.target.value)} />
          <SimpleNotification offsetBottom="0.5rem">На указанный адрес отправлено письмо со ссылкой на оплату.</SimpleNotification>
          <button className="btn btn-success" type="submit">Отправить</button>
        </form>
      </Modal>
      <div name="check_info" className="search_results__main page--calck">
        <SimpleNotification offsetBottom="1rem">Отлично! Вы выбрали продукт от компании {companies[chosenOffer.companyCode].name}.</SimpleNotification>
        <SimpleNotification offsetBottom="1rem">Проверьте правильность введенных данных.</SimpleNotification>
        {chosenOffer.companyCode === 'gelios' ? (
          <SimpleNotification offsetBottom="1rem">
            <strong className="ml-4">Для полисов от компании «Гелиос» действуют следующие ограничения:</strong>
            <ul>
              <li> Страхование не распространяется на перевозку грузов из/в/через территории:
                Республики Дагестан, Карачаево-Черкесской Республики, Кабардино-Балкарской Республики, Республики Северная Осетия – Алания, Республики Ингушетия, Чеченской Республики; Донецкой и Луганской народных республик, Херсонской и Запорожской областей.
              </li>
            </ul>
          </SimpleNotification>
        ) : null}
        <div>
          <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
        </div>
        <div className="mb-2 d-flex">
          <span className="mr-auto align-self-center">Введенные данные</span>
          {loadingOfferta
            ? (<div className="d-flex flex-row align-items-center mr-2"><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Формируется проект</div>)
            : (<a className="btn custom-btn mr-2 pt-2" href={linkOfferta} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFileDownload} className="fa-fw mr-1" />Скачать проект</a>)}
          <button type="button" onClick={(e) => backToForm(e, 'top')} className="btn custom-btn">Редактировать</button>
        </div>
        <table className="data_table">
          <thead>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Страхователь</span></td>
              <td className="data_table__row_content">
                <p>
                  <span className="font-weight-bold">{formData.chooseFace.value.label}</span>
                  <p>
                    {formData.organizationName.value.value ? (
                      <>
                        Название: {formData.organizationName.value.value} <br />
                        Юр. адрес: {formData.organizationName.value?.data?.address?.value ?? '-'} <br />
                        КПП: {formData.organizationName.value?.data?.kpp ?? '-'}<br />
                        ИНН: {formData.organizationName.value?.data?.inn ?? '-'}<br />
                        ОГРН: {formData.organizationName.value?.data?.ogrn ?? '-'} <br />
                      </>
                    ) : (
                      <>
                        {formData.lastName.value} {formData.firstName.value} {formData.middleName.value}, {formData.passport.value},
                        {formData.passportUnitNumber.value}, {formData.passportDivision.value},
                        {format(new Date(formData.passportDate.value), 'dd.MM.yyyy')}, {formData.insuredAddressRegistration.value.value}
                      </>
                    )}
                  </p>
                </p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Выгодоприобретатель</span></td>
              <td className="data_table__row_content">
                <p>
                  {formData.beneficiaryName.value.value ? (
                    <>
                      Название: {formData.beneficiaryName.value.value} <br />
                      Юр. адрес: {formData.beneficiaryName.value?.data?.address?.value ?? '-'} <br />
                      КПП: {formData.beneficiaryName.value?.data?.kpp ?? '-'}<br />
                      ИНН: {formData.beneficiaryName.value?.data?.inn ?? '-'}<br />
                      ОГРН: {formData.beneficiaryName.value?.data?.ogrn ?? '-'} <br />
                    </>
                  ) : (
                    <>
                      Договор страхования имущества в пользу выгодоприобретателя может быть заключен без указания имени или наименования выгодоприобретателя (страхование «за счет кого следует»)
                    </>
                  )}
                </p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Данные о полисе</span></td>
              <td className="data_table__row_content">
                <p>
                  <b>Продукт:</b>
                  {draftInfo.type === 'cargoInsurance' ? ' Страхование грузов' : null}
                </p>
                <p><b>Cтраховая компания:</b> {companies[chosenOffer.companyCode].name}</p>
                <p><b>Дата начала:</b> {format(new Date(formData.startDate.value), 'dd.MM.yyyy')}</p>
                <p><b>Дата окончания:</b> {format(new Date(formData.endDate.value), 'dd.MM.yyyy')}</p>
                <p><b>Страховая cумма:</b> {formData.costFull.value} {formData.valute.value.label}</p>
                <p><b>Дальность перевозки:</b> {formData.range.value} км</p>
                <p><b>Тип груза:</b> {formData.cargo.value.label},а именно {formData.cargoFull.value}</p>
                <p><b>Тип транспорта:</b> {formData.transport.value.label}</p>
                <p><b>Пункт отправления:</b> {formData.insuredAddressUp.value.value}</p>
                <p><b>Пункт назначения:</b> {formData.insuredAddressDown.value.value}</p>
                <p><b>Франшиза:</b> {formData.franchise.value.label}</p>
                <p><b>Б/У груз:</b> {formData.loadunload.value ? 'есть' : 'нету'}</p>
                <p><b>Рефрежираторные риски:</b> {formData.refrisks.value ? 'есть' : 'нету'}</p>
                <p><b>Способ перевозки:</b> {formData.ride.value.label}</p>
                <p><b>Тип упаковки:</b> {formData.packaging.value}</p>
                <p><b>Вес:</b> {formData.weight.value} кг</p>
                <p><b>Марка:</b> {formData.mark.value}</p>
                <p><b>TTH:</b> {formData.transDoc.value}</p>
                {formData.overloadAddress_1.value?.value ? (
                  <p>
                    <b>Пункты перегрузки: </b>
                    {formData.overloadAddress_1.value?.value ? `${formData.overloadAddress_1.value?.value}; ` : null}
                    {formData.overloadAddress_2.value?.value ? `${formData.overloadAddress_2.value?.value}; ` : null}
                    {formData.overloadAddress_3.value?.value ? `${formData.overloadAddress_3.value?.value}; ` : null}
                    {formData.overloadAddress_4.value?.value ? `${formData.overloadAddress_4.value?.value}; ` : null}
                    {formData.overloadAddress_4.value?.value ? formData.overloadAddress_5.value?.value : null}
                  </p>
                ) : null}
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Контактная информация</span></td>
              <td className="data_table__row_content">
                <p><b>Телефон:</b> {formData.contactPhone.value}</p>
                <p><b>Email:</b> {formData.contactEmail.value}</p>
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div className="sticky-top search_results__sidebar page--search js-search_results__sidebar">
        <div className="search_results__info mt-0">
          <div className="product_block">
            <div className="product_block__title">
              <div className="product_block__title-pay">{parseInt(chosenOffer.price, 10) ? chosenOffer.price : '-'} {formData.valute.value.label}</div>
              <span className="product_block__title-name">Страхование грузов</span>
            </div>
            <div className="product_block__body">
              <div className="product_block__image">
                <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo}`} width="142" height="62" alt="" />
                <div className="company_item__star">
                  {[...Array(companies[chosenOffer.companyCode].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </div>
              <div className="product_block__info">
                <div className="product_block__info_title">Стоимость полиса</div>
                <dl className="description_list">
                  <dt className="color-light">
                    {draftInfo.type === 'cargoInsurance' ? 'Страхование грузов' : null}
                  </dt>
                  <dd><b>{parseInt(chosenOffer.price, 10) ? chosenOffer.price : '-'} {formData.valute.value.label}</b></dd>
                </dl>
                <div className="product_block__info_footer">
                  <dl className="description_list">
                    <dt className="color-light">Итого:</dt>
                    <dd><b>{parseInt(chosenOffer.price, 10) ? chosenOffer.price : '-'}  {formData.valute.value.label}</b></dd>
                  </dl>
                </div>
              </div>
              <div className="product_block__footer">
                {loadingFinalResult
                  ? (<><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Выполняется финальный расчет</>)
                  : null}
                {loadingPaymentLink
                  ? (<><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Получение ссылки на оплату</>)
                  : null}
                {loadingAgreement.loading
                  ? (<><LoadingSpinner type="spin" className="loading-circle mr-1 d-inline-block mb-2" height={37} width={37} />Отправка на согласование</>)
                  : null}
                {!loadingPaymentLink && !loadingFinalResult
                  ? (
                    errorMessage ? (
                      <div>
                        {chosenOffer.agreement && !criticalErrorMessage ? (
                          <>
                            {!loadingAgreement.send && !loadingAgreement.loading ? (
                              <button
                                type="button"
                                onClick={submitPayment}
                                className="btn custom-btn mb-2"
                              >
                                Отправить на согласование
                              </button>
                            ) : !loadingAgreement.loading ? (<label>Полис отправлен на согласование!</label>) : null}
                          </>
                        ) : null}
                        <div className="error_block">
                          {errorMessage}
                        </div>
                      </div>
                    ) : (
                      chosenOffer.companyCode === 'alpha-retail' && paymentInfo ? (
                        <div className="info_block">{paymentInfo}</div>
                      ) : (
                        policy.is_demo ? (
                          <a type="button" rel="noopener noreferrer" onClick={(e) => e.preventDefault()} className="btn custom-btn mr-1">Оплатить</a>
                        ) : (
                          paymentLink ? (
                            <>
                              <div className="d-flex flex-column">
                                <a
                                  type="button"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={paymentLink}
                                  onClick={submitPayment}
                                  className="btn custom-btn mr-1 w-100"
                                >Оплата по Счёту
                                </a>
                                {paymentLinkCard ? (
                                  <a
                                    type="button"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={paymentLinkCard}
                                    onClick={submitPayment}
                                    className="btn btn-primary mr-1 w-100"
                                  >Банковской картой
                                  </a>
                                ) : null}
                              </div>
                              <Tooltip
                                animation="zoom"
                                placement="top"
                                visible={visibleTooltipCopyToClipboard}
                                overlay={<span>Ссылка скопирована в буфер обмена</span>}
                                trigger=""
                                overlayClassName="custom-tooltip"
                              >
                                <CopyToClipboard text={paymentLinkCard}>
                                  <button
                                    type="button"
                                    title="Скопировать ссылку на оплату"
                                    onClick={copyToClipBoard}
                                    className="btn btn-outline btn-square sq mr-1"
                                  >
                                    <FontAwesomeIcon icon={faClone} className="fa-fw" />
                                  </button>
                                </CopyToClipboard>
                              </Tooltip>
                              <button
                                type="button"
                                title="Отправить ссылку на оплату"
                                onClick={() => setOpenModalSendLinkToEmail(true)}
                                className="btn btn-outline btn-square"
                              >
                                <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                              </button>
                            </>
                          ) : (
                            <a
                              type="button"
                              rel="noopener noreferrer"
                              target="_blank"
                              onClick={getPaymentLink}
                              className="btn custom-btn mr-1"
                            >Получить ссылку на оплату
                            </a>
                          )
                        )
                      )
                    )
                  ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
