import React, { useContext } from 'react';
import ModalInfoWindow from './ModalInfoWindow';
import { ModalMessagesContext } from '../ContextsServices/ModalMessagesService';

export default function ModalInfoList() {
  const messageContext = useContext(ModalMessagesContext);

  return (
    <div className="modal-info-box">
      {messageContext.messages.map((item) => (
        <ModalInfoWindow message={item.text} timeout={item.timeout} key={item.id} id={item.id} isActive={item.isActive} type={item.type} />
      ))}
    </div>
  );
}
