import React, { Component } from 'react';
import {
  format, parseISO,
} from 'date-fns';
import ru from 'date-fns/locale/ru';
import parse from 'html-react-parser';
import { animateScroll as scroll } from 'react-scroll';
import ReactPaginate from 'react-paginate';
import draftToHtml from 'draftjs-to-html';
import {
  MagnifyingGlassIcon, CheckIcon, EnvelopeIcon, EnvelopeOpenIcon, XMarkIcon, MinusIcon, EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import Tippy from '@tippyjs/react';
import { customFetch } from '../Utils/Helpers';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();
const perPage = 10;

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      allNews: [],
      pageCount: 0,
      offset: 0,
      selectedTag: '',
      forcePage: null,
      search: '',
      allNew: false,
      typingTimeout: null,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('tag');
    if (myParam) {
      this.setState({
        selectedTag: myParam,
      }, () => {
        this.loadNews();
      });
    } else {
      this.loadNews();
      this.getAllNews();
    }
  }

  getAllNews = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      selectedTag, search, allNew,
    } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news?type=main_block&offset=${0}&limit=${Number.MAX_SAFE_INTEGER}&tag=${selectedTag}&search=${search}&all_new=${allNew}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          allNews: data.news,
        }, () => {
          this.scrollToTop();
          this.setState({ typingTimeout: null });
        });
      });
  };

  openNews = (e, id) => {
    if (window.innerWidth > 767) {
      if (e.target.className !== 'news-container__tag-link'
          && e.target.className !== 'news-container__badge'
          && e.target.className !== 'visually-hidden'
          && typeof e.target.className !== 'object') {
        const { history } = this.props;
        history.push(`news/${id}`);
      }
    }
    return false;
  };

  openNewsInMobile = (e, id) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(`news/${id}`);
  };

  handlePageClick = (data) => {
    const { selected } = data;
    const offset = Math.ceil(selected * perPage);
    this.setState({
      offset,
      forcePage: selected,
    }, () => {
      this.loadNews();
    });
  };

  handleChangeAllNew = (e) => {
    this.setState({
      allNew: e.target.checked,
    }, () => {
      this.loadNews();
    });
  }

  changeReadEveryNews = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { news } = this.state;
    const { userInfo } = this.props;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news/${news[0].id}/update`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          readAll: true,
          agentId: userInfo.id,
        },
      ),
    })
      .then(() => this.loadNews());
  }

  handleChangeFilter = (e) => {
    const { value } = e.target;
    this.setState({ search: value });

    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      typingTimeout: setTimeout(() => {
        this.loadNews(value);
      }, 800),
    });
  }

  changeNewIsRead = (read) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const dataChecked = [];
    const { allNews, news } = this.state;
    const { userInfo } = this.props;

    allNews.forEach((value) => {
      if (value.checked) {
        dataChecked.push({ id: value.id, checked: read, agent_id: userInfo.id });
      }
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news/${news[0].id}/update`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          checks: JSON.stringify(dataChecked),
        },
      ),
    })
      .then(() => this.loadNews())
      .finally(() => {
        this.changeAllCheckNews(false);
      });
  }

  loadNews = () => {
    abortController.abort();
    abortController = new AbortController();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      offset, selectedTag, search, allNew,
    } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news?type=main_block&offset=${allNew ? 0 : offset}&limit=${perPage}&tag=${selectedTag}&search=${search}&all_new=${allNew}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          news: data.news,
          pageCount: Math.ceil(data.total_count / perPage),
        }, () => {
          this.scrollToTop();
          this.setState({ typingTimeout: null });
        });
      });
  };

  changeCheckNews = (id, e) => {
    const { allNews } = this.state;
    this.setState({
      allNews: [...allNews.map((value) => {
        if (value.id === id) {
          value.checked = e.target.checked;
        }
        return value;
      })],
    });
  }

  changeAllCheckNews = (checks = true) => {
    const { allNews } = this.state;
    this.setState({
      allNews: [...allNews.map((value) => {
        value.checked = checks;
        return value;
      })],
    });
  }

  getNewsByTag = (e, tag) => {
    e.preventDefault();
    let { selectedTag } = this.state;
    selectedTag = selectedTag ? selectedTag.split(',') : [];
    selectedTag.push(tag);
    this.setState({
      selectedTag: selectedTag.join(','),
      offset: 0,
      forcePage: 0,
    }, () => {
      this.loadNews();
    });
  };

  deleteNewsByTag = (e, tag) => {
    e.preventDefault();
    let { selectedTag } = this.state;
    selectedTag = selectedTag ? selectedTag.split(',') : [];
    selectedTag.splice(selectedTag.indexOf(tag), 1);
    this.setState({
      selectedTag: selectedTag.join(','),
      offset: 0,
      forcePage: 0,
    }, () => {
      this.loadNews();
    });
  };

  scrollToTop = () => {
    const { forcePage } = this.state;
    if (forcePage !== null) {
      scroll.scrollToTop();
    }
  };

  render() {
    const {
      news, pageCount, forcePage, search, selectedTag, allNew, typingTimeout, allNews,
    } = this.state;
    const { userInfo } = this.props;

    const newsBlock = news ? news.map((currentNews) => {
      const tags = currentNews.tags ? currentNews.tags.split(',').map((tag) => (
        <a
          className="news-container__tag-link"
          key={tag}
          href="#"
          onClick={(e) => this.getNewsByTag(e, tag)}
        >#{tag}
        </a>
      )) : null;
      const firstParagraph = JSON.parse(currentNews.txt);
      firstParagraph.blocks.splice(1);
      return (
        <div key={currentNews.id} className="row" onClick={(e) => this.openNews(e, currentNews.id)}>
          <div className="news-container">
            <div className="news-container-card">
              <div className="custom-checkbox-ui">
                <input
                  name={`checkbox-${currentNews.id}`}
                  type="checkbox"
                  id={`checkbox-${currentNews.id}`}
                  className="visually-hidden"
                  checked={allNews.find(({ id }) => id === currentNews.id)?.checked}
                  onChange={(e) => this.changeCheckNews(currentNews.id, e)}
                />
                <label htmlFor={`checkbox-${currentNews.id}`}>
                  <span className="custom-checkbox-ui__icon-wrapper">
                    <CheckIcon className="custom-checkbox-ui__check-icon font-weight-bold" color="#fff" width={20} height={20} strokeWidth={1} stroke="#fff" />
                  </span>
                  <span className="custom-checkbox-ui__label" />
                </label>
              </div>
            </div>
            <div className="news-container-card text-break">
              <h2 className="news-container__title">{currentNews.title}</h2>
              <div className="news-container__text">{parse(draftToHtml(firstParagraph))}</div>
            </div>
            <div className="news-container__tag">
              <div className="news-container__tag-date">{format(parseISO(currentNews.date_start), 'dd MMMM yyyy', { locale: ru })}</div>
              <div className={`news-container__badge ${(currentNews.news_is_read.length === 0 && userInfo.created_at < currentNews.created_at) || tags ? '' : 'd-none'}`}>
                {(currentNews.news_is_read.length === 0 && userInfo.created_at < currentNews.created_at)
                  ? <div className="news-container__tag-new">Новая</div> : null}
                {tags}
              </div>
            </div>
            <div className="card__check-btns">
              <div className="custom-check-btn">
                <input
                  className="custom-check-btn__input visually-hidden"
                  id={`checkbox-${currentNews.id}`}
                  type="checkbox"
                  name="checkBtn"
                  onChange={(e) => this.changeCheckNews(currentNews.id, e)}
                  checked={allNews.find(({ id }) => id === currentNews.id)?.checked}
                />
                <label htmlFor={`checkbox-${currentNews.id}`}>
                  <div className="custom-check-btn__wrapper">
                    {allNews.find(({ id }) => id === currentNews.id)?.checked ? (
                      <>
                        <i className="border-0 bg-transparent mr-1 custom-check-btn__icon-wrapper"><CheckIcon color="#737373" width={20} height={20} /></i>
                        <span className="custom-check-btn__label">Выбрано</span>
                      </>
                    ) : (
                      <>
                        <i className="border-0 bg-transparent mr-1 custom-check-btn__icon-wrapper"><MinusIcon color="#737373" width={20} height={20} /></i>
                        <span className="custom-check-btn__label">Выбрать</span>
                      </>
                    )}
                  </div>
                </label>
              </div>
              <button className="btn btn--green-border" type="button" onClick={(e) => this.openNewsInMobile(e, currentNews.id)}><span className="btn__title">Читать</span>
              </button>
            </div>
          </div>
        </div>
      );
    }) : null;
    return (
      <div className="page-news">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h1 className="page-wrapper__title">Новости</h1>
          <div className="toggle toggle__header">
            <span className="toggle__title">Только новые</span>
            <label className="toggle__switch" htmlFor="toggle-id">
              <input type="checkbox" id="toggle-id" name="toggle-name" onChange={(e) => this.handleChangeAllNew(e)} checked={allNew} />
              <span className="toggle__slider">
                <span className="toggle__slider-point" />
              </span>
            </label>
          </div>
        </div>
        <div className="search-block__wrapper">
          {allNews.find((element) => element.checked) ? (
            <div className="modal-bottom-btns">
              <div className="modal-bottom-btns__wrapper">
                <p className="modal-bottom-btns__title">Выбрано {allNews.filter((element) => element.checked).length} новости</p>
                <div className="modal-bottom-btns__btns">
                  <button type="button" className="btn btn--gray" onClick={() => this.changeNewIsRead(true)}>
                    <i className="icon border-0 bg-transparent mr-2 pb-1"><EnvelopeOpenIcon
                      color="#fff"
                      width={20}
                      height={20}
                    />
                    </i>
                    <span>Отметить как прочитанное</span>
                  </button>
                  <button type="button" className="btn btn--gray" onClick={() => this.changeNewIsRead(false)}>
                    <i className="icon border-0 bg-transparent mr-2 pb-1"><EnvelopeIcon
                      color="#fff"
                      width={20}
                      height={20}
                    />
                    </i>
                    <span>Отметить как непрочитанное</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn--gray"
                    onClick={() => this.changeAllCheckNews(false)}
                  >Отмена
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="search-block__item search-block__item--search">
            <div className="search-block__input">
              <input
                type="text"
                onChange={(e) => this.handleChangeFilter(e)}
                value={search}
                placeholder="Поиск новостей по ключевым словам"
              />
              <i className="icon border-0 bg-transparent"><MagnifyingGlassIcon color="#737373" width={20} height={20} /></i>
            </div>
          </div>
          <div className="search-block__item search-block__item--toggle">
            <div className="toggle toggle__search"><span className="toggle__title">Только новые</span>
              <label className="toggle__switch" htmlFor="toggle-id">
                <input type="checkbox" id="toggle-id" name="toggle-name" checked={allNew} />
                <span className="toggle__slider" onChange={(e) => this.handleChangeAllNew(e)}><span className="toggle__slider-point" /></span>
              </label>
            </div>
          </div>
          <Tippy
            placement="bottom-start"
            interactive
            trigger="click"
            className="page-popup page-popup--agents-list"
            arrow={false}
            offset={[0, 12]}
            onShown={(instance) => {
              document.querySelector('[data-tippy-root]').addEventListener('click', () => {
                instance.hide();
              });
            }}
            content={(
              <div className="popup is-active popup--right popup--action popup--search-news">
                <div className="popup__wrapper">
                  <ul className="popup__list">
                    <li className="popup__item">
                      <button className="btn popup__button" type="button" onClick={this.changeReadEveryNews}>
                        <span className="btn__title">Отметить все новости прочитанными</span>
                        <i className="border-0 bg-transparent">
                          <EnvelopeOpenIcon color="#fff" width={18} height={18} />
                        </i>
                      </button>
                    </li>
                    <li className="popup__item">
                      <button className="btn popup__button" type="button" onClick={this.changeAllCheckNews}>
                        <span className="btn__title">Выбрать все</span>
                        <i className="border-0 bg-transparent">
                          <CheckIcon color="#fff" width={18} height={18} strokeWidth={1} stroke="#fff" />
                        </i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          >
            <div className="search-block__item search-block__item--btn btn pr-0 d-flex flex-row">
              <button
                className="btn btn--square-icon-notext btn--open-popup is-more"
                type="button"
              >
                <i className="border-0 bg-transparent">
                  <EllipsisHorizontalIcon color="#737373" width={20} height={20} strokeWidth={1} stroke="#737373" />
                </i>
              </button>
            </div>
          </Tippy>
          <div className="search-block__item search-block__item--btn pr-0 d-flex flex-row">
            <button
              type="button"
              className="search-block__item--btn-custom btn d-flex align-items-center mr-1"
              onClick={this.changeAllCheckNews}
            >
              <i className="border-0 bg-transparent mb-1">
                <CheckIcon color="#737373" width={18} height={18} strokeWidth={1} stroke="#737373" />
              </i>
              <span className="ml-2">Выбрать все</span>
            </button>
            <button
              type="button"
              className="search-block__item--btn-custom btn d-flex align-items-center"
              onClick={this.changeReadEveryNews}
            >
              <i className="border-0 bg-transparent mb-1">
                <EnvelopeOpenIcon color="#737373" width={18} height={18} />
              </i>
              <span className="ml-2">Отметить все новости прочитанными</span>
            </button>
          </div>
        </div>
        {selectedTag ? (
          <div className="search-block__input-badge">
            {selectedTag.split(',').map((value) => (
              <div className="search-badge">
                <span>{value}</span>
                <button type="button" className="btn"><XMarkIcon
                  width={20}
                  height={20}
                  color="#737373"
                  onClick={(e) => this.deleteNewsByTag(e, value)}
                />
                </button>
              </div>
            ))}
          </div>
        ) : null}
        <LoadingBanner loadingFlag={typingTimeout}>
          {newsBlock}
          <ReactPaginate
            previousLabel="предыдущая"
            nextLabel="следующая"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={this.handlePageClick}
            containerClassName="pagination mt-3"
            subContainerClassName="pages pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            nextClassName="page-item"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            activeClassName="active"
            forcePage={forcePage}
          />
        </LoadingBanner>
      </div>
    );
  }
}

export default withUserInfoStoreStore(News);
