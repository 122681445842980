import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import { customFetch } from '../Utils/Helpers';
import DraftDemoData from './DraftDemoData';
//import AnalyticsDemoData from './AnalyticsDemoData';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';

export default function DemoDataAdmin(props) {
  const { history } = props;
  const [activeTab, setActiveTab] = useState('1');
  const [insuranceTypeList, setInsuranceTypeList] = useState([]);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const loadInsuranceTypeList = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/demo-data/insurance-type-list`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setInsuranceTypeList(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  useEffect(() => {
    loadInsuranceTypeList();
  }, []);

  return (
    <div className="position-relative">
      <h2 className="d-inline-block mr-3">Демо-данные</h2>
      <TabNavList>
        <TabNavItem
          active={activeTab === '1'}
          onClick={() => { setActiveTab('1'); }}
          text="Черновики"
        />
        <TabNavItem
          active={activeTab === '2'}
          onClick={() => { setActiveTab('2'); }}
          text="Статистика"
        />
        <TabNavItem
          active={activeTab === '3'}
          onClick={() => { setActiveTab('3'); }}
          text="Статистика виджета"
        />
      </TabNavList>
      <TabContent animation="false" activeTab={activeTab}>
        <TabPane tabId="1">
          <DraftDemoData insuranceTypeList={insuranceTypeList} history={history} />
        </TabPane>
{/*        <TabPane tabId="2">
          <AnalyticsDemoData />
        </TabPane>
        <TabPane tabId="3">
          <AnalyticsDemoData />
        </TabPane>*/}
      </TabContent>
    </div>
  );
}
