import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import matchSorter from 'match-sorter';
import { format, parse, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import queryString from 'query-string';
import LoadingSpinner from "../Layout/LoadingSpinner";
import { customFetch, toLocalDate } from '../Utils/Helpers';

export default class AdminWidgetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetsList: [],
      filtered: [],
      loading: true,
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { location, tab } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets-list?all=true`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((widgets) => {
        const widgetsList = [];
        widgets.forEach((widget) => {
          if (widget.settings) {
            widget.type_insurance = widget.policy_type.name;
            widget.type = 'Виджет';
            widget.user_name = `${widget.agent.last_name} ${widget.agent.first_name} ${widget.agent.middle_name}`;
            widget.avtocod_limit = widget.settings.avtocod_limit;
            widget.sms_limit = widget.settings.sms_limit;
            widget.sms_timer = widget.settings.sms_timer;
            widget.avtocod_timer = widget.settings.avtocod_timer;
            widget.urlsString = '-';
            if (widget.widget_urls.length > 0) {
              widget.urlsString = widget.widget_urls[0].hosts;
            }
            widgetsList.push(widget);
          }
        });
        this.setState({
          widgetsList,
          loading: false,
        });
      });
  }

  setTableSorting = (newSort) => {
    const settings = reactLocalStorage.getObject('adminWidgetsSettings');
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
    reactLocalStorage.setObject('adminWidgetsSettings', settings);
  };

  setTableSettings = (newResized) => {
    const settings = reactLocalStorage.getObject('adminWidgetsSettings');
    newResized.forEach((item) => {
      settings[item.id] = item.value;
    });
    reactLocalStorage.setObject('adminWidgetsSettings', settings);
  };

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${this.props.tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  render() {
    const {
      widgetsList,
      loading,
      filtered,
    } = this.state;

    let defaultSortingField = 'sum_cost';
    let defaultSortingOrder = 'true';
    if (reactLocalStorage.getObject('adminWidgetsSettings').defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject('adminWidgetsSettings').defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject('adminWidgetsSettings').defaultSortingOrder;
    }

    const columns = [{
      Header: '№',
      accessor: 'id',
      maxWidth: 70,
      width: reactLocalStorage.getObject('adminWidgetsSettings').id,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_id !== false,
    },
    {
      Header: 'Пользователь',
      accessor: 'user_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['user_name'] }),
      filterAll: true,
      minWidth: 170,
      width: reactLocalStorage.getObject('adminWidgetsSettings').user_name,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_user_name !== false,
    },
    {
      Header: 'Тип',
      accessor: 'type',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').type,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_type !== false,
    },
    {
      Header: 'Продукт',
      accessor: 'type_insurance',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type_insurance'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').type_insurance,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_type_insurance !== false,
    },
    {
      Header: 'Наименование',
      accessor: 'name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').name,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_name !== false,
    },
    {
      Header: 'Лимит автокод',
      accessor: 'avtocod_limit',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['avtocod_limit'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').avtocod_limit,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_avtocod_limit !== false,
    },
    {
      Header: 'Таймер автокод',
      accessor: 'avtocod_timer',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['avtocod_timer'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').avtocod_timer,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_avtocod_timer !== false,
    },
    {
      Header: 'Лимит смс',
      accessor: 'sms_limit',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['sms_limit'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').sms_limit,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_sms_limit !== false,
    },
    {
      Header: 'Таймер смс',
      accessor: 'sms_timer',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['sms_timer'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('adminWidgetsSettings').sms_timer,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_sms_timer !== false,
    },
    {
      Header: 'Дата создания',
      id: 'created_at',
      width: reactLocalStorage.getObject('adminWidgetsSettings').created_at,
      accessor: (d) => format(toLocalDate(parseISO(d.created_at)), 'yyyy-MM-dd, HH:mm:ss'),
      filterMethod: (filter, row) => this.customFilter(filter, row),
      Filter: () => (<div className="hideFilterInput" />),
      sortMethod: (a, b) => (parse(a, 'yyyy-MM-dd, HH:mm:ss', new Date()) > parse(b, 'yyyy-MM-dd, HH:mm:ss', new Date()) ? 1 : -1),
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_created_at !== false,
    },
    {
      Header: 'Дата изменения',
      accessor: 'updated_at',
      Filter: () => (<div className="hideFilterInput" />),
      width: reactLocalStorage.getObject('adminWidgetsSettings').updated_at,
      Cell: (row) => <div>{ format(toLocalDate(parseISO(row.original.updated_at)), 'yyyy-MM-dd, HH:mm:ss') }</div>,
      show: reactLocalStorage.getObject('adminWidgetsSettings').show_updated_at !== false,
    },
    {
      Header: 'Адреса страниц',
      accessor: 'urlsString',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['urlsString'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('widgetListSettings').urls,
      show: reactLocalStorage.getObject('widgetListSettings').show_urls !== false,
    }];

    return (
      <>
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    this.props.history.push(`/admin/widgets/${rowInfo.row._original.id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/admin/widgets/${rowInfo.row._original.id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          ref={(r) => this.reactTable = r}
          className="table"
          defaultFiltered={filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          data={widgetsList}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            this.setTableSorting(newSort);
          }}
          onResizedChange={(newResized) => {
            this.setTableSettings(newResized);
          }}
          minRows={1}
        />
      </>
    );
  }
}
