import React from 'react';
import styled from 'styled-components';

export const LinesContainer = styled.div`
  margin-top: 16px;
`;

export const LinesGrid = styled.div`
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
`;

export default function ProgressBarLines(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <LinesContainer disabled={disabled} width={width}>
      <LinesGrid>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </LinesGrid>
    </LinesContainer>
  );
}
