import React, { Component } from 'react';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default class WidgetAnalyticsIntegrationRoistat extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      showMenu,
      roistatProjectId,
      sendUrl,
      toggleFlag,
      changeWidgetAnalyticsIntegrationId,
      updateWidgetWithInfo,
      widget,
    } = this.props;

    const marginInLine = { marginLeft: '10px' };

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку систем аналитики</LinkBreadcrumbs>
            </div>
          </div>
          <div className="card">
            <div className="form-group row">
              <div className="col-lg-12">
                <FormNotificationColorLine>
                  <p>Для корректной работы необходимо указать id проекта и добавить следующие события в настройках вашего аккаунта Roistat:</p>
                  <ul>
                    {widget.policy_type.code === 'osago' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Id:</strong> phoneValidate_widgetOsago</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Id:</strong> createQuote_widgetOsago</li>
                        <li><strong>Название:</strong> Автомобиль заполнен, <strong style={marginInLine}>Id:</strong> carFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Страхователь заполнен, <strong style={marginInLine}>Id:</strong> insuranceFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Собственник заполнен, <strong style={marginInLine}>Id:</strong> ownerFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Водитель заполнен, <strong style={marginInLine}>Id:</strong> driverFilled_widgetOsago</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Id:</strong> getResult_widgetOsago</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Id:</strong> chooseInsurance_widgetOsago</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Id:</strong> buyPolicy_widgetOsago</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Id:</strong> paymentPolicy_widgetOsago</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'mortgage' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Id:</strong> phoneValidate_widgetMortgage</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Id:</strong> createQuote_widgetMortgage</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Id:</strong> getResult_widgetMortgage</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Id:</strong> chooseInsurance_widgetMortgage</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Id:</strong> buyPolicy_widgetMortgage</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Id:</strong> paymentPolicy_widgetMortgage</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'greenCard' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Id:</strong> getResult_widgetGreenCard</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Id:</strong> chooseInsurance_widgetGreenCard</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Id:</strong> buyPolicy_widgetGreenCard</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Id:</strong> paymentPolicy_widgetGreenCard</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dkp' ? (
                      <>
                        <li><strong>Название:</strong> Переход к оформлению ДКП, <strong style={marginInLine}>Id:</strong> checkDocumentData_widgetDKP</li>
                        <li><strong>Название:</strong> Оформление ДКП, <strong style={marginInLine}>Id:</strong> getDocument_widgetDKP</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'kbm' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки КБМ, <strong style={marginInLine}>Id:</strong> getKbm_widgetKbm</li>
                        <li><strong>Название:</strong> Отправлен запрос на восстановление КБМ, <strong style={marginInLine}>Id:</strong> refreshKbm_widgetKbm</li>
                        <li><strong>Название:</strong> Переход к оформлению ОСАГО, <strong style={marginInLine}>Id:</strong> getOsago_widgetKbm</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'property' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки недвижимости, <strong style={marginInLine}>Id:</strong> getProperty_widgetProperty</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dmsStudent' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Id:</strong> getResult_widgetDmsStudent</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Id:</strong> chooseInsurance_widgetDmsStudent</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Id:</strong> buyPolicy_widgetDmsStudent</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Id:</strong> paymentPolicy_widgetDmsStudent</li>
                      </>
                    ) : null}
                  </ul>
                </FormNotificationColorLine>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="widgetRoistatId">Id проекта в Roistat</label>
                <input value={roistatProjectId} onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'roistatProjectId')} onBlur={updateWidgetWithInfo} name="widgetRoistatId" type="text" className="form-control" id="widgetRoistatId" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-check custom-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                  <input onChange={toggleFlag} checked={sendUrl} name="roistatSendUrl" className="form-check-input checkbox-styled" type="checkbox" id="roistatSendUrl" />
                  <label className="form-check-label" htmlFor="roistatSendUrl">
                    Отправлять вместе с событием адрес страницы виджета
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
