import React, {
  useContext, createRef, useState, useEffect,
} from 'react';
import { format, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  getDefaultSorting7, onFilteredChangeCustom, onTableCellClick,
  setTableSettings7,
  setTableSorting,
} from '../Utils/Helpers';
import Table from '../Layout/Table';

export default function AdminFaqKnowledgeTable(props) {
  const { history } = props;
  const tableRef = createRef();
  const defaultSorting = getDefaultSorting7('faqKnowledgeTableSettings');
  const [filtered, setFiltered] = useState([]);
  const [adminCourses, setAdminCourses] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingAdminCoursesFlag, setLoadingAdminCoursesFlag] = useState(false);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const getAdminCourses = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingAdminCoursesFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setAdminCourses(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingAdminCoursesFlag(false);
      });
  };

  const deleteCourse = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingAdminCoursesFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setAdminCourses(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingAdminCoursesFlag(false);
      });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'ID',
      accessor: 'id',
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').id,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Категория',
      accessor: 'categories.name',
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').categories,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_categories !== false,
      showCustomized: true,
    }, {
      Header: 'Вопрос',
      accessor: 'question',
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').question,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_guestion !== false,
      showCustomized: true,
    }, {
      Header: 'Загрузил контент ',
      accessor: (d) => (d.creater ? `${d.creater.last_name} ${d.creater.first_name} ${d.creater.middle_name}` : ''),
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').creater,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_creater !== false,
      showCustomized: true,
    }, {
      Header: 'Обновил контент',
      accessor: (d) => (d.updater ? `${d.updater.last_name} ${d.updater.first_name} ${d.updater.middle_name}` : ''),
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').reading_time,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_reading_time !== false,
      showCustomized: true,
    }, {
      Header: 'Дата создания',
      accessor: (d) => (d.created_at ? format(parseISO(d.created_at), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').created_at,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Дата обновления',
      accessor: 'updated_at',
      Cell: ({ row }) => format(parseISO(row.original.updated_at), 'dd.MM.yyyy, HH:mm:ss'),
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').updated_at,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_updated_at !== false,
      showCustomized: true,
    }, {
      Header: 'Показывается',
      accessor: 'show',
      width: reactLocalStorage.getObject('faqKnowledgeTableSettings').show,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_show !== false,
      showCustomized: true,
      Cell: ({ row }) => {
        if (row.original.show === 1) {
          return 'Да';
        }
        return 'Нет';
      },
    }, {
      id: 'delete_button',
      Header: '',
      width: 40,
      filterable: false,
      show: reactLocalStorage.getObject('faqKnowledgeTableSettings').show_delete_button !== false,
      showCustomized: true,
      Cell: ({ row }) => (
        <div title="Удалить новость"><FontAwesomeIcon onClick={() => deleteCourse(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    setColumns(updatedColumns);
  };

  useEffect(() => {
    getAdminCourses();
    updateTableColumns();
  }, []);

  return (
    <>
      <button type="button" className="btn btn-success mb-4" onClick={() => history.push('/faq_answer/add')}>Добавить FAQ</button>
      <Table
        onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'faq_answer', history)}
        ref={tableRef}
        defaultSorted={defaultSorting}
        defaultFiltered={filtered}
        data={adminCourses}
        columns={columns}
        loading={loadingAdminCoursesFlag}
        noDataText="Статьи не найдены"
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'faqKnowledgeTableSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'faqKnowledgeTableSettings');
        }}
        onFilteredChange={(value) => setFiltered(onFilteredChangeCustom(value))}
      />
    </>
  );
}
