import React, { Component } from 'react';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

class WidgetCRMAmo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      auth_key: '',
      type: 'amo',
      client_id: '',
      client_secret: '',
      redirect_url: '',
    };
  }

  componentDidMount() {
    this.loadCRMInfo();
  }

  loadCRMInfo = () => {
    const { widgetId } = this.props;
    const { type } = this.state;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/crm-info?&type=${type}&widgetId=${widgetId}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let domain = '';
        let auth_key = '';
        let client_id = '';
        let client_secret = '';
        let redirect_url = '';
        data.forEach((dataRow) => {
          if (dataRow['field_name'] === 'domain') {
            domain = dataRow.value;
          }
          if (dataRow['field_name'] === 'auth_key') {
            auth_key = dataRow.value;
          }
          if (dataRow['field_name'] === 'client_id') {
            client_id = dataRow.value;
          }
          if (dataRow['field_name'] === 'client_secret') {
            client_secret = dataRow.value;
          }
          if (dataRow['field_name'] === 'redirect_url') {
            redirect_url = dataRow.value;
          }
        });
        this.setState({
          domain,
          auth_key,
          client_id,
          client_secret,
          redirect_url,
        });
      });
  };

  saveCrmInfo = () => {
    const { widgetId } = this.props;
    const { showModalInfo } = this.context;
    const {
      domain,
      auth_key,
      client_id,
      client_secret,
      redirect_url,
      type,
    } = this.state;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/crm-info`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        widgetId,
        type,
        fields: {
          domain,
          auth_key,
          client_id,
          client_secret,
          redirect_url,
        },
      }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Изменения сохранены');
      });
  };

  handleChangeInput = (e, fieldName) => {
    const { target } = e;
    this.setState({ [fieldName]: target.value });
  };

  render() {
    const {
      showMenu,
      activeFlag,
      changeCRMIntegrationActive,
    } = this.props;

    const {
      domain,
      auth_key,
      client_id,
      client_secret,
      redirect_url,
    } = this.state;

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку CRM</LinkBreadcrumbs>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <div className="form-check custom-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                <input onChange={(e) => changeCRMIntegrationActive(e, 'amo')} checked={activeFlag} name="widgetBitrix24ActiveFlag" className="form-check-input checkbox-styled" type="checkbox" id="widgetBitrix24ActiveFlag" />
                <label className="form-check-label" htmlFor="widgetBitrix24ActiveFlag">
                  Активная интеграция
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="widgetAmoDomain">Домен</label>
              <input value={domain} onChange={(e) => this.handleChangeInput(e, 'domain')} onBlur={this.saveCrmInfo} name="widgetAmoDomain" type="text" className="form-control" id="widgetAmoDomain" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="widgetAmoAuthKey">Код авторизации</label>
              <input value={auth_key} onChange={(e) => this.handleChangeInput(e, 'auth_key')} onBlur={this.saveCrmInfo} name="widgetAmoAuthKey" type="text" className="form-control" id="widgetAmoAuthKey" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="widgetAmoClientId">ID интеграции</label>
              <input value={client_id} onChange={(e) => this.handleChangeInput(e, 'client_id')} onBlur={this.saveCrmInfo} name="widgetAmoClientId" type="text" className="form-control" id="widgetAmoClientId" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="widgetAmoClientSecret">Секретный ключ</label>
              <input value={client_secret} onChange={(e) => this.handleChangeInput(e, 'client_secret')} onBlur={this.saveCrmInfo} name="widgetAmoToken" type="text" className="form-control" id="widgetAmoClientSecret" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="widgetAmoRedirectUrl">Url редиректа</label>
              <input value={redirect_url} onChange={(e) => this.handleChangeInput(e, 'redirect_url')} onBlur={this.saveCrmInfo} name="widgetAmoRedirectUrl" type="text" className="form-control" id="widgetAmoRedirectUrl" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

WidgetCRMAmo.contextType = ModalMessagesContext;

export default WidgetCRMAmo;
