import React, {
  useContext,
} from 'react';
import PaymentRealizationsTable from './PaymentRealizationsTable';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../../Utils/Helpers';

export default function PaymentRealizations(props) {
  const { history } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);

  const createPayment = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/paymentRealizations`, {
      method: 'POST',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          history.push(`paymentRealizations/${response.id}`);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
      });
  };

  return (
    <>
      <button type="button" className="btn btn-success mb-3" onClick={createPayment}>Создать реализацию</button>
      <PaymentRealizationsTable history={history} />
    </>
  );
}
