import React, {
  useEffect, useState, useContext, useMemo, forwardRef, useImperativeHandle, useRef,
} from 'react';
import {
  animateScroll as scroll, scroller,
} from 'react-scroll';
import queryString from 'query-string';
import {
  customFetch, getDeepObjectValue, setDeepObjectValue, validateFields,
} from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import UniversalForm from './UniversalForm';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

function UniversalFormContainer(props, ref) {
  const {
    policyStatus,
    policy,
    copyDraft,
    match,
    location,
    loadPolicy,
    history,
  } = props;

  const optionType = [
    { value: 'natural', label: 'Физ. лицо' },
    { value: 'individual', label: 'Индивидуальный предприниматель' },
    { value: 'legal', label: 'Юр. лицо' },
  ];

  const ownerOptionType = [
    { value: 'natural', label: 'Физ. лицо' },
    { value: 'individual', label: 'Индивидуальный предприниматель' },
    { value: 'legal', label: 'Юр. лицо' },
  ];

  const productType = [
    { value: 'dago', label: 'ДАГО' },
    { value: 'vzr', label: 'ВЗР' },
    { value: 'ifl', label: 'ИФЛ' },
    { value: 'dmsStudent', label: 'ДМС' },
    { value: 'telemedicine', label: 'Телемедицина' },
    { value: 'ns', label: 'Несчастный случай' },
    { value: 'gruz', label: 'Грузы' },
    { value: 'infullBroker', label: 'Услуга INFULL - Ипотечный Брокер' },
    { value: 'infullLizing', label: 'Услуга INFULL - Лизинг' },
  ];

  const urlParams = useMemo(() => queryString.parse(location.search), []);

  const insurerAddressRegistrationRef = useRef();
  const ownerAddressRegistrationRef = useRef();
  const regionRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [errorBlockName, setErrorBlockName] = useState('');
  const [loadingUniversalInfo, setLoadingUniversalInfo] = useState(true);
  const [disableField, setDisableField] = useState(false);

  const [formData, setFormData] = useState({
    insurerLastName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    insurerFirstName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    insurerMiddleName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    insurerPassport: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportNumberSerie',
    },
    insurerBirthday: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    insurerPassportDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    insurerTypeCode: {
      value: optionType[0],
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    insurerPassportDivision: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    insurerPassportUnitNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportUnitNumber',
    },
    insurerAddressRegistration: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insurerINN: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'inn',
    },
    insurerSnils: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'snils',
    },
    insurerCompanyName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    insurerOGRN: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'ogrn',
    },
    insurerKPP: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'kpp',
    },
    insurerLegalAdress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insurerActualAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insurerAddressRegistrationFlag: {
      value: false,
    },
    //---------------------------------------------------
    ownerLastName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    ownerFirstName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    ownerMiddleName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    ownerPassport: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportNumberSerie',
    },
    ownerBirthday: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    ownerPassportDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    ownerTypeCode: {
      value: ownerOptionType[0],
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    ownerPassportDivision: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    ownerPassportUnitNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportUnitNumber',
    },
    ownerAddressRegistration: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    ownerINN: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'inn',
    },
    ownerSnils: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'snils',
    },
    ownerCompanyName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    ownerOGRN: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'ogrn',
    },
    ownerKPP: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'kpp',
    },
    ownerLegalAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    ownerActualAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    ownerAddressRegistrationFlag: {
      value: false,
    },
    //---------------------------------------------------
    listProductType: {
      value: productType[0],
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    insuranceObject: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    endDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    region: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'region',
    },
  });
  const [scrollTo, setScrollTo] = useState('');

  const postProcessingDate = (value, fieldName, errorMessage) => {
    let resetErrorMessage = errorMessage;
    if (fieldName === 'startDate' && formData.startDate.value && formData.endDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть больше даты окончания';
    } else if (fieldName === 'endDate' && formData.endDate.value && formData.startDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть меньше даты начала';
    }
    return resetErrorMessage;
  };

  const postProcessingAddress = (name) => {
    let registrationAddressFlag = formData.ownerAddressRegistrationFlag.value;
    if (name === 'insurerActualAddress') {
      registrationAddressFlag = false;
    }

    setFormData((prev) => ({
      ...prev,
      ownerAddressRegistrationFlag: {
        ...prev.ownerAddressRegistrationFlag,
        value: registrationAddressFlag,
      },
    }));
  };

  const handleValidate = (e, args = {}) => {
    let fieldName = '';
    if (e) {
      const { target } = e;
      fieldName = target.id;
    } else {
      fieldName = args.fieldName;
    }
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);

    if (value === undefined) {
      value = {
        error: '',
        data: '',
      };
    }

    let errorMessage = value.error;
    value = value.data;
    if (errorMessage) {
      errorMessage = value.error;
    } else {
      errorMessage = getValidation(value, formData[fieldName].validationType);

      if (fieldName === 'multiSelect' && (formData[fieldName].value === null || !formData[fieldName].value.length)) {
        errorMessage = 'Выберите хотя бы один вариант';
      }

      if (fieldName === 'startDate'
        || fieldName === 'endDate') {
        errorMessage = postProcessingDate(value, fieldName, errorMessage);
      }

      if (fieldName === 'insurerActualAddress'
        || fieldName === 'ownerActualAddress'
        || fieldName === 'ownerAddressRegistration') {
        postProcessingAddress();
      }
    }
    setDeepObjectValue(formData, setFormData, path, value, errorMessage);
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const makeFormDataFlatJson = () => {
    const flatJson = {};
    Object.keys(formData).forEach((key) => {
      flatJson[key] = formData[key].value;
    });

    return flatJson;
  };

  const validateForm = () => {
    const formFields = { ...formData };
    const validationResult = validateFields(formFields);
    let newErrorBlockName = '';
    newErrorBlockName = Object.keys(validationResult.result).find(((key) => validationResult.result[key].errorMessage));
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });
    setFormData(newFormData);
    if (newErrorBlockName) {
      setErrorBlockName(newErrorBlockName);
      setScrollTo('errorBlock');
    }
    return validationResult.validationSuccess;
  };

  const loadPolicyData = () => {
    const draftData = policy.form_data;

    const insurerTypeValue = draftData.insurerTypeCode && optionType.filter((option) => option.value === draftData.insurerTypeCode.value).length ? draftData.insurerTypeCode : optionType[0];
    const ownerTypeValue = draftData.ownerTypeCode && optionType.filter((option) => option.value === draftData.ownerTypeCode.value).length ? draftData.ownerTypeCode : optionType[0];
    const productValue = draftData.listProductType && productType.filter((option) => option.value === draftData.listProductType.value).length ? draftData.listProductType : productType[0];

    const data = {
      insurerLastName: {
        ...formData.insurerLastName,
        value: draftData.insurerLastName,
      },
      insurerFirstName: {
        ...formData.insurerFirstName,
        value: draftData.insurerFirstName,
      },
      insurerMiddleName: {
        ...formData.insurerMiddleName,
        value: draftData.insurerMiddleName,
      },
      insurerPassport: {
        ...formData.insurerPassport,
        value: draftData.insurerPassport,
      },
      insurerBirthday: {
        ...formData.insurerBirthday,
        value: draftData.insurerBirthday,
      },
      insurerPassportDate: {
        ...formData.insurerPassportDate,
        value: draftData.insurerPassportDate,
      },
      insurerPassportDivision: {
        ...formData.insurerPassportDivision,
        value: draftData.insurerPassportDivision,
      },
      insurerPassportUnitNumber: {
        ...formData.insurerPassportUnitNumber,
        value: draftData.insurerPassportUnitNumber,
      },
      insurerAddressRegistration: {
        ...formData.insurerAddressRegistration,
        value: draftData.insurerAddressRegistration || formData.insurerAddressRegistration.value,
      },
      insurerINN: {
        ...formData.insurerINN,
        value: draftData.insurerINN,
      },
      insurerSnils: {
        ...formData.insurerSnils,
        value: draftData.insurerSnils,
      },
      insurerCompanyName: {
        ...formData.insurerCompanyName,
        value: draftData.insurerCompanyName,
      },
      insurerOGRN: {
        ...formData.insurerOGRN,
        value: draftData.insurerOGRN,
      },
      insurerKPP: {
        ...formData.insurerKPP,
        value: draftData.insurerKPP,
      },
      insurerLegalAdress: {
        ...formData.insurerLegalAdress,
        value: draftData.insurerLegalAdress,
      },
      insurerActualAddress: {
        ...formData.insurerActualAddress,
        value: draftData.insurerActualAddress || formData.insurerActualAddress.value,
      },
      insurerAddressRegistrationFlag: {
        ...formData.insurerAddressRegistrationFlag,
        value: draftData.insurerAddressRegistrationFlag,
      },
      insurerTypeCode: {
        ...formData.insurerTypeCode,
        value: insurerTypeValue,
      },
      ownerLastName: {
        ...formData.ownerLastName,
        value: draftData.ownerLastName,
      },
      ownerFirstName: {
        ...formData.ownerFirstName,
        value: draftData.ownerFirstName,
      },
      ownerMiddleName: {
        ...formData.ownerMiddleName,
        value: draftData.ownerMiddleName,
      },
      ownerPassport: {
        ...formData.ownerPassport,
        value: draftData.ownerPassport,
      },
      ownerBirthday: {
        ...formData.ownerBirthday,
        value: draftData.ownerBirthday,
      },
      ownerPassportDate: {
        ...formData.ownerPassportDate,
        value: draftData.ownerPassportDate,
      },
      ownerPassportDivision: {
        ...formData.ownerPassportDivision,
        value: draftData.ownerPassportDivision,
      },
      ownerPassportUnitNumber: {
        ...formData.ownerPassportUnitNumber,
        value: draftData.ownerPassportUnitNumber,
      },
      ownerAddressRegistration: {
        ...formData.ownerAddressRegistration,
        value: draftData.ownerAddressRegistration || formData.ownerAddressRegistration.value,
      },
      ownerINN: {
        ...formData.ownerINN,
        value: draftData.ownerINN,
      },
      ownerSnils: {
        ...formData.ownerSnils,
        value: draftData.ownerSnils,
      },
      ownerCompanyName: {
        ...formData.ownerCompanyName,
        value: draftData.ownerCompanyName,
      },
      ownerOGRN: {
        ...formData.ownerOGRN,
        value: draftData.ownerOGRN,
      },
      ownerKPP: {
        ...formData.ownerKPP,
        value: draftData.ownerKPP,
      },
      ownerLegalAddress: {
        ...formData.ownerLegalAddress,
        value: draftData.ownerLegalAddress,
      },
      ownerActualAddress: {
        ...formData.ownerActualAddress,
        value: draftData.ownerActualAddress || formData.ownerActualAddress.value,
      },
      ownerAddressRegistrationFlag: {
        ...formData.ownerAddressRegistrationFlag,
        value: draftData.ownerAddressRegistrationFlag,
      },
      ownerTypeCode: {
        ...formData.ownerTypeCode,
        value: ownerTypeValue,
      },

      listProductType: {
        ...formData.listProductType,
        value: productValue,
      },

      insuranceObject: {
        ...formData.insuranceObject,
        value: draftData.insuranceObject,
      },
      region: {
        ...formData.region,
        value: draftData.region,
      },

      correctAddressFlag: {
        ...formData.correctAddressFlag,
        value: draftData.correctAddressFlag || false,
      },
      startDate: {
        ...formData.startDate,
        value: draftData.startDate,
      },
      endDate: {
        ...formData.endDate,
        value: draftData.endDate,
      },
    };
    setFormData(data);
    setDisableField(policy.status !== 'pending');
    setLoadingUniversalInfo(false);
  };

  const saveDraft = (e, skipValidation = true) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    const formIsValid = skipValidation || validateForm();
    if (formIsValid) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

      const data = makeFormDataFlatJson();

      if (policy.id) {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}`, {
          method: 'put',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formData: data,
            type: formData.listProductType.value.value,
          }),
        }).then((response) => response.json())
          .then((response) => {
            history.push(`/${response.type_insurance}/${response.id}`);
            showModalInfo('Черновик сохранен');
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
          method: 'post',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formData: data,
            service: true,
            type: formData.listProductType.value.value,
          }),
        }).then((response) => response.json())
          .then((newDraft) => {
            loadPolicy(newDraft.id);
            showModalInfo('Черновик сохранен');
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      }
    }
  };

  const toggleFlag = (e) => {
    const { target } = e;
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
  };

  const copyForm = () => {
    copyDraft();
    setDisableField(false);
  };

  useEffect(() => {
    if (scrollTo !== '') {
      switch (scrollTo) {
        case 'searchResults':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
          break;
        case 'chosen-offer':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        case 'top':
          scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: true,
          });
          break;
        case 'errorBlock':
          scroller.scrollTo(errorBlockName, {
            duration: 1200,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        default:
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
      }
    }
    setScrollTo('');
  }, [scrollTo]);

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  useEffect(() => {
    if (match.params.id) {
      if (policy.id !== 0) {
        loadPolicyData(policy.id);
      }
    } else {
      setLoadingUniversalInfo(false);
    }
  }, [policy.id]);

  useEffect(() => {
    if (policy.id && !match.params.id) {
      let link = `/${policy.type_insurance}/${policy.id}`;
      if (urlParams.adminEdit) {
        link = `/${policy.type_insurance}/${policy.id}?adminEdit=1`;
      }
      window.history.pushState(null, null, link);
    }
  }, [policy.id]);

  useEffect(() => {
    if (formData.ownerAddressRegistrationFlag.value) {
      setFormData((prev) => ({
        ...prev,
        ownerAddressRegistration: {
          ...prev.ownerAddressRegistration,
          value: prev.ownerActualAddress.value,
          errorMessage: '',
        },
      }));
      if (ownerAddressRegistrationRef.current) {
        ownerAddressRegistrationRef.current.state.query = formData.ownerActualAddress.value.value;
        ownerAddressRegistrationRef.current.state.inputQuery = formData.ownerActualAddress.value.value;
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        ownerAddressRegistration: {
          ...prev.ownerAddressRegistration,
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
        },
      }));
      if (ownerAddressRegistrationRef.current) {
        ownerAddressRegistrationRef.current.state.query = '';
        ownerAddressRegistrationRef.current.state.inputQuery = '';
      }
    }
  }, [formData.ownerAddressRegistrationFlag.value]);

  useEffect(() => {
    if (formData.insurerAddressRegistrationFlag.value) {
      setFormData((prev) => ({
        ...prev,
        insurerAddressRegistration: {
          ...prev.insurerAddressRegistration,
          value: prev.insurerActualAddress.value,
          errorMessage: '',
        },
      }));
      if (insurerAddressRegistrationRef.current) {
        insurerAddressRegistrationRef.current.state.query = formData.insurerActualAddress.value.value;
        insurerAddressRegistrationRef.current.state.inputQuery = formData.insurerActualAddress.value.value;
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        insurerAddressRegistration: {
          ...prev.insurerAddressRegistration,
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
        },
      }));
      if (insurerAddressRegistrationRef.current) {
        insurerAddressRegistrationRef.current.state.query = '';
        insurerAddressRegistrationRef.current.state.inputQuery = '';
      }
    }
  }, [formData.insurerAddressRegistrationFlag.value]);

  return (
    <>
      {(policyStatus === 'processing' || policyStatus === 'calculated') && (
        <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
      )}
      <div name="mortgage_container">
        <LoadingBanner loadingFlag={loadingUniversalInfo}>
          <UniversalForm
            toggleFlag={toggleFlag}
            optionType={optionType}
            ownerOptionType={ownerOptionType}
            productType={productType}
            setFormData={setFormData}
            formData={formData}
            disableField={disableField}
            handleValidate={handleValidate}
            setCursorToEnd={setCursorToEnd}
            insurerAddressRegistrationRef={insurerAddressRegistrationRef}
            ownerAddressRegistrationRef={ownerAddressRegistrationRef}
            regionRef={regionRef}
            policy={policy}
            urlParams={urlParams}
            saveDraft={saveDraft}
          />
        </LoadingBanner>
      </div>
    </>
  );
}

export default forwardRef(UniversalFormContainer);
