const masks = {
  officeNumber: {
    mask: '************************',
    formatChars: {
      '*': '[0-9A-Za-zА-Яа-я ёЁ`~<>}{\\:\\"[\\-\\]\\;\\,\\.\']',
    },
  },
  policySerial: {
    mask: 'aaa',
    formatChars: {
      a: '[A-Za-zА-Яа-я]',
    },
  },
  policyNumber: {
    mask: '999999999999',
  },
  passportNumberSerie: {
    mask: '9999 999999',
  },
  numberDocument: {
    mask: '999999',
    formatChars: {
      9: '[*0-9]',
    },
  },
  numberForeignDocument: {
    mask: '*********',
    formatChars: {
      '*': '[A-Za-zА-Яа-я0-9]',
    },
  },
  numberEDocument: {
    mask: '999999999999999',
  },
  serialDocument: {
    mask: 'aaaa',
    formatChars: {
      a: '[A-Za-zА-Яа-я0-9<>}{\\:\\"[\\-\\]\\;\\,\\.\']',
    },
  },
  serialForeignDocument: {
    mask: '********',
    formatChars: {
      '*': '[A-Za-zА-Яа-я0-9]',
    },
  },
  diagnosticCard: {
    mask: '999999999999999',
  },
  chassisNumber: {
    mask: 'aaaaaaaaaaaaaaaaa',
    formatChars: {
      a: '(?=[A-Za-z0-9])',
    },
  },
  bodyNumber: {
    mask: 'aaaaaaaaaaaaaaaaa',
    formatChars: {
      a: '(?=[A-Za-z0-9])',
    },
  },
  vin: {
    mask: 'aaaaaaaaaaaaaaaaa',
    formatChars: {
      a: '(?=[A-Za-z0-9*])[^OIQoiq]',
    },
  },
  decimal: {
    mask: '9999999999',
    formatChars: {
      9: '[0-9\\.]',
    },
  },
  discountPercent: {
    mask: '9999',
    formatChars: {
      9: '[0-9]',
    },
  },
  int: {
    mask: '999999',
    formatChars: {
      9: '[0-9]',
    },
  },
  carNumber: {
    mask: 'a999aa999',
    formatChars: {
      9: '[0-9]',
      a: '[АВЕКМНОРСТУХавекмнорстухFDTRVYJHCNE{[fdtrvyjhcne]',
    },
  },
  motorcycleNumber: {
    mask: '9999aa999',
    formatChars: {
      9: '[0-9]',
      a: '[АВЕКМНОРСТУХавекмнорстухFDTRVYJHCNE{[fdtrvyjhcne]',
    },
  },
  carName: {
    mask: '********************',
    formatChars: {
      '*': '[0-9A-Za-zА-Яа-я ёЁ`~<>}{\\:\\"[\\-\\]\\;\\,\\.\']',
    },
  },
  name: {
    formatChars: {
      '*': '[A-Za-zА-Яа-я ёЁ`~<>}{\\:\\"[\\-\\]\\;\\,\\.\']',
    },
    mask: '*****************************',
  },
  fullName: {
    formatChars: {
      '*': '[A-Za-zА-Яа-я ёЁ`~<>}{\\:\\"[\\-\\]\\;\\,\\.\']',
    },
    mask: '*****************************************************',
  },
  phone: {
    mask: '+7 (9**) ***-**-**',
    formatChars: {
      '*': '[0-9]',
    },
  },
  email: {
    mask: '***************************************',
    formatChars: {
      '*': '[\\S]',
    },
  },
  smsCode: {
    mask: '9999',
  },
  domain: {
    formatChars: {
      '*': '[A-Za-z0-9\\-]',
    },
    mask: '********************',
  },
  passportSerial: {
    mask: '9999',
  },
  passportNumber: {
    mask: '999999',
  },
  passportUnitNumber: {
    mask: '***-***',
    formatChars: {
      '*': '[0-9]',
    },
  },
  ogrn: {
    mask: '9999999999999',
  },
  ogrnip: {
    mask: '999999999999999',
  },
  inn: {
    mask: '999999999999',
    formatChars: {
      9: '[0-9]',
    },
  },
  snils: {
    mask: '99999999999',
  },
  kpp: {
    mask: '999999999',
  },
  bic: {
    mask: '999999999',
  },
  paymentAccount: {
    mask: '99999999999999999999',
  },
  paymentCard: {
    mask: '9999 9999 9999 9999 99',
  },
  year: {
    mask: '9999',
    formatChars: {
      9: '[0-9\\,\\.]',
    },
  },
  percent: {
    mask: '9999',
    formatChars: {
      9: '[0-9\\,\\.]',
    },
  },
  sum: {
    mask: '99999999',
    formatChars: {
      9: '[0-9]',
    },
  },
  mortgageContract: {
    mask: '**************',
  },
  mortgageContractSberbank: {
    mask: '********',
  },
  floor: {
    mask: '99',
    formatChars: {
      9: '[0-9]',
    },
  },
  humanHeight: {
    mask: '999',
    formatChars: {
      9: '[0-9]',
    },
  },
  humanWeight: {
    mask: '999',
    formatChars: {
      9: '[0-9]',
    },
  },
  bloodPressure: {
    mask: '999',
    formatChars: {
      9: '[0-9]',
    },
  },
  overload: {
    mask: '9',
    formatChars: {
      9: '[0-4]',
    },
  },
};
const getMask = (key) => masks[key];
export default getMask;
