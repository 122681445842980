import React, { useEffect, useState } from 'react';

export const ModalMessagesContext = React.createContext({
  showModalInfo: () => {},
  messages: [],
});

export default function ModalMessagesService({ children }) {
  const [messages, setMessages] = useState([]);

  const idArr = [...Array(15).keys()];

  const flushMessages = (id) => {
    setMessages((prev) => prev.filter((message) => message.id !== id));
  };

  const showModalInfo = (message, type = 'success', timeout = 5000) => {
    setMessages((prev) => {
      const newId = idArr.find((templateId) => prev.findIndex((msg) => msg.id === templateId) === -1);
      return prev.concat({
        text: message, timeout, id: newId, type, isActive: false, fired: false,
      });
    });
  };

  useEffect(() => {
    Object.values(messages).forEach((message) => {
      if (!message.fired) {
        const classTimeout = message.timeout - 500;
        setTimeout(() => {
          setMessages((prev) => {
            const newArr = [...prev];
            const index = newArr.findIndex((existMessage) => existMessage.id === message.id);
            newArr[index].isActive = true;
            newArr[index].fired = true;
            return newArr;
          });
        }, 10);
        setTimeout(() => {
          setMessages((prev) => {
            const newArr = [...prev];
            const index = newArr.findIndex((existMessage) => existMessage.id === message.id);
            newArr[index].isActive = false;
            return newArr;
          });
        }, classTimeout);
        setTimeout(() => flushMessages(message.id), message.timeout);
      }
    });
  }, [messages.length]);

  return (
    <ModalMessagesContext.Provider
      value={{
        showModalInfo,
        messages,
      }}
    >
      {children}
    </ModalMessagesContext.Provider>
  );
}
