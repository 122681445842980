import validate from 'validate.js';

const constraints = {
  disable: {},
  creditAgreementNumber: {
    format: {
      pattern: '[^\\s№]+',
      message: 'Некорректный номер',
    },
  },
  kadNumber: {
    length: {
      minimum: 14,
      message: 'Некорректный номер',
    },
  },
  policyFull: {
    length: {
      minimum: 13,
      maximum: 15,
      message: 'Серия и номер полиса должны содержать три буквы и от 10 до 12 цифр',
    },
  },
  policySerial: {
    length: {
      is: 3,
      message: 'Серия полиса должна содержать три буквы',
    },
  },
  policyNumber: {
    length: {
      minimum: 10,
      maximum: 12,
      message: 'Номер полиса должен содержать от 10 до 12 цифр',
    },
  },
  comment: {
    length: {
      maximum: 300,
      message: 'Максимальный размер комментария 300 символов',
    },
  },
  carNumber: {
    format: {
      pattern: '[а-яА-Я]\\d{3}[а-яА-Я]{2}\\d{2,3}',
      message: 'Некорректный номер',
    },
  },
  carName: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
  },
  passportNumberSerie: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '(\\d){10}',
      message: 'Должен содержать 10 цифр',
    },
  },
  numberDocument: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '([*\\d]){6}',
      message: 'Должен содержать 6 символов',
    },
  },
  floor: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 99,
      notInteger: 'Может содержать только цифры',
      notGreaterThan: 'Должно быть больше 0',
      notLessThanOrEqualTo: 'Должно быть меньше 99',
    },
  },
  humanHeight: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 50,
      lessThanOrEqualTo: 250,
      notInteger: 'Может содержать только цифры',
      notGreaterThan: 'Должно быть больше 50',
      notLessThanOrEqualTo: 'Должно быть меньше 250',
    },
  },
  passportUnitNumber: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    length: {
      is: 6,
      message: 'Должен содержать 6 цифр',
    },
  },
  humanWeight: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 20,
      lessThanOrEqualTo: 300,
      notInteger: 'Может содержать только цифры',
      notGreaterThan: 'Должно быть больше 20',
      notLessThanOrEqualTo: 'Должно быть меньше 300',
    },
  },
  bloodPressure: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 50,
      lessThanOrEqualTo: 250,
      notInteger: 'Может содержать только цифры',
      notGreaterThan: 'Должно быть больше 50',
      notLessThanOrEqualTo: 'Должно быть меньше 250',
    },
  },
  numberForeignDocument: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
  },
  serialForeignDocument: {
  },
  numberEDocument: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 15,
      message: 'Должен содержать 15 символов',
    },
  },
  serialDocument: {
    format: {
      pattern: '[A-Za-zА-Яа-я0-9]{4}',
      message: 'Некорректное значение',
    },
  },
  diagnosticCard: {
    format: {
      pattern: '(\\d){15}',
      message: 'Должен содержать 15 цифр',
    },
  },
  chassisNumber: {
    format: {
      pattern: '(\\w){6,17}',
      message: 'Должен содержать от 6 до 17 символов',
    },
  },
  bodyNumber: {
    format: {
      pattern: '(\\w){6,17}',
      message: 'Должен содержать от 6 до 17 символов',
    },
  },
  vin: {
    format: {
      pattern: '([*\\w]){17}',
      message: 'Должен содержать 17 символов',
    },
  },
  decimal: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '^(([1-9]+\\d*[.,]\\d+)|(\\d{1}[.,]\\d+)|([1-9]+\\d*))+$',
      message: 'Некорректное значение',
    },
  },
  'discountPercentRenessans-second': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 15,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 15%',
    },
  },
  'discountPercentRenessans-third': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 15,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 15%',
    },
  },
  discountPercentRenessans: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 15,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 15%',
    },
  },
  discountPercentUgoria: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 15,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 15%',
    },
  },
  discountPercentSoglasie: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 20,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 20%',
    },
  },
  discountPercentIngoss: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 25,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 25%',
    },
  },
  'discountPercentRgs-bank-canal': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 10,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 10%',
    },
  },
  discountPercent: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 20,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 20%',
    },
  },
  allowancePercentSoglasie: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 100%',
    },
  },
  allowancePercentRenessans: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 100%',
    },
  },
  'allowancePercentRgs-bank-canal': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 200,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 200%',
    },
  },
  'allowancePercentAlpha-bank': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 100%',
    },
  },
  'discountPercentAlpha-bank': {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 0,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер скидки - 0%',
    },
  },
  allowancePercent: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 100%',
    },
  },
  allowancePercentUgoria: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 1000,
      notGreaterThan: 'Не меньше 0',
      notLessThanOrEqualTo: 'Максимальный размер надбавки - 1000%',
    },
  },
  plain: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$',
      message: 'Пароль должен содержать минимум 8 символов, заглавную букву, строчную букву и цифру. Допустима только латиница.',
    },
  },
  id: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
  },
  passwordSubmit: {
    equality: {
      message: 'Пароли должны совпадать',
      attribute: 'password',
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '[а-яА-ЯёЁ\\- ]+',
      message: 'Используйте только кириллицу и дефис',
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '(\\+7)\\ \\([0-9]{3}\\)\\ [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}',
      message: 'Неправильный формат номера телефона',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'Не может быть пустым',
    },
    email: {
      message: 'Введите корректный адрес эл. почты',
    },
  },
  passportIssuedBy: {
    presence: {
      allowEmpty: false,
      message: 'Выберите адрес из выпадающего списка',
    },
  },
  bankName: {
    presence: {
      allowEmpty: false,
      message: 'Выберите банк из выпадающего списка',
    },
  },
  region: {
    presence: {
      allowEmpty: false,
      message: 'Выберите регион из выпадающего списка',
    },
  },
  'region.data.region': {
    presence: {
      allowEmpty: false,
      message: 'Укажите регион',
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'Выберите населенный пункт из выпадающего списка',
    },
  },
  'city.data.city': {
    presence: {
      allowEmpty: false,
      message: 'Укажите населенный пункт',
    },
  },
  addressWithFlat: {
    presence: {
      allowEmpty: false,
      message: 'Выберите адрес из выпадающего списка',
    },
  },
  'addressWithFlat.data.flat': {
    presence: {
      allowEmpty: false,
      message: 'Укажите квартиру',
    },
  },
  'addressWithFlat.data.house': {
    presence: {
      allowEmpty: false,
      message: 'Укажите дом',
    },
  },
  'addressWithFlat.data': {
    presence: {
      allowEmpty: false,
      message: 'Выберите адрес из выпадающего списка',
    },
  },
  address: {
    presence: {
      allowEmpty: false,
      message: 'Выберите адрес из выпадающего списка',
    },
  },
  'address.data.house': {
    presence: {
      allowEmpty: false,
      message: 'Укажите дом',
    },
  },
  'address.data': {
    presence: {
      allowEmpty: false,
      message: 'Выберите адрес из выпадающего списка',
    },
  },
  select: {
    presence: {
      allowEmpty: false,
      message: 'Выберите вариант',
    },
  },
  multiSelect: {
    presence: {
      allowEmpty: false,
      message: 'Выберите вариант',
    },
  },
  durationDiagnosticCard: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    datetime: {
      earliest: Date.now(),
      message: 'Период действия истек',
    },
  },
  date: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
  },
  checkbox: {
    presence: {
      allowEmpty: false,
      message: 'Необходимо подтвердить',
    },
    inclusion: {
      within: [true],
      message: 'Необходимо подтвердить',
    },
  },
  domain: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '[a-zA-Z0-9\\-]+',
      message: 'Может содержать только латиницу и цифры',
    },
  },
  passportSerial: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 4,
      message: 'Должно содержать 4 символа',
    },
  },
  ogrn: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 13,
      message: 'Должно содержать 13 цифр',
    },
  },
  ogrnip: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 15,
      message: 'Должно содержать 15 цифр',
    },
  },
  inn: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      minimum: 10,
      maximum: 12,
      message: 'Должен содержать от 10 до 12 символов',
    },
  },
  snils: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 11,
      message: 'Должен содержать 11 цифр',
    },
  },
  kpp: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 9,
      message: 'Должно содержать 9 цифр',
    },
  },
  bic: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 9,
      message: 'Должно содержать 9 цифр',
    },
  },
  paymentAccount: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 20,
      message: 'Должно содержать 20 цифр',
    },
  },
  paymentCard: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      minimum: 16,
      maximum: 18,
      message: 'Должно содержать от 16 до 18 цифр',
    },
  },
  passportNumber: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 6,
      message: 'Должно содержать 6 символов',
    },
  },
  dayOffset: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
  },
  image: {
    presence: {
      allowEmpty: false,
      message: 'Загрузите документ',
    },
    format: {
      pattern: '^.*\\.(jpg|JPG|jpeg|JPEG|gif|GIF|doc|DOC|pdf|PDF|png|PNG)$',
      flags: 'i',
      message: 'Недопустимый формат файла',
    },
  },
  insuranceLimit: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      greaterThan: 10000,
      lessThanOrEqualTo: 5000000,
      notGreaterThan: 'Не меньше 10 000',
      notLessThanOrEqualTo: 'Не больше 5 000 000',
    },
    format: {
      pattern: '^(([1-9]+\\d*[.,]\\d+)|(\\d{1}[.,]\\d+)|([1-9]+\\d*))+$',
      message: 'Некорректное значение',
    },
  },
  loanBalance: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '^(([1-9]+\\d*[.,]\\d+)|(\\d{1}[.,]\\d+)|([1-9]+\\d*))+$',
      message: 'Некорректное значение',
    },
  },
  costFull: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      greaterThan: 300,
      lessThanOrEqualTo: 999999999,
      notGreaterThan: 'Не меньше 300',
      notLessThanOrEqualTo: 'Не больше 999 999 999',
    },
    format: {
      pattern: '^(([1-9]+\\d*[.,]\\d+)|(\\d{1}[.,]\\d+)|([1-9]+\\d*))+$',
      message: 'Некорректное значение',
    },
  },
  objectCost: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
    },
  },
  intNumber: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      onlyInteger: true,
    },
  },
  summ: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '[0-9\\s_]+',
      message: 'Может содержать только цифры',
    },
  },
  yearOfConstruction: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    length: {
      is: 4,
      message: 'Должно содержать 4 цифры',
    },
    numericality: {
      message: 'Год не должен превышать текущую дату',
      lessThanOrEqualTo: new Date().getFullYear(),
    },
  },
  year: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    numericality: {
      message: 'Может содержать только цифры',
    },
    length: {
      is: 4,
      message: 'Должно содержать 4 цифры',
    },
  },
  percent: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '[1-9][0-9][0-9]|[1-9][0-9]|[1-9]',
      message: 'Некорректное значение',
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Значение должно быть больше 0',
      notLessThanOrEqualTo: 'Значение должно быть меньше или равно 100',
    },
    length: {
      maximum: 3,
      message: 'Должно содержать не более 3-х цифр',
    },
  },
  decimalPercent: {
    presence: {
      allowEmpty: false,
      message: 'Заполните поле',
    },
    format: {
      pattern: '^(([1-9]+\\d*[.,]\\d+)|(\\d{1}[.,]\\d+)|([1-9]+\\d*))+$',
      message: 'Некорректное значение',
    },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 100,
      notGreaterThan: 'Значение должно быть больше 0',
      notLessThanOrEqualTo: 'Значение должно быть меньше или равно 100',
      message: 'Может содержать только цифры',
    },
    length: {
      maximum: 6,
      message: 'Должно содержать не более 3-х цифр',
    },
  },
};

const validator = (field, value) => {
  let object = {};
  if (field === 'passwordSubmit' && value !== null) {
    object = value;
  } else {
    object[field] = value;
  }
  validate.extend(validate.validators.datetime, {
    parse(date) {
      return new Date(date).valueOf();
    },
    format(date) {
      return new Date(date);
    },
  });

  let result = validate(object, constraints, { fullMessages: false });
  result = Object.keys(result).reduce(
    (acc, key) => {
      const arrayFields = key.split('.');
      const rootField = arrayFields[0];
      if (arrayFields.length > 1 && rootField in result) {
        return ({
          ...acc,
          ...{ [rootField]: result[rootField].concat(result[key]) },
        });
      }
      return ({
        ...acc,
        ...{ [rootField]: result[key] },
      });
    },
    {},
  );
  if (field in result) {
    return result[field][0];
  }
  return false;
};

export function getConstraints(type) {
  return constraints[type];
}

export default function getValidation(value, type) {
  if (constraints[type]) {
    const errorMessage = validator(type, value);
    if (errorMessage) {
      return errorMessage;
    }
  }
  return false;
}
