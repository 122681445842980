import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { customFetch } from '../Utils/Helpers';
import getCompanies from '../Utils/InsurancesCompaniesStyle';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';

class WidgetInsurancesSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
    };
  }

  componentDidMount() {
    const { widget } = this.props;
    if (widget.policy_type.code) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      getCompanies(lsToken, widget.policy_type.code).then((companies) => {
        this.getDisabledCompanies(companies);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { widget } = this.props;
    if (prevProps.widget !== widget) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      getCompanies(lsToken, widget.policy_type.code).then((companies) => {
        this.getDisabledCompanies(companies);
      });
    }
  }

  getDisabledCompanies = (allCompanies) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { widget } = this.props;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/disabled-companies?widgetId=${widget.id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((disabledCompanies) => {
        const companies = [];
        Object.keys(allCompanies).forEach((company) => {
          allCompanies[company].checked = false;
          allCompanies[company].code = company;
          if (disabledCompanies.some((disabledCompany) => disabledCompany.insurance_code === company)) {
            allCompanies[company].checked = true;
          }
          companies.push(allCompanies[company]);
        });
        this.setState({ companies });
      });
  }

  changeInsuranceActive = (e, company) => {
    const { companies } = this.state;
    const { widget } = this.props;
    const { showModalInfo } = this.context;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    const newCompanies = companies.map((element) => {
      if (element.code === company.code) {
        element.checked = !company.checked;
      }
      return element;
    });

    if (company.checked) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/disabled-companies`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ company: company.code, widget_id: widget.id }),
      })
        .then((response) => response.json())
        .then(() => {
          showModalInfo('Изменения сохранены');
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        });
    } else {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/disabled-companies`, {
        method: 'delete',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ company: company.code, widget_id: widget.id }),
      })
        .then((response) => response.json())
        .then(() => {
          showModalInfo('Изменения сохранены');
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        });
    }

    this.setState({ companies: newCompanies });
  };

  validateCommissionLimit = () => {
    const { widget, updateWidgetWithInfo, changeCommissionLimit } = this.props;
    if (widget.settings.commission_limit === '') {
      changeCommissionLimit(0, updateWidgetWithInfo);
    } else {
      updateWidgetWithInfo();
    }
  };

  render() {
    const { companies } = this.state;
    const {
      widget,
      toggleFlag,
      changeCommissionLimit,
    } = this.props;
    return (
      <>
        {(widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage') ? (
          <>
            <h2>Настройки вывода результатов страховых компаний</h2>
            <div className="card mb-4">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                      <input onChange={toggleFlag} checked={widget.settings.hide_zero_commission_insurances} name="hide_zero_commission_insurances" className="form-check-input checkbox-styled" type="checkbox" id="hide_zero_commission_insurances" />
                      <label className="form-check-label" htmlFor="hide_zero_commission_insurances">Скрыть в результатах расчета варианты с нулевым вознаграждением</label>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                      <input onChange={toggleFlag} checked={widget.settings.limit_insurances_by_commission} name="limit_insurances_by_commission" className="form-check-input checkbox-styled" type="checkbox" id="limit_insurances_by_commission" />
                      <label className="form-check-label" htmlFor="limit_insurances_by_commission">Вручную ограничить вывод результатов по размеру вознаграждения</label>
                    </div>
                  </div>
                </div>
                {widget.settings.limit_insurances_by_commission && (
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <label htmlFor="widgetCommissionLimit">Лимит (результаты с меньшим размером вознаграждения не будут выводиться в виджете)</label>
                      <InputMask
                        onBlur={this.validateCommissionLimit}
                        className="form-control"
                        onChange={changeCommissionLimit}
                        value={widget.settings.commission_limit}
                        name="widgetCommissionLimit"
                        id="widgetCommissionLimit"
                        mask="999"
                        maskChar={null}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
        <h2>Список страховых компаний</h2>
        <div className="form-group row">
          <div className="col-lg-12">
            <FormNotificationColorLine>
              Вы можете указать в списке какие компании <strong>не будут</strong> выводиться в результатах расчета виджета
            </FormNotificationColorLine>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="form-group row insurance-deactivate-list">
              {companies.filter((company) => !company.hide)
                .map((company) => (
                  <div className="form-group row" key={company.code}>
                    <div className="col-lg-3">
                      <div className="form-check custom-checkbox insurance-label-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                        <input onChange={(e) => { this.changeInsuranceActive(e, company); }} checked={company.checked} name={`widgetInsuranceActive${company.code}`} className="form-check-input checkbox-styled" type="checkbox" id={`widgetInsuranceActive${company.code}`} />
                        <label className="form-check-label" htmlFor={`widgetInsuranceActive${company.code}`}>
                          <div className="insurance-deactivate-list__logo" style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_DOMAIN}/${company.logo})` }} />
                          <div className="search_results__company-name text-center">
                            {company.name}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

WidgetInsurancesSettings.contextType = ModalMessagesContext;

export default WidgetInsurancesSettings;
