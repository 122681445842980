import React, {
  useState, useRef, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import ReactTable from 'react-table-6';
import MultiRef from 'react-multi-ref';
import Modal from 'react-responsive-modal';
import matchSorter from 'match-sorter';
import ReactExport from 'react-data-export';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format, parseISO } from 'date-fns';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import {
  toLocalDate, getDefaultSorting, setTableSettings, setTableSorting, downloadReport6, handleTableCustomizedSetAll, handleTableCustomizedSetNone, handleSubmitTableCustomized,
} from '../../Utils/Helpers';

function CheckOsagoTable(props, ref) {
  const { policyDataList, tab, requestWaitingFlag } = props;

  const simulateClick = new MultiRef();

  const tableRef = useRef(null);
  const excelFile = useRef(null);
  const customizeTableRef = useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;

  const [filtered, setFiltered] = useState([]);
  const [columns, setColumns] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [goDownloadFile, setGoDownloadFile] = useState(false);
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  const updateTableColumns = () => {
    const newColumns = [{
      Header: 'Тип',
      accessor: 'type',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').type,
      Cell: ({ row }) => {
        if (row.type === 'VIN') return 'VIN';
        return 'Гос. номер';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'vin') {
          return row.type === 'VIN';
        }
        if (filter.value === 'carNumber') {
          return row.type === 'Гос. номер';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="vin">VIN</option>
          <option value="carNumber">Гос. номер</option>
        </select>
      ),
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_type !== false,
      showCustomized: true,
    }, {
      Header: 'Страховая компания',
      accessor: 'company',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').company,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_company !== false,
      showCustomized: true,
    }, {
      Header: 'Серия и номер полиса',
      id: 'policySerialNumber',
      accessor: (d) => (`${d.series}${d.number}`),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').policySerialNumber,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_policySerialNumber !== false,
      showCustomized: true,
    }, {
      Header: 'VIN',
      accessor: 'vin_number',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').vin_number,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_vin_number !== false,
      showCustomized: true,
    }, {
      Header: 'Гос. номер',
      accessor: 'license_plate',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').license_plate,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_license_plate !== false,
      showCustomized: true,
    }, {
      Header: 'Номер кузова',
      accessor: 'body_number',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').body_number,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_body_number !== false,
      showCustomized: true,
    }, {
      Header: 'Марка',
      accessor: 'mark',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['mark'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').mark,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_mark !== false,
      showCustomized: true,
    }, {
      Header: 'Модель',
      accessor: 'model',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['model'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').model,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_model !== false,
      showCustomized: true,
    }, {
      Header: 'Цель использования ТС',
      accessor: 'usage_target',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').usage_target,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_usage_target !== false,
      showCustomized: true,
    }, {
      Header: 'Управление ТС с прицепом',
      accessor: 'has_trailer',
      Cell: ({ original }) => (original.has_trailer ? 'Да' : 'Нет'),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').has_trailer,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_has_trailer !== false,
      showCustomized: true,
    }, {
      Header: 'ТС следует к месту регистрации или к месту проведения технического осмотра',
      accessor: 'in_transit',
      Cell: ({ original }) => (original.in_transit ? 'Да' : 'Нет'),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').in_transit,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_in_transit !== false,
      showCustomized: true,
    }, {
      Header: 'Дата создания',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').date_created,
      accessor: 'date_created',
      Cell: ({ original }) => (original.date_created ? format(toLocalDate(parseISO(original.date_created)), 'dd.MM.yyyy') : ''),
      filterMethod: (filter, row) => { const rowValue = format(parseISO(row._original.date_created), 'dd.MM.yyyy'); return rowValue.includes(filter.value); },
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_date_created !== false,
      showCustomized: true,
    }, {
      Header: 'Дата начала действия',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').start_date,
      accessor: 'start_date',
      Cell: ({ original }) => (original.start_date ? format(toLocalDate(parseISO(original.start_date)), 'dd.MM.yyyy') : ''),
      filterMethod: (filter, row) => { const rowValue = format(parseISO(row._original.start_date), 'dd.MM.yyyy'); return rowValue.includes(filter.value); },
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_start_date !== false,
      showCustomized: true,
    }, {
      Header: 'Дата окончания действия',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').end_date,
      accessor: 'end_date',
      Cell: ({ original }) => (original.end_date ? format(toLocalDate(parseISO(original.end_date)), 'dd.MM.yyyy') : ''),
      filterMethod: (filter, row) => { const rowValue = format(parseISO(row._original.end_date), 'dd.MM.yyyy'); return rowValue.includes(filter.value); },
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_end_date !== false,
      showCustomized: true,
    }, {
      Header: 'Страхователь',
      accessor: 'insurer_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insurer_name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').insurer_name,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_insurer_name !== false,
      showCustomized: true,
    }, {
      Header: 'Дата рождения страхователя',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').insurer_birthday,
      accessor: 'insurer_birthday',
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_insurer_birthday !== false,
      showCustomized: true,
    }, {
      Header: 'Владелец',
      accessor: 'owner_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['owner_name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').owner_name,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_owner_name !== false,
      showCustomized: true,
    }, {
      Header: 'Дата рождения владельца',
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').owner_birthday,
      accessor: 'owner_birthday',
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_owner_birthday !== false,
      showCustomized: true,
    }, {
      Header: 'КБМ',
      accessor: 'KBM',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').KBM,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_KBM !== false,
      showCustomized: true,
    }, {
      Header: 'Адрес',
      accessor: 'region',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').region,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_region !== false,
      showCustomized: true,
    }, {
      Header: 'Цена',
      accessor: 'price',
      filterAll: true,
      width: reactLocalStorage.getObject('osagoCheckInfoSettings').price,
      show: reactLocalStorage.getObject('osagoCheckInfoSettings').show_price !== false,
      showCustomized: true,
    }];

    setColumns([...newColumns]);
  };

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
    downloadReport: () => {
      downloadReport6(tableRef, columns, setExcelColumns, setDataSet, setGoDownloadFile);
    },
  }));

  useEffect(() => {
    if (goDownloadFile) {
      excelFile.current.handleDownload();
      setGoDownloadFile(false);
    }
  }, [goDownloadFile]);

  useEffect(() => {
    updateTableColumns();
  }, []);

  const customizedColumns = columns.map((column) => {
    const id = column.id ? column.id : column.accessor;
    if (column.showCustomized) {
      return (
        <li key={id} className="form-check">
          <input ref={simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
          <label className="form-check-label" htmlFor={id}>{column.Header}</label>
        </li>
      );
    }
    return null;
  });

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={() => handleTableCustomizedSetAll(simulateClick)} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={() => handleTableCustomizedSetNone(simulateClick)} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={(e) => handleSubmitTableCustomized(e, 'osagoCheckInfoSettings', setFlagModalTableCustomized, updateTableColumns)} id="tableCustomized">
          <ul className="two-column-list">
            {customizedColumns}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <div className="d-none">
        <ExcelFile ref={excelFile} element={<button type="button">Download Data</button>}>
          <ExcelSheet data={dataSet} name="OsagoPoliciesInfo">
            {excelColumns}
          </ExcelSheet>
        </ExcelFile>
      </div>
      <ReactTable
        ref={tableRef}
        filtered={filtered}
        onFilteredChange={(filtered) => onFilteredChangeCustom(filtered)}
        className="table"
        data={policyDataList}
        columns={columns}
        defaultSorted={[
          {
            id: getDefaultSorting('osagoCheckInfoSettings').field,
            desc: getDefaultSorting('osagoCheckInfoSettings').order,
          },
        ]}
        previousText="Назад"
        nextText="Вперед"
        loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
        noDataText="Информация не найдена"
        pageText="Страница"
        ofText="Из"
        rowsText="строк"
        loading={requestWaitingFlag}
        defaultPageSize={50}
        filterable
        minRows={1}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'osagoCheckInfoSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings(newResized, 'osagoCheckInfoSettings');
        }}
      />
    </>
  );
}

export default forwardRef(CheckOsagoTable);
