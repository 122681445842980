import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Modal from 'react-responsive-modal';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch } from './Helpers';

export default function PhoneVerifying(props) {
  const {
    validateForm,
    phone,
    showModal,
    setShowModal,
    phoneVerified,
    draftHash,
    setPhoneVerified,
  } = props;

  const [phoneVerifyData, setPhoneVerifyData] = useState({
    send: false,
    error: '',
    limitError: false,
    code: '',
    timer: 0,
    sendCode: false,
    type: '',
    waitingFlag: false,
  });

  const clearPhoneVerifyError = () => {
    setPhoneVerifyData((prev) => ({
      ...prev,
      error: '',
    }));
  };

  const changeVerifyPhoneCode = (e, type) => {
    const { target } = e;
    setPhoneVerifyData((prev) => ({
      ...prev,
      code: target.value,
      type,
    }));
  };

  const sendPhoneVerifyCode = (e, absolutDeclaration = false) => {
    if (e === undefined || !e.target.classList.contains('disable')) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

      setPhoneVerifyData((prev) => ({
        ...prev,
        waitingFlag: true,
      }));

      customFetch(`${process.env.REACT_APP_API_DOMAIN}/send-sms-code`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          draftHash,
          phone,
          type: 'mortgage',
          absolutDeclaration,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 'ok') {
            setPhoneVerifyData((prev) => ({
              ...prev,
              waitingFlag: false,
              send: true,
            }));
          } else {
            setPhoneVerifyData((prev) => ({
              ...prev,
              waitingFlag: false,
              limitError: true,
            }));
          }
        });
    }
  };

  const sendPhoneVerifyCodeRepeat = (absolutDeclaration = false) => {
    setPhoneVerifyData((prev) => ({
      ...prev,
      timer: parseInt(60, 10),
      code: '',
      waitingFlag: true,
    }));

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/send-sms-code`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash,
        phone,
        type: 'mortgage',
        absolutDeclaration,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          setPhoneVerifyData((prev) => ({
            ...prev,
            waitingFlag: false,
            send: true,
          }));
        } else {
          setPhoneVerifyData((prev) => ({
            ...prev,
            waitingFlag: false,
            error: 'Превышено количество обращений к серверу, повторите попытку позже или обратитесь к нашим менеджерам',
          }));
        }
      });
  };

  const checkVerifyPhoneCode = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/check-sms-code`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash,
        smsCode: phoneVerifyData.code,
        type: 'mortgage',
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'same') {
          setPhoneVerified(true);
        } else {
          setPhoneVerifyData((prev) => ({
            ...prev,
            error: 'Неверный код, введите код повторно',
          }));
        }
      });
  };

  useEffect(() => {
    if (showModal.absolutDeclarationForm) {
      sendPhoneVerifyCode(undefined, showModal.absolutDeclarationForm);
    }
  }, [showModal.absolutDeclarationForm]);

  useEffect(() => {
    if (phoneVerifyData.code.length === 4) {
      checkVerifyPhoneCode(phoneVerifyData.type);
    }
  }, [phoneVerifyData.code]);

  useEffect(() => {
    if (phoneVerified) {
      setShowModal((prev) => ({ ...prev, absolutDeclarationForm: false }));
    }
  }, [phoneVerified]);

  useEffect(() => {
    if (phoneVerifyData.timer > 0) {
      setTimeout(() => {
        setPhoneVerifyData((prev) => ({
          ...prev,
          timer: prev.timer - 1,
        }));
      }, 1000);
    }
  }, [phoneVerifyData.timer]);

  return (
    <Modal
      classNames={{ overlay: 'modal-window modal-form', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
      open={showModal.absolutDeclarationForm}
      onClose={() => setShowModal((prev) => ({ ...prev, absolutDeclarationForm: false }))}
      closeIconSize={16}
      center
    >
      {phoneVerifyData.waitingFlag
        ? (
          <>
            Идет формирование декларации...
            <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
          </>
        )
        : (
          <>
            <h4 className="modal__heading">Ознакомьтесь с декларацией и подтвердите контактные данные</h4>
            <form className="real-contacts-form form">
              {phoneVerifyData.send ? (
                <>
                  <div className="mb-2">
                    На ваш номер телефона <b>{phone}</b> была отправлена ссылка на декларацию и код
                    подтверждения. Пожалуйста, ознакомьтесь с ней и введите код в строке ниже.
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <label htmlFor="phone" className={classnames({ 'is-invalid-label': phoneVerifyData.error })}>Код подтверждения</label>
                      <input
                        onChange={(e) => { changeVerifyPhoneCode(e, 'calculate'); }}
                        onFocus={clearPhoneVerifyError}
                        value={phoneVerifyData.code}
                        className={classnames('form-control', { error: phoneVerifyData.error })}
                        name="phoneVerifyCode"
                        id="phoneVerifyCode"
                        placeholder="Код подтверждения из смс"
                        inputMode="decimal"
                      />
                      {phoneVerifyData.error && (
                        <div className="validation-error">{phoneVerifyData.error}</div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
              {phoneVerifyData.timer && !phoneVerified ? (
                <div className="modal__msg msg msg--mail-sent js-msg-mail-sent">
                  <div className="msg__restricted">
                    Вы можете отправить код<br /> еще раз через <b>0:{(`0${phoneVerifyData.timer}`).slice(-2)}</b>
                  </div>
                </div>
              ) : null}
              <div className="form__action form__action--modal">
                {phoneVerifyData.send && !phoneVerified && !phoneVerifyData.timer ? (
                  <button type="button" className="btn btn-success mt-2" onClick={() => sendPhoneVerifyCodeRepeat(showModal.absolutDeclarationForm)}>Получить код повторно</button>
                ) : null}
                {phoneVerified ? (
                  <button type="button" onClick={(e) => validateForm(e)} className="btn btn-success mt-2">Оформить</button>
                ) : null}
              </div>
            </form>
          </>
        )}
    </Modal>
  );
}
