import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subYears, addDays } from 'date-fns';
import { faCalendarAlt, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Tippy from '@tippyjs/react';
import CurrencyInput from 'react-currency-input-field';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import getMask from '../../Utils/Masks';
import { customSelectStyle, handleChange } from '../../Utils/Helpers';
import AccountSystem from '../Blocks/AccountSystem';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function IflForm(props) {
  const {
    customFieldName,
    companies,
    privacyPolicy,
    formData,
    disableField,
    goToBuying,
    handleValidate,
    toggleFlag,
    genderOptions,
    setCursorToEnd,
    regionRef,
    insuredAddressRegistrationRef,
    phoneCheckRequestFlag,
    policy,
    urlParams,
    saveDraft,
    draftInfo,
    chosenOffer,
    setFormData,
    userFullTimeEmployee,
    linkToDeal,
    leadSource,
    managerAttracted,
    setLeadSource,
    setManagerAttracted,
    setLinkToDeal,
    leadSourcesOptions,
    managerAttractedOptions,
    customFieldOptions,
    setCustomField,
    customField,
    newAgent,
    setNewAgent,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const customStyles = useMemo(() => customSelectStyle(), []);
  const insuranceTerritoryCodeOpts = [
    { value: 'Москва', label: 'Москва' },
    { value: 'Московская область', label: 'Московская область' },
    { value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
    { value: 'Ленинградская область', label: 'Ленинградская область' },
    { value: 'Алтайский край', label: 'Алтайский край' },
    { value: 'Амурская область', label: 'Амурская область' },
    { value: 'Архангельская область', label: 'Архангельская область' },
    { value: 'Астраханская область', label: 'Астраханская область' },
    { value: 'Белгородская область', label: 'Белгородская область' },
    { value: 'Брянская область', label: 'Брянская область' },
    { value: 'Владимирская область', label: 'Владимирская область' },
    { value: 'Волгоградская область', label: 'Волгоградская область' },
    { value: 'Вологодская область', label: 'Вологодская область' },
    { value: 'Воронежская область', label: 'Воронежская область' },
    { value: 'Еврейская автономная область', label: 'Еврейская автономная область' },
    { value: 'Забайкальский край', label: 'Забайкальский край' },
    { value: 'Ивановская область', label: 'Ивановская область' },
    { value: 'Иркутская область', label: 'Иркутская область' },
    { value: 'Кабардино-Балкарская Республика', label: 'Кабардино-Балкарская Республика' },
    { value: 'Калининградская область', label: 'Калининградская область' },
    { value: 'Калужская область', label: 'Калужская область' },
    { value: 'Камчатский край', label: 'Камчатский край' },
    { value: 'Карачаево-Черкесская Республика', label: 'Карачаево-Черкесская Республика' },
    { value: 'Кемеровская область', label: 'Кемеровская область' },
    { value: 'Кировская область', label: 'Кировская область' },
    { value: 'Костромская область', label: 'Костромская область' },
    { value: 'Краснодарский край', label: 'Краснодарский край' },
    { value: 'Красноярский край', label: 'Красноярский край' },
    { value: 'Курганская область', label: 'Курганская область' },
    { value: 'Курская область', label: 'Курская область' },
    { value: 'Липецкая область', label: 'Липецкая область' },
    { value: 'Магаданская область', label: 'Магаданская область' },
    { value: 'Мурманская область', label: 'Мурманская область' },
    { value: 'Ненецкий автономный округ', label: 'Ненецкий автономный округ' },
    { value: 'Нижегородская область', label: 'Нижегородская область' },
    { value: 'Новгородская область', label: 'Новгородская область' },
    { value: 'Новосибирская область', label: 'Новосибирская область' },
    { value: 'Омская область', label: 'Омская область' },
    { value: 'Оренбургская область', label: 'Оренбургская область' },
    { value: 'Орловская область', label: 'Орловская область' },
    { value: 'Пензенская область', label: 'Пензенская область' },
    { value: 'Пермский край', label: 'Пермский край' },
    { value: 'Приморский край', label: 'Приморский край' },
    { value: 'Псковская область', label: 'Псковская область' },
    { value: 'Республика Адыгея', label: 'Республика Адыгея' },
    { value: 'Республика Алтай', label: 'Республика Алтай' },
    { value: 'Республика Башкортостан', label: 'Республика Башкортостан' },
    { value: 'Республика Бурятия', label: 'Республика Бурятия' },
    { value: 'Республика Дагестан', label: 'Республика Дагестан' },
    { value: 'Республика Ингушетия', label: 'Республика Калмыкия' },
    { value: 'Республика Карелия', label: 'Республика Карелия' },
    { value: 'Республика Коми', label: 'Республика Коми' },
    { value: 'Республика Крым', label: 'Республика Крым' },
    { value: 'Республика Марий Эл', label: 'Республика Марий Эл' },
    { value: 'Республика Мордовия', label: 'Республика Мордовия' },
    { value: 'Республика Саха (Якутия)', label: 'Республика Саха (Якутия)' },
    { value: 'Республика Северная Осетия - Алания', label: 'Республика Северная Осетия - Алания' },
    { value: 'Республика Татарстан', label: 'Республика Татарстан' },
    { value: 'Республика Тыва', label: 'Республика Тыва' },
    { value: 'Республика Хакасия', label: 'Республика Хакасия' },
    { value: 'Ростовская область', label: 'Ростовская область' },
    { value: 'Рязанская область', label: 'Рязанская область' },
    { value: 'Самарская область', label: 'Самарская область' },
    { value: 'Саратовская область', label: 'Саратовская область' },
    { value: 'Сахалинская область', label: 'Сахалинская область' },
    { value: 'Свердловская область', label: 'Свердловская область' },
    { value: 'Севастополь', label: 'Севастополь' },
    { value: 'Смоленская область', label: 'Смоленская область' },
    { value: 'Ставропольский край', label: 'Ставропольский край' },
    { value: 'Тамбовская область', label: 'Тамбовская область' },
    { value: 'Тверская область', label: 'Тверская область' },
    { value: 'Томская область', label: 'Томская область' },
    { value: 'Тульская область', label: 'Тульская область' },
    { value: 'Тюменская область', label: 'Тюменская область' },
    { value: 'Удмуртская Республика', label: 'Удмуртская Республика' },
    { value: 'Ульяновская область', label: 'Ульяновская область' },
    { value: 'Хабаровский край', label: 'Хабаровский край' },
    { value: 'Ханты-Мансийский автономный округ-Югра', label: 'Ханты-Мансийский автономный округ-Югра' },
    { value: 'Челябинская область', label: 'Челябинская область' },
    { value: 'Чеченская Республика', label: 'Чеченская Республика' },
    { value: 'Чувашская республика', label: 'Чувашская республика' },
    { value: 'Чукотский автономный округ', label: 'Чукотский автономный округ' },
    { value: 'Ямало-Ненецкий автономный округ', label: 'Ямало-Ненецкий автономный округ' },
    { value: 'Ярославская область', label: 'Ярославская область' },
    { value: 'Вся территория РФ', label: 'Вся территория РФ' },
    { value: 'Весь мир', label: 'Весь мир' },
  ];

  const [coverzOpts, setCoverzOpts] = useState([
    { value: 'BuildingConstructiveAndInnerDecoration', label: 'Конструктивные элементы, внутренняя отделка и инженерное оборудование строения' },
    { value: 'FlatConstructive', label: 'Конструктивные элементы, образующие помещения квартиры' },
    { value: 'FlatInnerDecoration', label: 'Внутренняя отделка и инженерное оборудование квартиры' },
    { value: 'CivilResponsibility', label: 'Гражданская ответственность' },
    { value: 'Movable', label: 'Движимое имущество' },
    { value: 'MovableByInventory', label: 'Движимое имущество по описи' },
    { value: 'MovableWithoutInventory', label: 'Движимое имущество без описи' },
    { value: 'Animals', label: 'Страхование животных' },
    { value: 'ExpClaimsAndForceMaj', label: 'Расходы по убыткам ПДТЛ или обстоятельствам непреодолимой силы' },
    { value: 'ExpPaymentExperts', label: 'Расходы на оплату юристов, оценщиков и экспертов' },
    { value: 'Gadget', label: 'Страхование электронных устройств' },
  ]);

  const [attrsOpts, setAttrOpts] = useState([
    {
      value: 'Material',
      label: 'материал конструктива',
      opts: [
        { value: 'Brick', label: 'кирпич' },
        { value: 'ReinforcedConcreteBlock', label: 'железобетонные блоки' },
        { value: 'Monolith', label: 'монолит' },
        { value: 'Timber', label: 'бревно' },
        { value: 'Bar', label: 'брус' },
        { value: 'FrameShield', label: 'каркасно-щитовой' },
        { value: 'SandwichPanel', label: 'сэндвич-панели' },
        { value: 'Mixed', label: 'смешанный' },
      ],
    },
    {
      value: 'FloorMaterial',
      label: 'материал межэтажных перекрытий',
      opts: [
        { value: 'ReinforcedConcrete', label: 'железобетон' },
      ],
    },
    {
      value: 'FenceMaterial',
      label: 'материал забора',
      opts: [
        { value: 'Plank', label: 'доска' },
        { value: 'Grid', label: 'сетка' },
        { value: 'MetalSheet', label: 'металлические листы' },
        { value: 'Brick', label: 'кирпич' },
      ],
    },
    { value: 'YearConstruct', label: 'год постройки', opts: [] },
    { value: 'Area', label: 'площадь', opts: [] },
    { value: 'FloorCount', label: 'количество этажей', opts: [] },
    { value: 'OpenFire', label: 'наличие сауны, бани, камина, печи', opts: [10] },
    { value: 'Repairs', label: 'ведутся ремонтно-строительные работы', opts: [10] },
    { value: 'ProgressConstruct', label: 'незавершенное строительство', opts: [10] },
    { value: 'Pledged', label: 'объект находится в залоге', opts: [10] },
    { value: 'ObjectDescription', label: 'описание объекта страхования (для «Иная постройка»)', opts: [] },
    { value: 'EqBrandModel', label: 'марка и модель техники', opts: [] },
    { value: 'SerialNumber', label: 'серийный номер/IMEI', opts: [] },
  ]);
  const [attrOpt, setAttrOpt] = useState(attrsOpts[0]);
  const [attrVal, setAttrVal] = useState('');
  const [attrErrorMessage, setAttrErrorMessage] = useState('');

  const flatMaterialOpts = [
    { value: 'Кирпичный', label: 'Кирпичный' },
    { value: 'Железобетонный', label: 'Железобетонный' },
    { value: 'Монолитный', label: 'Монолитный' },
    { value: 'Бревенчатый', label: 'Бревенчатый' },
    { value: 'Брусчатый', label: 'Брусчатый' },
    { value: 'Каркасно-щитовой', label: 'Каркасно-щитовой' },
    { value: 'Сэндвич-панели', label: 'Сэндвич-панели' },
    { value: 'Смешанный', label: 'Смешанный' },
  ];

  const getAttrVals = () => {
    if (!attrOpt.opts.length) {
      return (
        <InputMask
          maskChar=""
          onBlur={false}
          disabled={disableField}
          placeholder="Значение"
          onChange={(e) => setAttrVal(e.target.value)}
          id="attrVal"
          name="attrVal"
          className={classnames('form-control', { error: attrErrorMessage })}
          value={attrVal}
        />
      );
    }
    if (attrOpt.opts[0] === 10) {
      return (
        <>
          <label className="switch">
            <input
              onChange={() => setAttrVal(!attrVal)}
              checked={attrVal}
              name="attrVal"
              id="attrVal"
              type="checkbox"
              className={classnames('', { error: attrErrorMessage })}
              disabled={disableField}
            />
            <span className="slider round" />
          </label>
          <span className="switch-text" />
        </>
      );
    }
    return (
      <Select
        classNamePrefix="react-select"
        styles={customStyles}
        isDisabled={disableField}
        name="attrVal"
        noOptionsMessage={() => 'Не найдено'}
        inputId="attrVal"
        isSearchable={false}
        value={attrVal}
        onChange={(e) => { setAttrVal(e); }}
        options={attrOpt.opts}
        className={classnames('', { error: attrErrorMessage })}
      />
    );
  };

  const [addingCoverName, setAddingCoverName] = useState(coverzOpts[0]);
  const [coverSum, setCoverSum] = useState('');
  const pTypeOptions = [
    { value: 'flat', label: 'Страхование квартиры' },
    { value: 'house', label: 'Страхование дома' },
  ];

  const compensationTypeOpts = [
    { value: 'CalcWithWear', label: 'с учетом износа' },
    { value: 'CalcWithoutWear', label: 'без учета износа' },
  ];

  const pProgramOptions = {
    Flat: [
      { label: 'Квартира Экспресс', value: 'EXFLAT' },
      { label: 'Квартира Экспресс 1', value: 'EXFLATN' },
      { label: 'Квартира Экспресс Партнер', value: 'EXFLATP' },
    ],
    Apartment: [
      { label: 'Квартира Экспресс', value: 'EXFLAT' },
      { label: 'Квартира Экспресс 1', value: 'EXFLATN' },
      { label: 'Квартира Экспресс Партнер', value: 'EXFLATP' },
    ],
    Room: [
      { label: 'Квартира Экспресс', value: 'EXFLAT' },
      { label: 'Квартира Экспресс 1', value: 'EXFLATN' },
      { label: 'Квартира Экспресс Партнер', value: 'EXFLATP' },
    ],
    Garage: [
      { label: 'Квартира Экспресс', value: 'EXFLAT' },
      { label: 'Квартира Экспресс 1', value: 'EXFLATN' },
      { label: 'Квартира Экспресс Партнер', value: 'EXFLATP' },
    ],

    House: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    TownHouse: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    BathHouse: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    Outbuilding: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    Construction: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    Fence: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],
    OtherConstruction: [
      { label: 'Дом Экспресс', value: 'EXDOM' },
      { label: 'Дом Экспресс 1', value: 'EXDOMN' },
      { label: 'Дом Классика', value: 'EXDOMKL' },
    ],

    AutoMovable: [
      { label: 'Движимое имущество в автомобиле', value: 'PRAUTO' },
      { label: 'Движимое имущество в автомобиле. ЦОК', value: 'PRAUTOCOK' },
      { label: 'Движимое имущество в авто.Автодор', value: 'AUTODOR' },
      { label: 'Движимое имущество в авто.Дилеры', value: 'PRAUTODILER' },
    ],
    AutoOptionalEq: [
      { label: 'Движимое имущество в автомобиле', value: 'PRAUTO' },
      { label: 'Движимое имущество в автомобиле. ЦОК', value: 'PRAUTOCOK' },
      { label: 'Движимое имущество в авто.Автодор', value: 'AUTODOR' },
      { label: 'Движимое имущество в авто.Дилеры', value: 'PRAUTODILER' },
    ],
    Keys: [
      { label: 'Движимое имущество в автомобиле', value: 'PRAUTO' },
      { label: 'Движимое имущество в автомобиле. ЦОК', value: 'PRAUTOCOK' },
      { label: 'Движимое имущество в авто.Автодор', value: 'AUTODOR' },
      { label: 'Движимое имущество в авто.Дилеры', value: 'PRAUTODILER' },
    ],
    Docs: [
      { label: 'Движимое имущество в автомобиле', value: 'PRAUTO' },
      { label: 'Движимое имущество в автомобиле. ЦОК', value: 'PRAUTOCOK' },
      { label: 'Движимое имущество в авто.Автодор', value: 'AUTODOR' },
      { label: 'Движимое имущество в авто.Дилеры', value: 'PRAUTODILER' },
    ],
    PortableEq: [
      { label: 'Движимое имущество в автомобиле', value: 'PRAUTO' },
      { label: 'Движимое имущество в автомобиле. ЦОК', value: 'PRAUTOCOK' },
      { label: 'Движимое имущество в авто.Автодор', value: 'AUTODOR' },
      { label: 'Движимое имущество в авто.Дилеры', value: 'PRAUTODILER' },
    ],

    Animals: [
      { label: 'Страхование животных', value: 'ANIMALS' },
    ],
    VeterinaryService: [
      { label: 'Страхование животных', value: 'ANIMALS' },
    ],

    CivilResponsibility: [
      { label: 'Страхование памятников', value: 'MONM' },
      { label: 'Страхование техники', value: 'TECH' },
      { label: 'Базовая программа конструктора', value: 'BASE' },
      { label: 'Гаджеты', value: 'GADGET1' },
      { label: 'Задержка посылки', value: 'PACKAGE' },
    ],
    Costs: [
      { label: 'Страхование памятников', value: 'MONM' },
      { label: 'Страхование техники', value: 'TECH' },
      { label: 'Базовая программа конструктора', value: 'BASE' },
      { label: 'Гаджеты', value: 'GADGET1' },
      { label: 'Задержка посылки', value: 'PACKAGE' },
    ],
    Legal: [
      { label: 'Страхование памятников', value: 'MONM' },
      { label: 'Страхование техники', value: 'TECH' },
      { label: 'Базовая программа конструктора', value: 'BASE' },
      { label: 'Гаджеты', value: 'GADGET1' },
      { label: 'Задержка посылки', value: 'PACKAGE' },
    ],
  };

  const [coverSumErrorMessage, setCoverSumErrorMessage] = useState('');

  const insTypeOpts = [
    { label: 'базовый', value: 'BASE' },
    { label: 'классический', value: 'KLASSIC' },
    { label: 'только гражданская ответственность', value: 'GO' },
    { label: 'расширенный', value: 'Extended' },
    { label: 'программа 1', value: 'PR1' },
    { label: 'программа 2', value: 'PR2' },
    { label: 'программа 3', value: 'PR3' },
    { label: 'программа 4', value: 'PR4' },
    { label: 'программа 5', value: 'PR5' },
    { label: 'программа 6', value: 'PR6' },
    { label: 'программа 7', value: 'PR7' },
    { label: 'программа 8', value: 'PR8' },
    { label: 'программа 9', value: 'PR9' },
  ];

  const addCover = () => {
    setCoverSumErrorMessage('');
    if (!coverSum) {
      setCoverSumErrorMessage('Обязательно');
      return;
    }
    const newData = { ...formData };
    newData.coverz.value.push({
      val: addingCoverName,
      sum: coverSum,
    });
    const coverzOptsN = [];
    for (let i = 0; i < coverzOpts.length; i += 1) {
      if (coverzOpts[i].value !== addingCoverName.value) {
        coverzOptsN.push(coverzOpts[i]);
      }
    }
    setCoverzOpts(coverzOptsN);
    setCoverSum('');
    setAddingCoverName(coverzOptsN[0]);
    setFormData(newData);
  };

  const copyAddress = (addressType) => {
    const newData = { ...formData };
    newData.region.value = newData[addressType].value;
    newData.region.errorMessage = newData[addressType].errorMessage;
    setFormData(newData);
  };

  useEffect(() => {
    const coverzOptsN = [];
    for (let i = 0; i < coverzOpts.length; i += 1) {
      let flag = true;
      for (let j = 0; j < formData.coverz.value.length; j += 1) {
        if (coverzOpts[i].value === formData.coverz.value[j].val.value) {
          flag = false;
        }
      }
      if (flag) coverzOptsN.push(coverzOpts[i]);
    }
    setCoverzOpts(coverzOptsN);
    setAddingCoverName(coverzOptsN[0]);
  }, [formData.coverz.length]);

  const delCover = (el) => {
    const opt = el.val;
    const coverzOptsN = coverzOpts;
    coverzOptsN.unshift(opt);
    const newData = { ...formData };
    const coverz = [];
    for (let i = 0; i < newData.coverz.value.length; i += 1) {
      if (newData.coverz.value[i].val.value !== opt.value) {
        coverz.push(newData.coverz.value[i]);
      }
    }
    newData.coverz.value = coverz;
    setCoverzOpts(coverzOptsN);
    setAddingCoverName(coverzOptsN[0]);
    setFormData(newData);
  };

  useEffect(() => {
    const attrsOptsN = [];
    for (let i = 0; i < attrsOpts.length; i += 1) {
      let flag = true;
      for (let j = 0; j < formData.attrs.value.length; j += 1) {
        if (attrsOpts[i].value === formData.attrs.value[j].code.value) {
          flag = false;
        }
      }
      if (flag) attrsOptsN.push(attrsOpts[i]);
    }
    setAttrOpts(attrsOptsN);
    setAttrOpt(attrsOptsN[0]);
  }, [formData.attrs.length]);

  const addAttr = () => {
    setAttrErrorMessage('');
    if (!attrVal && (attrOpt.opts.length && attrOpt.opts[0] !== 10)) {
      setAttrErrorMessage('Обязательно');
      return;
    }
    const newData = { ...formData };
    newData.attrs.value.push({
      code: attrOpt,
      value: attrVal,
    });
    const attrsOptsN = [];
    for (let i = 0; i < attrsOpts.length; i += 1) {
      if (attrsOpts[i].value !== attrOpt.value) {
        attrsOptsN.push(attrsOpts[i]);
      }
    }
    setAttrOpts(attrsOptsN);
    setAttrVal('');
    setAttrOpt(attrsOptsN[0]);
    setFormData(newData);
  };

  const delAttr = (el) => {
    const opt = el.code;
    const attrsOptsN = attrsOpts;
    attrsOptsN.unshift(opt);
    const newData = { ...formData };
    const attrs = [];
    for (let i = 0; i < newData.attrs.value.length; i += 1) {
      if (newData.attrs.value[i].code.value !== opt.value) {
        attrs.push(newData.attrs.value[i]);
      }
    }
    newData.attrs.value = attrs;
    setAttrOpts(attrsOptsN);
    setAttrOpt(attrsOptsN[0]);
    setFormData(newData);
  };

  const getValLabel = (el) => {
    if (typeof el === 'boolean' && el) return 'да';
    if (!el) return 'нет';
    if (Object.prototype.hasOwnProperty.call(el, 'label')) return el.label;
    return el;
  };

  return (
    <form name="IflForm" id="Ifl_form">
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">1</span>
          <span>Страхователь</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="lastName">Фамилия</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="lastName"
                name="lastName"
                placeholder="Иванов"
                query={formData.lastName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'lastName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['SURNAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.lastName.errorMessage })}
              />
              {formData.lastName.errorMessage && (
                <div className="validation-error">
                  {formData.lastName.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="firstName">Имя</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="firstName"
                name="firstName"
                placeholder="Иван"
                query={formData.firstName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'firstName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['NAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.firstName.errorMessage })}
              />
              {formData.firstName.errorMessage && (
                <div className="validation-error">
                  {formData.firstName.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="middleName">Отчество</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="middleName"
                name="middleName"
                placeholder="Иванович"
                query={formData.middleName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'middleName', formData, setFormData, null, { dadataType: 'fio', multiWords: true, firstCapital: true })}
                parts={['PATRONYMIC']}
                disabled={disableField}
                translate
                className={classnames('form-control', { error: formData.middleName.errorMessage })}
              />
              {formData.middleName.errorMessage && (
                <div className="validation-error">
                  {formData.middleName.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="passport">Серия и номер паспорта</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'passport', formData, setFormData)}
                id="passport"
                placeholder="1111 111111"
                name="passport"
                className={classnames('form-control', { error: formData.passport.errorMessage })}
                mask={getMask('passportNumberSerie').mask}
                value={formData.passport.value}
                inputMode="tel"
              />
              {formData.passport.errorMessage && (
                <div className="validation-error">
                  {formData.passport.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="passportDate">Дата выдачи паспорта</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={() => handleValidate(null, { fieldName: 'passportDate' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'passportDate' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'passportDate' })}
                className={classnames('form-control', { error: formData.passportDate.errorMessage })}
                selected={formData.passportDate.value ? formData.passportDate.value : null}
                onChange={(e) => handleChange(e, 'passportDate', formData, setFormData)}
                name="passportDate"
                id="passportDate"
                locale={ru}
                maxDate={new Date()}
                minDate={subYears(new Date(), 100)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.passportDate.errorMessage && (
                <div className="validation-error">
                  {formData.passportDate.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="birthday">Дата рождения</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={() => handleValidate(null, { fieldName: 'birthday' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'birthday' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'birthday' })}
                className={classnames('form-control', { error: formData.birthday.errorMessage })}
                selected={formData.birthday.value ? formData.birthday.value : null}
                onChange={(e) => handleChange(e, 'birthday', formData, setFormData)}
                name="birthday"
                id="birthday"
                locale={ru}
                maxDate={new Date()}
                minDate={subYears(new Date(), 100)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.birthday.errorMessage && (
                <div className="validation-error">
                  {formData.birthday.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-9">
              <label htmlFor="passportDivision">Кем выдан</label>
              <Tippy
                className="tippy-custom"
                arrow={false}
                offset={[0, 5]}
                content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
                hideOnClick={false}
              >
                <div className="d-inline-block">
                  <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                </div>
              </Tippy>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'passportDivision', formData, setFormData)}
                id="passportDivision"
                name="passportDivision"
                placeholder=""
                className={classnames('form-control', { error: formData.passportDivision.errorMessage })}
                value={formData.passportDivision.value}
              />
              {formData.passportDivision.errorMessage && (
                <div className="validation-error">
                  {formData.passportDivision.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="passportUnitNumber">Код подразделения</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'passportUnitNumber', formData, setFormData, null, { pureValue: true })}
                id="passportUnitNumber"
                name="passportUnitNumber"
                mask={getMask('passportUnitNumber').mask}
                placeholder="123-456"
                formatChars={getMask('passportUnitNumber').formatChars}
                className={classnames('form-control', { error: formData.passportUnitNumber.errorMessage })}
                value={formData.passportUnitNumber.value}
              />
              {formData.passportUnitNumber.errorMessage && (
                <div className="validation-error">
                  {formData.passportUnitNumber.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3">
              <label htmlFor="gender">Пол</label>
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                onBlur={handleValidate}
                isDisabled={disableField}
                name="gender"
                noOptionsMessage={() => 'Не найдено'}
                inputId="gender"
                isSearchable={false}
                value={formData.gender.value.value ? formData.gender.value : null}
                onChange={(e) => handleChange(e, 'gender', formData, setFormData)}
                options={genderOptions}
              />
            </div>
            {(chosenOffer.companyCode === 'gelios') ? (
              <div className="col-lg-4 col-xl-3">
                <label htmlFor="inn">ИНН</label>
                <InputMask
                  maskChar=""
                  onBlur={handleValidate}
                  disabled={disableField}
                  onChange={(e) => handleChange(e, 'inn', formData, setFormData)}
                  id="inn"
                  name="inn"
                  className={classnames('form-control', { error: formData.inn.errorMessage })}
                  value={formData.inn.value}
                />
                {formData.inn.errorMessage && (
                  <div className="validation-error">
                    {formData.inn.errorMessage}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {(chosenOffer.companyCode === 'renessans') ? (
            <div className="form-group row">
              <div className="col-lg-12 col-xl-6">
                <label htmlFor="birthPlace">Место рождения</label>
                <Tippy
                  className="tippy-custom"
                  arrow={false}
                  offset={[0, 5]}
                  content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
                  hideOnClick={false}
                >
                  <div className="d-inline-block">
                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                  </div>
                </Tippy>
                <InputMask
                  maskChar=""
                  onBlur={handleValidate}
                  disabled={disableField}
                  onChange={(e) => handleChange(e, 'birthPlace', formData, setFormData)}
                  id="birthPlace"
                  name="birthPlace"
                  className={classnames('form-control', { error: formData.birthPlace.errorMessage })}
                  value={formData.birthPlace.value}
                />
                {formData.birthPlace.errorMessage && (
                  <div className="validation-error">
                    {formData.birthPlace.errorMessage}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insuredAddress">{(Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'rgs') ? 'Место рождения' : 'Фактический адрес'}</label>
              <ReactDadata
                id="insuredAddress"
                name="insuredAddress"
                className={classnames('form-control', { error: formData.insuredAddress.errorMessage })}
                query={formData.insuredAddress.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insuredAddress', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField}
                onFocus={setCursorToEnd}
              />
              {formData.insuredAddress.errorMessage && (
                <div className="validation-error">
                  {formData.insuredAddress.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className="form-check custom-checkbox">
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.addressRegistrationFlag.value}
                  name="addressRegistrationFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="addressRegistrationFlag"
                />
                <label className="form-check-label" htmlFor="addressRegistrationFlag">
                  {(Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'rgs') ? 'Место рождения и адрес регистрации совпадают' : 'Фактический адрес и адрес регистрации совпадают'}
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insuredAddressRegistration">Адрес регистрации</label>
              <ReactDadata
                id="insuredAddressRegistration"
                name="insuredAddressRegistration"
                className={classnames('form-control', { error: formData.insuredAddressRegistration.errorMessage })}
                query={formData.insuredAddressRegistration.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insuredAddressRegistration', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField || formData.addressRegistrationFlag.value}
                onFocus={setCursorToEnd}
                ref={insuredAddressRegistrationRef}
              />
              {formData.insuredAddressRegistration.errorMessage && (
                <div className="validation-error">
                  {formData.insuredAddressRegistration.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">2</span>
          <span>Объект страхования</span>
        </div>
        <div className="card-body">
          {/* {(chosenOffer && Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'renessans') ? (
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="pType">Тип</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  onBlur={handleValidate}
                  isDisabled={disableField}
                  name="pType"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="pType"
                  isSearchable={false}
                  value={formData.pType.value.value ? formData.pType.value : null}
                  onChange={(e) => handleChange(e, 'pType', formData, setFormData)}
                  options={pTypeOptions}
                />
              </div>
            </div>
          ) : null} */}
          {(chosenOffer && Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'renessans2') ? (
            <>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="pType">Тип</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    onBlur={handleValidate}
                    isDisabled={disableField}
                    name="pType"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="pType"
                    isSearchable={false}
                    value={formData.pType.value.value ? formData.pType.value : null}
                    onChange={(e) => handleChange(e, 'pType', formData, setFormData)}
                    options={pTypeOptions}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="pProgram">Программа</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    onBlur={handleValidate}
                    name="pProgram"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="pProgram"
                    isSearchable={false}
                    value={formData.pProgram.value.value ? formData.pProgram.value : null}
                    onChange={(e) => handleChange(e, 'pProgram', formData, setFormData)}
                    options={pProgramOptions[formData.pType.value.value]}
                  />
                  {formData.pProgram.errorMessage && (
                    <div className="validation-error">
                      {formData.pProgram.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <label htmlFor="insType">Вариант страхования</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    onBlur={handleValidate}
                    isDisabled={disableField}
                    name="insType"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="insType"
                    isSearchable={false}
                    value={formData.insType.value.value ? formData.insType.value : null}
                    onChange={(e) => handleChange(e, 'insType', formData, setFormData)}
                    options={insTypeOpts}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label>Покрытия</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="coverName"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="coverName"
                    isSearchable={false}
                    value={addingCoverName}
                    onChange={(e) => { setAddingCoverName(e); }}
                    options={coverzOpts}
                  />
                </div>
                <div className="col-lg-3">
                  <CurrencyInput
                    inputMode="decimal"
                    id="coverzSum"
                    disabled={disableField}
                    name="coverSum"
                    decimalSeparator="."
                    placeholder="Страховая сумма"
                    value={coverSum}
                    onChange={(e) => {
                      setCoverSum(e.target.value.replace(/[^\d.]/g, ''));
                    }}
                    decimalsLimit={2}
                    className={classnames('form-control', { error: coverSumErrorMessage })}
                  />
                  {coverSumErrorMessage && (
                    <div className="validation-error">
                      {coverSumErrorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <button style={{ marginTop: 0, display: coverzOpts.length ? 'block' : 'none' }} disabled={disableField} onClick={() => addCover()} type="button" className="btn btn-border btn-success">+ Добавить</button>
                </div>
              </div>
              <div className="form-group row">
                {formData.coverz.value.map((el) => (
                  <div key={el.val.value} style={{ display: 'table-row', marginLeft: '20px', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '75%' }}>
                      <div style={{ width: '33%' }}>{el.val.label}</div>
                      <div style={{ width: '33%' }}>{el.sum}</div>
                      <div style={{ width: '33%' }}>
                        <button style={{ marginTop: 0 }} disabled={disableField} onClick={() => delCover(el)} type="button" className="btn btn-border btn-danger">- Удалить</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="compensationType">Тип компенсации</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="compensationType"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="compensationType"
                    isSearchable={false}
                    value={formData.compensationType.value.value ? formData.compensationType.value : null}
                    onChange={(e) => handleChange(e, 'compensationType', formData, setFormData)}
                    options={compensationTypeOpts}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="insuranceTerritoryCode">Код территории страхования:</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="insuranceTerritoryCode"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="insuranceTerritoryCode"
                    isSearchable
                    value={formData.insuranceTerritoryCode.value.value ? formData.insuranceTerritoryCode.value : null}
                    onChange={(e) => handleChange(e, 'insuranceTerritoryCode', formData, setFormData)}
                    options={insuranceTerritoryCodeOpts}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label>Атрибуты</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="attrOpt"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="attrOpt"
                    isSearchable={false}
                    value={attrOpt}
                    onChange={(e) => { setAttrOpt(e); setAttrVal(''); }}
                    options={attrsOpts}
                  />
                </div>
                <div className="col-lg-3">
                  {getAttrVals()}
                  {attrErrorMessage && (
                    <div className="validation-error">
                      {attrErrorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <button style={{ marginTop: 0, display: attrsOpts.length ? 'block' : 'none' }} disabled={disableField} onClick={() => addAttr()} type="button" className="btn btn-border btn-success">+ Добавить</button>
                </div>
              </div>
              <div className="form-group row">
                {formData.attrs.value.map((el) => (
                  <div key={el.val.value} style={{ display: 'table-row', marginLeft: '20px', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '75%' }}>
                      <div style={{ width: '33%' }}>{el.code.label}</div>
                      <div style={{ width: '33%' }}>{getValLabel(el.value)}</div>
                      <div style={{ width: '33%' }}>
                        <button style={{ marginTop: 0 }} disabled={disableField} onClick={() => delAttr(el)} type="button" className="btn btn-border btn-danger">- Удалить</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {(chosenOffer && Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'euroins') ? (
            <>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Риски</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="flatSum">Квартира</label>
                  <CurrencyInput
                    maxLength="20"
                    inputMode="decimal"
                    id="flatSum"
                    disabled={disableField}
                    name="flatSum"
                    decimalSeparator="."
                    placeholder="Страховая сумма"
                    value={formData.flatSum.value}
                    onChange={(e) => handleChange(e, 'flatSum', formData, setFormData)}
                    decimalsLimit={2}
                    className={classnames('form-control', { error: formData.flatSum.errorMessage })}
                  />
                  {formData.flatSum.errorMessage && (
                    <div className="validation-error">
                      {formData.flatSum.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <label htmlFor="homeProperty">Имущество</label>
                  <CurrencyInput
                    inputMode="decimal"
                    id="homeProperty"
                    disabled={disableField}
                    name="homeProperty"
                    decimalSeparator="."
                    placeholder="Страховая сумма"
                    value={formData.homeProperty.value}
                    onChange={(e) => handleChange(e, 'homeProperty', formData, setFormData)}
                    decimalsLimit={2}
                    className={classnames('form-control', { error: formData.homeProperty.errorMessage })}
                  />
                  {formData.homeProperty.errorMessage && (
                    <div className="validation-error">
                      {formData.homeProperty.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <label htmlFor="civilResponsibility">Гражданская ответственность</label>
                  <CurrencyInput
                    inputMode="decimal"
                    id="civilResponsibility"
                    disabled={disableField}
                    name="civilResponsibility"
                    decimalSeparator="."
                    placeholder="Страховая сумма"
                    value={formData.civilResponsibility.value}
                    onChange={(e) => handleChange(e, 'civilResponsibility', formData, setFormData)}
                    decimalsLimit={2}
                    className={classnames('form-control', { error: formData.civilResponsibility.errorMessage })}
                  />
                  {formData.civilResponsibility.errorMessage && (
                    <div className="validation-error">
                      {formData.civilResponsibility.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3">
                  <label htmlFor="decoration">Декор</label>
                  <CurrencyInput
                    inputMode="decimal"
                    id="decoration"
                    disabled={disableField}
                    name="decoration"
                    decimalSeparator="."
                    placeholder="Страховая сумма"
                    value={formData.decoration.value}
                    onChange={(e) => handleChange(e, 'decoration', formData, setFormData)}
                    decimalsLimit={2}
                    className={classnames('form-control', { error: formData.decoration.errorMessage })}
                  />
                  {formData.decoration.errorMessage && (
                    <div className="validation-error">
                      {formData.decoration.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Данные</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-2">
                  <label htmlFor="area">Площадь</label>
                  <InputMask
                    inputMode="decimal"
                    maskChar=""
                    onBlur={handleValidate}
                    onChange={(e) => handleChange(e, 'area', formData, setFormData)}
                    id="area"
                    placeholder="120"
                    name="area"
                    className={classnames('form-control', { error: formData.area.errorMessage })}
                    value={formData.area.value || ''}
                    disabled={disableField}
                  />
                  {formData.area.errorMessage && (
                    <div className="validation-error">
                      {formData.area.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-2">
                  <label htmlFor="roomsCount">Количество комнат</label>
                  <InputMask
                    inputMode="decimal"
                    maskChar=""
                    onBlur={handleValidate}
                    onChange={(e) => handleChange(e, 'roomsCount', formData, setFormData)}
                    id="roomsCount"
                    placeholder="3"
                    name="roomsCount"
                    className={classnames('form-control', { error: formData.roomsCount.errorMessage })}
                    value={formData.roomsCount.value || ''}
                    disabled={disableField}
                  />
                  {formData.roomsCount.errorMessage && (
                    <div className="validation-error">
                      {formData.roomsCount.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-2">
                  <label htmlFor="flatFloor">Этаж</label>
                  <InputMask
                    inputMode="decimal"
                    maskChar=""
                    onBlur={handleValidate}
                    onChange={(e) => handleChange(e, 'flatFloor', formData, setFormData)}
                    id="flatFloor"
                    placeholder="3"
                    name="flatFloor"
                    className={classnames('form-control', { error: formData.flatFloor.errorMessage })}
                    value={formData.flatFloor.value || ''}
                    disabled={disableField}
                  />
                  {formData.flatFloor.errorMessage && (
                    <div className="validation-error">
                      {formData.flatFloor.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-2">
                  <label htmlFor="flatMaterial">Материал</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="flatMaterial"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="flatMaterial"
                    isSearchable={false}
                    value={formData.flatMaterial.value.value ? formData.flatMaterial.value : null}
                    onChange={(e) => handleChange(e, 'flatMaterial', formData, setFormData)}
                    options={flatMaterialOpts}
                  />
                  {formData.flatMaterial.errorMessage && (
                    <div className="validation-error">
                      {formData.flatMaterial.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="programm">Программа</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customStyles}
                    isDisabled={disableField}
                    name="programm"
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="programm"
                    isSearchable={false}
                    value={formData.programm.value.value ? formData.programm.value : null}
                    onChange={(e) => handleChange(e, 'programm', formData, setFormData)}
                    options={[{ value: 'A', label: 'A' }, { value: 'B', label: 'B' }]}
                  />
                  {formData.programm.errorMessage && (
                    <div className="validation-error">
                      {formData.programm.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
          <div className="form-group row">
            <div className="col-lg-12 column-margin">
              <button disabled={disableField} type="button" className="btn btn-success mr-2 column-margin" onClick={() => copyAddress('insuredAddressRegistration')}>Скопировать адрес регистрации</button>
              <button disabled={disableField} type="button" className="btn btn-success column-margin" onClick={() => copyAddress('insuredAddress')}>Скопировать фактический адрес</button>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <label htmlFor="region">Адрес</label>
              <ReactDadata
                id="region"
                name="region"
                className={classnames('form-control', { error: formData.region.errorMessage })}
                query={formData.region.value.value}
                onBlur={handleValidate}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'region', formData, setFormData)}
                disabled={disableField}
                onFocus={setCursorToEnd}
                ref={regionRef}
              />
              {formData.region.errorMessage && (
                <div className="validation-error">
                  {formData.region.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">3</span>
          <span>Данные о полисе</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className={classnames('col-lg-2')}>
              <label htmlFor="period">Тип полиса</label>
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                isDisabled
                onBlur={handleValidate}
                name="period"
                inputId="period"
                isSearchable={false}
                value={formData.period.value.value ? formData.period.value : null}
                onChange={(e) => handleChange(e, 'period', formData, setFormData)}
                options={formData.periodOptions}
              />
            </div>
            <div className="col-lg-2">
              <label htmlFor="startDate">Дата начала</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                className={classnames('form-control', { error: formData.startDate.errorMessage })}
                selected={formData.startDate.value ? formData.startDate.value : null}
                onChange={(e) => handleChange(e, 'startDate', formData, setFormData)}
                name="startDate"
                id="startDate"
                onBlur={() => handleValidate(null, { fieldName: 'startDate', periodType: formData.period.value.value })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'startDate', periodType: formData.period.value.value })}
                onClickOutside={() => handleValidate(null, { fieldName: 'startDate', periodType: formData.period.value.value })}
                locale={ru}
                minDate={addDays(new Date(), 1)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.startDate.errorMessage && (
                <div className="validation-error">
                  {formData.startDate.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-2">
              <label htmlFor="endDate">Дата окончания</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={formData.period.value.value === 1 || disableField}
                className={classnames('form-control', { error: formData.endDate.errorMessage })}
                selected={formData.endDate.value ? formData.endDate.value : null}
                onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
                name="endDate"
                id="endDate"
                onBlur={() => handleValidate(null, { fieldName: 'endDate' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'endDate' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'endDate' })}
                locale={ru}
                minDate={addDays(new Date(), 5)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.endDate.errorMessage && (
                <div className="validation-error">
                  {formData.endDate.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <SimpleNotification>Срок страхования - 1 год. Дата начала - следующий день после оплаты.</SimpleNotification>
            </div>
          </div>
          <div className="form-group row">
            {(chosenOffer && Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'alpha')
              ? (
                <div className="col-lg-3">
                  <label htmlFor="promo">Промо-код: </label>
                  <InputMask
                    maskChar=""
                    onBlur={false}
                    disabled={false}
                    placeholder=""
                    onChange={(e) => handleChange(e, 'promo', formData, setFormData)}
                    id="promo"
                    name="promo"
                    className={classnames('form-control', { error: formData.promo.errorMessage })}
                    value={formData.promo.value}
                  />
                  {formData.promo.errorMessage && (
                    <div className="validation-error">
                      {formData.promo.errorMessage}
                    </div>
                  )}
                </div>
              )
              : null}
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">4</span>
          <span>Контактная информация</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="contactPhone">Телефон</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactPhone', formData, setFormData)}
                id="contactPhone"
                placeholder="+7 (999) 999-99-99"
                name="contactPhone"
                className={classnames('form-control', { error: formData.contactPhone.errorMessage })}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
                value={formData.contactPhone.value}
              />
              {formData.contactPhone.errorMessage && (
              <div className="validation-error">
                {formData.contactPhone.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-6 align-content-end">
              <SimpleNotification>Указывайте валидный телефон клиента</SimpleNotification>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="contactEmail">Email</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactEmail', formData, setFormData)}
                id="contactEmail"
                placeholder="your@email.ru"
                name="contactEmail"
                className={classnames('form-control', { error: formData.contactEmail.errorMessage })}
                value={formData.contactEmail.value}
                mask={getMask('email').mask}
                formatChars={getMask('email').formatChars}
              />
              {formData.contactEmail.errorMessage && (
              <div className="validation-error">
                {formData.contactEmail.errorMessage}
              </div>
              )}
            </div>
            <div className="col-lg-8 align-content-end">
              <SimpleNotification>Вы получите электронную версию документа на указанный адрес электронной почты</SimpleNotification>
            </div>
          </div>
          {phoneCheckRequestFlag ? (
            <div className="form-group row">
              <div className="col-12">
                <LoadingSpinner className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={20} width={20} />
                Проверяем телефон...
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">5</span>
          <span>Финальные условия</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.personalDataFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.personalDataFlag.value}
                  name="personalDataFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="personalDataFlag"
                />
                <label className="form-check-label" htmlFor="personalDataFlag">Согласен с передачей <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${privacyPolicy}`}>персональных данных</a></label>
                {formData.personalDataFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.personalDataFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.contractInfoFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.contractInfoFlag.value}
                  name="contractInfoFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="contractInfoFlag"
                />
                <label className="form-check-label" htmlFor="contractInfoFlag">Подтверждаю полноту и достоверность предоставленных данных в договоре страхования</label>
                {formData.contractInfoFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.contractInfoFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.insuranceRulesFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.insuranceRulesFlag.value}
                  name="insuranceRulesFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="insuranceRulesFlag"
                />
                <label className="form-check-label" htmlFor="insuranceRulesFlag">
                  {companies[chosenOffer.companyCode] && companies[chosenOffer.companyCode].rules && companies[chosenOffer.companyCode].rules[draftInfo.type] && companies[chosenOffer.companyCode].rules[draftInfo.type] ? (
                    <>Согласен с <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].rules[draftInfo.type]}`}>правилами страхования</a></>
                  ) : (
                    <>Согласен с правилами страхования</>
                  )}
                </label>
                {formData.insuranceRulesFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.insuranceRulesFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <SimpleNotification>Осуществляя покупку полиса и заполняя настоящие формы, я подтверждаю, что даю согласие выбранной страховой компании на обработку персональных данных и информации, в том числе на сбор, систематизацию, блокирование, уничтожение персональных данных и иные действия, предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» в целях определения условий и заключения со мной договора страхования, проведения маркетинговых исследований. При этом такое согласие я даю на неограниченный срок и могу отозвать согласие в любое время путем передачи страховой компании соответствующего уведомления. Безопасность передаваемой информации обеспечивается с помощью современных протоколов обеспечения безопасности в интернете (SSL/TLS).</SimpleNotification>
            </div>
          </div>
        </div>
      </div>
      {(userInfo.full_time_employee === 1 || customFieldOptions.length > 0) ? (
        <AccountSystem
          customFieldName={customFieldName}
          customFieldOptions={customFieldOptions}
          customField={customField}
          setCustomField={setCustomField}
          blockNumber="6"
          disableField={disableField}
          setLinkToDeal={setLinkToDeal}
          linkToDeal={linkToDeal}
          leadSourcesOptions={leadSourcesOptions}
          leadSource={leadSource}
          setLeadSource={setLeadSource}
          managerAttractedOptions={managerAttractedOptions}
          managerAttracted={managerAttracted}
          setManagerAttracted={setManagerAttracted}
          customStyles={customStyles}
          newAgent={newAgent}
          setNewAgent={setNewAgent}
        />
      ) : null}
      <div className="form-row row-grid">
        <div className="col-lg-12">
          {!policy.is_demo || urlParams.adminEdit ? (
            <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={(e) => saveDraft(e, false, true)}>Сохранить</button>
          ) : null}
          <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={(e) => goToBuying(e)}>Оформить</button>
        </div>
      </div>
    </form>
  );
}
