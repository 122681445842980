import React from 'react';
import ReactSVG from 'react-svg';
import cross from '../../../img/cross.svg';

export default function Slide(props) {
  const {
    alt, img, hideSlider, title, body, button, history,
  } = props;
  const submitButton = (e) => {
    e.preventDefault();
    if (button.target === '_self') {
      history.push(button.path);
    }
    if (button.target === '_blank') {
      window.open(button.path, '_blank');
    }
  };
  return (
    <div
      className="position-relative overflow-hidden h-100"
      style={{
        height: '264px',
      }}
    >
      <div
        style={{
          height: '262px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          alt={alt}
          style={{
            position: 'absolute',
          }}
          className="slick-slider--banner"
          src={img}
        />
        <ReactSVG
          className="slick-slider--cross"
          onClick={hideSlider}
          src={cross}
          style={{
            top: '16px',
            right: '16px',
            position: 'absolute',
            cursor: 'pointer',
          }}
        />
        <div
          style={{
            paddingTop: '24px',
            paddingLeft: '24px',
            zIndex: 1,
          }}
        >
          <div
            className="slick-slider--title"
            style={{
              fontWeight: '600',
            }}
          >
            {title}
          </div>
        </div>
        <div
          className="slick-slider--body"
          style={{
            marginTop: '12px',
            marginLeft: '24px',
            fontWeight: '500',
            color: '#737373',
            zIndex: 1,
            whiteSpace: 'pre-line',
          }}
        >
          {body}
        </div>
        <div
          style={{
            marginTop: 'auto',
            marginLeft: '24px',
            marginBottom: '77px',
          }}
        >
          {button.bitrix24Form ? <script data-b24-form="click/219/76t3k0" data-skip-moving="true" /> : null}
          <button onClick={(e) => submitButton(e)} type="button" className="btn btn--gray-border">{button.text}</button>
        </div>
      </div>
    </div>
  );
}
