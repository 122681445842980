import React, {
  useContext, createRef, useState, useEffect,
} from 'react';
import { format, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  getDefaultSorting7, onFilteredChangeCustom, onTableCellClick,
  setTableSettings7,
  setTableSorting,
} from '../../Utils/Helpers';
import Table from '../../Layout/Table';

export default function PaymentRealizationsTable(props) {
  const { history } = props;
  const tableRef = createRef();
  const defaultSorting = getDefaultSorting7('paymentRealizationTableSettings');
  const [filtered, setFiltered] = useState([]);
  const [paymentRealizations, setPaymentRealizations] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingPaymentRealizationsFlag, setLoadingPaymentRealizationsFlag] = useState(false);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const getPaymentRealizations = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingPaymentRealizationsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/paymentRealizations`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setPaymentRealizations(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingPaymentRealizationsFlag(false);
      });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'ID',
      accessor: 'id',
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').id,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Дата выплаты',
      accessor: (d) => (d.payment_date ? format(parseISO(d.payment_date), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').payment_date,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_payment_date !== false,
      showCustomized: true,
    }, {
      Header: 'Начало периода',
      accessor: (d) => (d.start_period ? format(parseISO(d.start_period), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').start_period,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_start_period !== false,
      showCustomized: true,
    }, {
      Header: 'Окончание периода',
      accessor: (d) => (d.end_period ? format(parseISO(d.end_period), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').end_period,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_end_period !== false,
      showCustomized: true,
    }, {
      Header: 'Сумма к выплате',
      accessor: 'full_sum',
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').full_sum,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_full_sum !== false,
      showCustomized: true,
    }, {
      Header: 'Выплачено',
      accessor: 'success_sum',
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').success_sum,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_success_sum !== false,
      showCustomized: true,
    }, {
      Header: 'Количество ошибок',
      accessor: 'errors_count',
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').errors_count,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_errors_count !== false,
      showCustomized: true,
    }, {
      Header: 'Ответственный',
      id: 'agent',
      accessor: (d) => (d.agent ? `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}` : ''),
      width: reactLocalStorage.getObject('paymentRealizationTableSettings').agent,
      show: reactLocalStorage.getObject('paymentRealizationTableSettings').show_agent !== false,
      showCustomized: true,
    },
    ];
    setColumns(updatedColumns);
  };

  useEffect(() => {
    getPaymentRealizations();
    updateTableColumns();
  }, []);

  return (
    <>
      <Table
        onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'paymentRealizations', history)}
        ref={tableRef}
        defaultSorted={defaultSorting}
        defaultFiltered={filtered}
        data={paymentRealizations}
        columns={columns}
        loading={loadingPaymentRealizationsFlag}
        noDataText="Реализации не найдены"
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'paymentRealizationTableSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'paymentRealizationTableSettings');
        }}
        onFilteredChange={(filtered) => setFiltered(onFilteredChangeCustom(filtered))}
      />
    </>
  );
}
