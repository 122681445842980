import { customFetch } from './Helpers';

const getBanks = (token, insurance = '') => customFetch(`${process.env.REACT_APP_API_DOMAIN}/banks?insurance=${insurance}`, {
  headers: {
    Authorization: token,
  },
})
  .then((response) => response.json());
export default getBanks;

const getBanksClassifiers = (token) => customFetch(`${process.env.REACT_APP_API_DOMAIN}/banks-classifiers`, {
  headers: {
    Authorization: token,
  },
})
  .then((response) => response.json());
export { getBanksClassifiers };

const getBanksToInsurance = (token) => customFetch(`${process.env.REACT_APP_API_DOMAIN}/banks-to-insurance`, {
  headers: {
    Authorization: token,
  },
})
  .then((response) => response.json());
export { getBanksToInsurance };

const getClassifiers = (token, product) => customFetch(`${process.env.REACT_APP_API_DOMAIN}/classifiers/${product}`, {
  headers: {
    Authorization: token,
  },
})
  .then((response) => response.json());
export { getClassifiers };
