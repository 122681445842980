import React from 'react';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../Stores/UserInfoStore';

const withUserInfoStoreStore = (BaseComponent) => {
  const WrappedComponent = React.forwardRef((props, ref) => {
    const { userInfo, theme, setUserInfo } = useUserInfoStore(
      (state) => ({
        userInfo: state.userInfo,
        theme: state.theme,
        setUserInfo: state.setUserInfo,
      }),
      shallow,
    );
    return (
      <BaseComponent
        {...props}
        ref={ref}
        userInfo={userInfo}
        theme={theme}
        setUserInfo={setUserInfo}
      />
    );
  });

  return WrappedComponent;
};

export default withUserInfoStoreStore;
