import React from 'react';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import {
  addYears, format, parseISO, subYears,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Tippy from '@tippyjs/react';
import DatePicker from 'react-datepicker';
import getMask from '../../../Utils/Masks';
import { handleChange } from '../../../Utils/Helpers';

export default function Owner({ props }) {
  const {
    formData,
    disableField,
    handleValidate,
    toggleFlag,
    setCursorToEnd,
    ownerAddressRegistrationRef,
    setFormData,
  } = props;

  const type = formData.ownerTypeCode.value ? formData.ownerTypeCode.value.value : '';

  if (type === 'individual') {
    return (
      <div className="card-body">
        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="ownerCompanyName">Наименование</label>
            <InputMask
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerCompanyName', formData, setFormData, null)}
              id="ownerCompanyName"
              name="ownerCompanyName"
              placeholder="Введите наименование компании"
              formatChars={getMask('name').formatChars}
              className={classnames('form-control', { error: formData.ownerCompanyName.errorMessage })}
              value={formData.ownerCompanyName.value}
            />
            {formData.ownerCompanyName.errorMessage && (
              <div className="validation-error">
                {formData.ownerCompanyName.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerOGRN">ОГРН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerOGRN', formData, setFormData, null, { pureValue: true })}
              id="ownerOGRN"
              name="ownerOGRN"
              mask={getMask('ogrn').mask}
              placeholder="11111111111"
              formatChars={getMask('ogrn').formatChars}
              className={classnames('form-control', { error: formData.ownerOGRN.errorMessage })}
              value={formData.ownerOGRN.value}
            />
            {formData.ownerOGRN.errorMessage && (
              <div className="validation-error">
                {formData.ownerOGRN.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerINN">ИНН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerINN', formData, setFormData, null, { pureValue: true })}
              id="ownerINN"
              name="ownerINN"
              mask={getMask('inn').mask}
              placeholder="1111111111"
              formatChars={getMask('inn').formatChars}
              className={classnames('form-control', { error: formData.ownerINN.errorMessage })}
              value={formData.ownerINN.value}
            />
            {formData.ownerINN.errorMessage && (
              <div className="validation-error">
                {formData.ownerINN.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_1">
            <label htmlFor="ownerLastName">Фамилия</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerLastName"
              name="ownerLastName"
              placeholder="Иванов"
              query={formData.ownerLastName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerLastName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['SURNAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerLastName.errorMessage })}
            />
            {formData.ownerLastName.errorMessage && (
              <div className="validation-error">
                {formData.ownerLastName.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
            <label htmlFor="ownerFirstName">Имя</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerFirstName"
              name="ownerFirstName"
              placeholder="Иван"
              query={formData.ownerFirstName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerFirstName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['NAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerFirstName.errorMessage })}
            />
            {formData.ownerFirstName.errorMessage && (
              <div className="validation-error">
                {formData.ownerFirstName.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="ownerMiddleName">Отчество</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerMiddleName"
              name="ownerMiddleName"
              placeholder="Иванович"
              query={formData.ownerMiddleName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerMiddleName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['PATRONYMIC']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerMiddleName.errorMessage })}
            />
            {formData.ownerMiddleName.errorMessage && (
              <div className="validation-error">
                {formData.ownerMiddleName.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerPassport">Серия и номер паспорта</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassport', formData, setFormData, null, { pureValue: true })}
              id="ownerPassport"
              placeholder="1111 111111"
              name="ownerPassport"
              className={classnames('form-control', { error: formData.ownerPassport.errorMessage })}
              mask={getMask('passportNumberSerie').mask}
              value={formData.ownerPassport.value}
              inputMode="tel"
            />
            {formData.ownerPassport.errorMessage && (
              <div className="validation-error">
                {formData.ownerPassport.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="ownerPassportDate">Дата выдачи паспорта</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              className={classnames('form-control', { error: formData.ownerPassportDate.errorMessage })}
              selected={formData.ownerPassportDate.value ? parseISO(formData.ownerPassportDate.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'ownerPassportDate', formData, setFormData)}
              name="insurerPassportDate"
              id="insurerPassportDate"
              locale={ru}
              maxDate={new Date()}
              minDate={formData.ownerBirthday.value ? addYears(parseISO(formData.ownerBirthday.value), 14) : subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.ownerPassportDate.errorMessage && (
              <div className="validation-error">
                {formData.ownerPassportDate.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-9">
            <label htmlFor="ownerPassportDivision">Кем выдан</label>
            <Tippy
              className="tippy-custom"
              arrow={false}
              offset={[0, 5]}
              content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
              hideOnClick={false}
            >
              <div className="d-inline-block">
                <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
              </div>
            </Tippy>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassportDivision', formData, setFormData)}
              id="ownerPassportDivision"
              name="ownerPassportDivision"
              placeholder=""
              className={classnames('form-control', { error: formData.ownerPassportDivision.errorMessage })}
              value={formData.ownerPassportDivision.value}
            />
            {formData.ownerPassportDivision.errorMessage && (
              <div className="validation-error">
                {formData.ownerPassportDivision.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="ownerPassportUnitNumber">Код подразделения</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassportUnitNumber', formData, setFormData, null, { pureValue: true })}
              id="ownerPassportUnitNumber"
              name="ownerPassportUnitNumber"
              mask={getMask('passportUnitNumber').mask}
              placeholder="123-456"
              formatChars={getMask('passportUnitNumber').formatChars}
              className={classnames('form-control', { error: formData.ownerPassportUnitNumber.errorMessage })}
              value={formData.ownerPassportUnitNumber.value}
            />
            {formData.ownerPassportUnitNumber.errorMessage && (
              <div className="validation-error">
                {formData.ownerPassportUnitNumber.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerBirthday">Дата рождения</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              className={classnames('form-control', { error: formData.ownerBirthday.errorMessage })}
              selected={formData.ownerBirthday.value ? parseISO(formData.ownerBirthday.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'ownerBirthday', formData, setFormData)}
              name="ownerBirthday"
              id="ownerBirthday"
              locale={ru}
              maxDate={new Date()}
              minDate={subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.ownerBirthday.errorMessage && (
              <div className="validation-error">
                {formData.ownerBirthday.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerActualAddress">Фактический адрес</label>
            <ReactDadata
              id="ownerActualAddress"
              name="ownerActualAddress"
              className={classnames('form-control', { error: formData.ownerActualAddress.errorMessage })}
              query={formData.ownerActualAddress.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerActualAddress', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField}
              onFocus={setCursorToEnd}
            />
            {formData.ownerActualAddress.errorMessage && (
              <div className="validation-error">
                {formData.ownerActualAddress.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className="form-check custom-checkbox">
              <input
                disabled={disableField}
                onChange={toggleFlag}
                checked={formData.ownerAddressRegistrationFlag.value}
                name="ownerAddressRegistrationFlag"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="ownerAddressRegistrationFlag"
              />
              <label className="form-check-label" htmlFor="ownerAddressRegistrationFlag">Фактический адрес и адрес регистрации совпадают</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerAddressRegistration">Адрес регистрации</label>
            <ReactDadata
              id="ownerAddressRegistration"
              name="ownerAddressRegistration"
              className={classnames('form-control', { error: formData.ownerAddressRegistration.errorMessage })}
              query={formData.ownerAddressRegistration.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerAddressRegistration', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField || formData.ownerAddressRegistration.value}
              onFocus={setCursorToEnd}
              ref={ownerAddressRegistrationRef}
            />
            {formData.ownerAddressRegistration.errorMessage && (
              <div className="validation-error">
                {formData.ownerAddressRegistration.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'legal') {
    return (
      <div className="card-body">
        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="ownerCompanyName">Наименование</label>
            <InputMask
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerCompanyName', formData, setFormData, null)}
              id="ownerCompanyName"
              name="ownerCompanyName"
              placeholder="Введите наименование компании"
              formatChars={getMask('name').formatChars}
              className={classnames('form-control', { error: formData.ownerCompanyName.errorMessage })}
              value={formData.ownerCompanyName.value}
            />
            {formData.ownerCompanyName.errorMessage && (
              <div className="validation-error">
                {formData.ownerCompanyName.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerOGRN">ОГРН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerOGRN', formData, setFormData, null, { pureValue: true })}
              id="ownerOGRN"
              name="ownerOGRN"
              mask={getMask('ogrn').mask}
              placeholder="1111111111111"
              formatChars={getMask('ogrn').formatChars}
              className={classnames('form-control', { error: formData.ownerOGRN.errorMessage })}
              value={formData.ownerOGRN.value}
            />
            {formData.ownerOGRN.errorMessage && (
              <div className="validation-error">
                {formData.ownerOGRN.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerINN">ИНН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerINN', formData, setFormData, null, { pureValue: true })}
              id="ownerINN"
              name="ownerINN"
              mask={getMask('inn').mask}
              placeholder="1111111111"
              formatChars={getMask('inn').formatChars}
              className={classnames('form-control', { error: formData.ownerINN.errorMessage })}
              value={formData.ownerINN.value}
            />
            {formData.ownerINN.errorMessage && (
              <div className="validation-error">
                {formData.ownerINN.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerKPP">КПП</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerKPP', formData, setFormData, null, { pureValue: true })}
              id="ownerKPP"
              name="ownerKPP"
              mask={getMask('kpp').mask}
              placeholder="11111111111"
              formatChars={getMask('kpp').formatChars}
              className={classnames('form-control', { error: formData.ownerKPP.errorMessage })}
              value={formData.ownerKPP.value}
            />
            {formData.ownerKPP.errorMessage && (
              <div className="validation-error">
                {formData.ownerKPP.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerLegalAddress">Юридический адрес</label>
            <ReactDadata
              id="ownerLegalAddress"
              name="ownerLegalAddress"
              className={classnames('form-control', { error: formData.ownerLegalAddress.errorMessage })}
              query={formData.ownerLegalAddress.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerLegalAddress', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField}
              onFocus={setCursorToEnd}
            />
            {formData.ownerLegalAddress.errorMessage && (
              <div className="validation-error">
                {formData.ownerLegalAddress.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerActualAddress">Фактический адрес</label>
            <ReactDadata
              id="ownerActualAddress"
              name="ownerActualAddress"
              className={classnames('form-control', { error: formData.ownerActualAddress.errorMessage })}
              query={formData.ownerActualAddress.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerActualAddress', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField}
              onFocus={setCursorToEnd}
            />
            {formData.ownerActualAddress.errorMessage && (
              <div className="validation-error">
                {formData.ownerActualAddress.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'natural') {
    return (
      <div className="card-body">
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_1">
            <label htmlFor="ownerLastName">Фамилия</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerLastName"
              name="ownerLastName"
              placeholder="Иванов"
              query={formData.ownerLastName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerLastName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['SURNAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerLastName.errorMessage })}
            />
            {formData.ownerLastName.errorMessage && (
            <div className="validation-error">
              {formData.ownerLastName.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
            <label htmlFor="ownerFirstName">Имя</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerFirstName"
              name="ownerFirstName"
              placeholder="Иван"
              query={formData.ownerFirstName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerFirstName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['NAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerFirstName.errorMessage })}
            />
            {formData.ownerFirstName.errorMessage && (
            <div className="validation-error">
              {formData.ownerFirstName.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="ownerMiddleName">Отчество</label>
            <ReactDadata
              onBlur={handleValidate}
              suggestionType="fio"
              id="ownerMiddleName"
              name="ownerMiddleName"
              placeholder="Иванович"
              query={formData.ownerMiddleName.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'ownerMiddleName', formData, setFormData, null, { dadataType: 'fio' })}
              parts={['PATRONYMIC']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.ownerMiddleName.errorMessage })}
            />
            {formData.ownerMiddleName.errorMessage && (
            <div className="validation-error">
              {formData.ownerMiddleName.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerPassport">Серия и номер паспорта</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassport', formData, setFormData, null, { pureValue: true })}
              id="ownerPassport"
              placeholder="1111 111111"
              name="ownerPassport"
              className={classnames('form-control', { error: formData.ownerPassport.errorMessage })}
              mask={getMask('passportNumberSerie').mask}
              value={formData.ownerPassport.value}
              inputMode="tel"
            />
            {formData.ownerPassport.errorMessage && (
            <div className="validation-error">
              {formData.ownerPassport.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="ownerPassportDate">Дата выдачи паспорта</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'ownerPassportDate' })}
              className={classnames('form-control', { error: formData.ownerPassportDate.errorMessage })}
              selected={formData.ownerPassportDate.value ? parseISO(formData.ownerPassportDate.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'ownerPassportDate', formData, setFormData)}
              name="ownerPassportDate"
              id="ownerPassportDate"
              locale={ru}
              maxDate={new Date()}
              minDate={formData.ownerBirthday.value ? addYears(formData.ownerBirthday.value, 14) : subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
                    }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.ownerPassportDate.errorMessage && (
            <div className="validation-error">
              {formData.ownerPassportDate.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-9">
            <label htmlFor="ownerPassportDivision">Кем выдан</label>
            <Tippy
              className="tippy-custom"
              arrow={false}
              offset={[0, 5]}
              content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
              hideOnClick={false}
            >
              <div className="d-inline-block">
                <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
              </div>
            </Tippy>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassportDivision', formData, setFormData)}
              id="ownerPassportDivision"
              name="ownerPassportDivision"
              placeholder=""
              className={classnames('form-control', { error: formData.ownerPassportDivision.errorMessage })}
              value={formData.ownerPassportDivision.value}
            />
            {formData.ownerPassportDivision.errorMessage && (
            <div className="validation-error">
              {formData.ownerPassportDivision.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="ownerPassportUnitNumber">Код подразделения</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'ownerPassportUnitNumber', formData, setFormData, null, { pureValue: true })}
              id="ownerPassportUnitNumber"
              name="ownerPassportUnitNumber"
              mask={getMask('passportUnitNumber').mask}
              placeholder="123-456"
              formatChars={getMask('passportUnitNumber').formatChars}
              className={classnames('form-control', { error: formData.ownerPassportUnitNumber.errorMessage })}
              value={formData.ownerPassportUnitNumber.value}
            />
            {formData.ownerPassportUnitNumber.errorMessage && (
            <div className="validation-error">
              {formData.ownerPassportUnitNumber.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerBirthday">Дата рождения</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              onCalendarClose={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              onClickOutside={() => handleValidate(null, { fieldName: 'ownerBirthday' })}
              className={classnames('form-control', { error: formData.ownerBirthday.errorMessage })}
              selected={formData.ownerBirthday.value ? parseISO(formData.ownerBirthday.value) : null}
              onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'ownerBirthday', formData, setFormData)}
              name="ownerBirthday"
              id="ownerBirthday"
              locale={ru}
              maxDate={new Date()}
              minDate={subYears(new Date(), 100)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.ownerBirthday.errorMessage && (
              <div className="validation-error">
                {formData.ownerBirthday.errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerINN">ИНН</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              placeholder="1111111111"
              formatChars={getMask('inn').formatChars}
              mask={getMask('inn').mask}
              onChange={(e) => handleChange(e, 'ownerINN', formData, setFormData)}
              id="ownerINN"
              name="ownerINN"
              className={classnames('form-control', { error: formData.ownerINN.errorMessage })}
              value={formData.ownerINN.value}
            />
            {formData.ownerINN.errorMessage && (
            <div className="validation-error">
              {formData.ownerINN.errorMessage}
            </div>
            )}
          </div>
          <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
            <label htmlFor="ownerSnils">СНИЛС</label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              placeholder="123–321–987 12"
              formatChars={getMask('snils').formatChars}
              mask={getMask('snils').mask}
              onChange={(e) => handleChange(e, 'ownerSnils', formData, setFormData)}
              id="ownerSnils"
              name="ownerSnils"
              className={classnames('form-control', { error: formData.ownerSnils.errorMessage })}
              value={formData.ownerSnils.value}
            />
            {formData.ownerSnils.errorMessage && (
            <div className="validation-error">
              {formData.ownerSnils.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerActualAddress">Фактический адрес</label>
            <ReactDadata
              id="ownerActualAddress"
              name="ownerActualAddress"
              className={classnames('form-control', { error: formData.ownerActualAddress.errorMessage })}
              query={formData.ownerActualAddress.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerActualAddress', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField}
              onFocus={setCursorToEnd}
            />
            {formData.ownerActualAddress.errorMessage && (
            <div className="validation-error">
              {formData.ownerActualAddress.errorMessage}
            </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className="form-check custom-checkbox">
              <input
                disabled={disableField}
                onChange={toggleFlag}
                checked={formData.ownerAddressRegistrationFlag.value}
                name="ownerAddressRegistrationFlag"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="ownerAddressRegistrationFlag"
              />
              <label className="form-check-label" htmlFor="ownerAddressRegistrationFlag">Фактический адрес и адрес регистрации совпадают</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 col-xl-6">
            <label htmlFor="ownerAddressRegistration">Адрес регистрации</label>
            <ReactDadata
              id="ownerAddressRegistration"
              name="ownerAddressRegistration"
              className={classnames('form-control', { error: formData.ownerAddressRegistration.errorMessage })}
              query={formData.ownerAddressRegistration.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onBlur={handleValidate}
              onChange={(e) => handleChange(e, 'ownerAddressRegistration', formData, setFormData)}
              placeholder="Можете начать с ввода улицы"
              disabled={disableField || formData.ownerAddressRegistrationFlag.value}
              onFocus={setCursorToEnd}
              ref={ownerAddressRegistrationRef}
            />
            {formData.ownerAddressRegistration.errorMessage && (
            <div className="validation-error">
              {formData.ownerAddressRegistration.errorMessage}
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
