import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/fontawesome-free-solid';
import Tippy from '@tippyjs/react';

export default function CheckPropertyResult(props) {
  const { propertyData } = props;
  const propertyInfo = propertyData.realty;

  return (
    <div name="check_info" className="search_results__main page--calck">
      <div className="mb-2 d-flex">
        <span className="mr-auto align-self-center">Данные по запросу - {propertyInfo.title}</span>
      </div>
      <table className="data_table">
        <thead>
          <tr>
            <td className="data_table__row_title"><span className="color-light">Основная информация - {propertyInfo.title}</span></td>
            <td className="data_table__row_content">
              <p><b>{propertyInfo.cn.title}:</b> {propertyInfo.cn.new.value}</p>
              <p><b>Объект:</b> {propertyInfo.type.value}</p>
              <p><b>Статус объекта:</b> {propertyInfo.status.message}</p>
              {propertyInfo.conditional_number.hasResult ? (
                <p>
                  <b>Условные номера БТИ:</b> {propertyInfo.conditional_number.message} - {propertyInfo.conditional_number.items.join(', ')}
                </p>
              ) : null}
              {propertyInfo.engineer.hasResult ? (<p><b>Кадастровый инженер:</b> {propertyInfo.engineer.message}</p>) : null}
              <p><b>Кадастровая стоимость:</b> {propertyInfo.cadastral_cost.amount.value} р. от {propertyInfo.cadastral_cost.valuation_date.value}</p>
              <p><b>Площадь объекта:</b> {propertyInfo.area.size.value} {propertyInfo.area.unit.value === 'Квадратный метр' ? (<>м<sup>2</sup></>) : propertyInfo.area.unit.value}</p>
              {propertyInfo.premises_floor.value ? (<p><b>{propertyInfo.premises_floor.title}:</b> {propertyInfo.premises_floor.value}</p>) : null}
            </td>
          </tr>

          <tr>
            <td className="data_table__row_title"><span className="color-light">Объект капитального строительства</span></td>
            <td className="data_table__row_content">
              {propertyInfo.oks.floors.value ? (<p><b>{propertyInfo.oks.floors.title}:</b> {propertyInfo.oks.floors.value}</p>) : null}
              {propertyInfo.oks.built_year.value ? (<p><b>{propertyInfo.oks.built_year.title}:</b> {propertyInfo.oks.built_year.value}</p>) : null}
              {propertyInfo.oks.material.value ? (<p><b>{propertyInfo.oks.material.title}:</b> {propertyInfo.oks.material.value}</p>) : null}
              {propertyInfo.oks.cost_date.value ? (<p><b>{propertyInfo.oks.cost_date.title}:</b> {propertyInfo.oks.cost_date.value}</p>) : null}
              {propertyInfo.oks.inventory_cost.value && propertyInfo.oks.inventory_cost.value !== -1 ? (<p><b>{propertyInfo.oks.inventory_cost.title}:</b> {propertyInfo.oks.inventory_cost.value}</p>) : null}
            </td>
          </tr>

          {propertyInfo.location.hasResult
            ? (
              <tr>
                <td className="data_table__row_title"><span className="color-light">Адреса объекта</span></td>
                <td className="data_table__row_content">
                  <p><b>Список адресов</b> - {propertyInfo.location.comment}</p>
                  {propertyInfo.location.addresses?.length ? propertyInfo.location.addresses.map((adress, index) => (
                    <p><b>Адрес №{index + 1}:</b> {adress.text} {adress.mkd ? (<ul>{Object.values(adress.mkd).map((field) => (field.value ? <li><b>{field.title}:</b> {field.value}</li> : null))}</ul>) : null}</p>
                  )) : 'Адреса отсутствуют'}
                </td>
              </tr>
            ) : null}

          {propertyInfo.registrations
            ? (
              <>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Права</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.registrations.rights.count ? propertyInfo.registrations.rights.items.map((right, index) => (
                      <>
                        <p className={index === 0 ? '' : 'mt-3'}><b>Право {index + 1}</b></p>
                        <p>
                          <b>Тип:</b> {right.type_name}
                        </p>
                        <p>
                          <b>Регистрационный номер:</b> {right.registration_number}
                        </p>
                        <p>
                          <b>Номер закрытия:</b> {right.close_number}
                        </p>
                        <p>
                          <b>Зерегестрировано:</b> {right.registered_at}
                        </p>
                        <p>
                          <b>Закрыто:</b> {right.closed_at}
                        </p>
                        <p>
                          <b>Срок владения:</b> {right.duration_message.value}
                        </p>
                        <p>
                          <b>Статус:</b> {right.stateTitle}
                        </p>
                        <p>
                          <b>Обременения на право:</b> {right.encumbrances.negatives.title}
                        </p>
                        <div>
                          <p> <b> Выводы:</b> {!right.conclusions?.length && 'отсутствуют'}</p>
                          {right.conclusions?.length ? (
                            <ul>
                              {right.conclusions.map((conclusion) => (
                                <li>
                                  <div className="d-inline-block">
                                    <b>{conclusion.title}</b>
                                    <Tippy
                                      className="tippy-custom"
                                      arrow={false}
                                      offset={[0, 5]}
                                      content={conclusion.message}
                                      hideOnClick={false}
                                    >
                                      <i className="icon icon-tooltip ml-2"><FontAwesomeIcon icon={faQuestion} className="fa-fw" /></i>
                                    </Tippy>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </div>
                      </>
                    )) : (<p>Права на объект отсутствуют</p>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Обременения</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.registrations.encumbrances.count ? propertyInfo.registrations.encumbrances.items.map((encumbrances) => (
                      <>
                        <p><b>Тип:</b> {encumbrances.type_name}</p>
                        <p><b>Регистрационный номер:</b> {encumbrances.registration_number}</p>
                        <p><b>Номер закрытия:</b> {encumbrances.close_number}</p>
                        <p><b>Зерегестрировано:</b> {encumbrances.registered_at}</p>
                        <p><b>Закрыто:</b> {encumbrances.closed_at}</p>
                        <p><b>Срок владения:</b> {encumbrances.duration_message.value}</p>
                        <p><b>Статус:</b> {encumbrances.stateTitle}</p>
                      </>
                    )) : (<p>Нет негативных обременений</p>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Реестр обременений</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.registrations.summary.verification.hasResult ? (
                      <>
                        <div>
                          <b>Негативные обременения:</b>
                          <ul>
                            {propertyInfo.registrations.summary.verification.items.negative.items.filter((item) => item?.exist).map((item) => (<li>{item.title}</li>))}
                          </ul>
                        </div>
                        <div>
                          <b>Стандартные обременения:</b>
                          <ul>
                            {propertyInfo.registrations.summary.verification.items.regular.items.filter((item) => item?.exist).map((item) => (<li>{item.title}</li>))}
                          </ul>
                        </div>
                      </>
                    ) : (<p>Сводные данные отсутствуют</p>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Суммарные данные</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.registrations.summary.amounts.hasResult ? (
                      <>
                        <p><b>Актуальные права:</b> {propertyInfo.registrations.summary.amounts.items.rightsActualCount.value}</p>
                        <p><b>Актуальные обременения:</b> {propertyInfo.registrations.summary.amounts.items.encumbrancesActualCount.value}</p>
                        <p><b>Погашенные права:</b> {propertyInfo.registrations.summary.amounts.items.rightsClosedCount.value}</p>
                        <p><b>Погашенные обременения:</b> {propertyInfo.registrations.summary.amounts.items.encumbrancesClosedCount.value}</p>
                      </>
                    ) : (<p>Суммарные данные отсутствуют</p>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Статистика регистраций</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.registrations.statistic.data.length ? propertyInfo.registrations.statistic.data.map((item) => (
                      <p><b>Номер {item.label}:</b> {item.type === 'right' ? 'Право' : 'Обременение'} {item.boundaries_message} - {item.duration_message}</p>
                    )) : (<p>Статистика регистраций пуста</p>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Отчёт статистики регистраций</span></td>
                  <td className="data_table__row_content">
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.common.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.common.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.common.explanations?.length ? (
                        propertyInfo.registrations.statistic.common.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.rights.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.rights.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.rights.explanations?.length ? (
                        propertyInfo.registrations.statistic.rights.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.rights_actual.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.rights_actual.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.rights_actual.explanations?.length ? (
                        propertyInfo.registrations.statistic.rights_actual.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.rights_closed.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.rights_closed.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.rights_closed.explanations?.length ? (
                        propertyInfo.registrations.statistic.rights_closed.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.encumbrances.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.encumbrances.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.encumbrances.explanations?.length ? (
                        propertyInfo.registrations.statistic.encumbrances.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.encumbrances_actual.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.encumbrances_actual.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.encumbrances_actual.explanations?.length ? (
                        propertyInfo.registrations.statistic.encumbrances_actual.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={propertyInfo.registrations.statistic.encumbrances_closed.comment}
                      hideOnClick={false}
                    >
                      <b>{propertyInfo.registrations.statistic.encumbrances_closed.title}:</b>
                    </Tippy>
                    <ul>
                      {propertyInfo.registrations.statistic.encumbrances_closed.explanations?.length ? (
                        propertyInfo.registrations.statistic.encumbrances_closed.explanations.map((explanation) => (<li>{explanation}</li>))
                      ) : <li>отсутствуют</li>}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Риск-индикатор</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.indicator.hasResult ? (
                      <div><b>Оценка на основании истории:</b> {propertyInfo.indicator.message}
                        <ul>
                          {propertyInfo.indicator.notifications.map((item) => (
                            <li>{item.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (<div>Риск-индикатор отсутствует</div>)}
                  </td>
                </tr>
                <tr>
                  <td className="data_table__row_title"><span className="color-light">Заключение и рекомендации</span></td>
                  <td className="data_table__row_content">
                    {propertyInfo.conclusions.messages?.length
                      ? propertyInfo.conclusions.messages.map((message) => (<div dangerouslySetInnerHTML={{ __html: message.text }} />))
                      : (<p>Заключение и рекомендации отсутствуют</p>)}
                  </td>
                </tr>
              </>
            ) : null}
        </thead>
      </table>
    </div>
  );
}
