import React, { forwardRef, useState, useContext } from 'react';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../../Utils/Helpers';

function Notes({ setNotes, notes }) {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  const [description, setDescription] = useState('');
  const [activeRisk, setActiveRisk] = useState(true);
  const [load, setLoad] = useState(false);
  const [loadDelete, setLoadDelete] = useState(0);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const stockCreate = () => {
    setLoad(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/createNote`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData: {
          description,
          type: activeRisk ? 'risk' : 'limitation',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setNotes(response);
          showModalInfo(activeRisk ? 'Новый риск создан' : 'Новое ограничение создано');
          setDescription('');
          setLoad(false);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo(`Не удалось создать ${activeRisk ? 'новый риск' : 'новое ограничение'}`);
        setLoad(false);
      });
  };

  const noteDelete = (id) => {
    setLoadDelete(id);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/deleteNote`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setNotes(response);
          showModalInfo('Примечание успешно удалено');
          setLoadDelete(0);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Не удалось удалить примечание');
        setLoadDelete(0);
      });
  };

  return (
    <>
      <div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="risk">Описание {activeRisk ? 'риска' : 'ограничения'}</label>
            <input
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              name="risk"
              id="risk"
              placeholder="Примечание"
            />
          </div>
          <div className="col-lg-4 col-xl-2 ml-4">
            <input
              onChange={() => setActiveRisk(!activeRisk)}
              checked={activeRisk}
              className="form-check-input checkbox-styled"
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="addToErp">Риск</label>
          </div>
        </div>
        <div className="form-group row col-xl-4">
          <button disabled={load} type="button" className="btn btn-success" onClick={stockCreate}>Сохранить</button>
          {load ? <LoadingSpinner type="spin" height={38} width={38} className="loading-circle ml-3" /> : null}
        </div>
      </div>
      {
        notes.map((note) => (
          <div className="form-group row">
            <div className="col-xl-4">
              {note.description}
            </div>
            <div className="form-group row">
              <button disabled={loadDelete === note.id} onClick={() => noteDelete(note.id)} type="button" className="btn btn-border btn-danger">{note.type === 'risk' ? 'Удалить риск' : 'Удалить ограничение'}</button>
              {loadDelete === note.id ? <LoadingSpinner type="spin" height={38} width={38} className="loading-circle ml-3" /> : null}
            </div>
          </div>
        ))
      }
    </>
  );
}

export default forwardRef(Notes);
