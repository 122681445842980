import React from 'react';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

export default function ZprChosenOffer(props) {
  const {
    chosenOffer,
    companies,
    backToForm,
    formData,
    disableField,
  } = props;

  const url = `url(${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo})`;

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <FormNotificationColorLine>
          <div className="chosen-offer">
            <div className="chosen-offer__logo logo-box">
              <div className="logo-box__logo" style={{ backgroundImage: url }} />
            </div>
            <div className="chosen-offer__price">
              <span>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</span>
              <span>Потеря работы</span>
            </div>
            {!disableField ? (
              <button type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short', true)}>Изменить данные</button>
            ) : null}
          </div>
          <hr className="mb-0" />
          <ul className="chosen-offer-info">
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Страховая сумма:</span>
              <span>{formData.insuranceLimit.value} р.</span>
            </li>
          </ul>
        </FormNotificationColorLine>
      </div>
    </div>
  );
}
