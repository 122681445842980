import React from 'react';
import styled from 'styled-components';
import { ContainerMainInfo } from './DashboardMainInfo';
import { WrapperFooterText } from './DashboardWrapperFooterText';
import { Wrapper } from './DashboardTopWrapper';

const Card = styled.li`
  width: ${(props) => (props.width || '32.1%')};
  

  @media (max-width: 1023px) {
    width: 100%;
  }
 `;

const CardComponents = styled.div`
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: initial;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 29px 31px 26px 30px;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    -webkit-box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    background-color: #fff;
    overflow: hidden;
    outline: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-transition: -webkit-box-shadow .15s ease;
    transition: box-shadow .15s ease;
    transition: box-shadow .15s ease, -webkit-box-shadow .15s ease;
    ${ContainerMainInfo} {
        margin-top: 27px;
    }

    @media (max-width: 1023px) {
        padding-bottom: 48px;
        ${ContainerMainInfo} {
            margin-bottom: 4px;
        }
        ${WrapperFooterText} {
            margin-bottom: 16px;
            font-size: 10px;
            line-height: 12px;
            max-width: 100%;
            margin-top: 8px;
        }
    }
    @media (max-width: 767px) {
        padding: 12px 16px 16px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        ${Wrapper} {
            width: 100%;
        }
        .big-icon {
            visibility: hidden;
            width: 1px;
            height: 1px;
            position: absolute;
        }
        
        ${WrapperFooterText} {
            margin-top: 0;
        }
        ${ContainerMainInfo} {
            margin-top: 20px;
            margin-bottom: 2px;
            p {
                font-size: 24px;
                line-height: 29px;
                white-space: nowrap;
            }
        }
    }
`;

export default function DashboardCardBig(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <Card disabled={disabled} width={width}>
      <CardComponents>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </CardComponents>
    </Card>
  );
}
