import React from 'react';
import Tippy from '@tippyjs/react';

function Regions(props) {
  const { regions, index } = props;
  return (
    <Tippy
      className="tippy-custom"
      arrow={false}
      offset={[0, 5]}
      content={<div style={{ maxWidth: 450 }}>{regions}</div>}
      hideOnClick={false}
    >
      <label className="col-md-2 col-form-label custom-region-label">
        <a className="" href="#" onClick={(e) => e.preventDefault()}>
          Группа регионов #{index + 1}
        </a>
      </label>
    </Tippy>
  );
}
export default React.memo(Regions);
