import React from 'react';
import styled, { css } from 'styled-components';

const LoadingSpinnerBlock = styled.div`
  & svg {
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  ${(props) => props.absolute === true && css`
      position: absolute;
      top: 8px;
      left: 8px;
  `}
  ${(props) => (props.margin_left ? `margin-left: ${props.margin_left}px` : '')};
  ${(props) => (props.margin_right ? `margin-right: ${props.margin_right}px` : '')};
`;

export default function LoadingSpinner(props) {
  const {
    width, height, marginLeft, marginRight, theme, absolute, className,
  } = props;

  return (
    <LoadingSpinnerBlock margin_left={marginLeft} margin_right={marginRight} absolute={absolute} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width || '50px'} height={height || '50px'} viewBox="0 0 128 128" xmlSpace="preserve">
        <defs>
          <linearGradient id="linear-gradient">
            <stop offset="0%" stopColor={theme ? theme.mainColor : '#00e646'} />
            <stop offset="40%" stopColor={theme ? theme.mainColor : '#00e646'} stopOpacity="0.7" />
            <stop offset="100%" stopColor={theme ? theme.mainColor : '#00e646'} stopOpacity="0" />
          </linearGradient>
        </defs>
        <path d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z" fill="url(#linear-gradient)" fillRule="evenodd" />
      </svg>
    </LoadingSpinnerBlock>
  );
}
