import React, {
  useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext,
} from 'react';
import ReactTable from 'react-table-6';
import MultiRef from 'react-multi-ref';
import { reactLocalStorage } from 'reactjs-localstorage';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import matchSorter from 'match-sorter';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import { format, parse, parseISO } from 'date-fns';
import ReactExport from 'react-data-export';
import { shallow } from 'zustand/shallow';
import {
  toLocalDate,
  customFetch,
  setTableSorting,
  setTableSettings,
  getDefaultSorting,
  handleSubmitTableCustomized,
  getCustomizedColumns,
  handleTableCustomizedSetNone,
  handleTableCustomizedSetAll, downloadReport6,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import useUserInfoStore from '../Stores/UserInfoStore';
import LoadingSpinner from '../Layout/LoadingSpinner';

function BonusesHistoryTable(props, ref) {
  const {
    tab, history, agentId,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  const simulateClick = new MultiRef();

  const RED_COLOR = 'rgb(255, 0, 0)';
  const GREEN_COLOR = 'rgb(0, 197, 60)';

  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);
  const excelFile = useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;

  const [filtered, setFiltered] = useState([]);
  const [bonusesHistoryList, setBonusesHistoryList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [goDownloadFile, setGoDownloadFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState('1');
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
    downloadReport: () => {
      downloadReport6(tableRef, columns, setExcelColumns, setDataSet, setGoDownloadFile);
    },
  }));

  useEffect(() => {
    if (goDownloadFile) {
      excelFile.current.handleDownload();
      setGoDownloadFile(false);
    }
  }, [goDownloadFile]);

  const updateTableColumns = () => {
    const newColumns = [{
      Header: 'Дата',
      width: reactLocalStorage.getObject('bonusesHistoryListSettings').created_at,
      Cell: ({ original }) => format(toLocalDate(parseISO(original.created_at)), 'dd.MM.yyyy'),
      accessor: 'created_at',
      show: reactLocalStorage.getObject('bonusesHistoryListSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Кол-во баллов',
      accessor: 'amount',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['amount'] }),
      Cell: (row) => <span style={{ color: row.original.income ? GREEN_COLOR : RED_COLOR }}>{row.original.income ? '+' : '-'}{row.value}</span>,
      filterAll: true,
      width: reactLocalStorage.getObject('bonusesHistoryListSettings').amount,
      show: reactLocalStorage.getObject('bonusesHistoryListSettings').show_amount !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      accessor: 'status',
      width: reactLocalStorage.getObject('bonusesHistoryListSettings').status,
      Cell: ({ row }) => {
        if (row.status === 'complete') return 'Выполнено';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'complete') {
          return row.status === 'complete';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="complete">Выполнено</option>
        </select>
      ),
      show: reactLocalStorage.getObject('bonusesHistoryListSettings').show_status !== false,
      showCustomized: true,
    }, {
      Header: 'Тип',
      accessor: 'type',
      width: reactLocalStorage.getObject('bonusesHistoryListSettings').type,
      Cell: ({ row }) => {
        if (row.type === 'withdrawal') return 'Переведено на баланс';
        if (row.type === 'osago') return 'ОСАГО';
        if (row.type === 'mortgage') return 'Ипотека';
        if (row.type === 'kasko') return 'Каско';
        if (row.type === 'ifl') return 'ИФЛ';
        if (row.type === 'vzr') return 'ВЗР';
        if (row.type === 'telemedicine') return 'Телемедицина';
        if (row.type === 'accident') return 'НС-кросс';
        if (row.type === 'self_employed') return 'Самозанятый';
        if (row.type === 'event') return 'Акция';
        if (row.type === 'agents') return 'Активные пользователи';
        if (row.type === 'dmsStudent') return 'ДМС студентов';
        if (row.type === 'ns') return 'НС';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'withdrawal') {
          return row.type === 'withdrawal';
        }
        if (filter.value === 'osago') {
          return row.type === 'osago';
        }
        if (filter.value === 'mortgage') {
          return row.type === 'mortgage';
        }
        if (filter.value === 'kasko') {
          return row.type === 'kasko';
        }
        if (filter.value === 'ifl') {
          return row.type === 'ifl';
        }
        if (filter.value === 'vzr') {
          return row.type === 'vzr';
        }
        if (filter.value === 'telemedicine') {
          return row.type === 'telemedicine';
        }
        if (filter.value === 'accident') {
          return row.type === 'accident';
        }
        if (filter.value === 'event') {
          return row.type === 'event';
        }
        if (filter.value === 'self_employed') {
          return row.type === 'self_employed';
        }
        if (filter.value === 'agents') {
          return row.type === 'agents';
        }
        if (filter.value === 'dmsStudent') {
          return row.type === 'dmsStudent';
        }
        if (filter.value === 'ns') {
          return row.type === 'ns';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="withdrawal">Переведено на баланс</option>
          <option value="osago">ОСАГО</option>
          <option value="mortgage">Ипотека</option>
          <option value="kasko">КАСКО</option>
          <option value="ifl">ИФЛ</option>
          <option value="vzr">ВЗР</option>
          <option value="accident">НС-кросс</option>
          <option value="telemedicine">Телемедицина</option>
          <option value="self_employed">Самозанятый</option>
          <option value="event">Акция</option>
          <option value="agents">Активные пользователи</option>
          <option value="dmsStudent">ДМС студентов</option>
          <option value="ns">НС</option>
        </select>
      ),
      show: reactLocalStorage.getObject('bonusesHistoryListSettings').show_type !== false,
      showCustomized: true,
    }, {
      Header: 'ID полиса',
      id: 'id',
      width: reactLocalStorage.getObject('bonusesHistoryListSettings').id,
      accessor: (d) => (d.policy_id ? d.policy_id : d.mortgage_id),
      show: reactLocalStorage.getObject('bonusesHistoryListSettings').show_id !== false,
      showCustomized: true,
    }];
    setColumns(newColumns);
  };

  const loadBonusesHistory = (dateFrom, dateTo) => {
    setLoading(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const urlParam = agentId ? `&agentId=${agentId}` : '';
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/bonuses/history?dateFrom=${dateFrom}&dateTo=${dateTo}${urlParam}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBonusesHistoryList(data);
        setTimeout(() => { updateTableColumns(); }, 1000);
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeStart = (date) => {
    setLoading(true);
    setBonusesHistoryList([]);
    setStartDate(date);
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateTo = null;
    const dateFrom = format(date, 'yyyy-MM-dd');
    if (endDate !== null) {
      filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
      dateTo = format(endDate, 'yyyy-MM-dd');
    }
    loadBonusesHistory(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'filter_date') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
    } else {
      newFiltered.push({ id: 'filter_date', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (endDate === null && filter.id === 'filter_date') {
          queryParams = `${queryParams + filter.id}=${filter.value} null`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    history.push({ search: queryParams });
  };

  const handleChangeEnd = (date) => {
    setLoading(true);
    setBonusesHistoryList([]);
    setEndDate(date);
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateFrom = null;
    const dateTo = format(date, 'yyyy-MM-dd');
    if (startDate !== null) {
      filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
      dateFrom = format(startDate, 'yyyy-MM-dd');
    }

    loadBonusesHistory(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'filter_date') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
    } else {
      newFiltered.push({ id: 'filter_date', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (startDate === null && filter.id === 'filter_date') {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    history.push({ search: queryParams });
  };

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    const urlParamsDateTab = urlParams.dateTab ? urlParams.dateTab : '1';
    setActiveFilterTab(urlParamsDateTab);
  }, []);

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    setLoading(true);
    setBonusesHistoryList([]);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    let lastButOneMonth = date.getMonth();
    let currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
    const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let dateFrom;
    let dateTo;
    let filterDate = null;
    if (activeFilterTab === '1') {
      dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
      dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
      setStartDate(startDateCurrentMonth);
      setEndDate(endDateCurrentMonth);
      filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
    } else if (activeFilterTab === '2') {
      if (currentMonth === 1) {
        currentYear -= 1;
        lastButOneMonth = '12';
        lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
        startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      }
      dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
      dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
      setStartDate(startDateLastButOneMonth);
      setEndDate(endDateLastButOneMonth);
      filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
    } else if (activeFilterTab === '3') {
      dateFrom = null;
      dateTo = null;
      setStartDate(null);
      setEndDate(date);
      filterDate = `${format(date, 'dd.MM.yyyy')}`;
    }

    let newFiltered = [];
    if (filtered.length) {
      newFiltered = filtered;
    } else {
      const urlParams = queryString.parse(window.location.search);
      Object.keys(urlParams).forEach((filter) => {
        if (filter !== 'filter_date') {
          newFiltered.push({ id: filter, value: urlParams[filter] });
        }
      });
    }

    let findedIndex = -1;
    let findedIndexDateTab = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'filter_date') {
        findedIndex = i;
      }
      if (filter.id === 'dateTab') {
        findedIndexDateTab = i;
      }
    });
    if (findedIndex !== -1) {
      if (filterDate === null) {
        newFiltered.splice(findedIndex, 1);
      } else {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      }
    } else if (filterDate !== null) {
      newFiltered.push({ id: 'filter_date', value: filterDate });
    }
    if (findedIndexDateTab !== -1) {
      newFiltered[findedIndexDateTab] = { id: 'dateTab', value: activeFilterTab };
    } else {
      newFiltered.push({ id: 'dateTab', value: activeFilterTab });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (filter.id === 'filter_date' && activeFilterTab === '3') {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
        }
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    history.push({ search: queryParams });
    setFiltered(newFiltered);
    loadBonusesHistory(dateFrom, dateTo);
  }, [activeFilterTab, userInfo]);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={() => handleTableCustomizedSetAll(simulateClick)} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={() => handleTableCustomizedSetNone(simulateClick)} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={(e) => handleSubmitTableCustomized(e, 'bonusesHistoryListSettings', setFlagModalTableCustomized, updateTableColumns)} id="tableCustomized">
          <ul className="two-column-list">
            {getCustomizedColumns(columns, simulateClick)}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <div className="d-none">
        <ExcelFile ref={excelFile} element={<button type="button">Download Data</button>}>
          <ExcelSheet data={dataSet} name="OsagoPoliciesInfo">
            {excelColumns}
          </ExcelSheet>
        </ExcelFile>
      </div>
      <div className="dateFilter">
        <div className="d-inline-block mb-2">
          <div
            onClick={() => { setActiveFilterTab('1'); }}
            className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
          >
            Текущий месяц
          </div>
          <div
            onClick={() => { setActiveFilterTab('2'); }}
            className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
          >
            Прошлый месяц
          </div>
          <div
            onClick={() => { setActiveFilterTab('3'); }}
            className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
          >
            Все время
          </div>
        </div>
        <div className="d-inline-block mb-2">
          <span>Точные даты с</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={startDate}
            className="form-control"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeStart}
            maxDate={endDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
        <div className="d-inline-block">
          <span>по</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={endDate}
            className="form-control"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeEnd}
            minDate={startDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
      </div>
      <ReactTable
        ref={tableRef}
        getTdProps={(state, rowInfo, column) => {
          if (rowInfo && column.id && rowInfo.original.id && rowInfo.original.type) {
            return {
              onMouseDown: (e) => {
                e.preventDefault();
                const id = rowInfo.original.policy_id ? rowInfo.original.policy_id : rowInfo.original.mortgage_id;
                if (e.button === 0) {
                  history.push(`/${rowInfo.original.type}/${id}`);
                }
                if (e.button === 1) {
                  window.open(`/${rowInfo.original.type}/${id}`, '_blank');
                }
              },
            };
          } return {};
        }}
        filtered={filtered}
        onFilteredChange={(newFiltered) => onFilteredChangeCustom(newFiltered)}
        className="table"
        data={bonusesHistoryList}
        columns={columns}
        defaultSorted={[
          {
            id: getDefaultSorting('bonusesHistoryListSettings').field,
            desc: getDefaultSorting('bonusesHistoryListSettings').order,
          },
        ]}
        previousText="Назад"
        nextText="Вперед"
        loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
        noDataText="Нет истории бонусов"
        pageText="Страница"
        ofText="Из"
        rowsText="строк"
        loading={loading}
        defaultPageSize={50}
        filterable
        minRows={1}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'bonusesHistoryListSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings(newResized, 'bonusesHistoryListSettings');
        }}
      />
    </>
  );
}

export default forwardRef(BonusesHistoryTable);
