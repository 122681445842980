import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import Countdown from 'react-countdown-now';
import 'rc-tooltip/assets/bootstrap.css';
import queryString from 'query-string';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { switchLanguage } from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import getMask from '../Utils/Masks';
import AuthService from '../Utils/AuthService';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class ResetPhone extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      resetToken: queryString.parse(this.props.location.search).resetToken,
      email: queryString.parse(this.props.location.search).email,
      verificationPhone: false,
      showCountdown: false,
      loading: false,
      showFormPhoneVerification: false,
      formFields: {
        phone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        smsCode: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'smsCode',
        },
      },
    };
  }

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formFields } = this.state;
    formFields[e.target.id].value = value;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeSmsCode = (e) => {
    const { formFields } = this.state;
    const { value } = e.target;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({
      formFields: {
        ...formFields,
        smsCode: {
          ...formFields.smsCode,
          value,
        },
      },
    }, () => {
      if (value.length === 4) {
        this.handleFormPhoneVerificationSubmit();
      }
    });
  };

  handleSendSms = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const validationSuccess = this.validateAllFields();
    if (validationSuccess) {
      this.sendSms();
    } else {
      this.setState({ loading: false });
    }
  };

  validateAllFields = () => {
    const { formFields } = this.state;
    let validationSuccess = true;
    Object.keys(formFields).forEach((field) => {
      let value;
      if (formFields[field].validationType === 'passwordSubmit') {
        value = { passwordSubmit: formFields.passwordSubmit.value, password: formFields.password.value };
      } else {
        value = formFields[field].value;
      }
      formFields[field].errorMessage = getValidation(value, formFields[field].validationType);
      if (formFields[field].errorMessage) {
        formFields[field].errorVisible = true;
        validationSuccess = false;
      }
    });
    this.setState({ formFields });
    return validationSuccess;
  };

  sendSms = (reset = false) => {
    const { formFields } = this.state;
    if (reset) {
      this.setState({
        formFields: {
          ...formFields,
          smsCode: {
            ...formFields.smsCode,
            value: '',
          },
        },
      });
    }
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/sendSms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ loading: false });
        if (response === 'phone_already_exist') {
          this.setState({
            showFormPhoneVerification: false,
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                value: '',
              },
              phone: {
                ...formFields.phone,
                errorMessage: 'Пользователь с данным номером уже зарегистрирован',
                errorVisible: true,
              },
            },
          });
        } else {
          this.setState({
            showCountdown: true,
            showFormPhoneVerification: true,
          });
        }
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Произошла ошибка, повторите попытку.');
      });
  };

  handleFormPhoneVerificationSubmit = () => {
    const { formFields, resetToken, email } = this.state;
    const { history } = this.props;
    const { showModalInfo } = this.context;
    this.setState({ verificationPhone: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/profile/changePhone`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
        code: formFields.smsCode.value,
        resetToken,
        email,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'not_verified') {
          this.setState({
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                errorMessage: 'Вы ввели неправильный код',
                errorVisible: true,
              },
            },
          });
        } else if (response.error) {
          showModalInfo(response.error);
        } else if (response === 'success') {
          showModalInfo('Телефон успешно изменен, через мгновение вы будете переадресованы в личный кабинет');
          setTimeout(() => {
            history.replace('/profile?activeTab=2');
          }, 3000);
        } else {
          showModalInfo('Произошла ошибка, повторите попытку.');
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      })
      .finally(() => {
        this.setState({ verificationPhone: false });
      });
  };

  handleValidate = (e, equalityField = null) => {
    const { formFields } = this.state;
    let { value } = e.target;
    if (equalityField) {
      value = { [e.target.id]: e.target.value, [equalityField]: formFields[equalityField].value };
    }
    formFields[e.target.id].errorMessage = getValidation(value, formFields[e.target.id].validationType);
    if (formFields[e.target.id].errorMessage) {
      formFields[e.target.id].errorVisible = true;
    }
    this.setState({ formFields });
  };

  hideCountdown = () => {
    this.setState({ showCountdown: false });
  };

  render() {
    const {
      formFields,
      showFormPhoneVerification,
      loading,
      showCountdown,
      verificationPhone,
    } = this.state;
    const { theme } = this.props;
    const formRegistration = (
      <form className="form-signin" onSubmit={this.handleSendSms}>
        <img className="mb-4" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logo}`} alt="" />
        <h1 className="h3 mb-3 font-weight-normal">Введите новый номер телефона</h1>
        <InputMask
          value={formFields.phone.value}
          className={classnames('form-control', { error: formFields.phone.errorVisible })}
          placeholder="Номер телефона"
          id="phone"
          name="phone"
          mask={getMask('phone').mask}
          formatChars={getMask('phone').formatChars}
          onBlur={this.handleValidate}
          onChange={this.handleChangeInput}
        />
        {formFields.phone.errorVisible && (
          <div className="validation-error">
            {formFields.phone.errorMessage}
          </div>
        )}
        {loading
          ? (<LoadingSpinner className="loading-circle ml-3 d-inline-block mt-2" type="spin" height={38} width={38} />)
          : (<button className="btn btn-lg btn-success btn-block mt-2" type="submit">Продолжить</button>)}
      </form>
    );
    const formPhoneVerification = (
      <form className="form-signin" onSubmit={this.handleFormPhoneVerificationSubmit}>
        <img className="mb-4" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logo}`} alt="" />
        <h1 className="h3 mb-3 font-weight-normal">На указанный номер телефона выслан СМС-код, введите его</h1>
        <InputMask
          className={classnames('form-control mb-2', { error: formFields.smsCode.errorVisible })}
          disabled={verificationPhone}
          value={formFields.smsCode.value}
          type="text"
          onChange={(e) => this.handleChangeSmsCode(e)}
          maskChar={null}
          name="smsCode"
          id="smsCode"
          mask={getMask('smsCode').mask}
          placeholder="SMS Код"
        />
        {formFields.smsCode.errorVisible && (
          <div className="validation-error">
            {formFields.smsCode.errorMessage}
          </div>
        )}
        {verificationPhone && (<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />)}
        {showCountdown
          ? (
            <Countdown
              date={Date.now() + 45000}
              renderer={(props) => <div>Отправить код повторно через {props.seconds} сек.</div>}
              onComplete={this.hideCountdown}
            />
          )
          : (<a role="button" tabIndex="0" onClick={() => this.sendSms(true)} className="dotted_link">Отправить СМС повторно</a>)}
      </form>
    );
    return (
      <div className="login-page text-center">
        {showFormPhoneVerification ? formPhoneVerification : formRegistration}
      </div>
    );
  }
}

ResetPhone.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(ResetPhone);
