import React from 'react';
import iconFile from '../../img/icon_file.png';

function AboutUs() {
  return (
    <>
      <h1 className="page-wrapper__title">О нас</h1>
      <h2>Наши реквизиты</h2>
      <ul>
        <li>Общество с ограниченной ответственностью «ПОЛИС ОНЛАЙН»</li>
        <li>ИНН: 7810745660</li>
        <li>КПП: 781001001</li>
        <li>ОГРН: 1187847378070 от 16 декабря 2018 г.</li>
        <li>ОКПО: 34991143</li>
        <li>ОКАТО: 40284000000</li>
        <li>ОКТМО: 40373000000</li>
        <li>Расчетный счет: 40702810632060009564 в Филиале «Санкт-Петербургский» АО “Альфа-Банк” г. Санкт-Петербург</li>
        <li>К/сч: 30101810600000000786</li>
        <li>БИК: 044030786</li>
        <li>Адрес: Россия, г. Санкт - Петербург Лиговский проспект, д. 266 лит. В, оф. 1-Н17</li>
        <li>Генеральный директор: Креер Андрей Юрьевич (Действует на основании устава)</li>
        <li>Главный бухгалтер: Смирнова Елена Викторовна</li>
      </ul>
      <div className="insurance__down-item">
        <div className="insurance__down-ico">
          <img src={iconFile} alt="" />
        </div>
        <h4>
          <a href={`${process.env.REACT_APP_BACKEND_DOMAIN}/storage/public/admin_documents/eula.pdf`} rel="noopener noreferrer" target="_blank">Пользовательское соглашение.pdf</a>
        </h4>
        pdf. 0.768Mb
      </div>
    </>
  );
}

export default AboutUs;
