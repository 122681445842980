import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../Layout/LoadingSpinner';
import getValidation from '../Utils/Validation';
import { customFetch } from '../Utils/Helpers';
import getMask from '../Utils/Masks';
import BonusesEvents from './BonusesEvents';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import useUserInfoStore from '../Stores/UserInfoStore';

export default function BonusesInfo(props) {
  const { history, toggle } = props;

  const { userInfo, setUserInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      setUserInfo: state.setUserInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);
  const [bonusSum, setBonusSum] = useState(0);
  const [paymentBonusSum, setPaymentBonusSum] = useState({
    value: '',
    errorMessage: '',
    errorVisible: false,
    validationType: 'decimal',
  });
  const [paymentRequestWaiting, setPaymentRequestWaiting] = useState(false);

  const paymentButtonRef = useRef(null);

  const changePaymentBonusSum = (e) => {
    const { target } = e;
    setPaymentBonusSum((prev) => ({
      ...prev,
      value: target.value,
      errorMessage: '',
      errorVisible: false,
      correctFlag: false,
    }));
  };

  const handlePaymentBonus = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }

    setPaymentBonusSum((prev) => ({
      ...prev,
      correctFlag: false,
    }));

    const errorMessage = getValidation(paymentBonusSum.value, paymentBonusSum.validationType);
    let { errorVisible } = paymentBonusSum;
    if (errorMessage) {
      errorVisible = true;
    } else if (parseFloat(paymentBonusSum.value) > parseFloat(bonusSum)) {
      showModalInfo('Сумма перевода превышает размер бонусов');
    } else {
      const paymentBonusSumValue = paymentBonusSum.value.replace(',', '.');
      setPaymentRequestWaiting(true);

      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/bonuses/execute-payment`, {
        method: 'post',
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          paymentSum: paymentBonusSumValue,
          income: false,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else {
            const newSum = parseFloat(bonusSum) - parseFloat(response.amount);
            setBonusSum(newSum);
            const newUserInfo = { ...userInfo };
            newUserInfo.bonus_sum = newSum;
            setUserInfo(newUserInfo);
            setPaymentBonusSum((prev) => ({
              ...prev,
              value: '',
            }));
            showModalInfo('Баллы успешно выведены на баланс');
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage, 'error');
        })
        .finally(() => {
          setPaymentRequestWaiting(false);
        });
    }
    setPaymentBonusSum((prev) => ({
      ...prev,
      errorMessage,
      errorVisible,
    }));
  };

  useEffect(() => {
    if (paymentBonusSum.correctFlag) {
      handlePaymentBonus();
    }
  }, [paymentBonusSum.correctFlag]);

  useEffect(() => {
    setBonusSum(userInfo.bonus_sum);
  }, [userInfo.id]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2>Накопленных баллов: {new Intl.NumberFormat('ru-RU').format(bonusSum)}</h2>
          <div className="form-group row">
            <div className="col-12">
              <span className="title_num">1</span>Регистрируйте пользователей по партнерской программе. Все взаимодействия с ними мы берем на себя.
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <span className="title_num">2</span>Копите бонусные баллы, полученные после оформления полисов вашими пользователями. 1 балл = 1 рубль.
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <span className="title_num">3</span>Приглашайте больше пользователей и получайте единовременный бонус 1000р.
            </div>
          </div>
          <h3>Перевести баллы на баланс:</h3>
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mb-2">
              <InputMask
                maskChar=""
                onChange={changePaymentBonusSum}
                id="paymentBonus"
                placeholder="100"
                name="paymentBonus"
                className={classnames('form-control', { error: paymentBonusSum.errorVisible })}
                value={paymentBonusSum.value}
                mask={getMask('decimal').mask}
                formatChars={getMask('decimal').formatChars}
              />
              {paymentBonusSum.errorVisible && (
                <div className="validation-error">
                  {paymentBonusSum.errorMessage}
                </div>
              )}
            </div>
            <div className="col-1">
              <button type="button" className="btn btn-success" disabled={paymentRequestWaiting} onClick={handlePaymentBonus} ref={paymentButtonRef}>Перевести</button>
            </div>
            <div className="col-1">
              {paymentRequestWaiting && <LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />}
            </div>
          </div>
        </div>
      </div>
      <BonusesEvents history={history} toggle={toggle} />
    </>
  );
}
