import React, {
  useEffect, useState, useMemo, useContext, useRef, useImperativeHandle, forwardRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import ReactTable from 'react-table-6';
import { reactLocalStorage } from 'reactjs-localstorage';
import MultiRef from 'react-multi-ref';
import Modal from 'react-responsive-modal';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../Layout/LoadingSpinner';
import {
  customSelectStyle,
  customFetch,
  setTableSettings,
  setTableSorting,
  getDefaultSorting,
  setTableCustomized,
  getCustomizedColumns,
} from '../Utils/Helpers';
import getMask from '../Utils/Masks';
import getValidation from '../Utils/Validation';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import useUserInfoStore from '../Stores/UserInfoStore';

function ReferralLink(props, ref) {
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  const simulateClick = new MultiRef();
  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);
  const customStyles = useMemo(() => customSelectStyle(), []);

  const [selectedRow, setSelectedRow] = useState(false);
  const [refLink, setRefLink] = useState('');
  const [agentRefLinkTypeOptions, setAgentRefLinkTypeOptions] = useState([]);
  const [refLinkTypeOptions, setRefLinkTypeOptions] = useState([]);
  const [agentRefLinkType, setAgentRefLinkType] = useState({});
  const [refLinkType, setRefLinkType] = useState({});
  const [selectedAgentRefLinkType, setSelectedAgentRefLinkType] = useState(false);
  const [selectedRefLinkType, setSelectedRefLinkType] = useState(false);

  const [dropDownActions, setDropDownActions] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [columns, setColumns] = useState([]);
  const [referralLinkList, setReferralLinkList] = useState([]);

  const [formData, setFormData] = useState({
    name: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: true,
    },
    email: {
      value: '',
      errorMessage: '',
      validationType: 'email',
      validationRequired: true,
    },
    phone: {
      value: '',
      errorMessage: '',
      validationType: 'phone',
      validationRequired: true,
    },
    quota: {
      value: '80',
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: true,
    },
  });

  const [sendingSettingsFlag, setSendingSettingsFlag] = useState('');
  const [creatingRefLinkFlag, setCreatingRefLinkFlag] = useState(false);
  const [loadingReferralLinksFlag, setLoadingReferralLinksFlag] = useState(false);
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
  }));

  const copyLink = () => {
    const copyInput = document.createElement('textarea');
    copyInput.textContent = refLink;
    copyInput.style.height = 0;
    copyInput.style.width = 0;
    document.body.appendChild(copyInput);
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(copyInput);
    window.getSelection().addRange(range);
    try {
      document.execCommand('copy');
      showModalInfo('Ссылка скопирована');
    } catch (err) {
      showModalInfo('Невозможно скопировать');
    }
    window.getSelection().removeAllRanges();
    copyInput.remove();

    setDropDownActions('');
  };

  const validateInput = (e) => {
    const fieldName = e.target.name;
    const { value } = e.target;
    const errorMessage = getValidation(value, formData[fieldName].validationType);
    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        errorMessage,
      },
    }));
  };

  const changeInput = (e) => {
    e.preventDefault();
    const fieldName = e.target.id;
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value,
        errorMessage: '',
      },
    }));
  };

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}`;
    window.history.replaceState(null, null, queryParams);
  };

  const handleTableCustomizedSetAll = () => {
    simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  const handleTableCustomizedSetNone = () => {
    simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  const handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    setTableCustomized(e.target.elements, 'referralLinkTableSettings');
    setFlagModalTableCustomized(false);
  };

  const toggleRow = (id, shortLink, type) => {
    if (selectedRow === id) {
      setSelectedRow(false);
      setRefLink('');
      setSelectedRefLinkType(false);
      setSelectedAgentRefLinkType(false);
    } else {
      setSelectedRow(id);
      setRefLink(shortLink);
      if (type === 'osago' || type === 'mortgage') {
        setSelectedRefLinkType(type);
        setSelectedAgentRefLinkType(false);
      } else {
        setSelectedRefLinkType('agent');
        setSelectedAgentRefLinkType(type);
      }
    }
  };

  const deleteReferralLink = (id) => {
    setLoadingReferralLinksFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/refLink/${id}`, {
      method: 'delete',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('Реферальная ссылка удалена');
          setReferralLinkList(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setLoadingReferralLinksFlag(false);
      });
  };

  const updateTableColumns = () => {
    const newColumns = [{
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: ({ original }) => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectedRow === original.id}
          onChange={() => toggleRow(original.id, original.short_link, original.type)}
        />
      ),
      width: 30,
    }, {
      Header: 'Тип',
      accessor: 'type',
      width: reactLocalStorage.getObject('referralLinkTableSettings').type ? reactLocalStorage.getObject('referralLinkTableSettings').type : 170,
      Cell: ({ row }) => {
        if (row.type === 'curator') return 'Кураторство';
        if (row.type === 'partnerProgram') return 'Партнерская программа';
        if (row.type === 'osago') return 'Оформление полиса ОСАГО';
        if (row.type === 'mortgage') return 'Оформление ипотечного полиса';
        return null;
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'curator') {
          return row.type === 'curator';
        }
        if (filter.value === 'partnerProgram') {
          return row.type === 'partnerProgram';
        }
        if (filter.value === 'osago') {
          return row.type === 'osago';
        }
        if (filter.value === 'mortgage') {
          return row.type === 'mortgage';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="curator">Кураторство</option>
          <option value="partnerProgram">Партнерская программа</option>
          <option value="osago">Оформление полиса ОСАГО</option>
          <option value="mortgage">Оформление ипотечного полиса</option>
        </select>
      ),
      show: reactLocalStorage.getObject('referralLinkTableSettings').show_type !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование',
      width: reactLocalStorage.getObject('referralLinkTableSettings').name,
      accessor: 'name',
      show: reactLocalStorage.getObject('referralLinkTableSettings').show_name !== false,
      showCustomized: true,
    }, {
      Header: 'Ссылка',
      width: reactLocalStorage.getObject('referralLinkTableSettings').short_link,
      accessor: 'short_link',
      show: reactLocalStorage.getObject('referralLinkTableSettings').show_short_link !== false,
      showCustomized: true,
    }, {
      Header: 'Размер квоты',
      width: reactLocalStorage.getObject('referralLinkTableSettings').short_quota,
      accessor: 'quota',
      show: reactLocalStorage.getObject('referralLinkTableSettings').show_quota !== false,
      showCustomized: true,
    }, {
      Header: 'Кол-во активаций',
      id: 'activations',
      width: reactLocalStorage.getObject('balanceTableSettings').activations,
      accessor: (d) => {
        if (d.type === 'osago') {
          return d.policies.length;
        }
        if (d.type === 'mortgage') {
          return d.mortgages.length;
        }
        return d.agents.length;
      },
      show: reactLocalStorage.getObject('referralLinkTableSettings').show_activations !== false,
      showCustomized: true,
    }, {
      Header: '',
      width: 40,
      filterable: false,
      Cell: (row) => (
        <div title="Удалить ссылку"><FontAwesomeIcon onClick={() => deleteReferralLink(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    }];

    setColumns([...newColumns]);
  };

  const loadReferralLinks = () => {
    setLoadingReferralLinksFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/refLinks`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setReferralLinkList(response);
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setLoadingReferralLinksFlag(false);
      });
  };

  const sendLink = (sendType) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let errorMessage = '';
    if (sendType === 'phone') {
      errorMessage = getValidation(formData.phone.value, formData.phone.validationType);
      setFormData((prev) => ({
        ...prev,
        phone: {
          ...prev.phone,
          errorMessage,
        },
      }));
    }
    if (sendType === 'email') {
      errorMessage = getValidation(formData.email.value, formData.email.validationType);
      setFormData((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          errorMessage,
        },
      }));
    }
    if (!errorMessage) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/send-ref-link`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          link: refLink,
          phone: formData.phone.value,
          email: formData.email.value,
          linkType: selectedRefLinkType,
          refType: selectedAgentRefLinkType,
          type: sendType,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            switch (sendType) {
              case 'phone':
                showModalInfo('Ссылка отправлена на телефон');
                break;
              case 'email':
                showModalInfo('Ссылка отправлена на почту');
                break;
              default:
                break;
            }
          } else {
            showModalInfo(response.error);
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage);
        })
        .finally(() => {
          setSendingSettingsFlag('');
        });
    }
  };

  const createRefLink = () => {
    const errorMessage = getValidation(formData.name.value, formData.name.validationType);
    if (!errorMessage) {
      setCreatingRefLinkFlag(true);
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/refLink`, {
        method: 'post',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name.value,
          type: refLinkType.value === 'agent' ? agentRefLinkType.value : refLinkType.value,
          quota: (refLinkType.value === 'agent' && agentRefLinkType.value === 'curator') ? formData.quota.value.replace(',', '.') : null,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            showModalInfo('Реферальная ссылка создана');
            const list = [...referralLinkList];
            list.unshift(response);
            setReferralLinkList(list);
            setFormData((prev) => ({
              ...prev,
              name: {
                ...prev.name,
                value: '',
                errorMessage: '',
              },
            }));
          } else {
            showModalInfo(response.error);
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage);
        })
        .finally(() => {
          setCreatingRefLinkFlag(false);
        });
    } else {
      setFormData((prev) => ({
        ...prev,
        name: {
          ...prev.name,
          errorMessage,
        },
      }));
    }
  };

  const repostLink = (socialType) => {
    let urlSocial = '';
    switch (socialType) {
      case 'vk':
        urlSocial = `https://vk.com/share.php?url=${refLink}`;
        break;
      case 'facebook':
        urlSocial = `https://www.facebook.com/sharer/sharer.php?u=${refLink}`;
        break;
      default:
        break;
    }

    if (urlSocial) {
      const newWindowWidth = 800;
      const newWindowHeight = 500;
      const newWindowLeft = (window.screen.width - newWindowWidth) / 2;
      const newWindowTop = (window.screen.height - newWindowHeight) / 2;
      const socialWindow = window.open(urlSocial, 'share_window', `height=${newWindowHeight},width=${newWindowWidth},top=${newWindowTop},left=${newWindowLeft}`);
      socialWindow.focus();
    }
    setDropDownActions('');
  };

  const dropDownBlock = () => {
    if (selectedRow !== false) {
      dropDownActions === 'opened' ? setDropDownActions('') : setDropDownActions('opened');
    } else {
      showModalInfo('Выберите ссылку в таблице');
    }
  };

  const dropDownBlockClose = () => {
    if (dropDownActions !== '') {
      setDropDownActions('');
    }
  };

  useEffect(() => {
    if (userInfo.id) {
      let newAgentRefLinkTypeOptions = [];
      let newRefLinkTypeOptions = [];

      if (userInfo.curator_obj.full_time_employee === 0) {
        newAgentRefLinkTypeOptions = userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' || userInfo.agent_type_code === 'self_employed' ? [
          { value: 'curator', label: 'Кураторство' },
        ] : [];
        newRefLinkTypeOptions = userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' ? [
          { value: 'osago', label: 'Оформление полиса ОСАГО' },
          { value: 'mortgage', label: 'Оформление ипотечного полиса' },
          { value: 'agent', label: 'Приглашение пользователя' },
        ] : [
          { value: 'osago', label: 'Оформление полиса ОСАГО' },
          { value: 'mortgage', label: 'Оформление ипотечного полиса' },
        ];
      } else {
        newAgentRefLinkTypeOptions = ((userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' || userInfo.agent_type_code === 'self_employed') || userInfo.full_time_employee) ? [
          { value: 'curator', label: 'Кураторство' },
          { value: 'partnerProgram', label: 'Партнерская программа' },
        ] : [
          { value: 'partnerProgram', label: 'Партнерская программа' },
        ];
        newRefLinkTypeOptions = [
          { value: 'osago', label: 'Оформление полиса ОСАГО' },
          { value: 'mortgage', label: 'Оформление ипотечного полиса' },
          { value: 'agent', label: 'Приглашение пользователя' },
        ];
      }
      setRefLinkTypeOptions(newRefLinkTypeOptions);
      setRefLinkType(newRefLinkTypeOptions[0]);
      if (((userInfo.domain === 'samoletplus' || userInfo.domain === 'm2' || userInfo.domain === 'ig' || userInfo.domain === 'unikor')
        && userInfo.id !== 8270 && userInfo.id !== 7846 && userInfo.id !== 11353 && userInfo.id !== 11031) || userInfo.full_time_employee
      ) {
        newAgentRefLinkTypeOptions = newAgentRefLinkTypeOptions.filter((option) => option.value !== 'partnerProgram');
      }
      setAgentRefLinkTypeOptions(newAgentRefLinkTypeOptions);
      setAgentRefLinkType(newAgentRefLinkTypeOptions[0]);
    }
  }, [userInfo.id]);

  useEffect(() => {
    setDropDownActions('');
  }, [sendingSettingsFlag]);

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    updateTableColumns();
  }, [selectedRow]);

  useEffect(() => {
    updateTableColumns();
    loadReferralLinks();
  }, []);

  return (
    <>
      <h1 className="page-wrapper__title d-inline-block mr-3 first-step">Реферальная программа</h1>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={handleSubmitTableCustomized} id="tableCustomized">
          <ul className="two-column-list">
            {getCustomizedColumns(columns, simulateClick)}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      {refLinkType.value === 'agent' ? (
        <div className="form-group row">
          <div className="col-md-12">
            <FormNotificationColorLine type="ok">
              Приглашайте пользователей в свою сеть (тип приглашения - кураторство, доступно только для юр. лиц и ИП), либо мы можем взять на себя всё взаимодействие с пользователем, а вы будете получать бонусные баллы за каждый оформленный им полис. (тип приглашения - партнерская программа)
            </FormNotificationColorLine>
          </div>
        </div>
      ) : null}
      {refLinkType.value !== 'agent' ? (
        <div className="form-group row">
          <div className="col-md-12">
            <FormNotificationColorLine type="ok">
              При оформлении полиса клиентом, идет фиксация клиента за вами и начисляется вознаграждение. Полис попадает к вам в статистику.
            </FormNotificationColorLine>
          </div>
        </div>
      ) : null}
      <div className="card mb-4">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="type">Наименование</label>
              <input
                onBlur={(e) => { validateInput(e); }}
                onChange={(e) => { changeInput(e); }}
                id="name"
                placeholder="Реферальная ссылка"
                name="name"
                className={classnames('form-control', { error: formData.name.errorMessage })}
                value={formData.name.value}
              />
              {formData.name.errorMessage && (
                <div className="validation-error">
                  {formData.name.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="type">Тип реферальной ссылки</label>
              <Select
                styles={customStyles}
                classNamePrefix="react-select"
                name="type"
                isSearchable={false}
                inputId="type"
                noOptionsMessage={() => 'Не найдено'}
                value={refLinkType.value ? refLinkType : null}
                onChange={(e) => { setRefLinkType(e); }}
                options={refLinkTypeOptions}
              />
            </div>
          </div>
          {(refLinkType.value === 'agent' && agentRefLinkTypeOptions.length > 0) ? (
            <>
              <div className="form-group row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <label htmlFor="type">Тип приглашения нового пользователя</label>
                  <Select
                    styles={customStyles}
                    classNamePrefix="react-select"
                    name="type"
                    isSearchable={false}
                    inputId="type"
                    noOptionsMessage={() => 'Не найдено'}
                    value={agentRefLinkType.value ? agentRefLinkType : null}
                    onChange={(e) => { setAgentRefLinkType(e); }}
                    options={agentRefLinkTypeOptions}
                  />
                </div>
              </div>
              {agentRefLinkType.value === 'curator' ? (
                <div className="form-group row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3 mb-lg-0">
                    <label htmlFor="quota">Укажите размер квоты (% который будете платить вашим пользователям от размера вашей комиссии):</label>
                    <InputMask
                      maskChar=""
                      onBlur={(e) => { validateInput(e); }}
                      onChange={(e) => { changeInput(e); }}
                      id="quota"
                      placeholder="90%"
                      name="quota"
                      className={classnames('form-control', { error: formData.quota.errorMessage })}
                      value={formData.quota.value}
                      mask={getMask('decimal').mask}
                      formatChars={getMask('decimal').formatChars}
                    />
                    {formData.quota.errorMessage && (
                      <div className="validation-error">
                        {formData.quota.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              {creatingRefLinkFlag ? (
                <LoadingSpinner className="loading-circle" type="spin" height={37} width={37} />
              ) : <button type="button" disabled={creatingRefLinkFlag} className="btn btn-success" onClick={createRefLink}>Создать</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row" style={{ position: 'relative', minHeight: '54px' }}>
        <div className="col-12">
          <div className="dropdown_absolute">
            <div className={`dropdown_block js-dropdown_block ${dropDownActions}`}>
              <OutsideClickHandler
                onOutsideClick={dropDownBlockClose}
              >
                <div onClick={dropDownBlock} className="dropdown_block__target">Действия с реферальной ссылкой</div>
                <ul className="dropdown_block__list js-dropdown_block__list">
                  <li><a href="" onClick={(e) => { e.preventDefault(); copyLink(); }}>Копировать</a></li>
                  <li><a href="" onClick={(e) => { e.preventDefault(); setSendingSettingsFlag('phone'); }}>Отправить по SMS</a></li>
                  <li><a href="" onClick={(e) => { e.preventDefault(); setSendingSettingsFlag('email'); }}>Отправить на E-mail</a></li>
                  <li><a href="" onClick={(e) => { e.preventDefault(); repostLink('facebook'); }}>Репост в Facebook</a></li>
                  <li><a href="" onClick={(e) => { e.preventDefault(); repostLink('vk'); }}>Репост в VK</a></li>
                </ul>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      </div>
      {sendingSettingsFlag === 'email' ? (
        <>
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="email">Укажите email:</label>
              <InputMask
                maskChar=""
                onBlur={(e) => { validateInput(e); }}
                onChange={(e) => { changeInput(e); }}
                id="email"
                placeholder="your@email.ru"
                name="email"
                className={classnames('form-control', { error: formData.email.errorMessage })}
                value={formData.email.value}
                mask={getMask('email').mask}
                formatChars={getMask('email').formatChars}
              />
              {formData.email.errorMessage && (
                <div className="validation-error">
                  {formData.email.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <button type="button" className="btn btn-success" onClick={() => { sendLink('email'); }}>Отправить</button>
            </div>
          </div>
        </>
      ) : null}
      {sendingSettingsFlag === 'phone' ? (
        <>
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="phone">Укажите телефон:</label>
              <InputMask
                maskChar=""
                onBlur={(e) => { validateInput(e); }}
                onChange={(e) => { changeInput(e); }}
                id="phone"
                placeholder="+7 (999) 999-99-99"
                name="phone"
                className={classnames('form-control', { error: formData.phone.errorMessage })}
                value={formData.phone.value}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
              />
              {formData.phone.errorMessage && (
                <div className="validation-error">
                  {formData.phone.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <button type="button" className="btn btn-success" onClick={() => { sendLink('phone'); }}>Отправить</button>
            </div>
          </div>
        </>
      ) : null}
      <ReactTable
        ref={tableRef}
        filtered={filtered}
        onFilteredChange={(data) => onFilteredChangeCustom(data)}
        className="table"
        data={referralLinkList}
        columns={columns}
        defaultSorted={[
          {
            id: getDefaultSorting('referralLinkTableSettings').field,
            desc: getDefaultSorting('referralLinkTableSettings').order,
          },
        ]}
        previousText="Назад"
        nextText="Вперед"
        loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
        noDataText="Информация не найдена"
        pageText="Страница"
        ofText="Из"
        rowsText="строк"
        loading={loadingReferralLinksFlag}
        defaultPageSize={50}
        filterable
        minRows={1}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'referralLinkTableSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings(newResized, 'referralLinkTableSettings');
        }}
      />
    </>
  );
}

export default forwardRef(ReferralLink);
