import React, {
  useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

const Tooltip = styled.div`
  right: ${(props) => (props.$leftPosition ? 'unset' : '0')} !important;
  position: absolute;
  bottom: calc(70% + 24px);
  padding: 4px;
  background: rgba(40, 40, 38, .83);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility .15s ease, opacity .15s ease;
  transition: visibility .15s ease, opacity .15s ease;
`;

const Line = styled.div`
  height: 100%;
  background-color: ${(props) => (props.background || '#f9da76')};
`;

const LineContainer = styled.div`
  height: 12px;
  -webkit-transition: height .15s ease;
  transition: height .15s ease;
  padding: 1px;
  position: relative;
  width: ${(props) => (props.width || '0%')};
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    height: 20px;
    padding: 1px;
    position: relative;
    transition: height .15s ease;
  }

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
`;

const TooltipWrapper = styled.div`
  padding: 4px 3px 3px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  span {
    left: 15px;
    width: 6px;
    height: 6px;
    background-color: ${(props) => (props.background || '#f9da76')};
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 50%;
  }

  p {
    margin-top: 0;
    margin-right: 6px;
    color: #d4d4d4;
    margin-bottom: 0;
    width: max-content;
  }

  p.percent {
    margin-right: 7px;
    color: #fff;
    margin: 0 0 0 auto;
  }
`;

export default function ProgressBarLine(props) {
  const {
    children, width, background, titleTooltip, refMain,
  } = props;
  const refContainer = useRef(null);
  const refTooltip = useRef(null);
  const [leftPosition, setLeftPosition] = useState(true);

  useEffect(() => {
    if (refMain?.current) {
      const tooltipWidth = refTooltip.current.clientWidth;
      const toolTipOffset = refContainer.current.offsetLeft + refMain.current.offsetLeft;
      const rightPosition = refMain.current.clientWidth + refMain.current.offsetLeft - 24;
      if (toolTipOffset + tooltipWidth > rightPosition) {
        setLeftPosition(false);
      }
    }
  }, [refMain]);

  return (
    <LineContainer width={width} ref={refContainer}>
      <Line background={background}>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </Line>
      <Tooltip ref={refTooltip} $leftPosition={leftPosition}>
        <TooltipWrapper background={background}>
          <span /> <p>{titleTooltip}</p> <p className="percent">({width})</p>
        </TooltipWrapper>
      </Tooltip>
    </LineContainer>
  );
}
