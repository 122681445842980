import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import ReactExport from 'react-data-export';
import Modal from 'react-responsive-modal';
import { reactLocalStorage } from 'reactjs-localstorage';
import matchSorter from 'match-sorter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faQuestionCircle,
  faFileArchive,
} from '@fortawesome/fontawesome-free-regular';
import { faSync, faCircle } from '@fortawesome/fontawesome-free-solid';
import MultiRef from 'react-multi-ref';
import InputMask from 'react-input-mask';
import Tippy from '@tippyjs/react';
import {
  parseISO, parse, format, differenceInCalendarDays,
} from 'date-fns';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import LoadingSpinner from '../Layout/LoadingSpinner';
import {
  customFetch,
  onContextMenuClick,
  onTableCellClick,
  setTableSettings7,
  setTableSorting,
  sortConversion,
  downloadReport,
  onFilteredChangeCustom,
} from '../Utils/Helpers';
import ModalInfoList from '../Utils/ModalNotification/ModalInfoList';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Table from '../Layout/Table';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();

class PoliciesTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this.reactTableRef = React.createRef();
    this.state = {
      openModalSure: false,
      showRenewal: false,
      columns: [],
      startDate: null,
      endDate: null,
      reports: [],
      loading: true,
      filtered: [],
      activeFilterTab: '1',
      excelcolumns: [],
      dataSet: [],
      openModalTableCustomized: false,
      defaultSortingField: reactLocalStorage.getObject('policiesTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('policiesTableSettings').defaultSortingField
        : 'order_date',
      defaultSortingOrder: reactLocalStorage.getObject('policiesTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('policiesTableSettings').defaultSortingOrder
        : 'true',
      modalInfoMessages: [],
      messageIdGen: 1,
    };
  }

  componentDidMount() {
    const {
      location, tab,
    } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    let urlQueryRenewal = '';
    if (urlParams.renewal) {
      this.setState({ showRenewal: true });
      urlQueryRenewal = `&renewal=${urlParams.renewal}`;
    }
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let dateFrom = format(parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date), 'yyyy-MM-dd');
    let dateTo = format(parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date), 'yyyy-MM-dd');
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        if (filter === 'filter_date') {
          const startDate = urlParams[filter].split(' ')[0] && urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', date) : null;
          const endDate = urlParams[filter].split(' ')[1] && urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', date) : null;
          dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
          dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
          this.setState({
            startDate,
            endDate,
          });
        }
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    this.downloadPolicies(dateFrom, dateTo, urlQueryRenewal);
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { userInfo } = this.props;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) || (prevProps.userInfo.role !== userInfo.role)) {
      this.updateTableColumns();
    }

    if (this.props.location.search !== prevProps.location.search) {
      const locationState = this.props.history.location.state;
      if (locationState && locationState.renewal) {
        this.showRenewal();
      }
    }
  }

  componentWillUnmount() {
    abortController.abort();
  }

  flushMessages = (messages) => {
    this.setState({ modalInfoMessages: messages });
  };

  downloadPolicies = (dateFrom, dateTo, urlQueryRenewal = '') => {
    abortController.abort();
    abortController = new AbortController();
    const { show } = this.props;
    let agentId = null;
    if (this.props.agentId) {
      agentId = this.props.agentId;
    }
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies?show=${show}&agent=${agentId}&dateFrom=${dateFrom}&dateTo=${dateTo}${urlQueryRenewal}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((reports) => {
        this.setState({
          loading: false,
          reports,
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          this.setState((prevState) => ({
            modalInfoMessages: prevState.modalInfoMessages.concat({ text: 'Ошибка загрузки данных', timeout: 5000, id: prevState.messageIdGen + 1 }),
            messageIdGen: prevState.messageIdGen + 1,
            loading: false,
          }));
        }
      });
  };

  updateTableColumns = () => {
    const {
      show, userInfo, customField, domain,
    } = this.props;
    const date = new Date();
    const columns = [{
      Header: '№',
      accessor: 'id',
      width: reactLocalStorage.getObject('policiesTableSettings').id,
      show: reactLocalStorage.getObject('policiesTableSettings').show_id !== false,
      showCustomized: true,
    }, {
      id: 'status',
      Header: 'Статус',
      width: reactLocalStorage.getObject('policiesTableSettings').status,
      filterOptions: [
        { value: '', label: 'Без фильтра' },
        { value: 'active', label: 'Активный' },
        { value: 'expired', label: 'Истек срок действия' },
        { value: 'expires', label: 'Истекает' },
        { value: 'canceled', label: 'Аннулирован' },
      ],
      Filter: 'selectColumnFilter',
      accessor: (d) => {
        let status;
        switch (d.status) {
          case 'complete':
            if (differenceInCalendarDays(parseISO(d.end_police), date) > 30) {
              status = 'active';
              break;
            }
            if (parseISO(d.end_police) < date) {
              status = 'expired';
              break;
            }
            if (parseISO(d.end_police) >= date && differenceInCalendarDays(parseISO(d.end_police), date) < 30) {
              status = 'expires';
              break;
            }
            break;
          default:
            status = d.status;
            break;
        }
        return status;
      },
      Cell: ({ row }) => {
        if (row.original.status === 'canceled') {
          return (<><FontAwesomeIcon color="#ff5f00" icon={faCircle} className="fa-fw" />Аннулирован</>);
        }
        if (parseISO(row.original.end_police) >= date) {
          if (differenceInCalendarDays(parseISO(row.original.end_police), date) < 30) {
            return (<><FontAwesomeIcon color="#f0f000" icon={faCircle} className="fa-fw" />Истекает</>);
          }
          return (<><FontAwesomeIcon color="#00E646" icon={faCircle} className="fa-fw" />Активный</>);
        }
        return (<><FontAwesomeIcon color="#ff5f00" icon={faCircle} className="fa-fw" />Истек срок действия</>);
      },
      show: reactLocalStorage.getObject('policiesTableSettings').show_status !== false,
      showCustomized: true,
    }, {
      Header: 'Дата оформления',
      width: reactLocalStorage.getObject('policiesTableSettings').order_date,
      accessor: 'order_date',
      filterable: false,
      Cell: ({ row }) => format(parseISO(row.original.order_date), 'dd.MM.yyyy'),
      show: reactLocalStorage.getObject('policiesTableSettings').show_order_date !== false,
      showCustomized: true,
    }, {
      Header: 'Дата внесения',
      width: reactLocalStorage.getObject('policiesTableSettings').created_at,
      accessor: 'created_at',
      filterable: false,
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy'),
      show: userInfo.full_time_employee && reactLocalStorage.getObject('policiesTableSettings').show_created_at !== false,
      showCustomized: userInfo.full_time_employee,
    }, {
      Header: 'Дата начала действия',
      width: reactLocalStorage.getObject('policiesTableSettings').start_police,
      filterable: false,
      accessor: 'start_police',
      Cell: ({ row }) => (row.original.start_police ? format(parseISO(row.original.start_police), 'dd.MM.yyyy') : ''),
      show: reactLocalStorage.getObject('policiesTableSettings').show_start_police !== false,
      showCustomized: true,
    }, {
      Header: 'Дата окончания действия',
      width: reactLocalStorage.getObject('policiesTableSettings').end_police,
      filterable: false,
      accessor: 'end_police',
      Cell: ({ row }) => (row.original.end_police ? format(parseISO(row.original.end_police), 'dd.MM.yyyy') : ''),
      show: reactLocalStorage.getObject('policiesTableSettings').show_end_police !== false,
      showCustomized: true,
    }, {
      Header: 'Продукт',
      id: 'policy_type',
      accessor: (d) => {
        let typeName;
        switch (d.type_insurance) {
          case 'osago':
            typeName = d.policy_type.name;
            break;
          case 'mortgage':
            switch (d.product_insurance) {
              case 'mortgage':
                typeName = 'Ипотека.Имущество';
                break;
              case 'mortgageLife':
                typeName = 'Ипотека.Жизнь';
                break;
              case 'mortgageComplex':
                typeName = 'Ипотека.Комплексное страхование';
                break;
              default:
                typeName = d.policy_type.name;
                break;
            }
            break;
          default:
            typeName = d.policy_type.name;
            break;
        }
        return typeName;
      },
      width: reactLocalStorage.getObject('policiesTableSettings').policy_type,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['policy_type'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_policy_type !== false,
      showCustomized: true,
    }, {
      Header: 'Номер полиса',
      id: 'insurance_id',
      accessor: (d) => {
        if (d.insurance_id) {
          if (d.type_insurance !== 'mortgage') {
            return d.insurance_id;
          }
          if (d.product_insurance === 'mortgage') {
            return d.insurance_id.property;
          }
          if (d.product_insurance === 'mortgageLife') {
            return d.insurance_id.life;
          }
          if (d.product_insurance === 'mortgageComplex') {
            if (d.insurance_id.union) {
              return d.insurance_id.union;
            }
            return `${d.insurance_id.property}/${d.insurance_id.life}`;
          }
        }
        return '-';
      },
      width: reactLocalStorage.getObject('policiesTableSettings').insurance_id,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insurance_id'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_insurance_id !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование компании',
      accessor: 'company',
      width: reactLocalStorage.getObject('policiesTableSettings').company,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_company !== false,
      showCustomized: true,
    }, {
      Header: 'Клиент',
      width: reactLocalStorage.getObject('policiesTableSettings').insured_full_name,
      accessor: 'insured_full_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insured_full_name'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_insured_full_name !== false,
      showCustomized: true,
    }, {
      Header: 'Объект страхования',
      accessor: 'insured_object_name',
      width: reactLocalStorage.getObject('policiesTableSettings').insured_object_name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insured_object_name'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_insured_object_name !== false,
      showCustomized: true,
    }, {
      Header: 'Регион',
      accessor: 'region',
      width: reactLocalStorage.getObject('policiesTableSettings').region,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_region !== false),
      showCustomized: true,
    }, {
      Header: 'Адрес объекта',
      accessor: 'insured_object_address',
      width: reactLocalStorage.getObject('policiesTableSettings').insured_object_address,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insured_object_address'] }),
      filterAll: true,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_insured_object_address !== false),
      showCustomized: true,
    }, {
      Header: 'Выгодоприобретатель',
      accessor: 'bank',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      showCustomized: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_bank !== false,
    }, {
      Header: 'Первичная продажа',
      id: 'isNewBuilding',
      accessor: (d) => (d.isNewBuilding ? 'Да' : 'Нет'),
      filterAll: true,
      show: (userInfo.full_time_employee === 1 || domain === 'samoletplus') && reactLocalStorage.getObject('policiesTableSettings').show_isNewBuilding !== false,
      showCustomized: userInfo.full_time_employee === 1 || domain === 'samoletplus',
    }, {
      Header: 'Тех. агент',
      width: reactLocalStorage.getObject('policiesTableSettings').tech_agent,
      accessor: 'tech_agent',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['tech_agent'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_tech_agent !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Пользователь 1-го уровня',
      width: reactLocalStorage.getObject('policiesTableSettings').agent_first_level,
      id: 'agent_first_level',
      accessor: (d) => (d.agent_first_level ? `${d.agent_first_level} (${d.agent_first_level_type})` : ''),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent_first_level'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_agent_first_level !== false),
      showCustomized: show === 'all',
    }, {
      id: 'agentName',
      Header: 'Пользователь',
      width: reactLocalStorage.getObject('policiesTableSettings').agentName,
      accessor: (d) => `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name} (${d.agent_type ? d.agent_type.name : '-'})`,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agentName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'all' || show === 'full_time_employee') && reactLocalStorage.getObject('policiesTableSettings').show_agentName !== false,
      showCustomized: show === 'all' || show === 'full_time_employee',
    }, {
      id: 'agent_city',
      Header: 'Регион пользователя',
      width: reactLocalStorage.getObject('policiesTableSettings').agent_city,
      accessor: (d) => d.agent.city,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent_city'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'all' || show === 'full_time_employee') && reactLocalStorage.getObject('policiesTableSettings').show_agent_city !== false,
      showCustomized: show === 'all' || show === 'full_time_employee',
    }, {
      Header: 'Партнер',
      width: reactLocalStorage.getObject('policiesTableSettings').partnerName,
      accessor: 'partnerName',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['partnerName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_partnerName !== false,
      showCustomized: show === 'all',
    }, {
      id: 'curator',
      Header: 'Штатный куратор',
      width: reactLocalStorage.getObject('policiesTableSettings').curator,
      accessor: (d) => d.curator,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['curator'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_curator !== false,
      showCustomized: show === 'all',
    }, {
      id: 'full_name_subagents_network',
      Header: 'Пользователь',
      width: reactLocalStorage.getObject('policiesTableSettings').full_name_subagents_network,
      accessor: (d) => d.full_name_subagents_network,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['full_name_subagents_network'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'subagents_network' && reactLocalStorage.getObject('policiesTableSettings').show_full_name_subagents_network !== false),
      showCustomized: show === 'subagents_network',
    }, {
      Header: 'Юр. лицо/ИП',
      id: 'organization_name',
      accessor: (d) => d.agent.organization_name,
      width: reactLocalStorage.getObject('policiesTableSettings').organization_name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['organization_name'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      minWidth: 150,
      show: ((show === 'all' || show === 'subagents_network') && reactLocalStorage.getObject('policiesTableSettings').show_organization_name !== false),
      showCustomized: show === 'all' || show === 'subagents_network',
    }, {
      Header: 'Скидка, %',
      accessor: 'discountPercent',
      width: reactLocalStorage.getObject('policiesTableSettings').discountPercent,
      show: reactLocalStorage.getObject('policiesTableSettings').show_discountPercent !== false,
      showCustomized: true,
    }, {
      Header: 'Надбавка, %',
      accessor: 'allowancePercent',
      width: reactLocalStorage.getObject('policiesTableSettings').allowancePercent,
      show: reactLocalStorage.getObject('policiesTableSettings').show_allowancePercent !== false,
      showCustomized: true,
    }, {
      Header: 'Страховая премия, руб.',
      id: 'cost',
      width: reactLocalStorage.getObject('policiesTableSettings').cost,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['cost'] }),
      filterAll: true,
      accessor: (d) => parseFloat(d.cost),
      show: reactLocalStorage.getObject('policiesTableSettings').show_cost !== false,
      showCustomized: true,
    }, {
      Header: 'Вознаграждение входящее, %',
      accessor: 'company_commission_persentage',
      width: reactLocalStorage.getObject('policiesTableSettings').company_commission_persentage,
      show: userInfo.hide_commission === 0 && reactLocalStorage.getObject('policiesTableSettings').show_company_commission_persentage !== false,
      showCustomized: userInfo.hide_commission === 0,
    }, {
      Header: 'Вознаграждение входящее, руб.',
      accessor: 'company_commission_rub',
      width: reactLocalStorage.getObject('policiesTableSettings').company_commission_rub,
      show: userInfo.hide_commission === 0 && reactLocalStorage.getObject('policiesTableSettings').show_company_commission_rub !== false,
      showCustomized: userInfo.hide_commission === 0,
    }, {
      Header: 'Вознаграждение пользователя 1-го уровня, %',
      accessor: 'agent_first_level_commission_persentage',
      width: reactLocalStorage.getObject('policiesTableSettings').agent_first_level_commission_persentage,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_agent_first_level_commission_persentage !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Вознаграждение пользователя 1-го уровня, руб.',
      accessor: 'agent_first_level_company_commission_rub',
      width: reactLocalStorage.getObject('policiesTableSettings').agent_first_level_company_commission_rub,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_agent_first_level_company_commission_rub !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Вознаграждение пользователя, %',
      id: 'persantage',
      accessor: (d) => parseFloat(((d.sum_in * 100) / d.cost).toFixed(2)),
      width: reactLocalStorage.getObject('policiesTableSettings').cost,
      show: (userInfo.hide_commission === 0 && show === 'all') && reactLocalStorage.getObject('policiesTableSettings').show_persantage !== false,
      showCustomized: userInfo.hide_commission === 0 && show === 'all',
    }, {
      Header: 'Вознаграждение пользователя, руб.',
      id: 'sum_in',
      accessor: (d) => d.sum_in,
      width: reactLocalStorage.getObject('policiesTableSettings').sum_in,
      show: (userInfo.hide_commission === 0 && show === 'all') && reactLocalStorage.getObject('policiesTableSettings').show_sum_in !== false,
      showCustomized: userInfo.hide_commission === 0 && show === 'all',
    }, {
      Header: 'Вознаграждение пользователя, %',
      id: 'persantage_subagents_network',
      accessor: (d) => parseFloat(((d.sum_in_subagents_network * 100) / d.cost).toFixed(2)),
      width: reactLocalStorage.getObject('policiesTableSettings').persantage_subagents_network,
      show: (userInfo.hide_commission === 0 && show === 'subagents_network' && reactLocalStorage.getObject('policiesTableSettings').show_persantage_subagents_network !== false),
      showCustomized: userInfo.hide_commission === 0 && show === 'subagents_network',
    }, {
      Header: 'Вознаграждение пользователя, руб.',
      id: 'sum_in_subagents_network',
      accessor: (d) => parseFloat(parseFloat(d.sum_in_subagents_network).toFixed(2)),
      width: reactLocalStorage.getObject('policiesTableSettings').sum_in_subagents_network,
      show: (userInfo.hide_commission === 0 && show === 'subagents_network' && reactLocalStorage.getObject('policiesTableSettings').show_sum_in_subagents_network !== false),
      showCustomized: userInfo.hide_commission === 0 && show === 'subagents_network',
    }, {
      Header: 'Вознаграждение партнера, %',
      id: 'partner_profit',
      accessor: (d) => parseFloat(((d.partner_profit_rub * 100) / d.cost).toFixed(2)),
      width: reactLocalStorage.getObject('policiesTableSettings').partner_profit,
      show: (userInfo.hide_commission === 0 && show === 'all') && reactLocalStorage.getObject('policiesTableSettings').show_partner_profit !== false,
      showCustomized: userInfo.hide_commission === 0 && show === 'all',
    }, {
      Header: 'Вознаграждение партнера, руб.',
      id: 'partner_profit_rub',
      accessor: (d) => parseFloat(parseFloat(d.partner_profit_rub).toFixed(2)),
      width: reactLocalStorage.getObject('policiesTableSettings').partner_profit_rub,
      show: (userInfo.hide_commission === 0 && show === 'all') && reactLocalStorage.getObject('policiesTableSettings').show_partner_profit_rub !== false,
      showCustomized: userInfo.hide_commission === 0 && show === 'all',
    }, {
      Header: 'РВД',
      accessor: 'rvd',
      width: reactLocalStorage.getObject('policiesTableSettings').rvd,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_rvd !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Налог',
      accessor: 'tax',
      width: reactLocalStorage.getObject('policiesTableSettings').tax,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_tax !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Доход, руб.',
      id: 'profit_in',
      accessor: (d) => (d.partner_flag ? parseFloat(parseFloat(d.company_commission_rub).toFixed(2)) : parseFloat(parseFloat(d.company_commission_rub - d.sum_in_subagents_network).toFixed(2))),
      width: reactLocalStorage.getObject('policiesTableSettings').profit_in,
      show: (userInfo.hide_commission === 0 && show === 'subagents_network' && reactLocalStorage.getObject('policiesTableSettings').show_profit_in !== false),
      showCustomized: userInfo.hide_commission === 0 && show === 'subagents_network',
    }, {
      Header: 'Доход компании, руб.',
      accessor: 'company_profit_in',
      width: reactLocalStorage.getObject('policiesTableSettings').company_profit_in,
      show: (show === 'all' && reactLocalStorage.getObject('policiesTableSettings').show_company_profit_in !== false),
      showCustomized: show === 'all',
    }, {
      Header: 'Дата начала действия кредитного договора',
      width: reactLocalStorage.getObject('policiesTableSettings').creditAgreementDate,
      filterable: false,
      accessor: 'creditAgreementDate',
      Cell: ({ row }) => (row.original.creditAgreementDate ? format(parseISO(row.original.creditAgreementDate), 'dd.MM.yyyy') : ''),
      show: reactLocalStorage.getObject('policiesTableSettings').show_creditAgreementDate !== false,
      showCustomized: true,
    }, {
      Header: 'Виджет',
      accessor: 'widget_id',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['widget_id'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('policiesTableSettings').widget_id,
      show: reactLocalStorage.getObject('policiesTableSettings').show_widget_id !== false,
      showCustomized: true,
    }, {
      Header: 'Интеграция',
      id: 'type_integration',
      accessor: (d) => {
        if (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) {
          return d.type_integration;
        }
        let typeIntegration = '';
        switch (d.type_integration) {
          case 'azure':
          case 'smart':
          case 'sravni':
          case 'direct':
          case 'inguru':
          case 'insapp':
            typeIntegration = 'Прямое оформление';
            break;
          case 'b2b':
            typeIntegration = 'B2B';
            break;
          default:
            typeIntegration = '';
        }
        return typeIntegration;
      },
      width: reactLocalStorage.getObject('policiesTableSettings').type_integration,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type_integration'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_type_integration !== false,
      showCustomized: true,
    }, {
      Header: 'Тип оформления',
      id: 'type_declaration',
      accessor: (d) => {
        let typeDeclaration = '';
        switch (d.type_declaration) {
          case 'middle-office':
            typeDeclaration = 'Мидл-офис';
            break;
          case 'digital-broker':
            typeDeclaration = 'Цифровой брокер';
            break;
          case 'curator':
            typeDeclaration = 'Куратор СК';
            break;
          case 'direct':
            typeDeclaration = 'Доступ B2B СК';
            break;
          case 'widget':
            typeDeclaration = 'Виджет';
            break;
          case 'lead':
            typeDeclaration = 'Передача лидов';
            break;
          case 'standard':
            typeDeclaration = 'Общий порядок';
            break;
          default:
            typeDeclaration = '';
        }
        return typeDeclaration;
      },
      width: reactLocalStorage.getObject('policiesTableSettings').type_declaration,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type_declaration'] }),
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_type_declaration !== false,
      showCustomized: true,
    }, {
      Header: 'Реферальная ссылка',
      id: 'referral_link',
      accessor: (d) => (d.referral_link ? d.referral_link.name : ''),
      width: reactLocalStorage.getObject('policiesTableSettings').referral_link,
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_referral_link !== false,
      showCustomized: true,
    }, {
      Header: 'Лиц. договор',
      id: 'custom_contract',
      accessor: (d) => (d.custom_contract && !d.custom_contract.is_polis_online_contract ? 'Да' : 'Нет'),
      width: reactLocalStorage.getObject('policiesTableSettings').custom_contract,
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_custom_contract !== false,
      showCustomized: true,
    }, {
      Header: 'Продано по акции',
      id: 'stock',
      accessor: (d) => ((d.stock && d.stock[d.company_code]) ? 'Да' : 'Нет'),
      width: reactLocalStorage.getObject('policiesTableSettings').stock,
      filterAll: true,
      show: reactLocalStorage.getObject('policiesTableSettings').show_stock !== false,
      showCustomized: true,
    }, {
      Header: 'Partner ID',
      accessor: 'partner_id',
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').partner_id,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_partner_id !== false),
      showCustomized: true,
    }, {
      Header: 'Client ID',
      accessor: 'client_id',
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').client_id,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_client_id !== false),
      showCustomized: true,
    }, {
      Header: 'Source',
      accessor: 'source',
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').source,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_source !== false),
      showCustomized: true,
    }, {
      Header: customField || 'custom_field',
      id: 'custom_field',
      accessor: (d) => (d.custom_field ? d.custom_field.name : null),
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').custom_field,
      show: customField && reactLocalStorage.getObject('policiesTableSettings').show_custom_field !== false,
      showCustomized: customField,
    }, {
      Header: 'Загружен в ERP',
      id: 'erp_status',
      accessor: (d) => (d.erp_status ? 'Да' : 'Нет'),
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').erp_status,
      show: userInfo.full_time_employee === 1 && reactLocalStorage.getObject('policiesTableSettings').show_erp_status !== false,
      showCustomized: userInfo.full_time_employee === 1,
    }, {
      Header: 'ИНН',
      id: 'inn',
      accessor: (d) => `${(d.agent_first_level_inn ? d.agent_first_level_inn : '')}`,
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').inn,
      show: ((show === 'all' || show === 'subagents_network') && reactLocalStorage.getObject('policiesTableSettings').show_inn && reactLocalStorage.getObject('policiesTableSettings').show_inn !== false),
      showCustomized: show === 'all' || show === 'subagents_network',
    }, {
      Header: 'Дата рождения страхователя',
      accessor: 'insurer_birthday',
      Cell: ({ row }) => {
        if (row.original.type_insurance === 'osago' && row.original.insurer_birthday) {
          return row.original.insurer_birthday;
        }
        if (row.original.type_insurance !== 'osago' && row.original.insurer_birthday) {
          return format(parseISO(row.original.insurer_birthday), 'dd.MM.yyyy');
        }
        return '-';
      },
      minWidth: 150,
      width: reactLocalStorage.getObject('policiesTableSettings').insurer_birthday,
      show: (reactLocalStorage.getObject('policiesTableSettings').show_insurer_birthday && reactLocalStorage.getObject('policiesTableSettings').show_insurer_birthday !== false),
      showCustomized: true,
    }, {
      Header: 'Azure',
      minWidth: 100,
      filterable: false,
      id: 'azure_conversion_rate',
      accessor: (d) => {
        if (d.azure_conversion_rate) {
          if (d.azure_conversion_rate.all > 0) {
            const percent = (parseFloat(d.azure_conversion_rate.success) / parseFloat(d.azure_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.azure_conversion_rate.success}/${d.azure_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_azure_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'УП',
      minWidth: 100,
      filterable: false,
      id: 'smart_conversion_rate',
      accessor: (d) => {
        if (d.smart_conversion_rate) {
          if (d.smart_conversion_rate.all > 0) {
            const percent = (parseFloat(d.smart_conversion_rate.success) / parseFloat(d.smart_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.smart_conversion_rate.success}/${d.smart_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_smart_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Сравни',
      minWidth: 100,
      filterable: false,
      id: 'sravni_conversion_rate',
      accessor: (d) => {
        if (d.sravni_conversion_rate) {
          if (d.sravni_conversion_rate.all > 0) {
            const percent = (parseFloat(d.sravni_conversion_rate.success) / parseFloat(d.sravni_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.sravni_conversion_rate.success}/${d.sravni_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_sravni_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Ингуру',
      minWidth: 100,
      filterable: false,
      id: 'inguru_conversion_rate',
      accessor: (d) => {
        if (d.inguru_conversion_rate) {
          if (d.inguru_conversion_rate.all > 0) {
            const percent = (parseFloat(d.inguru_conversion_rate.success) / parseFloat(d.inguru_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.inguru_conversion_rate.success}/${d.inguru_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_inguru_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Инсапп',
      minWidth: 100,
      filterable: false,
      id: 'insapp_conversion_rate',
      accessor: (d) => {
        if (d.insapp_conversion_rate) {
          if (d.insapp_conversion_rate.all > 0) {
            const percent = (parseFloat(d.insapp_conversion_rate.success) / parseFloat(d.insapp_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.insapp_conversion_rate.success}/${d.insapp_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_insapp_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Прямые интеграции',
      minWidth: 100,
      filterable: false,
      id: 'direct_conversion_rate',
      accessor: (d) => {
        if (d.direct_conversion_rate) {
          if (d.direct_conversion_rate.all > 0) {
            const percent = (parseFloat(d.direct_conversion_rate.success) / parseFloat(d.direct_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.direct_conversion_rate.success}/${d.direct_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_direct_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Общая',
      minWidth: 100,
      width: reactLocalStorage.getObject('policiesTableSettings').total_conversion_rate,
      filterable: false,
      id: 'total_conversion_rate',
      accessor: (d) => {
        if (d.total_conversion_rate) {
          if (d.total_conversion_rate.all > 0) {
            const percent = (parseFloat(d.total_conversion_rate.success) / parseFloat(d.total_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.total_conversion_rate.success}/${d.total_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('policiesTableSettings').show_total_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: '',
      width: 40,
      id: 'download_policy',
      show: true,
      filterable: false,
      Cell: ({ row }) => {
        if (row.original.link) {
          if (row.original.product_insurance === 'mortgage') {
            return (<a title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link.property}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>);
          }
          if (row.original.product_insurance === 'mortgageLife') {
            return (<a title="Скачать печатную форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link.life}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>);
          }
          if (row.original.product_insurance === 'mortgageComplex') {
            if (row.original.link.union) {
              return (<a title="Скачать печатную форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link.union}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>);
            }
            return (
              <>
                <a title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link.property}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>
                <a title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link.life}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>
              </>
            );
          }
          if (row.original.type_insurance !== 'mortgage') {
            return (<a title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>);
          }
        }
        return '-';
      },
    }, {
      Header: '',
      width: 40,
      id: 'download_archive',
      filterable: false,
      show: true,
      Cell: ({ row }) => (row.original.archive && row.original.type_insurance !== 'mortgage' ? (
        <a title="Скачать архив" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.archive}`}><FontAwesomeIcon icon={faFileArchive} className="fa-fw fa-lg" /></a>
      ) : (<>-</>)),
    }, {
      Header: '',
      id: 'copy_policy',
      width: 40,
      show: true,
      filterable: false,
      Cell: ({ row }) => row.original.type_insurance !== 'mortgage' && row.original.type_insurance !== 'accident' && (
        <div title="Пролонгировать полис"><FontAwesomeIcon onClick={() => this.copyPolicy(row.original.id)} icon={faSync} className="fa-fw" /></div>
      ),
    },
    ];
    this.setState({ columns });
  };

  handleChangeStart = (date) => {
    this.setState({ reports: [], showRenewal: false }, () => {
      const { endDate, filtered } = this.state;
      this.setState({ startDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateTo = null;
      if (endDate !== null) {
        filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
        dateTo = format(endDate, 'yyyy-MM-dd');
      }
      this.downloadPolicies(format(date, 'yyyy-MM-dd'), dateTo);

      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({ reports: [], showRenewal: false }, () => {
      const { startDate, filtered } = this.state;
      this.setState({ endDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateFrom = null;
      if (startDate !== null) {
        filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }
      this.downloadPolicies(dateFrom, format(date, 'yyyy-MM-dd'));

      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  customFilter = (filter, row) => {
    const { startDate, endDate } = this.state;
    if (startDate === null && endDate === null) {
      return (parse(row.order_date, 'dd.MM.yyyy', new Date()) === parse(filter.value, 'dd.MM.yyyy', new Date())) ? 1 : 0;
    }
    if (startDate === null) {
      return parse(row.order_date, 'dd.MM.yyyy', new Date()) <= parse(filter.value, 'dd.MM.yyyy', new Date()) ? 1 : 0;
    }
    if (endDate === null) {
      return parse(row.order_date, 'dd.MM.yyyy', new Date()) >= parse(filter.value, 'dd.MM.yyyy', new Date()) ? 1 : 0;
    }
    return (parse(row.order_date, 'dd.MM.yyyy', new Date()) >= parse(filter.value.split(' ')[0], 'dd.MM.yyyy', new Date()) && parse(row.order_date, 'dd.MM.yyyy', new Date()) <= parse(filter.value.split(' ')[1], 'dd.MM.yyyy', new Date())) ? 1 : 0;
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  setData = (excelcolumns, dataSet) => {
    this.setState({ excelcolumns, dataSet }, function () {
      this.excelFile.handleDownload();
    });
  }

  downloadReport = () => {
    const { columns } = this.state;
    downloadReport(this.reactTableRef, columns, [], this.setData);
  };

  copyPolicy = (id) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { history } = this.props;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/copy/${id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((policyId) => {
        history.push(`osago/${policyId}`);
      });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('policiesTableSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('policiesTableSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  showRenewal = () => {
    this.setState((prevState) => ({
      showRenewal: !prevState.showRenewal,
      reports: [],
    }), () => {
      const { showRenewal } = this.state;
      if (showRenewal) {
        const { filtered } = this.state;
        const date = new Date();
        const tab = '3';
        this.setState({
          startDate: null,
          endDate: date,
          activeFilterTab: tab,
        });
        let filterDate = null;
        filterDate = `${format(date, 'dd.MM.yyyy')}`;
        const newFiltered = filtered;
        let findedIndex = -1;
        let findedIndexDateTab = -1;
        newFiltered.forEach((filter, i) => {
          if (filter.id === 'filter_date') {
            findedIndex = i;
          }
          if (filter.id === 'dateTab') {
            findedIndexDateTab = i;
          }
        });
        if (findedIndex !== -1) {
          if (filterDate === null) {
            newFiltered.splice(findedIndex, 1);
          } else {
            newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
          }
        } else if (filterDate !== null) {
          newFiltered.push({ id: 'filter_date', value: filterDate });
        }
        if (findedIndexDateTab !== -1) {
          newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
        } else {
          newFiltered.push({ id: 'dateTab', value: tab });
        }

        const queryParams = `?filter_date=null ${filterDate}&dateTab=3&activeTab=${this.props.tab}&renewal=true`;
        const { history } = this.props;
        history.push({ search: queryParams });
        this.setState({ filtered: newFiltered });
        this.downloadPolicies('null', 'null', '&renewal=true');
      } else {
        this.toggle('1');
      }
    });
  };

  updatePolicy = () => {
    const { openModalSure } = this.state;
    const { filteredData } = this.reactTableRef.current.getTableData();
    const policies = filteredData.map((policy) => ({ id: policy.original.id, product: policy.original.policy_type.code }));
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/${openModalSure}`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ policies }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo('Полисы обновлены');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка обновления', 'error');
      })
      .finally(() => {
        this.setState({ loading: false, openModalSure: false });
      });
  };

  toggleUpdatePolicyWindow = (type) => {
    this.setState(() => ({
      openModalSure: type,
    }));
  };

  toggle(tab) {
    this.setState({ reports: [], showRenewal: false }, () => {
      const { filtered } = this.state;
      const newFiltered = [...filtered];
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let dateFrom;
      let dateTo;
      this.setState({ activeFilterTab: tab });
      let filterDate = null;
      if (tab === '1') {
        dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
        dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateCurrentMonth,
          endDate: endDateCurrentMonth,
        });
        filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
      } else if (tab === '2') {
        if (currentMonth === 1) {
          currentYear -= 1;
          lastButOneMonth = '12';
          lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
          startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
          endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
        }
        dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
        dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateLastButOneMonth,
          endDate: endDateLastButOneMonth,
        });
        filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
      } else if (tab === '3') {
        dateFrom = null;
        dateTo = null;

        this.setState({
          startDate: null,
          endDate: date,
        });
        filterDate = `${format(date, 'dd.MM.yyyy')}`;
      }

      let findedIndex = -1;
      let findedIndexDateTab = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
        if (filter.id === 'dateTab') {
          findedIndexDateTab = i;
        }
      });
      if (findedIndex !== -1) {
        if (filterDate === null) {
          newFiltered.splice(findedIndex, 1);
        } else {
          newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
        }
      } else if (filterDate !== null) {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      if (findedIndexDateTab !== -1) {
        newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
      } else {
        newFiltered.push({ id: 'dateTab', value: tab });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab' && filter.id !== 'renewal') {
          if (filter.id === 'filter_date' && tab === '3') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
          }
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
      this.setState({ filtered: newFiltered });
      this.downloadPolicies(dateFrom, dateTo);
    });
  }

  render() {
    const {
      reports,
      loading,
      startDate,
      endDate,
      activeFilterTab,
      dataSet,
      excelcolumns,
      openModalTableCustomized,
      columns,
      showRenewal,
      defaultSortingField,
      defaultSortingOrder,
      modalInfoMessages,
      openModalSure,
      filtered,
    } = this.state;
    const { history, userInfo, tab } = this.props;
    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <ModalInfoList messages={modalInfoMessages} flushMessages={this.flushMessages} />
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalSure}
          onClose={() => this.toggleUpdatePolicyWindow(false)}
          center
        >
          <h4>Вы уверены?</h4>
          <button disabled={loading} className="btn btn-success mr-2" type="button" onClick={this.updatePolicy}>Да</button>
          <button disabled={loading} type="button" className="btn btn-secondary" onClick={() => this.toggleUpdatePolicyWindow(false)}>Нет</button>
          {loading && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggle('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggle('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggle('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2">
            <span>Точные даты с</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="form-inline mt-2 d-inline-flex">
            <Tippy
              className="tippy-custom"
              arrow={false}
              offset={[0, 5]}
              content={<div style={{ maxWidth: 450 }}>Срок действия заканчивается в промежутке от -7 до + 50 дней от текущей даты</div>}
              hideOnClick={false}
            >
              <div>
                <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
              </div>
            </Tippy>
            <span className="switch-text">Требующие пролонгации</span>
            <label className="switch mr-2">
              <input type="checkbox" checked={showRenewal} />
              <span onClick={this.showRenewal} className="slider round" />
            </label>
            {userInfo.role === 'superadmin' ? (
              <>
                <button type="button" disabled={loading} onClick={() => this.toggleUpdatePolicyWindow('updateInErp')} className="btn btn-success mr-1 mb-1 mb-md-0 mt-1 mt-md-0">Обновить полисы в ERP</button>
                <button type="button" disabled={loading} onClick={() => this.toggleUpdatePolicyWindow('updateProfit')} className="btn btn-success mb-1 mb-md-0">Заново начислить вознаграждение</button>
              </>
            ) : null}
          </div>
        </div>
        <Table
          ref={this.reactTableRef}
          data={reports}
          columns={columns}
          loading={loading}
          onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, row.original.type_insurance, history)}
          onContextMenuClick={(e, row) => onContextMenuClick(e, row.original.type_insurance, row.original.id)}
          noDataText="Полисы не найдены"
          defaultFiltered={filtered}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'policiesTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings7(newResized, 'policiesTableSettings');
          }}
          onFilteredChange={(newFiltered) => onFilteredChangeCustom(newFiltered, startDate, tab)}
        />
      </>
    );
  }
}

PoliciesTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(PoliciesTable);
