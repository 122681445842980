import React, { Component } from 'react';
import {
  Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';

export default class WidgetAnalyticBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsSummaryByDay: 0,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.analyticsData.length > 0) {
      const analyticsSummaryByDay = Math.round((props.analyticsSummary[props.type] / props.analyticsData.length));

      return {
        analyticsSummaryByDay,
      };
    }
    return null;
  }

  render() {
    const {
      analyticsSummary,
      color,
      name,
      analyticsData,
      type,
      stackId,
      percent,
    } = this.props;

    const { analyticsSummaryByDay } = this.state;
    let yAxis = <YAxis allowDecimals={false} stroke="#000" axisLine={false} tickLine={false} allowDuplicatedCategory={false} />;
    if (percent) {
      yAxis = <YAxis allowDecimals={false} stroke="#000" axisLine={false} tickLine={false} allowDuplicatedCategory={false} />;
    }

    return (
      <div className="widget-analytics-summary__item">
        <h5>{name}</h5>
        <br />
        <div className="widget-analytics-item-info">
          <div className="widget-analytics-item-info__row">
            <h6>Всего за период</h6>
            <h4>{analyticsSummary[type]}</h4>
          </div>
          <div className="widget-analytics-item-info__row">
            <h6>Среднее за день</h6>
            <h4>{analyticsSummaryByDay}</h4>
          </div>
        </div>
        <br />
        <br />
        <div style={{ width: '100%', height: '250px' }}>
          {analyticsData.length > 0 ? (
            <ResponsiveContainer height={250}>
              <AreaChart
                data={analyticsData}
                margin={{
                  top: 0, right: 0, left: 0, bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id={`colorFill${stackId}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0.4} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="created_at" stroke="#000" />
                {yAxis}
                <Tooltip />
                <Area name={name} type="monotone" dataKey={type} stackId={stackId} stroke={color} fillOpacity={1} fill={`url(#colorFill${stackId})`} />
              </AreaChart>
            </ResponsiveContainer>
          ) : null}
        </div>
      </div>
    );
  }
}
