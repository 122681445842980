import React, { Component } from 'react';
import queryString from 'query-string';
import {
  TabContent, TabPane,
} from 'reactstrap';
import RequestsTable from './RequestsTable';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class RequestsList extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Лиды';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const {
      activeTab,
    } = this.state;

    const {
      type,
      history,
      userInfo,
    } = this.props;

    return (
      <>
        <div className="position-relative">
          <h1 className="page-wrapper__title d-inline-block mr-3">Лиды</h1>
          <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
              <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
              Настроить таблицу
            </Button>
          </div>
          <h2>Заявки и расчеты, оставленные пользователями через ваши виджеты</h2>
          <TabNavList>
            <TabNavItem
              active={activeTab === '1'}
              onClick={() => { this.toggle('1'); }}
              text="Мои лиды"
            />
            <TabNavItem
              active={activeTab === '2'}
              onClick={() => { this.toggle('2'); }}
              text="Лиды пользователей"
            />
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabNavItem
                active={activeTab === '3'}
                onClick={() => { this.toggle('3'); }}
                text="Все лиды (админ)"
              />
            )}
          </TabNavList>
          <TabContent animation="false" activeTab={activeTab}>
            <TabPane tabId="1">
              { activeTab === '1' ? <RequestsTable ref={activeTab === '1' ? this.child : undefined} type={type} history={history} tab={activeTab} /> : null }
            </TabPane>
            <TabPane tabId="2">
              { activeTab === '2' ? <RequestsTable ref={activeTab === '2' ? this.child : undefined} type={type} history={history} tab={activeTab} show="subagents" /> : null }
            </TabPane>
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabPane tabId="3">
                { activeTab === '3' ? <RequestsTable ref={activeTab === '3' ? this.child : undefined} type={type} history={history} tab={activeTab} show="all" /> : null }
              </TabPane>
            )}
          </TabContent>
        </div>
      </>
    );
  }
}

export default withUserInfoStoreStore(RequestsList);
