import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import queryString from 'query-string';
import { format, parseISO } from 'date-fns';
import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import {
  UserGroupIcon, DocumentDuplicateIcon, PencilSquareIcon, CodeBracketSquareIcon,
} from '@heroicons/react/20/solid';
import { scroller } from 'react-scroll';
import LoadingSpinner from '../Layout/LoadingSpinner';
import AgentInfo from './AgentInfo';
import AgentNetwork from './AgentNetwork';
import AgentCommission from './AgentCommission';
import PoliciesTable from '../Products/PoliciesTable';
import MailList from './MailList';
import { customFetch, toLocalDate } from '../Utils/Helpers';
import DraftsTable from '../Products/DraftsTable';
import PaymentInfo from './PaymentInfo';
import WidgetsTable from '../Widgets/WidgetsTable';
import AgentPollInfo from './AgentPollInfo';
import AgentInsuranceFilter from './AgentInsuranceFilter';
import AgentPartnerProgram from './AgentPartnerProgram';
import InterfaceTourAgentFlagsTable from '../InterfaceTours/InterfaceTourAgentFlagsTable';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import BalanceTable from '../Balance/BalanceTable';
import BonusesHistoryTable from '../Bonuses/BonusesHistoryTable';
import CustomContracts from './CustomContracts/CustomContracts';
import AgentInsuranceIdList from './AgentInsuranceIdList';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import Icon from '../Utils/UiComponents/CustomIcons';
import AgentEnforcementProceedings from './AgentEnforcementProceedings/AgentEnforcementProceedings';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Agent extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      loadingAgentFlag: false,
      agentsFlagsList: [],
      activeTab: '1',
      errorNotAllowed: false,
      generateXlsxTableFlag: false,
      agent: {
        crm_user_id: false,
        parentAgentIsFullTimeEmployee: false,
        kbm_cost: 0,
        property_cost: 0,
        sbi_agent: 0,
        remote_employee: 0,
        parser_api_limit: 0,
        parser_api_limit_counter: 0,
        parser_api_limit_is_paid: false,
        egarant_cost: 0,
        first_name: '',
        last_name: '',
        middle_name: '',
        phone: '',
        email: '',
        city: '',
        curator: '',
        curatorName: '',
        allow_checkbox_payment_partner: false,
        allow_agent_network: false,
        hide_commission: false,
        comment: '',
        full_time_employee: false,
        company: '',
        link_to_crm: '',
        crm_contact_id: '',
        crm_deal_id: '',
        last_meet_date: null,
        crm_comment: '',
        category: '',
        agent_type_code: '',
        sales_volume: '1',
        agentParentName: '',
        status: '',
        id: '',
        confirmed: 'yes',
        registrationType: '',
        balance: 0,
        policiesCount: 0,
        policiesCountDrafts: 0,
        widgetsCount: 0,
        partnersCount: 0,
        '1c_id': '',
        show_balance: false,
        allow_curator_drafts: false,
        description: '',
        tech_agent_id: '',
        partner_agent_id: '',
        partner_agent_name: '',
        show_egarant_block: 0,
        first_level: false,
        license_contract_signed: false,
        field_of_activity: '',
        office_number: null,
        is_digital_broker: false,
        is_middle_office_user: false,
        is_middle_office_director: false,
        ignore_category: false,
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
    this.downloadAgentInfo();
    this.loadInterfaceToursFlags();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.downloadAgentInfo();
      this.setState({ activeTab: '1' });
    }
  }

  loadInterfaceToursFlags = () => {
    const { showModalInfo } = this.context;
    const { location } = this.props;
    const agentId = location.pathname.substr(location.pathname.lastIndexOf('/') + 1);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/get-tour-by-agent/${agentId}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({ agentsFlagsList: response });
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  downloadAgentInfo = () => {
    const { location } = this.props;
    this.setState({ loadingAgentFlag: true });
    const agentId = location.pathname.substr(location.pathname.lastIndexOf('/') + 1);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agentId}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((obj) => {
        if (obj.error) {
          document.title = 'Ошибка | Polis.Online';
          this.setState({ errorNotAllowed: obj.error });
        } else {
          document.title = `${obj.last_name} ${obj.first_name} ${obj.middle_name} | Polis.Online`;
          this.setState({ agent: obj });
        }
      })
      .catch(() => {
        this.setState({ errorNotAllowed: 'Пользователь не существует' });
      })
      .finally(() => {
        this.setState({ loadingAgentFlag: false });
      });
  };

  updateAgent = (value) => {
    this.setState({ agent: value });
  };

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  setGenerateXlsxTableFlag = (value) => {
    this.setState({ generateXlsxTableFlag: value });
  };

  openModalTableCustomized = () => {
    this.child.current.openModalTableCustomized(true);
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const {
      agent, errorNotAllowed, activeTab, generateXlsxTableFlag, agentsFlagsList, loadingAgentFlag,
    } = this.state;
    const {
      location, history, customField, userInfo, domain,
    } = this.props;

    return (
      <>
        <LoadingBanner loadingFlag={loadingAgentFlag}>
          <div className="position-relative">
            {errorNotAllowed ? (
              <div className="row">
                <div className="col-md-3">
                  <div className="error_block">{errorNotAllowed}</div>
                </div>
              </div>
            ) : (
              <>
                <h1 className="page_title d-inline-block mr-3">{agent.last_name} {agent.first_name} {agent.middle_name}</h1>
                {(activeTab === '2' || activeTab === '3' || activeTab === '7' || activeTab === '15' || activeTab === '16') ? (
                  <div className="page_header__control mb-2 mb-md-0 mr-2">
                    {generateXlsxTableFlag
                      ? <LoadingSpinner className="loading-circle mr-1 d-inline-block" type="spin" height={38} width={38} />
                      : (
                        <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
                          <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                          Скачать в Excel
                        </Button>
                      )}
                  </div>
                ) : null}
                {(activeTab === '2' || activeTab === '3' || activeTab === '7' || activeTab === '15' || activeTab === '16') ? (
                  <div className="page_header__control ml-1 mb-2 mb-md-0">
                    <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
                      <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                      Настроить таблицу
                    </Button>
                  </div>
                ) : null}
                <div>
                  <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); history.push('/agents'); }}>К списку пользователей</LinkBreadcrumbs>
                </div>
                {agent.id ? (
                  <div className="main-agent-container">
                    <div className="agent-badge mb-3">
                      <div className="agent-badge__box agent-badge__large" onClick={() => { this.toggle('1'); }}>
                        <div>
                          <div>
                            Пользователь №{agent.id} от {format(toLocalDate(parseISO(agent.created_at)), 'dd.MM.yyyy')}
                            <Tippy
                              placement="bottom-start"
                              interactive
                              trigger="click"
                              className="page-popup page-popup--agents-list"
                              arrow={false}
                              offset={[0, 12]}
                              content={agent.chainAgents
                                ? (
                                  <div className="page-popup__content">
                                    <button onClick={hideAll} className="btn btn--circle-btn page-popup__close" type="button">
                                      <svg width="30" height="30" aria-hidden="true">
                                        <Icon type="round-cross" />
                                      </svg>
                                    </button>
                                    <div className="popup-agents">
                                      <ul className="popup-agents__list">
                                        {agent.chainAgents.map((element, i, { length }) => (
                                          <li key={element.id} className={classnames('popup-agents__item', { 'is-current': i + 1 === length })}>
                                            <div className="popup-agents__icon">
                                              <svg width="14" height="14" aria-hidden="true">
                                                <Icon type="icon-agent" />
                                              </svg>
                                            </div>
                                            <div onClick={(e) => { e.preventDefault(); history.push(`/agent/${element.id}`); }} className="popup-agents__name-wrapper">
                                              <span className="popup-agents__name">{element.name}</span>
                                              {element.first_level ? <span className="popup-agents__status">Пользователь первого уровня</span> : null}
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                            >
                              <div className="d-inline-block ml-1">
                                <div className="is-select btn--circle-btn btn-circle-white">
                                  <svg width="12" height="12" aria-hidden="true">
                                    <Icon type="icon-agent-list" />
                                  </svg>
                                </div>
                              </div>
                            </Tippy>
                          </div>
                          <div>Последняя авторизация: {agent.last_activity ? format(toLocalDate(parseISO(agent.last_activity)), 'dd.MM.yyyy, HH:mm:ss') : '-'}</div>
                          {agent.status === 'ban' ? <div>Заблокирован: {agent.blocked_at ? format(toLocalDate(parseISO(agent.blocked_at)), 'dd.MM.yyyy, HH:mm:ss') : '-'}</div> : null}
                          {agent.status === 'ban' ? <div>Блокировщик: <a className="dotted_link" href="#" onClick={(e) => { e.preventDefault(); history.push(`/agent/${agent.requester_ban_id}`); }}>{agent.requesterBanAgent}</a></div> : null}
                          {agent.status === 'ban' ? <div>Причина блокировки: {agent.reason_ban}</div> : null}
                          {userInfo.full_time_employee && agent.first_level ? <div>Баланс: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(agent.balance)}</div> : null}
                          {userInfo.full_time_employee && agent.first_level ? <div>Бонусы: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(agent.bonus_sum)}</div> : null}
                        </div>
                      </div>
                      <div
                        className="agent-badge__box"
                        onClick={() => {
                          this.toggle('4');
                          scroller.scrollTo('tab_content', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: -140,
                          });
                        }}
                      >
                        <div className="agent-badge__box-title">Черновиков: {agent.policiesCountDrafts}</div>
                        <div className="is-select btn--circle-btn btn-circle-white agent-badge__box-icon">
                          <svg width="12" height="12" aria-hidden="true">
                            <PencilSquareIcon type="icon-agent-list" />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="agent-badge__box"
                        onClick={() => {
                          this.toggle('6');
                          scroller.scrollTo('tab_content', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: -140,
                          });
                        }}
                      >
                        <div className="agent-badge__box-title">Партнеров: {agent.partnersCount}</div>
                        <div className="is-select btn--circle-btn btn-circle-white agent-badge__box-icon">
                          <svg width="12" height="12" aria-hidden="true">
                            <UserGroupIcon type="icon-agent-list" />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="agent-badge__box"
                        onClick={() => {
                          this.toggle('2');
                          scroller.scrollTo('tab_content', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: -140,
                          });
                        }}
                      >
                        <div className="agent-badge__box-title">Полисов: {agent.policiesCount}</div>
                        <div className="is-select btn--circle-btn btn-circle-white agent-badge__box-icon">
                          <svg width="12" height="12" aria-hidden="true">
                            <DocumentDuplicateIcon type="icon-agent-list" />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="agent-badge__box"
                        onClick={() => {
                          this.toggle('10');
                          scroller.scrollTo('tab_content', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: -140,
                          });
                        }}
                      >
                        <div className="agent-badge__box-title">Виджетов: {agent.widgetsCount}</div>
                        <div className="is-select btn--circle-btn btn-circle-white agent-badge__box-icon">
                          <svg width="12" height="12" aria-hidden="true">
                            <CodeBracketSquareIcon type="icon-agent-list" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <TabNavList>
                  <TabNavItem
                    active={activeTab === '1'}
                    onClick={() => { this.toggle('1'); }}
                    text="Общая информация"
                  />
                  <TabNavItem
                    active={activeTab === '9'}
                    onClick={() => { this.toggle('9'); }}
                    text="Платежная информация"
                  />
                  <TabNavItem
                    active={activeTab === '2'}
                    onClick={() => { this.toggle('2'); }}
                    text="Продажи"
                  />
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '3'}
                      onClick={() => { this.toggle('3'); }}
                      text="Продажи(админ)"
                    />
                  ) : null}
                  <TabNavItem
                    active={activeTab === '4'}
                    onClick={() => { this.toggle('4'); }}
                    text="Черновики"
                  />
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '5'}
                      onClick={() => { this.toggle('5'); }}
                      text="Черновики(админ)"
                    />
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.allow_agent_network) ? (
                    <TabNavItem
                      active={activeTab === '6'}
                      onClick={() => { this.toggle('6'); }}
                      text="Сеть пользователей"
                    />
                  ) : null}
                  {userInfo.hide_commission === 0 ? (
                    <TabNavItem
                      active={activeTab === '7'}
                      onClick={() => { this.toggle('7'); }}
                      text="Настройки вознаграждения"
                    />
                  ) : null}
                  <TabNavItem
                    active={activeTab === '8'}
                    onClick={() => { this.toggle('8'); }}
                    text="Письма"
                  />
                  <TabNavItem
                    active={activeTab === '10'}
                    onClick={() => { this.toggle('10'); }}
                    text="Виджеты"
                  />
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '11'}
                      onClick={() => { this.toggle('11'); }}
                      text="Опрос"
                    />
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '12'}
                      onClick={() => { this.toggle('12'); }}
                      text="Настройки партнерской программы"
                    />
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '13'}
                      onClick={() => { this.toggle('13'); }}
                      text="Статус прохождения демо-туров"
                    />
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabNavItem
                      active={activeTab === '14'}
                      onClick={() => { this.toggle('14'); }}
                      text="Фильтр страховых"
                    />
                  ) : null}
                  {userInfo.full_time_employee === 1 ? (
                    <>
                      <TabNavItem
                        active={activeTab === '15'}
                        onClick={() => { this.toggle('15'); }}
                        text="Баланс и выплаты"
                      />
                      <TabNavItem
                        active={activeTab === '16'}
                        onClick={() => { this.toggle('16'); }}
                        text="Бонусы"
                      />
                    </>
                  ) : null}
                  {(userInfo.full_time_employee === 1 && (agent.first_level || agent.full_time_employee)) ? (
                    <TabNavItem
                      active={activeTab === '17'}
                      onClick={() => { this.toggle('17'); }}
                      text="Лицензионный договор"
                    />
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <>
                      <TabNavItem
                        active={activeTab === '18'}
                        onClick={() => { this.toggle('18'); }}
                        text="Данные в СК"
                      />
                      <TabNavItem
                        active={activeTab === '19'}
                        onClick={() => { this.toggle('19'); }}
                        text="Исполнительное производство"
                      />
                    </>
                  ) : null}
                </TabNavList>
                <TabContent name="tab_content" animation="false" activeTab={activeTab}>
                  <TabPane tabId="1">
                    { activeTab === '1' ? <AgentInfo history={history} updateAgent={this.updateAgent} agent={agent} ref={activeTab === '1' ? this.child : undefined} /> : null }
                  </TabPane>
                  <TabPane tabId="2">
                    { activeTab === '2' ? <PoliciesTable history={history} tab="2" setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} domain={domain} customField={customField} show="subagents_network" location={location} agentId={agent.id} ref={activeTab === '2' ? this.child : undefined} /> : null }
                  </TabPane>
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="3">
                      { activeTab === '3' ? <PoliciesTable history={history} tab="3" setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} domain={domain} customField={customField} show="all" agentId={agent.id} location={location} ref={activeTab === '3' ? this.child : undefined} /> : null }
                    </TabPane>
                  ) : null}
                  <TabPane tabId="4">
                    { activeTab === '4' ? <DraftsTable ref={activeTab === '4' ? this.child : undefined} tab="4" agentId={agent.id} updateData={activeTab} location={location} history={history} show="subagents" /> : null }
                  </TabPane>
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="5">
                      { activeTab === '5' ? <DraftsTable ref={activeTab === '4' ? this.child : undefined} tab="4" agentId={agent.id} updateData={activeTab} location={location} history={history} show="all" /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.allow_agent_network) ? (
                    <TabPane tabId="6">
                      { activeTab === '6' ? <AgentNetwork history={history} email={agent.email} ref={activeTab === '6' ? this.child : undefined} /> : null }
                    </TabPane>
                  ) : null}
                  {userInfo.hide_commission === 0 ? (
                    <TabPane tabId="7">
                      { activeTab === '7' ? <AgentCommission setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} agentId={agent.id} hideCommission={agent.hide_commission} curator={agent.curator} ref={activeTab === '7' ? this.child : undefined} /> : null }
                    </TabPane>
                  ) : null}
                  <TabPane tabId="8">
                    { activeTab === '8' ? <MailList email={agent.email} ref={activeTab === '8' ? this.child : undefined} /> : null }
                  </TabPane>
                  <TabPane tabId="9">
                    { activeTab === '9' ? <PaymentInfo agent={agent} location={location} updateAgent={this.downloadAgentInfo} ref={activeTab === '9' ? this.child : undefined} /> : null }
                  </TabPane>
                  <TabPane tabId="10">
                    { activeTab === '10' ? <WidgetsTable ref={activeTab === '10' ? this.child : undefined} tab="10" location={location} history={history} agentId={agent.id} /> : null }
                  </TabPane>
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="11">
                      { activeTab === '11' ? <AgentPollInfo ref={activeTab === '11' ? this.child : undefined} history={history} agent={agent} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="12">
                      { activeTab === '12' ? <AgentPartnerProgram ref={activeTab === '12' ? this.child : undefined} agent={agent} updateAgent={this.updateAgent} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="13">
                      { activeTab === '13' ? (
                        <>
                          <Button colorType="grayBordered" type="border" width="auto" onClick={this.openModalTableCustomized}>
                            <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                            Настроить таблицу
                          </Button>
                          <InterfaceTourAgentFlagsTable ref={activeTab === '13' ? this.child : undefined} type="agent" agentsFlagsList={agentsFlagsList} setAgentsFlagsList={(list) => { this.setState({ agentsFlagsList: list }); }} />
                        </>
                      ) : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="14">
                      { activeTab === '14' ? <AgentInsuranceFilter agent={agent} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="15">
                      { activeTab === '15' ? <BalanceTable location={location} tab="15" ref={activeTab === '15' ? this.child : undefined} history={history} show="all" agentId={agent.id} /> : null }
                    </TabPane>
                  ) : null}
                  {userInfo.full_time_employee ? (
                    <TabPane tabId="16">
                      { activeTab === '16' ? <BonusesHistoryTable agentId={agent.id} ref={activeTab === '16' ? this.child : undefined} history={history} tab={activeTab} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.full_time_employee && (agent.first_level || agent.full_time_employee)) ? (
                    <TabPane tabId="17">
                      { activeTab === '17' ? <CustomContracts agent={agent} updateAgent={this.updateAgent} ref={activeTab === '17' ? this.child : undefined} history={history} tab={activeTab} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="18">
                      { activeTab === '18' ? <AgentInsuranceIdList agent={agent} updateAgent={this.updateAgent} ref={activeTab === '18' ? this.child : undefined} history={history} tab={activeTab} /> : null }
                    </TabPane>
                  ) : null}
                  {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                    <TabPane tabId="19">
                      { activeTab === '19' ? <AgentEnforcementProceedings agent={agent} updateAgent={this.updateAgent} ref={activeTab === '19' ? this.child : undefined} history={history} tab={activeTab} /> : null }
                    </TabPane>
                  ) : null}
                </TabContent>
              </>
            )}
          </div>
        </LoadingBanner>
      </>
    );
  }
}

Agent.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(Agent);
