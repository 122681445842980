import React, {
  useMemo, useState, useEffect, useContext, useRef,
} from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/fontawesome-free-regular';
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
  faStar,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/fontawesome-free-solid';
import { Element } from 'react-scroll';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { shallow } from 'zustand/shallow';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import {
  customFetch,
  sortAllPolicesArray,
  handleChange,
  customSelectStyle,
} from '../../Utils/Helpers';
import CommercialOffer from '../CommercialOffer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import getMask from '../../Utils/Masks';
import IflOptionsForm from './IflOptionsForm';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function IflShortForm(props) {
  const {
    disableField,
    formData,
    setFormData,
    saveDraft,
    handleValidate,
    blurClass,
    companies,
    goToFullForm,
    draftInfo,
    goCalcFlag,
    setGoCalcFlag,
    setBlurClass,
    showForm,
    backToForm,
    setLoadingInit,
    loadingInit,
    selectedSort,
    setSelectedSort,
    policySortOrderUp,
    setPolicySortOrderUp,
    policyIntegrationsData,
    setPolicyIntegrationsData,
    allPolices,
    setAllPolices,
    showCommission,
    setShowCommission,
    commercialOfferCompanies,
    setCommercialOfferCompanies,
    setShowCommercialOfferModalForm,
    showCommercialOfferModalForm,
    commercialOfferRef,
    setLoadingCommercialOffer,
    setScrollTo,
  } = props;

  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  let abortControllerCalculate = new AbortController();
  let abortControllerCalculationStatus = new AbortController();

  const intervalId = useRef(null);
  const [loadingResults, setLoadingResults] = useState(false);
  const [gonnaCalc, setGonnaCalc] = useState(0);
  const [calced, setCalced] = useState(0);
  const [checkingCalc, setCheckingCalc] = useState(0);
  const customStyles = useMemo(() => customSelectStyle(), []);
  const pTypeOptions = [
    { value: 'flat', label: 'Страхование квартиры' },
    { value: 'house', label: 'Страхование дома' },
    { value: 'apartment', label: 'Страхование апартаментов' },
  ];
  const makeAllPolicesArray = (product) => {
    const allPolicesArray = [];
    policyIntegrationsData[product].forEach((policyData) => {
      if ((policyData.price || policyData.errorMessage) && Object.prototype.hasOwnProperty.call(companies, policyData.polis_online_code)) {
        allPolicesArray.push({
          policyData,
          name: policyData.name,
          insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type])
          || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type])
          || (companies[policyData.polis_online_code].presentation && companies[policyData.polis_online_code].presentation[draftInfo.type]) ? 'close' : 'no',
          integration: 'direct',
          rating: companies[policyData.polis_online_code].rating,
          price: policyData.price ? parseFloat(policyData.price) : 0,
          commission: policyData.agentCommission ? policyData.agentCommission : 0,
          commissionCurrency: parseFloat((policyData.agentCommission * parseFloat(policyData.price)) / 100),
          errorMessage: policyData.errorMessage ? policyData.errorMessage : '',
          integrationCompanyCode: policyData.polis_online_code,
          risks: policyData.risks,
          insuranceLimit: policyData.insuranceLimit,
        });
        if (policyData.upSales && policyData.upSales.length) {
          policyData.upSales.forEach((item) => {
            allPolicesArray.push({
              policyData,
              name: item.name,
              insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type])
              || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type])
              || (companies[policyData.polis_online_code].presentation && companies[policyData.polis_online_code].presentation[draftInfo.type]) ? 'close' : 'no',
              integration: 'direct',
              rating: companies[policyData.polis_online_code].rating,
              price: parseFloat(item.price),
              commission: item.commission,
              commissionCurrency: parseFloat((item.commission * item.price) / 100),
              integrationCompanyCode: policyData.polis_online_code,
              risks: item.risks,
              insuranceLimit: item.insuranceLimit,
            });
          });
        }
      }
      return false;
    });
    return allPolicesArray;
  };

  const changeSort = (e, newSort) => {
    e.preventDefault();
    if (selectedSort === newSort) {
      const newAllPolices = { ...allPolices };
      const direction = policySortOrderUp ? 'down' : 'up';
      const newPolices = sortAllPolicesArray(newAllPolices.ifl, selectedSort, direction, policySortOrderUp);
      setAllPolices({
        ifl: newPolices,
      });
      setPolicySortOrderUp((prev) => !prev);
    } else {
      const direction = newSort === 'price' ? 'up' : 'down';
      const newAllPolices = { ...allPolices };
      const newPolices = sortAllPolicesArray(newAllPolices.ifl, newSort, direction, policySortOrderUp);
      setAllPolices({
        ifl: newPolices,
      });
      setSelectedSort(newSort);
      setPolicySortOrderUp(newSort === 'price');
    }
  };

  const toggleCompanyCommercialOffer = (e, companyCode) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const insuranceIndex = commercialOfferCompanies.findIndex((code) => code === companyCode);
    const newCommercialOfferCompanies = [...commercialOfferCompanies];
    let type = 'set';
    if (insuranceIndex !== -1) {
      type = 'delete';
      newCommercialOfferCompanies.splice(insuranceIndex, 1);
    } else {
      newCommercialOfferCompanies.push(companyCode);
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/ifl/${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance: { companyCode, crossProductCode: null },
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const toggleAllCompaniesCommercialOffer = (e, allPolicyData) => {
    e.preventDefault();

    let type = 'set';
    let newCommercialOfferCompanies;
    if (commercialOfferCompanies.length) {
      type = 'delete';
      newCommercialOfferCompanies = [];
    } else {
      newCommercialOfferCompanies = allPolicyData.map((policy) => ({
        companyCode: policy.policyData.polis_online_code,
        crossProductCode: null,
      }));
    }

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/ifl/all-insurances-${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurances: newCommercialOfferCompanies,
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  useEffect(() => {
    abortControllerCalculationStatus.abort();
    abortControllerCalculationStatus = new AbortController();
    if (calced && checkingCalc) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      let intervalCount = 0;
      const checkInterval = setInterval(() => {
        if (intervalCount === 100 || !calced || !checkingCalc) {
          setLoadingResults(false);
          clearInterval(intervalId.current);
          clearInterval(checkInterval);
        } else {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation-status?draftHash=${draftInfo.hash}`, {
            signal: abortControllerCalculationStatus.signal,
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
          }).then((response) => response.json())
            .then((response) => {
              if (!response.error) {
                if (response.resultStatus === 'changed') {
                  const newPolicesData = { ...policyIntegrationsData };
                  Object.keys(newPolicesData).forEach((type) => {
                    if (response.result[type]) {
                      newPolicesData[type] = response.result[type];
                    }
                  });
                  setPolicyIntegrationsData(newPolicesData);
                }
                if (response.calcStatus === 'complete') {
                  setCheckingCalc(0);
                  setCalced(0);
                  const newPolicesData = { ...policyIntegrationsData };
                  Object.keys(newPolicesData).forEach((type) => {
                    if (response.result) {
                      newPolicesData[type] = response.result;
                    }
                  });
                  setPolicyIntegrationsData(newPolicesData);
                  setLoadingResults(false);
                  clearInterval(checkInterval);
                }
              }
            })
            .catch(() => {
              clearInterval(checkInterval);
              setCheckingCalc(0);
              setCalced(0);
              showModalInfo('Ошибка', 'error');
            });
          intervalCount += 5;
        }
      }, 5000);
      intervalId.current = checkInterval;
    }

    return function cleanup() {
      clearInterval(intervalId.current);
      abortControllerCalculationStatus.abort();
    };
  }, [calced, checkingCalc]);

  const checkCalc = () => {
    setCheckingCalc(1);
  };

  useEffect(() => () => {
    if (!blurClass) {
      setLoadingResults(false);
      clearInterval(intervalId.current);
      abortControllerCalculationStatus.abort();
      abortControllerCalculate.abort();
    }
  }, [blurClass]);

  useEffect(() => {
    abortControllerCalculate.abort();
    abortControllerCalculate = new AbortController();
    if (gonnaCalc) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      setScrollTo('searchResults');
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
        signal: abortControllerCalculate.signal,
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          calcType: 'short',
          draftHash: draftInfo.hash,
          useEvents: false,
        }),
      })
        .then(() => {
          setGonnaCalc(0);
          setCalced(1);
          checkCalc();
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
          setGonnaCalc(0);
        });
    }

    return function cleanup() {
      abortControllerCalculate.abort();
    };
  }, [gonnaCalc]);

  const calculate = () => {
    setCalced(0);
    setGonnaCalc(1);
  };

  const stopCalculation = () => {
    setLoadingResults(false);
  };

  const makeCalculation = () => {
    setBlurClass('');
    setLoadingInit(true);
    setLoadingResults(true);
    setAllPolices({
      ifl: [],
    });
    setPolicyIntegrationsData({
      ifl: [],
    });
    calculate();
  };

  const toggleInsuranceInfoFlag = (e, targetOffer) => {
    e.preventDefault();
    const newPolicyArr = [...allPolices[draftInfo.type]];
    const offerIndex = newPolicyArr.findIndex((offer) => offer.name === targetOffer.name);
    newPolicyArr[offerIndex].insuranceInfoFlag = newPolicyArr[offerIndex].insuranceInfoFlag === 'close' ? 'open' : 'close';
    setAllPolices((prev) => ({
      ...prev,
      [draftInfo.type]: newPolicyArr,
    }));
  };

  useEffect(() => {
    if (draftInfo.hash && goCalcFlag) {
      makeCalculation();
      setGoCalcFlag(false);
    }
  }, [draftInfo.hash, goCalcFlag]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults && policyIntegrationsData.ifl.length))) {
      let allPolicyDataArr = makeAllPolicesArray('ifl');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp);
      setAllPolices((prev) => ({
        ...prev,
        ifl: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults, policyIntegrationsData.ifl.length]);

  useEffect(() => {
    if (draftInfo.id && loadingInit) {
      setCommercialOfferCompanies([]);
      saveDraft(undefined);
    }
  }, [draftInfo.type]);

  const allPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return policesList.filter((offer) => offer.price && !offer.errorMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row', { info: offer.insuranceInfoFlag === 'open', 'has-info': offer.insuranceInfoFlag !== 'no' })}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>
                {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(offer.price)}
              </div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>
                  <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((offer.commission * offer.price) / 100)}</div>
                  <div className="search_results__commission--percentage">{offer.commission}%</div>
                </div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div><b>Вариант {offer.name}</b></div>
            {offer.risks ? (
              <div>
                {offer.risks.map((risk) => (
                  <>{risk.name}: {new Intl.NumberFormat('ru-RU', {
                    style: 'currency', currency: 'RUB', maximumFractionDigits: 0, minimumFractionDigits: 0,
                  }).format(risk.value)}<br />
                  </>
                ))}
              </div>
            ) : null}
            {offer.policyData.customContract ? <div className="main-color">Оформление через личный договор</div> : null}
          </div>
          <div className="search_results__table--cell">
            <div className="buttons">
              <button
                type="button"
                onClick={(e) => goToFullForm(e, offer.policyData.polis_online_code, offer.price, companies[offer.policyData.polis_online_code].name, offer.integration, offer.insuranceLimit, offer.risks)}
                className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
              >
                Оформить
              </button>
              {/* <button
                type="button"
                onClick={(e) => toggleCompanyCommercialOffer(e, offer.policyData.polis_online_code)}
                className={classnames('btn btn-secondary search_results__table--button btn-commercial-offer', { disabled: blurClass.length > 0, active: commercialOfferCompanies.findIndex((company) => company === offer.policyData.polis_online_code) !== -1 })}
              >
                {commercialOfferCompanies.findIndex((company) => company === offer.policyData.polis_online_code) !== -1 ? (<>-</>) : (<>+</>)}
              </button> */}
            </div>
            <div className="insurance-info-toggle" onClick={(e) => toggleInsuranceInfoFlag(e, offer)}>
              {offer.insuranceInfoFlag === 'open' ? (
                <div className="insurance-info-toggle__button">
                  <span>Скрыть подробности</span>
                  <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
                </div>
              ) : null}
              {(offer.insuranceInfoFlag === 'close') ? (
                <div className="insurance-info-toggle__button">
                  <span>Подробности о полисе</span>
                  <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {offer.insuranceInfoFlag !== 'no' ? (
          <div className={classnames('insurance-info')}>
            {companies[offer.policyData.polis_online_code].policy_template && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].policy_template[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Пример полиса</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].rules && companies[offer.policyData.polis_online_code].rules[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].rules[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Правила страхования</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].presentation && companies[offer.policyData.polis_online_code].presentation[draftInfo.type] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].presentation[draftInfo.type]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Презентация</span>
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    ));
  }, [
    allPolices.ifl,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
    commercialOfferCompanies.length]);

  const errorPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    return allPolices[draftInfo.type].filter((offer) => !offer.price && offer.errorMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row')}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>-</div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>-</div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div>
              {offer.price ? 'Договор успешно согласован!' : <span className="text-danger">{offer.errorMessage}</span>}
            </div>
          </div>
        </div>
      </div>
    ));
  }, [
    loadingInit,
    allPolices.ifl,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
  ]);

  const otherCompaniesBlock = useMemo(() => {
    if (Object.keys(companies).length === 0 || !loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return Object.entries(companies).filter(([companyName, companyData]) => policesList.findIndex((company) => company.policyData.polis_online_code === companyName) === -1 && !companyData.hide).map(([companyName, value]) => (
      <div key={companyName} className="search_results__table--row">
        <div className="search_results__table--cell">
          <span className="color-light label">Страховая:</span>
          <div>
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${value.logo}`} alt="logo" className="search_results__logo" />
          </div>
          <div className="search_results__company-name">
            {value.name}
          </div>
        </div>
        {(loadingResults)
          ? <div className="search_results__table--cell"><LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} /></div>
          : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>-</div>
              </div>
              <div className="search_results__table--cell">
                { showCommission && userInfo.hide_commission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>-</div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[companyName].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                    </div>
                  </>
                )}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div>
                  <span className="text-danger">Отказ</span>
                </div>
              </div>
            </>
          )}
      </div>
    ));
  }, [
    loadingInit,
    allPolices.ifl,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission]);

  return (
    <>
      <form name="IflShortForm" id="Ifl_short_form" onSubmit={(e) => saveDraft(e, true)}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="pType">Тип объекта</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  onBlur={handleValidate}
                  isDisabled={disableField}
                  name="pType"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="pType"
                  isSearchable={false}
                  value={formData.pType.value.value ? formData.pType.value : null}
                  onChange={(e) => handleChange(e, 'pType', formData, setFormData)}
                  options={pTypeOptions}
                />
              </div>
              <div className="col-lg-2">
                <label htmlFor="buildYear">Год постройки</label>
                <InputMask
                  inputMode="decimal"
                  maskChar=""
                  onBlur={handleValidate}
                  onChange={(e) => handleChange(e, 'buildYear', formData, setFormData)}
                  id="buildYear"
                  placeholder="2014"
                  name="buildYear"
                  className={classnames('form-control', { error: formData.buildYear.errorMessage })}
                  value={formData.buildYear.value || ''}
                  mask={getMask('year').mask}
                  formatChars={getMask('year').formatChars}
                  disabled={disableField}
                />
                {formData.buildYear.errorMessage && (
                  <div className="validation-error">
                    {formData.buildYear.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <IflOptionsForm
          formData={formData}
          setFormData={setFormData}
        />
        <div className="row row-grid">
          <div className="col-lg-12">
            {showForm === 'ifl' ? (
              <button disabled={disableField} type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short')}>Изменить данные</button>
            ) : (
              <button disabled={disableField} type="submit" className="btn btn-success">Показать цены</button>
            )}
          </div>
          {(loadingResults) && (
            <div className="col-lg-6">
              <LoadingSpinner className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
              <span>Идет подбор предложений..</span>
            </div>
          )}
        </div>
      </form>
      {showForm === 'short' ? (
        <>
          {(loadingResults) && (
            <ProgressBar stopCalculation={stopCalculation} />
          )}
          {!loadingResults && (Object.prototype.hasOwnProperty.call(allPolices, draftInfo.type) && !allPolices[draftInfo.type].length) && loadingInit && !blurClass ? (
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="error_block">Повторите попытку позже</div>
              </div>
            </div>
          ) : null}
          {loadingInit && (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults"><div className="search_results__title h1">Результаты поиска</div></Element>
                {userInfo.hide_commission === 0 && (
                  <div className="form-inline mt-2 mb-4">
                    <span className="switch-text">Режим работы с клиентом</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span onClick={() => setShowCommission((prev) => !prev)} className="slider round" />
                    </label>
                  </div>
                )}
                <div className="search_results__table">
                  {/* {allPolices[draftInfo.type].length ? (
                    <button
                      onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type])}
                      type="button"
                      className="btn btn-secondary btn-commercial-offer-add-all show-mobile"
                    >
                      {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                    </button>
                  ) : null} */}
                  <div className="search_results__table--head">
                    <div className="search_results__table--row">
                      <div className="search_results__table--cell">Страховая компания</div>
                      <div className="search_results__table--cell">
                        <a className={classnames({ dotted_link: selectedSort === 'price' })} href="" onClick={(e) => { changeSort(e, 'price'); }}>
                          Стоимость
                          {policySortOrderUp && selectedSort === 'price' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                            : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                        </a>
                      </div>
                      <div className="search_results__table--cell">
                        {showCommission && userInfo.hide_commission === 0 ? (
                          <a className={classnames({ dotted_link: selectedSort === 'commissionCurrency' })} href="" onClick={(e) => { changeSort(e, 'commissionCurrency'); }}>
                            Вознаграждение
                            {policySortOrderUp && selectedSort === 'commissionCurrency' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        ) : (
                          <a className={classnames({ dotted_link: selectedSort === 'rating' })} href="" onClick={(e) => { changeSort(e, 'rating'); }}>
                            Рейтинг
                            {policySortOrderUp && selectedSort === 'rating' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        )}
                      </div>
                      <div className="search_results__table--cell">Комментарий</div>
                      {/* <div className="search_results__table--cell">
                        {allPolices[draftInfo.type].length ? (
                          <button
                            onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type])}
                            type="button"
                            className="btn btn-secondary btn-commercial-offer-add-all"
                          >
                            {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                          </button>
                        ) : null}
                      </div> */}
                    </div>
                  </div>
                  <div className="search_results__table--body">
                    {loadingInit ? (
                      <>
                        {allPolicyBlock}
                        {errorPolicyBlock}
                        {otherCompaniesBlock}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {blurClass && (
                <div className="blur_message">
                  Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «рассчитать»
                </div>
              )}
            </div>
          )}
        </>
      ) : null}
      <CommercialOffer
        policySortOrderUp={policySortOrderUp}
        selectedSort={selectedSort}
        setLoadingCommercialOffer={setLoadingCommercialOffer}
        commercialOfferCompanies={commercialOfferCompanies}
        policyId={draftInfo.id}
        policyIdHash={draftInfo.hash}
        product="ifl"
        productName="ИФЛ"
        setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
        showCommercialOfferModalForm={showCommercialOfferModalForm}
        ref={commercialOfferRef}
      />
    </>
  );
}
