import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { NavLink } from 'react-router-dom';
import CustomIcon from '../CustomIcon';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

const GlobalStyle = createGlobalStyle`
  #sidebar .sidebar-content{
    padding: 0;
  }
`;

const Container = styled.li`
  margin: ${(props) => (props.separator ? '48px 0 0' : '0')};
  cursor: pointer;

  a{
    position: relative;
    padding: 12px 16px;
    display: flex;
    color: #737373;
    background-color: #fff;
    transition: background-color .15s ease, color .15s ease;

    &:before {
      position: absolute;
      top: 0;
      content: "";
      height: 100%;
      left: 0;
      width: 4px;
      background-color: ${(props) => (props.theme && props.theme.mainColor ? props.theme.subColor : '#f0f000')};
      opacity: 0;
      transition: opacity .15s ease;
    }

    &.active:before {
      opacity: 1;
    }

    &:hover{
      background-color: #f6f6f6;
    }

    &.active {
      color: #3d3d3b;
      background-color: #efefef;
    }
  }
`;

const Text = styled.span`
  padding: 2px 0 0;
  transition: opacity .15s ease, visibility .15s ease;
  white-space: nowrap;
`;

const Icon = styled.div`
  margin: 0 16px 0 0;
  width: 24px;
  height: 24px;
  color: ${(props) => (props.$iconColor ? props.iconColor : '#a3a3a3')};

  li a.active & {
    color: ${(props) => (props.$iconColor ? props.iconColor : '#3d3d3b')};
  } 

  svg {
    width: 24px;
    height: 24px;
  }
`;

export default function SidebarItem(props) {
  const {
    text, icon, separator, sidebarActive, to, customClassName, toggleSidebar, sidebarIsFull, onClick, iconColor,
  } = props;

  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    toggleSidebar(false);
  };

  return (
    <>
      <GlobalStyle />
      <Container separator={separator} className={`${customClassName || ''} sidebar-item`} theme={theme} onClick={handleClick}>
        <NavLink to={to || ''} activeClassName="active">
          {icon ? (
            <Icon $iconColor={iconColor}>
              <CustomIcon icon={icon} />
            </Icon>
          ) : null}
          {sidebarActive || sidebarIsFull ? (<Text>{text}</Text>) : null}
        </NavLink>
      </Container>
    </>
  );
}
