import React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { parseISO, subYears } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import getMask from '../../Utils/Masks';

export default function InsuredPersons(props) {
  const {
    disableFields,
    insuredPersons,
    addInsured,
    removeInsured,
    copyInsurer,
    handleChangeDate,
    handleValidate,
    handleChangeInput,
  } = props;
  const insuredList = insuredPersons.map((insuredPerson, index) => (
    <div key={index}>
      <div className="form-group row">
        <div className="col-lg-12">
          <span className="d-inline-block search_filter__section_subtitle">Застрахованный №{index + 1}</span>
          {!disableFields && (index !== 0) && (<a onClick={removeInsured} className="close-driver">×</a>)}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12 column-margin">
          <button disabled={disableFields} type="button" className="btn btn-success mr-2 column-margin" onClick={() => copyInsurer(index)}>Скопировать данные страхователя</button>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="lastNameInsured">Фамилия</label>
          <InputMask
            maskChar=""
            onBlur={(e) => handleValidate(e, index)}
            disabled={disableFields}
            onChange={(e) => handleChangeInput(e, index, true)}
            name="lastNameInsured"
            placeholder="Иванов"
            className={classnames('form-control', { error: insuredPerson.lastNameInsured.errorVisible })}
            mask={getMask('name').mask}
            formatChars={getMask('name').formatChars}
            value={insuredPerson.lastNameInsured.value}
          />
          {insuredPerson.lastNameInsured.errorVisible && (
            <div className="validation-error">
              {insuredPerson.lastNameInsured.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <label htmlFor="firstNameInsured">Имя</label>
          <InputMask
            maskChar=""
            onBlur={(e) => handleValidate(e, index)}
            disabled={disableFields}
            onChange={(e) => handleChangeInput(e, index, true)}
            name="firstNameInsured"
            placeholder="Иван"
            className={classnames('form-control', { error: insuredPerson.firstNameInsured.errorVisible })}
            mask={getMask('name').mask}
            formatChars={getMask('name').formatChars}
            value={insuredPerson.firstNameInsured.value}
          />
          {insuredPerson.firstNameInsured.errorVisible && (
            <div className="validation-error">
              {insuredPerson.firstNameInsured.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <label htmlFor="middleNameInsured">Отчество</label>
          <InputMask
            maskChar=""
            onBlur={(e) => handleValidate(e, index)}
            disabled={disableFields}
            name="middleNameInsured"
            onChange={(e) => handleChangeInput(e, index, true)}
            placeholder="Иванович"
            className={classnames('form-control', { error: insuredPerson.middleNameInsured.errorVisible })}
            mask={getMask('name').mask}
            formatChars={getMask('name').formatChars}
            value={insuredPerson.middleNameInsured.value}
          />
          {insuredPerson.middleNameInsured.errorVisible && (
            <div className="validation-error">
              {insuredPerson.middleNameInsured.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <label htmlFor="insuredBirthday">Дата рождения</label>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            disabled={disableFields}
            onBlur={(e) => handleValidate(e, index)}
            className={classnames('form-control', { error: insuredPerson.insuredBirthday.errorVisible })}
            selected={insuredPerson.insuredBirthday.value ? parseISO(insuredPerson.insuredBirthday.value) : null}
            onChange={(date) => handleChangeDate(date, 'insuredBirthday', index)}
            name="insuredBirthday"
            locale={ru}
            maxDate={subYears(new Date(), 7)}
            minDate={subYears(new Date(), 70)}
            showYearDropdown
            showMonthDropdown
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
          {insuredPerson.insuredBirthday.errorVisible && (
            <div className="validation-error">
              {insuredPerson.insuredBirthday.errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  ));
  return (
    <>
      {insuredList}
      <div className="row">
        <div className="col-lg-3">
          <button disabled={disableFields || insuredList.length >= 4} onClick={addInsured} type="button" className="btn btn-border btn-success">+ Добавить застрахованного</button>
        </div>
      </div>
    </>
  );
}
