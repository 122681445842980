import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ru from 'date-fns/locale/ru';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid';
import ReactExport from 'react-data-export';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-regular';
import {
  ModalMessagesContext,
} from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
  getCustomizedColumns,
  getDefaultSorting7, onContextMenuClick,
  onFilteredChangeCustom, onTableCellClick,
  setTableCustomized,
  setTableSettings7,
  setTableSorting,
} from '../../Utils/Helpers';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import Table from '../../Layout/Table';
import Button from '../../Layout/Button';
import CustomIcon from '../../Layout/CustomIcon';

const tableRef = createRef();
export default function PaymentRealizationsDetail(props) {
  const { match, history } = props;

  const simulateClick = new MultiRef();
  const excelFile = useRef(null);
  const customizeTableRef = useRef(null);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;
  const customStyles = useMemo(() => customSelectStyle(), []);
  const [balance, setBalance] = useState(0.00);
  const agentTypesOptions = [
    { label: 'Физ. лицо', value: 'natural' },
    { label: 'Физ. лицо (самозанятый)', value: 'self_employed' },
    { label: 'Индивидуальный предприниматель', value: 'individual' },
    { label: 'Юр. лицо', value: 'legal' },
  ];
  const profileStatusesOptions = [
    { label: 'Подтвержден', value: 'yes' },
    { label: 'Не подтвержден', value: 'no' },
    { label: 'На проверке', value: 'under_review' },
    { label: 'Не требуется', value: 'no_need' },
    { label: 'Требуется уточнение', value: 'need_update' },
  ];
  const [selectAll, setSelectAll] = useState(0);
  const [selectFiltered, setSelectedFiltered] = useState(0);
  const [employee, setEmployee] = useState({});
  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentRealizationStatus, setPaymentRealizationStatus] = useState('pending');
  const [flagSure, setFlagSure] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [filtered, setFiltered] = useState([]);
  const defaultSorting = getDefaultSorting7('paymentRealizationDetailSettings');
  const [excelColumns, setExcelColumns] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [goDownloadFile, setGoDownloadFile] = useState(false);
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);
  const [agents, setAgents] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingPaymentRealizationFlag, setLoadingPaymentRealizationFlag] = useState(false);
  const [loadingPaymentsFlag, setLoadingPaymentsFlag] = useState(false);
  const [startDate, setStartDate] = useState(startOfMonth(subMonths(new Date(), 1)));
  const [endDate, setEndDate] = useState(endOfMonth(subMonths(new Date(), 1)));
  const [agentType, setAgentType] = useState(agentTypesOptions[0]);
  const [profileStatus, setProfileStatus] = useState([profileStatusesOptions[0]]);
  const [paymentSum, setPaymentSum] = useState(0);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const filterByReference = (arr1, arr2) => arr1.filter(
    (el) => Object.keys(arr2).find((element) => parseInt(element, 10) === el.id),
  );

  const createPayments = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingPaymentsFlag(true);
    setFlagSure(false);
    setPaymentRealizationStatus('processing');
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/paymentRealizations/${match.params.id}/payments/create`, {
      method: 'POST',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        agents: filterByReference(agents, selectedColumns),
        paymentRealization: match.params.id,
        startDate,
        endDate,
        agentType: agentType ? agentType.value : false,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setPaymentRealizationStatus(response.status);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingPaymentsFlag(false);
      });
  };

  const getPaymentRealization = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingPaymentRealizationFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/paymentRealizations/${match.params.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (response.status !== 'pending') {
            const uniqueArrayTypes = [...new Set(response.paymentsList.map((agent) => agent.agent_type_code))];
            const agentTypes = agentTypesOptions.filter(({ value }) => uniqueArrayTypes.includes(value));
            const uniqueArrayStatuses = [...new Set(response.paymentsList.map((agent) => agent.confirmed))];
            const profileStatuses = profileStatusesOptions.filter(({ value }) => uniqueArrayStatuses.includes(value));
            setAgentType(agentTypes);
            setProfileStatus(profileStatuses);
            setEmployee(response.agent);
            setPaymentDate(response.payment_date);
            setPaymentRealizationStatus(response.status);
            setAgents(response.paymentsList);
            setStartDate(parseISO(response.start_period));
            setEndDate(parseISO(response.end_period));
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingPaymentRealizationFlag(false);
      });
  };

  const getList = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingPaymentsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/paymentRealizations/payments`, {
      method: 'POST',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate,
        endDate,
        agentType: agentType ? agentType.value : false,
        profileStatus: profileStatus ? profileStatus.map((status) => status.value) : [],
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setAgents(response);
          const newObj = {};
          response.forEach((payment) => {
            newObj[payment.id] = true;
          });
          setSelectedColumns(newObj);
          setSelectedFiltered(1);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      })
      .finally(() => {
        setLoadingPaymentsFlag(false);
      });
  };

  const toggleRow = (id) => {
    const newSelected = Object.assign({}, selectedColumns);
    if (selectedColumns[id] === true) {
      delete newSelected[id];
    } else {
      newSelected[id] = !selectedColumns[id];
    }
    setSelectedColumns(newSelected);

    let newSelectFiltered = 2;
    let newSelectAll = 0;
    if (Object.entries(newSelected).length === 0) {
      newSelectFiltered = 0;
    }
    if (Object.entries(newSelected).length === agents.length) {
      newSelectFiltered = 1;
      newSelectAll = 1;
    }
    setSelectedFiltered(newSelectFiltered);
    setSelectAll(newSelectAll);
  };

  const downloadBalance = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/alpha_bank/balance`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setBalance(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки баланса');
      })
      .finally(() => {
      });
  };

  const downloadReport = () => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    const { filteredData } = tableRef.current.getTableData();
    const dataToDownload = [];
    for (let index = 0; index < filteredData.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (filteredData[index].values[columns[colIndex].accessor]) {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].accessor];
          } else {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].id];
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.filter((column) => column.show !== false && column.id !== 'select_cell').map((column) => <ExcelColumn label={column.Header} value={column.Header} />);
    setExcelColumns([...excelcolumns]);
    setDataSet([...dataToDownload]);
    setGoDownloadFile(true);
  };

  const openModalTableCustomized = () => {
    setFlagModalTableCustomized(true);
  };

  const handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    setTableCustomized(e.target.elements, 'paymentRealizationDetailSettings');
    setFlagModalTableCustomized(false);
  };

  const handleTableCustomizedSetAll = () => {
    simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  const handleTableCustomizedSetNone = () => {
    simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      disableFilters: true,
      show: true,
      disableSortBy: true,
      id: 'select_cell',
      Cell: (props) => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectedColumns[props.row.original.id] === true}
          onChange={() => toggleRow(props.row.original.id)}
        />
      ),
      Header: () => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectFiltered === 1}
          ref={(input) => {
            if (input) {
              input.indeterminate = selectFiltered === 2;
            }
          }}
          onChange={() => toggleSelectFiltered()}
        />
      ),
      width: 40,
      showCustomized: false,
    }, {
      Header: 'id',
      accessor: 'id',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').id,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'ФИО',
      id: 'name',
      accessor: (d) => `${d.last_name} ${d.first_name} ${d.middle_name}`,
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').name,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_name !== false,
      showCustomized: true,
    }, {
      Header: 'Юр.статус',
      id: 'agent_type',
      accessor: (d) => agentTypesOptions.find((type) => type.value === d.agent_type_code).label,
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').agent_type,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_agent_type !== false,
      showCustomized: true,
    }, {
      Header: 'Юр. лицо',
      accessor: 'organization_name',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').organization_name,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_organization_name !== false,
      showCustomized: true,
    }, {
      Header: 'Куратор',
      id: 'curator',
      accessor: (d) => `${d.curator_obj.last_name} ${d.curator_obj.first_name} ${d.curator_obj.middle_name}`,
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').curator,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_curator !== false,
      showCustomized: true,
    }, {
      Header: 'Статус профиля',
      id: 'confirmed',
      accessor: (d) => profileStatusesOptions.find((status) => status.value === d.confirmed).label,
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').confirmed,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_confirmed !== false,
      showCustomized: true,
      Footer: 'ИТОГО',
    }, {
      Header: 'Баланс',
      accessor: 'balance',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').balance,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_balance !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.balance) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Сумма вознаграждения (начислено)',
      id: 'profit_sum',
      accessor: (d) => (d.profit_sum ? d.profit_sum.toFixed(2) : 0.00),
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').profit_sum,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_profit_sum !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.profit_sum) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Корректировки вознаграждения',
      id: 'profit_sum_changed',
      accessor: (d) => (d.profit_sum_changed ? d.profit_sum_changed.toFixed(2) : 0.00),
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').profit_sum_changed,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_profit_sum_changed !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.profit_sum_changed) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Сконв. бонусы',
      id: 'bonuses_converted',
      accessor: (d) => (d.bonuses_converted ? parseFloat(d.bonuses_converted, 10).toFixed(2) : 0.00),
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').bonuses_converted,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_bonuses_converted !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.bonuses_converted) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Оплата услуг',
      id: 'service_payments',
      accessor: (d) => (d.service_payments ? parseFloat(d.service_payments, 10).toFixed(2) : 0.00),
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').service_payments,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_service_payments !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.service_payments) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Итого начислено',
      accessor: 'total_commission',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').total_commission,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_total_commission !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.total_commission.replace(',', '.')) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'НДФЛ (Удержано)',
      accessor: 'ndfl',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').ndfl,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_ndfl !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseInt(row.values.ndfl, 10) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(0)}</>;
      },
    }, {
      Header: 'Сумма удержания по ИП',
      accessor: 'enforcement_proceeding_amount',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').enforcement_proceeding_amount,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_enforcement_proceeding_amount !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseInt(row.values.enforcement_proceeding_amount, 10) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(0)}</>;
      },
    }, {
      Header: 'К выплате',
      accessor: 'commission_minus_ndfl',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').commission_minus_ndfl,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_commission_minus_ndfl !== false,
      showCustomized: true,
      Footer: (d) => {
        const total = React.useMemo(
          () => d.rows.reduce((sum, row) => parseFloat(row.values.commission_minus_ndfl.replace(',', '.')) + sum, 0),
          [d.rows],
        );
        return <>{total.toFixed(2)}</>;
      },
    }, {
      Header: 'Статус выплаты',
      accessor: 'status',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').status,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_status !== false,
      showCustomized: true,
      Cell: ({ row }) => {
        if (row.original.status === 'paid') {
          return (<><FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Выплачено</>);
        } if (row.original.status === 'canceled') {
          return (<><FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Оплата не прошла: ({row.original.error_message})</>);
        } if (row.original.status === 'pending') {
          return (<><FontAwesomeIcon color="#fd7e14" icon={faExclamationTriangle} className="font-awesome-custom-icon fa-fw fa-lg" />В процессе</>);
        }
        return 'Без статуса';
      },
    }, {
      Header: 'ID выплаты',
      accessor: 'paymentId',
      width: reactLocalStorage.getObject('paymentRealizationDetailSettings').paymentId,
      show: reactLocalStorage.getObject('paymentRealizationDetailSettings').show_paymentId !== false,
      showCustomized: true,
    },
    ];
    setColumns(updatedColumns);
  };

  const toggleSelectFiltered = () => {
    const newSelected = {};
    if (selectFiltered === 0) {
      const { filteredData } = tableRef.current.getTableData();
      for (let i = 0; i < filteredData.length; i += 1) {
        if (i >= filteredData.length) break;
        if (agents.find((x) => filteredData[i].original.id === x.id)) {
          newSelected[filteredData[i].original.id] = true;
        }
      }
    }
    setSelectedFiltered(selectFiltered === 0 ? 1 : 0);
    setSelectedColumns(newSelected);
    setSelectAll((agents.length === Object.keys(newSelected).length && selectFiltered === 0) ? 1 : 0);
    updateTableColumns();
  };

  useEffect(() => {
    let newSum = 0;
    filterByReference(agents, selectedColumns).forEach((agent) => {
      newSum += parseFloat(agent.total_commission.replace(',', '.'));
    });
    setPaymentSum(newSum);
  }, [selectedColumns]);

  useEffect(() => {
    if (goDownloadFile) {
      excelFile.current.handleDownload();
      setGoDownloadFile(false);
    }
  }, [goDownloadFile]);

  useEffect(() => {
    updateTableColumns();
  }, [selectedColumns]);

  useEffect(() => {
    updateTableColumns();
  }, [selectFiltered, selectAll]);

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    if (paymentRealizationStatus === 'pending') {
      setAgents([]);
    }
  }, [startDate, endDate, agentType, profileStatus]);

  useEffect(() => {
    downloadBalance();
    updateTableColumns();
    getPaymentRealization();
  }, []);

  return (
    <>
      <LoadingBanner loadingFlag={loadingPaymentRealizationFlag}>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={flagSure}
          onClose={() => { setFlagSure(false); }}
          center
        >
          <h4>Вы уверены, что хотите выплатить вознаграждение?</h4>
          <button className="btn btn-success mr-2" type="button" onClick={(e) => createPayments(e)}>Да</button>
          <button type="button" className="btn btn-secondary" onClick={() => { setFlagSure(false); }}>Нет</button>
        </Modal>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={flagModalTableCustomized}
          onClose={() => { setFlagModalTableCustomized(false); }}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={customizeTableRef} onSubmit={handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {getCustomizedColumns(columns, simulateClick)}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <h1 className="page_title d-inline-block mr-3">Выплата вознаграждения пользователям</h1>
        {agents.length ? (
          <>
            <div className="page_header__control mb-2 mb-md-0 mr-2">
              <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
                <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                Настроить таблицу
              </Button>
            </div>
            <div className="page_header__control mb-2 mb-md-0">
              <Button colorType="grayBordered" type="border" width="auto" onClick={downloadReport}>
                <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                Скачать в Excel
              </Button>
            </div>
          </>
        ) : null}
        {paymentRealizationStatus !== 'pending' ? (
          <div className="policy_info">
            <div style={{ marginBottom: 0 }}>Реализация №{match.params.id} от {paymentDate}</div>
            <div>Ответственный: <a className="dotted_link" href={`/agent/${employee.id}`} onClick={(e) => { e.preventDefault(); history.push(`/agent/${employee.id}`); }}>{employee.last_name} {employee.first_name} {employee.middle_name}</a></div>
          </div>
        ) : null}
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <span>Отчетный период с</span>
            <DatePicker
              disabled={paymentRealizationStatus !== 'pending'}
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              onChange={(date) => setStartDate(startOfDay(date))}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              disabled={paymentRealizationStatus !== 'pending'}
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => setEndDate(endOfDay(date))}
              minDate={startDate}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="mb-2">
            <span>Юр.статус</span>
            <Select
              isDisabled={paymentRealizationStatus !== 'pending'}
              classNamePrefix="react-select"
              styles={customStyles}
              value={agentType}
              noOptionsMessage={() => 'Не найдено'}
              placeholder="Юр.статус"
              onChange={(value) => setAgentType(value)}
              className="form-control-custom d-inline-block col-md-3"
              options={agentTypesOptions}
            />
          </div>
          <div className="mb-2">
            <span>Статус профиля</span>
            <Select
              isDisabled={paymentRealizationStatus !== 'pending'}
              classNamePrefix="react-select"
              styles={customStyles}
              isMulti
              value={profileStatus}
              noOptionsMessage={() => 'Не найдено'}
              placeholder="Статус профиля"
              onChange={(value) => { setProfileStatus(value); }}
              className="form-control-custom d-inline-block ml-1"
              options={profileStatusesOptions}
            />
          </div>
        </div>
        <div className="mb-2">Баланс: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(balance)}</div>
        <div className="mb-2">К выплате по выбранным: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(paymentSum)}</div>
        <div>
          <button disabled={paymentRealizationStatus !== 'pending'} type="button" className="btn btn-success mb-3" onClick={getList}>Сформировать список</button>
        </div>
        <div>
          <button disabled={paymentRealizationStatus !== 'pending'} type="button" className="btn btn-success mb-3" onClick={() => setFlagSure(true)}>Оплатить</button>
        </div>
        <div className="d-none">
          <ExcelFile ref={excelFile} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="PaymentRealizationDetail">
              {excelColumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <Table
          showFooter
          ref={tableRef}
          defaultSorted={defaultSorting}
          onContextMenuClick={(e, row) => onContextMenuClick(e, 'agent', row.original.id)}
          onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'agent', history)}
          defaultFiltered={filtered}
          data={agents}
          columns={columns}
          loading={loadingPaymentsFlag}
          noDataText="Пользователи не найдены"
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'paymentRealizationDetailSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings7(newResized, 'paymentRealizationDetailSettings');
          }}
          onFilteredChange={(filtered) => setFiltered(onFilteredChangeCustom(filtered))}
        />
      </LoadingBanner>
    </>
  );
}
