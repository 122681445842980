import React, { Component } from 'react';
import { getYear } from 'date-fns';
import { YMInitializer } from 'react-yandex-metrika';
import styled from 'styled-components';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const FooterContainer = styled.footer`
  background-color: #f6f6f6;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { theme } = this.props;
    if (theme.bitrix_widget_active) {
      const bitrixCode = theme.bitrix_widget_custom_code ? theme.bitrix_widget_custom_code : 'https://crm.infullbroker.ru/upload/crm/site_button/loader_6_yec22f.js';
      this.bitrix(window, document, bitrixCode);
    }
  }

  bitrix = (w, d, u) => {
    const s = d.createElement('script');
    s.async = 1;
    s.src = `${u}?${Date.now() / 60000 | 0}`;
    const h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  };

  render() {
    const { history, theme } = this.props;
    return (
      (history.location.pathname !== '/finances') ? (
        <FooterContainer className="footer-ui footer--white-mobile">
          <YMInitializer
            accounts={[50379067]}
            options={{
              webvisor: theme.domain !== 'localhost:3000',
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
            }}
            version="2"
          />
          <div className="footer-ui__wrapper container">
            <p className="footer-ui__copyright"><span>© 2019 - {getYear(new Date())}</span> {theme.title}</p>
            {(theme.domain === 'agents' || theme.link_to_telegram) ? (
              <ul className="footer-ui__nav">
                {theme.domain === 'agents' ? (
                  <>
                    <li className="footer-ui__item">
                      <a className="link-with-underline link-with-underline--monochrom" href="https://b2b.polis.online/company/">
                        <span>О нас</span>
                      </a>
                    </li>
                    <li className="footer-ui__item">
                      <a className="link-with-underline link-with-underline--monochrom" target="_blank" rel="nofollow noopener noreferrer" href="https://vk.com/realpolisonline">
                        <span>Вконтакте</span>
                      </a>
                    </li>
                    <li className="footer-ui__item">
                      <a className="link-with-underline link-with-underline--monochrom" target="_blank" rel="nofollow noopener noreferrer" href="https://www.facebook.com/realpolisonline/">
                        <span>Facebook</span>
                      </a>
                    </li>
                    <li className="footer-ui__item">
                      <a className="link-with-underline link-with-underline--monochrom" target="_blank" rel="nofollow noopener noreferrer" href="https://www.instagram.com/polis.on.line">
                        <span>Instagram</span>
                      </a>
                    </li>
                  </>
                ) : null}
                <li className="footer-ui__item">
                  <a className="link-with-underline link-with-underline--monochrom" target="_blank" rel="nofollow noopener noreferrer" href={theme.link_to_telegram}>
                    <span>Telegram</span>
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        </FooterContainer>
      ) : null
    );
  }
}

export default withUserInfoStoreStore(Footer);
