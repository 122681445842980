import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import LoadingSpinner from "../Layout/LoadingSpinner";
import matchSorter from 'match-sorter';
import classnames from 'classnames';
import InnerList from './InnerList';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

export default function Aliases() {
  const { showModalInfo } = useContext(ModalMessagesContext);
  const integrations = [
    {
      name: 'РГС',
      code: 'rgs',
    },
    {
      name: 'Сбер',
      code: 'sber',
    },
    {
      name: 'Макс',
      code: 'maks',
    },
    {
      name: 'Югория',
      code: 'ugoria',
    },
    {
      name: 'Энергогарант',
      code: 'energogarant',
    },
    {
      name: 'Согаз',
      code: 'sogaz',
    },
    {
      name: 'Тинькофф',
      code: 'tinkoff',
    },
    {
      name: 'Ингосстрах',
      code: 'ingoss',
    },
    {
      name: 'Гелиос',
      code: 'gelios',
    },
    {
      name: 'Умный полис',
      code: 'smart',
    },
    {
      name: 'Ингуру',
      code: 'inguru',
    },
    {
      name: 'Инсапп',
      code: 'insapp',
    },
    {
      name: 'Астроволга',
      code: 'astro_volga',
    },
    {
      name: 'Согласие',
      code: 'soglasie',
    },
    {
      name: 'Альфастрахование',
      code: 'alpha',
    },
  ];

  const [referenceMark, setReferenceMark] = useState('');
  const [integrationMark, setIntegrationMark] = useState('');
  const [integrationModel, setIntegrationModel] = useState('');
  const [referenceModel, setReferenceModel] = useState('');
  const [filteredReferenceMarks, setFilteredReferenceMarks] = useState([]);
  const [filteredIntegrationMarks, setFilteredIntegrationMarks] = useState([]);
  const [filteredIntegrationModels, setFilteredIntegrationModels] = useState([]);
  const [filteredReferenceModels, setFilteredReferenceModels] = useState([]);
  const [activeReferenceMark, setActiveReferenceMark] = useState(false);
  const [activeIntegrationMark, setActiveIntegrationMark] = useState(false);
  const [activeIntegration, setActiveIntegration] = useState(false);
  const [loadingReferenceMarksFlag, setLoadingReferenceMarksFlag] = useState(false);
  const [loadingReferenceModelsFlag, setLoadingReferenceModelsFlag] = useState(false);
  const [loadingIntegrationMarksFlag, setLoadingIntegrationMarksFlag] = useState(false);
  const [loadingIntegrationModelsFlag, setLoadingIntegrationModelsFlag] = useState(false);
  const [removingModelFlag, setRemovingModelFlag] = useState(false);
  const [state, setState] = useState({
    referenceMarks: null,
    referenceModels: null,
    integrationMarks: null,
    integrationModels: null,
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const combineItems = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    //const index = destClone.findIndex((x) => x.id === droppableDestination.draggableId);
    //destClone[index].content += removed.content;

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };
  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#f0f000' : '#f3f3f3',
    padding: grid,
    width: 220,
  });

  const updateReferenceModel = (sourceModel, destModelId) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingReferenceModelsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/models`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integration: activeIntegration,
        sourceModel,
        destModelId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceModels: response,
          }));
          if (referenceModel) {
            const newList = matchSorter(response, referenceModel, { keys: ['name'] });
            setFilteredReferenceModels(newList);
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReferenceModelsFlag(false);
      });
  };

  const addReferenceModel = (model) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingReferenceModelsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/models`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integration: activeIntegration,
        model,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceModels: response,
          }));
          if (referenceModel) {
            const newList = matchSorter(response, referenceModel, { keys: ['name'] });
            setFilteredReferenceModels(newList);
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReferenceModelsFlag(false);
      });
  };

  const updateReferenceMark = (sourceMark, destMarkId) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingReferenceMarksFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/marks`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integration: activeIntegration,
        sourceMark,
        destMarkId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceMarks: response,
          }));
          if (referenceMark) {
            const newList = matchSorter(response, referenceMark, { keys: ['name'] });
            setFilteredReferenceMarks(newList);
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReferenceMarksFlag(false);
      });
  };

  const addReferenceMark = (mark) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingReferenceMarksFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/marks`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integration: activeIntegration,
        mark,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceMarks: response,
          }));
          if (referenceMark) {
            const newList = matchSorter(response, referenceMark, { keys: ['name'] });
            setFilteredReferenceMarks(newList);
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReferenceMarksFlag(false);
      });
  };

  const onDragEnd = (result) => {
    console.log(result);
    const { source, destination, combine } = result;
    // dropped outside the list
    if ((!destination && !combine) || (combine && source.droppableId === combine.droppableId)) {
      return;
    }

    if (result.combine) {
      const newResult = combineItems(
        state[source.droppableId],
        state[combine.droppableId],
        source,
        combine,
      );
      if (source.droppableId === 'integrationMarks') {
        updateReferenceMark(state[source.droppableId][source.index], combine.draggableId);
      }
      if (source.droppableId === 'integrationModels') {
        updateReferenceModel(state[source.droppableId][source.index], combine.draggableId);
        const integrationMarksClone = Array.from(state.integrationMarks);
        const index = integrationMarksClone.findIndex((mark) => state[source.droppableId][source.index].mark_id === mark.id);
        integrationMarksClone[index].models_count -= 1;
        if (integrationMarksClone[index].models_count === 0) {
          integrationMarksClone.splice(index, 1);
        }
        setState((prevState) => ({
          ...prevState,
          [source.droppableId]: newResult[source.droppableId].length > 0 ? newResult[source.droppableId] : null,
          integrationMarks: integrationMarksClone,
        }));
      }
    } else if (source.droppableId === destination.droppableId) {
      const items = reorder(
        state[source.droppableId],
        source.index,
        destination.index,
      );

      setState((prevState) => ({
        ...prevState,
        [source.droppableId]: items,
      }));
    } else {
      const newResult = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination,
      );

      if (source.droppableId === 'integrationMarks') {
        addReferenceMark(state[source.droppableId][source.index]);
      }
      if (source.droppableId === 'integrationModels') {
        addReferenceModel(state[source.droppableId][source.index]);
        const integrationMarksClone = Array.from(state.integrationMarks);
        const index = integrationMarksClone.findIndex((mark) => state[source.droppableId][source.index].mark_id === mark.id);
        integrationMarksClone[index].models_count -= 1;
        if (integrationMarksClone[index].models_count === 0) {
          integrationMarksClone.splice(index, 1);
          setActiveIntegrationMark(false);
        }
        setState((prevState) => ({
          ...prevState,
          [source.droppableId]: newResult[source.droppableId].length > 0 ? newResult[source.droppableId] : null,
          integrationMarks: integrationMarksClone,
        }));
      }
    }
  };

  const loadReferenceMarks = () => {
    setLoadingReferenceMarksFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/marks`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceMarks: response,
          }));
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReferenceMarksFlag(false);
      });
  };

  const loadIntegrationModels = (markId) => {
    setLoadingIntegrationModelsFlag(true);
    setActiveIntegrationMark(markId);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/aliases/models?markId=${markId}&integration=${activeIntegration}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            integrationModels: response,
          }));
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((e) => {
        showModalInfo(e, 'error');
      })
      .finally(() => {
        setLoadingIntegrationModelsFlag(false);
      });
  };

  const loadReferenceModels = (markId) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/models?markId=${markId}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            referenceModels: response,
          }));
          setActiveReferenceMark(markId);
          if (referenceModel) {
            const newList = matchSorter(response, referenceModel, { keys: ['name'] });
            setFilteredReferenceModels(newList);
          }
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((e) => {
        showModalInfo(e, 'error');
      });
  };

  const changeIntegration = (integration) => {
    setActiveIntegration(integration);
    setActiveIntegrationMark(false);
    setLoadingIntegrationMarksFlag(true);
    setState((prevState) => ({
      ...prevState,
      integrationMarks: null,
      integrationModels: null,
    }));
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/aliases/marks?integration=${integration}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setState((prevState) => ({
            ...prevState,
            integrationMarks: response,
          }));
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((e) => {
        showModalInfo(e, 'error');
      })
      .finally(() => {
        setLoadingIntegrationMarksFlag(false);
      });
  };

  const filterReferenceModel = (e) => {
    const { value } = e.target;
    const newList = matchSorter(state.referenceModels, value, { keys: ['name'] });
    setReferenceModel(value);
    setFilteredReferenceModels(newList);
  };

  const filterReferenceMark = (e) => {
    const { value } = e.target;
    const newList = matchSorter(state.referenceMarks, value, { keys: ['name'] });
    setReferenceMark(value);
    setFilteredReferenceMarks(newList);
  };

  const filterIntegrationMark = (e) => {
    const { value } = e.target;
    const newList = state.integrationMarks.map((mark) => {
      const isMatch = mark.name.toLowerCase().includes(value.toLowerCase());
      return {
        ...mark,
        show: isMatch,
      };
    });
    setIntegrationMark(value);
    setFilteredIntegrationMarks(newList);
  };

  const filterIntegrationModel = (e) => {
    const { value } = e.target;
    const newList = state.integrationModels.map((model) => {
      const isMatch = model.name.toLowerCase().includes(value.toLowerCase());
      return {
        ...model,
        show: isMatch,
      };
    });
    setIntegrationModel(value);
    setFilteredIntegrationModels(newList);
  };

  const handleRemoveModel = (modelId, index) => {
    setRemovingModelFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/aliases/models?modelId=${modelId}&integration=${activeIntegration}`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const integrationModelsClone = Array.from(state.integrationModels);
          integrationModelsClone.splice(index, 1);

          const integrationMarksClone = Array.from(state.integrationMarks);
          const markIndex = integrationMarksClone.findIndex((mark) => state.integrationModels[index].mark_id === mark.id);
          integrationMarksClone[markIndex].models_count -= 1;
          if (integrationMarksClone[markIndex].models_count === 0) {
            integrationMarksClone.splice(markIndex, 1);
            setActiveIntegrationMark(false);
          }

          setState((prevState) => ({
            ...prevState,
            integrationModels: integrationModelsClone.length > 0 ? integrationModelsClone : null,
            integrationMarks: integrationMarksClone,
          }));
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((e) => {
        showModalInfo(e, 'error');
      })
      .finally(() => {
        setRemovingModelFlag(false);
      });
  };

  useEffect(() => {
    loadReferenceMarks();
  }, []);

  return (
    <div className="d-flex">
      <DragDropContext onDragEnd={onDragEnd}>
        {loadingReferenceMarksFlag
          ? <div className="mr-2" style={{ width: '220px' }}><LoadingSpinner className="loading-circle ml-auto mr-auto mt-5" type="spin" height={38} width={38} /></div>
          : state.referenceMarks
            ? (
              <div className="mr-2">
                <div className="align-center">Эталон (марки)</div>
                <input
                  className="form-control mb-2"
                  value={referenceMark}
                  type="text"
                  id="referenceMark"
                  name="referenceMark"
                  placeholder="Марка"
                  onChange={filterReferenceMark}
                />
                <Droppable droppableId="referenceMarks" isCombineEnabled type="MARKS">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <InnerList type="reference" activeItem={activeReferenceMark} list={referenceMark ? filteredReferenceMarks : state.referenceMarks} loadModels={loadReferenceModels} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )
            : null }
        {loadingReferenceModelsFlag
          ? <div className="mr-2" style={{ width: '220px' }}><LoadingSpinner className="loading-circle ml-auto mr-auto mt-5" type="spin" height={38} width={38} /></div>
          : state.referenceModels
            ? (
              <div className="mr-2">
                <div className="align-center">Эталон (модели)</div>
                <input
                  className="form-control mb-2"
                  value={referenceModel}
                  type="text"
                  id="referenceModel"
                  name="referenceModel"
                  placeholder="Модель"
                  onChange={filterReferenceModel}
                />
                <Droppable droppableId="referenceModels" isCombineEnabled type="MODELS">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <InnerList type="reference" list={referenceModel ? filteredReferenceModels : state.referenceModels} loadModels={() => {}} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ) : null }
        <div className="mr-2">
          <div className="align-center">Интеграция</div>
          <div className="vertical-list-container">
            {integrations.map((integration) => (
              <div key={integration.code} className={classnames('vertical-list-item', { active: activeIntegration === integration.code })} onClick={() => changeIntegration(integration.code)}>
                {integration.name}
              </div>
            ))}
          </div>
        </div>
        {loadingIntegrationMarksFlag
          ? <div className="mr-2" style={{ width: '220px' }}><LoadingSpinner className="loading-circle ml-auto mr-auto mt-5" type="spin" height={38} width={38} /></div>
          : state.integrationMarks ? (
            <div className="mr-2">
              <div className="align-center">Нераспределенные марки</div>
              <input
                className="form-control mb-2"
                value={integrationMark}
                type="text"
                id="integrationMark"
                name="integrationMark"
                placeholder="Марка"
                onChange={filterIntegrationMark}
              />
              <Droppable droppableId="integrationMarks" isCombineEnabled type="MARKS">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <InnerList activeItem={activeIntegrationMark} list={integrationMark ? filteredIntegrationMarks : state.integrationMarks} loadModels={loadIntegrationModels} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ) : null}
        {loadingIntegrationModelsFlag
          ? <div style={{ width: '220px' }}><LoadingSpinner className="loading-circle ml-auto mr-auto mt-5" type="spin" height={38} width={38} /></div>
          : state.integrationModels ? (
            <div>
              <div className="align-center">Нераспределенные модели</div>
              <input
                className="form-control mb-2"
                value={integrationModel}
                type="text"
                id="integrationModel"
                name="integrationModel"
                placeholder="Марка"
                onChange={filterIntegrationModel}
              />
              <Droppable droppableId="integrationModels" isCombineEnabled type="MODELS">
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <InnerList removingModelFlag={removingModelFlag} handleRemoveModel={handleRemoveModel} list={integrationModel ? filteredIntegrationModels : state.integrationModels} loadModels={() => {}} />
                      {provided.placeholder}
                    </div>
                  </>
                )}
              </Droppable>
            </div>
          ) : null}
      </DragDropContext>
    </div>
  );
}
