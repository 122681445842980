import React, {
  useEffect, useState, useContext,
} from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';

export default function AgentInsuranceFilter(props) {
  const { agent } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [insuranceFilters, setInsuranceFilters] = useState({});
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [savingFlag, setSavingFlag] = useState(false);
  const [product, setProduct] = useState({ value: 'osago', label: 'ОСАГО' });

  useEffect(() => {
    if (agent.id) {
      setLoadingFlag(true);
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/${product.value}/insuranceFilters`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            setInsuranceFilters(response);
          } else {
            showModalInfo(response.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка загрузки', 'error');
        })
        .finally(() => {
          setLoadingFlag(false);
        });
    }
  }, [agent.id, product.value]);

  const handleChangeActive = (e, insuranceCode) => {
    const newFiltered = { ...insuranceFilters };
    newFiltered[insuranceCode].active = e.target.checked;
    setInsuranceFilters(newFiltered);
  };

  const handleSelectRegion = (e, insuranceCode) => {
    const newFiltered = { ...insuranceFilters };
    if (e) {
      newFiltered[insuranceCode].filters = e.map((region) => region.value);
    } else {
      newFiltered[insuranceCode].filters = null;
    }
    setInsuranceFilters(newFiltered);
  };

  const promiseOptions = (inputValue) => new Promise((resolve) => {
    const token = 'Token 2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed';
    customFetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: inputValue,
        count: 10,
        from_bound: { value: 'region' },
        to_bound: { value: 'region' },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const regions = response.suggestions.map((address) => ({ value: address.value, label: address.value }));
        resolve(regions);
      });
  });

  const handleSubmit = () => {
    setSavingFlag(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/insuranceFilters`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ insuranceFilters, product: product.value }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('Данные обновлены');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки', 'error');
      })
      .finally(() => {
        setSavingFlag(false);
      });
  };

  return (
    <>
      <h2>Список страховых компаний</h2>
      <LoadingBanner loadingFlag={loadingFlag}>
        <div className="mb-4">
          <button disabled={savingFlag} type="button" className="btn btn-success mr-2" onClick={handleSubmit}>Сохранить</button>
          {savingFlag && <LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />}
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <Select
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="product"
                  isSearchable={false}
                  placeholder="Выберите продукт"
                  id="product"
                  value={product}
                  onChange={(value) => setProduct(value)}
                  options={[
                    { value: 'osago', label: 'ОСАГО' },
                    { value: 'mortgage', label: 'Ипотека' },
                  ]}
                />
              </div>
            </div>
            {Object.entries(insuranceFilters).map(([, insurance]) => (
              <div className="form-group row" key={insurance.code}>
                <div className="col-md-2">
                  <div className="form-check custom-checkbox">
                    <input onChange={(e) => handleChangeActive(e, insurance.code)} id={insurance.code} checked={insurance.active ? insurance.active : false} className="form-check-input checkbox-styled" type="checkbox" />
                    <label className="form-check-label" htmlFor={insurance.code}>
                      {insurance.name}
                    </label>
                  </div>
                </div>
                <div className="col-md-10">
                  <AsyncSelect
                    value={insurance.filters ? insurance.filters.map((filter) => ({ value: filter, label: filter })) : null}
                    classNamePrefix="react-select"
                    placeholder="Регионы"
                    isMulti
                    cacheOptions
                    loadingMessage={() => 'Загрузка'}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={(e) => handleSelectRegion(e, insurance.code)}
                    styles={customSelectStyle()}
                    loadOptions={(e) => promiseOptions(e)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </LoadingBanner>
    </>
  );
}
