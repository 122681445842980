import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import classnames from 'classnames';
import { shallow } from 'zustand/shallow';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import debounce from 'lodash.debounce';
import { customFetch, truncateString } from '../Utils/Helpers';
import ProductBlock from '../Products/ProductBlock';
import {
  ModalMessagesContext,
} from '../Utils/ContextsServices/ModalMessagesService';
import useUserInfoStore from '../Stores/UserInfoStore';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import LoadingSpinner from '../Layout/LoadingSpinner';

export default function FaqKnowledge(props) {
  const { history, match } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState(null);
  const [checkCategory, setCheckCategory] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { showModalInfo } = useContext(ModalMessagesContext);
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  useEffect(() => {
    document.title = 'FAQ - база знаний';
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/categories`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          setCategories(res);
        } else {
          showModalInfo(res.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при загрузке категорий', 'error');
      });
  }, []);

  function loadFaqBySearch(inputValue) {
    if (inputValue) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/search/${inputValue}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((faqs) => {
          if (!faqs.error) {
            setCheckCategory(null);
            setData(faqs);
          } else {
            showModalInfo(faqs.error, 'error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCheckCategory(14);
    }
  }

  const debounceFn = useCallback(debounce(loadFaqBySearch, 1000), []);

  function handleChangeSearch(event) {
    setLoading(true);
    setSearchText(event.target.value);
    debounceFn(event.target.value);
  }

  useEffect(() => {
    if (checkCategory) {
      setLoading(true);
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/categories/${checkCategory}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((faqs) => {
          if (!faqs.error) {
            setData(Array.isArray(faqs) ? faqs.sort((a, b) => a.sort - b.sort) : []);
          } else {
            showModalInfo(faqs.error, 'error');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [checkCategory]);

  useEffect(() => {
    if (match.params.id) {
      setCheckCategory(parseInt(match.params.id, 10));
    } else {
      setCheckCategory(14);
    }
  }, []);

  return (
    <>
      <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item">
          <a className="link" href="" onClick={(e) => { e.preventDefault(); setCheckCategory(null); history.push('/faq_knowledge'); }}>Вопросы и ответы</a>
        </li>
        {(checkCategory && categories) ? (
          <li className="breadcrumbs__item">
            <a className="link" href="" onClick={(e) => { e.preventDefault(); }}>{categories.find((category) => category.id === checkCategory).name}</a>
          </li>
        ) : null}
      </ul>
      <div><h1 className="page-wrapper__title d-inline-block mr-3">Вопросы и ответы</h1></div>
      <div className="search-block__wrapper flex-wrap">
        <div className="search-block__item search-block__item--search mb-4">
          <div className="search-block__input">
            <input
              type="text"
              value={searchText}
              placeholder="Поиск вопросов по ключевым словам"
              onChange={handleChangeSearch}
            />
            {loading
              ? (<LoadingSpinner width={20} height={20} absolute />)
              : (<i className="icon border-0 bg-transparent"><MagnifyingGlassIcon color="#737373" width={20} height={20} /></i>)}
          </div>
        </div>
        {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
          <button type="button" className="btn btn-success mb-4" onClick={() => history.push('/faq_answer/add')}>Добавить контент</button>
        ) : null}
      </div>
      <div className="filters-list">
        {categories && categories.map((category) => (
          <button
            key={category.id}
            onClick={() => {
              history.push(`/faq_knowledge/${category.id}`);
              setCheckCategory(category.id);
            }}
            className={classnames('filters-list-item', { 'filters-list-item__active': category.id === checkCategory })}
            type="button"
            id={category.id}
          >
            {category.name}
          </button>
        ))}
      </div>
      <LoadingBanner loadingFlag={loading}>
        <div className="product-cards">
          {data.map((faq) => (
            <ProductBlock
              key={faq.id}
              name={faq.question}
              category="all"
              path={`/faq_answer/${faq.id}`}
              target="_self"
              history={history}
            >
              {truncateString(JSON.parse(faq.answer).blocks[0].text, 180)}
            </ProductBlock>
          ))}
        </div>
      </LoadingBanner>
    </>
  );
}
