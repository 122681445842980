import React, {
  useContext, useEffect, useState,
} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format, parseISO } from 'date-fns';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  getDefaultSorting7,
  onContextMenuClick,
  onFilteredChangeCustom,
  onTableCellClick,
  setTableSettings7,
  setTableSorting,
} from '../../Utils/Helpers';
import Table from '../../Layout/Table';

export default function AgentEnforcementProceedingsHistoryTable(props) {
  const { selectedEnforcementProceeding, tab, history } = props;
  const defaultSorting = getDefaultSorting7('enforcementProceedingsHistoryTableSettings');
  const [filtered, setFiltered] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingEnforcementProceedingsFlag, setLoadingEnforcementProceedingsFlag] = useState(false);
  const [enforcementProceedingsHistory, setEnforcementProceedingsHistory] = useState([]);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const getEnforcementProceedingsHistory = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingEnforcementProceedingsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/enforcementProceeding/${selectedEnforcementProceeding}/history`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setEnforcementProceedingsHistory(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch((error) => {
        error = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error, 'error');
      })
      .finally(() => {
        setLoadingEnforcementProceedingsFlag(false);
      });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'ID реализации',
      accessor: 'payment_realization_id',
      width: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').payment_realization_id,
      show: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').show_payment_realization_id !== false,
    }, {
      id: 'status',
      Header: 'Статус',
      Cell: ({ row }) => {
        if (row.original.status === 'canceled') return 'Отменена';
        return 'Оплачена';
      },
      accessor: (d) => {
        if (d.status === 'canceled') {
          return 'Отменена';
        }
        return 'Оплачена';
      },
      Filter: 'selectColumnFilter',
      filter: 'strictEqualSelectFilterFn',
      width: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').enforcement_proceeding_status,
      show: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').show_enforcement_proceeding_status !== false,
    }, {
      Header: 'Дата списания',
      accessor: 'created_at',
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').created_at,
      show: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').show_created_at !== false,
    }, {
      Header: 'Сумма списания',
      accessor: 'amount',
      width: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').amount,
      show: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').show_amount !== false,
    }, {
      Header: 'Изначальный остаток',
      accessor: 'enforcement_proceeding_amount_history',
      width: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').enforcement_proceeding_amount_history,
      show: reactLocalStorage.getObject('enforcementProceedingsHistoryTableSettings').show_enforcement_proceeding_amount_history !== false,
    },
    ];
    setColumns(updatedColumns);
  };

  useEffect(() => {
    if (selectedEnforcementProceeding) {
      getEnforcementProceedingsHistory();
      updateTableColumns();
    }
  }, []);
  return (
    <Table
      onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'paymentRealizations', history)}
      onContextMenuClick={(e, row) => onContextMenuClick(e, 'paymentRealizations', row.original.payment_realization_id)}
      defaultSorted={defaultSorting}
      defaultFiltered={filtered}
      data={enforcementProceedingsHistory}
      columns={columns}
      loading={loadingEnforcementProceedingsFlag}
      noDataText="Списания не найдены"
      onSortedChange={(newSort) => {
        setTableSorting(newSort, 'enforcementProceedingsHistoryTableSettings');
      }}
      onResizedChange={(newResized) => {
        setTableSettings7(newResized, 'enforcementProceedingsHistoryTableSettings');
      }}
      onFilteredChange={(newFiltered) => {
        setFiltered(onFilteredChangeCustom(newFiltered, null, tab));
      }}
    />
  );
}
