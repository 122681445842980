import React, { useEffect, useState, useContext } from 'react';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';

export default function AgentPoll(props) {
  const { agent } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [pollData, setPollData] = useState({
    experience: {
      label: '',
      value: '',
    },
    agentNet: {
      label: '',
      value: '',
    },
    leadBuying: {
      label: '',
      value: '',
    },
    site: {
      label: '',
      value: '',
    },
    widget: {
      label: '',
      value: '',
    },
    training: {
      label: '',
      value: '',
    },
    newProduct: [{
      label: '',
      value: '',
    }],
    anotherNewProduct: '',
    servicesWorkWith: '',
    companiesWorkWith: '',
    crmWorkWith: '',
  });

  const loadPollInfo = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent-poll/${agent.id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((poll) => {
        if (!poll.error) {
          const loadedPollInfo = JSON.parse(poll.form_data);
          loadedPollInfo.createdDate = poll.created_at.substring(0, 10).split('-').reverse().join('.');
          setPollData({ ...loadedPollInfo });
        } else {
          showModalInfo(poll.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      });
  };

  useEffect(() => {
    if (agent.poll_complete) {
      loadPollInfo();
    }
  }, [agent.id]);

  return (
    <>
      <div className="position-relative">
        {agent.poll_complete ? (
          <div className="card">
            <div className="card-body">
              <p><strong className="mr-2">Дата заполнения:</strong>{pollData.createdDate}</p>
              <p><strong className="mr-2">Как давно работаете в сфере страхования?:</strong>{pollData.experience.label}</p>
              <p><strong className="mr-2">Есть ли у вас сеть пользователей?:</strong>{pollData.agentNet.label}</p>
              <p><strong className="mr-2">Интересна ли вам покупка лидов (заявки на страхование)?:</strong>{pollData.leadBuying.label}</p>
              <p><strong className="mr-2">Есть ли у вас сайт?:</strong>{pollData.site.label}</p>
              {pollData.site.value === 'yes' ? (
                <p><strong className="mr-2">Стоит ли виджет(модуль) е-осаго?:</strong>{pollData.widget.label}</p>
              ) : null}
              <p><strong className="mr-2">Какие продукты вы хотите добавить в сервис онлайн-страхования?:</strong>
                {pollData.newProduct.map((product, index) => {
                  if (product.value === 'another') {
                    return ` ${index + 1}.${pollData.anotherNewProduct}`;
                  }
                  return ` ${index + 1}.${product.label}`;
                })}
              </p>
              <p><strong className="mr-2">С какими сервисами страхования вы уже работаете?:</strong>{pollData.servicesWorkWith}</p>
              <p><strong className="mr-2">С какими страховыми компаниями вы уже работаете?:</strong>{pollData.companiesWorkWith}</p>
              <p><strong className="mr-2">Какой CRM системой вы пользуетесь?:</strong>{pollData.crmWorkWith}</p>
              <p><strong className="mr-2">Интересно ли вам обучение в школе Polis.online?:</strong>{pollData.training.label}</p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-6">
              <FormNotificationColorLine>
                Пользователь еще не заполнил опрос.
              </FormNotificationColorLine>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
