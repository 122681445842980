import React, {
  forwardRef, useContext, useImperativeHandle, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular/index';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function InsuranceProgramDocuments(props, ref) {
  const {
    documentsList, setDocumentsList, program,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const NEW_DOCUMENT = {
    name: '', path: '', id: null, file: null, downloaded: false, error: null,
  };

  const deleteDocument = (e, index) => {
    e.preventDefault();

    setDocumentsList((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const changeDocument = (e, index) => {
    e.preventDefault();
    setDocumentsList((prev) => {
      prev[index].name = e.target.files[0].name;
      prev[index].file = e.target.files[0];
      prev[index].downloaded = false;
      return [...prev];
    });
  };

  const saveDocuments = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;

    const formData = new FormData();
    documentsList.forEach((file, index) => {
      if (file.id) {
        formData.append(`file_id_${index}`, file.id);
      }
      if (file.file) {
        formData.append(`file_${index}`, file.file);
      } else {
        formData.append(`file_${index}`, file.path);
      }
      formData.append(`downloaded_${index}`, file.downloaded);
      formData.append(`name_${index}`, file.name);
    });
    formData.append('programId', program.id);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/document/`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const savedDocumentsList = documentsList.map((document, index) => ({
            ...document,
            downloaded: true,
            path: response[index].path,
            id: response[index].id,
          }));
          setDocumentsList(savedDocumentsList);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      saveDocuments();
    },
  }), [documentsList, program]);

  const documentsBlock = useMemo(() => {
    return documentsList.map((document, index) => (
      <div key={index}>
        <p style={{ marginBottom: '5px' }}>Документ №{index + 1}<a onClick={(e) => { deleteDocument(e, index); }} className="close-driver">×</a></p>
        <div className="form-group row">
          <div className="col-lg-4">
            <div className="jq-file styler">
              <div className="jq-file__name">{document.downloaded
                ? <>{document.name}<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                : document.name}
              </div>
              <div className="jq-file__browse" />
              <input onChange={(e) => { changeDocument(e, index); }} type="file" name={`document${index}`} className="custom-input-file" />
            </div>
            {document.path && (
              <div className="jq-file__download">
                <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${document.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
              </div>
            )}
            {document.error && (
              <div className="mt-2 error_block">{document.error}</div>
            )}
          </div>
        </div>
      </div>
    ));
  }, [documentsList]);

  return (
    <>
      <h5>Документы</h5>
      {documentsBlock}

      <div className="row">
        <div className="col-lg-3">
          <button onClick={() => { setDocumentsList([...documentsList, NEW_DOCUMENT]); }} type="button" className="btn btn-border btn-success">+ Еще документ</button>
        </div>
      </div>
    </>
  );
}

export default forwardRef(InsuranceProgramDocuments);
