import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import { shallow } from 'zustand/shallow';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import useUserInfoStore from '../../Stores/UserInfoStore';

export default function NsRequest() {
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  return (
    <LoadingBanner loadingFlag={!userInfo.id}>
      <h1 className="page_title">Страхование НС</h1>
      <div className="d-flex partner-screen h-auto">
        <IframeResizer
          src={`https://www.ingos.ru/iframe/neschastnyj-sluchaj/calc?partner=polis-оnline&utm_source=affiliate&utm_medium=cpa&utm_campaign=active:b2c|name:ns|partner:polisonline|date:2023&utm_content=frame_ocp-${userInfo.id}`}
          id="iFrameResizer0"
          scrolling="yes"
          style={{ flex: '1 1 0%', height: '1555px', overflow: 'auto !important' }}
        />
      </div>
    </LoadingBanner>
  );
}
