import React, { forwardRef, useContext, useImperativeHandle, useMemo } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { customFetch, validateFields } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function InsuranceProgramSales(props, ref) {
  const {
    setSavingProgramFlag, salesList, setSalesList, customStyles, periodOptions, program,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const NEW_SALE = {
    name: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    sale: {
      value: 0,
      validationRequired: true,
      errorMessage: '',
      validationType: 'percent',
    },
    parameter: {
      value: periodOptions[0],
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    changed: false,
    id: null,
  };

  const validateProgramSales = () => {
    let validationSuccess = true;
    const checkedSaleList = [...salesList];
    checkedSaleList.forEach((sale) => {
      const formFields = { ...sale };
      const validationResult = validateFields(formFields);
      sale = { ...validationResult.result };
      if (!validationResult.validationSuccess) {
        validationSuccess = false;
      }
    });
    setSalesList(checkedSaleList);
    return validationSuccess;
  };

  const saveSale = () => {
    const isValid = validateProgramSales();
    if (isValid) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      setSavingProgramFlag(true);

      const salesToSave = salesList.map((sale) => ({
        ...sale,
        name: sale.name.value,
        sale: sale.sale.value,
        parameter: sale.parameter.value.value,
      }));

      customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/sales-update`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          programId: program.id,
          sales: salesToSave,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            const savedSaleList = salesList.map((sale, index) => ({
              ...sale,
              changed: false,
              id: response[index].id,
            }));
            setSalesList(savedSaleList);
            showModalInfo('Изменения сохранены');
          } else {
            showModalInfo(response.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          setSavingProgramFlag(false);
        });
    }
  };

  const deleteSale = (e, index) => {
    e.preventDefault();

    setSalesList((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleChangeInput = (e) => {
    const { target } = e;
    const fieldIdData = target.id.split('_');
    const fieldName = fieldIdData[0];
    const index = parseInt(fieldIdData[1], 10);

    setSalesList((prev) => {
      prev[index][fieldName].value = target.value;
      prev[index][fieldName].errorMessage = '';
      prev[index].changed = true;
      return [...prev];
    });
  };

  const handleChangeSelect = (e, fieldName, index) => {
    setSalesList((prev) => {
      prev[index][fieldName].value = e;
      prev[index][fieldName].errorMessage = '';
      prev[index].changed = true;
      return [...prev];
    });
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      saveSale();
    },
  }), [salesList, program]);

  const salesBlock = useMemo(() => {
    return salesList.map((sale, index) => (
      <div key={index}>
        <p style={{ marginBottom: '5px' }}>Скидка №{index + 1}<a onClick={(e) => { deleteSale(e, index); }} className="close-driver">×</a></p>
        <div className="form-group row">
          <div className="col-lg-4">
            <label htmlFor={`documentName${index}`}>Наименование</label>
            <input
              value={sale.name.value}
              onChange={handleChangeInput}
              type="text"
              className={classnames('form-control', { error: sale.name.errorMessage })}
              id={`name_${index}`}
              name={`name_${index}`}
            />
            {sale.name.errorMessage && (
              <div className="validation-error">
                {sale.name.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor={`documentName${index}`}>Размер скидки (%)</label>
            <input
              value={sale.sale.value}
              onChange={handleChangeInput}
              type="number"
              id={`sale_${index}`}
              name={`sale_${index}`}
              className={classnames('form-control', { error: sale.sale.errorMessage })}
            />
            {sale.sale.errorMessage && (
              <div className="validation-error">
                {sale.sale.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-2">
            <label htmlFor={`documentName${index}`}>Срок (мес.)</label>
            <Select
              classNamePrefix="react-select"
              inputId={`parameter_${index}`}
              className={classnames({ error: sale.parameter.errorMessage })}
              name={`parameter_${index}`}
              placeholder="Срок (мес.)"
              noOptionsMessage={() => 'Не найдено'}
              value={sale.parameter.value}
              styles={customStyles}
              onChange={(e) => { handleChangeSelect(e, 'parameter', index); }}
              options={periodOptions}
            />
            {sale.parameter.errorMessage && (
              <div className="validation-error">
                {sale.parameter.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  }, [salesList]);

  return (
    <>
      <h5>Скидки</h5>
      {salesBlock}

      <div className="row">
        <div className="col-lg-3">
          <button onClick={() => { setSalesList([...salesList, NEW_SALE]); }} type="button" className="btn btn-border btn-success">+ Еще одна скидка</button>
        </div>
      </div>
    </>
  );
}

export default forwardRef(InsuranceProgramSales);
