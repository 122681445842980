import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { XCircleIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { shallow } from 'zustand/shallow';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../Utils/Helpers';
import Button from '../Layout/Button';
import useUserInfoStore from '../Stores/UserInfoStore';

const PanelContainer = styled.div`
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 18000;
  background: rgba(40, 40, 38, .83);
  backdrop-filter: blur(10px);
  transition: visibility .15s ease, opacity .15s ease;
  width: 100%;
  padding: 16px 32px;
`;

const ExitButton = styled.div`
    color: #ff4d00;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: 100%;
    padding: 7px 18px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    user-select: none;
    transition: all .15s ease;
    white-space: nowrap;
    cursor: pointer;

    &:hover{
      color: #fff;
    }

    & span{
      margin-left: 8px;
    }

    & svg{
      height: 24px;
    }
`;

const ButtonsContainer = styled.div`
  display: flex;

  & svg{
    height: 18px;
  }

  & span {
    margin-right: 8px;
  }

  & span:first-of-type {
    margin-left: 8px;
  }
`;

const StepsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 -8px;
  padding: 0 24px;
  list-style: none;

  & Button{
    margin-bottom: 8px !important;
    &:not(last-child){
      margin-right: 8px !important;
    }
  }
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: start;
`;

function InterfaceTourPanel(props) {
  const {
    closeTour,
    steps,
    currentStep,
    nextStep,
    prevStep,
    goToStep,
    domRef,
    userInfo,
    updateUser,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  const colorSchema = useMemo(() => ({
    main: theme.mainColor,
    background: theme.mainColor,
    hover: theme.mainColorDarker,
    text: theme.buttonFontColor,
    textHover: theme.buttonFontColor,
  }), [theme]);

  const disableInterfaceTours = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/disable`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newUserInfo = { ...userInfo };
          newUserInfo.show_interface_tours = false;
          updateUser(newUserInfo);
          closeTour();
          localStorage.removeItem('interfaceTourData');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка запроса деактивации обучения', 'error');
      });
  };

  return (
    <PanelContainer ref={domRef}>
      <CloseButtonContainer>
        <ExitButton onClick={disableInterfaceTours}>
          <XCircleIcon />
          <span>Я знаком с сервисом</span>
        </ExitButton>
        <ExitButton onClick={() => { closeTour(); }}>
          <XCircleIcon />
          <span>Пройти обучение позже</span>
        </ExitButton>
      </CloseButtonContainer>
      <StepsContainer>
        {steps.map((step, index) => {
          let buttonColorSchema = {};

          if (index < currentStep) {
            buttonColorSchema = colorSchema;
          }
          if (index === currentStep) {
            buttonColorSchema = {
              main: '#525252',
              hover: '#525252',
              text: '#fff',
              textHover: '#fff',
              borderColor: theme.mainColor,
              borderColorHover: theme.mainColor,
            };
          }

          let buttonText = index + 1;
          if (index === currentStep && step.name) {
            buttonText = `${buttonText}. ${step.name}`;
          }

          return (
            <Button
              key={step.id}
              width={currentStep === index && step.name ? 'auto' : '40px'}
              colorType={index >= currentStep ? 'darkGray' : 'primary'}
              onClick={(e) => goToStep(e, index)}
              colorSchema={buttonColorSchema}
              type={currentStep === index ? 'border' : 'fill'}
            >
              {buttonText}
            </Button>
          );
        })}
      </StepsContainer>
      <ButtonsContainer>
        <Button width="120px" colorType="gray" disabled={currentStep === 0} onClick={prevStep}>
          <ArrowLeftIcon />
          <span>Назад</span>
        </Button>
        {currentStep === steps.length - 1 ? (
          <Button width="120px" onClick={() => { closeTour('isFinished'); }} colorSchema={colorSchema}>
            <span>Завершить</span>
          </Button>
        ) : (
          <Button width="120px" onClick={nextStep} colorSchema={colorSchema}>
            <span>Далее</span>
            <ArrowRightIcon />
          </Button>
        )}
      </ButtonsContainer>
    </PanelContainer>
  );
}

export default InterfaceTourPanel;
