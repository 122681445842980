import React, { Component } from 'react';
import Select from 'react-select';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import CommissionInput from '../Admin/CommissionInput';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import Regions from '../Admin/Regions';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';

let abortController = new AbortController();

const regionName = {
  'Москва': 'moscow',
  'Московская обл.': 'moscowObl',
  'Санкт-Петербург': 'spb',
  'Ленинградская обл.': 'spbObl',
  'Россия': 'other',
};

const regionNameMortgage = {
  'Россия': 'other',
};

const regionList = {
  moscow: 'Москва',
  moscowObl: 'Московская обл.',
  other: 'Россия',
  spb: 'Санкт-Петербург',
  spbObl: 'Ленинградская обл.',
};

class AgentsCommission extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      sliderValue: '',
      loadingCommissions: false,
      loadingCompanies: false,
      commissionType: { value: 'quota', label: 'Квота от входящей' },
      selectedButtonCommission: false,
      companies: {},
      quota: {},
      companyNames: {},
      ownCommissions: [],
      errorCommission: false,
      product: { value: 'osago', label: 'ОСАГО' },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { product } = this.state;
    this.downloadCommissions(product.value);
    this.downloadCompanies(product.value);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  downloadCommissions = (product) => {
    this.setState({ loadingCommissions: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission?product=${product}`, {
      signal: abortController.signal,
      method: 'get',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((innerCommissions) => {
        this._isMounted && this.setState({ ownCommissions: Object.values(innerCommissions), loadingCommissions: false });
      });

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/init?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((initValues) => {
        this.setState({
          companies: JSON.parse(JSON.stringify(initValues)),
          quota: JSON.parse(JSON.stringify(initValues)),
        });
      });
  };

  downloadCompanies = (product) => {
    this.setState({ loadingCompanies: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies?type=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((companyData) => {
        const companyNames = {};
        Object.keys(companyData).forEach((company) => {
          companyNames[companyData[company].name] = company;
        });
        this._isMounted && this.setState({ companyNames, companyData, loadingCompanies: false });
      });
  };

  handleChangeProduct = (e) => {
    abortController.abort();
    abortController = new AbortController();
    this.setState({
      product: e, companyNames: {}, companyData: {}, companies: {}, quota: {}, selectedButtonCommission: false, ownCommissions: [],
    }, () => {
      this.downloadCompanies(e.value);
      this.downloadCommissions(e.value);
    });
  };

  handleChangeCommissionType = (e) => {
    this.setState({ commissionType: e });
  };

  handleInputCustomCommission = (company, key, e) => {
    const {
      companies, companyData, ownCommissions, quota, product,
    } = this.state;
    companies[company].custom.forEach((element, index) => {
      element.regions.sort();
      key.sort();
      if (JSON.stringify(element.regions) === JSON.stringify(key)) {
        element.value = e.target.value;
        const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === key[0] && commission.product.code === product.value);
        quota[company].custom[index].value = ownCommissions[findedQuota].price === 0 ? 0 : (e.target.value * 100) / ownCommissions[findedQuota].price;
      }
    });
    this.setState({ companies, quota });
  };

  handleInputCustomQuota = (company, key, e) => {
    const {
      quota, companies, ownCommissions, companyData, product,
    } = this.state;
    quota[company].custom.forEach((element, index) => {
      element.regions.sort();
      key.sort();
      if (JSON.stringify(element.regions) === JSON.stringify(key)) {
        element.value = e.target.value;
        const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === key[0] && commission.product.code === product.value);
        companies[company].custom[index].value = (e.target.value * ownCommissions[findedQuota].price) / 100;
      }
    });
    this.setState({ quota, companies });
  };

  banksBlock = (company) => {
    const {
      ownCommissions, companies, companyNames, commissionType, quota, product, companyData
    } = this.state;

    return Object.keys(companies[companyNames[company]]).map((bank) => {
      const yourCommission = ownCommissions.length && ownCommissions.findIndex((commission) => commission.company === company && commission.bank.code === bank && commission.product.code === product.value) !== -1 ? ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.bank.code === bank && commission.product.code === product.value)].price : '0';
      const agentCommission = companies[companyNames[company]][bank];
      return (
        <div key={`${companyNames[company]}${bank}`} className="form-group row">
          <label className="col-md-2 col-form-label">{companyData[companyNames[company]].banks[bank]}</label>
          <div className="col-md-2">
            <CommissionInput
              handleInputCommission={this.handleInputCommission}
              value={agentCommission}
              region={bank}
              company={companyNames[company]}
              disabled
            />
          </div>
          <div className="col-md-2">
            <input value={yourCommission} disabled className="form-control" />
          </div>
          {commissionType.value === 'quota' && (
            <>
              <div className="col-md-2">
                <CommissionInput
                  value={+(yourCommission - agentCommission).toFixed(2)}
                  region={bank}
                  company={companyNames[company]}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputQuota}
                  value={quota[companyNames[company]][bank]}
                  region={bank}
                  company={companyNames[company]}
                />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  regionsBlock = (company) => {
    const {
      ownCommissions, companies, companyNames, commissionType, quota, product,
    } = this.state;
    let filteredRegions = regionName;
    if (product.value === 'mortgage' || product.value === 'mortgageLife') {
      filteredRegions = regionNameMortgage;
    }
    return Object.keys(filteredRegions).map((region) => {
      const yourCommission = ownCommissions.length && ownCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value) !== -1 ? ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value)].price : '0';
      const agentCommission = companies[companyNames[company]][filteredRegions[region]];
      return (
        <div key={`${companyNames[company]}${filteredRegions[region]}`} className="form-group row">
          <label className="col-md-2 col-form-label">{region}</label>
          <div className="col-md-2">
            <CommissionInput
              handleInputCommission={this.handleInputCommission}
              value={agentCommission}
              region={filteredRegions[region]}
              company={companyNames[company]}
              disabled={commissionType.value === 'quota'}
            />
          </div>
          <div className="col-md-2">
            <input value={yourCommission} disabled className="form-control" />
          </div>
          {commissionType.value === 'quota' && (
            <>
              <div className="col-md-2">
                <CommissionInput
                  value={+(yourCommission - agentCommission).toFixed(2)}
                  region={filteredRegions[region]}
                  company={companyNames[company]}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputQuota}
                  value={quota[companyNames[company]][filteredRegions[region]]}
                  region={filteredRegions[region]}
                  company={companyNames[company]}
                />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  customRegionBlock = (company) => {
    const {
      ownCommissions, companies, companyNames, commissionType, quota, product,
    } = this.state;
    const customCommissions = ownCommissions.filter((commission) => !regionName.hasOwnProperty(commission.region)
      && commission.company === company && commission.product.code === product.value);

    Array.prototype.groupBy = function (prop) {
      return this.reduce((groups, item) => {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    };
    const groupedByGroup = customCommissions.groupBy('group_id');
    return Object.keys(groupedByGroup).map((group, index) => {
      const regionsArray = Object.keys(groupedByGroup[group].groupBy('region')).map((value) => (value));
      const regions = regionsArray.join(', ');
      const ownCommissionCustomRegion = ownCommissions[ownCommissions.findIndex((commission) => (commission.company === company && commission.region === regionsArray[0] && commission.product.code === product.value))];
      const customCommission = companies[companyNames[company]].custom.find((element) => {
        if (element.groupId === group) {
          return element;
        }
        return false;
      });
      const customQuota = quota[companyNames[company]].custom.find((element) => {
        if (element.groupId === group) {
          return element;
        }
        return false;
      });
      const yourCommission = ownCommissionCustomRegion ? ownCommissionCustomRegion.price : 0;
      const commission = customCommission ? customCommission.value : 0;
      return (
        <div key={group} className="form-group row">
          <Regions
            regions={regions}
            index={index}
          />
          <div className="col-md-2">
            <CommissionInput
              handleInputCommission={this.handleInputCustomCommission}
              value={commission}
              region={regionsArray}
              company={companyNames[company]}
              disabled={commissionType.value === 'quota'}
            />
          </div>
          <div className="col-md-2">
            <input value={yourCommission} disabled className="form-control" />
          </div>
          {commissionType.value === 'quota' && (
            <>
              <div className="col-md-2">
                <CommissionInput
                  disabled
                  value={+(yourCommission - commission).toFixed(2)}
                  region={regionsArray}
                  company={companyNames[company]}
                />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputCustomQuota}
                  value={customQuota ? customQuota.value : 0}
                  region={regionsArray}
                  company={companyNames[company]}
                />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  handleInputCommission = (company, key, e) => {
    const {
      companies, quota, ownCommissions, companyData, product,
    } = this.state;
    companies[company][key] = e.target.value;
    const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === regionList[key] && commission.product.code === product.value);
    quota[company][key] = ownCommissions[findedQuota].price === 0 ? 0 : (e.target.value * 100) / ownCommissions[findedQuota].price;
    this.setState({ companies, quota });
  };

  handleInputQuota = (company, key, e) => {
    const {
      quota, companies, ownCommissions, companyData, product,
    } = this.state;
    quota[company][key] = e.target.value;
    const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === regionList[key] && commission.product.code === product.value);
    companies[company][key] = (e.target.value * ownCommissions[findedQuota].price) / 100;
    this.setState({ quota, companies });
  };

  handleChangeSlider = (e) => {
    const {
      companies, ownCommissions, companyData, quota, product,
    } = this.state;

    if (product.value === 'osago') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((region) => {
          if (region === 'custom') {
            companies[company][region].forEach((customRegion, index) => {
              if (customRegion.regions.length > 0) {
                const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === customRegion.regions[0] && commission.product.code === product.value)].price;
                quota[company][region][index].value = 100 + e;
                if (quota[company][region][index].value < 0) {
                  quota[company][region][index].value = 0;
                }
                companies[company][region][index].value = (quota[company][region][index].value * parentCommission) / 100;
              }
            });
          } else {
            const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === regionList[region] && commission.product.code === product.value)].price;
            quota[company][region] = 100 + e;
            if (quota[company][region] < 0) quota[company][region] = 0;
            companies[company][region] = (quota[company][region] * parentCommission) / 100;
          }
        });
      });
    }
    if (product.value === 'mortgage') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((bank) => {
          const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.bank.code === bank && commission.product.code === product.value)].price;
          quota[company][bank] = 100 + e;
          if (quota[company][bank] < 0) quota[company][bank] = 0;
          companies[company][bank] = (quota[company][bank] * parentCommission) / 100;
        });
      });
    }

    this.setState({
      companies,
      quota,
      selectedButtonCommission: false,
    });
  };

  handleChangeSliderFastButton = (value) => {
    const {
      companies, ownCommissions, companyData, quota, product,
    } = this.state;

    if (product.value === 'osago') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((region) => {
          if (region === 'custom') {
            companies[company][region].forEach((customRegion, index) => {
              if (customRegion.regions.length > 0) {
                const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === customRegion.regions[0] && commission.product.code === product.value)].price;
                quota[company][region][index].value = 100 - parseFloat(value);
                if (quota[company][region][index].value < 0) quota[company][region][index].value = 0;
                companies[company][region][index].value = (quota[company][region][index].value * parentCommission) / 100;
              }
            });
          } else {
            const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.region === regionList[region] && commission.product.code === product.value)].price;
            quota[company][region] = 100 - parseFloat(value);
            if (quota[company][region] < 0) quota[company][region] = 0;
            companies[company][region] = (quota[company][region] * parentCommission) / 100;
          }
        });
      });
    }
    if (product.value === 'mortgage') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((bank) => {
          const parentCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === companyData[company].name && commission.bank.code === bank && commission.product.code === product.value)].price;
          quota[company][bank] = 100 - parseFloat(value);
          if (quota[company][bank] < 0) quota[company][bank] = 0;
          companies[company][bank] = (quota[company][bank] * parentCommission) / 100;
        });
      });
    }

    this.setState({
      sliderValue: -value,
      companies,
      quota,
      selectedButtonCommission: value,
    });
  };

  handleSubmit = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      companies, commissionType, quota, product,
    } = this.state;
    const { showModalInfo } = this.context;
    const { agents } = this.props;
    this.setState({
      errorCommission: false,
    });
    this.props.onChangeGroupEditProgress(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/group`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companies,
        quota,
        agents,
        update: true,
        type: commissionType.value,
        product: product.value,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        if (commissions.error) {
          this.setState({ errorCommission: commissions.error });
        } else {
          showModalInfo('Изменение вознаграждения может занять несколько минут');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка обновления вознаграждения');
        this.props.onChangeGroupEditProgress(false);
      })
      .finally(() => {
        const { setSelected, changeEditOption, onChangeGroupEditProgress } = this.props;
        setSelected({}, 0, 0);
        changeEditOption({ value: '', label: '' });
        onChangeGroupEditProgress(false);
      });
  };

  setSliderValue = (val) => {
    this.setState({
      sliderValue: val,
    });
  };

  render() {
    const {
      commissionType,
      selectedButtonCommission,
      companies,
      companyNames,
      errorCommission,
      product,
      loadingCompanies,
      loadingCommissions,
      sliderValue,
    } = this.state;

    return (
      <>
        <div className="form-group row">
          <div className="col-md-3">
            <label htmlFor="commissionType">Тип начисления вознаграждения</label>
            <Select
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              name="commissionType"
              isSearchable={false}
              placeholder="Выберите тип"
              id="commissionType"
              value={commissionType}
              onChange={this.handleChangeCommissionType}
              options={[
                { value: 'quota', label: 'Квота от входящей' },
                { value: 'hide', label: 'Скрывать' },
              ]}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-3">
            <Select
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              name="product"
              isSearchable={false}
              placeholder="Выберите продукт"
              id="product"
              value={product}
              onChange={this.handleChangeProduct}
              options={[
                { value: 'osago', label: 'ОСАГО' },
                { value: 'mortgage', label: 'Ипотека' },
              ]}
            />
          </div>
        </div>
        {errorCommission && (
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="error_block">{errorCommission}</div>
            </div>
          </div>
        )}
        {commissionType.value !== 'hide' && (
          <LoadingBanner loadingFlag={loadingCompanies || loadingCommissions}>
            <div className="form-group">
              <SimpleNotification>Условия вознаграждения могут быть изменены, точный размер вознаграждения определяется в момент расчета стоимости полиса и указывается в выдаче результатов.</SimpleNotification>
            </div>
            <div className="form-group row">
              <div className="col-md-6 my-auto">
                <Slider
                  onAfterChange={this.handleChangeSlider}
                  min={-100}
                  max={0}
                  step={0.5}
                  onChange={(val) => this.setSliderValue(val)}
                  value={sliderValue}
                  handleRender={(node, handleProps) => (
                    <Tooltip
                      prefixCls="rc-slider-tooltip"
                      overlay={`${handleProps.value}%`}
                      placement="top"
                      visible={handleProps.dragging}
                    >
                      {node}
                    </Tooltip>
                  )}
                  defaultValue={0}
                />
              </div>
              {commissionType.value === 'quota' ? (
                <div className="col-md-3">
                  <div className="form_check_group form_check_group-circle">
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 10} name="commissionFastButton" value="10" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(10)} className="form_check_group__btn">-10%</span>
                    </label>
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 20} name="commissionFastButton" value="20" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(20)} className="form_check_group__btn">-20%</span>
                    </label>
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 30} name="commissionFastButton" value="30" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(30)} className="form_check_group__btn">-30%</span>
                    </label>
                  </div>
                </div>
              ) : (
                <div className="col-md-3">
                  <div className="form_check_group form_check_group-circle">
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 3} name="commissionFastButton" value="3" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(3)} className="form_check_group__btn">-3%</span>
                    </label>
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 5} name="commissionFastButton" value="5" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(5)} className="form_check_group__btn">-5%</span>
                    </label>
                    <label className="form_check_group__row">
                      <input type="radio" checked={selectedButtonCommission === 7} name="commissionFastButton" value="7" className="js-currency-radio" />
                      <span onClick={() => this.handleChangeSliderFastButton(7)} className="form_check_group__btn">-7%</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            {Object.keys(companyNames).map((company) => {
              if (companies[companyNames[company]]) {
                return (
                  <div key={company} className="form-group row">
                    <div className="col">
                      <div className="form-group row">
                        <div className="col-md-2"><p className="h5">{company}</p></div>
                        <div className="col-md-2"><p className="h5">Вознаграждение пользователя, %.</p></div>
                        <div className="col-md-2"><p className="h5">Ваше вознаграждение, %.</p></div>
                        {commissionType.value === 'quota' && (
                          <>
                            <div className="col-md-2"><p className="h5">Оставляете себе, %.</p></div>
                            <div className="col-md-2"><p className="h5">Квота от общего входящего вознаграждения, %.</p></div>
                          </>
                        )}
                      </div>
                      {product.value === 'osago' ? (
                        <>
                          {this.regionsBlock(company)}
                          {this.customRegionBlock(company)}
                        </>
                      ) : this.banksBlock(company)}
                    </div>
                  </div>
                );
              }
              return false;
            })}
          </LoadingBanner>
        )}
      </>
    );
  }
}

AgentsCommission.contextType = ModalMessagesContext;

export default AgentsCommission;
