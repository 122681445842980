import React from 'react';
import styled from 'styled-components';
import CustomIcon from '../CustomIcon';

const Text = styled.p`
  margin: 0 0 0 10px;
  color: #3d3d3b;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  box-shadow: none;
  border-radius: 10px;
  color: #f90;
  background-color: #fff5d4;
    ${(props) => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}` : '')};
    ${(props) => (props.offsetTop ? `margin-top: ${props.offsetTop}` : '')};

  ${(props) => (props.type === 'error' ? 'color: #ff4c00;' : '')}
  ${(props) => (props.type === 'error' ? 'background-color: #ffe2de;' : '')}
  ${(props) => (props.type === 'success' ? 'color: #0BD95D;' : '')}
  ${(props) => (props.type === 'success' ? 'background-color: #d6ffea;' : '')}
`;

const IconWrapper = styled.div`
  position: relative;
  top: auto;
  left: auto;
  width: 20px;
  height: 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
    flex-shrink: 0;
`;

export default function SimpleNotification(props) {
  const {
    children,
    type,
    offsetBottom,
    offsetTop,
  } = props;

  return (
    <Wrapper type={type} offsetBottom={offsetBottom} offsetTop={offsetTop}>
      <IconWrapper><CustomIcon pureSvg icon="exclamation" /></IconWrapper>
      <Text>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </Text>
    </Wrapper>
  );
}
