import styled from 'styled-components';
import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { shallow } from 'zustand/shallow';
import TableTbody from './TableTbody';
import TableThead from './TableThead';
import TableTheadTr from './TableTheadTr';
import TableTheadTh from './TableTheadTh';
import TableTbodyTr from './TableTbodyTr';
import TableTbodyTd from './TableTbodyTd';
import useUserInfoStore from '../../Stores/UserInfoStore';

export const PageWrapperContent = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 767px) {
    transform: translate(0) !important;
    position: absolute;
    bottom: 0;
    padding: 4px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(40, 40, 38, .83);
    max-height: calc(100vh - 114px);
  }
  button.result-popup {
    position: absolute;
    top: -48px;
    right: 0;
    z-index: 9;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: #3d3d3b;
    -webkit-transition: color .15s ease;
    transition: color .15s ease;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    @media (max-width: 767px) {
      transform: translate(0) !important;
      display: flex;
      width: 48px;
      height: 48px;
      position: absolute;
      top: -48px;
      right: 0;
      z-index: 2;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: #3d3d3b;
      -webkit-transition: color .15s ease;
      transition: color .15s ease;
      svg {
        width: 32px;
        height: 32px;
        color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#0bd95d')};
        &:hover {
          background-color: transparent;
          color: #e9e9e9;
        }
      }
    }
    }
  }
`;

export const PageWrapperTableContent = styled.div`
  width: 100%;
  @media (max-width: 1023px) {
    overflow: auto;
    scrollbar-color: #737373 transparent;
    scrollbar-width: thin;
  }
  @media (max-width: 767px) {
    max-height: calc(100vh - 122px);
  }
  table {
    margin: 8px 0 30px;
    width: 100%;
    border-collapse: collapse;
    @media (max-width: 1023px) {
      width: max-content;
      margin: 32px 24px 0 0;
      display: block;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 12px 0 0;
    }
    caption {
      padding: 0;
      color: initial;
      text-align: initial;
      caption-side: inherit;
      @media (max-width: 767px) {
        display: flex;
      }
      span.caption {
        display: block;
        padding: 0 0 14px 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: left;
        @media (max-width: 767px) {
          display: none;
        }
      }
      span.popup-title {
        display: none;
        width: 100%;
        margin: 0;
        padding: 0 16px 14px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }
`;

export default function TableResultAnswers(props) {
  const {
    disabled, width, resultsData, indexElement, onClick, headerText,
  } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <PageWrapperContent disabled={disabled} width={width} theme={theme}>
      <button className="result-popup" onClick={() => onClick(indexElement)}><XCircleIcon height={24} color="#a3a3a3" /></button>
      <PageWrapperTableContent>
        <table>
          <caption>
            <span className="caption">Интеграции</span>
            <span className="popup-title">{headerText}</span>
          </caption>
          <TableThead block="results">
            <TableTheadTr block="results">
              <TableTheadTh block="integration"><div><span>Интеграция</span></div></TableTheadTh>
              <TableTheadTh block="status"><div><span>Статус</span></div></TableTheadTh>
              <TableTheadTh block="sumsp"><div><span>Сумма СП / Ошибка</span></div></TableTheadTh>
              <TableTheadTh block="into-com"><div><span>Вх.КВ (заявленное)</span></div></TableTheadTh>
              <TableTheadTh block="out-com"><div><span>Вх.КВ (факт)</span></div></TableTheadTh>
              <TableTheadTh block="analize"><div><span>Анализ КВ</span></div></TableTheadTh>
              <TableTheadTh block="id"><div><span>ID расчета</span></div></TableTheadTh>
            </TableTheadTr>
          </TableThead>
          <TableTbody>
            {Object.entries(resultsData.integrations).map(([key, value]) => (value.status ? (
              <TableTbodyTr block="results">
                <TableTbodyTd block="integration">
                  <span>{key === 'direct' ? 'Прямая' : key}</span>
                </TableTbodyTd>
                <TableTbodyTd block="status">
                  <span>Статус:</span>
                  <span>Работает</span>
                </TableTbodyTd>
                <TableTbodyTd block="sumsp">
                  <span>Сумма СП / Ошибка:</span>
                  <span>{value.errorMessage ? value.errorMessage : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value.premium)}</span>
                </TableTbodyTd>
                <TableTbodyTd block="into-com">
                  <span>Вх. КВ (заявленная):</span>
                  <span>{value.polisOnlineCommission !== false ? `${value.polisOnlineCommission}%` : '-'}</span>
                </TableTbodyTd>
                <TableTbodyTd block="out-com">
                  <span>Вх. КВ (факт):</span>
                  <span>{value.innerCommission !== false ? `${value.innerCommission}%` : '-'}</span>
                </TableTbodyTd>
                <TableTbodyTd block="analize">
                  <span>Анализ КВ:</span>
                  {value.polisOnlineCommission < value.innerCommission
                        && value.polisOnlineCommission !== false
                        && value.innerCommission !== false
                        && <span style={{ color: '#0bd95d' }}>Выше заявленной</span>}
                  {value.polisOnlineCommission > value.innerCommission
                        && value.polisOnlineCommission !== false
                        && value.innerCommission !== false
                        && <span style={{ color: '#ff4d00' }}>Ниже заявленной</span>}
                  {value.polisOnlineCommission === value.innerCommission
                        && value.polisOnlineCommission !== false
                        && value.innerCommission !== false
                        && <span>Соответствует заявленной</span>}
                </TableTbodyTd>
                <TableTbodyTd block="id">
                  <span>ID расчета:</span>
                  <span>{value.integrationRequestId}</span>
                </TableTbodyTd>
              </TableTbodyTr>
            ) : null))}
          </TableTbody>
        </table>
      </PageWrapperTableContent>
    </PageWrapperContent>
  );
}
