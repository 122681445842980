import React, { Component } from 'react';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default class WidgetAnalyticsIntegrationGoogle extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      showMenu,
      googleId,
      changeWidgetAnalyticsIntegrationId,
      updateWidgetWithInfo,
      widget,
    } = this.props;

    const marginInLine = { marginLeft: '10px' };

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку систем аналитики</LinkBreadcrumbs>
            </div>
          </div>
          <div className="card">
            <div className="form-group row">
              <div className="col-lg-12">
                <FormNotificationColorLine>
                  <p>Для корректной работы необходимо указать идентификатор отслеживания и добавить следующие цели в настройках вашего аккаунта Google Аналитики:</p>
                  <ul>
                    {widget.policy_type.code === 'osago' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> phoneValidate</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> createQuote</li>
                        <li><strong>Название:</strong> Автомобиль заполнен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> carFilled</li>
                        <li><strong>Название:</strong> Страхователь заполнен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> insuranceFilled</li>
                        <li><strong>Название:</strong> Собственник заполнен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> ownerFilled</li>
                        <li><strong>Название:</strong> Водитель заполнен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> driverFilled</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> getResult</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> chooseInsurance</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> buyPolicy</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetOsago, <strong style={marginInLine}>Действие:</strong> paymentPolicy</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'mortgage' ? (
                      <>
                        <li><strong>Название:</strong> Телефон прошел валидацию, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> phoneValidate</li>
                        <li><strong>Название:</strong> Старт расчета, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> createQuote</li>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> getResult</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> chooseInsurance</li>
                        <li><strong>Название:</strong> Попытка покупки, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> buyPolicy</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetMortgage, <strong style={marginInLine}>Действие:</strong> paymentPolicy</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'greenCard' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetGreenCard, <strong style={marginInLine}>Действие:</strong> getResult</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetGreenCard, <strong style={marginInLine}>Действие:</strong> chooseInsurance</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetGreenCard, <strong style={marginInLine}>Действие:</strong> buyPolicy</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetGreenCard, <strong style={marginInLine}>Действие:</strong> paymentPolicy</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dkp' ? (
                      <>
                        <li><strong>Название:</strong> Переход к оформлению ДКП, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDKP, <strong style={marginInLine}>Действие:</strong> checkDocumentData</li>
                        <li><strong>Название:</strong> Оформление ДКП, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDKP, <strong style={marginInLine}>Действие:</strong> getDocument</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'kbm' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки КБМ, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetKbm, <strong style={marginInLine}>Действие:</strong> getKbm</li>
                        <li><strong>Название:</strong> Отправлен запрос на восстановление КБМ, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetKbm, <strong style={marginInLine}>Действие:</strong> refreshKbm</li>
                        <li><strong>Название:</strong> Переход к оформлению ОСАГО, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetKbm, <strong style={marginInLine}>Действие:</strong> getOsago</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'property' ? (
                      <>
                        <li><strong>Название:</strong> Получен результат проверки недвижимости, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetProperty, <strong style={marginInLine}>Действие:</strong> getProperty</li>
                      </>
                    ) : null}
                    {widget.policy_type.code === 'dmsStudent' ? (
                      <>
                        <li><strong>Название:</strong> Получены результаты, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDmsStudent, <strong style={marginInLine}>Действие:</strong> getResult</li>
                        <li><strong>Название:</strong> Выбор полиса, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDmsStudent, <strong style={marginInLine}>Действие:</strong> chooseInsurance</li>
                        <li><strong>Название:</strong> Отправка заявки, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDmsStudent, <strong style={marginInLine}>Действие:</strong> buyPolicy</li>
                        <li><strong>Название:</strong> Полис оплачен, <strong style={marginInLine}>Тип:</strong> Событие, <strong style={marginInLine}>Категория:</strong> WidgetDmsStudent, <strong style={marginInLine}>Действие:</strong> paymentPolicy</li>
                      </>
                    ) : null}
                  </ul>
                </FormNotificationColorLine>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <label htmlFor="widgetGoogleId">Идентификатор отслеживания в Google Аналитике</label>
                <input value={googleId} onChange={(e) => changeWidgetAnalyticsIntegrationId(e, 'googleId')} onBlur={updateWidgetWithInfo} name="widgetGoogleId" type="text" className="form-control" id="widgetGoogleId"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
