import React from 'react';
import {
  faMobileAlt, faSlidersH, faFile, faCommentDots,
} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CheckPropertyInfo(props) {
  const { setActiveTab, history } = props;
  return (
    <>
      <h1>Проверка недвижимости за несколько секунд </h1>
      <p>Уникальная технология моментальной проверки недвижимости без выписки ЕГРН с полным соблюдением закона о персональных данных и реальной пользой. Доступно через веб-интерфейс и API </p>
      <ul className="events-grid events-grid--advantages">
        <li className="events-grid__event">
          <div className="event">
            <div className="event__achieve event__icon">
              <div className="event__achieve-title"><FontAwesomeIcon icon={faMobileAlt} className="fa-fw"/></div>
            </div>
            <h3 className="event__achieve-head">Эксклюзивно</h3>
            <p className="event__achieve-text">Скрытые и активные ограничения: 7 видов негативных и 14 эксплуатационных.</p>
          </div>
        </li>
        <li className="events-grid__event">
          <div className="event">
            <div className="event__achieve event__icon">
              <div className="event__achieve-title"><FontAwesomeIcon icon={faSlidersH} className="fa-fw"/></div>
            </div>
            <h3 className="event__achieve-head">Моментально</h3>
            <p className="event__achieve-text">Полные данные из Росреестра с 1998 года в режиме реального времени.</p>
          </div>
        </li>
        <li className="events-grid__event">
          <div className="event">
            <div className="event__achieve event__icon">
              <div className="event__achieve-title"><FontAwesomeIcon icon={faFile} className="fa-fw"/></div>
            </div>
            <h3 className="event__achieve-head">Информативно</h3>
            <p className="event__achieve-text">Оценка благонадежности продавца по 14 пунктам.</p>
          </div>
        </li>
        <li className="events-grid__event">
          <div className="event">
            <div className="event__achieve event__icon">
              <div className="event__achieve-title"><FontAwesomeIcon icon={faCommentDots} className="fa-fw"/></div>
            </div>
            <h3 className="event__achieve-head">Полезно</h3>
            <p className="event__achieve-text">Отчёт содержит понятные рекомендации для безопасной сделки по конкретному объекту проверки.</p>
          </div>
        </li>
      </ul>
      <div className="form-group row mt-3">
        <ul>
          <li><b>Аресты</b> - объект мог фигурировать по уголовному делу, возможно, имелись крупные финансовые претензии к собственникам;</li>
          <li><b>Запрещения</b> - могут указывать на существенные претензии к законности предыдущих сделок, споры о праве собственности или пользования;</li>
          <li><b>Погашенные ипотеки</b> - залоговая история объекта, число вероятных кредиторов, характеристика финансовой активности владельцев;</li>
          <li><b>Рента</b> - необходимость учёта (исключения) вероятных претензий от родственников рентополучателя;</li>
          <li><b>Аренда</b> - режим использования объекта, вероятность наличия прав пользования третьих лиц.</li>
          <li><b>Права</b> - покажем как часто объект менял владельцев и объясним как с этим работать</li>
          <li><b>Подробности</b> - кадастровая стоимость, тип объекта, площадь, этаж;</li>
          <li><b>Рекомендации</b> - составим чек-лист, на что нужно обратить внимание, что спросить, какие документы проверить перед покупкой.</li>
        </ul>
      </div>
      <button type="button" className="btn btn-success mr-3 mb-1 mb-md-0" onClick={() => { history.push('/check_property?activeTab=1'); setActiveTab('1'); }}>Создать запрос</button>
    </>
  );
}
