import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import ReactTable from 'react-table-6';
import { reactLocalStorage } from 'reactjs-localstorage';
import matchSorter from 'match-sorter';
import LoadingSpinner from "../Layout/LoadingSpinner";
import { customFetch, setTableSorting, setTableSettings } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

class AdminWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      widget: {
        apiKey: '',
        name: '',
        id: 0,
        widthType: {
          value: '1',
        },
        settings: {
          full_width: true,
          width: '',
          widthType: { value: '1', label: 'px' },
          main_color: '#fff',
          button_color: '#fff',
          button_hover_color: '#fff',
        },
        font: {},
        help_texts: {
          base: '',
          steps: {
            one: '',
            two: '',
            three: '',
            four: '',
            five: '',
          },
        },
      },
      urls: [],
      loadingUrlsList: true,
    };
  }

  componentDidMount() {
    document.title = 'Редактирование виджета';
    this.getSettings();
    this.getUrls();
  }

  getUrls = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/get-url-list?widgetId=${id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((widgetUrlList) => {
        const urls = [];
        widgetUrlList.forEach((element) => {
          urls.push({ created: element.created_at, updated: element.updated_at, host: element.host });
        });
        this.setState({
          urls,
          loadingUrlsList: false,
        });
      });
  }

  getSettings = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((widget) => {
        widget.settings.widthType = {};
        widget.settings.widthType.value = widget.settings.width_type_id;
        this.setState({ widget });
      });
  };

  updateWidget = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;
    const { widget } = this.state;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${id}`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ widget }),
    })
      .then((response) => response.json());
  };

  changeInput = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          [id]: parseInt(value.replace(/_/g, ''), 10),
        },
      },
    }));
  };

  validInput = (e) => {
    const { showModalInfo } = this.context;
    const { id } = e.target;
    let { value } = e.target;
    value = value.replace(/_/g, '');
    if (value === '' || value === '0') {
      this.setState((prevState) => ({
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            [id]: 1,
          },
        },
      }), () => {
        this.updateWidget();
        showModalInfo('Изменения сохранены');
      });
    } else if (parseInt(value, 10) > 255 && (id === 'avtocod_timer' || (id === 'sms_timer'))) {
      this.setState((prevState) => ({
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            [id]: 255,
          },
        },
      }), () => {
        this.updateWidget();
        showModalInfo('Изменения сохранены');
      });
    } else {
      this.updateWidget();
      showModalInfo('Изменения сохранены');
    }
  };

  render() {
    const {
      widget,
      urls,
      loadingUrlsList,
    } = this.state;

    const { history } = this.props;

    const columns = [{
      Header: 'Адрес страницы',
      accessor: 'host',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['host'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('widgetUrlListSettings').host,
      show: reactLocalStorage.getObject('widgetUrlListSettings').show_host !== false,
    }, {
      Header: 'Дата первой загрузки',
      accessor: 'created',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['created'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('widgetUrlListSettings').created,
      show: reactLocalStorage.getObject('widgetUrlListSettings').show_created !== false,
    }, {
      Header: 'Дата последней загрузки',
      accessor: 'updated',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['updated'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('widgetUrlListSettings').updated,
      show: reactLocalStorage.getObject('widgetUrlListSettings').show_updated !== false,
    },
    ];

    let defaultSortingField = 'calculated_date';
    let defaultSortingOrder = 'true';
    if (reactLocalStorage.getObject('widgetUrlListSettings').defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject('widgetUrlListSettings').defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject('widgetUrlListSettings').defaultSortingOrder;
    }

    return (
      <>
        <div className="position-relative">
          <div className="row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); history.push('/admin?activeTab=8'); }}>К списку виджетов</LinkBreadcrumbs>
            </div>
          </div>
          <h1 className="page_title d-inline-block mr-3">Редактирование виджета</h1>
          <h2>Вы можете поменять параметры виджета</h2>
          <div className="card mb-4">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-12 col-12">
                  <label htmlFor="avtocod_limit">Автокод лимит</label>
                  <InputMask
                    onBlur={this.validInput}
                    className="form-control"
                    onChange={this.changeInput}
                    value={widget.settings.avtocod_limit}
                    name="avtocod_limit"
                    id="avtocod_limit"
                    mask="999"
                    maskChar={null}
                  />
                </div>
                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-12 col-12">
                  <label htmlFor="avtocod_timer">Автокод таймер</label>
                  <InputMask
                    onBlur={this.validInput}
                    className="form-control"
                    onChange={this.changeInput}
                    value={widget.settings.avtocod_timer}
                    name="avtocod_timer"
                    id="avtocod_timer"
                    mask="999"
                    maskChar={null}
                  />
                </div>
              </div>
              <p />
              <div className="form-group row">
                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-12 col-12">
                  <label htmlFor="sms_limit">Смс лимит</label>
                  <InputMask
                    onBlur={this.validInput}
                    className="form-control"
                    onChange={this.changeInput}
                    value={widget.settings.sms_limit}
                    name="sms_limit"
                    id="sms_limit"
                    mask="999"
                    maskChar={null}
                  />
                </div>
                <div className="col-lg-3 col-xl-2 col-md-4 col-sm-12 col-12">
                  <label htmlFor="sms_timer">Смс таймер</label>
                  <InputMask
                    onBlur={this.validInput}
                    className="form-control"
                    onChange={this.changeInput}
                    value={widget.settings.sms_timer}
                    name="sms_timer"
                    id="sms_timer"
                    mask="999"
                    maskChar={null}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h2>Список страниц с установленным виджетом</h2>
          <ReactTable
            getTdProps={(state, rowInfo, column) => {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0 || e.button === 1) {
                    Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: `http://${rowInfo.row._original.host}`,
                    }).click();
                  }
                },
              };
            }}
            ref={(r) => this.reactTable = r}
            defaultFiltered={this.state.filtered}
            className="table"
            data={urls}
            columns={columns}
            defaultSorted={[
              {
                id: defaultSortingField,
                desc: defaultSortingOrder,
              },
            ]}
            previousText="Назад"
            nextText="Вперед"
            loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
            noDataText="Черновики не найдены"
            pageText="Страница"
            ofText="Из"
            rowsText="строк"
            loading={loadingUrlsList}
            defaultPageSize={50}
            filterable
            minRows={1}
            onSortedChange={(newSort) => {
              setTableSorting(newSort, 'widgetUrlListSettings');
            }}
            onResizedChange={(newResized) => {
              setTableSettings(newResized, 'widgetUrlListSettings');
            }}
          />
        </div>
      </>
    );
  }
}

AdminWidget.contextType = ModalMessagesContext;

export default AdminWidget;
