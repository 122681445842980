import styled, { css } from 'styled-components';
import React from 'react';

export const TableTheadAnswersTh = styled.th`
  padding: 0 16px;
  &:first-of-type {
    padding-left: 24px;
  }
  ${(props) => props.block === 'name' && css`
    width: 26.5%;
    @media (max-width: 1279px) {
      width: 21%;
    }
    @media (max-width: 1023px) {
      width: 46.5%;
    }
  `}

  ${(props) => props.block === 'value' && css`
    width: 14.5%;
    @media (max-width: 1279px) {
      width: 16%;
    }
    @media (max-width: 1023px) {
      width: 27.5%;
      padding-left: 0;
    }
  `}

  ${(props) => props.block === 'sum' && css`
    width: 26.5%;
    @media (max-width: 1279px) {
      width: 21%;
    }
    @media (max-width: 1023px) {
      width: 46.5%;
    }
  `}

  ${(props) => props.block === 'integration' && css`
    width: 10.5%;
    &:first-of-type {
      padding-left: 16px;
    }
    @media (max-width: 1023px) {
      width: 134px;
    }
  `}

  ${(props) => props.block === 'status' && css`
    width: 11.5%;
    @media (max-width: 1023px) {
      width: 145px;
    }
  `}

  ${(props) => props.block === 'sumsp' && css`
    width: 23%;
    @media (max-width: 1023px) {
      width: 417px;
    }
  `}

  ${(props) => props.block === 'id' && css`
    width: 10%;
    @media (max-width: 1023px) {
      width: 417px;
    }
  `}
  
  ${(props) => props.block === 'into-com' && css`
    width: 13%;
    @media (max-width: 1023px) {
      width: 165px;
    }
  `}

  ${(props) => props.block === 'out-com' && css`
    width: 10.5%;
    @media (max-width: 1023px) {
      width: 130px;
    }
  `}

  ${(props) => props.block === 'analize' && css`
    width: 21.5%;
    padding-left: 24px;
    @media (max-width: 1023px) {
      width: 295px;
    }
  `}
  
  div {
    padding: 10px 0;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-transition: color .15s ease, background-color .15s ease;
    transition: color .15s ease, background-color .15s ease;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      -webkit-transition: color .15s ease;
      transition: color .15s ease;
    }
  }
`;

export default function TableTheadTh(props) {
  const {
    children, block,
  } = props;

  return (
    <TableTheadAnswersTh block={block}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTheadAnswersTh>
  );
}
