import React from 'react';
import InputMask from 'react-input-mask';

function CommissionInput(props) {
  const {
    value, region, company, integration, disabled,
  } = props;
  return (
    integration
      ? (<InputMask value={value} maskChar="" disabled={disabled} onChange={(e) => { props.handleInputCommission(integration, company, region, e); }} className="form-control" formatChars={{ 9: '[0-9 .]' }} mask="999999" placeholder="0%" />)
      : (<InputMask value={value} maskChar="" disabled={disabled} onChange={(e) => { props.handleInputCommission(company, region, e); }} className="form-control" formatChars={{ 9: '[0-9 .]' }} mask="999999" placeholder="0%" />)
  );
}
function areEqual(prevProps, nextProps) {
  return (prevProps.value === nextProps.value && prevProps.disabled === nextProps.disabled);
}
export default React.memo(CommissionInput, areEqual);
