import React, {
  useEffect, useState, useRef, useMemo, useContext,
} from 'react';
import queryString from 'query-string';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import InsuranceProgram from './InsuranceProgram';
import InsuranceProgramsTable from './InsuranceProgramsTable';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';

export default function InsuranceProgramFactory(props) {
  const { history, tab } = props;
  const [program, setProgram] = useState({});
  const [insurancesList, setInsurancesList] = useState([]);
  const [insuranceTypesList, setInsuranceTypesList] = useState([]);
  const [insuranceProductList, setInsuranceProductList] = useState([]);
  const [programsList, setProgramsList] = useState([]);
  const [paramsLoaded, setParamsLoaded] = useState(false);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const customStyles = useMemo(() => customSelectStyle());

  const tableRef = useRef();

  const setNewProgram = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setProgram(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const openModalTableCustomized = () => {
    tableRef.current.openModalTableCustomized(true);
  };

  const loadInsurancesList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies?type=list`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          const insurancesArray = Object.entries(data).map(([key, value]) => ({ ...value, code: key }));
          setInsurancesList(insurancesArray);
        } else {
          showModalInfo(data.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const loadInsuranceTypeList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/products`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          const insuranceTypesOptions = data.filter((option) => option.code !== 'dkp' && option.code !== 'kbm').map((option) => ({ label: option.name, value: option.code }));
          setInsuranceTypesList(insuranceTypesOptions);
        } else {
          showModalInfo(data.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const loadInsuranceProductList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-products`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          setInsuranceProductList(data);
        } else {
          showModalInfo(data.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const clearQueryParams = () => {
    const urlParams = queryString.parse(window.location.search);
    let queryParams = '?';
    Object.entries(urlParams).forEach(([key, value]) => {
      if (key !== 'activeProgram' && key !== 'activeProgramTab') {
        queryParams = `${queryParams}&${key}=${value}`;
      }
    });
    history.push({ search: queryParams });
  };

  const addQueryParams = (urlParams) => {
    let queryParams = '?';
    Object.entries(urlParams).forEach(([key, value]) => {
      if (key !== 'activeProgram' && key !== 'activeProgramTab' && key !== '') {
        if (queryParams === '?') {
          queryParams = `${queryParams}${key}=${value}`;
        } else {
          queryParams = `${queryParams}&${key}=${value}`;
        }
      }
    });
    queryParams = `${queryParams}&activeProgram=${program.id}`;
    if ('activeProgramTab' in urlParams) {
      queryParams = `${queryParams}&activeProgramTab=${urlParams.activeProgramTab}`;
    } else {
      queryParams = `${queryParams}&activeProgramTab=1`;
    }
    history.push({ search: queryParams });
  };

  useEffect(() => {
    loadInsurancesList();
    loadInsuranceTypeList();
    loadInsuranceProductList();
  }, []);

  useEffect(() => {
    if (!paramsLoaded && insurancesList.length && insuranceTypesList.length && programsList.length) {
      const urlParams = queryString.parse(window.location.search);
      if ('activeProgram' in urlParams) {
        setProgram(programsList[programsList.findIndex((p) => p.id === parseInt(urlParams.activeProgram, 10))]);
      }
      setParamsLoaded(true);
    }
  }, [programsList, insurancesList, insuranceTypesList]);

  useEffect(() => {
    if (!program.id && programsList.length) {
      clearQueryParams();
    }
  }, [program]);

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (program.id && (!('activeProgram' in urlParams) || (parseInt(urlParams.activeProgram, 10) !== program.id))) {
      addQueryParams(urlParams);
    }
  }, [program.id]);

  return (
    <>
      {!program.id ? (
        <div className="position-relative">
          <div className="row">
            <div className="col-lg-12">
              <button type="button" className="btn btn-success mr-3" onClick={setNewProgram}>Создать новую программу страхования</button>
              <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
                <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                Настроить таблицу
              </Button>
            </div>
          </div>
          <br />
          <InsuranceProgramsTable
            setProgram={setProgram}
            ref={tableRef}
            history={history}
            tab={tab}
            programsList={programsList}
            setProgramsList={setProgramsList}
          />
        </div>
      ) : (
        <InsuranceProgram
          insurancesList={insurancesList}
          insuranceTypesList={insuranceTypesList}
          insuranceProductList={insuranceProductList}
          program={program}
          setProgram={setProgram}
          customStyles={customStyles}
          history={history}
        />
      )}
    </>
  );
}
