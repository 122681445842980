import React from 'react';

export default function WidgetCallbackSettings(props) {
  const { widget, toggleFlag } = props;

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-6 col-xl-5">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input onChange={toggleFlag} checked={widget.settings.send_callback_email} name="send_callback_email" className="form-check-input checkbox-styled" type="checkbox" id="send_callback_email" />
            <label className="form-check-label" htmlFor="send_callback_email">Отправлять callback email</label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-6 col-xl-5">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input onChange={toggleFlag} checked={widget.settings.send_callback_sms} name="send_callback_sms" className="form-check-input checkbox-styled" type="checkbox" id="send_callback_sms" />
            <label className="form-check-label" htmlFor="send_callback_sms">Отправлять callback sms</label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-6 col-xl-5">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input onChange={toggleFlag} checked={widget.settings.neutral_sms_sender} name="neutral_sms_sender" className="form-check-input checkbox-styled" type="checkbox" id="neutral_sms_sender" />
            <label className="form-check-label" htmlFor="neutral_sms_sender">Нейтральный отправитель sms</label>
          </div>
        </div>
      </div>
    </>
  );
}
