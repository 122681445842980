import React from 'react';
import styled from 'styled-components';
import SidebarItem from './SidebarItem';
import SocialLinks from './SocialLinks';
import InterfaceTourSidebar from '../../InterfaceTours/InterfaceTourSidebar';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

const Wrapper = styled.ul`
    list-style: none;
    margin: 0 0 48px;
    width: 100%;
    padding: 0 0 30px;
`;

const BottomBlocksContainer = styled.div`
  margin: auto 13px 16px;
`;

export default function SidebarList(props) {
  const {
    sidebarActive, getTourList, setTour, toggleSidebar, sidebarIsFull,
  } = props;

  const { userInfo, theme } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <Wrapper>
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="sector"
        text="Главная"
        to="/dashboard"
        customClassName="tour_2_dt_step_4"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="documentPlus"
        text="Оформить"
        to="/products"
        customClassName="test_tour_dt_step_1 tour_2_dt_step_5"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="drafts"
        text="Черновики"
        to="/drafts"
        customClassName="tour_2_dt_step_6"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="policies"
        text="Полисы"
        to="/policies"
        customClassName="tour_2_dt_step_7"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="leads"
        text="Лиды"
        to="/requests-list"
        customClassName="tour_2_dt_step_11"
        toggleSidebar={toggleSidebar}
      />
      {userInfo.hide_commission === 0 && (
        <SidebarItem
          sidebarActive={sidebarActive}
          sidebarIsFull={sidebarIsFull}
          icon="statistic"
          text="Статистика"
          to="/reports"
          customClassName="tour_2_dt_step_9"
          toggleSidebar={toggleSidebar}
        />
      )}
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="userGroup"
        text="Пользователи"
        to="/agents"
        customClassName="tour_2_dt_step_8"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="widgets"
        text="Виджеты"
        to="/widgets"
        customClassName="tour_2_dt_step_12"
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="referal"
        text="Реф. программа"
        to="/referral"
        customClassName="tour_2_dt_step_10"
        toggleSidebar={toggleSidebar}
      />
      {(userInfo.role === 'superadmin' || userInfo.role === 'admin') && (
        <SidebarItem
          sidebarActive={sidebarActive}
          sidebarIsFull={sidebarIsFull}
          icon="briefcase"
          text="Администрирование"
          to="/admin"
          toggleSidebar={toggleSidebar}
        />
      )}
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="question"
        text="FAQ-база знаний"
        to="/faq_knowledge"
        toggleSidebar={toggleSidebar}
      />
      {sidebarActive || sidebarIsFull ? (
        <BottomBlocksContainer>
          {userInfo.show_interface_tours && userInfo.interface_tours_percent < 100 ? (
            <InterfaceTourSidebar getTourList={getTourList} setTour={setTour} />
          ) : null}
          {theme.link_to_telegram ? <SocialLinks link={theme.link_to_telegram} /> : null}
        </BottomBlocksContainer>
      ) : null}
    </Wrapper>
  );
}
