import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

export default function DraftDemoData(props) {
  const { insuranceTypeList, history } = props;
  const [selectedInsuranceType, setSelectedInsuranceType] = useState({});
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState([]);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const goToDemoDraft = () => {
    const selectedTypeIndex = insuranceTypeList.findIndex((option) => option.code === selectedInsuranceType.value);
    if (insuranceTypeList[selectedTypeIndex].draft) {
      history.push(`/${insuranceTypeList[selectedTypeIndex].code}/${insuranceTypeList[selectedTypeIndex].draft}?adminEdit=1`);
    } else {
      showModalInfo('Для данного типа страхования не создан демо-черновик', 'error');
    }
  };

  useEffect(() => {
    if (insuranceTypeList.length) {
      const options = insuranceTypeList.map((option) => ({ value: option.code, label: option.name }));
      setInsuranceTypeOptions(options);
      setSelectedInsuranceType(options[0]);
    }
  }, [insuranceTypeList]);

  return (
    <>
      <h2>Редактирование демо-черновика</h2>
      {insuranceTypeOptions.length ? (
        <div className="position-relative">
          <div className="card mb-4">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="carYear">Тип</label>
                  <Select
                    classNamePrefix="react-select"
                    inputId="insuranceType"
                    name="insuranceType"
                    placeholder="Тип"
                    noOptionsMessage={() => 'Не найдено'}
                    value={selectedInsuranceType}
                    styles={customStyles}
                    onChange={(e) => { setSelectedInsuranceType(e); }}
                    options={insuranceTypeOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <button type="button" className="btn btn-success mr-3 mb-3" onClick={goToDemoDraft}>Редактировать</button>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
      )}
    </>
  );
}
