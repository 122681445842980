import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format, parse, parseISO } from 'date-fns';
import ReactExport from 'react-data-export';
import matchSorter from 'match-sorter';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import queryString from 'query-string';
import Sticky from 'react-sticky-el';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import Table from '../Layout/Table';
import {
  customFetch,
  onContextMenuClick,
  onTableCellClick,
  setTableSettings7,
  downloadReport,
  setTableSorting,
  sortConversion,
  onFilteredChangeCustom,
  customSelectStyle,
  toggleSelectAll,
  toggleRow,
  toggleSelectFiltered,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();

class DraftsTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this.reactTableRef = React.createRef();
    this.state = {
      startDate: null,
      endDate: null,
      filtered: [],
      activeFilterTab: '1',
      policies: [],
      columns: [],
      loading: true,
      excelcolumns: [],
      selectFiltered: 0,
      groupEditProgress: false,
      selected: [],
      selectAll: [],
      dataSet: [],
      editOption: { value: '', label: '' },
      openModalTableCustomized: false,
      defaultSortingField: reactLocalStorage.getObject('draftsSettings').defaultSortingField
        ? reactLocalStorage.getObject('draftsSettings').defaultSortingField
        : 'updated_at',
      defaultSortingOrder: reactLocalStorage.getObject('draftsSettings').defaultSortingField
        ? reactLocalStorage.getObject('draftsSettings').defaultSortingOrder
        : 'true',
    };
  }

  componentDidMount() {
    const { location, tab } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let dateFrom = format(parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let dateTo = format(parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        if (filter === 'filter_date') {
          const startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
          const endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
          dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
          dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
          this.setState({
            startDate,
            endDate,
          });
        }
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    this.downloadDrafts(dateFrom, dateTo);
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { userInfo } = this.props;
    if (openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) {
      this.updateTableColumns();
    }
    if (userInfo.role !== prevProps.userInfo.role || userInfo.full_time_employee !== prevProps.userInfo.full_time_employee) {
      this.updateTableColumns();
    }
  }

  componentWillUnmount() {
    abortController.abort();
  }

  setSelected = (selected, selectAll, selectFiltered) => {
    this.setState({
      selected,
      selectAll,
      selectFiltered,
    }, () => {
      this.updateTableColumns();
    });
  }

  updateTableColumns = () => {
    const { show, userInfo } = this.props;
    const { selectFiltered, selected, policies } = this.state;
    const columns = [{
      disableFilters: true,
      disableSortBy: true,
      id: 'select_cell',
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selected[row.original.id]}
          onChange={() => toggleRow(row.original.id, selected, policies, this.setSelected, row.original.type_insurance)}
        />
      ),
      Header: () => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectFiltered === 1}
          ref={(input) => {
            if (input) {
              input.indeterminate = selectFiltered === 2;
            }
          }}
          onChange={() => toggleSelectFiltered(selectFiltered, policies, this.reactTableRef.current.getTableData(), this.setSelected, 'type_insurance')}
        />
      ),
      width: 40,
      show: userInfo.full_time_employee === 1,
    },
    {
      Header: '№',
      accessor: 'id',
      minWidth: 70,
      width: reactLocalStorage.getObject('draftsSettings').id,
      show: reactLocalStorage.getObject('draftsSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      id: 'status',
      Filter: 'selectColumnFilter',
      accessor: (d) => {
        switch (d.status) {
          case 'processing':
            return 'Ожидает оплаты';
          case 'agreement':
            return 'На согласовании';
          default:
            return 'Новый';
        }
      },
      filterable: false,
      width: reactLocalStorage.getObject('draftsSettings').status,
      show: reactLocalStorage.getObject('draftsSettings').show_status !== false,
      showCustomized: true,
    }, {
      Header: 'Продукт страхования',
      id: 'policy_type',
      accessor: (d) => {
        let typeName;
        switch (d.type_insurance) {
          case 'osago':
          case 'greenCard':
          case 'covid':
          case 'dmsStudent':
            typeName = d.policy_type.name;
            break;
          case 'mortgage':
            switch (d.product_insurance) {
              case 'mortgage':
                typeName = 'Ипотека.Имущество';
                break;
              case 'mortgageLife':
                typeName = 'Ипотека.Жизнь';
                break;
              case 'mortgageComplex':
                typeName = 'Ипотека.Комплексное страхование';
                break;
              default:
                typeName = d.policy_type.name;
                break;
            }
            break;
          default:
            typeName = d.policy_type.name;
            break;
        }
        return typeName;
      },
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['policy_type'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('draftsSettings').policy_type,
      show: reactLocalStorage.getObject('draftsSettings').show_policy_type !== false,
      showCustomized: true,
    }, {
      Header: 'Страхователь',
      id: 'insuredName',
      accessor: (d) => (d.insuredName ? d.insuredName.replaceAll('null', '') : ''),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insuredName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      width: reactLocalStorage.getObject('draftsSettings').insuredName,
      show: reactLocalStorage.getObject('draftsSettings').show_insuredName !== false,
      showCustomized: true,
    }, {
      Header: 'Объект страхования',
      accessor: 'insuredObject',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insuredObject'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('draftsSettings').insuredObject,
      show: reactLocalStorage.getObject('draftsSettings').show_insuredObject !== false,
      showCustomized: true,
    }, {
      Header: 'Регион',
      id: 'region',
      accessor: (d) => (d.region && d.region !== 'null' ? d.region : ''),
      width: reactLocalStorage.getObject('draftsSettings').region,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      show: (reactLocalStorage.getObject('draftsSettings').show_region !== false),
      showCustomized: true,
    }, {
      Header: 'Дата создания',
      width: reactLocalStorage.getObject('draftsSettings').created_at,
      accessor: 'created_at',
      filterable: false,
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy, HH:mm:ss'),
      show: reactLocalStorage.getObject('draftsSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Дата изменения',
      accessor: 'updated_at',
      filterable: false,
      width: reactLocalStorage.getObject('draftsSettings').updated_at,
      Cell: ({ row }) => format(parseISO(row.original.updated_at), 'dd.MM.yyyy, HH:mm:ss'),
      show: reactLocalStorage.getObject('draftsSettings').show_updated_at !== false,
      showCustomized: true,
    }, {
      Header: 'Пользователь 1-го уровня',
      id: 'agent_first_level',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent_first_level'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('draftsSettings').agent_first_level,
      accessor: (d) => d.agent_first_level,
      show: show === 'all' && reactLocalStorage.getObject('draftsSettings').show_agent_first_level !== false,
      showCustomized: show === 'all',
    }, {
      Header: 'Пользователь',
      id: 'agent_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent_name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('draftsSettings').agent_name,
      accessor: (d) => `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}`,
      show: (show === 'all' || show === 'subagents' || show === 'full_time_employee') && reactLocalStorage.getObject('draftsSettings').show_agent_name !== false,
      showCustomized: show === 'all' || show === 'subagents' || show === 'full_time_employee',
    }, {
      Header: 'Партнер',
      width: reactLocalStorage.getObject('draftsSettings').partnerName,
      id: 'partnerName',
      accessor: (d) => (d.agent.partner ? `${d.agent.partner.last_name} ${d.agent.partner.first_name} ${d.agent.partner.middle_name}` : ''),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['partnerName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show === 'all' || show === 'subagents' || show === 'full_time_employee') && reactLocalStorage.getObject('draftsSettings').show_partnerName !== false,
      showCustomized: show === 'all' || show === 'subagents' || show === 'full_time_employee',
    }, {
      Header: 'Azure',
      width: reactLocalStorage.getObject('draftsSettings').azure_conversion_rate,
      filterable: false,
      id: 'azure_conversion_rate',
      accessor: (d) => {
        if (d.azure_conversion_rate) {
          if (d.azure_conversion_rate.all > 0) {
            const percent = (parseFloat(d.azure_conversion_rate.success) / parseFloat(d.azure_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.azure_conversion_rate.success}/${d.azure_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_azure_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'УП',
      width: reactLocalStorage.getObject('draftsSettings').smart_conversion_rate,
      filterable: false,
      id: 'smart_conversion_rate',
      accessor: (d) => {
        if (d.smart_conversion_rate) {
          if (d.smart_conversion_rate.all > 0) {
            const percent = (parseFloat(d.smart_conversion_rate.success) / parseFloat(d.smart_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.smart_conversion_rate.success}/${d.smart_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_smart_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Сравни',
      width: reactLocalStorage.getObject('draftsSettings').sravni_conversion_rate,
      filterable: false,
      id: 'sravni_conversion_rate',
      accessor: (d) => {
        if (d.sravni_conversion_rate) {
          if (d.sravni_conversion_rate.all > 0) {
            const percent = (parseFloat(d.sravni_conversion_rate.success) / parseFloat(d.sravni_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.sravni_conversion_rate.success}/${d.sravni_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_sravni_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Ингуру',
      width: reactLocalStorage.getObject('draftsSettings').inguru_conversion_rate,
      filterable: false,
      id: 'inguru_conversion_rate',
      accessor: (d) => {
        if (d.inguru_conversion_rate) {
          if (d.inguru_conversion_rate.all > 0) {
            const percent = (parseFloat(d.inguru_conversion_rate.success) / parseFloat(d.inguru_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.inguru_conversion_rate.success}/${d.inguru_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_inguru_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Инсапп',
      width: reactLocalStorage.getObject('draftsSettings').insapp_conversion_rate,
      filterable: false,
      id: 'insapp_conversion_rate',
      accessor: (d) => {
        if (d.insapp_conversion_rate) {
          if (d.insapp_conversion_rate.all > 0) {
            const percent = (parseFloat(d.insapp_conversion_rate.success) / parseFloat(d.insapp_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.insapp_conversion_rate.success}/${d.insapp_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_insapp_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Прямые интеграции',
      width: reactLocalStorage.getObject('draftsSettings').direct_conversion_rate,
      filterable: false,
      id: 'direct_conversion_rate',
      accessor: (d) => {
        if (d.direct_conversion_rate) {
          if (d.direct_conversion_rate.all > 0) {
            const percent = (parseFloat(d.direct_conversion_rate.success) / parseFloat(d.direct_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.direct_conversion_rate.success}/${d.direct_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_direct_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Общая',
      width: reactLocalStorage.getObject('draftsSettings').total_conversion_rate,
      filterable: false,
      id: 'total_conversion_rate',
      accessor: (d) => {
        if (d.total_conversion_rate) {
          if (d.total_conversion_rate.all > 0) {
            const percent = (parseFloat(d.total_conversion_rate.success) / parseFloat(d.total_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.total_conversion_rate.success}/${d.total_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('draftsSettings').show_total_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: '',
      width: 40,
      id: 'remove_draft',
      filterable: false,
      show: true,
      Cell: ({ row }) => (
        <div title="Удалить черновик"><FontAwesomeIcon onClick={() => this.deleteOsago(row.original.id, row.original.type_insurance)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    this.setState({ columns });
  };

  downloadDrafts = (dateFrom, dateTo) => {
    abortController.abort();
    abortController = new AbortController();
    const { showModalInfo } = this.context;
    const { show, agentId } = this.props;
    const sendAgentId = agentId || null;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/draft?type=${show}&dateFrom=${dateFrom}&dateTo=${dateTo}&agent=${sendAgentId}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((policies) => {
        this.setState({ policies, loading: false }, () => {
          this.updateTableColumns();
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          showModalInfo('Ошибка загрузки данных', 'error');
          this.setState({ loading: false });
        }
      });
  };

  deleteOsago = (id, product) => {
    const { policies } = this.state;
    this.setState({ loading: true, policies: [] }, () => {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      const { show } = this.props;
      let link = '';
      switch (product) {
        case 'mortgage':
          link = `${process.env.REACT_APP_API_DOMAIN}/mortgage/${id}`;
          break;
        default:
          link = `${process.env.REACT_APP_API_DOMAIN}/policy/${id}?type=${show}`;
          break;
      }
      customFetch(link, {
        headers: {
          Authorization: lsToken,
        },
        method: 'delete',
      })
        .then((response) => response.json())
        .then(() => {
          const index = policies.findIndex((draft) => draft.id === id);
          policies.splice(index, 1);
          this.setState({ policies, loading: false });
        });
    });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleSubmitGroupEdit = (e) => {
    e.preventDefault();
    const { editOption } = this.state;
    if (editOption.value) {
      if (editOption.value === 'request_sk') {
        this.checkAllPayments(e);
      }
    }
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('draftsSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('draftsSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  setData = (excelcolumns, dataSet) => {
    this.setState({ excelcolumns, dataSet }, function () {
      this.excelFile.handleDownload();
    });
  }

  downloadReport = () => {
    const { columns } = this.state;
    downloadReport(this.reactTableRef, columns, ['remove_draft', 'select_cell'], this.setData);
  };

  toggleFilter = (tab) => {
    this.setState({ policies: [] }, () => {
      const { activeFilterTab, filtered } = this.state;
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let dateFrom;
      let dateTo;
      if (activeFilterTab !== tab) {
        this.setState({ activeFilterTab: tab });
        let filterDate = null;
        if (tab === '1') {
          dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
          dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateCurrentMonth,
            endDate: endDateCurrentMonth,
          });
          filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
        } else if (tab === '2') {
          if (currentMonth === 1) {
            currentYear -= 1;
            lastButOneMonth = '12';
            lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
            startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
            endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          }
          dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
          dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateLastButOneMonth,
            endDate: endDateLastButOneMonth,
          });
          filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
        } else if (tab === '3') {
          dateFrom = null;
          dateTo = null;
          this.setState({
            startDate: null,
            endDate: date,
          });
          filterDate = `${format(date, 'dd.MM.yyyy')}`;
        }
        const newFiltered = filtered;
        let findedIndex = -1;
        let findedIndexDateTab = -1;
        newFiltered.forEach((filter, i) => {
          if (filter.id === 'filter_date') {
            findedIndex = i;
          }
          if (filter.id === 'dateTab') {
            findedIndexDateTab = i;
          }
        });
        if (findedIndex !== -1) {
          if (filterDate === null) {
            newFiltered.splice(findedIndex, 1);
          } else {
            newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
          }
        } else if (filterDate !== null) {
          newFiltered.push({ id: 'filter_date', value: filterDate });
        }
        if (findedIndexDateTab !== -1) {
          newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
        } else {
          newFiltered.push({ id: 'dateTab', value: tab });
        }
        let queryParams = '?';
        newFiltered.forEach((filter, key, arr) => {
          if (filter.id !== 'activeTab') {
            if (filter.id === 'filter_date' && tab === '3') {
              queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            } else {
              queryParams = `${queryParams + filter.id}=${filter.value}`;
            }
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        });
        queryParams = `${queryParams}&activeTab=${this.props.tab}`;
        const { history } = this.props;
        history.push({ search: queryParams });
        this.setState({ filtered: newFiltered });
        this.downloadDrafts(dateFrom, dateTo);
      }
    });
  };

  checkAllPayments = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { selected } = this.state;
    this.setState({ groupEditProgress: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/check_payment`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        policies: selected,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          this.setState({ selected: [], selectAll: 0, selectFiltered: 0 }, () => {
            this.updateTableColumns();
          });
          showModalInfo('Запросы по полисам отправлены');
        } else if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        this.setState({ groupEditProgress: false });
      });
  };

  handleChangeStart = (date) => {
    this.setState({ policies: [] }, () => {
      const { endDate, filtered } = this.state;
      const { tab } = this.props;
      this.setState({ startDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateTo = null;
      if (endDate !== null) {
        filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
        dateTo = format(endDate, 'yyyy-MM-dd');
      }
      this.downloadDrafts(format(date, 'yyyy-MM-dd'), dateTo);
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({ policies: [] }, () => {
      const { startDate, filtered } = this.state;
      const { tab } = this.props;
      this.setState({ endDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateFrom = null;
      if (startDate !== null) {
        filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }
      this.downloadDrafts(dateFrom, format(date, 'yyyy-MM-dd'));
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  changeEditOption = (e) => {
    this.setState({ editOption: e });
  };

  render() {
    const {
      loading,
      policies,
      openModalTableCustomized,
      dataSet,
      excelcolumns,
      activeFilterTab,
      startDate,
      endDate,
      defaultSortingField,
      defaultSortingOrder,
      columns,
      filtered,
      selected,
      selectAll,
      editOption,
      groupEditProgress,
    } = this.state;
    const { history, tab, userInfo } = this.props;
    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggleFilter('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2">
            <span>Точные даты с</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
        </div>
        <Table
          ref={this.reactTableRef}
          data={policies}
          columns={columns}
          loading={loading}
          onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, row.original.type_insurance, history)}
          onContextMenuClick={(e, row) => onContextMenuClick(e, row.original.type_insurance, row.original.id)}
          noDataText="Черновики не найдены"
          defaultFiltered={filtered}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'draftsSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings7(newResized, 'draftsSettings');
          }}
          onFilteredChange={(newFiltered) => onFilteredChangeCustom(newFiltered, startDate, tab)}
        />
        <Sticky
          mode="bottom"
          positionRecheckInterval={1}
          className={Object.keys(selected).length > 0 ? 'visible-animation' : 'hidden-animation'}
        >
          <form name="groupEditForm" id="groupEditForm" onSubmit={this.handleSubmitGroupEdit}>
            <div className="row">
              <div className="form-group col-lg-3">
                <Select
                  classNamePrefix="react-select"
                  menuPlacement="top"
                  styles={customSelectStyle()}
                  name="editOption"
                  isSearchable={false}
                  noOptionsMessage={() => 'Не найдено'}
                  id="editOption"
                  value={editOption.value ? editOption : ''}
                  placeholder="Выберите действие"
                  onChange={this.changeEditOption}
                  options={(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? [
                    { value: 'request_sk', label: 'Запросить полисы в СК' },
                  ] : [
                    { value: 'request_sk', label: 'Запросить полисы в СК' },
                  ]}
                />
              </div>
              <div className="form-group col-lg-6">
                <button type="submit" className="btn btn-success mr-2">Применить</button>
                <input
                  type="checkbox"
                  className="checkbox mr-1"
                  id="selectAll"
                  checked={selectAll === 1}
                  onChange={(e) => toggleSelectAll(e.target.checked, policies, this.setSelected, this.reactTableRef.current.getTableData(), 'type_insurance')}
                />
                <label htmlFor="selectAll">Применить ко всем</label>
                <span className="nowrap">(Выбрано: {Object.keys(selected).length})</span>
                {groupEditProgress && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </div>
            </div>
          </form>
        </Sticky>
      </>
    );
  }
}

DraftsTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(DraftsTable);
