import React from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../Stores/UserInfoStore';

const Link = styled.span`
    padding-bottom: 2px;
    background: linear-gradient(to right, rgba(17, 212, 95, 0.5), rgba(17, 212, 95, 0.5)) 100% 100% / 100% 0.1em no-repeat,
    linear-gradient(to right, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}) 0 100% / 0 0.1em no-repeat;
    user-select: none;
    transition: background-size 0.3s, opacity 0.3s;
    cursor: pointer !important;

    &:hover,
    &:focus {
        background-size: 0 0.1em, 100% 0.1em;
    }
    
    ${(props) => (props.noSpaceAround ? '' : `
        &:before, &:after {
            content: "\\00a0";
        }`)};
    ${(props) => (!props.minimized ? '' : `
        @media screen and (max-width: 620px) {
          width: 0;
          height: 0;
          font-size: 0;
          visibility: hidden;
          position: relative;
          &:before {
            content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nY3VycmVudENvbG9yJyBjbGFzcz0ndy01IGgtNSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNOC4yMiA1LjIyYS43NS43NSAwIDAgMSAxLjA2IDBsNC4yNSA0LjI1YS43NS43NSAwIDAgMSAwIDEuMDZsLTQuMjUgNC4yNWEuNzUuNzUgMCAwIDEtMS4wNi0xLjA2TDExLjk0IDEwIDguMjIgNi4yOGEuNzUuNzUgMCAwIDEgMC0xLjA2WicgY2xpcC1ydWxlPSdldmVub2RkJyAvPjwvc3ZnPgo=);
            display: inline-block;
            width: 24px;
            height: 24px;
            bottom: -0.75rem;
            right: 4px;
            font-size: 16px;
            visibility: visible;
            position: absolute;
          }
        }
    `)};
`;

function LinkUnderLine(props) {
  const {
    children, onClick, noSpaceAround, minimized,
  } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <Link minimized={minimized} theme={theme} onClick={onClick} noSpaceAround={noSpaceAround}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </Link>
  );
}

export default LinkUnderLine;
