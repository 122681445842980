import React, { useContext, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { BellIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { shallow } from 'zustand/shallow';
import { ModalMessagesContext } from '../../ContextsServices/ModalMessagesService';
import { customFetch } from '../../Helpers';
import NavbarPopupLink from '../../../Layout/ NavbarPopupLink';
import CustomIcon from '../../../Layout/CustomIcon';
import useUserInfoStore from '../../../Stores/UserInfoStore';
//import useSocketsSocketIO from '../../../CustomHooks/UseSocketsSocketIO';

const Container = styled.div`
  cursor: pointer;
  margin-right: 0.5rem;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .15s ease;

  &:hover {
    background-color: #ccc;
  }

  ${(props) => {
    if (!props.$activeFlag) {
      return '';
    }
    return props.theme ? `background-color: ${props.theme.subColor} !important;` : 'background-color: #f0f000 !important;';
  }}

  @media (max-width: 767px) {
    display: none;
  }
`;

const Count = styled.div`
  position: absolute;
  top: 0;
  right: -7px;
  z-index: 1;
  min-width: 21px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 23px;
  background-color: #01e250;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
`;

export const ModalContainer = styled.div`
  padding: 17px 16px 0 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 32px 0;
  }
`;

export const ModalHeader = styled.div`
  padding: 0 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0 16px 14px 16px;
  }
`;

export const Name = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #cfcfcf;

  @media (max-width: 767px) {
    letter-spacing: normal;
    text-transform: capitalize;
    font-size: 26px;
    line-height: 32px;
    color: #3d3d3b;
  }
`;

const ModalButton = styled.div`
  cursor: pointer;
  margin: 0 0 0 24px;
  padding: 6px 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  border: 0;
  outline: 0;
  background-color: #3d3d3b;
  border-radius: 22px;
  transition: background-color .15s ease;

  &:hover{
    background-color: #2f2f2d;
    @media (max-width: 767px) {
      background-color: #3d3d3b;
    }
  }    

  @media (max-width: 767px) {    
    color: #3d3d3b;
    background-color: #cfcfcf;
  }
`;

export const ModalList = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {   
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: #fff;
  }
`;

export const ModalNotificationItemContainer = styled.div`
  position: relative;
  padding: 16px 22px 17px 50px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;
  transition: background-color .15s ease;
  margin: 0 -16px 0 -24px;

  

  &:hover{
    background-color: #3d3d3b;
    @media (max-width: 767px) {
      background-color: #f6f6f6;
    }
  }

  &:before{
    position: absolute;
    top: 22px;
    left: 24px;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0bd95d;

    @media (max-width: 767px) { 
      top: 22px;
      left: 16px;
      width: 8px;
      height: 8px;
    }
  }

  @media (max-width: 767px) {   
    margin: 0 0 20px;
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow: 0, 6px, 12px, rgba(143, 143, 143, .08);
    border: 1px solid #e9e9e9;
    padding: 15px 16px 15px 36px;
    color: #3d3d3b;
  }
`;

export const ModalNotificationText = styled.div`
`;

const ModalNotificationCloseButton = styled.div`
  color: #a3a3a3;

  &:hover{
    color: #e9e9e9;
  }

  @media (max-width: 767px) {  
    color: #a3a3a3;
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    transition: color .15s ease;
  }
`;

const BottomLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 -16px 0 -24px;

    a{
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(17, 212, 95, .5);
      display: inline-block;
      font-weight: 500;
      color: #3d3d3b !important;
    }
`;

export default function UserNotificationsIcon(props) {
  const {
    history, notificationsList, setNotificationsList, notificationsTippy, notificationsTippyActiveFlag, setNotificationsTippyActiveFlag,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);
  const socket = useRef(null);

  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  const goToLink = (e, item) => {
    e.preventDefault();

    if (item.link) {
      setNotificationsList((prev) => (prev.filter((readItem) => readItem.id !== item.id || readItem.type !== item.type)));
      history.push(`${item.link}`);
      notificationsTippy.current.click();
    }
  };

  const readNotifications = (e, id, type) => {
    e.preventDefault();

    let idList = [];
    if (id === 'all') {
      idList = notificationsList.map((item) => ({ id: item.id, type: item.type }));
    } else {
      idList.push({ id, type });
    }

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/user-notifications/set-read-flag`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idList }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          setNotificationsList((prev) => (prev.filter((readItem) => ((readItem.id !== id || readItem.type !== type) && id !== 'all'))));
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при установке флага прочтения для уведомления');
      });
  };

  const listenNotification = (arg) => {
    setNotificationsList((prev) => [arg, ...prev]);
  };

  /*
  useEffect(() => {
    if (userInfo.id) {
      socket.current = useSocketsSocketIO(`user_notifications:${userInfo.id}`);
      socket.current.connect();
      socket.current.on('notification', listenNotification);
    }
  }, [userInfo.id]);
  */

  useEffect(() => () => {
    if (socket.current && socket.current.off) {
      socket.current.off('notification', listenNotification);
      socket.current.disconnect();
    }
  }, []);

  return (
    <Tippy
      style
      placement="bottom-start"
      interactive
      trigger="click"
      className="page-popup page-popup--agents-list page-popup-user-notifications page-popup_auto-width"
      arrow={false}
      offset={[0, 12]}
      ref={notificationsTippy}
      content={(
        <ModalContainer>
          <ModalHeader>
            {notificationsList.length ? (
              <>
                <Name>Уведомления</Name>
                <ModalButton onClick={(e) => readNotifications(e, 'all')}>Скрыть все</ModalButton>
              </>
            ) : (
              <Name>Нет уведомлений</Name>
            )}
          </ModalHeader>
          <ModalList>
            {notificationsList.map((item, index) => {
              if (index > 4) {
                return null;
              }
              return (
                <ModalNotificationItemContainer>
                  <ModalNotificationText onClick={(e) => goToLink(e, item)}>{item.text}</ModalNotificationText>
                  <ModalNotificationCloseButton onClick={(e) => readNotifications(e, item.id, item.type)}>
                    {window.innerWidth > 767 ? (
                      <XCircleIcon height={24} />
                    ) : (
                      <CustomIcon icon="cross" width="24px" height="24px" />
                    )}
                  </ModalNotificationCloseButton>
                </ModalNotificationItemContainer>
              );
            })}
          </ModalList>
          {window.innerWidth > 767 ? (
            <NavbarPopupLink text="Смотреть все новости" theme={theme} link="/news" closeTippy={() => { notificationsTippy.current.click(); }} />
          ) : (
            <BottomLink>
              <a href="" onClick={(e) => { e.preventDefault(); notificationsTippy.current.click(); history.push('/news'); }}>Все новости</a>
            </BottomLink>
          )}
        </ModalContainer>
      )}
      hideOnClick
      onShow={() => setNotificationsTippyActiveFlag(true)}
      onHide={() => setNotificationsTippyActiveFlag(false)}
    >
      <Container theme={theme} $activeFlag={notificationsTippyActiveFlag}>
        <BellIcon height={16} />
        {notificationsList.length ? (
          <Count>{notificationsList.length}</Count>
        ) : null}
      </Container>
    </Tippy>
  );
}
