import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import AuthService from './AuthService';

GoogleAnalytics.initialize('UA-54062205-2');

export default function withAuth(AuthComponent) {
  const Auth = new AuthService(process.env.REACT_APP_API_DOMAIN);

  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
    });
    GoogleAnalytics.pageview(page);
  };
  return class AuthWrapped extends Component {
    constructor() {
      super();
      this.state = { user: null };
    }

    componentWillMount() {
      const { history, location } = this.props;
      const currentLocation = location.pathname + location.search;
      const promise = new Promise(this.checkLastBuild);
      promise.then((resolve) => {
        if (resolve === true) {
          if (!Auth.loggedIn()) {
            const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
            const opts = {
              method: 'get',
              headers: {
                Authorization: lsToken,
              },
            };
            fetch(`${process.env.REACT_APP_API_DOMAIN}/refresh`, opts)
              .then((response) => response.json())
              .then((response) => {
                localStorage.setItem('id_token', response.token);
                try {
                  const profile = Auth.getProfile();
                  this.setState({
                    user: profile,
                  });
                  if (currentLocation === '/') {
                    history.push('/dashboard');
                  }
                } catch (err) {
                  Auth.logout();
                  history.replace('/login');
                }
              })
              .catch(() => {
                const path = {
                  pathname: '/login',
                  state: { from: currentLocation },
                };
                history.push(path);
              });
          } else {
            try {
              const profile = Auth.getProfile();
              this.setState({
                user: profile,
              });
              if (currentLocation === '/') {
                history.push('/dashboard');
              }
            } catch (err) {
              Auth.logout();
              history.replace('/login');
            }
          }
        } else {
          window.location.reload();
        }
      });
    }

    componentDidMount() {
      const { location } = this.props;
      trackPage(location.pathname);
    }

    componentDidUpdate() {
      const { location } = this.props;
      trackPage(location.pathname);
    }

    checkLastBuild = (resolve) => {
      fetch(`${process.env.REACT_APP_API_DOMAIN}/checkLastBuild`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (localStorage.getItem('build') === response) {
            resolve(true);
          }
          localStorage.setItem('build', response);
          resolve(false);
        })
        .catch(() => resolve(false));
    };

    render() {
      const { user } = this.state;
      const { history, theme } = this.props;
      if (user) {
        return (
          <AuthComponent theme={theme} history={history} user={user} />
        );
      }
      return null;
    }
  };
}
