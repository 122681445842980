import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import { format, parse } from 'date-fns';
import ReactTable from 'react-table-6';
import ReactExport from 'react-data-export';
import { reactLocalStorage } from 'reactjs-localstorage';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import matchSorter from 'match-sorter';
import classnames from 'classnames';
import Modal from 'react-responsive-modal';
import queryString from 'query-string';
import MultiRef from 'react-multi-ref';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend,
} from 'recharts';
import { faChartArea } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortControllerAgents = new AbortController();
let abortControllerReports = new AbortController();
const rootDate = new Date();
const columnColors = {
  drafts_count: '#3908b4',
  drafts_unique: '#b42808',
  count_subagents: '#0878b4',
  policies_count: '#7a1f7f',
  policies_sum: '#a36fe7',
  agent_profit: '#b12faf',
  my_profit: '#856fe7',
  company_profit: '#5441b4',
  company_inner_kv: '#598000',
};
const agentsColors = [
  '#e60000',
  '#acb315',
  '#1f8027',
  '#40a4b3',
  '#836ee6',
  '#b36ba3',
  '#804e3d',
  '#90b340',
  '#37e674',
  '#1575b3',
];

class ReportsTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this.state = {
      period: 'day',
      showGraph: true,
      optionsPeriod: [],
      mobileSize: window.innerWidth <= 760,
      checkedAgents: [],
      checkedColumns: {
        policies_count: true,
      },
      loadingDataGraph: true,
      columns: [],
      agents: [],
      loading: true,
      activeFilterTab: '1',
      startDate: parse(`01.${rootDate.getMonth() + 1}.${rootDate.getFullYear()}`, 'dd.MM.yyyy', new Date()),
      endDate: parse(`${new Date(rootDate.getFullYear(), rootDate.getMonth() + 1, 0).getDate()}.${rootDate.getMonth() + 1}.${rootDate.getFullYear()}`, 'dd.MM.yyyy', new Date()),
      filtered: [],
      excelcolumns: [],
      product: { value: false, label: 'Все продукты' },
      dataSet: [],
      theme: window.location.host.split('.')[0],
      openModalTableCustomized: false,
      graphData: [],
      defaultSortingField: reactLocalStorage.getObject('reportsTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('reportsTableSettings').defaultSortingField
        : 'sum_cost',
      defaultSortingOrder: reactLocalStorage.getObject('reportsTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('reportsTableSettings').defaultSortingOrder
        : 'true',
    };
  }

  componentDidMount() {
    const { location, tab } = this.props;
    const { filtered } = this.state;
    let { startDate, endDate } = this.state;
    const urlParams = queryString.parse(location.search);
    let dateFrom = format(startDate, 'yyyy-MM-dd HH:mm:ss');
    let dateTo = format(endDate, 'yyyy-MM-dd HH:mm:ss');
    if (window.innerWidth <= 760) {
      this.setState({
        optionsPeriod: [
          { value: 'day', label: 'По дням' },
          { value: 'week', label: 'По неделям' },
          { value: 'month', label: 'По месяцам' },
        ],
      });
    } else {
      this.setState({
        optionsPeriod: [
          { value: 'day', label: 'Группировать по дням' },
          { value: 'week', label: 'Группировать по неделям' },
          { value: 'month', label: 'Группировать по месяцам' },
        ],
      });
    }
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        if (filter === 'filter_date') {
          startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
          endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
          dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
          dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
          this.setState({
            startDate,
            endDate,
          });
        } else {
          filtered.push({ id: filter, value: urlParams[filter] });
        }
      });
      this.setState({ filtered });
    }
    this.downloadReports(dateFrom, dateTo);
    this.downloadDataForGraph(startDate, endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized, checkedAgents, checkedColumns } = this.state;
    const { userInfo } = this.props;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) || (prevProps.userInfo.role !== userInfo.role)) {
      this.updateTableColumns();
    } else if (prevState.checkedColumns !== checkedColumns || prevState.checkedAgents !== checkedAgents) {
      this.updateTableColumns();
    }
  }

  downloadReports = (dateFrom, dateTo) => {
    abortControllerAgents.abort();
    abortControllerAgents = new AbortController();
    const { show } = this.props;
    const { product } = this.state;
    const { showModalInfo } = this.context;
    const productParam = product.value === false ? '' : `&product=${product.value}`;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/reports?show=${show}&startDate=${dateFrom}&endDate=${dateTo}${productParam}`, {
      signal: abortControllerAgents.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((agents) => {
        this.setState({
          agents,
          loading: false,
        }, () => {
          this.updateTableColumns();
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          showModalInfo('Ошибка загрузки данных', 'error');
          this.setState({ loading: false });
        }
        this.updateTableColumns();
      });
  };

  updateTableColumns = () => {
    const {
      show,
      userInfo,
    } = this.props;
    const { agents, checkedAgents, mobileSize } = this.state;
    const columns = [{
      Header: 'Пользователь',
      id: 'subAgentName',
      width: reactLocalStorage.getObject('reportsTableSettings').subAgentName,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['subAgentName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      Footer: show !== 'full_time_employee' ? 'ИТОГО' : null,
      show: reactLocalStorage.getObject('reportsTableSettings').show_subAgentName !== false,
      accessor: (d) => `${d.last_name} ${d.first_name} ${d.middle_name}`,
      Cell: (row) => {
        const agentIndex = checkedAgents.findIndex((agent) => agent === row.original.id);
        return <><FontAwesomeIcon onClick={(e) => this.handleChangeCheckedAgents(e, row.original.id)} icon={faChartArea} className={classnames({ active: agentIndex !== -1 }, 'fa-fw')} />{ `${row.original.last_name} ${row.original.first_name} ${row.original.middle_name}` }</>;
      },
      showCustomized: true,
    }, {
      Header: 'Юр. лицо/ИП',
      accessor: 'organization_name',
      width: reactLocalStorage.getObject('reportsTableSettings').organization_name,
      minWidth: 150,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['organization_name'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show && reactLocalStorage.getObject('reportsTableSettings').show_organization_name !== false),
      showCustomized: show,
    }, {
      Header: 'Город',
      accessor: 'city',
      width: reactLocalStorage.getObject('reportsTableSettings').city,
      minWidth: 150,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['city'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (show && reactLocalStorage.getObject('reportsTableSettings').show_city !== false),
      showCustomized: show,
    }, {
      Header: mobileSize ? 'Пользователи' : 'Кол-во пользователей',
      accessor: 'count_subagents',
      width: reactLocalStorage.getObject('reportsTableSettings').count_subagents,
      show: reactLocalStorage.getObject('reportsTableSettings').show_count_subagents !== false,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { count_subagents }) => total += count_subagents, 0)}
        </span>
      ) : null,
      showCustomized: true,
    }, {
      Header: mobileSize ? 'Расчеты' : 'Кол-во расчетов',
      accessor: 'drafts_count',
      width: reactLocalStorage.getObject('reportsTableSettings').drafts_count,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { drafts_count }) => total += drafts_count, 0)}
        </span>
      ) : null,
      show: reactLocalStorage.getObject('reportsTableSettings').show_drafts_count !== false,
      showCustomized: true,
    }, {
      Header: mobileSize ? 'Кол-во черновиков' : 'Кол-во уникальных черновиков',
      accessor: 'drafts_unique',
      width: reactLocalStorage.getObject('reportsTableSettings').drafts_unique,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { drafts_unique }) => total += drafts_unique, 0)}
        </span>
      ) : null,
      show: reactLocalStorage.getObject('reportsTableSettings').show_drafts_unique !== false,
      showCustomized: true,
    }, {
      Header: 'Кол-во полисов',
      accessor: 'policies_count',
      width: reactLocalStorage.getObject('reportsTableSettings').policies_count,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { policies_count }) => total += policies_count, 0)}
        </span>
      ) : null,
      show: reactLocalStorage.getObject('reportsTableSettings').show_policies_count !== false,
      showCustomized: true,
    }, {
      Header: mobileSize ? 'Продажи' : 'Сумма продаж, руб.',
      id: 'policies_sum',
      width: reactLocalStorage.getObject('reportsTableSettings').policies_sum,
      accessor: (d) => parseFloat(d.policies_sum),
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { policies_sum }) => total += parseFloat(policies_sum), 0).toFixed(2)}
        </span>
      ) : null,
      show: reactLocalStorage.getObject('reportsTableSettings').show_policies_sum !== false,
      showCustomized: true,
    }, {
      Header: mobileSize ? 'Доход пользователя' : 'Пользователь заработал, руб.',
      id: 'agent_profit',
      width: reactLocalStorage.getObject('reportsTableSettings').agent_profit,
      accessor: (d) => ((d.id === userInfo.id) ? '0' : d.agent_profit),
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { agent_profit }) => total += parseFloat(agent_profit), 0).toFixed(2)}
        </span>
      ) : null,
      show: (show && reactLocalStorage.getObject('reportsTableSettings').show_agent_profit !== false),
      showCustomized: show,
    }, {
      Header: mobileSize ? 'Ваш доход' : 'Ваша прибыль, руб.',
      accessor: 'my_profit',
      width: reactLocalStorage.getObject('reportsTableSettings').my_profit,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { my_profit }) => total += parseFloat(my_profit), 0).toFixed(2)}
        </span>
      ) : null,
      show: (show !== 'full_time_employee' && show !== 'not_employee_first_level' && reactLocalStorage.getObject('reportsTableSettings').show_my_profit !== false),
      showCustomized: (show !== 'full_time_employee' && show !== 'not_employee_first_level'),
    }, {
      Header: mobileSize ? 'Вход.вознаграждение' : 'Входящее вознаграждение',
      accessor: 'company_inner_kv',
      width: reactLocalStorage.getObject('reportsTableSettings').company_inner_kv,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { company_inner_kv }) => total += parseFloat(company_inner_kv), 0).toFixed(2)}
        </span>
      ) : null,
      show: (show === 'not_employee_first_level' && reactLocalStorage.getObject('reportsTableSettings').show_company_inner_kv !== false),
      showCustomized: show === 'not_employee_first_level',
    }, {
      Header: mobileSize ? 'Прибыль компании' : 'Прибыль компании, руб.',
      accessor: 'company_profit',
      width: reactLocalStorage.getObject('reportsTableSettings').company_profit,
      Footer: show !== 'full_time_employee' ? (
        <span>
          {agents.reduce((total, { company_profit }) => total += parseFloat(company_profit), 0).toFixed(2)}
        </span>
      ) : null,
      show: (userInfo.full_time_employee && reactLocalStorage.getObject('reportsTableSettings').show_company_profit !== false),
      showCustomized: userInfo.full_time_employee,
    },
    ];
    this.setState({ columns });
  }

  setTableSettings = (newResized) => {
    const settings = reactLocalStorage.getObject('reportsTableSettings');
    newResized.forEach((item) => {
      settings[item.id] = item.value;
    });
    reactLocalStorage.setObject('reportsTableSettings', settings);
  };

  setTableSorting = (newSort) => {
    const settings = reactLocalStorage.getObject('reportsTableSettings');
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
    reactLocalStorage.setObject('reportsTableSettings', settings);
  };

  handleChangeStart = (date) => {
    this.setState({
      loading: true, agents: [], loadingDataGraph: true, graphData: [],
    }, () => {
      const { endDate, filtered } = this.state;
      const { tab } = this.props;
      this.setState({ startDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let endDateFormated;
      if (endDate === null) {
        endDateFormated = null;
      } else {
        endDateFormated = format(endDate, 'yyyy-MM-dd HH:mm:ss');
      }
      if (endDate !== null) {
        filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
      }
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      this.downloadReports(format(date, 'yyyy-MM-dd HH:mm:ss'), endDateFormated);
      this.downloadDataForGraph(date, endDate);
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${tab}`;
      window.history.replaceState(null, null, queryParams);
      this.setState({ filtered: newFiltered });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({
      loading: true, agents: [], loadingDataGraph: true, graphData: [],
    }, () => {
      const { startDate, filtered } = this.state;
      const { tab } = this.props;
      this.setState({ endDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let startDateFormeted;
      if (startDate === null) {
        startDateFormeted = null;
      } else {
        startDateFormeted = format(startDate, 'yyyy-MM-dd HH:mm:ss');
      }
      if (startDate !== null) {
        filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
      }
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      this.downloadReports(startDateFormeted, format(date, 'yyyy-MM-dd HH:mm:ss'));
      this.downloadDataForGraph(startDate, date);
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${tab}`;
      window.history.replaceState(null, null, queryParams);
      this.setState({ filtered: newFiltered });
    });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('reportsTableSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('reportsTableSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  handleChangePeriod = (e) => {
    const { value } = e;
    this.setState({ period: value }, () => {
      const { startDate, endDate } = this.state;
      this.downloadDataForGraph(startDate, endDate);
    });
  }

  handleChangeProduct = (e) => {
    this.setState({
      product: e, loading: true, agents: [], loadingDataGraph: true, graphData: [],
    }, () => {
      let endDateFormated;
      const { startDate, endDate } = this.state;
      let startDateFormeted;
      if (startDate === null) {
        startDateFormeted = null;
      } else {
        startDateFormeted = format(startDate, 'yyyy-MM-dd HH:mm:ss');
      }
      if (endDate === null) {
        endDateFormated = null;
      } else {
        endDateFormated = format(endDate, 'yyyy-MM-dd HH:mm:ss');
      }
      this.downloadReports(startDateFormeted, endDateFormated);
      this.downloadDataForGraph(startDate, endDate);
    });
  };

  downloadReport = () => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    const { columns } = this.state;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    const dataToDownload = [];
    for (let index = 0; index < currentRecords.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (currentRecords[index][columns[colIndex].accessor]) {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
        } else {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.filter((column) => column.show !== false).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);
    this.setState({ excelcolumns, dataSet: dataToDownload }, function () {
      this.excelFile.handleDownload();
    });
  };

  onFilteredChangeCustom = (filtered) => {
    const { startDate } = this.state;
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (filter.id === 'filter_date' && startDate === null) {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
        }
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  getDataByMetric = (e, metric) => {
    const { checkedColumns } = this.state;
    if (checkedColumns[metric]) {
      delete checkedColumns[metric];
    } else {
      checkedColumns[metric] = true;
    }
    this.setState({ checkedColumns }, () => {
      const { startDate, endDate } = this.state;
      this.downloadDataForGraph(startDate, endDate);
    });
  };

  downloadDataForGraph = (startDate, endDate) => {
    abortControllerReports.abort();
    abortControllerReports = new AbortController();
    this.setState({ loadingDataGraph: true, graphData: [] }, () => {
      const { showModalInfo } = this.context;
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const {
        checkedColumns, period, product,
      } = this.state;
      const { show } = this.props;
      const checkedAgents = [...this.state.checkedAgents];

      let graphType = 'metrics';
      if (checkedAgents.length >= 2 && Object.keys(checkedColumns).length === 1) {
        graphType = 'agents';
      }

      customFetch(`${process.env.REACT_APP_API_DOMAIN}/reports/dataGraph`, {
        signal: abortControllerReports.signal,
        method: 'post',
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          columns: checkedColumns,
          startDate: startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null,
          endDate: endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null,
          agents: checkedAgents,
          graphType,
          period,
          product,
          show,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          this.updateTableColumns();
          this.setState({
            graphData: response,
            loadingDataGraph: false,
          }, () => {
            this.updateTableColumns();
          });
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            showModalInfo('Ошибка загрузки графика', 'error');
            this.setState({ loadingDataGraph: false });
          }
        });
    });
  };

  handleChangeShowGraph = () => {
    this.setState((prevState) => ({
      showGraph: !prevState.showGraph,
    }));
  }

  handleChangeCheckedAgents = (e, agent) => {
    e.preventDefault();
    const { checkedAgents, checkedColumns } = this.state;
    const index = checkedAgents.findIndex((agentId) => agentId === agent);
    if (index !== -1) {
      checkedAgents.splice(index, 1);
    } else {
      if (checkedAgents.length >= 10 && Object.keys(checkedColumns).length === 1) {
        checkedAgents.shift();
      }
      checkedAgents.push(agent);
    }
    this.setState({ checkedAgents, showGraph: true }, () => {
      const { startDate, endDate } = this.state;
      this.downloadDataForGraph(startDate, endDate);
    });
  };

  toggle(tab) {
    const { activeFilterTab, filtered } = this.state;
    if (activeFilterTab !== tab) {
      this.setState({
        loading: true, agents: [], loadingDataGraph: true, graphData: [],
      }, () => {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        let lastButOneMonth = date.getMonth();
        let currentYear = date.getFullYear();
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
        let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
        const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        this.setState({ activeFilterTab: tab });
        let filterDate = null;
        let startDate = null;
        let endDate = null;
        if (tab === '1') {
          this.setState({
            startDate: startDateCurrentMonth,
            endDate: endDateCurrentMonth,
          });
          filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
          startDate = startDateCurrentMonth;
          endDate = endDateCurrentMonth;
        } else if (tab === '2') {
          if (currentMonth === 1) {
            currentYear -= 1;
            lastButOneMonth = '12';
            lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
            startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
            endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          }
          this.setState({
            startDate: startDateLastButOneMonth,
            endDate: endDateLastButOneMonth,
          });
          filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
          startDate = startDateLastButOneMonth;
          endDate = endDateLastButOneMonth;
        } else if (tab === '3') {
          this.setState({
            startDate: null,
            endDate: date,
          });
          filterDate = `${format(date, 'dd.MM.yyyy')}`;
          endDate = date;
        }
        const stringStartDate = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
        const stringEndDate = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;
        this.downloadReports(stringStartDate, stringEndDate);
        this.downloadDataForGraph(startDate, endDate);
        const newFiltered = filtered;
        let findedIndex = -1;
        let findedIndexDateTab = -1;
        newFiltered.forEach((filter, i) => {
          if (filter.id === 'filter_date') {
            findedIndex = i;
          }
          if (filter.id === 'dateTab') {
            findedIndexDateTab = i;
          }
        });
        if (findedIndex !== -1) {
          if (filterDate === null) {
            newFiltered.splice(findedIndex, 1);
          } else {
            newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
          }
        } else if (filterDate !== null) {
          newFiltered.push({ id: 'filter_date', value: filterDate });
        }
        if (findedIndexDateTab !== -1) {
          newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
        } else {
          newFiltered.push({ id: 'dateTab', value: tab });
        }
        let queryParams = '?';
        newFiltered.forEach((filter, key, arr) => {
          if (filter.id !== 'activeTab') {
            if (filter.id === 'filter_date' && tab === '3') {
              queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            } else {
              queryParams = `${queryParams + filter.id}=${filter.value}`;
            }
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        });
        queryParams = `${queryParams}&activeTab=${this.props.tab}`;
        window.history.replaceState(null, null, queryParams);
        this.setState({ filtered: newFiltered });
      });
    }
  }

  render() {
    const {
      agents,
      loading,
      activeFilterTab,
      startDate,
      endDate,
      dataSet,
      excelcolumns,
      theme,
      openModalTableCustomized,
      columns,
      graphData,
      loadingDataGraph,
      checkedAgents,
      checkedColumns,
      showGraph,
      period,
      defaultSortingField,
      defaultSortingOrder,
      product,
      optionsPeriod,
      mobileSize,
    } = this.state;
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;

    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className={`btn btn-success ${theme}`}>Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button>Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggle('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggle('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggle('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2">
            <span>Точные даты с</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              placeholderText="ДД.ММ.ГГГГ"
              dateFormat="dd.MM.yyyy"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block mr-1 mb-2">
            <span>по</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              placeholderText="ДД.ММ.ГГГГ"
              dateFormat="dd.MM.yyyy"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block mr-1 mb-2">
            <Tippy
              className="tippy-custom"
              arrow={false}
              offset={[0, 5]}
              content={showGraph ? 'Скрыть график' : 'Показать график'}
              hideOnClick={false}
            >
              <div className="statistic-graph__tippy" onClick={this.handleChangeShowGraph}><FontAwesomeIcon icon={faChartArea} className={classnames({ active: showGraph }, 'fa-fw')} /></div>
            </Tippy>
          </div>
          {showGraph && (
            <div className="d-inline-block statistic-graph__period mb-2 mr-1">
              <Select
                classNamePrefix="react-select"
                styles={customSelectStyle()}
                isSearchable={false}
                value={optionsPeriod.filter(({ value }) => value === period)}
                onChange={this.handleChangePeriod}
                options={optionsPeriod}
              />
            </div>
          )}
          <div className="d-inline-block statistic-graph__period mb-2">
            <Select
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              isSearchable={false}
              value={product}
              onChange={this.handleChangeProduct}
              options={[
                { value: false, label: 'Все продукты' },
                { value: 'osago', label: 'ОСАГО' },
                { value: 'mortgage', label: 'Ипотека' },
                { value: 'kasko', label: 'КАСКО' },
                { value: 'cargoInsurance', label: 'Грузы' },
              ]}
            />
          </div>
        </div>
        {showGraph && (
          <div className="statistic-graph">
            <div className="statistic-graph__chart">
              {(!loadingDataGraph && Object.keys(checkedColumns).length === 0) ? (
                <div className="statistic-graph__alert">
                  Для построения графика выберите хотя бы одну метрику
                </div>
              ) : null}
              {loadingDataGraph ? (
                <div className="statistic-graph__alert">
                  <LoadingSpinner className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} />
                </div>
              ) : null }
              <ResponsiveContainer>
                <AreaChart
                  data={graphData}
                  key={loadingDataGraph}
                  margin={{ left: -20 }}
                >
                  {checkedAgents.length >= 2 && Object.keys(checkedColumns).length === 1
                    ? (
                      <defs>
                        {checkedAgents.map((agent, index) => (
                          <linearGradient key={agent} id={agent} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={agentsColors[index]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={agentsColors[index]} stopOpacity={0.4} />
                          </linearGradient>
                        ))}
                      </defs>
                    )
                    : (
                      <defs>
                        {columns.map((column) => {
                          const id = column.id ? column.id : column.accessor;
                          if (column.showCustomized) {
                            return (
                              <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={columnColors[id]} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={columnColors[id]} stopOpacity={0.4} />
                              </linearGradient>
                            );
                          }
                          return null;
                        })}
                      </defs>
                    )}
                  {checkedAgents.length >= 2 && Object.keys(checkedColumns).length === 1
                    ? (checkedAgents.map((agent, index) => {
                      if (agents.length > 0) {
                        const findedAgent = agents.find((element) => element.id === agent);
                        return <Area key={agent} name={`${findedAgent.last_name} ${findedAgent.first_name} ${findedAgent.middle_name}`} type="monotone" dataKey={agent} stackId={agent} stroke={agentsColors[index]} fill={`url(#${agent})`} />;
                      }
                      return null;
                    }))
                    : (columns.map((column) => {
                      const id = column.id ? column.id : column.accessor;
                      if (checkedColumns[id] && column.showCustomized) {
                        return <Area key={id} name={column.Header} type="monotone" dataKey={id} stackId={id} stroke={columnColors[id]} fillOpacity={1} fill={`url(#${id})`} />;
                      }
                      return null;
                    }))}
                  <XAxis dataKey="created_at" stroke="#000" tick={{ fontSize: mobileSize ? 8 : 14 }} />
                  <YAxis allowDecimals={false} stroke="#000" tick={{ fontSize: mobileSize ? 8 : 14 }} />
                  <Legend />
                  <Tooltip wrapperStyle={{ zIndex: 10000 }} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="statistic-graph__metrics">
              <Scrollbars
                autoHeightMin="100%"
                autoHeightMax="100%"
              >
                <div className="statistic-graph__metrics-container">Выбрано метрик: {Object.keys(checkedColumns).length}</div>
                {columns.map((column) => {
                  const id = column.id ? column.id : column.accessor;
                  if (id !== 'subAgentName' && column.accessor !== 'city' && column.accessor !== 'organization_name' && column.showCustomized) {
                    return (
                      <div className="statistic-graph__metrics-container" key={id}>
                        <div
                          style={{
                            backgroundColor: checkedColumns[id] ? columnColors[id] : '',
                            boxShadow: checkedColumns[id] ? `${columnColors[id]} 0px 0px 0px 1px inset` : 'rgb(215, 216, 217) 0px 0px 0px 1px inset',
                          }}
                          className={classnames({ 'statistic-graph__metrics-item_active': checkedColumns[id] }, 'statistic-graph__metrics-item')}
                          onClick={(e) => this.getDataByMetric(e, id)}
                        >
                          {column.Header}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </Scrollbars>
            </div>
          </div>
        )}
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 1) {
                    window.open(`/agent/${rowInfo.original.id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          ref={(r) => this.reactTable = r}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          defaultFiltered={this.state.filtered}
          className="table"
          data={agents}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          defaultPageSize={50}
          filterable
          minRows={1}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            this.setTableSorting(newSort);
          }}
          onResizedChange={(newResized) => {
            this.setTableSettings(newResized);
          }}
        />
      </>
    );
  }
}
ReportsTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(ReportsTable);
