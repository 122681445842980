import React from 'react';
import styled from 'styled-components';

const PointItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 18px;
    @media (max-width: 767px) {
      margin-bottom: 17px;
    }
  }
  color: ${(props) => (props.color || '#f9da76')};
  list-style: ${(props) => (props.$listStyle || 'disc')};
  div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    p.point-text {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #737373;
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 20px;
        max-width: 72%;
      }
    }
    
    div.info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: -2px;
      margin-left: 20px;
      
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #3d3d3b;
        margin: 0 12px 0 0;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        @media (max-width: 767px) {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
  }
 `;

export default function DashboardPointListItem(props) {
  const {
    children, color, listStyle,
  } = props;

  return (
    <PointItem color={color} $listStyle={listStyle}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </PointItem>
  );
}
