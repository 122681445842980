import React, { useContext, useState } from 'react';
import Modal from 'react-responsive-modal';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../Utils/Helpers';

export default function ModalBlockedForm(props) {
  const {
    showAcceptModalForm,
    setShowAcceptModalForm,
    agent,
  } = props;

  const [reason, setReason] = useState('');
  const [loadingAcceptForm, setLoadingAcceptForm] = useState(false);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const checkAccept = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingAcceptForm(true);
    if (!reason) {
      showModalInfo('Укажите причину!');
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/blocked`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reason,
        agentId: agent.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoadingAcceptForm(false);
        if (response.error) {
          setShowAcceptModalForm(false);
          showModalInfo(response.error, 'error');
        } else {
          setShowAcceptModalForm(false);
          showModalInfo('Пользователь заблокирован!');
          window.location.reload();
        }
      })
      .catch(() => {
        setShowAcceptModalForm(false);
        setLoadingAcceptForm(false);
        showModalInfo('Ошибка', 'error');
      });
  };

  return (
    <Modal
      classNames={{
        overlay: 'modal-window',
        closeButton: 'modalCloseButton',
        modal: 'modal-window-inner wide-window',
      }}
      closeIconSize={16}
      open={showAcceptModalForm}
      onClose={() => setShowAcceptModalForm(false)}
      center
    >
      <h4>Вы уверены?</h4>
      <div className="form-group row">
        <div className="col-md-12">
          <label>Укажите причину блокировки</label>
          <input
            className="form-control"
            required
            value={reason}
            type="text"
            id="reason"
            name="reason"
            placeholder="Превышение прав"
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </div>
      <button
        disabled={loadingAcceptForm}
        className="btn btn-success mr-2"
        type="button"
        onClick={(e) => checkAccept(e)}
      >Да
      </button>
      <button
        disabled={loadingAcceptForm}
        type="button"
        className="btn btn-secondary"
        onClick={() => setShowAcceptModalForm(false)}
      >Нет
      </button>
      {loadingAcceptForm && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
    </Modal>
  );
}
