import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import Products from './Products/Products';
import PolicyContainer from './Products/PolicyContainer';
import Drafts from './Products/Drafts';
import Agent from './Agent/Agent';
import Reports from './Reports/Reports';
import Policies from './Products/Policies';
import Agents from './Agents/Agents';
import Sidebar from './Layout/Sidebar';
import Navbar from './Layout/Navbar';
import Profile from './Profile/Profile';
import withAuth from './Utils/withAuth';
import Admin from './Admin/Admin';
import Footer from './Layout/Footer';
import AboutUs from './Layout/AboutUs';
import News from './News/News';
import NewsDetail from './News/NewsDetail';
import Notifications from './Layout/Notifications';
import WidgetsList from './Widgets/WidgetsList';
import Widget from './Widgets/Widget';
import AdminWidget from './Admin/AdminWidget';
import RequestsList from './Widgets/RequestsList';
import Dkp from './Products/Dkp/Dkp';
import CovidForm from './Products/Covid/CovidForm';
import DkpForm from './Products/Dkp/DkpForm';
import ThemeForm from './Admin/ThemeForm';
import Balance from './Balance/Balance';
import { customFetch } from './Utils/Helpers';
import Dashboard from './Dashboard/Dashboard';
import AgentPoll from './Agent/AgentPoll';
import Bonuses from './Bonuses/Bonuses';
import withUserInfoStoreStore from './Utils/withUserInfoStoreStore';
import PaymentRealizationsDetail from './Admin/PaymentRealizations/PaymentRealizationsDetail';
import InsuranceProgram from './InsuranceProgramFactory/InsuranceProgram';
import ModalInfoList from './Utils/ModalNotification/ModalInfoList';
import ScrollToTop from './Utils/ScrollToTop';
import CheckOsago from './Products/CheckOsago/CheckOsago';
import Mortgage from './Products/Mortgage/Mortgage';
import Universal from './Products/Universal/Universal';
import Zpr from './Products/Zpr/Zpr';
import ServiceTour from './InterfaceTours/ServiceTour';
import Finances from './Products/Finances/Finances';
import KaskoRequest from './Products/KaskoRequest/KaskoRequest';
import NsRequest from './Products/NsRequest/NsRequest';
import InsuranceRequest from './Products/InsuranceRequest/InsuranceRequest';
import Ifl from './Products/Ifl/Ifl';
import FaqKnowledge from './Faq/FaqKnowledge';
import FaqAnswer from './Faq/FaqAnswer';
import FaqEditAdd from './Faq/FaqEditAdd';
import CargoInsurance from './Products/CargoInsurance/CargoInsurance';
import ReferralLink from './Bonuses/ReferralLink';
import CheckProperty from './Products/CheckPropeprty/CheckProperty';
import InsurexWidget from './Products/InsurexWidget/InsurexWidget';
//import useSocket from './Utils/CustomHooks/UseSocket';
import AuthService from './Utils/AuthService';
import RegisterSelfEmployed from './Layout/RegisterSelfEmployed';
import useUserInfoStore from './Stores/UserInfoStore';
import LoadingBanner from './Utils/UiComponents/LoadingBanner';

const ContentContainer = styled.div`
    background-color: #f6f6f6;
    padding-top: 88px;
    transition: padding-left .15s ease;

    @media (min-width: 769px) {
        padding-left: 75px;
        &.full-sidebar{
            padding-left: 283px
        }
    }
    
    @media (max-width: 620px) {
        padding-top: 64px;
    }
`;

const Auth = new AuthService();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: '',
      isFramed: 0,
      sidebarActive: false,
      sidebarIsFull: false,
      modalInfoMessages: [],
    };
    this.serviceTour = React.createRef();
  }

  componentDidMount() {
    let isFramed = false;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    // const { createSocketConnection, listenChanel } = useSocket();
    // createSocketConnection(lsToken);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq_file`, {
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((faq) => {
        this.setState({ faq: faq.url });
      });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/info`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((userInfo) => {
        if (userInfo.message === 'Token Signature could not be verified.') {
          localStorage.removeItem('id_token');
          window.location.href = '/login';
        } else {
          useUserInfoStore.setState({ userInfo });
          const domain = window.location.host.split('.')[0];
          if (userInfo.domain !== domain && domain !== 'localhost:3000' && userInfo.role !== 'admin' && userInfo.role !== 'superadmin') {
            localStorage.removeItem('id_token');
            window.location.href = `https://${userInfo.domain}.polis.online${window.location.pathname}`;
          }
          // listenChanel(`news.${userInfo.id}`, 'newItem');
        }
      });

    if (window.innerWidth > 768) {
      let sidebarIsFullFlag = localStorage.getItem('sidebarIsFull');
      sidebarIsFullFlag = sidebarIsFullFlag === null ? 'false' : sidebarIsFullFlag;
      sidebarIsFullFlag = sidebarIsFullFlag !== 'false';
      this.setState({ sidebarIsFull: sidebarIsFullFlag });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarIsFull } = this.state;
    if (prevState.sidebarIsFull !== sidebarIsFull) {
      localStorage.setItem('sidebarIsFull', sidebarIsFull);
    }
  }

  updateUnreadNewsCount = () => {
    const newUserInfo = { ...this.props.userInfo };
    useUserInfoStore.setState({ newUserInfo });
  };

  toggleSidebar = (sidebarActiveFlag) => {
    const { sidebarIsFull, sidebarActive } = this.state;
    if (!sidebarIsFull && sidebarActiveFlag !== sidebarActive) {
      this.setState({ sidebarActive: sidebarActiveFlag });
    }
  };

  setSidebarFull = (sidebarIsFull) => { this.setState({ sidebarIsFull }); };

  flushMessages = (messages) => {
    this.setState({ modalInfoMessages: messages });
  };

  setTour = (tourName) => {
    this.serviceTour.current.setTour(tourName);
  };

  getTourList = () => this.serviceTour.current.getTourList();

  setDataLoadFunctions = (loadDemoData, loadRealData) => {
    this.serviceTour.current.setDataLoadFunctions(loadDemoData, loadRealData);
  };

  handleLogout = (e) => {
    e.preventDefault();
    const { history } = this.props;
    Auth.logout();
    history.replace('/login');
  };

  render() {
    const {
      sidebarActive, modalInfoMessages, faq, isFramed, sidebarIsFull,
    } = this.state;
    const {
      history, theme, userInfo,
    } = this.props;

    return (
      <LoadingBanner loadingFlag={!theme.id}>
        <div className="wrapper">
          <ServiceTour
            ref={this.serviceTour}
            history={history}
            toggleSidebar={this.toggleSidebar}
            sidebarActive={sidebarActive}
          />
          <Navbar
            faq={faq}
            toggleSidebar={this.toggleSidebar}
            isFramed={isFramed}
            history={history}
            sidebarIsFull={sidebarIsFull}
            sidebarActive={sidebarActive}
            setSidebarFull={this.setSidebarFull}
            handleLogout={this.handleLogout}
          />
          <div className="main d-flex">
            <Sidebar
              faq={faq}
              sidebarActive={sidebarActive}
              getTourList={this.getTourList}
              setTour={this.setTour}
              toggleSidebar={this.toggleSidebar}
              sidebarIsFull={sidebarIsFull}
              setSidebarFull={this.setSidebarFull}
              handleLogout={this.handleLogout}
            />
            <ContentContainer id="content" className={classnames('container-fluid d-flex flex-fill flex-column', { 'full-sidebar': sidebarIsFull })}>
              <Route component={ScrollToTop} />
              <Notifications history={history} />
              <div className="flex-fill content-body">
                <ModalInfoList messages={modalInfoMessages} flushMessages={this.flushMessages} />
                <Route exact path="/register_self_employed" render={(props) => <RegisterSelfEmployed {...props} />} />
                <Route path="/products/" render={(props) => <Products userId={userInfo.id} setTour={this.setTour} {...props} />} />
                <Route exact path="/news" render={(props) => <News {...props} />} />
                <Route exact path="/news/:id" render={(props) => <NewsDetail updateUnreadNewsCount={this.updateUnreadNewsCount} {...props} />} />
                <Route exact path="/covid" render={(props) => <CovidForm {...props} />} />
                <Route exact path="/covid/:id" render={(props) => <CovidForm {...props} />} />
                <Route exact path="/osago" render={(props) => <PolicyContainer policyType="osago" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                <Route exact path="/osago/:id" render={(props) => <PolicyContainer policyType="osago" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                <Route exact path="/accident" render={(props) => <PolicyContainer policyType="accident" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                <Route exact path="/accident/:id" render={(props) => <PolicyContainer policyType="accident" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                {userInfo.full_time_employee ? (
                  <>
                    <Route exact path="/kasko" render={(props) => <PolicyContainer policyType="kasko" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                    <Route exact path="/kasko/:id" render={(props) => <PolicyContainer policyType="kasko" setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                  </>
                ) : null}
                {/* <Route exact path="/dmsStudent" render={(props) => <DmsStudent timestamp={new Date().toString()} {...props} />} />
                <Route exact path="/dmsStudent/:id" render={(props) => <DmsStudent timestamp={new Date().toString()} {...props} />} /> */}
                <Route exact path="/mortgage" render={(props) => <Mortgage setTour={this.setTour} {...props} />} />
                <Route exact path="/mortgage/:id" render={(props) => <Mortgage setTour={this.setTour} {...props} />} />

                <Route exact path="/zpr" render={(props) => <Zpr setTour={this.setTour} {...props} />} />
                <Route exact path="/zpr/:id" render={(props) => <Zpr setTour={this.setTour} {...props} />} />

                <Route exact path="/universal" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/universal/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/cargoInsurance" render={(props) => <CargoInsurance setTour={this.setTour} {...props} />} />
                <Route exact path="/cargoInsurance/:id" render={(props) => <CargoInsurance setTour={this.setTour} {...props} />} />

                <Route exact path="/dago" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/dago/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/vzr" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/vzr/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/dmsStudent" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/dmsStudent/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/ns" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/ns/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/telemedicine" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/telemedicine/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/gruz" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/gruz/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/infullLizing" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/infullLizing/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/infullBroker" render={(props) => <Universal setTour={this.setTour} {...props} />} />
                <Route exact path="/infullBroker/:id" render={(props) => <Universal setTour={this.setTour} {...props} />} />

                <Route exact path="/ifl" render={(props) => <Ifl setTour={this.setTour} {...props} />} />
                <Route exact path="/ifl/:id" render={(props) => <Ifl setTour={this.setTour} {...props} />} />
                <Route path="/drafts" render={(props) => <Drafts {...props} />} />
                <Route path="/reports" render={(props) => <Reports {...props} />} />
                <Route path="/agents" render={(props) => <Agents {...props} />} />
                {(theme.domain !== 'samoletplus' || userInfo.id === 8270) ? (
                  <Route path="/bonuses" render={(props) => <Bonuses setTour={this.setTour} {...props} history={history} />} />
                ) : null}
                <Route path="/referral" render={(props) => <ReferralLink setTour={this.setTour} {...props} history={history} />} />
                <Route path="/policies" render={(props) => <Policies domain={theme.domain} customField={theme.custom_field ? theme.custom_field.name : null} {...props} />} />
                {userInfo.role === 'superadmin' ? (
                  <>
                    <Route exact path="/admin/widgets/:id" render={(props) => <AdminWidget {...props} />} />
                    <Route exact path="/paymentRealizations/:id" render={(props) => <PaymentRealizationsDetail {...props} history={history} />} />
                  </>
                ) : null}
                {(userInfo.role === 'superadmin' || userInfo.role === 'admin') ? (
                  <>
                    <Route exact path="/admin" render={(props) => <Admin {...props} />} />
                    <Route exact path="/themes/:id" render={(props) => <ThemeForm {...props} />} />
                  </>
                ) : null}
                <Route path="/agent/:id" render={(props) => <Agent domain={theme.domain} customField={theme.custom_field ? theme.custom_field.name : null} {...props} />} />
                <Route path="/profile" render={(props) => <Profile history={history} {...props} />} />
                <Route exact path="/dkp" render={(props) => <Dkp {...props} />} />
                <Route exact path="/dkp/:id" render={(props) => <DkpForm {...props} />} />
                <Route path="/about_us" component={AboutUs} />
                <Route exact path="/faq_knowledge" render={(props) => <FaqKnowledge history={history} {...props} />} />
                <Route exact path="/faq_knowledge/:id" render={(props) => <FaqKnowledge history={history} {...props} />} />
                <Route exact path="/faq_answer/add" render={(props) => <FaqEditAdd history={history} {...props} />} />
                <Route exact path="/faq_answer/:id([0-9]+)" render={(props) => <FaqAnswer history={history} {...props} />} />
                <Route path="/faq_answer/:id/edit" render={(props) => <FaqEditAdd history={history} {...props} />} />
                {userInfo.show_balance === 1 ? (
                  <Route path="/balance" render={(props) => <Balance {...props} />} />
                ) : null}
                <Route exact path="/finances" render={(props) => <Finances {...props} />} />
                <Route exact path="/insurance_request" render={(props) => <InsuranceRequest {...props} />} />
                <Route exact path="/ns_request" render={(props) => <NsRequest {...props} />} />
                <Route exact path="/check_property" render={(props) => <CheckProperty setTour={this.setTour} history={history} {...props} />} />
                <Route exact path="/check_property/:id" render={(props) => <CheckProperty setTour={this.setTour} history={history} {...props} />} />
                <Route exact path="/insurex_widget" render={(props) => <InsurexWidget {...props} />} />
                <Route exact path="/kasko_request" render={(props) => <KaskoRequest history={history} {...props} />} />
                <Route exact path="/widgets/" render={(props) => <WidgetsList {...props} setTour={this.setTour} />} />
                <Route exact path="/widgets/:id" render={(props) => <Widget {...props} setTour={this.setTour} setDataLoadFunctions={this.setDataLoadFunctions} />} />
                <Route exact path="/requests-list" render={(props) => <RequestsList {...props} />} />
                <Route exact path="/dashboard" render={(props) => <Dashboard setTour={this.setTour} {...props} />} />
                {(userInfo.role === 'admin' || userInfo.role === 'superadmin' || (userInfo.curator_obj.full_time_employee === 1 && userInfo.full_time_employee === 0)) ? (
                  <Route exact path="/agent-poll" render={(props) => <AgentPoll history={history} {...props} />} />
                ) : null}
                {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                  <Route exact path="/insurance-program/:id" render={(props) => <InsuranceProgram history={history} {...props} />} />
                ) : null}
                <Route exact path="/check-osago" render={(props) => <CheckOsago {...props} />} />
              </div>
              <Footer history={history} />
            </ContentContainer>
          </div>
        </div>
      </LoadingBanner>
    );
  }
}
export default withAuth(withUserInfoStoreStore(App));
