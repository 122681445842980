import React, {
  useImperativeHandle, forwardRef, useEffect, useState, useContext,
} from 'react';
import LoadingSpinner from '../LoadingSpinner';
import PageTopAnswers from './PageTopAnswers';
import TableAnswers from './TableAnswers';
import PageWrapperAnswers from './PageWrapperAnswers';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';

function IntegrationAnswers(props, ref) {
  const {
    copyDraft, infoAnswers, typeIntegration, policy,
  } = props;

  const copyForm = () => {
    copyDraft();
  };

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  const [integrationAnswers, setIntegrationAnswers] = useState([]);
  const [calcErrors, setCalcErrors] = useState({
    notExist: false,
  });
  const [loadingInfo, setLoadingInfo] = useState(true);
  const { showModalInfo } = useContext(ModalMessagesContext);

  useEffect(() => {
    if (infoAnswers?.blocksInfo && policy.id) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      if (typeIntegration === 'mortgage') {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/${policy.id}/integration-answers`, {
          headers: {
            Authorization: lsToken,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error === 'not_exist') {
              setCalcErrors((prev) => ({
                ...prev,
                notExist: true,
              }));
            } else {
              setCalcErrors((prev) => ({
                ...prev,
                notExist: false,
              }));
              setIntegrationAnswers([...response]);
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          })
          .finally(() => {
            setLoadingInfo(false);
          });
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/integrationAnswers`, {
          headers: {
            Authorization: lsToken,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error === 'not_exist') {
              setCalcErrors((prev) => ({
                ...prev,
                notExist: true,
              }));
            } else {
              setCalcErrors((prev) => ({
                ...prev,
                notExist: false,
              }));
              setIntegrationAnswers([...response.filteredAnswers]);
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          })
          .finally(() => {
            setLoadingInfo(false);
          });
      }
    }
  }, [infoAnswers, policy.id]);

  return (
    <>
      {loadingInfo
        ? <LoadingSpinner className="loading-circle ml-auto mr-auto mt-2" type="spin" height={70} width={70} />
        : (
          <>
            {!calcErrors?.notExist ? (
              <PageWrapperAnswers>
                <PageTopAnswers infoAnswers={infoAnswers} />
                <TableAnswers integrationAnswers={integrationAnswers} />
              </PageWrapperAnswers>
            ) : <h2>Расчет не производился</h2>}
          </>
        )}
    </>
  );
}

export default forwardRef(IntegrationAnswers);
