import React from 'react';
import styled from 'styled-components';

const Error = styled.span`
  ${(props) => (props.type === 'select' || props.type === 'datapicker' || props.type === 'dadata' ? '' : `
    top: 100%;
    left: 0;
  `)}
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  font-size: 12px;
  line-height: 20px;
  color: #ff4d00;
  -webkit-transition: opacity .15s ease;
  transition: opacity .15s ease;
`;

export default function FieldErrorMessage(props) {
  const { children, type } = props;

  return (
    <Error type={type}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </Error>
  );
}
