import styled, {css} from 'styled-components';
import React from 'react';

export const TableTbodyAnswersTr = styled.tr`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    padding: 24px 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    background-color: #fff;
  }
  border-bottom: 1px solid #e9e9e9;

  ${(props) => props.block === 'results' && css`
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    @media (max-width: 1023px) {
      min-height: auto;
      padding: 16px;
    }
    @media (max-width: 767px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
      padding: 12px 0;
      color: #fff;
      background-color: transparent;
      border: 0;
      border-top: 1px solid #2f2f2d;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
    }
  `}

  ${(props) => props.active && css`
    position: relative;
    &:before {
      background-color: var(--subColor);
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      content: "";
      -webkit-transition: background-color .15s ease;
      transition: background-color .15s ease;
      @media (max-width: 767px) {
        background-color: transparent;
        width: 0;
      }
    }
  `}
`;

export default function TableTbodyTr(props) {
  const {
    children, block, active,
  } = props;

  return (
    <TableTbodyAnswersTr block={block} active={active}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTbodyAnswersTr>
  );
}
