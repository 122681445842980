import React, {
  forwardRef, useState, useContext, useMemo, useEffect,
} from 'react';
import Select from 'react-select';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function WidgetStocks({ widget }) {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [stocksOption, setStocksOption] = useState([]);
  const [currentStocks, setCurrentStocks] = useState([]);
  const [load, setLoad] = useState(false);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const save = () => {
    const stocksSave = [];
    Array.isArray(currentStocks) && currentStocks.map((stock) => {
      stocksSave.push(stock.value);
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/setStocks`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        widgetId: widget.id,
        stocks: stocksSave,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('Акции успешно сохранены');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      });
  };

  useEffect(() => {
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/stocks`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        widgetId: widget.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          Array.isArray(response) && response.map((item) => {
            setStocksOption((stock) => [...stock, { label: `${item.description + ' ' + item.number + ' рублей '} (${item.insurance.name} - ${item.bank.name} - ${item.risk.name})`, value: item.id }]);
          });
          setLoad(true);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      });
  }, []);

  useEffect(() => {
    if (load) {
      Array.isArray(widget.stocks) && widget.stocks.forEach((item) => {
        stocksOption.find((value) => {
          value.value === item && setCurrentStocks((stock) => [...stock, { label: value.label, value: value.value }]);
        });
      });
      setLoad(false);
    }
  }, [stocksOption, load]);

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-8  mb-3 mb-lg-0">
          <label htmlFor="stocks">Выбор акций</label>
          <Select
            classNamePrefix="react-select"
            styles={customStyles}
            name="stocks"
            noOptionsMessage={() => 'Не найдено'}
            inputId="flatMaterial"
            value={currentStocks}
            isMulti
            onChange={(e) => setCurrentStocks(e)}
            options={stocksOption}
            placeholder="Акция"
          />
        </div>
      </div>
      <div className="form-group row">
        <button disabled={load} type="button" className="btn btn-success ml-3 mb-3 mt-3" onClick={save}>Сохранить</button>
        {load ? <LoadingSpinner type="spin" height={38} width={38} className="loading-circle ml-3" /> : null}
      </div>
    </>
  );
}

export default forwardRef(WidgetStocks);
