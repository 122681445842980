import styled from 'styled-components';
import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import { shallow } from 'zustand/shallow';
import Button, { ButtonBlock } from '../Button';
import CustomIcon from '../CustomIcon';
import useUserInfoStore from '../../Stores/UserInfoStore';

export const TableTop = styled.div`
  padding: 20px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
  box-shadow: 0 6px 12px rgba(143, 143, 143, .08);
    .page-popup .tippy-content {
        background-color: transparent;
    }
    @media (max-width: 1023px) {
        padding: 16px;
        flex-wrap: wrap;
    }
    @media (max-width: 767px) {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }
    ${ButtonBlock} {
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 50%;
        -webkit-box-shadow: none;
        box-shadow: none;
        min-width: initial;
        @media (max-width: 767px) {
            background-color: #fff;
        }
        @media (max-width: 1023px) {
            width: 32px;
            height: 32px;
        }
        svg {
            color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#0bd95d')};
            height: 20px;
            width: 20px;
            @media (max-width: 1023px) {
                height: 24px;
                width: 24px;
            }
        }
        &:hover {
            background-color: #e9e9e9;
            border: 1px solid #e9e9e9;
        }
    }
`;

export const TablePopupButton = styled.div`
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-right: 24px;
    @media (max-width: 1023px) {
        margin-bottom: 0;
        margin-right: 0;
    }
    
`;

export const TableTopDescription = styled.div`
  position: relative;
  max-width: none;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 2;
  flex-shrink: 2;
  overflow: hidden;
    @media (max-width: 1023px) {
        max-width: calc(100% - 32px);
        margin: 0;
        width: 100%;
    }
    @media (max-width: 767px) {
        width: auto;
    }
`;

export const TableTopList = styled.ul`
  display: flex;
  -ms-flex-negative: 2;
  flex-shrink: 2;
  margin: 0 22px 0 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
    &:after {
        opacity: 1;
        visibility: visible;
        content: "...";
        position: absolute;
        top: calc(50% - 11px);
        right: 12px;
        width: 10px;
        height: 22px;
        color: #a3a3a3;
        -webkit-transition: visibility .15s ease, opacity .15s ease;
        transition: visibility .15s ease, opacity .15s ease;
    }
    @media (max-width: 767px) {
        margin-right: 15px;
    }
`;

export const TableTopListItem = styled.li`
    margin-right: 14px;
    position: relative;
    flex-shrink: 0;
    span {
        color: #a3a3a3;
        @media (max-width: 767px) {
            font-size: 13px;
            line-height: 20px;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #a3a3a3;
        right: -7px;
    }
`;

export const ModalContainerMultiply = styled.div`
    backdrop-filter: blur(20px);
    background-color: rgba(40, 40, 38, .83);
    border-radius: 8px;
    padding: 0;
    overflow: hidden;
    scrollbar-color: #737373 transparent;
    position: relative;

    ${ButtonBlock} {
        top: 4px;
        right: 4px;
        z-index: 2;
        color: #a3a3a3;
        cursor: pointer;
        width: 44px;
        height: 44px;
        padding: 0;
        background-color: transparent !important;
        border: 0 !important;
        border-radius: 50%;
        -webkit-transition: color .15s ease;
        transition: color .15s ease;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;

        svg {
            width: 30px;
            height: 30px;
            color: ${(props) => (props.theme.mainColor ? props.theme.mainColor : '#a3a3a3')} !important;
            cursor: pointer;

            &:hover {
                color: #949090 !important;
            }
        }

        &:hover {
            background-color: transparent !important;
            border: 0;
        }
    }
`;

export const ModalContainerMultiplyBlock = styled.div`
    position: relative;
    margin: 24px 0;
    padding: 0 24px;
    &:after {
        position: absolute;
        left: 0;
        bottom: -24px;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #2f2f2d;
    }
`;

export const ModalContainerMultiplyBlockPopup = styled.div`
    overflow: auto;
    scrollbar-color: #737373 transparent;
    scrollbar-width: thin;
    max-height: 542px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: flex;
`;

export const ModalContainerMultiplyBlockTitle = styled.div`
    color: #a3a3a3;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin: 0 0 16px;
`;

export const ModalContainerMultiplyBlockList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        margin: 0 0 8px;
        span.term {
            color: #cfcfcf;
        }
        span.def {
            color: #fff;
        }
    }
`;

export default function PageTopAnswers(props) {
  const {
    children, disabled, width, infoAnswers,
  } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <TableTop disabled={disabled} width={width} theme={theme}>
      <TableTopDescription>
        <TableTopList>
          {infoAnswers?.blocksInfo ? infoAnswers.blocksInfo[0].map((item) => (<TableTopListItem><span>{item.title}: {item.value}</span></TableTopListItem>)) : null}
        </TableTopList>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </TableTopDescription>
      <Tippy
        style
        placement="bottom-end"
        interactive
        trigger="click"
        className="page-popup page-popup--agents-list page-popup-user-notifications page-popup_auto-width"
        arrow={false}
        offset={[0, 12]}
        content={(
          <ModalContainerMultiply>
            <Button type="button" onClick={hideAll}><XCircleIcon height={24} color="#a3a3a3" /></Button>
            <ModalContainerMultiplyBlockPopup>
              {infoAnswers?.blocksInfo ? infoAnswers.blocksInfo.map((item, index) => (
                <ModalContainerMultiplyBlock>
                  <ModalContainerMultiplyBlockTitle>{infoAnswers?.headers[index]}</ModalContainerMultiplyBlockTitle>
                  <ModalContainerMultiplyBlockList>
                    {infoAnswers?.blocksInfo[index].map((listItem) => (
                      <li>
                        <span className="term">{listItem.title}: </span>
                        <span className="def">{listItem.value}</span>
                      </li>
                    ))}
                  </ModalContainerMultiplyBlockList>
                </ModalContainerMultiplyBlock>
              )) : null}
            </ModalContainerMultiplyBlockPopup>
          </ModalContainerMultiply>
            )}
      >
        <TablePopupButton>
          <Button type="button"><CustomIcon icon="icon-info" width="20px" height="20px" pureSvg /></Button>
        </TablePopupButton>
      </Tippy>
    </TableTop>
  );
}
