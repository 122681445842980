import React from 'react';
import styled, { keyframes } from 'styled-components';

const disableBreakpointAnimation = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const OverlayDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .45);
  z-index: 1200;
  cursor: pointer;
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  -webkit-animation: .3s disableBreakpointAnimation;
  animation: .3s disableBreakpointAnimation;
  -webkit-transition: .15s ease;
  transition: .15s ease;
`;

export default function Overlay(props) {
  const { active } = props;

  return (
    <OverlayDiv active={active} />
  );
}
