import React from 'react';
import { styled } from 'styled-components';

const LabelBlock = styled.label`
  margin-bottom: 4px;
  cursor: pointer;
  display: flex;
`;

export default function Label(props) {
  const {
    label, dataKey, children,
  } = props;

  return (
    <LabelBlock htmlFor={dataKey}>
      {label}
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </LabelBlock>
  );
}
