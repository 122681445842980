import React, { Component } from 'react';
import queryString from 'query-string';
import LoadingSpinner from '../Layout/LoadingSpinner';
import 'rc-tooltip/assets/bootstrap.css';
import AuthService from '../Utils/AuthService';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const Auth = new AuthService(process.env.REACT_APP_API_DOMAIN);

class ResetEmail extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      loading: false,
      resetToken: queryString.parse(this.props.location.search).resetToken,
      newEmail: queryString.parse(this.props.location.search).newEmail,
      oldEmail: queryString.parse(this.props.location.search).oldEmail,
    };
  }

  componentDidMount() {
    const { oldEmail, resetToken, newEmail } = this.state;
    const { history } = this.props;
    const { showModalInfo } = this.context;
    this.setState({ loading: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/profile/changeEmail`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldEmail,
        resetToken,
        newEmail,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'success') {
          showModalInfo('Адрес электронной почты успешно изменен, через мгновение вы будете переадресованы на страницу авторизации');
          setTimeout(() => {
            const token = localStorage.getItem('id_token');
            const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
            if (Auth.isTokenExpired(token)) {
              return fetch(`${process.env.REACT_APP_API_DOMAIN}/refresh`, {
                method: 'get',
                headers: {
                  Authorization: lsToken,
                },
              })
                .then((response) => response.json())
                .then((response) => {
                  localStorage.setItem('id_token', response.token);
                  history.replace('/profile?activeTab=2');
                })
                .catch(() => {
                  window.location.replace('/login');
                });
            }
            history.replace('/profile?activeTab=2');
          }, 6000);
        } else if (response.error) {
          showModalInfo(response.error);
        } else {
          showModalInfo('Произошла ошибка, повторите попытку.');
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      loading,
    } = this.state;
    const { theme } = this.props;
    const formPhoneVerification = (
      <form className="form-signin" onSubmit={this.handleFormPhoneVerificationSubmit}>
        <img className="mb-4" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logo}`} alt="" />
        {loading && (<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={38} width={38} />)}
      </form>
    );
    return (
      <div className="login-page text-center">
        {formPhoneVerification}
      </div>
    );
  }
}

ResetEmail.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(ResetEmail);
