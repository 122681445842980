import { useEffect } from 'react';

export default function ScrollToTop(props) {
  const { location } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}
