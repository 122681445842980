import styled, { css } from 'styled-components';
import React from 'react';

export const TableTheadAnswersTr = styled.tr`
   display: flex;
   padding: 0;
   @media (max-width: 1023px) {
      width: 100%;
   }
   @media (max-width: 767px) {
     display: none;
   }

  ${(props) => props.block === 'results' && css`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f3f3f3;
    border: 1px solid #e9e9e9;
    @media (max-width: 1023px) {
      width: max-content;
    }
    @media (max-width: 767px) {
      display: none;
    }
  `}
`;

export default function TableTheadTr(props) {
  const {
    children, block,
  } = props;

  return (
    <TableTheadAnswersTr block={block}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTheadAnswersTr>
  );
}
