import React from 'react';
import styled from 'styled-components';

const ContainerTooltip = styled.div`
  padding: 4px;
  background: rgba(40, 40, 38, .83) !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px) !important;
  border-radius: 10px;
  -webkit-transition: visibility .15s ease, opacity .15s ease;
  transition: visibility .15s ease, opacity .15s ease;
 `;

const ContentTooltip = styled.div`
  padding: 5px 2px 4px 13px;
  overflow: auto;
  scrollbar-color: #737373 transparent;
  scrollbar-width: thin;
  margin: 0 -4px;
  border-radius: 10px;
    p.title {
      color: #cfcfcf;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      word-spacing: 4px;
    }
  
  p.text {
    margin-right: 12px;
    margin-top: 3px;
    margin-bottom: 0;
    color: #fff;
    width: max-content;
  }
 `;

export default function DashboardTooltipGraphs(props) {
  const {
    children, disabled,
  } = props;

  return (
    <ContainerTooltip disabled={disabled}>
      <ContentTooltip>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </ContentTooltip>
    </ContainerTooltip>
  );
}
