import React, { Component } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { format, parse } from 'date-fns';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import queryString from 'query-string';
import { customFetch } from '../Utils/Helpers';
import WidgetAnalyticBlock from './WidgetAnalyticBlock';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';

export default class WidgetAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      activeFilterTab: '2',
      analyticsData: [],
      filtered: [],
      uniqueFlag: false,
      analyticsSummary: {
        loaded: 0,
        started: 0,
        searching: 0,
        calculated: 0,
        choosing: 0,
        offline: 0,
        buying: 0,
        realBuying: 0,
        requestConversion: 0,
        realBuyingConversion: 0,
      },
      analyticBlocksNames: {
        loaded: 'Показы',
        started: 'Взаимодействия',
        searching: 'Попытка расчёта',
        calculated: 'Успешный расчёт',
        choosing: 'Выбор варианта',
        offline: 'Заявки на менеджера',
        buying: 'Нажатие на кнопку купить',
        realBuying: 'Продажи',
        requestConversion: 'Конверсия в заявки %',
        realBuyingConversion: 'Конверсия в продажи %',
      },
    };
  }

  componentDidMount() {
    const { filtered } = this.state;
    const { setTour, setDataLoadFunctions } = this.props;

    const urlParams = queryString.parse(window.location.search);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const currentDate = date.getDate();
    const currentWeekDay = date.getDay();
    const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    const weekStart = new Date(new Date(date).setDate(date.getDate() - lessDays));
    let dateFrom = format(parse(`${weekStart.getDate()}.${weekStart.getMonth() + 1}.${weekStart.getFullYear()}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let dateTo = format(parse(`${currentDate}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '2' });
    }
    let startDate = null;
    let endDate = null;
    Object.keys(urlParams).forEach((filter) => {
      if (filter === 'created_at') {
        startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
        endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
        dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
        dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
        this.setState({
          startDate,
          endDate,
        });
      } else {
        filtered.push({ id: filter, value: urlParams[filter] });
      }
    });
    if (startDate === null && endDate === null) {
      this.setState({ startDate: parse(dateFrom, 'yyyy-MM-dd', new Date()), endDate: parse(dateTo, 'yyyy-MM-dd', new Date()) });
    }
    this.setState({ filtered }, () => {
      this.getAnalytics(dateFrom, dateTo);

      setTour('widget-analytics');
      setDataLoadFunctions(this.loadDemoData, this.loadRealData);
    });
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.widget.policy_type.code === 'dkp') {
      const { analyticBlocksNames } = prevState;
      analyticBlocksNames.choosing = 'Переход к оформлению ДКП';
      analyticBlocksNames.realBuying = 'Оформление ДКП';
      return analyticBlocksNames;
    }
    if (props.widget.policy_type.code === 'kbm') {
      const { analyticBlocksNames } = prevState;
      analyticBlocksNames.calculated = 'Получен результат расчета КБМ';
      analyticBlocksNames.choosing = 'Отправлен запрос на восстановление КБМ';
      analyticBlocksNames.buying = 'Переход к оформлению ОСАГО';
      analyticBlocksNames.realBuying = 'КБМ восстановлен';
      return analyticBlocksNames;
    }
    if (props.widget.policy_type.code === 'property') {
      const { analyticBlocksNames } = prevState;
      analyticBlocksNames.calculated = 'Получен результат проверки недвижимости';
      analyticBlocksNames.choosing = 'Отправлен запрос на проверку недвижимости';
      analyticBlocksNames.buying = 'Переход к услуге';
      analyticBlocksNames.realBuying = 'Данные успешно получены';
      return analyticBlocksNames;
    }
    return null;
  }

  loadDemoData = () => {
    this.getAnalytics(null, null, true);
  };

  loadRealData = () => {
    const { startDate, endDate } = this.state;
    let dateFrom = null;
    let dateTo = null;
    if (startDate && endDate) {
      dateFrom = format(startDate, 'yyyy-MM-dd');
      dateTo = format(endDate, 'yyyy-MM-dd');
    } else {
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      const currentDate = date.getDate();
      const currentWeekDay = date.getDay();
      const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
      const weekStart = new Date(new Date(date).setDate(date.getDate() - lessDays));
      dateFrom = format(parse(`${weekStart.getDate()}.${weekStart.getMonth() + 1}.${weekStart.getFullYear()}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
      dateTo = format(parse(`${currentDate}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    }
    this.getAnalytics(dateFrom, dateTo, false);
  };

  getAnalytics = (dateFrom, dateTo, demo = false) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { widget } = this.props;
    const { uniqueFlag } = this.state;

    let link = `${process.env.REACT_APP_API_DOMAIN}/widgets/get-widget-analytics?&dateFrom=${dateFrom}&dateTo=${dateTo}&widgetId=${widget.id}&widgetType=osago&unique=${uniqueFlag}`;
    if (demo) {
      link = `${process.env.REACT_APP_API_DOMAIN}/demo-data/widget-analytics`;
    }

    this.setState({
      analyticsData: [],
    }, () => {
      customFetch(link, {
        method: 'get',
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const resultArr = data.resultArr.map((row) => {
            let requestConversion = 0;
            let realBuyingConversion = 0;
            if (row.offline !== 0) {
              requestConversion = Math.round((row.offline / row.started) * 100);
            }
            if (row.realBuying !== 0) {
              realBuyingConversion = Math.round((row.realBuying / row.started) * 100);
            }
            return {
              ...row,
              requestConversion,
              realBuyingConversion,
            };
          });
          this.setState({
            analyticsData: resultArr,
            analyticsSummary: {
              loaded: data.loadedAll,
              started: data.startedAll,
              searching: data.searchingAll,
              calculated: data.calculatedAll,
              choosing: data.choosingAll,
              offline: data.offlineAll,
              buying: data.buyingAll,
              realBuying: data.realBuyingAll,
              requestConversion: Math.round((data.offlineAll / data.startedAll) * 100),
              realBuyingConversion: Math.round((data.realBuyingAll / data.startedAll) * 100),
            },
          });
        });
    });
  };

  toggleFilter = (tab) => {
    const { activeFilterTab, filtered } = this.state;
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();
    let lastButOneMonth = date.getMonth();
    let currentYear = date.getFullYear();
    const currentWeekDay = date.getDay();
    const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    const weekStart = new Date(new Date(date).setDate(date.getDate() - lessDays));
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
    const startOfCurrentWeek = parse(`${weekStart.getDate()}.${weekStart.getMonth() + 1}.${weekStart.getFullYear()}`, 'dd.MM.yyyy', new Date());
    const startEndCurrentDate = parse(`${currentDate}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
    let dateFrom;
    let dateTo;
    if (activeFilterTab !== tab) {
      this.setState({ activeFilterTab: tab });
      let filterDate = null;
      if (tab === '1') {
        dateFrom = format(startEndCurrentDate, 'yyyy-MM-dd');
        dateTo = format(startEndCurrentDate, 'yyyy-MM-dd');
        this.setState({
          startDate: startEndCurrentDate,
          endDate: startEndCurrentDate,
        });
        filterDate = `${currentDate}.${currentMonth}.${currentYear} ${currentDate}.${currentMonth}.${currentYear}`;
      } else if (tab === '2') {
        dateFrom = format(startOfCurrentWeek, 'yyyy-MM-dd');
        dateTo = format(startEndCurrentDate, 'yyyy-MM-dd');
        this.setState({
          startDate: startOfCurrentWeek,
          endDate: startEndCurrentDate,
        });
        filterDate = `${weekStart.getDate()}.${weekStart.getMonth() + 1}.${weekStart.getFullYear()} ${currentDate}.${currentMonth}.${currentYear}`;
      } else if (tab === '3') {
        dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
        dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateCurrentMonth,
          endDate: endDateCurrentMonth,
        });
        filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
      } else if (tab === '4') {
        if (currentMonth === 1) {
          currentYear -= 1;
          lastButOneMonth = '12';
          lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
          startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        }
        dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
        dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateLastButOneMonth,
          endDate: endDateLastButOneMonth,
        });
        filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
      } else if (tab === '5') {
        dateFrom = null;
        dateTo = null;
        this.setState({
          startDate: null,
          endDate: date,
        });
        filterDate = `${format(date, 'dd.MM.yyyy')}`;
      }
      const newFiltered = filtered;
      let findedIndex = -1;
      let findedIndexDateTab = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'created_at') {
          findedIndex = i;
        }
        if (filter.id === 'dateTab') {
          findedIndexDateTab = i;
        }
      });
      if (findedIndex !== -1) {
        if (filterDate === null) {
          newFiltered.splice(findedIndex, 1);
        } else {
          newFiltered[findedIndex] = { id: 'created_at', value: filterDate };
        }
      } else if (filterDate !== null) {
        newFiltered.push({ id: 'created_at', value: filterDate });
      }
      if (findedIndexDateTab !== -1) {
        newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
      } else {
        newFiltered.push({ id: 'dateTab', value: tab });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (filter.id === 'created_at' && tab === '5') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
          }
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
      this.setState({ filtered: newFiltered });
      this.getAnalytics(dateFrom, dateTo);
    }
  };

  handleChangeStart = (date) => {
    const { endDate, filtered } = this.state;
    this.setState({ startDate: date });
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateTo = null;
    const dateFrom = format(date, 'yyyy-MM-dd');
    if (endDate !== null) {
      filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
      dateTo = format(endDate, 'yyyy-MM-dd');
    }
    this.getAnalytics(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'created_at') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'created_at', value: filterDate };
    } else {
      newFiltered.push({ id: 'created_at', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (endDate === null && filter.id === 'created_at') {
          queryParams = `${queryParams + filter.id}=${filter.value} null`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${this.props.tab}`;
    const { history } = this.props;
    history.push({ search: queryParams });
  };

  handleChangeEnd = (date) => {
    const { startDate, filtered } = this.state;
    this.setState({ endDate: date });
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateFrom = null;
    const dateTo = format(date, 'yyyy-MM-dd');
    if (startDate !== null) {
      filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
      dateFrom = format(startDate, 'yyyy-MM-dd');
    }
    this.getAnalytics(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'created_at') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'created_at', value: filterDate };
    } else {
      newFiltered.push({ id: 'created_at', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (startDate === null && filter.id === 'created_at') {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${this.props.tab}`;
    const { history } = this.props;
    history.push({ search: queryParams });
  };

  changeUniqueFlag = () => {
    this.setState((prev) => ({ uniqueFlag: !prev.uniqueFlag }), () => {
      const { startDate, endDate } = this.state;
      let dateFrom = null;
      let dateTo = null;
      if (endDate) {
        dateTo = format(endDate, 'yyyy-MM-dd');
      }
      if (startDate) {
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }
      this.getAnalytics(dateFrom, dateTo, false);
    });
  };

  render() {
    const {
      activeFilterTab,
      startDate,
      endDate,
      analyticsData,
      analyticsSummary,
      analyticBlocksNames,
      uniqueFlag,
    } = this.state;

    const { widget } = this.props;

    let grafHeight = window.innerWidth / 2;
    if (grafHeight > 400) {
      grafHeight = 400;
    }

    const CustomTooltip = (props) => {
      if (props.active && props.payload && props.payload.length) {
        const newPayload = props.payload.map((item, i, array) => {
          let newValue = item.value;
          if (i > 0) {
            const persentage = array[0].value ? item.value * 100 / array[0].value : 0;
            newValue = `${item.value} (${persentage.toFixed(2)}%)`;
          }
          return (
            <li
              key={item.dataKey}
              className="recharts-tooltip-item"
              style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: item.color || '#000' }}
            >
              <span className="recharts-tooltip-item-name">{item.name}</span>
              <span className="recharts-tooltip-item-separator"> : </span>
              <span className="recharts-tooltip-item-value">{newValue}</span>
            </li>
          );
        });

        // we render the default, but with our overridden payload
        return (
          <div className="recharts-tooltip-wrapper">

            <div
              className="recharts-default-tooltip"
              style={{ margin: '0px', padding: '10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}
            >
              <p className="recharts-tooltip-label" style={{ margin: '0px' }}>{props.label}</p>
              <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
                {newPayload}
              </ul>
            </div>

          </div>
        );
      }
      return null;
    };

    return (
      <>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggleFilter('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Сегодня
            </div>
            <div
              onClick={() => { this.toggleFilter('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Текущая неделя
            </div>
            <div
              onClick={() => { this.toggleFilter('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('4'); }}
              className={classnames({ active: activeFilterTab === '4' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('5'); }}
              className={classnames({ active: activeFilterTab === '5' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2 widget-analytics_dt_step_1">
            <span>Точные даты с</span>
            <DatePicker
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="form-inline mt-2 d-inline-flex">
            <span className="switch-text">Уникальные лиды</span>
            <label className="switch mr-2">
              <input type="checkbox" checked={uniqueFlag} />
              <span onClick={this.changeUniqueFlag} className="slider round" />
            </label>
          </div>
        </div>
        <div style={{ width: '100%', height: `${grafHeight}px` }}>
          {analyticsData.length > 0 ? (
            <ResponsiveContainer height={grafHeight}>
              <AreaChart
                data={analyticsData}
                margin={{
                  top: 10, right: 30, left: 0, bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorLoaded" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FFB833" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#FFB833" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorStarted" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#334FFF" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#334FFF" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorSearching" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#c0eb34" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#c0eb34" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorCalculated" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#33FF93" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#33FF93" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorChoosing" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#a70fff" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#a70fff" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorOffline" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#33AFFF" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#33AFFF" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorBuying" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#72c267" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#72c267" stopOpacity={0.4} />
                  </linearGradient>
                  <linearGradient id="colorRealBuying" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ff5f03" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ff5f03" stopOpacity={0.4} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="created_at" stroke="#000" />
                <YAxis allowDecimals={false} stroke="#000" />
                <Tooltip content={<CustomTooltip />} />
                <Area name={analyticBlocksNames.loaded} type="monotone" dataKey="loaded" stackId="1" stroke="#FFB833" fillOpacity={1} fill="url(#colorLoaded)" dot={{ stroke: '#FFB833', strokeWidth: 2 }} />
                <Area name={analyticBlocksNames.started} type="monotone" dataKey="started" stackId="2" stroke="#334FFF" fillOpacity={1} fill="url(#colorStarted)" dot={{ stroke: '#334FFF', strokeWidth: 2 }} />
                {widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                  <Area name={analyticBlocksNames.searching} type="monotone" dataKey="searching" stackId="6" stroke="#c0eb34" fillOpacity={1} fill="url(#colorSearching)" dot={{ stroke: '#c0eb34', strokeWidth: 2 }} />
                ) : null}
                {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'kbm' || widget.policy_type.code === 'dmsStudent'
                 || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                   <Area name={analyticBlocksNames.calculated} type="monotone" dataKey="calculated" stackId="3" stroke="#33FF93" fillOpacity={1} fill="url(#colorOffline)" dot={{ stroke: '#33FF93', strokeWidth: 2 }} />
                  ) : null}
                <Area name={analyticBlocksNames.choosing} type="monotone" dataKey="choosing" stackId="7" stroke="#a70fff" fillOpacity={1} fill="url(#colorChoosing)" dot={{ stroke: '#a70fff', strokeWidth: 2 }} />
                {widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                  <Area name={analyticBlocksNames.offline} type="monotone" dataKey="offline" stackId="4" stroke="#33AFFF" fillOpacity={1} fill="url(#colorCalculated)" dot={{ stroke: '#33AFFF', strokeWidth: 2 }} />
                ) : null}
                {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'kbm' || widget.policy_type.code === 'dmsStudent'
                 || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                   <Area name={analyticBlocksNames.buying} type="monotone" dataKey="buying" stackId="5" stroke="#72c267" fillOpacity={1} fill="url(#colorBuying)" dot={{ stroke: '#72c267', strokeWidth: 2 }} />
                  ) : null}
                <Area name={analyticBlocksNames.realBuying} type="monotone" dataKey="realBuying" stackId="8" stroke="#ff5f03" fillOpacity={1} fill="url(#colorRealBuying)" dot={{ stroke: '#ff5f03', strokeWidth: 2 }} />
              </AreaChart>
            </ResponsiveContainer>
          ) : null}
        </div>
        <div className="widget-analytics-summary">
          <WidgetAnalyticBlock type="loaded" name={analyticBlocksNames.loaded} color="#FFB833" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="1" />
          <WidgetAnalyticBlock type="started" name={analyticBlocksNames.started} color="#334FFF" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="2" />
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="searching" name={analyticBlocksNames.searching} color="#c0eb34" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="6" />
          ) : null}
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'kbm' || widget.policy_type.code === 'dmsStudent'
          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="calculated" name={analyticBlocksNames.calculated} color="#33FF93" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="3" />
          ) : null}
          <WidgetAnalyticBlock type="choosing" name={analyticBlocksNames.choosing} color="#a70fff" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="7" />
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="offline" name={analyticBlocksNames.offline} color="#33AFFF" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="4" />
          ) : null}
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'kbm' || widget.policy_type.code === 'dmsStudent'
          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="buying" name={analyticBlocksNames.buying} color="#72c267" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="5" />
          ) : null}
          <WidgetAnalyticBlock type="realBuying" name={analyticBlocksNames.realBuying} color="#ff5f03" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="8" />
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="requestConversion" name={analyticBlocksNames.requestConversion} color="#fff703" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="9" percent />
          ) : null}
          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'greenCard' || widget.policy_type.code === 'dmsStudent'
          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
            <WidgetAnalyticBlock type="realBuyingConversion" name={analyticBlocksNames.realBuyingConversion} color="#ff4ae7" analyticsSummary={analyticsSummary} analyticsData={analyticsData} stackId="10" percent />
          ) : null}
        </div>
      </>
    );
  }
}
