import React, {
  useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular/index';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import classnames from 'classnames';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import InsuranceProgramDocuments from './InsuranceProgramDocuments';
import { customFetch, validateFields } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function InsuranceProgramBaseSettings(props, ref) {
  const {
    program,
    customStyles,
    insuranceTypesList,
    insurancesOptions,
    statusList,
    setProgram,
    documentsList,
    setDocumentsList,
    costTypeOptions,
    nameOptions,
    setNameOptions,
    programData,
    setProgramData,
    loadingNameOptions,
    documentsRef,
    setSavingProgramFlag,
    setGoToSaveFlag,
    setRisksList,
    setOptionsList,
    setSalesList,
    insuranceProductList,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const templateRef = useRef(null);
  const templateSampleRef = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [goToSaveEditorStateFlag, setGoToSaveEditorStateFlag] = useState(false);
  const [documentForm, setDocumentForm] = useState({
    path: '', file: null, downloaded: false, error: null,
  });
  const [documentFormSample, setDocumentFormSample] = useState({
    path: '', file: null, downloaded: false, error: null,
  });
  const [templateInputKey, setTemplateInputKey] = useState(1);
  const [templateSampleInputKey, setTemplateSampleInputKey] = useState(1);

  const toggleFlag = (e) => {
    const { target } = e;
    setProgramData((prev) => ({
      ...prev,
      [target.id]: {
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
  };

  const handleChangeInput = (e) => {
    const { target } = e;
    setProgramData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: target.value,
        errorMessage: '',
      },
    }));
  };

  const handleChangeSelect = (e, fieldName) => {
    let costValidationType = programData.cost.validationType;
    let costValue = programData.cost.value;
    if (fieldName === 'costType') {
      if (e.value === 'rub') {
        costValidationType = 'decimal';
      } else {
        if (Number.isNaN(parseFloat(costValue))) {
          costValue = 0;
        } else {
          costValue = parseInt(costValue, 10);
          if (costValue > 100) {
            costValue = 100;
          }
          if (costValue < 0) {
            costValue = 0;
          }
        }
        costValidationType = 'percent';
      }
    }
    setProgramData((prev) => {
      prev[fieldName].value = e;
      prev[fieldName].errorMessage = '';
      prev.cost.validationType = costValidationType;
      prev.cost.value = costValue;
      prev.cost.errorMessage = '';
      return { ...prev };
    });
  };

  const promiseCountryOptions = (inputValue) => new Promise((resolve) => {
    const token = 'Token 2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed';
    customFetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: inputValue,
        count: 10,
        from_bound: { value: 'region' },
        to_bound: { value: 'city' },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const regions = response.suggestions.map((address) => ({ value: address.value, label: address.value }));
        let regionsFiltered = regions;
        regionsFiltered = [{ value: 'farCountry', label: 'Ближнее зарубежье' }, { value: 'nearCountry', label: 'Дальнее зарубежье' }, ...regionsFiltered];
        resolve(regionsFiltered);
      });
  });

  const changeCountry = (e) => {
    let newCountryList = [];
    if (e !== null) {
      newCountryList = e;
    }
    setProgramData((prev) => ({
      ...prev,
      country: {
        ...prev.country,
        value: newCountryList,
        errorMessage: '',
      },
    }));
  };

  const saveDocumentForm = (target, type, setFunction) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;

    const formData = new FormData();
    formData.append('file', target.files[0]);
    formData.append('name', target.files[0].name);
    formData.append('type', type);

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/${program.id}/set-document-form`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          let path = data.document_form;
          if (type === 'sample') {
            path = data.document_form_sample;
          }
          setFunction((prev) => ({
            ...prev,
            downloaded: true,
            path,
          }));
          showModalInfo('Изменения сохранены');
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const changeDocumentForm = (e, type) => {
    e.preventDefault();
    const { target } = e;

    let setFunction = setDocumentForm;
    if (type === 'sample') {
      setFunction = setDocumentFormSample;
    }
    setFunction((prev) => ({
      ...prev,
      downloaded: false,
      name: target.files[0].name,
      error: false,
      file: target.files[0],
    }));
    saveDocumentForm(target, type, setFunction);
  };

  const clearDocumentForm = (type) => {
    let setFunction = setDocumentForm;
    if (type === 'sample') {
      templateRef.current.value = null;
      setFunction = setDocumentFormSample;
    } else {
      templateSampleRef.current.value = null;
    }
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/${program.id}/delete-document-form?type=${type}`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          setFunction({
            path: '', file: null, downloaded: false, error: null,
          });
          setTemplateInputKey((prev) => prev + 1);
          setTemplateSampleInputKey((prev) => prev + 1);
          showModalInfo('Изменения сохранены');
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const handleChangeProgramName = (newValue, actionMeta) => {
    if (actionMeta.action === 'clear') {
      setProgramData((prev) => ({
        ...prev,
        name: {
          ...prev.name,
          value: null,
          errorMessage: '',
        },
      }));
    } else {
      setProgramData((prev) => ({
        ...prev,
        name: {
          ...prev.name,
          value: newValue,
          errorMessage: '',
        },
      }));
    }
  };

  const handleCreateNewProgramName = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setNameOptions([newOption, ...nameOptions]);
    setProgramData((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        value: newOption,
        errorMessage: '',
      },
    }));
  };

  const prepProgramData = () => {
    setProgram((prev) => ({
      ...prev,
      name: programData.name.value.label,
      insurance_type: programData.insuranceType.value.value,
      type_insurance_name: programData.insuranceType.value.label,
      product_insurance: programData.insuranceProduct.value.value,
      cost_type: programData.costType.value.value,
      cost_type_label: programData.costType.value.label,
      insurance: programData.insurance.value.value,
      insurance_name: programData.insurance.value.label,
      country: programData.country.value.length ? programData.country.value : null,
      status: programData.status.value.value,
      status_name: programData.status.value.label,
      cost: programData.cost.value,
      has_sales: programData.hasSales.value,
      has_options: programData.hasOptions.value,
      has_risks: programData.hasRisks.value,
    }));
  };

  const validateProgramDataFields = () => {
    const formFields = { ...programData };
    const validationResult = validateFields(formFields);
    setProgramData({ ...validationResult.result });
    if (validationResult.validationSuccess) {
      prepProgramData();
      setGoToSaveFlag('baseSettings');
    }
  };

  const saveSettings = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setSavingProgramFlag(true);

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/${program.id}`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(program),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (!programData.hasRisks.value) {
            setRisksList([]);
          }
          if (!programData.hasOptions.value) {
            setOptionsList([]);
          }
          if (!programData.hasSales.value) {
            setSalesList([]);
          }
          showModalInfo('Изменения сохранены');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setSavingProgramFlag(false);
      });
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      documentsRef.current.save();
      saveSettings();
    },
    validateFields: () => { validateProgramDataFields(); },
  }), [program, programData]);

  useEffect(() => {
    let documentFormPath = '';
    let documentFormName = '';
    let downloadedDocumentForm = false;
    if (program.document_form) {
      documentFormPath = program.document_form;
      documentFormName = program.document_form_name;
      downloadedDocumentForm = true;
    }
    setDocumentForm((prev) => ({
      ...prev,
      path: documentFormPath,
      downloaded: downloadedDocumentForm,
      name: documentFormName,
      error: false,
    }));

    let documentFormSamplePath = '';
    let downloadedDocumentFormSample = false;
    let documentFormSampleName = false;
    if (program.document_form_sample) {
      documentFormSamplePath = program.document_form_sample;
      documentFormSampleName = program.document_form_sample_name;
      downloadedDocumentFormSample = true;
    }
    setDocumentFormSample((prev) => ({
      ...prev,
      path: documentFormSamplePath,
      downloaded: downloadedDocumentFormSample,
      name: documentFormSampleName,
      error: false,
    }));
  }, [program.id]);

  useEffect(() => {
    if (program.description !== null && program.description !== '') {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(program.description))));
    }
  }, [program.id]);

  useEffect(() => {
    if (goToSaveEditorStateFlag) {
      setProgram((prev) => ({
        ...prev,
        description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        saveChangesFlag: true,
      }));
      setGoToSaveEditorStateFlag(false);
    }
  }, [goToSaveEditorStateFlag]);

  return (
    <div className="position-relative">
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="carYear">Тип</label>
          <Select
            classNamePrefix="react-select"
            inputId="insuranceType"
            className={classnames({ error: programData.insuranceType.errorMessage })}
            name="insuranceType"
            placeholder="Тип"
            noOptionsMessage={() => 'Не найдено'}
            value={programData.insuranceType.value}
            styles={customStyles}
            onChange={(e) => { handleChangeSelect(e, 'insuranceType'); }}
            options={insuranceTypesList}
          />
          {programData.insuranceType.errorMessage && (
            <div className="validation-error">
              {programData.insuranceType.errorMessage}
            </div>
          )}
        </div>
        {programData.insuranceType.value && programData.insuranceType.value.value === 'mortgage' ? (
          <div className="col-lg-3">
            <label htmlFor="carYear">Продукт страхования</label>
            <Select
              classNamePrefix="react-select"
              inputId="insuranceProduct"
              className={classnames({ error: programData.insuranceProduct.errorMessage })}
              name="insuranceProduct"
              placeholder="Страховая компания"
              noOptionsMessage={() => 'Не найдено'}
              value={programData.insuranceProduct.value}
              styles={customStyles}
              onChange={(e) => { handleChangeSelect(e, 'insuranceProduct'); }}
              options={insuranceProductList[programData.insuranceType.value.value]}
            />
            {programData.insuranceProduct.errorMessage && (
              <div className="validation-error">
                {programData.insuranceProduct.errorMessage}
              </div>
            )}
          </div>
        ) : null}
        <div className="col-lg-3">
          <label htmlFor="carYear">Страховая компания</label>
          <Select
            classNamePrefix="react-select"
            inputId="insurance"
            className={classnames({ error: programData.insurance.errorMessage })}
            name="insurance"
            placeholder="Страховая компания"
            noOptionsMessage={() => 'Не найдено'}
            value={programData.insurance.value}
            styles={customStyles}
            onChange={(e) => { handleChangeSelect(e, 'insurance'); }}
            options={insurancesOptions}
          />
          {programData.insurance.errorMessage && (
            <div className="validation-error">
              {programData.insurance.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-4">
          <label htmlFor="name">Наименование</label>
          <CreatableSelect
            classNamePrefix="react-select"
            isClearable
            isDisabled={loadingNameOptions}
            isLoading={loadingNameOptions}
            onChange={handleChangeProgramName}
            className={classnames({ error: programData.name.errorMessage })}
            onCreateOption={handleCreateNewProgramName}
            options={nameOptions}
            value={programData.name.value}
            placeholder="Выберите наименование или введите новое"
            noOptionsMessage={() => 'Не найдено'}
            styles={customStyles}
            formatCreateLabel={(userInput) => `Добавить ${userInput}`}
            createOptionPosition="first"
          />
          {programData.name.errorMessage && (
            <div className="validation-error">
              {programData.name.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-2">
          <label htmlFor="cost">Цена</label>
          <input
            value={programData.cost.value}
            onChange={handleChangeInput}
            name="cost"
            className={classnames('form-control', { error: programData.cost.errorMessage })}
            id="cost"
          />
          {programData.cost.errorMessage && (
            <div className="validation-error">
              {programData.cost.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-2">
          <label htmlFor="carYear">Тип цены</label>
          <Select
            classNamePrefix="react-select"
            inputId="costType"
            className={classnames({ error: programData.costType.errorMessage })}
            name="costType"
            placeholder="Тип цены"
            noOptionsMessage={() => 'Не найдено'}
            value={programData.costType.value}
            styles={customStyles}
            onChange={(e) => { handleChangeSelect(e, 'costType'); }}
            options={costTypeOptions}
          />
          {programData.costType.errorMessage && (
            <div className="validation-error">
              {programData.costType.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12">
          <label htmlFor="description">Описание продукта</label>
          <Editor
            stripPastedStyles
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="form-control"
            onEditorStateChange={(e) => { setEditorState(e); }}
            onBlur={() => { setGoToSaveEditorStateFlag(true); }}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-3 col-xl-2">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input checked={programData.hasOptions.value} onChange={toggleFlag} name="hasOptions" className="form-check-input checkbox-styled" type="checkbox" id="hasOptions" fieldname="has_options" />
            <label className="form-check-label" htmlFor="hasOptions">
              Содержит доп. опции
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-3 col-xl-2">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input checked={programData.hasRisks.value} onChange={toggleFlag} name="hasRisks" className="form-check-input checkbox-styled" type="checkbox" id="hasRisks" fieldname="has_risks" />
            <label className="form-check-label" htmlFor="hasRisks">
              Содержит риски
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-3 col-xl-2">
          <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
            <input checked={programData.hasSales.value} onChange={toggleFlag} name="hasSales" className="form-check-input checkbox-styled" type="checkbox" id="hasSales" fieldname="has_sales" />
            <label className="form-check-label" htmlFor="hasSales">
              Содержит скидки
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="carYear">Статус</label>
          <Select
            classNamePrefix="react-select"
            inputId="status"
            className={classnames({ error: programData.status.errorMessage })}
            name="status"
            placeholder="Статус"
            noOptionsMessage={() => 'Не найдено'}
            value={programData.status.value}
            styles={customStyles}
            onChange={(e) => { handleChangeSelect(e, 'status'); }}
            options={statusList}
          />
          {programData.status.errorMessage && (
            <div className="validation-error">
              {programData.status.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-6">
          <label htmlFor="carYear">Страны</label>
          <AsyncSelect
            classNamePrefix="react-select"
            placeholder="Страны"
            isMulti
            className={classnames({ error: programData.country.errorMessage })}
            cacheOptions
            value={programData.country.value}
            loadingMessage={() => 'Загрузка'}
            noOptionsMessage={() => 'Не найдено'}
            onChange={(e) => changeCountry(e)}
            styles={customStyles}
            loadOptions={(e) => promiseCountryOptions(e)}
          />
          {programData.country.errorMessage && (
            <div className="validation-error">
              {programData.country.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <label htmlFor="documentForm">Бланк полиса</label>
          <div className="jq-file styler">
            <div className="jq-file__name">{documentForm.downloaded
              ? <>{documentForm.name}<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
              : 'Бланк полиса'}
            </div>
            {(documentForm.path || documentForm.file)
              ? (<div onClick={() => clearDocumentForm('form')} className="jq-file__remove" />)
              : (<div className="jq-file__browse" />)}
            <input onChange={(e) => { changeDocumentForm(e, 'form'); }} type="file" name="documentForm" className="custom-input-file" ref={templateRef} key={templateInputKey} />
          </div>
          {documentForm.path && (
            <div className="jq-file__download">
              <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documentForm.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
            </div>
          )}
          {documentForm.error && (
            <div className="mt-2 error_block">{documentForm.error}</div>
          )}
        </div>
        <div className="col-lg-4">
          <label htmlFor="documentFormSample">Бланк образца</label>
          <div className="jq-file styler">
            <div className="jq-file__name">{documentFormSample.downloaded
              ? <>{documentFormSample.name}<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
              : 'Бланк образца'}
            </div>
            {(documentFormSample.path || documentFormSample.file)
              ? (<div onClick={() => clearDocumentForm('sample')} className="jq-file__remove" />)
              : (<div className="jq-file__browse" />)}
            <input onChange={(e) => { changeDocumentForm(e, 'sample'); }} type="file" name="documentFormSample" className="custom-input-file" ref={templateSampleRef} key={templateSampleInputKey} />
          </div>
          {documentFormSample.path && (
            <div className="jq-file__download">
              <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documentFormSample.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
            </div>
          )}
          {documentFormSample.error && (
            <div className="mt-2 error_block">{documentFormSample.error}</div>
          )}
        </div>
      </div>
      <br />
      <InsuranceProgramDocuments
        documentsList={documentsList}
        setDocumentsList={setDocumentsList}
        program={program}
        ref={documentsRef}
      />
    </div>
  );
}

export default forwardRef(InsuranceProgramBaseSettings);
