import React from 'react';
import { parseISO, format } from 'date-fns';
import Select from 'react-select';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import { faExclamationTriangle } from '@fortawesome/fontawesome-free-solid';
import Sticky from 'react-sticky-el';
import queryString from 'query-string';
import { scroller } from 'react-scroll';
import ReactExport from 'react-data-export';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import LoadingSpinner from '../Layout/LoadingSpinner';
import {
  customFetch,
  customSelectStyle,
  getDefaultSorting7,
  onContextMenuClick,
  onTableCellClick,
  setTableSettings7,
  setTableSorting,
  downloadReport,
  onFilteredChangeCustom,
  loadSuggestions,
  toggleSelectFiltered,
  toggleRow,
  toggleSelectAll,
} from '../Utils/Helpers';
import AgentsCommission from './AgentsCommission';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Table from '../Layout/Table';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();

class AgentsTable extends React.Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this._isMounted = false;
    this.child = React.createRef();
    this.reactTableRef = React.createRef();
    this.state = {
      openModalTableCustomized: false,
      excelcolumns: [],
      dataSet: [],
      columns: [],
      filtered: [],
      groupEditProgress: false,
      agents: [],
      loading: true,
      showButtonSendMail: true,
      selected: {},
      selectAll: 0,
      selectFiltered: 0,
      curator: { value: '', label: '' },
      editOption: { value: '', label: '' },
      agentCategory: { value: '', label: '' },
      defaultSorted: [{
        id: 'updated_at',
        desc: 'true',
      }],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      location, tab,
    } = this.props;
    const { filtered, defaultSorted } = this.state;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    this.downloadAgents();
    const newDefaultSorted = getDefaultSorting7('agentsTableSettings', defaultSorted);
    this.updateTableColumns();
    this.setState({ defaultSorted: newDefaultSorted });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agent.length !== 0) {
      const { agents } = this.state;
      const addedAgent = agents;
      addedAgent.push(nextProps.agent);
      this.setState({
        agents: addedAgent,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { userInfo } = this.props;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) || (prevProps.userInfo.role !== userInfo.role)) {
      this.updateTableColumns();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    abortController.abort();
  }

  downloadAgents = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      blockedAgents, showAll,
    } = this.props;
    let addressApi = 'agents';
    if (showAll) {
      const firstPolicyDateFlag = reactLocalStorage.getObject('agentsTableSettings').show_firstPolicyDate && reactLocalStorage.getObject('agentsTableSettings').show_firstPolicyDate !== false;
      const lastPolicyDateFlag = reactLocalStorage.getObject('agentsTableSettings').show_lastPolicyDate && reactLocalStorage.getObject('agentsTableSettings').show_lastPolicyDate !== false;
      addressApi = `all_agents?firstPolicyDate=${firstPolicyDateFlag}&lastPolicyDate=${lastPolicyDateFlag}`;
    } else if (blockedAgents) {
      addressApi = 'blocked_agents';
    }
    const { showModalInfo } = this.context;
    abortController.abort();
    abortController = new AbortController();
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/${addressApi}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((agents) => {
        this._isMounted && this.setState({ agents });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          showModalInfo('Ошибка загрузки данных', 'error');
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  sendMail = (row, agentId, lastActivity) => {
    const { agents } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const newAgents = agents;
    newAgents[row].emailSended = true;
    let typeNotification;
    if (lastActivity) {
      typeNotification = 'remind';
    } else {
      typeNotification = 'send';
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mail/${typeNotification}`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agentId }),
    })
      .then((response) => response.json())
      .then(() => {
      });
    this.setState({ agents: newAgents });
  };

  setData = (excelcolumns, dataSet) => {
    this.setState({ excelcolumns, dataSet }, function () {
      this.excelFile.handleDownload();
    });
  }

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  downloadReport = () => {
    const { columns } = this.state;
    downloadReport(this.reactTableRef, columns, ['select_cell', 'send_mail'], this.setData);
  };

  setSelected = (selected, selectAll, selectFiltered) => {
    this.setState({
      selected,
      selectAll,
      selectFiltered,
    }, () => {
      this.updateTableColumns();
    });
  }

  changeEditOption = (e) => {
    this.setState({ editOption: e }, () => {
      if (e.value === 'changeCommission') {
        scroller.scrollTo('agentCommission', {
          duration: 1500,
          delay: 100,
          smooth: true,
          offset: -140,
        });
      }
    });
  };

  handleSubmitGroupEdit = (e) => {
    e.preventDefault();
    const {
      editOption, selected, agentCategory, curator,
    } = this.state;
    const { showAll, blockedAgents } = this.props;
    this.setState({ groupEditProgress: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { showModalInfo } = this.context;
    if (editOption.value === 'block') {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/block`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selected }),
      })
        .then((response) => response.json())
        .then(() => {
          this.setState((prevState) => ({
            loading: !prevState.loading,
          }));
          let addressApi = 'agents';
          if (showAll) {
            addressApi = 'all_agents';
          } else if (blockedAgents) {
            addressApi = 'blocked_agents';
          }
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/${addressApi}`, {
            headers: {
              Authorization: lsToken,
            },
          })
            .then((response) => response.json())
            .then((agents) => {
              this.setState((prevState) => ({
                loading: !prevState.loading,
                agents,
                groupEditProgress: false,
              }));
            });
        });
    }

    if (editOption.value === 'unblock') {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/unblock`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selected }),
      })
        .then((response) => response.json())
        .then(() => {
          this.setState((prevState) => ({
            loading: !prevState.loading,
          }));
          let addressApi = 'agents';
          if (showAll) {
            addressApi = 'all_agents';
          } else if (blockedAgents) {
            addressApi = 'blocked_agents';
          }
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/${addressApi}`, {
            headers: {
              Authorization: lsToken,
            },
          })
            .then((response) => response.json())
            .then((agents) => {
              this.setState((prevState) => ({
                loading: !prevState.loading,
                agents,
                groupEditProgress: false,
              }));
            });
        });
    }
    if (editOption.value === 'sendMail') {
      const { agents } = this.state;
      const newAgents = agents;
      newAgents.forEach((newAgent) => {
        if (newAgent.id in selected) {
          newAgent.emailSended = true;
        }
      });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/mail/group`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selected),
      })
        .then((response) => response.json())
        .then(() => {
          this.setState({
            groupEditProgress: false,
          });
        });
      this.setState({ agents: newAgents });
    }
    if (editOption.value === 'changeCategory') {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/changeCategory`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agents: selected,
          category: agentCategory,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            showModalInfo('Обновление займет несколько минут.');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          this.setState({ groupEditProgress: false });
        });
    }
    if (editOption.value === 'changeCurator') {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents/changeCurator`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agents: selected,
          curator: curator.value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            showModalInfo('Обновление займет несколько минут. Только у пользователей первого уровня будет изменен куратор!');
          }
          this.setState({ groupEditProgress: false });
        })
        .catch(() => {
          this.setState({ groupEditProgress: false });
        });
    }
    if (editOption.value === 'changeCommission') {
      this.child.current.handleSubmit();
    }
  };

  onChangeGroupEditProgress = (value) => {
    this.setState({ groupEditProgress: value });
  };

  updateTableColumns = () => {
    const {
      showButtonSendMail, selected, selectFiltered, agents,
    } = this.state;
    const {
      showAll, blockedAgents, userInfo,
    } = this.props;
    const columns = [{
      disableFilters: true,
      show: true,
      disableSortBy: true,
      id: 'select_cell',
      Cell: ({ row }) => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selected[row.original.id]}
          onChange={() => toggleRow(row.original.id, selected, agents, this.setSelected)}
        />
      ),
      Header: () => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectFiltered === 1}
          ref={(input) => {
            if (input) {
              input.indeterminate = selectFiltered === 2;
            }
          }}
          onChange={() => toggleSelectFiltered(selectFiltered, agents, this.reactTableRef.current.getTableData(), this.setSelected)}
        />
      ),
      width: 40,
    }, {
      Header: 'id',
      accessor: 'id',
      width: reactLocalStorage.getObject('agentsTableSettings').id,
      minWidth: 70,
      show: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'ФИО',
      id: 'agentName',
      accessor: (d) => `${d.last_name} ${d.first_name} ${d.middle_name}`,
      width: reactLocalStorage.getObject('agentsTableSettings').agentName,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_agentName !== false,
      showCustomized: true,
    }, {
      Header: 'Телефон',
      accessor: 'phone',
      width: reactLocalStorage.getObject('agentsTableSettings').phone,
      Cell: ({ row }) => (showAll ? <></> : row.original.phone),
      filterAll: true,
      minWidth: 150,
      show: !showAll && reactLocalStorage.getObject('agentsTableSettings').show_phone !== false,
      showCustomized: !showAll,
    }, {
      Header: 'E-mail',
      accessor: 'email',
      width: reactLocalStorage.getObject('agentsTableSettings').email,
      Cell: ({ row }) => (showAll ? <></> : row.original.email),
      filterAll: true,
      minWidth: 150,
      show: !showAll && reactLocalStorage.getObject('agentsTableSettings').show_email !== false,
      showCustomized: !showAll,
    }, {
      Header: 'Куратор',
      id: 'curator',
      accessor: (d) => ((showAll || userInfo.full_time_employee) ? `${d.curator_obj.last_name} ${d.curator_obj.first_name} ${d.curator_obj.middle_name}` : `${d.last_name} ${d.first_name} ${d.middle_name}`),
      width: reactLocalStorage.getObject('agentsTableSettings').curator,
      filterAll: true,
      minWidth: 150,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_curator !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Пользователь 1-го уровня',
      id: 'agent_first_level',
      accessor: (d) => d.agent_first_level,
      width: reactLocalStorage.getObject('agentsTableSettings').agent_first_level,
      filterAll: true,
      minWidth: 150,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_agent_first_level !== false,
      showCustomized: (!!showAll || userInfo.full_time_employee),
    }, {
      Header: 'Является пользователем первого уровня',
      id: 'first_level_flag',
      accessor: (d) => ((d.curator_obj && d.curator_obj.full_time_employee) ? 'Да' : 'Нет'),
      width: reactLocalStorage.getObject('agentsTableSettings').first_level_flag,
      filterAll: true,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_first_level_flag !== false,
      showCustomized: true,
    }, {
      Header: 'Юр. лицо/ИП',
      accessor: 'organization_name',
      width: reactLocalStorage.getObject('agentsTableSettings').organization_name,
      filterAll: true,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_organization_name !== false,
      showCustomized: true,
    }, {
      Header: 'Партнер',
      id: 'partner_agent',
      accessor: (d) => (d.partner ? `${d.partner.last_name} ${d.partner.first_name} ${d.partner.middle_name}` : ''),
      width: reactLocalStorage.getObject('agentsTableSettings').partner_agent,
      filterAll: true,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_partner_agent !== false,
      showCustomized: true,
    }, {
      Header: 'Кол-во черновиков',
      accessor: 'draftsCount',
      width: reactLocalStorage.getObject('agentsTableSettings').draftsCount,
      filterAll: true,
      minWidth: 70,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_draftsCount !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Кол-во полисов',
      accessor: 'policiesCount',
      width: reactLocalStorage.getObject('agentsTableSettings').policiesCount,
      filterAll: true,
      minWidth: 70,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_policiesCount !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Кол-во пользователей',
      accessor: 'subAgentsCount',
      width: reactLocalStorage.getObject('agentsTableSettings').subAgentsCount,
      filterAll: true,
      minWidth: 70,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_subAgentsCount !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Кол-во виджетов',
      accessor: 'widgetsCount',
      width: reactLocalStorage.getObject('agentsTableSettings').widgetsCount,
      filterAll: true,
      minWidth: 70,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_widgetsCount !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Город',
      accessor: 'city',
      width: reactLocalStorage.getObject('agentsTableSettings').city,
      filterAll: true,
      show: reactLocalStorage.getObject('agentsTableSettings').show_city !== false,
      minWidth: 150,
      showCustomized: true,
    }, {
      Header: 'Поддомен',
      accessor: 'domain',
      width: reactLocalStorage.getObject('agentsTableSettings').domain,
      filterAll: true,
      minWidth: 100,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_domain !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Категория',
      id: 'agent_category',
      accessor: (d) => (d.agent_category ? d.agent_category.name : ''),
      width: reactLocalStorage.getObject('agentsTableSettings').agent_category,
      Filter: 'selectColumnFilter',
      filterAll: true,
      minWidth: 150,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_agent_category !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Тип пользователя',
      id: 'agent_type',
      accessor: (d) => (d.agent_type ? d.agent_type.name : ''),
      width: reactLocalStorage.getObject('agentsTableSettings').agent_type,
      Filter: 'selectColumnFilter',
      filterAll: true,
      minWidth: 150,
      filter: 'value',
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_agent_type !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Баланс',
      id: 'balance',
      accessor: (d) => (Number(d.balance)),
      width: reactLocalStorage.getObject('agentsTableSettings').balance,
      filterAll: true,
      minWidth: 100,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_balance !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Тип регистрации',
      id: 'registrationType',
      Filter: 'selectColumnFilter',
      minWidth: 150,
      accessor: (d) => {
        if (d.registrationType === 'simple_form' || d.registrationType === 'complete_form') {
          return 'Автоматическая';
        } if (d.registrationType === 'invite') {
          return 'Приглашение';
        }
        return 'Без статуса';
      },
      width: reactLocalStorage.getObject('agentsTableSettings').registrationType,
      filterAll: true,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_registrationType !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Дата регистрации',
      accessor: 'created_at',
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy'),
      filter: 'dateTextFilterFn',
      width: reactLocalStorage.getObject('agentsTableSettings').created_at,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Последняя активность',
      accessor: 'last_activity',
      show: reactLocalStorage.getObject('agentsTableSettings').show_last_activity !== false,
      showCustomized: true,
      minWidth: 150,
      Cell: ({ row }) => ((row.original.last_activity === null) ? 'Пользователь не авторизовался' : format(parseISO(row.original.last_activity), 'dd.MM.yyyy')),
      width: reactLocalStorage.getObject('agentsTableSettings').last_activity,
      filter: (rows, id, filterValue) => rows.filter((row) => {
        let rowValue = '';
        if (row.original.last_activity) {
          rowValue = format(parseISO(row.original.last_activity), 'dd.MM.yyyy');
        } else {
          rowValue = 'Пользователь не авторизовался'.toUpperCase();
          filterValue = filterValue.toUpperCase();
        }
        return rowValue.includes(filterValue);
      }),
    }, {
      Header: 'Дата первой продажи',
      accessor: 'firstPolicyDate',
      Cell: ({ row }) => (row.original.firstPolicyDate ? format(parseISO(row.original.firstPolicyDate), 'dd.MM.yyyy') : null),
      filter: 'dateTextFilterFn',
      width: reactLocalStorage.getObject('agentsTableSettings').firstPolicyDate,
      minWidth: 150,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_firstPolicyDate && reactLocalStorage.getObject('agentsTableSettings').show_firstPolicyDate !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Дата последней продажи',
      accessor: 'lastPolicyDate',
      Cell: ({ row }) => (row.original.lastPolicyDate ? format(parseISO(row.original.lastPolicyDate), 'dd.MM.yyyy') : null),
      filter: 'dateTextFilterFn',
      width: reactLocalStorage.getObject('agentsTableSettings').lastPolicyDate,
      minWidth: 150,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_lastPolicyDate && reactLocalStorage.getObject('agentsTableSettings').show_lastPolicyDate !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      Header: 'Дата блокировки',
      accessor: 'blocked_at',
      minWidth: 150,
      Cell: ({ row }) => (row.original.blocked_at ? format(parseISO(row.original.blocked_at), 'dd.MM.yyyy') : ''),
      filter: 'dateTextFilterFn',
      width: reactLocalStorage.getObject('agentsTableSettings').blocked_at,
      show: !!blockedAgents && reactLocalStorage.getObject('agentsTableSettings').show_blocked_at !== false,
      showCustomized: !!blockedAgents,
    }, {
      Header: 'Причина блокировки',
      accessor: 'reason_ban',
      minWidth: 150,
      width: reactLocalStorage.getObject('agentsTableSettings').reason_ban,
      show: !!blockedAgents && reactLocalStorage.getObject('agentsTableSettings').show_reason_ban !== false,
      showCustomized: !!blockedAgents,
    }, {
      Header: 'Описание',
      accessor: 'description',
      width: reactLocalStorage.getObject('agentsTableSettings').description,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_description !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
      minWidth: 150,
      filter: (rows, id, filterValue) => rows.filter((row) => {
        if (filterValue === 'all') {
          return true;
        }
        if (row.original.description) {
          const arrayDescriptions = row.original.description.split(',');
          const finded = arrayDescriptions.findIndex((description) => description === filterValue);
          return finded !== -1;
        }
        return false;
      }),
      Filter: 'selectColumnFilter',
    }, {
      Header: 'Комментарий',
      accessor: 'comment',
      width: reactLocalStorage.getObject('agentsTableSettings').comment,
      filterAll: true,
      show: reactLocalStorage.getObject('agentsTableSettings').show_comment !== false,
      showCustomized: true,
      minWidth: 150,
    }, {
      Header: 'Номер офиса',
      accessor: 'office_number',
      width: reactLocalStorage.getObject('agentsTableSettings').office_number,
      filterAll: true,
      show: (userInfo.domain === 'samoletplus' || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_office_number !== false,
      minWidth: 150,
      showCustomized: userInfo.domain === 'samoletplus' || userInfo.full_time_employee,
    }, {
      Header: 'Опрос пройден',
      id: 'poll_complete',
      accessor: (d) => (d.poll_complete ? 'Да' : 'Нет'),
      width: reactLocalStorage.getObject('agentsTableSettings').poll_complete,
      Filter: 'selectColumnFilter',
      minWidth: 100,
      show: (!!showAll || userInfo.full_time_employee) && reactLocalStorage.getObject('agentsTableSettings').show_poll_complete !== false,
      showCustomized: !!showAll || userInfo.full_time_employee,
    }, {
      id: 'confirmed',
      Header: 'Статус',
      Filter: 'selectColumnFilter',
      filter: 'strictEqualSelectFilterFn',
      Cell: ({ row }) => {
        if (row.original.confirmed === 'yes') {
          return (<><FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Подтвержден</>);
        } if (row.original.confirmed === 'no') {
          return (<><FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Не подтвержден</>);
        } if (row.original.confirmed === 'under_review') {
          return (<><FontAwesomeIcon color="#fd7e14" icon={faExclamationTriangle} className="font-awesome-custom-icon fa-fw fa-lg" />На проверке</>);
        } if (row.original.confirmed === 'no_need') {
          return 'Не требуется';
        } if (row.original.confirmed === 'need_update') {
          return 'Требуется уточнение';
        }
        return 'Без статуса';
      },
      accessor: (d) => {
        if (d.confirmed === 'yes') {
          return 'Подтвержден';
        } if (d.confirmed === 'no') {
          return 'Не подтвержден';
        } if (d.confirmed === 'under_review') {
          return 'На проверке';
        } if (d.confirmed === 'no_need') {
          return 'Не требуется';
        } if (d.confirmed === 'need_update') {
          return 'Требуется уточнение';
        }
        return 'Без статуса';
      },
      minWidth: 100,
      show: reactLocalStorage.getObject('agentsTableSettings').show_confirmed !== false,
      showCustomized: true,
    }, {
      Header: 'Реферальная ссылка',
      id: 'referral_link',
      accessor: (d) => (d.referral_link ? d.referral_link.name : ''),
      width: reactLocalStorage.getObject('agentsTableSettings').referral_link,
      filterAll: true,
      minWidth: 150,
      show: reactLocalStorage.getObject('agentsTableSettings').show_referral_link !== false,
      showCustomized: true,
    }, {
      Header: () => null, // No header
      width: 40,
      id: 'send_mail',
      disableFilters: true,
      filterable: false,
      clickable: true,
      Cell: ({ row }) => !row.original.emailSended && (
        <div title="Отправить приглашение"><FontAwesomeIcon onClick={() => this.sendMail(row.index, row.original.id, row.original.last_activity)} icon={faEnvelope} className="fa-fw" /></div>
      ),
      show: showButtonSendMail,
    },
    ];
    this.setState({ columns });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    let updateAgentsFlag = false;
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('agentsTableSettings');
    columns.forEach((column) => {
      if ((column.name === 'firstPolicyDate' || column.name === 'lastPolicyDate') && column.checked !== settings[`show_${column.name}`]) {
        updateAgentsFlag = true;
      }
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('agentsTableSettings', settings);
    if (updateAgentsFlag) {
      this.downloadAgents();
    }
    this.setState({ openModalTableCustomized: false });
  };

  handleChangeSelect = (e, field) => {
    this.setState({ [field.name]: e });
  }

  render() {
    const {
      openModalTableCustomized,
      agents,
      loading,
      selected,
      selectAll,
      editOption,
      groupEditProgress,
      columns,
      agentCategory,
      defaultSorted,
      excelcolumns,
      dataSet,
      curator,
    } = this.state;
    const { userInfo, history, tab } = this.props;
    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <Table
          ref={this.reactTableRef}
          data={agents}
          columns={columns}
          loading={loading}
          onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'agent', history)}
          onContextMenuClick={(e, row) => onContextMenuClick(e, 'agent', row.original.id)}
          noDataText="Пользователи не найдены"
          defaultFiltered={this.state.filtered}
          defaultSorted={defaultSorted}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'agentsTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings7(newResized, 'agentsTableSettings');
          }}
          onFilteredChange={(filtered) => onFilteredChangeCustom(filtered, null, tab)}
        />
        <Sticky
          mode="bottom"
          positionRecheckInterval={1}
          className={Object.keys(selected).length > 0 ? 'visible-animation' : 'hidden-animation'}
        >
          <form name="groupEditForm" id="groupEditForm" onSubmit={this.handleSubmitGroupEdit}>
            <div className="row">
              <div className="form-group col-lg-3">
                <Select
                  classNamePrefix="react-select"
                  menuPlacement="top"
                  styles={customSelectStyle()}
                  name="editOption"
                  isSearchable={false}
                  noOptionsMessage={() => 'Не найдено'}
                  id="editOption"
                  value={editOption.value ? editOption : ''}
                  placeholder="Выберите действие"
                  onChange={this.changeEditOption}
                  options={(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? [
                    { value: 'block', label: 'Заблокировать' },
                    { value: 'unblock', label: 'Разблокировать' },
                    { value: 'sendMail', label: 'Отправить письмо для активации продаж пользователя' },
                    { value: 'changeCommission', label: 'Изменить размер вознаграждения' },
                    { value: 'changeCategory', label: 'Изменить категорию' },
                    { value: 'changeCurator', label: 'Изменить куратора' },
                  ] : [
                    { value: 'block', label: 'Заблокировать' },
                    { value: 'unblock', label: 'Разблокировать' },
                    { value: 'sendMail', label: 'Отправить письмо для активации продаж пользователя' },
                    { value: 'changeCommission', label: 'Изменить размер вознаграждения' },
                  ]}
                />
              </div>
              {editOption.value === 'changeCategory' && (
                <div className="form-group col-lg-3">
                  <Select
                    classNamePrefix="react-select"
                    menuPlacement="top"
                    styles={customSelectStyle()}
                    name="agentCategory"
                    isSearchable={false}
                    placeholder="Выберите категорию"
                    id="agentCategory"
                    value={agentCategory.value ? agentCategory : ''}
                    onChange={this.handleChangeSelect}
                    options={[
                      { value: 'vip', label: 'Вип' },
                      { value: 'pro', label: 'Профессионал' },
                      { value: 'standard', label: 'Стандарт' },
                      { value: 'novice', label: 'Новичок' },
                      { value: 'blank', label: 'Без категории' },
                    ]}
                  />
                </div>
              )}
              {editOption.value === 'changeCurator' && (
                <div className="form-group col-lg-3">
                  <AsyncSelect
                    value={curator.value ? curator : ''}
                    classNamePrefix="react-select"
                    placeholder="Выберите куратора"
                    name="curator"
                    menuPlacement="top"
                    id="curator"
                    loadingMessage={() => 'Загрузка'}
                    noOptionsMessage={() => 'Не найдено'}
                    onChange={this.handleChangeSelect}
                    styles={customSelectStyle()}
                    loadOptions={debounce(loadSuggestions('full_time_employee'), 500)}
                  />
                </div>
              )}
              <div className="form-group col-lg-6">
                <button type="submit" className="btn btn-success mr-2">Применить</button>
                <input
                  type="checkbox"
                  className="checkbox mr-1"
                  id="selectAll"
                  checked={selectAll === 1}
                  onChange={(e) => toggleSelectAll(e.target.checked, agents, this.setSelected, this.reactTableRef.current.getTableData())}
                />
                <label htmlFor="selectAll">Применить ко всем</label>
                <span className="nowrap">(Выбрано: {Object.keys(selected).length})</span>
                {groupEditProgress && <LoadingSpinner className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </div>
            </div>
          </form>
        </Sticky>
        {editOption.value === 'changeCommission' && (
          <div name="agentCommission"><AgentsCommission ref={this.child} changeEditOption={this.changeEditOption} setSelected={this.setSelected} onChangeGroupEditProgress={this.onChangeGroupEditProgress} agents={selected} /></div>
        )}
      </>
    );
  }
}

AgentsTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(AgentsTable);
