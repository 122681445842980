import React, { Component } from 'react';
import { Line } from 'rc-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { customFetch } from '../Utils/Helpers';

export default class AdminDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      documents: {
        faq: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        commerce_offer_for_agents: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        commerce_offer_for_clients_osago: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        commerce_offer_for_clients_mortgage: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        commerce_offer_for_clients_mortgage_life: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        presentation_service: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        eula: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        offer_for_natural: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        offer_for_self_employed: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        offer_for_legal: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        privacy_policy: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
        green_card_bets: {
          name: '',
          file: null,
          downloaded: false,
          error: null,
          path: '',
        },
      },
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((newDocuments) => {
        const { documents } = this.state;
        Object.keys(newDocuments).forEach((document) => {
          if (newDocuments[document]) {
            documents[document].downloaded = true;
            documents[document].path = newDocuments[document];
          }
        });
        this.setState({ documents });
      })
      .catch((error) => console.log(error));
  }

  handleChange = (e) => {
    const { documents } = this.state;
    documents[e.target.name].name = e.target.files[0].name;
    documents[e.target.name].file = e.target.files[0];
    documents[e.target.name].downloaded = false;
    this.setState({ documents });
  };

  increase = () => {
    const percent = this.state.percent + 1;
    if (percent >= 90) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({ percent });
    this.tm = setTimeout(this.increase, 10);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { documents } = this.state;
    const formData = new FormData();
    Object.keys(documents).forEach((document) => {
      if (documents[document].file) {
        formData.append(document, documents[document].file);
      } else {
        formData.append(document, documents[document].path);
      }
    });
    clearTimeout(this.tm);
    this.setState({ percent: 0 }, () => {
      this.increase();
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/upload`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((newDocuments) => {
        Object.keys(newDocuments).forEach((document) => {
          if (newDocuments[document]) {
            documents[document].downloaded = true;
            documents[document].error = null;
            documents[document].path = newDocuments[document];
          }
        });
        clearTimeout(this.tm);
        this.setState({ documents, percent: 0 });
      })
      .catch((error) => console.log(error));
  };

  handleDeleteFile = (documentName) => {
    const { documents } = this.state;
    documents[documentName].name = '';
    documents[documentName].file = null;
    documents[documentName].downloaded = false;
    documents[documentName].error = null;
    documents[documentName].path = '';
    this.setState({ documents });
  };

  render() {
    const {
      documents,
      percent,
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Инструкция</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.faq.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.faq.name}
                  </div>
                  {(documents.faq.path || documents.faq.file)
                    ? (<div onClick={() => this.handleDeleteFile('faq')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="faq" className="custom-input-file" />
                </div>
                {documents.faq.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.faq.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.faq.error && (
                  <div className="mt-2 error_block">{documents.faq.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Пользовательское соглашение</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.eula.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.eula.name}
                  </div>
                  {(documents.eula.path || documents.eula.file)
                    ? (<div onClick={() => this.handleDeleteFile('eula')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="eula" className="custom-input-file" />
                </div>
                {documents.eula.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.eula.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.eula.error && (
                  <div className="mt-2 error_block">{documents.eula.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Договор оферты для ФЛ</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.offer_for_natural.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.offer_for_natural.name}
                  </div>
                  {(documents.offer_for_natural.path || documents.offer_for_natural.file)
                    ? (<div onClick={() => this.handleDeleteFile('offer_for_natural')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="offer_for_natural" className="custom-input-file" />
                </div>
                {documents.offer_for_natural.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.offer_for_natural.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.offer_for_natural.error && (
                  <div className="mt-2 error_block">{documents.offer_for_natural.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Договор оферты для самозанятых</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.offer_for_self_employed.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.offer_for_self_employed.name}
                  </div>
                  {(documents.offer_for_self_employed.path || documents.offer_for_self_employed.file)
                    ? (<div onClick={() => this.handleDeleteFile('offer_for_self_employed')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)
                  }
                  <input onChange={this.handleChange} type="file" name="offer_for_self_employed" className="custom-input-file" />
                </div>
                {documents.offer_for_self_employed.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.offer_for_self_employed.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.offer_for_self_employed.error && (
                  <div className="mt-2 error_block">{documents.offer_for_self_employed.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Договор оферты для ЮЛ</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.offer_for_legal.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.offer_for_legal.name}
                  </div>
                  {(documents.offer_for_legal.path || documents.offer_for_legal.file)
                    ? (<div onClick={() => this.handleDeleteFile('offer_for_legal')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="offer_for_legal" className="custom-input-file" />
                </div>
                {documents.offer_for_legal.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.offer_for_legal.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.offer_for_legal.error && (
                  <div className="mt-2 error_block">{documents.offer_for_legal.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Презентация сервиса</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.presentation_service.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.presentation_service.name}
                  </div>
                  {(documents.presentation_service.path || documents.presentation_service.file)
                    ? (<div onClick={() => this.handleDeleteFile('presentation_service')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="presentation_service" className="custom-input-file" />
                </div>
                {documents.presentation_service.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.presentation_service.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.presentation_service.error && (
                  <div className="mt-2 error_block">{documents.presentation_service.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Политика конфиденциальности</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.privacy_policy.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.privacy_policy.name}
                  </div>
                  {(documents.privacy_policy.path || documents.privacy_policy.file)
                    ? (<div onClick={() => this.handleDeleteFile('privacy_policy')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="privacy_policy" className="custom-input-file" />
                </div>
                {documents.privacy_policy.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.privacy_policy.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.privacy_policy.error && (
                  <div className="mt-2 error_block">{documents.privacy_policy.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Коммерческое предложение для пользователей</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.commerce_offer_for_agents.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.commerce_offer_for_agents.name}
                  </div>
                  {(documents.commerce_offer_for_agents.path || documents.commerce_offer_for_agents.file)
                    ? (<div onClick={() => this.handleDeleteFile('commerce_offer_for_agents')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="commerce_offer_for_agents" className="custom-input-file" />
                </div>
                {documents.commerce_offer_for_agents.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.commerce_offer_for_agents.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.commerce_offer_for_agents.error && (
                  <div className="mt-2 error_block">{documents.commerce_offer_for_agents.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Зеленая карта - тарифы</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.green_card_bets.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.green_card_bets.name}
                  </div>
                  {(documents.green_card_bets.path || documents.green_card_bets.file)
                    ? (<div onClick={() => this.handleDeleteFile('green_card_bets')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="green_card_bets" className="custom-input-file" />
                </div>
                {documents.green_card_bets.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.green_card_bets.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.green_card_bets.error && (
                  <div className="mt-2 error_block">{documents.green_card_bets.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Коммерческое предложение для клиентов (ОСАГО)</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.commerce_offer_for_clients_osago.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.commerce_offer_for_clients_osago.name}
                  </div>
                  {(documents.commerce_offer_for_clients_osago.path || documents.commerce_offer_for_clients_osago.file)
                    ? (<div onClick={() => this.handleDeleteFile('commerce_offer_for_clients_osago')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="commerce_offer_for_clients_osago" className="custom-input-file" />
                </div>
                {documents.commerce_offer_for_clients_osago.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.commerce_offer_for_clients_osago.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.commerce_offer_for_clients_osago.error && (
                  <div className="mt-2 error_block">{documents.commerce_offer_for_clients_osago.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Коммерческое предложение для клиентов (Ипотека.Имущество)</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.commerce_offer_for_clients_mortgage.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.commerce_offer_for_clients_mortgage.name}
                  </div>
                  {(documents.commerce_offer_for_clients_mortgage.path || documents.commerce_offer_for_clients_mortgage.file)
                    ? (<div onClick={() => this.handleDeleteFile('commerce_offer_for_clients_mortgage')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="commerce_offer_for_clients_mortgage" className="custom-input-file" />
                </div>
                {documents.commerce_offer_for_clients_mortgage.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.commerce_offer_for_clients_mortgage.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.commerce_offer_for_clients_mortgage.error && (
                  <div className="mt-2 error_block">{documents.commerce_offer_for_clients_mortgage.error}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3 my-auto">
                <b>Коммерческое предложение для клиентов (Ипотека.Жизнь)</b>
              </div>
              <div className="col-md-3">
                <div className="jq-file styler">
                  <div className="jq-file__name">{documents.commerce_offer_for_clients_mortgage_life.downloaded
                    ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></>
                    : documents.commerce_offer_for_clients_mortgage_life.name}
                  </div>
                  {(documents.commerce_offer_for_clients_mortgage_life.path || documents.commerce_offer_for_clients_mortgage_life.file)
                    ? (<div onClick={() => this.handleDeleteFile('commerce_offer_for_clients_mortgage_life')} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={this.handleChange} type="file" name="commerce_offer_for_clients_mortgage_life" className="custom-input-file" />
                </div>
                {documents.commerce_offer_for_clients_mortgage_life.path && (
                  <div className="jq-file__download">
                    <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.commerce_offer_for_clients_mortgage_life.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                  </div>
                )}
                {documents.commerce_offer_for_clients_mortgage_life.error && (
                  <div className="mt-2 error_block">{documents.commerce_offer_for_clients_mortgage_life.error}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-2">
            <button type="submit" className="btn btn-success">Загрузить</button>
          </div>
          {percent > 0 && (
            <div className="col-md-4 my-auto">
              <Line percent={percent} />
            </div>
          )}
        </div>
      </form>
    );
  }
}
