import React, { Component } from 'react';
import queryString from 'query-string';
import {
  compareAsc, format, parse, parseISO,
} from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import ReactTable from 'react-table-6';
import DatePicker from 'react-datepicker';
import Modal from 'react-responsive-modal';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import ReactExport from 'react-data-export';
import MultiRef from 'react-multi-ref';
import matchSorter from 'match-sorter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFilePdf } from '@fortawesome/fontawesome-free-regular';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { customFetch, setTableSettings, setTableSorting } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();

class BalanceTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this.state = {
      redColor: 'rgb(255, 0, 0)',
      greenColor: 'rgb(0, 197, 60)',
      activeFilterTab: '1',
      startDate: null,
      endDate: null,
      filtered: [],
      columns: [],
      payments: [],
      loading: true,
      openModalTableCustomized: false,
      excelcolumns: [],
      dataSet: [],
      defaultSortingField: reactLocalStorage.getObject('balanceTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('balanceTableSettings').defaultSortingField
        : 'order_date',
      defaultSortingOrder: reactLocalStorage.getObject('balanceTableSettings').defaultSortingField
        ? reactLocalStorage.getObject('balanceTableSettings').defaultSortingOrder
        : 'true',
    };
  }

  componentDidMount() {
    const { location, tab } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let dateFrom = format(parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let dateTo = format(parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let startDate = parseISO(dateFrom);
    let endDate = parseISO(dateTo);
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        if (filter === 'filter_date') {
          startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
          endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
          dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
          dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
        } else {
          filtered.push({ id: filter, value: urlParams[filter] });
        }
      });
      this.setState({ filtered });
    }
    this.setState({
      startDate,
      endDate,
    });
    this.downloadPayments(dateFrom, dateTo);
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized)) {
      this.updateTableColumns();
    }
  }

  downloadPayments = (dateFrom, dateTo, reload = false) => {
    if (reload) {
      const { startDate, endDate } = this.state;
      dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
      dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
    }
    abortController.abort();
    abortController = new AbortController();
    const { show } = this.props;
    let agentId = '';
    if (this.props.agentId) {
      agentId = this.props.agentId;
    }
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/payments?type=${show}&dateFrom=${dateFrom}&dateTo=${dateTo}&agent=${agentId}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          this.setState({ loading: false });
        } else {
          this.setState({ payments: response });
        }
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Ошибка загрузки данных');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  updateTableColumns = () => {
    const { show, userInfo } = this.props;
    const { redColor, greenColor } = this.state;
    const columns = [{
      id: 'status',
      accessor: (d) => {
        if (d.status === 'credited_to') {
          return 'Зачислено';
        }
        if (d.status === 'pending') {
          return 'Ожидает поступления средств/На согласовании';
        }
        if (d.status === 'agreed') {
          return 'Согласована';
        }
        if (d.status === 'move_to_paid') {
          return 'Передана в оплату';
        }
        if (d.status === 'approved') {
          return 'Утверждена';
        }
        if (d.status === 'commission_changed') {
          return 'Изменение вознаграждения';
        }
        if (d.status === 'paid') {
          return 'Оплачена';
        }
        if (d.status === 'canceled') {
          return 'Отклонена';
        }
        if (d.status === 'returned') {
          return 'Возврат';
        }
        if (d.status === 'error') {
          return 'Ошибка';
        }
        return 0;
      },
      Header: 'Статус',
      filterMethod: (filter, { _original }) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'credited_to') {
          return _original.status === 'credited_to';
        }
        if (filter.value === 'pending') {
          return _original.status === 'pending';
        }
        if (filter.value === 'agreed') {
          return _original.status === 'agreed';
        }
        if (filter.value === 'move_to_paid') {
          return _original.status === 'move_to_paid';
        }
        if (filter.value === 'returned') {
          return _original.status === 'returned';
        }
        if (filter.value === 'approved') {
          return _original.status === 'approved';
        }
        if (filter.value === 'paid') {
          return _original.status === 'paid';
        }
        if (filter.value === 'commission_changed') {
          return _original.status === 'commission_changed';
        }
        if (filter.value === 'canceled') {
          return _original.status === 'canceled';
        }
        if (filter.value === 'error') {
          return _original.status === 'error';
        }
        return true;
      },
      width: reactLocalStorage.getObject('balanceTableSettings').status,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Без фильтра</option>
          <option value="commission_changed">Изменение вознаграждения</option>
          <option value="credited_to">Зачислено</option>
          <option value="pending">Ожидает поступления средств/На согласовании</option>
          <option value="agreed">Согласована</option>
          <option value="approved">Утверждена</option>
          <option value="move_to_paid">Передана в оплату</option>
          <option value="returned">Возврат</option>
          <option value="paid">Оплачена</option>
          <option value="canceled">Отклонена</option>
          <option value="error">Ошибка</option>
        </select>
      ),
      show: reactLocalStorage.getObject('balanceTableSettings').show_status !== false,
      showCustomized: true,
    }, {
      Header: 'Дата',
      id: 'order_date',
      accessor: (d) => {
        if (d.erp_payment_data) {
          return format(parseISO(d.erp_payment_data), 'dd.MM.yyyy');
        }
        if (d.status === 'commission_changed') {
          return format(parseISO(d.created_at), 'dd.MM.yyyy');
        }
        if (d.order_date) {
          return format(parseISO(d.order_date), 'dd.MM.yyyy');
        }
        return format(parseISO(d.created_at), 'dd.MM.yyyy');
      },
      sortMethod: (a, b) => compareAsc(parse(a, 'dd.MM.yyyy', new Date()), parse(b, 'dd.MM.yyyy', new Date())),
      width: reactLocalStorage.getObject('balanceTableSettings').order_date,
      show: reactLocalStorage.getObject('balanceTableSettings').show_order_date !== false,
      showCustomized: true,
    }, {
      Header: 'Дата внесения полиса',
      id: 'created_at',
      accessor: (d) => {
        if (d.policy) {
          return format(parseISO(d.policy.created_at), 'dd.MM.yyyy');
        }
        if (d.mortgage) {
          return format(parseISO(d.mortgage.created_at), 'dd.MM.yyyy');
        }
        return '';
      },
      sortMethod: (a, b) => compareAsc(parse(a, 'dd.MM.yyyy', new Date()), parse(b, 'dd.MM.yyyy', new Date())),
      width: reactLocalStorage.getObject('balanceTableSettings').created_at,
      show: reactLocalStorage.getObject('balanceTableSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Приход, руб.',
      id: 'amount',
      width: reactLocalStorage.getObject('balanceTableSettings').amount,
      accessor: (d) => {
        if (d.status === 'credited_to' || (d.status === 'commission_changed' && d.amount > 0)) {
          return (parseFloat(d.amount));
        }
        return null;
      },
      Cell: (row) => ((row.value || row.value === 0) ? <span style={{ color: greenColor }}>+{row.value}</span> : ''),
      show: reactLocalStorage.getObject('balanceTableSettings').show_amount !== false,
      showCustomized: true,
    }, {
      Header: 'Расход, руб.',
      id: 'payment',
      width: reactLocalStorage.getObject('balanceTableSettings').payment,
      accessor: (d) => {
        if (d.status === 'commission_changed' && d.amount > 0) {
          return null;
        }
        if (d.status !== 'credited_to') {
          return (parseFloat(Math.abs(d.amount)));
        }
        return null;
      },
      Cell: (row) => (row.value ? <span style={{ color: redColor }}>{row.value > 0 ? '-' : ''}{row.value}</span> : ''),
      show: reactLocalStorage.getObject('balanceTableSettings').show_payment !== false,
      showCustomized: true,
    }, {
      Header: 'Продукт',
      accessor: 'product',
      width: reactLocalStorage.getObject('balanceTableSettings').product,
      Cell: ({ row }) => {
        if (row.product === 'osago') return 'ОСАГО';
        if (row.product === 'mortgage') return 'Ипотека';
        if (row.product === 'kasko') return 'КАСКО';
        if (row.product === 'multipolis') return 'Мультиполис';
        if (row.product === 'ifl') return 'Страхование имущества';
        if (row.product === 'zpr') return 'Защита от потери работы';
        if (row.product === 'medicineWithoutBorders') return 'Медицина без границ+';
        if (row.product === 'vzr') return 'ВЗР';
        if (row.product === 'telemedicine') return 'Телемедицина';
        if (row.product === 'accident') return 'НС-кросс';
        if (row.product === 'dmsStudent') return 'ДМС студентов';
        if (row.product === 'ns') return 'НС';
        return '';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'osago') {
          return row.product === 'osago';
        }
        if (filter.value === 'mortgage') {
          return row.product === 'mortgage';
        }
        if (filter.value === 'kasko') {
          return row.product === 'kasko';
        }
        if (filter.value === 'multipolis') {
          return row.product === 'multipolis';
        }
        if (filter.value === 'ifl') {
          return row.product === 'ifl';
        }
        if (filter.value === 'zpr') {
          return row.product === 'zpr';
        }
        if (filter.value === 'vzr') {
          return row.product === 'vzr';
        }
        if (filter.value === 'telemedicine') {
          return row.product === 'telemedicine';
        }
        if (filter.value === 'accident') {
          return row.product === 'accident';
        }
        if (filter.value === 'dmsStudent') {
          return row.product === 'dmsStudent';
        }
        if (filter.value === 'ns') {
          return row.product === 'ns';
        }
        if (filter.value === 'medicineWithoutBorders') {
          return row.product === 'medicineWithoutBorders';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="osago">ОСАГО</option>
          <option value="kasko">КАСКО</option>
          <option value="mortgage">Ипотека</option>
          <option value="multipolis">Мультиполис</option>
          <option value="vzr">ВЗР</option>
          <option value="telemedicine">Телемедицина</option>
          <option value="accident">НС-кросс</option>
          <option value="dmsStudent">ДМС студентов</option>
          <option value="ns">НС</option>
          <option value="ifl">Страхование имущества</option>
          <option value="zpr">Защита от потери работы</option>
          <option value="medicineWithoutBorders">Медицина без границ+</option>
        </select>
      ),
      show: reactLocalStorage.getObject('balanceTableSettings').show_product_name !== false,
      showCustomized: true,
    }, {
      Header: 'ID полиса',
      id: 'policyId',
      width: reactLocalStorage.getObject('balanceTableSettings').policyId,
      accessor: (d) => {
        if (d.policy) {
          return d.policy.id;
        }
        if (d.policy_id) {
          return d.policy_id;
        }
        if (d.mortgage_id) {
          return d.mortgage_id;
        }
      },
      filterAll: true,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['policyId'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      show: reactLocalStorage.getObject('balanceTableSettings').show_policyId !== false,
      showCustomized: true,
    }, {
      Header: 'Пользователь',
      id: 'agent',
      accessor: (d) => (d.agent ? `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}` : null),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agent'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      width: reactLocalStorage.getObject('balanceTableSettings').agent,
      show: (reactLocalStorage.getObject('balanceTableSettings').show_agent !== false && show === 'all'),
      showCustomized: show === 'all',
    }, {
      Header: 'Номер полиса',
      id: 'policyNumber',
      accessor: (d) => {
        if (d.policy) {
          return d.policy.insurance_id;
        }
        if (d.mortgage && d.mortgage.insurance_id) {
          switch (d.mortgage.product_insurance) {
            case 'mortgage':
              return d.mortgage.insurance_id.property;
            case 'mortgageLife':
              return d.mortgage.insurance_id.life;
            case 'mortgageComplex':
              if (d.mortgage.insurance_id.union) {
                return d.mortgage.insurance_id.union;
              }
              return (`${d.mortgage.insurance_id.life}/${d.mortgage.insurance_id.property}`);
            default:
              return null;
          }
        }
        return null;
      },
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['policyNumber'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      width: reactLocalStorage.getObject('balanceTableSettings').policyNumber,
      show: (reactLocalStorage.getObject('balanceTableSettings').show_policyNumber !== false),
      showCustomized: true,
    }, {
      Header: 'Страховая компания',
      id: 'insuranceCompany',
      accessor: (d) => {
        if (d.mortgage?.company) {
          return d.mortgage.company;
        }
        if (d.policy?.company) {
          return d.policy.company;
        }
      },
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insuranceCompany'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      width: reactLocalStorage.getObject('balanceTableSettings').insuranceCompany,
      show: (reactLocalStorage.getObject('balanceTableSettings').show_insuranceCompany !== false),
      showCustomized: true,
    }, {
      Header: 'Комментарий',
      width: reactLocalStorage.getObject('balanceTableSettings').comment,
      accessor: 'comment',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['comment'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: reactLocalStorage.getObject('balanceTableSettings').show_comment !== false,
      showCustomized: true,
    }, {
      Header: 'Документ основание',
      width: reactLocalStorage.getObject('balanceTableSettings').basis_of_payment,
      accessor: 'basis_of_payment',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['basis_of_payment'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: (userInfo.full_time_employee && reactLocalStorage.getObject('balanceTableSettings').show_basis_of_payment !== false),
      showCustomized: userInfo.full_time_employee,
    }, {
      Header: 'Ошибка',
      width: reactLocalStorage.getObject('balanceTableSettings').error_message,
      accessor: 'error_message',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['error_message'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: reactLocalStorage.getObject('balanceTableSettings').show_error_message !== false,
      showCustomized: true,
    }, {
      Header: 'ФИО страхователя',
      id: 'full_name',
      width: reactLocalStorage.getObject('balanceTableSettings').full_name,
      accessor: (d) => (d.last_name ? `${d.last_name} ${d.first_name} ${d.middle_name}` : ''),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['full_name'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      show: reactLocalStorage.getObject('balanceTableSettings').show_full_name !== false,
      showCustomized: true,
    }, {
      Header: 'Загружен в ERP',
      id: 'erp_status',
      width: reactLocalStorage.getObject('balanceTableSettings').erp_status,
      accessor: (d) => {
        if (d.policy) {
          return d.policy.erp_status;
        }
        if (d.mortgage) {
          return d.mortgage.erp_status;
        }
        return null;
      },
      Cell: ({ row }) => (row.erp_status === 1
        ? (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />)
        : null),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'true') {
          return row.erp_status === 1;
        }
        return !row.erp_status;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Без фильтра</option>
          <option value="true">Загружен</option>
          <option value="false">Не загружен</option>
        </select>
      ),
      show: (userInfo.full_time_employee && reactLocalStorage.getObject('balanceTableSettings').show_erp_status !== false),
      showCustomized: userInfo.full_time_employee,
    }, {
      Header: '',
      width: 40,
      filterable: false,
      Cell: (row) => (row.original.receipt ? (
        <a title="Скачать чек" rel="noopener noreferrer" target="_blank" href={row.original.receipt}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>
      ) : null),
      show: (show === 'all'),
    },
    ];
    this.setState({ columns });
  };

  downloadReport = () => {
    const { columns } = this.state;
    const { ExcelColumn } = ReactExport.ExcelFile;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    const dataToDownload = [];
    for (let index = 0; index < currentRecords.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (currentRecords[index][columns[colIndex].accessor]) {
            if (currentRecords[index][columns[colIndex].accessor] && currentRecords[index][columns[colIndex].accessor].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
            }
          } else {
            if (currentRecords[index][columns[colIndex].id] && currentRecords[index][columns[colIndex].id].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
            }
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }

    const excelcolumns = columns.filter((column) => column.show).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);

    this.setState({ excelcolumns, dataSet: dataToDownload }, () => {
      this.excelFile.handleDownload();
    });
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('balanceTableSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('balanceTableSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    const { startDate } = this.state;
    const { tab } = this.props;
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (filter.id === 'filter_date' && startDate === null) {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
        }
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  handleChangeStart = (date) => {
    this.setState({ payments: [] }, () => {
      const { endDate, filtered } = this.state;
      this.setState({ startDate: date, loading: true });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateTo = null;
      if (endDate !== null) {
        filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
        dateTo = format(endDate, 'yyyy-MM-dd');
      }
      this.downloadPayments(format(date, 'yyyy-MM-dd'), dateTo);

      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({ payments: [] }, () => {
      const { startDate, filtered } = this.state;
      this.setState({ endDate: date, loading: true });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateFrom = null;
      if (startDate !== null) {
        filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }
      this.downloadPayments(dateFrom, format(date, 'yyyy-MM-dd'));

      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
    });
  };

  toggle(tab) {
    this.setState({ payments: [] }, () => {
      const { filtered } = this.state;
      const newFiltered = [...filtered];
      const date = new Date();
      this.setState({ loading: true });
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
      let dateFrom;
      let dateTo;
      this.setState({ activeFilterTab: tab });
      let filterDate = null;
      if (tab === '1') {
        dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
        dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateCurrentMonth,
          endDate: endDateCurrentMonth,
        });
        filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
      } else if (tab === '2') {
        if (currentMonth === 1) {
          currentYear -= 1;
          lastButOneMonth = '12';
          lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
          startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
          endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', date);
        }
        dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
        dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
        this.setState({
          startDate: startDateLastButOneMonth,
          endDate: endDateLastButOneMonth,
        });
        filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
      } else if (tab === '3') {
        dateFrom = null;
        dateTo = null;

        this.setState({
          startDate: null,
          endDate: date,
        });
        filterDate = `${format(date, 'dd.MM.yyyy')}`;
      }

      let findedIndex = -1;
      let findedIndexDateTab = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
        if (filter.id === 'dateTab') {
          findedIndexDateTab = i;
        }
      });
      if (findedIndex !== -1) {
        if (filterDate === null) {
          newFiltered.splice(findedIndex, 1);
        } else {
          newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
        }
      } else if (filterDate !== null) {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      if (findedIndexDateTab !== -1) {
        newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
      } else {
        newFiltered.push({ id: 'dateTab', value: tab });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab' && filter.id !== 'renewal') {
          if (filter.id === 'filter_date' && tab === '3') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
          }
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${this.props.tab}`;
      const { history } = this.props;
      history.push({ search: queryParams });
      this.setState({ filtered: newFiltered });
      this.downloadPayments(dateFrom, dateTo);
    });
  }

  render() {
    const {
      payments,
      columns,
      loading,
      activeFilterTab,
      openModalTableCustomized,
      dataSet,
      excelcolumns,
      startDate,
      endDate,
      defaultSortingField,
      defaultSortingOrder,
    } = this.state;
    const { history } = this.props;
    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggle('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggle('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggle('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2">
            <span>Точные даты с</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
        </div>
        <ReactTable
          ref={(r) => this.reactTable = r}
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id && rowInfo.original.product) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    history.push(`/${rowInfo.original.product}/${rowInfo.original.policyId || rowInfo.original.mortgage_id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/${rowInfo.original.product}/${rowInfo.original.policyId || rowInfo.original.mortgage_id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          defaultFiltered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          className="table"
          data={payments}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<LoadingSpinner className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные отсутствуют"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          defaultPageSize={50}
          minRows={1}
          filterable
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'balanceTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings(newResized, 'balanceTableSettings');
          }}
        />
      </>
    );
  }
}

BalanceTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(BalanceTable);
