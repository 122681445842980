import React, { useMemo } from 'react';
import styled from 'styled-components';

const List = styled.li`
  width: ${(props) => (props.width || '32.1%')};
    margin-top: 60px;
    margin-bottom: -25px;
    padding: 0;
    list-style: none;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: .8fr 1fr;
    grid-template-columns: .8fr 1fr;
    -ms-grid-rows: -webkit-max-content minmax(95px, -webkit-max-content);
    -ms-grid-rows: max-content minmax(95px, max-content);
    grid-template-rows: -webkit-max-content minmax(95px, -webkit-max-content);
    grid-template-rows: max-content minmax(95px, max-content);
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
    width: 61.2%;
    -webkit-column-gap: 36px;
    -moz-column-gap: 36px;
    column-gap: 36px;
    row-gap: 36px;

  @media (max-width: 1023px) {
    width: 64%;
    margin-bottom: -46px;
  }
  @media (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 0;
    width: 100%;
    margin-top: 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: -webkit-max-content;
    -ms-grid-rows: max-content;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    row-gap: 0
  }
 `;

export default function DashboardCardBigList(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <List disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </List>
  );
}
