import React, {
  forwardRef, useState, useContext, useEffect,
} from 'react';
import TagsProduct from './TagsProduct';
import Notes from './Notes';
import Insurances from './Insurances';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import getBanks from '../../Utils/ClassifiersLoaders';

function InsuranceManagement() {
  const { showModalInfo } = useContext(ModalMessagesContext);

  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  const [currentSection, setCurrentTag] = useState(1);
  const [companies, setCompanies] = useState([]);
  // const [stocks, setStocks] = useState([]);
  const [notes, setNotes] = useState([]);
  const [tags, setTags] = useState([]);
  const [banksOption, setBanksOption] = useState([]);

  useEffect(() => {
    getCompanies(lsToken, 'mortgage').then((response) => setCompanies(response));
    getBanks(lsToken).then((response) => response.forEach((bank) => {
      setBanksOption((banks) => [...banks, { label: bank.name, value: bank.code }]);
    }));
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/additions`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setTags(response.tags);
          setNotes(response.notes);
          // setStocks(response.stocks);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      });
  }, []);

  return (
    <>
      <div className="form-group row border-bottom">
        <div className="col-lg-12">
          <button type="button" className="btn btn-success mr-3 mb-3" onClick={() => setCurrentTag(1)}>Страховые</button>
          <button type="button" className="btn btn-success mr-3 mb-3" onClick={() => setCurrentTag(2)}>Примечания</button>
          <button type="button" className="btn btn-success mr-3 mb-3" onClick={() => setCurrentTag(3)}>Теги</button>
        </div>
      </div>
      {
        currentSection === 1 ? (
          <Insurances
            companies={companies}
            notes={notes}
            tags={tags}
            banksOption={banksOption}
            setCompanies={setCompanies}
          />
        ) : null
      }
      {
        currentSection === 2 ? <Notes notes={notes} setNotes={setNotes} /> : null
      }
      {
        currentSection === 3 ? <TagsProduct setTags={setTags} tags={tags} /> : null
      }
    </>
  );
}

export default forwardRef(InsuranceManagement);
