import styled from 'styled-components';
import React from 'react';

export const TableTbodyAnswers = styled.tbody`
    @media (max-width: 1023px) {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
  
`;

export default function TableTbody(props) {
  const {
    children, disabled, width,
  } = props;

  return (
    <TableTbodyAnswers disabled={disabled} width={width}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </TableTbodyAnswers>
  );
}
