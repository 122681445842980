import React, {
  useState, useRef, useEffect,
} from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import CheckPropertyForm from './CheckPropertyForm';
import CheckPropertyInfo from './CheckPropertyInfo';
import CheckPropertyHistoryTable from './CheckPropertyHistoryTable';
import TabNavList from '../../Layout/Tabs/TabNavList';
import TabNavItem from '../../Layout/Tabs/TabNavItem';
import Button from '../../Layout/Button';
import CustomIcon from '../../Layout/CustomIcon';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

export default function CheckProperty(props) {
  const {
    match,
    history,
    location,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const tableRef = useRef();

  const [requestWaitingFlag, setRequestWaitingFlag] = useState(false);
  const [activeTab, setActiveTab] = useState('3');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    history.push({ search: `?activeTab=${tab}` });
  };

  const openModalTableCustomized = () => {
    tableRef.current.customizeTable();
  };

  const downloadReport = () => {
    tableRef.current.downloadReport();
  };

  useEffect(() => {
    const urlParams = queryString.parse(location.search);
    if (match.params.id) {
      setActiveTab('1');
    } else if (urlParams.activeTab) {
      setActiveTab(urlParams.activeTab);
    }
  }, []);

  return (
    <div className="position-relative">
      <h1 className="page_title d-inline-block mr-3">Проверка недвижимости</h1>
      {(activeTab === '2' || activeTab === '4') && (
        <>
          <div className="page_header__control mb-2 mb-md-0 mr-2">
            <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
              <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
              Настроить таблицу
            </Button>
          </div>
          <div className="page_header__control mb-2 mb-md-0">
            <Button colorType="grayBordered" type="border" width="auto" onClick={downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
          </div>
        </>
      )}
      <div>
        <TabNavList>
          <TabNavItem
            onClick={() => { toggle('3'); }}
            active={activeTab === '3'}
            text="Описание сервиса"
          />
          <TabNavItem
            onClick={() => { toggle('1'); }}
            active={activeTab === '1'}
            text="Новый запрос"
          />
          <TabNavItem
            onClick={() => { toggle('2'); }}
            active={activeTab === '2'}
            text="История запросов"
          />
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabNavItem
              onClick={() => { toggle('4'); }}
              active={activeTab === '4'}
              text="История запросов (админ)"
            />
          )}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            {activeTab === '1' ? (
              <CheckPropertyForm requestWaitingFlag={requestWaitingFlag} match={match} history={history} />
            ) : null}
          </TabPane>
          <TabPane tabId="2">
            {activeTab === '2' ? (
              <CheckPropertyHistoryTable ref={tableRef} history={history} tab={activeTab} toggle={toggle} type="request" />
            ) : null}
          </TabPane>
          <TabPane tabId="3">
            {activeTab === '3' ? (
              <CheckPropertyInfo setActiveTab={setActiveTab} history={history} />
            ) : null}
          </TabPane>
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabPane tabId="4">
              { activeTab === '4' ? <CheckPropertyHistoryTable ref={tableRef} history={history} tab={activeTab} toggle={toggle} type="all" /> : null }
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
}
